<template>

  <div class="view pb-2">

    <i-card :viewIndex="card.index"
            :viewCount="1"
            :leftPanel="card.panels.left"
            :rightPanel="card.panels.right"
            :topPanel="card.panels.top"
            :bottomPanel="card.panels.bottom"
            class="i-card mr-2 br-1">

      <div class="i-card__main">

        <div class="i-card__main__header">

          <div class="d-flex align-center flex-grow-1 ml-1" style="height: 52px">

            <v-tooltip bottom
                       nudge-right="72"
                       nudge-bottom="6"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       color="ycmd-black"
                       content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn dark icon depressed v-on="on"
                       @click="() => { messages = []; loadedData = false; changeView({ view: 'list-view', params: {startingId: params.data.id} }); }"
                       color="white">
                  <v-icon :size="22">arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Show Messages</span>
            </v-tooltip>
            <div class="white--text fs-16 ml-4">
              Message Details
            </div>
          </div>
        </div>

        <div class="i-card__main__content white br-1">

          <div class="i-card__scrollable">

            <div class="pa-4">
              <v-row style="border-bottom: solid #C3D7E8 2px;">
                <v-col cols="12" md="4">
                  <div class="d-flex align-center">
                    <div class="fs-18 fw-500 ycmd-medium-blue--text uppercase">
                      {{
                        (params.data.patient && (params.data.patient.first_name || params.data.patient.last_name)) ? `${params.data.patient.first_name} ${params.data.patient.last_name}` : 'Patient Name N/A'
                      }}
                    </div>
                    <div v-if="params.data.patient && params.data.patient.dob" class="fs-12 fw-500 pl-3">
                      ({{ formatDOB({date: params.data.patient.dob }) }})
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="5">
                  <div class="d-flex align-center full-height fs-13 fw-400">
                    {{
                      `${params.data.facility_name && params.data.facility_name ? params.data.facility_name : 'Facility Name N/A'}`
                    }}
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="d-flex align-center justify-end full-height">
                    <div class="label-title" style="width: 24px">ID</div>
                    <div class="label-value">
                      {{ params.data.id ? params.data.id : 'Not Available' }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div class="pa-4">
              <v-row style="border-bottom: solid #C3D7E8 2px;">
                <v-col cols="12" sm="4">
                  <div class="d-flex align-start">
                    <div class="label-title" style="width: 92px">SENT BY</div>
                    <div class="label-value">
                      <div class="d-flex flex-column">
                        {{
                          `${params.data.from_user && (params.data.from_user.first_name || params.data.from_user.last_name) ? `${params.data.from_user.first_name || ''} ${params.data.from_user.last_name || ''}` : 'N/A'}`
                        }}
                        <div class="pt-2">{{
                            params.data.callback ? formatPhoneNumber(params.data.callback) : 'N/A'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="params.data.guest_name" cols="12" sm="4">
                  <template v-if="params.data.guest_name && params.data.guest_name !== 'null'">
                    <div class="d-flex align-center">
                      <div class="label-title" style="width: 92px">ON BEHALF OF</div>
                      <div class="label-value">
                        {{ params.data.guest_name }}
                      </div>
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="12" class="pt-1 pb-2">
                  <div class="ycmd-light-gray fw-400 black--text pa-4 br-2">
                    {{
                      `${params.data.contents ? params.data.contents : 'No Message'}`
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="d-flex align-center">
                    <div class="label-title" style="width: 92px">ORIGINALLY TO</div>
                    <div class="label-value">
                      {{
                        `${params.data.orig_user && (params.data.orig_user.first_name || params.data.orig_user.last_name) ? `${params.data.orig_user.first_name || ''} ${params.data.orig_user.last_name || ''}` : 'N/A'}`
                      }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="d-flex align-center">
                    <div class="label-title" style="width: 92px">SENT TO</div>
                    <div class="label-value">
                      {{
                        `${params.data.to_user && (params.data.to_user.first_name || params.data.to_user.last_name) ? `${params.data.to_user.first_name || ''} ${params.data.to_user.last_name || ''}` : 'N/A'}`
                      }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="d-flex flex-column full-height">
                    <div class="d-flex pb-4">
                      <div class="label-title" style="width: 80px">SENT</div>
                      <div class="d-flex justify-end label-value full-width">
                        {{ formatDate({date: params.data.last_sent, isUTC: false }) }}
                      </div>
                    </div>
                    <div class="d-flex pb-4">
                      <div class="d-flex align-center full-height label-title" style="width: 80px">CONFIRMED</div>
                      <div class="d-flex justify-end label-value full-width">
                        {{ formatDate({date: params.data.date_confirmed, isUTC: false   }) === 'NA' ? 'Unconfirmed' : formatDate({date: params.data.date_confirmed, isUTC: false   }) }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="d-flex align-center full-height label-title" style="width: 80px">RESPONSE</div>
                      <div class="d-flex justify-end label-value full-width">
                        {{
                          params.data.response && params.data.response != 0 ? params.data.response : 'No Response'
                        }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <template v-if="params.data.forwards && params.data.forwards.length > 0">
                  <template v-for="(item) in params.data.forwards">
                    <v-col cols="12" sm="8">
                      <div class="d-flex align-center">
                        <div class="label-title" style="width: 92px">FORWARDED BY</div>
                        <div class="label-value">
                          {{ item.forwarded_by_name }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <div class="d-flex align-center">
                        <div class="label-title" style="width: 80px">SENT</div>
                        <div class="d-flex justify-end label-value full-width">
                          {{ formatDate({date: item.date_forwarded, isUTC: false  }) }}
                        </div>
                      </div>
                    </v-col>
                  </template>
                  <template v-if="params.data.forwarded_message">
                    <v-col cols="12" sm="12" class="pt-1 pb-3">
                      <div class="ycmd-medium-gray white--text pa-4 br-2">
                        {{ params.data.forwarded_message }}
                      </div>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </div>

            <div class="pa-4">
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="(field, i) in params.data.fields" :key="`custom_field_${i}`">
                  <div class="label-title">{{ field.label }}</div>
                  <div class="label-value pt-2">
                    {{
                      field.formatted_value
                    }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>

      <template slot="right-panel">

        <div class="i-card__panel i-card__panel__right ycmd-blue-gradient pa-2 pr-0 pt-0">

          <div class="i-card__panel__header br-2">

            <div class="d-flex align-center flex-grow-1">

              <v-tooltip bottom
                         nudge-bottom="10"
                         nudge-right="50"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark depressed v-on="on" @click="card.panels.right = false" class="mr-2">
                    <v-icon :size="22">close</v-icon>
                  </v-btn>
                </template>
                <span>Close Statistics</span>
              </v-tooltip>

              <div class="white--text fs-18 ml-3">
                Statistics
              </div>
            </div>
            <div class="d-flex align-center justify-end ml-2">

            </div>
          </div>

          <div class="i-card__panel__content white br-2 br_tr-0 pa-2">

            <div class="i-card__scrollable">
              <div class="pa-3">
                Content
              </div>
            </div>
          </div>
        </div>

      </template>
    </i-card>
  </div>

</template>

<script>

import {formatDate, formatDOB} from '@/methods/formatters';
import {formatPhoneNumber} from '@/methods/globalMethods';

import SectionTitle from '@/components/sections/section-title';
import SectionContent from '@/components/sections/section-content';

export default {
  name: 'messageDetail',
  components: {
    'section-title': SectionTitle,
    'section-content': SectionContent
  },
  props: {
    changeView: Function,
    params: {
      data: Object
    }
  },
  data() {
    return {
      formatDate,
      formatDOB,
      formatPhoneNumber,
      isLoadingInit: false,
      card: {
        index: 0,
        panels: {left: false, right: false, top: false, bottom: false}
      }
    }
  },
  activated() {
    this.loadedData = false;
    this.messages = [];
  },
  mounted() {
    console.log('props?: ', this.params);
  },
  methods: {
    route(name, transition) {

      this.$router.push({
        name: name, params: {
          transition: transition
        }
      }).catch(err => {
        console.log('router error: ', err)
      });
    },
    slide(direction) {
      this.card.panels[direction] = !this.card.panels[direction];
    }
  }
}

</script>

<style scoped>

.item-box {
  background-color: #fff;
  border-bottom: #177ad5 solid 1px;
}

.item-box.odd {
  background-color: #ECEFF1;
}

.item-box:hover {
  background-color: rgba(255, 255, 255, 0.8)
}

</style>
