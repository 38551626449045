<template>

  <div class="view">

    <div class="view">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 ycmd-medium=blue">

        <div class="i-card__main">

          <div class="i-card__main__header" style="height: 56px">

            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width white--text pl-3 pr-3" style="height:56px">
                <div>
                  <v-btn icon dark elevation="0" @click="changeView({view: 'chat-rooms', transition: 'prev'})">
                    <v-icon size="18">close</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-column flex-grow-1 pl-2 pr-2 fs-12">
                  GENERAL SETTINGS
                </div>


              </div>
            </div>

          </div>

          <div class="i-card__main__content white"  >

            <div class="i-card__scrollable white--text">

              <div class="d-flex flex-column pt-4 pl-4 ycmd-medium-blue">

                <div class="d-flex align-center pa-2">
                  <div class="fade-2 fs-12">NOTIFICATIONS</div>
                </div>
              </div>
              <div class="d-flex flex-row pt-4 pl-4">
                <v-switch  
                          color="ycmd-blue"
                          v-model="enableNotifications"
                          label="Enable Notifications for all Chats" />            

              </div>

            </div>
          </div>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>

  import {mapActions, mapState, mapGetters} from "vuex";
  import CHANGE_CHAT_SETTINGS from "@/graphql/mutations/phoenix/ycmdUserSettings";

  export default {
    name: 'chatGeneralSettingsList',
    props: {
        changeView: Function,
    },
    data() {
      return {
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        transition: 'fade',
        enableNotifications: true
      }
    },
    mounted() {

      this.enableNotifications = this.getChatNotificationsEnabled;
    },
    methods: {
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu',]),
      ...mapActions('profile', ['enableChatNotifications']),
      async changeChatSettings(params) {

        const { enable } = params;

        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: CHANGE_CHAT_SETTINGS,
          variables: {
            enable_chat_notifications: enable
          },
          update: (cache, { data: { ycmdUserSettings } }) => {
            try {

              const { enable_chat_notifications } = ycmdUserSettings;

              this.enableChatNotifications({enable_chat_notifications});
            }
            catch (e) {
              console.error(e);
            }
          }
        });
      }
    },
    computed: {
      ...mapState('rightMenu', ['isRightMenuExpanded']),
      ...mapState('profile', ['settings']),
      ...mapGetters('profile', ['getChatNotificationsEnabled'])
    },
    watch: {
      enableNotifications (enable) {

        this.changeChatSettings({enable})
      }
    }
  }
</script>

<style>


</style>