<template>

    <div class="d-flex fill-height ycmd-dark-blue-radial-gradient full-width pa-2 br-2">

      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="false"
              :background-color="`#101622`"
              :color="`#637eb6`"
              :loader="`bars`">
      </loading>

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">Forgot Password</div>
                <v-spacer />
                <div></div>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                <div class="d-flex flex-column flex-grow-1 full-width pa-3 br-2">

                    <div class="d-flex shrink flex-column full-width justify-center fs-20 ycmd-medium-blue--text white--text">

                        <v-form
                            v-if="!forgotPasswordPassed"
                            ref="forgotPasswordForm"
                            v-model="forgotPasswordForm"
                            :lazy-validation="false"
                            class="mb-3 mt-1">

                            <v-layout column class="mb-3 fs-12" style="text-align: center">
                                Please enter your YouCallMD username
                            </v-layout>

                            <v-text-field dark flat single-line dense solo
                                          v-model="YCMDUsername"
                                          :rules="[rules.required]"
                                          v-on:keyup.enter="forgotPassword"
                                          background-color="ycmd-dark-blue"
                                          label="YCMD Username" />

                            <v-text-field dark flat single-line dense solo
                                          class="hidden"
                                          :rules="[]"
                                          background-color="ycmd-dark-blue"
                                          label="Hidden" />

                        </v-form>



                        <v-form
                            v-if="forgotPasswordPassed"
                            ref="forgotPasswordFormConfirm"
                            v-model="forgotPasswordFormConfirm"
                            :lazy-validation="false"
                            class="mb-3">

                            <v-layout column class="mb-2 fs-12" style="text-align: center">
                                An email has been sent with a reset token. Please enter it and your new password below.
                            </v-layout>

                            <v-text-field light flat single-line dense solo
                                          v-model="password"
                                          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                          :rules="[rules.required]"
                                          :type="showPassword ? 'text' : 'password'"
                                          :tabindex="1"
                                          label="Create New Password"
                                          @input="extraPasswordCheck()"
                                          @click:append="showPassword = !showPassword" />

                            <v-text-field light flat single-line dense solo
                                          v-model="confirmPassword"
                                          :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                                          :rules="[rules.required]"
                                          :type="showConfirmPassword ? 'text' : 'password'"
                                          :tabindex="2"
                                          label="Confirm New Password"
                                          v-on:keyup.enter="register"
                                          @input="extraPasswordCheck()"
                                          @click:append="showConfirmPassword = !showConfirmPassword" />

                            <v-text-field light flat single-line dense solo
                                          v-model="resetToken"
                                          :rules="[rules.required]"
                                          :tabindex="3"
                                          label="Reset Token" />

                        </v-form>

                    </div>

                    <v-layout v-if="errorMessage" column class="fs-8" style="color: #FC5252; padding: 0px; text-align: center">
                        {{errorMessage}}
                    </v-layout>

                    <v-layout v-if="forgotPasswordPassed" column class="fs-8" style="color: #FC5252; padding: 0px; text-align: center">
                        {{extraPasswordError}}
                    </v-layout>

                </div>

                <div class="d-flex flex-column align-center">

                    <div class="full-width pa-1 pl-0 pr-0" v-if="!forgotPasswordPassed">
                        <v-btn dark block large depressed color="ycmd-light-blue" :disabled="!forgotPasswordForm" @click="forgotPassword()">Continue</v-btn>
                    </div>

                    <div class="full-width pa-1 pl-0 pr-0" v-else>
                        <v-btn dark block large depressed color="ycmd-light-blue" :disabled="!forgotPasswordFormConfirm" @click="forgotPasswordConfirm()">Submit</v-btn>
                    </div>

                    <div class="pt-2">
                        <v-btn dark text depressed color="white" @click="route('registerAuthenticate', 'prev')">Back</v-btn>
                    </div>
                </div>

            </div>

        </div>

        <v-dialog persistent v-model="dialog" max-width="330" style="">

          <v-card color="" dark style="background-color: #09283F; padding-top: 20px">
            <v-card-text class="fs-18" style="text-align: center;">
              Successfully reset password!
            </v-card-text>

            <v-card-actions>
              <v-btn text color="white" @click="route('registerAuthenticate', 'prev')" style="width: 100%;">Return</v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>

    </div>

</template>

<script>

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import AUTHENTICATE_YCMD from '../../graphql/mutations/authenticateGetYCMDToken';
    import REGISTER_USER from '../../graphql/mutations/authenticateRegisterUser';

    import AUTHENTICATE_FORGOT_PHOENIX_PASSWORD from '../../graphql/mutations/authenticateForgotPhoenixPassword';
    import AUTHENTICATE_FORGOT_PHOENIX_PASSWORD_CONFIRM from '../../graphql/mutations/authenticateForgotPhoenixPasswordConfirm';

    export default {
        name: 'registerForgotPassword',
        data: () => ({
            isLoading: false,
            forgotPasswordPassed: false,
            registrationCode: '',

            forgotPasswordForm: false,
            forgotPasswordFormConfirm: false,

            dialog: false,
            errorMessage: '',
            YCMDUsername: '',

            password: '',
            confirmPassword: '',
            resetToken: '',

            showPassword: false,
            showConfirmPassword: false,
            showYCMDPassword: false,
            extraPasswordError: '',
            passwordsMatch: false,

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  return pattern.test(value) || 'Invalid e-mail';
                }
            }
        }),
        components: {
          Loading
        },
        mounted() {
          const registrationCode = this.$route.query.registration_token;
          const resetToken = this.$route.query.reset_token;
          const username = this.$route.query.username;

          const registrationCodeSaved = localStorage.getItem("registrationCode");

          if (registrationCode) {
            // console.log('got registration code, saving: ', registrationCodeSaved);
            this.registrationCode = registrationCode;
          } else {
            if (registrationCodeSaved && registrationCodeSaved !== 'undefined') {
              // console.log('using registration code from localstorage: ', registrationCodeSaved);
              this.registrationCode = registrationCodeSaved;
            }
          }
          if (resetToken) {
            // console.log('got reset token, saving: ', resetToken);
            this.resetToken = resetToken;
          }
          if (username) {
            // console.log('got user name, saving: ', username);
            this.YCMDUsername = username;
          }

          if (resetToken && username) {
            // console.log('got all three') took out registrationCode &&  since it's not technically needed here
            // we are coming from redirect, pass first form
            this.forgotPasswordPassed = true;
          }
          // const registrationCode = this.$route.query.registration_code;
        },
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            forgotPasswordConfirmValid() {
              if (!this.forgotPasswordFormConfirm && this.nextToken) {
                return false;
              }
              return true;
            },
            async forgotPassword() {
                if (!this.forgotPasswordForm) return;

                this.isLoading = true;
                // console.log(this.YCMDUsername, this.YCMDPassword);
                try {
                    const response = await this.$apollo.mutate({
                        mutation: AUTHENTICATE_FORGOT_PHOENIX_PASSWORD,
                        variables: {
                          username: this.YCMDUsername,
                          registration_token: this.registrationCode || null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('response: ', response);
                    this.isLoading = false;

                    if (response.data.authenticateForgotPhoenixPassword) {
                      this.forgotPasswordPassed = true;
                    }  else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message && typeof response.errors[0].message === 'string') {
                        this.errorMessage = response.errors[0].message || 'Error 2A. Try again later.';
                      } else {
                        this.errorMessage = 'Error 2B. Err did not come back.';
                      }
                    } else {
                      // data did not come back
                      console.log('optIn data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.errorMessage = e.message;
                    console.log('CAUGHT: There was an error logging in *registerUser* : ', e);
                }
            },
            async forgotPasswordConfirm() {
                this.isLoading = true;
                // console.log(this.YCMDUsername, this.YCMDPassword);
                try {
                    const response = await this.$apollo.mutate({
                        mutation: AUTHENTICATE_FORGOT_PHOENIX_PASSWORD_CONFIRM,
                        variables: {
                          username: this.YCMDUsername,
                          newpassword: this.confirmPassword,
                          resetToken: this.resetToken
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('response: ', response);
                    this.isLoading = false;
                    console.log(response, this.resetToken);
                    if (response.data && response.data.authenticateForgotPhoenixPasswordConfirm) {
                      this.dialog = true;
                    }  else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message && typeof response.errors[0].message === 'string') {
                        this.errorMessage = response.errors[0].message || 'Error 2A. Try again later.';
                      } else {
                        this.errorMessage = 'Error 2B. Err did not come back.';
                      }
                    } else {
                      // data did not come back
                      console.log('optIn data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.errorMessage = e.message;
                    console.log('CAUGHT: There was an error logging in *registerUser* : ', e);
                }
            },
            resetFields() {
              this.isLoading = false;
              this.dialog = false;
              this.password = '';
              this.confirmPassword = '';
              this.showPassword = false;
              this.showConfirmPassword = false;
              this.showYCMDPassword = false;
              this.YCMDPassword = '';
              this.YCMDUsername = '';
              this.errorMessage = '';
              this.email = '';
              this.countryCode = '+1';
              this.mobileNumber = '';
              this.parsedMobileNumber = '';
              this.verificationCode = '';

              this.forgotPasswordForm = false;
              this.forgotPasswordFormConfirm = false;
              this.resetToken = '';
              this.registrationCode = '';
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            extraPasswordCheck() {
              // check if passwords match
              if (this.password !== this.confirmPassword) {
                this.extraPasswordError = 'Passwords do not match.';
                this.passwordsMatch = false;
              } else {
                this.extraPasswordError = '';
                this.passwordsMatch = true;
              }
            },
            clearYCMDForm() {
              this.YCMDUsername = '';
              this.YCMDPassword = '';
            },
            checkYCMDUser() {
              if (this.YCMDUser && this.YCMDUsername && this.YCMDPassword) {
                return true;
              } else if (this.YCMDUser && (!this.YCMDUsername || !this.YCMDPassword)) {
                return false;
              }
              return true;
            },
            validateCheckBegin() {
              if (this.forgotPasswordForm) {
                return true;
              }
              return false;
            },
            validateCheckConfirm() {
              if (this.forgotPasswordFormConfirm) {
                return true;
              }
              return false;
            }
        }
    }

</script>

<style scoped>



</style>
