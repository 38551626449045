import gql from 'graphql-tag'

export default gql`subscription onPCPMessage($email: String!) {
    onPCPMessage(email: $email) {
        pcp_message {
            _facility
            _id
            _time
            _visit
            _reference_id
            accident_date_time
            date_created
            confirmed_time
            event_type_code
        }
    }
}`