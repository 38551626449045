<template>

    <div>
        <!-- Title first row -->
        <section-title :title="`Change Password`"></section-title>

        <section-content>

            <template v-slot:section-content>

                <v-row class="full-width">
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Current Password</div>
                        <v-text-field
                          light solo dense flat outlined
                          v-model="passwordDataLocal.oldPassword"
                          :append-icon="passwordDataLocal.showOldPassword ? 'visibility' : 'visibility_off'"
                          :rules="[rules.required]"
                          :type="passwordDataLocal.showOldPassword ? 'text' : 'password'"
                          :tabindex="1"
                          @click:append="passwordDataLocal.showOldPassword = !passwordDataLocal.showOldPassword"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="full-width">
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">New Password</div>
                        <v-text-field
                          light solo dense flat outlined
                          v-model="passwordDataLocal.newPassword"
                          @input="changePasswordForm.validate()"
                          :append-icon="passwordDataLocal.showNewPassword ? 'visibility' : 'visibility_off'"
                          :rules="[rules.required, rules.min, rules.passwordMatch]"
                          :type="passwordDataLocal.showNewPassword ? 'text' : 'password'"
                          :tabindex="1"
                          @click:append="passwordDataLocal.showNewPassword = !passwordDataLocal.showNewPassword"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Confirm New Password</div>
                        <v-text-field
                          light solo dense flat outlined
                          v-model="passwordDataLocal.newPasswordConfirm"
                          @input="changePasswordForm.validate()"
                          :append-icon="passwordDataLocal.showNewPasswordConfirm ? 'visibility' : 'visibility_off'"
                          :rules="[rules.required, rules.min, rules.passwordMatch]"
                          :type="passwordDataLocal.showNewPasswordConfirm ? 'text' : 'password'"
                          :tabindex="1"
                          @click:append="passwordDataLocal.showNewPasswordConfirm = !passwordDataLocal.showNewPasswordConfirm"
                        ></v-text-field>
                    </v-col>
                </v-row>

            </template>

        </section-content>
    </div>

</template>

<script>

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import {mapActions, mapState} from "vuex";

    export default {
        name: 'editProfileEditPassword',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent
        },
        apollo: {
            $client: 'phoenixClient'
        },
        props: {
            passwordData: Object,
            changePasswordForm: Object
        },
        data() {
            return {
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v && v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    },
                    email: email => {
                      const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(email) || 'Invalid email';
                    },
                    passwordMatch: password => {
                      return this.passwordDataLocal.newPassword === this.passwordDataLocal.newPasswordConfirm || 'New passwords do not match';
                    }
                }
            }
        },
        created() {},
        mounted() {

        },
        activated() {},
        watch: {

        },
        methods: {

        },
        computed: {
            passwordDataLocal: {
                get: function() {
                    return this.passwordData;
                },
                set: function(newValue) {
                    this.$emit('update:passwordData', newValue)
                }
            },
        }
    }

</script>

<style scoped>

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
