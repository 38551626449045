<template>

  <div class="view">

    <i-card :viewIndex="card.index"
            :viewCount="1"
            :leftPanel="card.panels.left"
            :rightPanel="card.panels.right"
            :topPanel="card.panels.top"
            :bottomPanel="card.panels.bottom"
            class="i-card mr-2 br-2">

      <div class="i-card__main pa-2 pr-0 pt-0">

        <div class="i-card__main__header">

          <div class="d-flex align-center flex-grow-1 ml-2">

            <v-tooltip bottom
                       nudge-right="72"
                       nudge-bottom="6"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       color="ycmd-black"
                       content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn dark icon depressed v-on="on"
                       @click="() => { messages = []; loadedData = false;changeView({ view: 'list-view', params: {data: changed ? voicemail : null, startingId: params.id} }); }">
                  <v-icon :size="22">arrow_back</v-icon>
                </v-btn>
              </template>
              <span>Back To List</span>
            </v-tooltip>
            <div class="white--text fs-16 ml-4">
              Voicemail Details
            </div>
          </div>

          <v-tooltip bottom
                     nudge-bottom="5"
                     :disabled="$vuetify.breakpoint.xsOnly"
                     color="ycmd-black"
                     content-class="fade-0 pa-4 pt-2 pb-2">
            <template v-slot:activator="{ on }">
              <v-btn icon dark depressed
                     v-on="on"
                     @click="openAudioPlayer"
                     class="mr-3">
                <v-icon :size="22">play_circle_outline</v-icon>
              </v-btn>
            </template>
            <span>Play</span>
          </v-tooltip>
<!--          <v-tooltip bottom-->
<!--                     nudge-bottom="5"-->
<!--                     :disabled="$vuetify.breakpoint.xsOnly"-->
<!--                     color="ycmd-black"-->
<!--                     content-class="fade-0 pa-4 pt-2 pb-2">-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-btn icon dark depressed v-on="on" class="mr-3">-->
<!--                <v-icon :size="22" @click="">restore</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>Restore</span>-->
<!--          </v-tooltip>-->
        </div>

        <div class="i-card__main__content white pa-2 br-2">

          <div class="i-card__scrollable">

            <template v-if="voicemail">

              <!-- Title first row -->
              <section-title :title="`Caller`">

                <template v-slot:end-content>
                    <span
                      class="unobtrusive-on-white"
                    >id: {{voicemail.contact_id}}</span>&nbsp;

                    <span>{{voicemail.date_created ? format(parseISO(voicemail.date_created), 'iii MMM d, yyyy h:mm a') : 'N/A'}}</span>
                </template>
              </section-title>

              <section-content>

                <template v-slot:section-content>

                  <v-col cols="12" sm="6" md="3">
                    <div class="label-title pt-4">Name</div>
                    <div class="label-value pt-2 pb-4">
                      {{ (voicemail.spelled_first_name) || voicemail.spelled_last_name ?
                      `${voicemail.spelled_first_name} ${voicemail.spelled_last_name}` : 'N/A' }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <div class="label-title pt-4">Callback Phone</div>
                    <div :class="{'label-value': true,
                                  'pt-2': true,
                                  'pb-4': true,
                                  'text-decoration-underline' : voicemail.callback_phone_sms}"
                        @click="openSendSMS()"
                        :style="{'cursor': voicemail.callback_phone_sms ? 'pointer' : 'default'}"
                        >

                      {{ voicemail.callback_phone ? formatPhoneNumber(voicemail.callback_phone) : 'N/A' }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <div class="label-title pt-4">From Phone</div>
                    <div class="label-value pt-2 pb-4">
                      {{ voicemail.from_phone ? formatPhoneNumber(voicemail.from_phone) : 'N/A' }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <div class="label-title pt-4">Birth Date</div>
                    <div class="label-value pt-2 pb-4">
                      {{ voicemail.birth_date ? voicemail.birth_date : 'N/A' }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-if="showAddress">
                    <div class="label-title pt-4">Address</div>
                    <div class="label-value pt-2 pb-4">
                      {{ voicemail.address_street ? voicemail.address_street : 'N/A' }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-if="showAddress">
                    <div class="label-title pt-4">City</div>
                    <div class="label-value pt-2 pb-4">
                      {{ voicemail.address_city ? voicemail.address_city : 'N/A' }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-if="showAddress">
                    <div class="label-title pt-4">State</div>
                    <div class="label-value pt-2 pb-4">
                      {{ voicemail.address_state ? voicemail.address_state : 'N/A' }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-if="showAddress">
                    <div class="label-title pt-4">Zipcode</div>
                    <div class="label-value pt-2 pb-4">
                      {{ voicemail.address_zipcode ? voicemail.address_zipcode : 'N/A' }}
                    </div>
                  </v-col>

                </template>

              </section-content>

              <!-- Title 2nd row -->
              <section-title class="pt-2"
                             :title="`Transcription`"
                             :endContent="`${ voicemail.transcribe_date ? format(parseISO(voicemail.transcribe_date), 'iii MMM d, yyyy h:mm a') : 'N/A' }`"
                             />

              <section-content class="pl-2">

                <template v-slot:section-content>

                  <v-col cols="12">
                    <div class="label-value pt-3 pb-2">
                      <v-tooltip bottom
                                 nudge-bottom="5"
                                 :disabled="$vuetify.breakpoint.xsOnly"
                                 color="ycmd-black"
                                 content-class="fade-0 pa-4 pt-2 pb-2">
                        <template v-slot:activator="{ on }">
                          <v-btn icon light depressed
                                 v-on="on"
                                 class="mr-1"
                                 :loading="loadingUrl"
                                 @click="openAudioPlayer">
                            <v-icon :size="22" color="ycmd-blue">play_circle_outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Play</span>
                      </v-tooltip>
                      {{ voicemail.transcript ? voicemail.transcript : 'N/A' }}
                    </div>
                  </v-col>
                  <div>
                    <div class="label-title pt-4 pl-2">Language</div>
                    <div :class="{'label-value': true,
                                  'pt-2': true,
                                  'pb-4': true,
                                  'pl-2': true
                                  }"
                    >
                      {{ voicemailLanguage }}
                    </div>
                  </div>

                </template>
              </section-content>

             <section-title class="pt-2"
                             :title="`History`"
              >
                <template v-slot:end-content>
                  <v-tooltip bottom
                            nudge-left="26"
                            nudge-bottom="0"
                            :disabled="$vuetify.breakpoint.xsOnly"
                            color="ycmd-black"
                            content-class="fade-0 pa-4 pt-2 pb-2">
                      <template v-slot:activator="{ on }">
                          <v-btn icon dark depressed v-on="on" @click="startAddNote()">
                              <v-icon :size="22" color="ycmd-blue">note_add</v-icon>
                          </v-btn>
                      </template>
                      <span>Add Note</span>
                  </v-tooltip>
                </template>
             </section-title>

              <section-content class="pl-2">

                <template v-slot:section-content>
                  <list-history
                    :voicemail="voicemail"
                    />
                </template>

              </section-content>

            </template>

            <template v-else>
              <div class="d-flex align-center justify-center full-width full-height" >
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </i-card>

    <template v-if="voicemail">

      <i-audio-player :audioPath="audioPath"
                      :title="voicemail.fromPhone"
                      :subtitle="voicemail.dateCreated" />
    </template>


    <voicemail-sms 
        v-model="sms"
        :voicemail="voicemail"
        v-on:changed="onVoicemailChange" 
      />

    <voicemail-note 
        v-model="note"
        :voicemail="voicemail"
        v-on:changed="onVoicemailChange" 
        />

  </div>

</template>

<script>

  import {format, parseISO} from 'date-fns';
  import {formatPhoneNumber} from '@/methods/globalMethods';
  import SectionTitle from '@/components/sections/section-title';
  import SectionContent from '@/components/sections/section-content';

  import GET_VOICEMAIL_BY_ID from '@/graphql/queries/phoenix/ycmdVoicemailGetById';
  import GET_VOICEMAIL_AUDIO from '@/graphql/queries/phoenix/ycmdVoicemailAudioGet';
  import VoicemailHistory from "./list-history";
  import VoicemailSMS from '@/components/modals/voicemail-sms';
  import VoicemailNote from '@/components/modals/voicemail-note';
  
  export default {
    name: 'voicemailDetail',
    components: {
      'section-title': SectionTitle,
      'section-content': SectionContent,
      'list-history': VoicemailHistory,
      'voicemail-sms': VoicemailSMS,
      'voicemail-note': VoicemailNote
    },
    apollo: {
      $client: 'phoenixClient'
    },
    props: {
      changeView: Function,
      params: {
        type: Object
      }
    },
    data() {
      return {
        format,
        parseISO,
        formatPhoneNumber,
        card: {
          index: 0,
          panels: {}
        },
        voicemail: undefined,
        audioPath: '',
        fromPhone: '',
        dateCreated: '',
        loadingUrl: false,
        sms: false,
        note: false,
        changed: false
      }
    },
    activated() {
      //console.log('CONTACT_ID', this.params.id);
      this.getVoicemail(this.params.id);
    },
    mounted() {},
    computed: {
       showAddress() {
         if (this.voicemail) {
          if (this.voicemail.address_street || this.voicemail.address_city || this.voicemail.address_city || this.voicemail.address_state || this.voicemail.address_zipcode) {
            return true
          }
         }
         return false
       },
       voicemailLanguage () {
         if (this.voicemail) {
          switch (this.voicemail.language) {
            case 'es/us':
              return 'Spanish'
            default:
              return 'English'
          }
         }
       }
    },
    methods: {
      onVoicemailChange (voicemail) {
        this.changed = true
        this.voicemail = voicemail
      },
      openSendSMS() {
        if (this.voicemail.callback_phone && this.voicemail.callback_phone_sms) {
            this.sms = true
        }
      },
      startAddNote() {
          this.note = true
      },
      async getVoicemail(id) {

        try {
          const response = await this.$apollo.query({
            query: GET_VOICEMAIL_BY_ID,
            variables: {
              id: id
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          });

          //console.log(response);

          // check errors
          if (response) {

            if (response.errors) {

              throw response.errors;
            }
            else {

              const { voicemail } = response.data.ycmdVoicemailGet;

              if (voicemail.length === 1) {
                this.changed = false
                this.voicemail = voicemail[0];

                this.voicemail.spelled_first_name = this.properCase(this.voicemail.spelled_first_name)
                this.voicemail.spelled_last_name = this.properCase(this.voicemail.spelled_last_name)
                
                this.fromPhone = formatPhoneNumber(voicemail.from_phone);
                this.dateCreated = voicemail.date_created ? format(parseISO(voicemail.date_created), 'iii MMM d, yyyy h:mm a') : 'NA';

              }
              else {

                if (voicemail.length > 1)
                  throw `Data error. Cannot have more than one record return for voicemail details.`;
                else
                  throw `Data error. No record found for the contact_id: ${ this.params.id }`;
              }
            }
          }
          else
            throw `No respones returned when getting audio url`;
        }
        catch (e) {
          console.log(e);
        }
      },
      async openAudioPlayer() {
        if(this.voicemail) {
          this.loadingUrl = true

          const { contact_id, from_phone, date_created } = this.voicemail;

          const url = await this.getAudioUrl(contact_id);

          this.fromPhone = formatPhoneNumber(from_phone);
          this.dateCreated = format(parseISO(date_created), 'iii MMM d, yyyy h:mm a');
          this.audioPath = url;
          this.loadingUrl = false
        }
      },
      async getAudioUrl(contactId) {

        try {
          const response = await this.$apollo.query({
            query: GET_VOICEMAIL_AUDIO,
            variables: {
              contactId: contactId
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          });

          // check errors
          if(response) {

            if (response.errors) {
              throw response.errors;
            }
            else {
              const { ycmdVoicemailAudioGet: url } = response.data;

              return url;
            }
          }
          else
            throw "No respones returned when getting audio url";
        }
        catch(e) {
          console.log(e);
        }
      },
      properCase (str) {
        if (str) {
            str = str.charAt(0).toUpperCase() + str.slice(1);
        }
        return str
      }
    }
  }
</script>

<style scoped>

</style>
