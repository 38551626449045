<template>

    <div >
        
        <v-select flat 
            v-model="selectedReason"
            :label="label"
            :items="reasons"
            item-text="description"
            item-value="id"
            :background-color="backgroundColor"
            :outlined="outlined"
            class="emr-reason"
            :rules="rules"
        >            
            <template v-slot:selectionXX="data">
                <div style="">{{data.item.description}}</div>
            </template>        
        </v-select>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'emrReasonSelect',
        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },  
            value: {
                default: '',
                type: String
            },                       
            providerId: {
                default: '',
                type: String
            }, 
            locationId: {
                default: '',
                type: String
            }, 
            patientType: {
                default: 'existing',
                type: String
            }, 
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            label: {
                default: 'Reason',
                type: String
            },
        },
        data: () => ({
            //selectedReason: ''
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
        },
        methods: {
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getPractices']
            ),      
            providerMap () {
                let obj = {}
                this.getProviders.map(provider => {
                    obj[provider.id] = provider
                })
                return obj
            },

            practiceMap () {
                let obj = {}
                this.getPractices.map(practice => {
                    obj[practice.id] = practice
                })
                return obj
            },
            reasons () {
                if (!this.providerId || !this.locationId) {
                    return []
                }

                let provider = this.providerMap[this.providerId]

                if (!this.practiceId) {
                    this.practiceId = provider.practiceId
                }

                if (!provider) {
                    return []
                }

                let practice = this.practiceMap[this.practiceId]
                console.log('provider', provider)
                console.log('practice', practice)
                console.log('this.locationId', this.locationId)
                console.log('this.patientType', this.patientType)

                let referentialReason = practice.referential.find(r => {
                    return r.key = 'reason'
                })
                let referentialMap = {}
                if (referentialReason && referentialReason.value && referentialReason.value.length) {
                    referentialReason.value.map(rr => {
                        referentialMap[rr.id] = rr
                    })
                }

                let locationReasons = provider.locationReasons[`location|${this.locationId}`]

                let reasons = []
                if (locationReasons) {
                    locationReasons.map(lr => {
                        if (lr.patientType === this.patientType || lr.patientType === 'all') {
                            reasons.push({
                                id: lr.reasonId,
                                description: lr.reasonDescription,
                                name: lr.reasonName
                            })

                        }
                    })
                }

                return reasons
            },
            selectedReason: {
                get: function() {
                    return this.value ;
                },
                set: function(newValue) {
                    this.$emit('input', newValue)
                }
            }, 
        }
    }
</script>
<style scoped>

div.emr-reason >>> div.v-select__selection {
    text-wrap: wrap
}

</style>