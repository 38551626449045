<template>

  <div class="view">

    <div class="view">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 ycmd-medium=blue">

        <div class="i-card__main">

          <div class="i-card__main__header" style="height: 56px">

            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width white--text pl-3 pr-3" style="height:56px">
                <div>
                  <v-btn icon dark elevation="0" @click="changeView({view: 'chat-edit', transition: 'prev', params: {room_id}})">
                    <v-icon size="18">close</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-column flex-grow-1 pl-2 pr-2 fs-12">
                  ROOM SETTINGS
                </div>

<!--                
                <div class="d-flex align-center full-height pl-3 ml-1" style="border-left: solid 2px #125ea3">
                  <v-tooltip top
                             nudge-top="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-1 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon dark depressed
                          v-on="on"
                          class="ma-0"
                          @click="isRightMenuExpanded ? collapseRightMenu() : expandRightMenu()">
                        <v-icon dark size="20">{{ isRightMenuExpanded ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-no-wrap">{{ isRightMenuExpanded ? 'Minimize Chat' : 'Maximize Chat' }}</span>
                  </v-tooltip>
                </div>
-->                
              </div>
            </div>

          </div>

          <div class="i-card__main__content white"  >

            <div class="i-card__scrollable white--text">

              <div class="d-flex flex-column pt-4 pl-4 ycmd-medium-blue">

                <div class="d-flex align-center pa-2">
                  <div class="fade-2 fs-12">NOTIFICATIONS</div>
                </div>

<!--                <v-switch flat dark color="ycmd-light-blue" v-model="switch1" label="Notify me only when I am OnCall" />-->
<!--                <v-switch flat dark color="ycmd-light-blue" v-model="switch2" label="Notify me only when I am Online" />-->
<!--                <v-switch flat dark color="ycmd-light-blue" v-model="switch3" label="Notify me only on Direct Chats" />-->

<!--                <div class="d-flex align-center pa-2 pt-4">-->
<!--                  <div class="fade-2 fs-12">CONTACT</div>-->
<!--                </div>-->
<!--                <v-switch flat dark color="ycmd-light-blue" v-model="switch4" label="Enable Direct Messaging" />-->
<!--                <v-switch flat dark color="ycmd-light-blue" v-model="switch5" label="Enable Direct Calling" />-->

<!--                <div class="d-flex align-center pa-2 pt-4">-->
<!--                  <div class="fade-2 fs-12">PRIVACY</div>-->
<!--                </div>-->
<!--                <v-switch flat dark color="ycmd-light-blue" v-model="switch4" label="Show Offline when Not OnCall" />-->
              </div>
              <div class="d-flex flex-row pt-4 pl-4">
                <v-switch  
                          color="ycmd-blue"
                          v-model="enableNotifications"
                          label="Enable Notifications for this room" />
              </div>              
            </div>
          </div>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

  import CHANGE_CHAT_SETTINGS from "@/graphql/mutations/phoenix/ycmdUserSettings";
  import GET_CHAT_ROOM from "@/graphql/queries/phoenix/ycmdChatRoomGet";

  import dataClient from '@/graphql/clients/axios';

  export default {
    name: 'chatSettingsList',
    props: {
      changeView: Function,
      params: {
          data: Object
      }
    },
    data() {
      return {
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        transition: 'fade',
        enableNotifications: true,
        rooms: [],
        room_id: ''
      }
    },
    apollo: {
      $client: 'phoenixClient',
/*      
      ycmdChatRoomGet: {
        query: GET_CHAT_ROOM,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        skip: false,
        variables() {
          return {
            room_id: this.params.room_id,
            search_objects: ['room'],
            limit: 1
          }
        },
        result({data}) {
          console.log('this.params.room_id', this.params.room_id)

          if (data) {

            const {rooms} = data.ycmdChatRoomGet;

          }

          this.loading = false;
        },
        error(e) {
          console.error('ERROR: In ycmdChatPostGet Query', e)
        }
      }
      */
    },
    mounted() {
      let params = {}
      this.$emit('initialized', 'chat-settings', this.event)

      this.setEnableNotifications(this.params.room_id)
    },
    methods: {
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu',]),
      ...mapActions('profile', ['setChatRoomNotifications']),
      setEnableNotifications(room_id) {
        console.log('room_id', room_id)
        this.room_id = room_id

        this.rooms = this.getChatRoomNotifications;
        console.log('this.rooms', this.rooms)

        //console.log("ROOM_NOTIFICATIONS: ", this.getChatRoomNotifications);

        const room = this.rooms.filter((n) => n.room_id === room_id)

        //console.log("SETTINGS: ", room);

        if(room && room.length > 0)
          this.enableNotifications = room[0].settings.enable_chat_notifications;

      },
      async loadRoom (room_id) {
          this.loading = true

          let response = await dataClient('ycmdChatRoomGet', 
            {room_id: room_id,
            search_objects: ['room'],
            limit: 1          
            }
          )

          this.room = response.rooms[0];
          this.originalRoomName = this.room.name
          this.roomName = this.originalRoomName;
          this.loading = false
      },

      event (name, params) {

        console.log('name', name, params)
        switch (name) {
          case 'load':

            break
        }
      }, 
      async changeChatSettings(params) {

        const { enable } = params;

        //console.log("ENABLING: ", enable)

        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: CHANGE_CHAT_SETTINGS,
          variables: {
            room_settings: {
              room_id: this.room_id,
              settings: {
                enable_chat_notifications: enable
              }
            }
          },
          update: (cache, { data: { ycmdUserSettings } }) => {
            try {

              //console.log("DATA: ", ycmdUserSettings);

              const { room_settings } = ycmdUserSettings;

              // console.log("BEFORE: ", this.rooms)
              // console.log("CHECKING: ", this.rooms.filter((n) => n.room_id === this.currentRoom.id)[0].settings.enable_chat_notifications)

              this.rooms = [...this.rooms.filter((n) => n.room_id !== this.currentRoom.id), ...room_settings];

              // console.log("AFTER: ", this.rooms)
              // console.log("CHECKING: ", this.rooms.filter((n) => n.room_id === this.currentRoom.id)[0].settings.enable_chat_notifications)

              this.setChatRoomNotifications(this.rooms);
            }
            catch (e) {
              console.error(e);
            }
          }
        });
      }
    },
    computed: {
      ...mapState('rightMenu', ['isRightMenuExpanded']),
      ...mapState('profile', ['settings']),
      //...mapState('chat', ['currentRoom']),
      ...mapGetters('profile', ['getChatRoomNotifications'])
    },
    watch: {
      enableNotifications (enable) {

        this.changeChatSettings({enable})
      }
    },
  }
</script>