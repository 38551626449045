<template>

    <div class="view">
        <div class="d-flex flex-column flex-grow-1 relative">

            <transition :name="transition">

                <keep-alive >
                    <component v-bind:is="view_name" :changeView="changeView" :params="view_params" @initialized="componentInitialized"/>
                </keep-alive>

            </transition>
        </div>
    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import Schedule from '@/views/apps/scheduling-new/redirect.vue'    

    export default {
        name: 'messages',
        components: {
            schedule: Schedule
        },
        data() {
            return {
                view_name: 'schedule',
                view_params: {},
                transition: 'next',
                callbacks: {}
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            componentInitialized(name, callback) {
                console.log('name', name)
                console.log('callback', callback)

                this.callbacks[name] = callback
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            changeView({view, transition, params}) {
                console.log(`changeview ${view} transition: ${transition} params`, params)
                if (transition) {
                    this.transition = transition
                } else if (params.transition) {
                    this.transition = params.transition                    
                }
                
                //this.sendEvent(this.view.name, 'unload') not needed
                this.view_name = view;
                this.view_params = params || {};
                this.sendEvent(this.view_name, 'load', params)
            },
            sendEvent (view, event, params) {
                let callback = this.callbacks[view]

                if (callback) {
                    callback(event, params)
                }
            }
        },
        computed: {
        }        
    }

</script>

<style scoped>


</style>
