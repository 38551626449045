<template>

  <div :id="`id_${attachment_id}`" class="d-flex flex-column justify-center align-center full-height" style="max-height: inherit">

    <template v-if="loading">
      <div class="d-flex align-center justify-center full-height"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
    </template>

    <template v-else>

      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else-if="contentType=='YCMDMessage'">

        <div class="pa-4">
          <div class="d-flex full-width ycmd-medium-gray br-2">
            <div>
              <v-icon dark size="48">person</v-icon>
            </div>
            <div class="d-flex justify-space-around flex-column pl-4">
              <div>
                <span>{{ msg_attachment.patient_name }}</span>
                <span class="fade-2 pl-2">({{ msg_attachment.patient_dob }})</span>
              </div>
              <div class="fade-2">
                {{ msg_attachment.facility_name }}
              </div>
            </div>
          </div>
        </div>

      </template>
      <template v-else>

        <object :data="dataurl" style="object-fit: contain; max-width: 100%; max-height: inherit" class="pl-4 pr-4" />

        <div class="d-flex full-width align-center ycmd-light-grey pt-2 pb-2"

        >
          <div v-if="name" class="full-width text-center fs-12 fade-2 ">
            Filename: <div class="ycmd-blue--text text-decoration-underline pointer"> {{name}}
            <v-icon color="primary" size="24" :class="iconPadding">file_download</v-icon>
            <v-icon color="primary" size="24" :class="iconPadding">launch</v-icon>
            </div>

          </div>


          <slot />
        </div>

      </template>

    </template>

<!--    <span class="red&#45;&#45;text" v-if="view=='error'">{{errorMessage}}</span>-->

  </div>

</template>

<script>
import { mapGetters  } from "vuex";
import GET_CHAT_ATTACHMENT from '@/graphql/queries/phoenix/ycmdChatAttachmentGet';

export default {
  props: {
    attachment_id: {
      default: '',
      type: String
    },
    room_id: {
      default: '',
      type: String
    },
    view:  {
      default: '',
      type: String
    },
  },
  data: () => ({
    loading: true,
    dataurl:'',
    errorMessage: '',
    contentType: '',
    name: '',
    retrievedAttachment: null,
    msg_attachment: {

    }
  }),
  computed: {
      ...mapGetters(
          'chat', ['getCachedAttachment'],
      ),
      iconPadding () {
        if (this.view === 'full') {
          return 'pl-5'
        }
        return 'pl-1'
      }
  },

  async mounted() {

    let cached = this.getCachedAttachment(this.attachment_id)

    if (cached) {
      this.name = cached.name       
      this.contentType = cached.contentType

      if (cached.contentType === 'YCMDMessage') {
        this.msg_attachment = cached.attachment
      } else {
        this.dataurl = cached.attachment
      }
      this.loading = false;
      console.log('cached', cached)
      this.$emit('loaded', {attachment_id: this.attachment_id, room_id: cached.room_id})


      return
    }

    const response = await this.getAttachment();

    this.errorMessage = '';

    if (response) {

      const { content_type, data, id, meta, num_pages } = response.data.ycmdChatAttachmentGet;

      let attachment = data;

      if (num_pages > 1) {

        let promises = []

        for (let i = 2; i <= num_pages; i++) {
          promises.push(this.getAttachment(i))
        }

        if (promises.length) {

          let rest_of_attachment = await Promise.all(promises)

          rest_of_attachment.map(a => {
            attachment += a.data.ycmdChatAttachmentGet.data
          })
        }
      }

      let metaname = meta.find(m => m.name === 'name')

      this.name = metaname ? metaname.value: '';
      this.contentType = content_type
      
      if (this.contentType === 'YCMDMessage') {
        meta.map(m => {
          this.msg_attachment[m.name] = m.value
        })
      }

      this.dataurl = `data:${content_type};base64,${attachment}`
      this.$emit('loaded', {attachment_id: this.attachment_id, room_id: this.room_id})

      this.$store.commit('chat/cacheAttachment', {
        attachment_id: this.attachment_id, 
        contentType: this.contentType, 
        attachment: this.contentType === 'YCMDMessage' ? this.msg_attachment: this.dataurl,
        name: this.name,
        room_id: this.room_id
      })
    }
    else {
      this.errorMessage = `Attachment ID: ${ this.attachment_id } could not be loaded`
    }

    this.loading = false;
  },
  methods: {
    getAttachment(page = null) {

      let params = {
        room_id: this.room_id,
        attachment_id: this.attachment_id,
      };

      if (page !== null) {
        params["page"] = page;
      }

      return this.$apollo.query({
        client: 'phoenixClient',
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        query: GET_CHAT_ATTACHMENT,
        variables: params
      });
    },
  },
  /*
  watch: {
    loading(loading) {

      //console.log("loading: ", loading)

      if(!loading) {
        if(this.onLoaded)
          this.onLoaded()
      }
    }
  }
  */
}
</script>
