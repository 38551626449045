import gql from 'graphql-tag'

export default gql`
    subscription onChatActivity($user_id: ID){
      onChatActivity(user_id: $user_id) {
        user_id
        activity {
          activity_date
          first_name
          last_name
          type
          user_id
          post {
            date_created
            first_name
            id
            last_name
            text
            type
            system_type
            user_id
            attachment_id
            key
            reply_to_id
            reply_to_user {
                id
                name
            }
            reply_to_text
            reactions {
              reaction_key
              reaction_users {
                  id
                  name
              }
            }
          }
          room {
            id
            created_by_id
            date_created
            date_updated
            last_post_id
            last_post_time
            name
            room_type
            participants{
              date_joined
              date_left
              date_last_seen
              last_post_seen
              user_id
              group_id
              group_name
              first_name
              last_name
              creator
              title
              specialty
              online_status
              status
              ooo
              app_version
            }
            tags
            meta {
              name
              value
            }
          }
        }
      }
    }`
