// Initial state
import {logout} from "@/methods/globalMethods";

const state = {
  idleTimer: false,
  idleTimeout: 15,
  idleReminders: [10],
  idleWait: 0,
  idleDialog: false,
  idleTimeRemaining: 0,
  idleInterval: {},
  idleValue: 0,
};

const getters = {
  fullscreen: state => state.fullscreen
};

const actions = {

  startTimer: ({state, commit}, isFullscreen) => {

    commit('setFullscreen', isFullscreen)
  },
  stopTimer: ({state, commit}, isFullscreen) => {

    commit('setFullscreen', isFullscreen)
  },
  extendSession: ({state, commit}, isFullscreen) => {

    commit('setFullscreen', isFullscreen)
  },
  onRemind: ({state, commit}, {time}) => {

    console.log("TIME REMAINING: ", time);

    commit('setIdleDialog', true)
    commit('setIdleTimer', false)

    // commit('setIdleInterval', setInterval(() => {
    //   if (state.idleValue === state.idleReminders[0]) {
    //     commit('clearIdleTimer', state.idleInterval);
    //     commit('setIdleDialog', false)
    //     commit('setIdleTimer', false);
    //     commit('setIdleValue', 0);
    //     logout();
    //   }
    //   commit('setIdleValue', state.idleValue+1);
    // }, 1000))
  }
};

const mutations = {

  setIdleDialog (state, dialog) {
    state.idleDialog = dialog;
  },
  setIdleTimer (state, timer) {
    state.idleTimer = timer;
  },
  setIdleValue (state, value) {
    state.idleValue = value;
  },
  setIdleInterval (state, interval) {
    state.setInterval = interval;
  },
  clearIdleTimer (state) {
    clearInterval(state.idleInterval);
  },
  resetState (state) {
    state.idleTimer = false
    state.idleTimeout = 15
    state.idleReminders = [10]
    state.idleWait = 0
    state.idleDialog = false
    state.idleTimeRemaining = 0
    state.idleInterval = {}
    state.idleValue = 0
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
