import gql from 'graphql-tag'

export default gql`mutation ycmdReportScheduleDelete(
	$schedule_id: ID
	) {
		ycmdReportScheduleDelete(
			schedule_id: $schedule_id
		)
		{
			status,
			data
		}
}`
