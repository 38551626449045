import _ from "lodash";
// TODO: Remove after prototyping
// import groups from '@/data/mock/groups'
// selected: { group_name: 'All Groups', group_id: '-1', user_id: '-1' }

const state = {
    myGroup: {},
    selected: {},
    list: [],
    adminList: [],
};

const getters = {
  selected: state => state.selected,
  list: state => state.list,
  myGroup: state => state.myGroup
};

const actions = {
    initGroups: ({commit}, info) => {
        const {
            myGroup,
            groups,
            myUser,
            linkedUsers
        } = info;
        // Set my group
        commit('setMyGroup', myGroup)
        // Populate from database after prototyping
        commit('setList', { groups: [myGroup, ...groups], linkedUsers: [myUser, ...linkedUsers] })
        // set selected
        commit('changeGroup', myGroup)
    },
    changeGroup: ({commit}, _id) => commit('changeGroup', group)
};

const mutations = {
    setList(state, {groups, linkedUsers}) {
        state.list = groups;
        // set admin list
        const merged = _.merge(_.keyBy(groups, 'user_id'), _.keyBy(linkedUsers, 'id'));
        const mergedUsers = _.values(merged);
        const adminLinkedUsers = mergedUsers.filter((user) => {
            return user.roles && user.roles.length && user.roles.find(role => role === 'group');
        })
        console.log('INFO: Admin linked users - ', adminLinkedUsers);
        state.adminList = adminLinkedUsers;
    },
    setMyGroup(state, group) {
        state.myGroup = group;
    },
    changeGroup(state, group) {
        state.selected = group;
    },
    resetState(state) {
        state.myGroup = {}
        state.selected = {}
        state.list = []
        state.adminList = []
    }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
