import gql from 'graphql-tag'

export default gql`mutation
ycmdGroupUpdate(
	$address: AddressInput,
	$mail_address: AddressInput,
	$name: String,
	$alt_name: String,
	$group_type: GroupType,
	$entity_type: GroupEntityType,
	$time_zone: TimeZone,
	$greeting: String,
	$phone: String,
	$lunch_phone: String,
	$fax: String,
	$website: String,
	$facilities: [ID],
	$patient_tags: [PatientTagInput],
	$settings: GroupSettingsInput,
	$hours: [OfficeHourInput],
	$forwarding: GroupForwardingTimetableInput
	)
{
	ycmdGroupUpdate(
		address: $address,
		mail_address: $mail_address,
		name: $name,
		alt_name: $alt_name,
		group_type: $group_type,
		entity_type: $entity_type,
		time_zone: $time_zone,
		greeting: $greeting,
		phone: $phone,
		lunch_phone: $lunch_phone,
		fax: $fax,
		website: $website,
		facilities: $facilities,
		patient_tags: $patient_tags,
		settings: $settings,
		hours: $hours,
		forwarding: $forwarding
		)
			{
				status
			}
}`
