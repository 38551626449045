import gql from 'graphql-tag'

export default gql`query ycmdContactGet($search: String, $page: Int, $limit: Int, $sort_field: ContactSortField, $sort_direction: SortDirection, $is_favorite: Boolean, $contact_types: [ContactType]) {
	ycmdContactGet(search: $search, page: $page, limit: $limit, sort_field: $sort_field, sort_direction: $sort_direction, is_favorite: $is_favorite, contact_types: $contact_types) {
		total_count
		contacts {
			type
			is_favorite
			... on ContactCustom {
				id
				type
				is_favorite
				company
				email_addresses {
				  address
				  label
				}
				first_name
				group_id
				group_name
				last_name
				notes
				phone_numbers {
					extension
					label
					number
				}
				specialty
				addresses {
					city
					label
					state
					street
					street2
					zip_code
				}
			}
			... on ContactFacility {
			  id
			  type
			  is_favorite
			  facility_id
			  facility_name
			  in_group
			  address
			  units {
				  name
				  shortcode
				  number
				  new_patients
				  deleted
				  unit_id
			  }
			}
			... on ContactProvider {
				id
				type
				is_favorite
				can_chat
				user_id
				first_name
				last_name
				label
				specialty
				group_id
				group_name
				phone
				ycmd_phone
				device_phone
			}
		}
    }
}`
