<template>

    <div class="i-card__panel i-card__panel__right">

        <div class="i-card__panel__header br-2">

            <div class="d-flex align-center flex-grow-1">

                <v-tooltip bottom
                           nudge-bottom="8"
                           nudge-right="40"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on" @click="closeAdvancedSearch" class="ml-2 mr-2">
                            <v-icon :size="20">close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close Search</span>
                </v-tooltip>

                <div class="white--text fs-14 ml-3">
                    Advanced Search
                </div>
            </div>
            <div class="d-flex align-center justify-end ml-2">

              <v-btn dark small text
                     @click="clearSearch"
                     class="ycmd-light-blue ml-2 mr-2">
                Clear
              </v-btn>

                <v-btn dark small text
                       @click="submitAdvancedSearch({ shouldRefresh: true, closePanel: true });"
                       class="ycmd-light-blue mr-1">
                    Search
                </v-btn>

            </div>
        </div>

        <div class="i-card__panel__content white br-2 pa-2">

            <div class="i-card__scrollable">

                    <section-title :title="`Search Fields`"/>

                    <section-content>

                        <template v-slot:section-content>

                            <div>
                                <v-row
                                    
                                >
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">Transferred By</div>

                                        <user-picker
                                            v-model="advancedSearchLocal.transferred_by_id"
                                            label="Transferred By"
                                            :roles="['provider']"
                                            :include_searching_user="true" 
                                            :exclude_group_filter="true"
                                            :single="true"
                                        />                                       
                                    
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">To Facility</div>
                                      <ycmd-facility-search :shouldIncludeFacilities="false" :facilitySearchSelected.sync="advancedSearchLocal.to_facility_id" :initialValue="advancedSearchLocal.to_facility_id" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">From Facility</div>
                                      <ycmd-facility-search :shouldIncludeFacilities="false" :facilitySearchSelected.sync="advancedSearchLocal.from_facility_id" :initialValue="advancedSearchLocal.from_facility_id"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">Message Date</div>
                                      <date-range-picker
                                        v-model="advancedSearch.message_date"
                                        :includeCustomIcon="false"
                                        :key="`drp_${refresh}`"
                                        :datetype.sync="advancedSearch.datetype"
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4">
                                        <div class="label-title pb-2">&nbsp;</div>
                                        <v-btn
                                            @click="openSchedule = true"
                                            class="white--text ycmd-light-blue"
                                        >
                                            Schedule
                                        </v-btn>
                                    </v-col>

                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"
                                >
                                    <v-col
                                        cols="12"
                                        class="pa-0 ma-0"
                                    >
                                      <div class="label-title pb-2">Schedules</div>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"                                    
                                >
                                    <v-col cols="12"
                                    class="pa-0 ma-0"
                                    >
                                        <schedules
                                            :schedules="patientTransferSchedules"
                                             @deleteSchedule="deleteSchedule"
                                            height="200px"
                                        />
                                    </v-col>
                                </v-row>
                            </div>

                        </template>

                    </section-content>

            </div>

        </div>
        <schedule v-model="openSchedule" @save="saveSchedule"
            :key="`schedule_${refreshSchedule}`"
        />
        <confirmation
            v-model="confirmScheduleDelete"
            title="Delete Schedule"
            subtitle="Confirm Schedule Delete"
            text="Are you sure you want to delete the schedule?"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { format, parseISO, startOfDay, endOfDay, startOfMonth, endOfMonth, startOfWeek, endOfWeek, subDays } from 'date-fns';
    import { convertMillitaryTimeToAmPm } from '@/methods/utils'

    import {refreshHandler} from '@/graphql/handler/refreshHandler';
    import { ycmdProviderRoles, ycmdPatientStatus, ycmdMessageConfirmedStatus } from '@/data/communications/constants';

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import YCMDFacilitySearch from '@/components/searches/ycmd-facility';
    import userPicker from '@/components/searches/user-picker';
    import DateRangePicker from '@/components/datetime/date-range-picker';

    import schedule from '@/components/modals/schedule'
    import ycmdReportScheduleCreate from '@/graphql/mutations/phoenix/ycmdReportScheduleCreate';
    import ycmdReportScheduleDelete from '@/graphql/mutations/phoenix/ycmdReportScheduleDelete';
    import Schedules from '@/components/schedules';
    import Confirmation from '@/components/modals/confirmation.vue'

    export default {
        name: 'messagesAdvancedSearch',
        components: {
            'ycmd-facility-search': YCMDFacilitySearch,
            'user-picker': userPicker,
            'section-title': SectionTitle,
            'section-content': SectionContent,
            'date-range-picker': DateRangePicker,
            schedule,
            'schedules': Schedules,
            confirmation: Confirmation
        },
        props: {
            advancedSearch: Object,
            clearAdvancedSearch: Function,
            submitAdvancedSearch: Function
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                convertMillitaryTimeToAmPm,
                ycmdProviderRoles,
                ycmdPatientStatus,
                ycmdMessageConfirmedStatus,
                dialog: false,
                openSchedule: false,
                confirmScheduleDelete: false,
                scheduleDeleteId: null,
                sending: false,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                timePickerModels: {
                    start: {
                        display: false
                    },
                    end: {
                        display: false
                    }
                },
                messageDateRange: [
                    {
                        name: 'Display All',
                        id: null,
                        start_date: null,
                        end_date: null
                    },
                    {
                        name: 'Last Week',
                        id: 'Last Week',
                        start_date: startOfWeek(subDays(new Date(), 7)),
                        end_date: endOfWeek(subDays(new Date(), 7))
                    },
                    {
                        name: 'This Week',
                        id: 'This Week',
                        start_date: startOfWeek(new Date()),
                        end_date: endOfWeek(new Date())
                    },
                    {
                        name: 'Yesterday',
                        id: 'Yesterday',
                        start_date: subDays(startOfDay(new Date()), 1),
                        end_date: subDays(endOfDay(new Date()), 1)
                    },
                    {
                        name: 'This Month',
                        id: 'This Month',
                        start_date: startOfMonth(startOfDay(new Date())),
                        end_date: endOfMonth(startOfDay(new Date()))
                    },
                    {
                        name: 'Custom Range',
                        id: 'Custom Range',
                        start_date: null,
                        end_date: null
                    }
                ],
                validAdvancedSearchForm: false,
                refresh: 0,
                refreshSchedule: 0
            }
        },
        activated() {

        },
        created() {
        },
        mounted() {

        },
        computed: {
            ...mapGetters('profile', ['getLegacyRoles']),
            ...mapGetters('report', ['getReportDefinitions']),
            confirmationButtons () {
                return [
                    {caption: 'OK', disabled: this.sending},
                    {caption: 'Cancel', disabled: this.sending}
                ]
            },
            patientTransferSchedules () {
                if (this.getReportDefinitions && this.getReportDefinitions.length) {
                    let def = this.getReportDefinitions.find(d => {
                        return d.id === 'patient_transfers'
                    })
                    if (def) {
                        return def.schedules
                    }
                }
                return []
            },
            legacyRoleMap () {
                let map = {}
                let roles = this.getLegacyRoles
                console.log('roles', roles)
                if (roles && roles.length) {
                    roles.map(r => {
                        map[r] = true
                    })
                }
                return map
            },
            nurseView () {
                if (this.legacyRoleMap['provider']) {
                    return false
                }
                if (this.legacyRoleMap['member']) {
                    return false
                }
                if (this.legacyRoleMap['nurse']) {
                    return true
                }

                return false

            },
            advancedSearchLocal: {
                get: function(val) {
                    return this.advancedSearch;
                },
                set: function(newValue) {
                    this.$emit('update:advancedSearch', newValue)
                }
            },
            customTimeStart: {
                get: function() {
                    return this.advancedSearch.customTimeStart
                },
                set: function(val) {
                    this.advancedSearchLocal.customTimeStart = val;
                }
            },
            customTimeEnd: {
                get: function() {
                    return this.advancedSearch.customTimeEnd
                },
                set: function(val) {
                    this.advancedSearchLocal.customTimeEnd = val;
                }
            },
            customDateRange: {
                get: function() {
                    return this.advancedSearch.customDateRange
                },
                set: function(val) {
                    let dates = Array.from(val);
                    const sortedDates = dates.sort((a, b) => {
                        return (a < b) ? -1 : ((a > b) ? 1 : 0);
                    });
                    const sortedDatesRange = {
                        start_date: (sortedDates.length === 1 || sortedDates[0] === sortedDates[1]) ? startOfDay(new Date(sortedDates[0].replace(/-/g, '\/'))) : (startOfDay(new Date(sortedDates[0].replace(/-/g, '\/'))) || null),
                        end_date: (sortedDates.length === 1 || sortedDates[0] === sortedDates[1]) ? endOfDay(new Date(sortedDates[0].replace(/-/g, '\/'))) : (endOfDay(new Date(sortedDates[1].replace(/-/g, '\/'))) || null)
                    }
                    // messageDateRangeSelected will keep format for sending to API
                    this.advancedSearch.messageDateRangeSelected = {
                        name: 'Custom Range',
                        ...sortedDatesRange
                    }
                    console.log('setting custom range: ', sortedDatesRange);
                    // customDateRange will keep format for v-date-picker
                    this.advancedSearchLocal.customDateRange = dates;
                }
            }
        },
        watch: {
            dialog(val) {
                if (val === false) {
                    // the date picker modal was closed
                    if (this.customDateRange && this.customDateRange.length) {
                        // if we selected the first date but not the last
                        if (this.customDateRange[0] && !this.customDateRange[1]) {
                            this.customDateRange = [this.customDateRange[0], this.customDateRange[0]]
                        }
                    }
                }
            }
        },
        methods: {
            async confirmationClicked (btn) {
                if (btn.caption === 'OK') {

                    this.sending = true
                    let response = await this.$apollo.mutate({
                        mutation: ycmdReportScheduleDelete,
                        variables: {schedule_id: this.scheduleDeleteId},
                        client: 'phoenixClient',
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    })

                    console.log('response', response)
                    if (response && response.errors && response.errors.length) {
                        this.$toasted.error(`Error: ${response.errors[0].message}`);
                    } else {
                        await this.$store.commit('report/removeSchedule',  
                        {
                            report_id: 'patient_transfers',
                            schedule_id: this.scheduleDeleteId
                        })
                        this.$toasted.success('Schedule deleted');
                    }
                    this.sending = false
                }
                this.confirmScheduleDelete = false
            },
            deleteSchedule (schedule_id) {
                this.scheduleDeleteId = schedule_id
                this.confirmScheduleDelete = true
            },
            async saveSchedule (schedule) {
                let scheduleObject = {
                    report_id: 'patient_transfers',
                    schedule_repeat: schedule.repeat,
                    dow: schedule.dow,
                    dom: schedule.dom,
                    time: schedule.time,
                    parameters: [],
                    format: 'xls',
                    emails: schedule.emails
                }

                if (this.advancedSearchLocal) {
                    if (this.advancedSearchLocal.transferred_by_id) {
                        //: ID, : ID, message_date: AWSDateTime, message_end_date: AWSDateTime
                        scheduleObject.parameters.push({
                            id: 'transferred_by_id',
                            value: this.advancedSearchLocal.transferred_by_id
                        })
                    }
                    if (this.advancedSearchLocal.to_facility_id) {
                        scheduleObject.parameters.push({
                            id: 'to_facility_id',
                            value: this.advancedSearchLocal.to_facility_id
                        })
                    }
                    if (this.advancedSearchLocal.from_facility_id) {
                        scheduleObject.parameters.push({
                            id: 'from_facility_id',
                            value: this.advancedSearchLocal.from_facility_id
                        })
                    }
                    if (this.advancedSearchLocal.message_date) {
                        scheduleObject.parameters.push({
                            id: 'message_date',
                            value: this.advancedSearchLocal.message_date
                        })
                    }
                }

                let response = await this.$apollo.mutate({
                    mutation: ycmdReportScheduleCreate,
                    variables: {schedule: scheduleObject},
                    client: 'phoenixClient',
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                })

                console.log('response', response)

                if (response && response.data && response.data.ycmdReportScheduleCreate) {
                    await this.$store.commit('report/addSchedule',  
                    {
                        report_id: 'patient_transfers',
                        schedule: JSON.parse(response.data.ycmdReportScheduleCreate.data)
                    })
                    this.$toasted.success('Schedule saved');
                    this.refreshSchedule++

                } else {
                        this.$toasted.error(`An error has occurred`);
                }

            },
            route(name, transition) {

              this.$router.push({
                name: name, params: {
                  transition: transition
                }
              }).catch(err => { console.log('router error: ', err) });
            },
            formatCustomDate(input) {
                // converts yy/mm/dd to mm/dd/yy
                const array = (input || '').toString().split(/\-/g);
                array.push(array.shift());
                return array.join('-') || null;
            },
            clearSearch() {
                this.refresh++
                this.clearAdvancedSearch();
                this.submitAdvancedSearch({ shouldRefresh: false, closePanel: false });
            },
            closeAdvancedSearch() {
                // update on parent
                this.submitAdvancedSearch({ shouldRefresh: false, closePanel: true });
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            }
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
