import gql from 'graphql-tag'

export default gql`mutation authenticateContinue($email: String!, $challengeName: String!, $session: String!, $mfCode: String, $new_password: String) {
	authenticateContinue(email: $email, challengeName: $challengeName, session: $session, mfCode: $mfCode, new_password: $new_password) {
    ycmdAuthenticate {
		accessToken
		tokenType
		refreshToken
		idToken
		roles {
			role
			role_label
			group {
				id
				name
			}
			facilities {
				id
				name
			}
		}
		first_name
		last_name
    }
    phoenixAuthenticate {
		first_name
		last_name
		accepted_terms_of_service
		is_device_registered
		token
		user_id
		link_id
		linked_users {
			id
			roles
		}
		linked_groups {
			user_id
			group_id
			group_name
		}
		provider_id
		member_id
		nurse_id
		pcp_user_id
		pcp_analyst_id
		roles
		legacy_roles
		refresh_token
		group_id
		group_name
		group {
			voicemail {
				mailboxes {
					name
				}
			}
			forwarding {
				days
				start_time
				end_time
			},
			settings {
				chat_allow_same_participant_rooms
				explicit_message_confirmation
				explicit_message_confirmation_can_override  				
			}
		}
		reference_id
    }
  }
}`
