import dataClient from '@/graphql/clients/axios';
import { differenceInMinutes, addMinutes } from 'date-fns'
import {utcToZonedTime} from 'date-fns-tz';
import {timezoneIsoDate} from '@/methods/formatters';

// Initial state
const state = {
  providers: [],
  practices: [],
  loadedProviders: false,
  providerPalettes: [
    {bg: '#e41a1c', fg: '#ffffff' },
    {bg: '#4daf4a', fg: '#ffffff' },
    {bg: '#984ea3', fg: '#ffffff' },
    {bg: '#ff7f00', fg: '#ffffff' },
    {bg: '#ffff33', fg: '#000000' },
    {bg: '#a65628', fg: '#ffffff' },
    {bg: '#f781bf', fg: '#ffffff' },
    {bg: '#999999', fg: '#ffffff' },
    {bg: '#377eb8', fg: '#ffffff' },

    {bg: '#fbb4ae', fg: '#000000' },
    {bg: '#b3cde3', fg: '#000000' },
    {bg: '#ccebc5', fg: '#000000' },
    {bg: '#decbe4', fg: '#000000' },
    {bg: '#fed9a6', fg: '#000000' },
    {bg: '#ffffcc', fg: '#000000' },
    {bg: '#e5d8bd', fg: '#000000' },
    {bg: '#fddaec', fg: '#000000' },
    {bg: '#f2f2f2', fg: '#000000' },

    {bg: '#a6cee3', fg: '#ffffff' },
    {bg: '#1f78b4', fg: '#ffffff' },
    {bg: '#b2df8a', fg: '#ffffff' },
    {bg: '#33a02c', fg: '#ffffff' },
    {bg: '#fb9a99', fg: '#ffffff' },
    {bg: '#e31a1c', fg: '#ffffff' },
    {bg: '#fdbf6f', fg: '#ffffff' },
    {bg: '#ff7f00', fg: '#ffffff' },
    {bg: '#cab2d6', fg: '#ffffff' },

    {bg: '#8dd3c7', fg: '#000000' },
    {bg: '#ffffb3', fg: '#000000' },
    {bg: '#bebada', fg: '#000000' },
    {bg: '#fb8072', fg: '#000000' },
    {bg: '#80b1d3', fg: '#000000' },
    {bg: '#fdb462', fg: '#000000' },
    {bg: '#b3de69', fg: '#000000' },
    {bg: '#fccde5', fg: '#000000' },
    {bg: '#d9d9d9', fg: '#000000' },
  ],
  paletteAssignments: []
};

const getters = {
  getProviders: state => state.providers,
  getPractices: state => state.practices,  
  getloadedProviders: state => state.loadedProviders,
  getProviderPalette: (state) => (id) => {

    let palette = state.paletteAssignments[id]

    if (!palette) {
      let count = 0
      for (let c in state.paletteAssignments) {
        count++
      }

      let index = count % state.providerPalettes.length //if we go past the end, we cycle around again

      //technically should do a mutation 
      palette = state.providerPalettes[index]
      state.paletteAssignments[id] = palette
    }

    return palette
  },  
  getProviderPaletteFg: (state, getters) => (id) => {
    let palette = getters.getProviderPalette(id)

    return palette.fg
  },    
  getProviderPaletteBg: (state, getters) => (id) => {
    let palette = getters.getProviderPalette(id)

    return palette.bg
  },    
  getProviderById: (state) => (id) => {
    if (state.providers && state.providers.length) {
        let provider = state.providers.find(r => {
            return r.id === id
        })
        if (provider) {
            return provider
        }                                                  
    }
    return null
  },
  getPracticeById: (state) => (id) => {
    if (state.practices && state.practices.length) {
        let practice = state.practices.find(r => {
            return r.id === id
        })
        if (practice) {
            return practice
        }                                                  
    }
    return null
  },
  getPracticeByProviderId: (state, getters) => (id) => {
    let provider = getters.getProviderById(id)

    if (provider) {
      let practice = getters.getPracticeById(provider.practiceId)
      return practice
    }
    return null
  },
  getPracticePropertyByProviderId: (state, getters) => (id, property) => {
    let provider = getters.getProviderById(id)

    if (provider) {
      let practice = getters.getPracticeById(provider.practiceId)

      if (practice && practice.properties && practice.properties.length) {

        let prop = practice.properties.find(p => {
          return p.id === property
        })
        if (prop) {
          return prop.value
        }
      }
    }
    return null
  },  
  getTimezoneByProviderId: (state, getters) => (id) => {
    let practice = getters.getPracticeByProviderId(id)

    if (practice) {
      let timezone = practice.locations[0].timezone
      return timezone
    }
    return null
  },

  getReferential: (state, getters) => (practiceId, referentialKey, key) => {
    let practice = getters.getPracticeById(practiceId)

    if (practice) {
      let referential = practice.referential.find(r => {
        return r.key === referentialKey
      })
      if (referential && referential.value && referential.value.length) {
          let value = referential.value.find(r => {
            return r.id === key
          })
          if (value) {
            return value.name
          }
      }
    }
    return null
  },

  findAvailablePeriods: (state, getters) => (providerId, date) => {
    let provider = getters.getProviderById(providerId)

    let ret = []
    if (provider && provider.availabilities && provider.availabilities.length) {

      let practice = getters.getPracticeByProviderId(providerId)
      if (practice) {
        let timezone = practice.locations[0].timezone

        let dow = date.getDay() + ''

        provider.availabilities.map(a => {
          if (a.weekday == dow) {
            let localPieces = a.startlocal.split(':')
            let convertedDate = getters.timezoneDate(date, localPieces[0], localPieces[1], 0, timezone)
            let endDate = addMinutes(convertedDate, a.duration)
            if (date >= convertedDate && date <= endDate) {
              ret.push(a)
            }

          }
        })
      }
    }
    return ret
  },  
  timezoneDate: (state) => (date, hours, minutes, seconds, timezone) => {
    let iso = timezoneIsoDate(date, hours, minutes, seconds, timezone)
    return new Date(iso)
  },
};

const actions = {
  loadProviders: async ({commit, getters, dispatch}, payload) => {

    if (getters.getloadedProviders) {
      return getters.getProviders
    }

    let params = {
      operation: 'provider_get'
    }
    
    let result = await dataClient('emrGet', params)

    result.providers.map(p => {
      let lr = {}
      if (p.locationReasons && p.locationReasons.length) {
        p.locationReasons.map(reason => {
          if (!lr[reason.locationId]) {
            lr[reason.locationId] = []
          }
          lr[reason.locationId].push(reason)
        })
      }
      p.locationReasons = lr
    })
    //console.log('result.providers', result.providers)

    commit('setProviders', result.providers)
    commit('setPractices', result.practices)
    commit('setLoadedProviders', true)

    return result
  },
  createPatient: async ({commit, getters, dispatch}, patient) => {
    let parameters = []
    for (let prop in patient) {

      let val = patient[prop]
      if (val) {
        if (typeof(val) !== 'string') {
          val = JSON.stringify(val)
        }  
      }

      parameters.push({
        id: prop,
        value: val
      })
    }
    let params = {
      operation: 'patient_create',
      parameters
    }

    let result = await dataClient('emrOperation', params)
    console.log('result', result)

    if (result.error) {
      return result
    }

    return result.patients &&  result.patients.length ? result.patients[0]: null 
  },
  checkAppointment: async ({commit, getters, dispatch}, body) => {
    let parameters = []
    for (let prop in body) {
      parameters.push({
        id: prop,
        value: body[prop]
      })
    }

    let params = {
      operation: 'appointment_check',
      parameters
    }

    let result = await dataClient('emrGet', params)
    console.log('result', result)
    
    if (result.error) {
      return result
    }

    return result

  },
  saveAppointment: async ({commit, getters, dispatch}, appointment) => {
    let parameters = []
    for (let prop in appointment) {
      parameters.push({
        id: prop,
        value: appointment[prop]
      })
    }
    let params = {
      operation: 'appointment_save',
      parameters
    }

    let result = await dataClient('emrOperation', params)
    console.log('result', result)
    
    if (result.error) {
      return result
    }

    return result.appointments &&  result.appointments.length ? result.appointments[0]: null 
  },
  cancelAppointment: async ({commit, getters, dispatch}, appointment) => {

    let parameters = []
    for (let prop in appointment) {
      parameters.push({
        id: prop,
        value: appointment[prop]
      })
    }
  
    let params = {
      operation: 'appointment_cancel',
      parameters
    }

    let result = await dataClient('emrOperation', params)
    console.log('result', result)
    
    //if (result.error) {
      return result
    //}

    //return result.appointments &&  result.appointments.length ? result.appointments[0].id: null 
  },
  getUnavailablePeriods: ({commit, getters, dispatch}, payload) => {

    //getUnavailable (availabilities, timezone, day, provider, practice) {

      let provider = getters['getProviderById'](payload.providerId)

      let practice = getters['getPracticeById'](provider.practiceId)
      let timezone = practice.locations[0].timezone

      let dow = payload.day.getDay()

      let availabilities = {}
      
      if (provider.availabilities && provider.availabilities.length) {
          provider.availabilities.map(avail => {
              let avails = availabilities[avail.weekday+'']

              if (!avails) {
                  availabilities[avail.weekday+''] = []
                  avails = availabilities[avail.weekday+'']
              }
              avails.push(avail)
          })
      }

      let ret = []

      let dayAvailabilities = availabilities[dow+'']
      if (!dayAvailabilities) {

          let start = new Date( getters.timezoneDate(payload.day, 0, 0, 0, timezone))
          let end = addMinutes(start, 24 * 60)

          ret.push({
                eventType: 'unavailable_time',
                datetimeutc: start,
                duration: 24 * 60,
                end: end,
                providerid: payload.providerId,
                start: start,
          })         
      } else {
          let startTime = payload.day
          let hours = 0
          let minutes = 0
          let temp = []
          for (let i=0;i<dayAvailabilities.length;i++) {
              let pieces = dayAvailabilities[i].startlocal.split(':')
              hours = parseInt(pieces[0])
              minutes = parseInt(pieces[1])

              let start = getters.timezoneDate(startTime, hours, minutes, 0, timezone)

              let end = addMinutes(start, dayAvailabilities[i].duration)

              temp.push({
                  start,
                  end,
                  hours,
                  minutes,
                  availability: dayAvailabilities[i]
              })
          }

          let sorted = temp.sort((a, b) => {
              return (a.start < b.start) ? -1 : ((a.start > b.start) ? 1 : 0)
          });

          for (let i=1;i<sorted.length;i++) {

              let first = sorted[i-1]
              let second = sorted[i]

              let start = first.end
              let end = second.start

              let duration = differenceInMinutes(end, start)

              ret.push(
                  {
                      eventType: 'unavailable_time',
                      datetimeutc: start,
                      duration: duration,
                      end: end,
                      providerid: payload.providerId,
                      start: start,
                  }
              )
          }  
          
          if (payload.includeEndpoints) {

            let sortedDay = dayAvailabilities.sort((a, b) => {
              return (a.startlocal < b.startlocal) ? -1 : ((a.startlocal > b.startlocal) ? 1 : 0)
            });

            let start = new Date( getters.timezoneDate(payload.day, 0, 0, 0, timezone)  )

            let pieces = sortedDay[0].startlocal.split(':')
            hours = parseInt(pieces[0])
            minutes = parseInt(pieces[1])

            let end = getters.timezoneDate(payload.day, hours, minutes, 0, timezone)

            ret.unshift({
              eventType: 'unavailable_time',
              datetimeutc: start,
              duration: differenceInMinutes(end, start),
              end: end,
              providerid: payload.providerId,
              start: start,
            })

            let pieces2 = sortedDay[sortedDay.length-1].startlocal.split(':')
            hours = parseInt(pieces2[0])
            minutes = parseInt(pieces2[1])

            let start2 = getters.timezoneDate(payload.day, hours, minutes, 0, timezone)
            let start3 = addMinutes(start2, sortedDay[sortedDay.length-1].duration)

            let end2 = getters.timezoneDate(payload.day, 0, 0, 0, timezone)
            let end3 = addMinutes(end2, 24 * 60)

            ret.unshift({
              eventType: 'unavailable_time',
              datetimeutc: start3,
              duration: differenceInMinutes(end3, start3),
              end: end3,
              providerid: payload.providerId,
              start: start3,
            })

          }

      }

      return ret
  }

};

const mutations = {

  resetState (state) {
    state.providers = []
    state.practices = []
    state.loadedProviders = false
    state.paletteAssignments = []
  },
  setProviders (state, data) {
    state.providers = data;
  },
  setPractices (state, data) {
    state.practices = data;
  },  

  setLoadedProviders (state, data) {
    state.loadedProviders = data;
  },
  clearPalette (state, data) {
    state.paletteAssignments = []
  }  

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
