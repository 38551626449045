<template>

    <div class="list-container full-height full-width"
        v-if="history && history.length"
    >


        <v-row no-gutters>
            <v-col cols="2">
                <div class="label-title ml-3"
                >Time</div>
            </v-col>
            <v-col cols="3">
                <div class="label-title ml-2"
                >User
                </div>
            </v-col>
            <v-col cols="2">
                <div class="label-title" 
                >
                Activity                    
                </div>
            </v-col>
            <v-col cols="5">
                <div class="label-title"                                        
                    >Note
                </div>
            </v-col>
        </v-row>

        <v-container fluid
                     v-for="(item) in history"
                     :key="`${item.type}_${item.timestamp}`"
                     :class="`list-item flex-shrink-0 pa-3 pt-2 pb-2 relative`"
                     style="border-style: none"
                     >

            <div class="d-flex flex-grow-1">

                <div class="d-flex flex-grow-1 flex-column">

                    <v-row no-gutters>

                        <v-col cols="2">
                            <div class="list-item__column">
                                <div class="label-value">
                                    {{format(parseISO(item.timestamp), 'MM-dd-yyyy h:mm a') }}
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="3">
                            <div class="list-item__column">
                                <div class="label-value">
                                    {{ item.first_name }} {{ item.last_name }}
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="2">
                            <div class="list-item__column">
                                <div class="label-value">
                                        {{ typeName(item.type)  }}
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="5">
                            <div class="list-item__column">
                                <div class="label-value">
                                        {{ historyValue(item) }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-container>
    </div>

</template>

<script>
    import { format, parseISO } from 'date-fns';

    export default {
        props: {
            voicemail: {
                type: Object
            }
        },
        components: {
        },
        data: () => ({
            format,
            parseISO,
        }),
        mounted() {},
        methods: {
            properCase (str) {
                if (str) {
                    str = str.charAt(0).toUpperCase() + str.slice(1);
                }
                return str
            },
            typeName (type) {
                switch (type) {
                    default: 
                        return this.properCase(type)
                }
            },
            historyValue (item) {
                switch (item.type) {
                    case 'status': 
                        switch (item.value) {
                            case 'new':
                                return 'New call'
                            case 'left_message':
                                return "Left a message"
                            case 'sent_text':
                                return 'Sent a Text Message'
                            case 'waiting':
                                return 'Waiting on patient'
                            case 'complete':
                                return 'Call complete'

                        }
                    default:
                        return item.value
                }
            }
        },
        computed: {
            history() {
                let arr = []
                let keys = {}
                if (this.voicemail && this.voicemail.history) {
                    this.voicemail.history.map( h => {
                        console.log('h', h)
                        let key = JSON.stringify(h)

                        if (!keys[key]) {
                            keys[key] = true
                            arr.push(h)
                        }
                    })
                }
                return arr
            }
        },
    }
</script>
