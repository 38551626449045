import gql from 'graphql-tag'

export default gql`mutation ycmdContactUpdate(
		$custom_contact_id: ID,
		$specialty: String,
		$first_name: String,
		$notes: String,
		$phone_numbers: [PhonenumberInput],
		$company: String,
		$addresses: [AddressInput],
		$last_name: String,
		$email_addresses: [EmailAddressInput]
	) {
		ycmdContactUpdate(
			custom_contact_id: $custom_contact_id,
			specialty: $specialty,
			first_name: $first_name,
			notes: $notes,
			phone_numbers: $phone_numbers,
			company: $company,
			addresses: $addresses,
			last_name: $last_name,
			email_addresses: $email_addresses
		) {
			... on ContactCustom {
				id
				addresses {
					label
					street
					street2
					city
					state
					zip_code
				}
				company
				email_addresses {
					address
					label
				}
				first_name
				last_name
				group_id
				group_name
				notes
				phone_numbers {
					label
					number
					extension
				}
				specialty
			}
		}
}`
