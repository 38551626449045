<template>

    <div v-if="item">
        <v-card
          outlined
        >
            <template v-if="isEditable" class="">
                <v-row dense class="pa-4 pb-0" align="center" justify="center">
                    <v-col cols="12" sm="6" md="6" class="">
                        <v-text-field light solo dense flat outlined
                                      v-model="customContactData.first_name"
                                      :label="`First Name - ${itemLocal.first_name || 'Not Provided'}`" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="">
                        <v-text-field light solo dense flat outlined
                                    v-model="customContactData.last_name"
                                    :label="`Last Name - ${itemLocal.last_name || 'Not Provided'}`" />
                    </v-col>
                </v-row>
                <v-row dense class="pl-4 pr-4 pb-2 pt-0" align="center" justify="center">
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field light solo dense flat outlined hide-details
                                    v-model="customContactData.specialty"
                                    :label="`Specialty - ${itemLocal.specialty || 'Not Provided'}`" />
                    </v-col>
                </v-row>
                <v-row dense class="pl-4 pr-4 pb-2 pt-0" align="center" justify="center">
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field light solo dense flat outlined hide-details
                                    v-model="customContactData.company"
                                    :label="`Company - ${itemLocal.company || 'Not Provided'}`" />
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-card-title class="d-flex justify-center">
                    <v-icon
                        large
                        left
                        :color="'#28C96D'"
                    >
                        contacts
                    </v-icon>
                    <span class="title font-weight-light">{{ (customContactData.first_name || customContactData.last_name) ? `${customContactData.first_name} ${customContactData.last_name}` : 'N/A' }}</span>
                </v-card-title>
                <v-card-subtitle v-if="customContactData.specialty" class="d-flex justify-center pb-0">{{ customContactData.specialty || 'No Specialty Provided' }}</v-card-subtitle>
                <v-card-subtitle v-if="customContactData.company" class="d-flex justify-center mt-0 pt-0">{{ customContactData.company || 'No Company Provided' }}</v-card-subtitle>
            </template>
        </v-card>

        <v-card
        class="mt-2"
        outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline">
                      {{ itemLocal.type || 'N/A' }}
                  </div>
                  <!-- notes -->
                  <v-list-item-title class="headline mt-2">
                      Notes
                  </v-list-item-title>
                  <template v-if="isEditable">
                      <v-row no-gutters class="">
                          <v-col cols="12" sm="6" md="6">
                              <v-text-field light solo dense flat outlined hide-details
                                            v-model="customContactData.notes"
                                            :label="`Notes - ${itemLocal.notes || 'Not Provided'}`"
                                            class="" />
                          </v-col>
                      </v-row>
                  </template>
                  <v-list-item-subtitle v-else>{{ customContactData.notes || 'Not Available' }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-card-actions class="">
            <v-tooltip bottom
                 nudge-bottom="0"
                 nudge-right="0"
                 color="ycmd-black"
                 content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark large rounded
                         @click="onContactDelete(item)"
                         v-on="on"
                         color="red">
                      <v-icon color="red" :size="30">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
            </v-tooltip>
            <v-tooltip bottom
               nudge-bottom="0"
               nudge-right="0"
               color="ycmd-black"
               content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn icon dark rounded large
                       @click="toggleContactFavorite(item)"
                       v-on="on"
                       class=""
                       :color="`${itemLocal.is_favorite ? '#FFCC00' : 'grey'}`">
                    <v-icon :size="30">star</v-icon>
                </v-btn>
              </template>
              <span>{{ itemLocal.is_favorite ? 'Remove Favorite' : 'Favorite' }}</span>
            </v-tooltip>

            <v-spacer />

            <v-tooltip bottom
                 nudge-bottom="0"
                 nudge-right="0"
                 color="ycmd-black"
                 content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark rounded large
                         @click="onToggleEdit"
                         v-on="on"
                         color="ycmd-blue">
                      <v-icon :size="30">{{ isEditable ? 'close' : 'edit' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ isEditable ? 'Cancel Edit' : 'Edit' }}</span>
            </v-tooltip>
            <v-tooltip bottom
                 v-if="isEditable"
                 nudge-bottom="0"
                 nudge-right="0"
                 color="ycmd-black"
                 content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark rounded large
                         @click="onContactEditSave"
                         v-on="on"
                         color="ycmd-blue">
                      <v-icon :size="30">save</v-icon>
                  </v-btn>
                </template>
                <span>Save</span>
            </v-tooltip>
            </v-card-actions>
        </v-card>
        <!-- phone numbers -->
        <v-card
            v-if="customContactData.phone_numbers.length || isEditable"
            class="mt-2"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline d-flex justify-center align-center">
                        Phone Numbers
                        <v-spacer />
                        <v-tooltip bottom
                             v-if="isEditable"
                             nudge-bottom="0"
                             nudge-right="0"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark rounded large
                                    @click="customContactData.phone_numbers.push({
                                        label: null,
                                        number: null,
                                        extension: null
                                    })"
                                    v-on="on"
                                    color="ycmd-blue">
                                    <v-icon :size="30">add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Phone</span>
                        </v-tooltip>
                    </div>
                    <template v-for="(currentPhone, i) in customContactData.phone_numbers">
                        <v-list-item-title class="headline mt-4">
                            <template v-if="isEditable">
                                <div class="d-flex align-center">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="currentPhone.label"
                                                  :label="`Label - ${currentPhone.label || 'Not Provided'}`"
                                                  class="red"
                                                  style="max-width: 200px;" />
                                    <v-tooltip bottom
                                       v-if="isEditable"
                                       nudge-bottom="0"
                                       nudge-right="0"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                      <template v-slot:activator="{ on }">
                                          <v-btn icon dark rounded large
                                              @click="customContactData.phone_numbers.splice(i, 1)"
                                              v-on="on"
                                              color="red">
                                              <v-icon :size="24">delete_forever</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Delete Phone</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-else>
                                {{ currentPhone.label || 'No Label' }}
                            </template>
                        </v-list-item-title>
                        <template v-if="isEditable">
                            <v-row dense class="mt-4 d-flex align-center">
                                <v-col cols="12" sm="8" md="6" lg="6">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="currentPhone.number"
                                                  v-mask="masks.phone"
                                                  :label="`Phone - ${currentPhone.number ? formatPhoneNumber(currentPhone.number) : 'Not Provided'}`"
                                                  class="" />
                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="4">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="currentPhone.extension"
                                                  :label="`Extension - ${currentPhone.extension || 'Not Provided'}`"
                                                  class="" />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            <v-list-item-subtitle>{{ currentPhone.number ? (currentPhone.extension ? `${formatPhoneNumber(currentPhone.number)} | Ext. ${currentPhone.extension}` : formatPhoneNumber(currentPhone.number)) : 'No Number' }}</v-list-item-subtitle>
                        </template>
                    </template>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <!-- addresses numbers -->
        <v-card
            v-if="customContactData.addresses.length || isEditable"
            class="mt-2"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline d-flex justify-center align-center">
                        Addresses
                        <v-spacer />
                        <v-tooltip bottom
                             v-if="isEditable"
                             nudge-bottom="0"
                             nudge-right="0"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark rounded large
                                    @click="customContactData.addresses.push({
                                        label: null,
                                        street: null,
                                        street2: null,
                                        city: null,
                                        state: null,
                                        zip_code: null
                                    })"
                                    v-on="on"
                                    color="ycmd-blue">
                                    <v-icon :size="30">add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Address</span>
                        </v-tooltip>
                    </div>
                    <template v-for="(addressLocal, i) in customContactData.addresses">
                        <template v-if="isEditable">
                            <v-row dense class="mt-4 d-flex align-center">
                                <v-col cols="6" sm="6" md="4" lg="4">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="addressLocal.label"
                                                  :label="`Label ${i+1} - ${addressLocal.label || 'Not Provided'}`" />
                                </v-col>
                                <v-col cols="6" sm="6" md="4" lg="4">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="addressLocal.street"
                                                  :label="`Street - ${addressLocal.street || 'Not Provided'}`" />
                                </v-col>
                                <v-col cols="6" sm="6" md="4" lg="4">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="addressLocal.street2"
                                                  :label="`Street Cont.. - ${addressLocal.street2 || 'Not Provided'}`" />
                                </v-col>
                                <v-col cols="6" sm="6" md="4" lg="3">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="addressLocal.city"
                                                  :label="`City - ${addressLocal.city || 'Not Provided'}`" />
                                </v-col>
                                <v-col cols="6" sm="6" md="4" lg="3">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="addressLocal.state"
                                                  :label="`State - ${addressLocal.state || 'Not Provided'}`" />
                                </v-col>
                                <v-col cols="6" sm="3" md="3" lg="3">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="addressLocal.zip_code"
                                                  :label="`Zipcode - ${addressLocal.zip_code || 'Not Provided'}`" />
                                </v-col>
                                <v-tooltip bottom
                                   v-if="isEditable"
                                   nudge-bottom="0"
                                   nudge-right="0"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                  <template v-slot:activator="{ on }">
                                      <v-btn icon dark rounded large
                                          @click="customContactData.addresses.splice(i, 1)"
                                          v-on="on"
                                          color="red">
                                          <v-icon :size="24">delete_forever</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Delete Address</span>
                                </v-tooltip>
                            </v-row>
                        </template>
                        <template v-else>
                            <v-list-item-title class="headline mb-1 mt-2">
                                {{ addressLocal.label || 'No Label' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ addressLocal.street || 'No Street Provided' }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="addressLocal.street2">{{ addressLocal.street2 }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="addressLocal.city || addressLocal.state || addressLocal.zip_code">{{ addressLocal.city || '' }}{{ `, ${addressLocal.state}` || '' }}{{ ` ${addressLocal.zip_code}` || '' }}</v-list-item-subtitle>
                        </template>
                    </template>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <!-- emails -->
        <v-card
            v-if="customContactData.email_addresses.length || isEditable"
            class="mt-2"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline d-flex justify-center align-center">
                        Email
                        <v-spacer />
                        <v-tooltip bottom
                             v-if="isEditable"
                             nudge-bottom="0"
                             nudge-right="0"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark rounded large
                                    @click="customContactData.email_addresses.push({
                                        label: null,
                                        address: null
                                    })"
                                    v-on="on"
                                    color="ycmd-blue">
                                    <v-icon :size="30">add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Address</span>
                        </v-tooltip>
                    </div>
                    <template v-for="(emailLocal, i) in customContactData.email_addresses">
                        <template v-if="isEditable">
                            <v-row dense class="mt-4 d-flex align-center">
                                <v-col cols="6" sm="4" md="4" lg="4">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="customContactData.email_addresses[i].label"
                                                  :label="`Label ${i+1} - ${emailLocal.label || 'Not Provided'}`" />
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6">
                                    <v-text-field light solo dense flat outlined hide-details
                                                  v-model="customContactData.email_addresses[i].address"
                                                  :label="`Email ${i+1} - ${emailLocal.address || 'Not Provided'}`" />
                                </v-col>
                                <v-tooltip bottom
                                   v-if="isEditable"
                                   nudge-bottom="0"
                                   nudge-right="0"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                  <template v-slot:activator="{ on }">
                                      <v-btn icon dark rounded large
                                          @click="customContactData.email_addresses.splice(i, 1)"
                                          v-on="on"
                                          color="red">
                                          <v-icon :size="24">delete_forever</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Delete Email</span>
                                </v-tooltip>
                            </v-row>
                        </template>
                        <template v-else>
                            <v-list-item-title class="headline mb-1 mt-2">
                                {{ emailLocal.label || 'No Label' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ emailLocal.address || 'No Email Provided' }}</v-list-item-subtitle>
                        </template>
                    </template>
                </v-list-item-content>
            </v-list-item>
        </v-card>

    </div>

</template>

<script>
    import { capitalizeFirstLetter } from '@/methods/utils';
    import {formatPhoneNumber} from '@/methods/globalMethods';
    import * as cloneDeep from 'lodash/cloneDeep';

    import UPDATE_CONTACT from '@/graphql/mutations/phoenix/ycmdContactUpdate';

    export default {
        name: 'customItemDetails',
        props: {
            item: Object,
            toggleContactFavorite: Function,
            onContactDelete: Function,
            updateContact: Function,
            isLoading: Boolean
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data: () => ({
            formatPhoneNumber,
            capitalizeFirstLetter,
            isEditable: false,
            test: null,
            masks: {
                phone: '(###) ###-####'
            },
            customContactData: {
                validContactForm: true,
                first_name: null,
                last_name: null,
                specialty: null,
                company: null,
                notes: null,
                phone_numbers: [
                    {
                        label: null,
                        number: null,
                        extension: null
                    }
                ],
                addresses: [
                    {
                        label: null,
                        street: null,
                        street2: null,
                        city: null,
                        state: null,
                        zip_code: null
                    }
                ],
                email_addresses: [
                    {
                        label: null,
                        address: null
                    }
                ]
            }
        }),
        mounted() {
            console.log('item? ', this.item);
            this.copyParentItem();
        },
        watch: {

        },
        methods: {
            copyParentItem() {
                this.customContactData = {...this.customContactData, ...cloneDeep(this.item)};
            },
            onToggleEdit() {
                // if pressed cancel
                if (this.isEditable) {
                    this.copyParentItem();
                }
                this.isEditable = !this.isEditable;
            },
            async onContactEditSave() {
                this.isLoadingLocal = true;
                console.log('saving notes: ', this.customContactData.notes);
                try {
                    const response = await this.$apollo.mutate({
                        mutation: UPDATE_CONTACT,
                        variables: {
                            custom_contact_id: this.itemLocal.id,
                    		specialty: this.customContactData.specialty,
                    		first_name: this.customContactData.first_name,
                    		notes: this.customContactData.notes,
                    		phone_numbers: this.customContactData.phone_numbers,
                    		company: this.customContactData.company,
                    		addresses: this.customContactData.addresses,
                    		last_name: this.customContactData.last_name,
                    		email_addresses: this.customContactData.email_addresses
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingLocal = false;

                    console.log('response: ', response);

                    const { ycmdContactUpdate } = response.data

                    console.log('ycmdContactUpdate: ', ycmdContactUpdate);

                    if (ycmdContactUpdate) {
                        this.customContactData = {...cloneDeep(ycmdContactUpdate)};
                        this.updateContact(ycmdContactUpdate);
                        this.isEditable = false;
                        this.$toasted.success(`Successfully updated contact!`);
                    } else {
                        // data did not come back
                        this.$toasted.error(`There was an error updating the contact`);
                        console.log('ycmdContactUpdate did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingLocal = false;
                    this.$toasted.error(`Caught an error updating contact`);
                    console.log('WARNING: Caught error - ', e);
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            isLoadingLocal: {
                get: function() {
                    return this.isLoading;
                },
                set: function(newValue) {
                    this.$emit('update:isLoading', newValue)
                }
            },
            itemLocal: {
                get: function() {
                    return this.item;
                },
                set: function(newValue) {
                    this.$emit('update:item', newValue)
                }
            }
        }
    }

</script>

<style scoped>

</style>
