<template>

  <div class="view">

    <div class="view">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 ycmd-medium-blue">

        <div class="i-card__main">

          <div class="i-card__main__header">

            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width white--text pl-3 pr-3" style="height:56px">
                <div>
                  <v-btn icon dark elevation="0" @click="changeView({view: 'chat-main', transition: 'prev', params: {room_id, room_name}})">
                    <v-icon size="18">close</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-column flex-grow-1 pl-2 pr-2 fs-12">
                  ROOM DETAILS
                </div>

<!--                
                <div class="d-flex align-center full-height pl-3 ml-1" style="border-left: solid 2px #125ea3">
                  <v-tooltip top
                             nudge-top="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-1 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon dark depressed
                          v-on="on"
                          class="ma-0"
                          @click="isRightMenuExpanded ? collapseRightMenu() : expandRightMenu()">
                        <v-icon dark size="20">{{ isRightMenuExpanded ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-no-wrap">{{ isRightMenuExpanded ? 'Minimize Chat' : 'Maximize Chat' }}</span>
                  </v-tooltip>
                </div>
-->                
              </div>
            </div>

          </div>

          <div class="i-card__main__content">
            <template v-if="loading">
              <div class="d-flex align-center justify-center full-height"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            </template>
            <template v-else>
              <div class="i-card__scrollable black--text flex-glow-1 white">
                <div class="pt-2 pb-2" style="border-top: solid 2px #125ea3">
                  <v-tabs-items v-model="tab" class="full-tabs">
                    <v-tab-item class="pa-2">
                      <div class="d-flex align-center pa-2 pl-4 pr-4">
                        <div class="fade-2 fs-12 ">VIEWED BY</div>
                        <v-spacer/>
                        <div class="fs-14 pr-2">
<!--                          {{ members.viewed.length }}-->
<!--                          <span class="fs-10 fade-2">of</span>-->
                          {{ readCount  }}</div>
                        <div class="fade-2 fs-11">User(s)</div>
                      </div>
                      <template v-for="(recipient, i) in post.recipients">
                        <template v-if="recipient.user_id !== post.user_id">
                          <provider
                            :key="`provider_${recipient.user_id}`"
                            v-if="recipient.read"
                            :provider="getProvider(recipient)"
                            class="pa-2"
                            foregroundColor="white--text"
                            backgroundColor="ycmd-blue"                            
                            />
                        </template>
                      </template>
                    </v-tab-item>
                    <v-tab-item class="pa-2">
                      <div class="d-flex align-center pa-2 pl-4 pr-4">
                        <div class="fade-2 fs-12 ">NOT VIEWED BY</div>
                        <v-spacer/>
                        <div class="fs-14 pr-2">
                          {{ UnreadCount  }}</div>
                        <div class="fade-2 fs-11">User(s)</div>
                      </div>
                      <template v-for="(recipient, i) in post.recipients">
                        <template v-if="recipient.user_id !== post.user_id">
                          <provider
                            :key="`provider_${recipient.user_id}`"
                            v-if="!recipient.read"
                            :provider="getProvider(recipient)"
                            class="pa-2"
                            foregroundColor="white--text"
                            backgroundColor="ycmd-blue"  
                            />
                        </template>
                      </template>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>

              <div style="border-top: solid 2px #125ea3">
                <div class="pa-2 ycmd-dark-blue">
                  <v-tabs dark centered elevation="0" v-model="tab" background-color="transparent">
                    <v-tabs-slider color="ycmd-light-green"></v-tabs-slider>
                    <v-tab class="fs-11">Viewed By</v-tab>
                    <v-tab class="fs-11">Not Viewed By</v-tab>
                  </v-tabs>
                </div>
              </div>

            </template>
          </div>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>

  import {mapActions, mapMutations, mapState} from "vuex";

  import GET_CHAT_POST_DETAIL from "@/graphql/queries/phoenix/ycmdChatPostDetailGet";

  import provider from "@/views/apps/chat/components/provider";
  import myPost from "@/views/apps/chat/components/my-post";
  import userPost from "@/views/apps/chat/components/user-post";
  import chatAttachment from "@/views/apps/chat/components/attachment";
  import {format, utcToZonedTime} from "date-fns-tz";
  import dataClient from '@/graphql/clients/axios';

  export default {
    name: 'chatDetailsList',
    props: {
      changeView: Function,
      params: {
          data: Object
      }
    },     
    components: {
      'provider': provider,
      'my-post': myPost,
      'user-post': userPost,
      'chat-attachment': chatAttachment
    },
    data() {
      return {
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        transition: 'fade',
        tab: null,
        post_id: null,
        post: null,
        loading: true,
        room_name: '',
        room_id: ''
      }
    },
    apollo: {
      $client: 'phoenixClient',
      /*
      ycmdChatPostDetailGet: {
        query: GET_CHAT_POST_DETAIL,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        skip: true,
        variables() {
          return {
            post_id: this.post_id
          }
        },
        result ({ data }) {

          //console.log("****************************** USER_ID: ",this.info.userID);

          if(data) {
            this.post = data.ycmdChatPostDetailGet
          }

          this.loading = false;
        },
        error (e) {
          console.error('ERROR: In ycmdChatPostDetailGet Query', e)
        }
      }
      */
    },
    mounted() {

      this.$emit('initialized', 'chat-details', this.event)

      this.room_name = this.params.room_name
      this.room_id = this.params.room_id
      // Only start this after we set the reactive post id
      this.loadPost(this.params.post_id)
    },
    methods: {
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu',]),
      async event (name, params) {
        console.log('name', name, params)
        switch (name) {
          case 'load':
            this.loading = true            
            this.room_name = params.room_name
            this.room_id = params.room_id

            this.loadPost(params.post_id);

            break
        }
      },
      async loadPost(post_id) {
        this.loading = true
        this.post_id = post_id;
        let result = await dataClient('ycmdChatPostDetailGet', {post_id: post_id})

        if(result) {
          this.post = result
        }

        this.loading = false;
        
      },

      getPoster({ first_name, last_name, user_id, recipients }) {

        let title = null;
        let specialty = null;
        let group_name = null;
        let online_status = null;

        if(recipients && recipients.length > 0)
        {
          const poster = recipients.filter((p) => p.user_id === user_id);

          if(poster && poster.length > 0)
          {
            title = poster[0].title;
            specialty = poster[0].specialty;
            group_name = poster[0].group_name;
            online_status = poster[0].online_status;
          }
        }

        return {first_name, last_name, title, specialty, group_name, online_status}
      },
      getProvider({ first_name, last_name, title, specialty, group_name, online_status }) {
        return {
          first_name, last_name, title, specialty, group_name, online_status
        }
      }
    },
    computed: {
      ...mapState('profile', ['info']),
      ...mapState('rightMenu', ['isRightMenuExpanded']),
      readCount: function() {

        if(this.post && this.post.recipients.length > 0)
          return this.post.recipients.filter(r => {
            if (r.user_id !== this.post.user_id)
              return r.read
          }).length;
        else
          return 0;
      },
      UnreadCount: function() {

        if(this.post && this.post.recipients.length > 0)
          return this.post.recipients.filter(r => {
            if (r.user_id !== this.post.user_id)
              return !r.read;
          }).length;
        else
          return 0;
      }
    }
  }
</script>

<style>


</style>