<template>

    <v-layout column class="blue">

        <v-layout shrink column dark text class="transparent pa-0 ma-0">

            <v-layout class="pl-3 pt-2 pr-4 pb-1">
                <v-layout align-center class="pt-2" style="height: 100%">
                    <div class="hidden-xs-only white--text pl-1 pb-2">
                        <span class="fs-18">Subscription</span>
                    </div>
                </v-layout>
                <v-layout class="provider-list pl-4 pr-3" style="height: 100%; max-width: 16%; min-width: 100px">
                    <v-btn @click="route('ADMIN.sampleDetails', 'next')">Next</v-btn>
                </v-layout>
            </v-layout>

        </v-layout>

        <v-layout class="white pa-2">
            <div class="fs-22 blue--text pb-4" >

                <div v-if="message">Message ID: {{ message._id }}</div>
                <div v-else>Waiting for a message....</div>
            </div>
        </v-layout>

    </v-layout>

</template>

<script>

    import SUBSCRIBE_TO_MESSAGES from '@/graphql/subscriptions/subscribeToMessages';

    export default {
        name: 'sampleSubscribeToMessages',
        data() {
            return {
                message: undefined,
                username: "chris.bergeron+pcp@youcallmd.com"
            }
        },
        mounted() {},
        apollo: {
            $subscribe: {
                onPCPMessage: {
                    query: SUBSCRIBE_TO_MESSAGES,
                    variables() {
                        return { email: this.username }
                    },
                    result ({data}) {

                        console.log('subscription response: ', JSON.stringify(data));
                        this.message = data.onPCPMessage.pcp_message;
                    }
                }
            }
        },
        methods: {
            changeView(view) {

                this.$emit('onChangeView', view);
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });

            }
        }
    }

</script>

<style scoped>

</style>
