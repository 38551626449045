import gql from 'graphql-tag'

export default gql`query emrGet($operation: String!, $parameters: [NameIdValueInput]) {
	emrGet(operation: $operation, parameters: $parameters )
    {
		operation
		providers {
			id
			lastname
			firstname
			acceptingnewpatients
			credentials
			npi
			specialty
			practiceName
            practiceId
			locationReasons {
				locationId
				patientType
				reasonId
				reasonName
				reasonDescription
			}
			availabilities {
				weekday
				startlocal
				duration
				locationsAllowed
				locationsNotAllowed
				reasonsAllowed
				reasonsNotAllowed
				exceptions{
					date
					startlocal
					duration
					locationsAllowed
					locationsNotAllowed
					reasonsAllowed
					reasonsNotAllowed					
				}
			}
			properties {
				name
				id
				value
			}
		}
		appointments {
			id
			nativeid
			datetimelocal
			datetimeutc
			duration
			status
			patientid
			practiceid
			practicename        
			locationid
			providerid
			type
			typeid
			typedisplay
			datestringlocal
			timezone
			scheduledonutc
			scheduledonlocal
			scheduledonby
			location_address1
			location_address2
			location_city
			location_state
			location_zip
			location_email
			location_phone
			location_fax
			location_name
			provider_lastname
			provider_firstname
			provider_npi
			provider_credentials
			provider_speciality
			provider_acceptingnewpatients
			patient {
				id
				address1
				address2
				city
				state
				dob
				zip
				emails {
					name
					id
					value
				}
				firstname
				lastname
				phones {
					name
					id
					value
				}
				practice_id
				primary_provider_id
				sex
				ssn
				status
			}
			reasonid
			reasondisplay
			notes {
				id
				created
				text
				type
			}
		}
		practices {
			id
			name
			publicName
			locations {
				id
				address1
				address2
				city
				state
				zip
				name
				fax
				phone
				timezone
			}
			referential {
            	key
            	value {
					name
					id
					value
				}
			}
			properties {
				id
				value
				name
			}
        },
		patients {
			id
			address1
			address2
			city
			state
			dob
			zip
			emails {
				name
				id
				value
			}
			firstname
			lastname
			phones {
				name
				id
				value
			}
			practice_id
			primary_provider_id
			sex
			ssn
			status
		}
		appointmentCheck {
			errors {
				name
				id
				value
			}
    		defaultDuration
			canOverrideDefaultDuration
			defaultReasonId
			canOverrideDefaultReasonId
			canCreateNewPatient
		}
    }
}`
