<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">

                <div class="i-card__main__header">

                    <communication-header>

                        <template v-slot:default>

                            <v-tooltip bottom
                                       nudge-bottom="0"
                                       nudge-right="40"
                                       color="ycmd-black"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark rounded
                                           @click="refreshDataFiltered"
                                           v-on="on"
                                           class="">
                                        <v-icon :size="22" color="">refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh</span>
                            </v-tooltip>

                        </template>

                        <template v-slot:actions>

                        </template>

                    </communication-header>

                </div>

                <div class="i-card__main__content br-2 pb-2 pr-2">

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <sub-header-content v-if="items.length">

                                <template v-slot:header-start>

                                    <v-row no-gutters>
                                        <v-col cols="6" sm="4" md="2" lg="2">
                                            <div class="fixed-header__column--group fixed-header__text">Patient
                                                <v-btn icon color="ycmd-blue" class="hidden">
                                                    <v-icon :size="16">arrow_downward</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col  cols="4" sm="4" md="1" lg="1">
                                            <div class="fixed-header__column--group fixed-header__text">Facility
                                                <v-btn icon color="ycmd-green" class="hidden">
                                                    <v-icon :size="16">arrow_downward</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="4" md="3" lg="3">
                                            <div class="fixed-header__column--group fixed-header__text">First Occurrence
                                                <v-btn icon color="ycmd-blue" class="hidden">
                                                    <v-icon :size="16">arrow_downward</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="4" md="3" lg="3">
                                            <div class="fixed-header__column--group fixed-header__text">Last Occurrence
                                                <v-btn icon color="ycmd-green" class="hidden">
                                                    <v-icon :size="16">arrow_downward</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="2" lg="3">
                                            <div class="fixed-header__column--group fixed-header__text">Reason
                                                <v-btn icon color="ycmd-blue" class="hidden">
                                                    <v-icon :size="16">arrow_downward</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <!-- Spacing for list buttons -->
                                    <div class="" style="width: 40px">&nbsp;</div>

                                </template>

                                <template v-slot:header-end>

                                    <counter :count="(items ? items.length.toString() : 0)" :total="totalItems.toString()"/>

                                </template>

                            </sub-header-content>

                            <div class="i-card__scrollable">

                                <div class="list-container">

                                    <v-container fluid v-for="(item, i) in items" :key="`user_${i}`" :class="`list-item flex-shrink-0 pa-3 pl-4 pr-4 ${ !( !(!!item.last_confirmed_time || !(!!item.last_event_type_code)) || !(!!item.first_confirmed_time || !(!!item.first_event_type_code)) ) ? 'message--confirmed' : 'message--confirmed' }`">

                                        <v-row no-gutters>
                                            <v-col cols="6" sm="3" md="2" lg="2">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">Patient</div>
                                                    <div class="label-value fw-500">
                                                        {{ item.patient_given_name || 'N/A' }}
                                                        {{ item.patient_family_name || 'N/A' }}
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="2" md="1" lg="1">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">Facility</div>
                                                    <div class="label-value">
                                                        {{item.message_sending_facility || 'N/A'}}
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="3" lg="3">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">First Occurrence</div>
                                                    <div class="label-value fs-10"> {{ item.first_date_created ? `${parsePcpStatus[item.first_event_type_code]} - ${format(parseISO(item.first_date_created), 'MM-dd-yyyy h:mm a')} PST` : 'Not Occurred' }}</div>
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="3" lg="3">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">Last Occurrence</div>
                                                    <div class="label-value fs-10"> {{ item.last_date_created ? `${parsePcpStatus[item.last_event_type_code]} - ${format(parseISO(item.last_date_created), 'MM-dd-yyyy h:mm a')} PST` : 'Not Occurred' }}</div>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3" lg="3">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                                                        <div class="hidden-md-and-up label-title">Reason</div>
                                                        <div class="label-value fs-10">{{ item.visit_admit_reason || 'N/A' }}</div>
                                                    </div>
                                                    <div class="hidden-sm-and-up">
                                                        <v-tooltip left
                                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                                   color="ycmd-black"
                                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn icon dark text small
                                                                       @click="() => { changeView({ view: 'details-view', params: { data: item } }); }"
                                                                       v-on="on" color="white" class="ycmd-blue mr-1">
                                                                    <v-icon :size="14">arrow_forward</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Show Details</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <div class="hidden-xs-only">
                                            <div class="d-flex flex-shrink-0 fill-height align-center justify-center">
                                                <v-tooltip left
                                                           :disabled="$vuetify.breakpoint.xsOnly"
                                                           color="ycmd-black"
                                                           content-class="fade-0 pa-4 pt-2 pb-2">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn icon dark text small
                                                               @click="() => { changeView({ view: 'details-view', params: { data: item } }); }"
                                                               v-on="on" color="white" class="ycmd-blue mr-1">
                                                            <v-icon :size="14">arrow_forward</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Show Details</span>
                                                </v-tooltip>
                                            </div>
                                        </div>

                                    </v-container>

                                    <template v-if="!items.length && !isLoading">

                                        <div class="d-flex align-start justify-center full-height fs-18 white--text mt-4">No Results</div>

                                    </template>

                                    <infinite-loader :infiniteHandler="infiniteHandlerFiltered"
                                                    :infiniteId="infiniteId"
                                                    :refreshHandler="refreshDataFiltered"/>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import {refreshHandler} from '@/graphql/handler/refreshHandler';
    import { parsePcpStatus } from '@/methods/globalMethods';

    import CommunicationHeader from "@/views/apps/communication/components/communication-header";
    import Counter from '@/components/header/items-counter';
    import SubHeaderContent from '@/components/header/sub-header-content';
    import InfiniteLoader from '@/components/loader/infinite-loader';

    import GET_MESSAGES from '@/graphql/queries/pcpMessagesBrowse';
    import GET_FACILITIES from '@/graphql/queries/pcpGetFacilities';
    import CONFIRM_MESSAGES from '@/graphql/mutations/pcpMessageConfirm';
    import GET_MESSAGES_FILTERED from '@/graphql/queries/pcpPatientStatus.js';
    import {mapActions, mapState} from "vuex";

    import { pcpUserMessageTypes } from '@/data/pcpUser/constants.js';

    export default {
        name: 'notifications',
        components: {
            'communication-header': CommunicationHeader,
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'infinite-loader': InfiniteLoader,
            Loading,
            InfiniteLoading
        },
        props: {
            changeView: Function
        },
        data() {
            return {
                format,
                parseISO,
                isLoading: false,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                mode: null,
                parsePcpStatus: parsePcpStatus,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },
                pcpUserMessageTypes: pcpUserMessageTypes,
                pcpUserMessageTypeSelected: pcpUserMessageTypes[0],

                nextToken: null,
                totalItems: 0,
                searchValue: '',
                items: [],
                infiniteId: 1
            }
        },
        created() {

        },
        mounted() {
          // console.log(this.pcpUserMessageTypes);
        },
        activated() {
          // this.$nextTick(() => this.$refs.searchBarRef.focus());
        },
        watch: {
          pcpUserMessageTypeSelected(val) {
            // do new search here
            this.searchMessages()
          }
        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            async infiniteHandlerFiltered($state) {
                this.isLoading = true;

                try {
                    const response = await this.$apollo.query({
                        query: GET_MESSAGES_FILTERED,
                        variables: {
                          reference_id: (this.mode === 'SINGLE' && this.referenceID) ? this.referenceID : null,
                          facility_id: null,
                          visit_id: null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    console.log('pcpPatientStatus response: ', response);
                    this.isLoading = false;

                    if (response.data && response.data.pcpPatientStatus) {
                        // set the total count
                        this.totalItems = response.data.pcpPatientStatus.length;
                        this.items.push(...response.data.pcpPatientStatus);
                        // check nextToken
                        if (response.data.pcpPatientStatus.nextToken) {
                          this.nextToken = response.data.pcpPatientStatus.nextToken;
                          $state.loaded();
                        } else {
                          this.nextToken = null;
                          $state.loaded();
                          $state.complete();
                        }
                    } else {
                        // data did not come back
                        $state.complete();
                    }
                } catch (e) {
                    $state.complete();
                    defaultErrorHandler(e);
                }
            },
            refreshDataFiltered() {
                this.totalItems = 0;
                this.items = [];
                this.nextToken = null;

                this.infiniteId++;
            },
            onSearchClickFiltered(e, value) {
                if (value === '') value = null;
                this.searchValue = value;
                this.refreshDataFiltered();
            },
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                referenceID: state => state.profile.info.referenceID
            })
        }
    }

</script>

<style scoped>

    .message--confirmed {

    }

    .message--unconfirmed {
      background-color: rgba(11, 160, 236, 0.5);
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
