import gql from 'graphql-tag'

export default gql`mutation ycmdProfileUpdate(
	$first_name: String, 
	$middle_initial: String, 
	$last_name: String, 
	$email: String, 
	$address: AddressInput,
	$online_status: OnlineStatus,
	$mobile_number: String
	) {
	ycmdProfileUpdate(
		first_name: $first_name, 
		middle_initial: $middle_initial, 
		last_name: $last_name, 
		email: $email, 
		address: $address,
		online_status: $online_status,
		mobile_number: $mobile_number
		) {
		status
	}
}`
