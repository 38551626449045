<template>
  <v-dialog
      v-model="value"
      persistent
      max-width="290"
      >
      <v-card>
          <v-card-title class="text-h5">
              Update Voicemail
          </v-card-title>
          <v-card-subtitle>
              {{subtitle}}
          </v-card-subtitle>

          <v-card-text>

            <v-select light dense flat outlined
                      v-model="status"
                      label="Action"
                      :items="statuses"
                      item-text="name"
                      item-value="value"
                      background-color="white"/>

              <v-textarea
                  counter
                  :label="textLabel"
                  v-model="noteText"
                  rows="3"
                  :maxlength="1000"
                  background-color="grey lighten-3"
              ></v-textarea>

          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="doAction()"
              :disabled="!!!noteText || sending"
          >
              {{buttonCaption}}
          </v-btn>
          <v-btn
              text
              @click="closeNoteDialog()"
          >
              Cancel
          </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
  import MUTATE_VOICEMAIL_STATUS from '@/graphql/mutations/phoenix/ycmdVoicemailUpdateStatus';

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      voicemail: {
        default: () => {return {}},
        type: Object
      }
    },
    data: () => ({
      noteText: '',
      sending: false,
      status: null
    }),
    mounted() {
    },
    methods: {
      badgeCount (originalStatus, status) {
          let count = 0

          if (originalStatus === 'new') {
              if (status !== 'new') {
                  count = -1
              }
          } else {
              if (status === 'new' ) {
                  count = 1
              }
          }
          return count
      },
      closeNoteDialog() {
        this.noteText = ''
        this.status = null
        this.sending = false
        this.$emit('input', false)
      },
      async doAction() {

        let response
        let bcount
        let originalStatus = this.voicemail.status
        switch (this.status) {
          case 'sms':
          case 'smsarchive':
            let variables = {
              voicemail_id: this.voicemail.contact_id,
              sms_number: this.voicemail.callback_phone,
              sms_message: this.noteText
            }
            if (this.status === 'smsarchive') {
              variables.status = "complete"
            }

            this.sending = true
            response = await this.$apollo.mutate({
                mutation: MUTATE_VOICEMAIL_STATUS,
                variables: variables,
                client: 'phoenixClient',
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            this.$emit('changed', response && response.data ? response.data.ycmdVoicemailUpdateStatus : null )

            bcount = this.badgeCount(originalStatus, variables.status)

            break

          default:

            this.sending = true
            response = await this.$apollo.mutate({
                mutation: MUTATE_VOICEMAIL_STATUS,
                variables: {
                    voicemail_id: this.voicemail.contact_id,
                    note: this.noteText,
                    status: this.status
                },
                client: 'phoenixClient',
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            this.$emit('changed', response && response.data ? response.data.ycmdVoicemailUpdateStatus : null )

            this.$set(this.voicemail, "hasNote", true);

            bcount = this.badgeCount(originalStatus, this.status)
            break
        }
        if (bcount) {
          this.$store.dispatch('app/adjustBadge', 
          {
              badgeKey: 'voicemail',
              count: bcount
          })
        }

        this.closeNoteDialog()
      },
    },
    computed: {
      textLabel () {
        switch (this.status) {
          case 'sms':
          case 'smsarchive':
            return 'Text message'
          default:
            return 'Note'
        }
      },
      buttonCaption () {
        switch (this.status) {
          case 'sms':
          case 'smsarchive':
            return 'Send Text'
          default:
            return 'Add Note'
        }
      },
      statuses () {
        let arr = [
          {name: 'Left Voicemail', value: 'left_message'},
          {name: 'Patient Unavailable', value: 'unavailable'},
          {name: 'Archive Call', value: 'complete'}
        ]
        if (this.voicemail.callback_phone_sms) {
          arr.push({name: 'Send SMS', value: 'sms'})
          arr.push({name: 'Send SMS and Archive', value: 'smsarchive'})
        }
        return arr
      },
      subtitle () {
        switch (this.status) {
          case 'sms':
          case 'smsarchive':
            let message =  `Send text message to ${this.voicemail.callback_phone}`
            if (this.status === 'smsarchive') {
              message += ' and archive'
            }
            return message
          default:
            return `Add note to voicemail from ${this.voicemail.spelled_first_name} ${this.voicemail.spelled_last_name}`
        }
      

      }
    }
  }
</script>

