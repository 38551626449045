<template>

  <div>

    <v-dialog v-model="value" persistent max-width="800">

        <v-form
          ref="frmAppointment"
          v-model="appointmentForm"
        >
        <v-card>
            <v-card-title
              class="fs-18 primary white--text"
            >
              Create Appointment - {{practiceName}}
            </v-card-title>
            <v-card-text class="fs-18 pt-5">
                <v-container
                class="pa-0 ma-0"
                >
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0 fw-700"
                    >
                      <emr-provider-select
                          v-model="selectedProvider"
                          :providerIds="providerIds"
                          :key="`provider-select_${refresh}`"
                          backgroundColor="ycmd-very-lightest-blue"
                          :rules="requiredRules"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0 fw-700"
                    >
                      <emr-patient-select
                          v-model="selectedPatient"
                          :providerId="selectedProvider"
                          backgroundColor="ycmd-very-lightest-blue"
                          :rules="requiredRules"
                          @newPatient="onNewPatient"
                          :patientList="newPatients"
                          @clearNewPatient="clearNewPatient"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="3"
                        class="pa-0 ma-0 fw-700 pr-1"
                    >
                      <emr-location-select
                          v-model="selectedLocation"
                          :providerId="selectedProvider"
                          :outlined="false"
                          backgroundColor="ycmd-very-lightest-blue"
                          :rules="requiredRules"
                      />
                    </v-col>
                    <v-col cols="9"
                        class="pa-0 ma-0 fw-700 pl-1"
                    >
                     <emr-reason-select
                          v-model="selectedReason"
                          :providerId="selectedProvider"
                          :locationId="selectedLocation"
                          :outlined="false"
                          backgroundColor="ycmd-very-lightest-blue"
                          :rules="requiredRules"
                      />
                    </v-col>                    
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="3"
                      class="pa-0 ma-0 fw-700"
                    >
<!--                    
                      <v-row
                          class="pa-0 ma-0"
                      >
                        <v-col
                          class="pa-0 ma-0"
                          cols="5"
                        >
-->                        
                          <date-picker
                              v-model="localAppointmentDate"
                              :includeCustomIcon="false"
                              :keyx="`appt_`"
                              :allowedDates="allowedDates"
                              backgroundColor="ycmd-very-lightest-blue"
                              iconStyle="inner"
                              :rules="requiredRules"
                          />
                        </v-col>

                        <v-col
                          class="pl-2 pt-0 pr-0 pb-0 ma-0"
                          cols="2"
                        >
                          <time-entry
                            v-model="localAppointmentTime"
                            backgroundColor="ycmd-very-lightest-blue"
                            :rules="requiredRules"
                            :label="timezoneLabel"
                          />
                        </v-col>
                        <v-col
                          class="pl-2 pt-0 pr-0 pb-0 ma-0"
                          cols="3"
                        >
                          <emr-duration-select
                              v-model="selectedDuration"
                              backgroundColor="ycmd-very-lightest-blue"
                              :rules="requiredRules"
                              :providerId="selectedProvider"
                          />
<!--                          
                        </v-col>

                      </v-row>    
-->
                    </v-col>

                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0 fw-700"
                    >
                      <v-textarea
                        v-model="description"
                        background-color="ycmd-very-lightest-blue"
                        rows="2"
                        label="Note"
                      />
                    </v-col>
                  </v-row>        
                                             
                </v-container>


            </v-card-text>
            <v-card-subtitle
            >
              <div
                class="error white--text"
                v-if="error"
              >
                {{error}}
              </div>
              <div
                v-else
              >
                &nbsp;
              </div>
            </v-card-subtitle>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                  @click="save"
                  class="primary"
                  :disabled="sending"
                  :loading="sending"
                >
                  Save
                </v-btn>
                <v-btn
                  @click="cancel"
                  :disabled="sending"
                >
                  Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-form>

    </v-dialog>

    <emr-new-patient
      v-model="openNewPatient"
      :providerId="selectedProvider"
      :error="newPatientError"
      @save="savePatient"
    >
    </emr-new-patient>

  </div>

</template>

<script>
  import { mapGetters  } from "vuex";
  import emrProviderSelect from '@/views/apps/emr/components/emrProviderSelect';
  import emrLocationSelect from '@/views/apps/emr/components/emrLocationSelect';
  import emrPatientSelect from '@/views/apps/emr/components/emrPatientSelect';
  import emrDurationSelect from '@/views/apps/emr/components/emrDurationSelect';
  import emrReasonSelect from '@/views/apps/emr/components/emrReasonSelect';
  import emrNewPatient from '@/views/apps/emr/components/emrNewPatient';
  import timeEntry from '@/components/datetime/time-entry';
  import DatePicker from '@/components/datetime/date-picker';
  import {formatSimple} from '@/methods/formatters';
  import {timeZoneShorts} from '@/data/groupApp/dataTypes';
  export default {
    name: 'emrAppointment',
    props: {
      value: {
        default: false,
        type: Boolean
      },
      appointmentTime: {
        default: null,
        type: String
      },
      appointmentDate: {
        default: null,
        type: String
      },
      timezone: {
        default: null,
        type: String
      },      
      providerIds: {
        default: () => [],
        type: Array
      },
      error: {
        default: null,
        type: String
      }       
    },
    components: {
        'emr-provider-select': emrProviderSelect,
        'emr-location-select': emrLocationSelect,
        'emr-duration-select': emrDurationSelect,
        'emr-patient-select': emrPatientSelect,
        'emr-new-patient': emrNewPatient,
        'emr-reason-select': emrReasonSelect,
        'time-entry': timeEntry,
        'date-picker': DatePicker,
    },
    data: () => ({
      sending: false,
      selectedProvider: null,
      selectedLocation: null,
      selectedPatient: null,
      selectedDuration: null,
      refresh: '',
      formatSimple,
      selectedReason: null,
      description: null,
      rules: {
          required: (value) => {
            return value ? true : 'Required'
          },
      },  
      newPatientError: null,  
      appointmentForm: false,
      openNewPatient: false,
      newPatients: [],
      timeZoneShorts
    }),
    mounted() {
    },
    methods: {
      async checkAppointment () {
        debugger
        let params = {
          providerid: this.selectedProvider,
          patientid: this.selectedPatient
        }
        
        let check = await this.$store.dispatch('emr/checkAppointment', params)
        debugger
      },
      async savePatient (payload) {

        this.newPatientError = null
        let patient = payload.patient
        let callback = payload.callback

        let newPatient = await this.$store.dispatch('emr/createPatient', patient)

        if (newPatient && newPatient.id) {
          this.newPatients = [newPatient]

          this.openNewPatient = false

        } else if (newPatient && newPatient.error) {
            this.newPatientError = newPatient.error.message
            if (!this.newPatientError) {
                this.newPatientError = 'An error has occurred'
            }
        }

        if (callback) {
          callback()
        }
      },
      onNewPatient () {
        this.openNewPatient = true
      },
      clearAll () {
        this.sending = false
        this.selectedProvider = null
        this.refresh = (new Date()).toISOString()
      },
      saveFinished () {
        this.sending = false
      },
      save () {

        console.log('this.appointmentForm', this.appointmentForm)

        let isValid = this.$refs.frmAppointment.validate()

        if (isValid) {
          this.sending = true
          this.$emit('save', {appointment: this.appointment, callback: this.saveFinished})
        }

      },
      cancel () {
        this.clearAll()
        this.$emit('close')
      },    
      allowedDates (date) {
        let now = this.formatSimple(new Date(),'yyyy-MM-dd')
        if (now > date) {
          return false
        }
        return true
      },
      clearNewPatient () {
        this.newPatients = [] 
      }
    },
    computed: {
      ...mapGetters(
          'emr',['getProviders','getPractices','getProviderPaletteFg', 'getProviderPaletteBg', 'getTimezoneByProviderId','getPracticeByProviderId']
      ),
      requiredRules () {
        return [this.rules.required]
      },
      localAppointmentDate: {
          get: function() {
            return this.appointmentDate;
          },
          set: function(newValue) {

            this.$emit('update:appointmentDate', newValue)
            //this.$emit('input', newValue)
          }
      },
      localAppointmentTime: {
          get: function() {
            return this.appointmentTime;
          },
          set: function(newValue) {
            this.$emit('update:appointmentTime', newValue)
            //this.$emit('input', newValue)
          }
      }, 
      appointment () {
        return {
          date: this.localAppointmentDate,
          time: this.localAppointmentTime,
          locationid: this.selectedLocation,
          duration: this.selectedDuration,
          reasonid: this.selectedReason,
          description: this.description,
          patientid: this.selectedPatient,
          providerid: this.selectedProvider 
        }
      },
      timezoneLabel () {
        let tz = this.getTimezoneByProviderId(this.selectedProvider)
        if (tz) {
          let short = this.timeZoneShorts[tz].short
          if (short) {
            return `Time (${short})`
          }
        }
        return 'Time'
      },
      practiceName () {
        return this.practice.name
      },
      practice () {
        debugger
        let p = this.$store.getters['emr/getPracticeByProviderId'](this.selectedProvider)
        return p ? p : {}
      }
    },
    watch: {
      selectedReason (newValue, oldValue) {
        //let p = this.$store.getters['emr/getPracticeByProviderId'](this.selectedProvider)
        debugger
        this.checkAppointment()
      },
      selectedPatient(newValue, oldValue) {
        //let p = this.$store.getters['emr/getPracticeByProviderId'](this.selectedProvider)
        debugger
        this.checkAppointment()
      },
      selectedLocation(newValue, oldValue) {
        //let p = this.$store.getters['emr/getPracticeByProviderId'](this.selectedProvider)
        debugger
        this.checkAppointment()
      },
    }
  }
</script>

