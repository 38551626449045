<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__header">
                    <div class="d-flex align-center flex-grow-1 ml-1">

                        <v-btn icon class="hidden-sm-and-up mr-2" @click="toggleMenu">
                            <v-icon :size="22" color="white">menu</v-icon>
                        </v-btn>
                        <div class="d-flex align-center white--text fs-13" style="width: 168px">
                            <v-tooltip bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark depressed v-on="on" class="ml-2 mr-2">
                                        <v-icon :size="22" @click="fetchStats()">refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh Stats</span>
                            </v-tooltip>
                        </div>
                        <div class="d-flex flex-grow-1">
                        </div>
                    </div>
                    <div class="d-flex align-center justify-end ml-2" style="width: 150px">

                    </div>
                </div>

                <div class="i-card__main__content white pa-2 br-2">

                    <div class="i-card__scrollable">


                        <v-container fluid class="pa-0">
                            <div v-for="(section, i) in messageStatsReturn" :key="`section_${i}`">
                                <v-col cols="12" sm="12" :class="`pb-2 pb-2 pl-0 pr-0 ${ $vuetify.breakpoint.smAndUp ? '' : ''}`">
                                    <div class="d-flex align-center pa-2 pt-3 ml-2">
                                        <div class="fs-14 fw-500">Facilities</div>
                                    </div>
                                    <div class="pa-2 pl-4 pr-4">
                                        <v-row>
                                            <v-col class="" v-for="(facility, i) in section.facilities" :key="`facility${i}`" cols="6" sm="6" md="4">
                                                <div class="ycmd-light-gray b b-1 br-2 pa-1">
                                                    <div class="label-title">{{facility.name}}</div>
                                                    <div v-for="(message, i) in facility.message_types" :key="`message_${i}`"class="label-value d-flex align-center">
                                                        <span>{{parsedMessageStatus[message.message_type] || 'N/A'}}: {{message.count}}</span>
                                                        <v-spacer/>
                                                        <span>Average Response (min): {{formattedTime(message.average_confirmation_seconds)}}</span>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="d-flex align-center pa-2 pt-3 ml-2">
                                        <div class="fs-14 fw-500">Providers</div>
                                    </div>
                                    <div class="pa-2 pl-4 pr-4">
                                        <v-row>
                                            <v-col class="" v-for="(provider, i) in section.providers" :key="`facility${i}`" cols="6" sm="6" md="4">
                                                <div class="ycmd-light-gray b b-1 br-2 pa-1" style="min-height: 80px;">
                                                    <div class="label-title">{{provider.first_name || 'N/A'}} {{provider.last_name || 'N/A'}}</div>
                                                    <div v-for="(message, i) in provider.message_types" :key="`message_${i}`"class="label-value d-flex align-center">
                                                        <span>{{parsedMessageStatus[message.message_type] || 'N/A'}}: {{message.count}}</span>
                                                        <v-spacer/>
                                                        <span>Average Response (min): {{formattedTime(message.average_confirmation_seconds)}}</span>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </div>
                        </v-container>

                        <!-- <v-row v-for="(section, i) in messageStatsReturn" :key="`section_${i}`" cols="12" sm="12" :class="">

                            <v-col cols="12" sm="12" v-for="(facility, i) in section.facilities" :key="`facility${i}`">

                                <v-row cols="6" sm="6" md="4" :class="`d-flex flex-column pb-2 ma-1`">
                                    <v-container fluid class="flex-grow-1 ycmd-light-gray pa-4 pb-3 ma-0 mb-2 br-2 b b-1">
                                      test
                                    </v-container>
                                </v-row>

                            </v-col>

                        </v-row> -->

                    </div>

                </div>
            </div>

            <template slot="right-panel">

                <div class="i-card__panel i-card__panel__right ycmd-blue-gradient pa-2">

                    <div class="i-card__panel__header">
                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark depressed v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                        <v-icon :size="22">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Close Search Filter</span>
                            </v-tooltip>

                            <div class="white--text fs-18 ml-3">
                                Filters
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2"></div>
                    </div>

                    <div class="i-card__panel__content white br-2 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">

                                <div class="">
                                    <div class="hidden-lg-and-up fs-10 fw-500 pt-1 pb-1">Status</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </i-card>


        <v-dialog persistent v-model="dialog" max-width="330">

            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="false"
                     :background-color="`#101622`"
                     :color="`#637eb6`"
                     :loader="`bars`">
            </loading>

        </v-dialog>

    </div>

</template>

<script>

    import { format, addSeconds } from 'date-fns';
    import { mapState, mapActions, mapMutations } from 'vuex';

    // Import component
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';

    import FETCH_STATS from '../../../../graphql/queries/pcpMessageStats';

    export default {
        name: 'dashboard',
        components: {
            Loading,
            InfiniteLoading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                format,
                dialog: false,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                parsedMessageStatus: {
                  "A01": "Admitted",
                  "A02": "Transfer a Patient",
                  "A03": "Discharged",
                  "A04": "Registered"
                },
                isLoading: false,
                messageStatsReturn: [],
                facilities: [],
                basis: 'total',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    }
                }
            }
        },
        activated() {
            // this.$nextTick(() => this.$refs.searchBarRef.focus());
        },
        mounted() {
          this.fetchStats()
        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            formattedTime(seconds) {
                seconds = seconds || 0;
                const helperDate = addSeconds(new Date(0), seconds);
                return format(helperDate, 'mm:ss');
            },
            async fetchStats() {
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: FETCH_STATS,
                        variables: {
                          start: "2020-04-02",
                          end: "2021-04-03",
                          basis: this.basis
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    console.log('fetch stats: ', response);

                    if (response.data.pcpMessageStats) {
                        this.messageStatsReturn = response.data.pcpMessageStats;
                    } else {
                        // data did not come back
                        console.log('updateStatusError data did not come back: ', response);
                        this.updateStatusError = "Error 3A. Invalid response.";
                        this.$toasted.error(`There was an error.`);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
                this.isLoading = false;
            }
        },
        watch: {

        },
        computed: {

        }
    }

</script>

<style scoped>

    .v-select__selection {
        width: 100%;
        justify-content: center;
    }

</style>
