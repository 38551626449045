<template>

    <div class="view pb-2">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card mr-2">

            <div class="i-card__main">

                <div class="i-card__main__header">

                    <communication-header>

                        <template v-slot:default>

                            <v-tabs dark
                                    background-color="transparent"
                                    v-model="headerTabs">

                                <v-tabs-slider color="ycmd-light-green"></v-tabs-slider>

                                <v-tab href="#tab-all">All</v-tab>

                            </v-tabs>

                            <v-tooltip bottom
                                       nudge-bottom="0"
                                       nudge-right="40"
                                       color="ycmd-black"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark rounded
                                           @click="refreshDataAll"
                                           v-on="on"
                                           class="">
                                        <v-icon :size="22" color="">refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh</span>
                            </v-tooltip>

                        </template>

                        <template v-slot:actions>

                            <input-search :searchValue.sync="notificationsAll.searchValue" :onSearchClick="onSearchClickAll" />

                        </template>

                    </communication-header>

                </div>

                <div class="i-card__main__content br-2">

                    <sub-header-content v-if="notificationsAll.items.length">

                        <template v-slot:header-start>

                            <v-row no-gutters>
                                <v-col cols="6" sm="4" md="2" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text">PCP
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col  cols="4" sm="4" md="2" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text">Message
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4" md="3" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text">Occurred
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4" md="2" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text">Patient
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="4" md="2" lg="3">
                                    <div class="fixed-header__column--group fixed-header__text">Reason
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Spacing for list buttons -->
                            <div class="" style="width: 40px">&nbsp;</div>

                        </template>

                        <template v-slot:header-end>

                            <counter :count="(notificationsAll.items ? notificationsAll.items.length.toString() : 0)" :total="notificationsAll.totalItems.toString()"/>

                        </template>

                    </sub-header-content>

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <v-tabs-items v-model="headerTabs">

                                <v-tab-item value="tab-all"
                                            transition="fade-in-transition"
                                            reverse-transition="fade-in-transition">

                                            <notifications-list
                                                :changeView="changeView"
                                                :infiniteHandler="infiniteHandlerAll"
                                                :infiniteId="notificationsAll.infiniteId"
                                                :refreshHandler="refreshDataAll"
                                                :items="notificationsAll.items"
                                                :totalItems="notificationsAll.totalItems"
                                                :isLoading="isLoading">

                                            </notifications-list>

                                </v-tab-item>

                            </v-tabs-items>

                        </div>

                    </div>

                </div>

            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import {refreshHandler} from '@/graphql/handler/refreshHandler';

    import SubHeaderContent from '@/components/header/sub-header-content';
    import NotificationsList from './notifications-list';
    // import NotificationsFilteredList from './notifications-filtered-list';
    import Counter from '@/components/header/items-counter';
    import ListContainer from '@/components/content/list-container';
    import CommunicationHeader from "../components/communication-header";
    import InputSearch from "@/components/input-search";
    import InfiniteLoader from '@/components/loader/infinite-loader';

    import GET_MESSAGES from '@/graphql/queries/pcpMessagesBrowse';
    import GET_MESSAGES_FILTERED from '@/graphql/queries/pcpPatientStatus.js';
    import GET_FACILITIES from '@/graphql/queries/pcpGetFacilities';
    import CONFIRM_MESSAGES from '@/graphql/mutations/pcpMessageConfirm';
    import {mapActions, mapState} from "vuex";

    import { pcpUserMessageTypes } from '@/data/pcpUser/constants.js';

    export default {
        name: 'notifications',
        components: {
            'notifications-list': NotificationsList,
            'communication-header': CommunicationHeader,
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'list-items-container': ListContainer,
            'input-search': InputSearch,
            'infinite-loader': InfiniteLoader,
            Loading,
            InfiniteLoading
        },
        props: {
            changeView: Function
        },
        data() {
            return {
                format,
                parseISO,
                isLoading: false,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                mode: null,
                headerTabs: null,

                loadingMessageResult: '',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },
                pcpUserMessageTypes: pcpUserMessageTypes,
                pcpUserMessageTypeSelected: pcpUserMessageTypes[0],

                notificationsAll: {
                    nextToken: null,
                    totalItems: 0,
                    searchValue: '',
                    items: [],
                    infiniteId: 1
                },
                notificationsFiltered: {
                    nextToken: null,
                    totalItems: 0,
                    searchValue: '',
                    items: [],
                    infiniteId: 1
                }
            }
        },
        apollo: {
          $client: 'phoenixClient'
        },
        created() {

        },
        mounted() {
        },
        activated() {
          // this.$nextTick(() => this.$refs.searchBarRef.focus());
        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            refreshDataAll() {
                this.notificationsAll.totalItems = 0;
                this.notificationsAll.items = [];
                this.notificationsAll.nextToken = null;

                this.notificationsAll.infiniteId++;
            },
            refreshDataFiltered() {
                this.notificationsFiltered.totalItems = 0;
                this.notificationsFiltered.items = [];
                this.notificationsFiltered.nextToken = null;

                this.notificationsFiltered.infiniteId++;
            },
            onSearchClickAll(e, value) {
                if (value === '') value = null;
                this.notificationsAll.searchValue = value;
                this.refreshDataAll();
            },
            onSearchClickFiltered(e, value) {
                if (value === '') value = null;
                this.notificationsFiltered.searchValue = value;
                this.refreshDataFiltered();
            },
            confirmMessagesUI(visitID) {
              if (!visitID) return;
              for (let message in this.items) {
                const currentMessage = this.items[message];
                // check if visitID matches
                if (currentitem.visit_id === visitID) {
                  currentitem.confirmed_time = Date.now();
                }
              }
            },
            async confirmMessagePCP(visitID) {
              // TODO: TEMPORARY
              // taking this out as requested for now
              return;
              //
              if (!visitID) {
                this.$toasted.error(`There was an error confirming message - missing visit ID`);
                return;
              }
              this.confirmMessagesUI(visitID);
              try {
                const response = await this.$apollo.mutate({
                  mutation: CONFIRM_MESSAGES,
                  variables: { visit_id: visitID },
                  fetchPolicy: 'no-cache',
                  errorPolicy: "all"
                })
                // console.log('MESSAGE: Confirm message response: ', response);
                if (response.data && response.data.pcpMessageConfirm) {
                    // check for success
                    if (response.data.pcpMessageConfirm.status === 'success') {
                        this.$toasted.success(`Successfully confirmed item.`);
                    }
                } else if (response.errors && response.errors.length > 0) {
                    if (response.errors[0].message) {
                        this.$toasted.error(`There was a problem confirming messsage - ${response.errors[0].message}`);
                    } else {
                        this.$toasted.error(`There was an error confirming item.`);
                    }
                } else {
                    // data did not come back
                    console.log('updateStatusError data did not come back: ', response);
                    this.$toasted.error(`There was an error.`);
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            async infiniteHandlerAll($state) {

                this.isLoading = true;

                try {
                    const response = await this.$apollo.query({
                        query: GET_MESSAGES,
                        variables: {
                            limit: 25,
                            search: this.notificationsAll.searchValue || null,
                            sort_direction: 'desc',
                            sort_field: '_sort_time',
                            reference_id: (this.mode === 'SINGLE' && this.referenceID) ? this.referenceID : null,
                            nextToken: this.notificationsAll.nextToken || null,
                            event_type_code: this.pcpUserMessageTypeSelected ? this.pcpUserMessageTypeSelected.value : this.pcpUserMessageTypes[0].value
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    console.log('response: ', response);
                    this.isLoading = false;

                    // update count
                    if (response.data.pcpMessagesBrowse && response.data.pcpMessagesBrowse.message_totals && response.data.pcpMessagesBrowse.message_totals.message_types) {
                      for (let type in response.data.pcpMessagesBrowse.message_totals.message_types) {
                          const currentType = response.data.pcpMessagesBrowse.message_totals.message_types[type];
                          if (currentType.message_type === 'search') this.notificationsAll.totalItems = currentType.count;
                      }
                    }

                    if (response.data.pcpMessagesBrowse && response.data.pcpMessagesBrowse.messages.length) {
                        this.notificationsAll.items.push(...response.data.pcpMessagesBrowse.messages);
                        // check nextToken
                        if (response.data.pcpMessagesBrowse.nextToken) {
                          this.notificationsAll.nextToken = response.data.pcpMessagesBrowse.nextToken;
                          $state.loaded();
                        } else {
                          this.notificationsAll.nextToken = null;
                          $state.loaded();
                          $state.complete();
                        }
                    } else {
                        // data did not come back
                        $state.complete();
                    }
                } catch (e) {
                    $state.complete();
                    defaultErrorHandler(e);
                }
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                referenceID: state => state.profile.info.referenceID
            })
        },
        watch: {
        }
    }

</script>

<style scoped>

    .message--confirmed {

    }

    .message--unconfirmed {
      background-color: rgba(11, 160, 236, 0.5);
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
