<template>

    <div>

        <v-toolbar
            dense
            flat
            color="ycmd-very-lightest-blue"
            id="emr_toolbar"
        >

          <v-progress-circular
            indeterminate
            :size="20"
            :width="1"
            color="primary"
            :style="{visibility: loading ? 'visible': 'hidden'}"
          >
          </v-progress-circular>
          <v-btn
            text
            class="text-none"
            color="ycmd-very-lightest-blue"
            style="border: none"
            width="100px"
            @click="toggleCalendarType"
          >
            {{calendarTypeDescription}}
          </v-btn>

            <v-btn
              color="ycmd-very-lightest-blue"
              style="border: none"
              text
              @click="$refs.calendar.prev()"
            >
              <v-icon>chevron_left</v-icon>
            </v-btn>


            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  class="text-none"
                  color="ycmd-very-lightest-blue"
                  style="border: none"
                  width="100px"
                >
                  {{calendarRangeDescription}}
                </v-btn>
              </template>

              <v-date-picker
                v-model="calendarDate"
                no-title
                scrollable
              >
      
              </v-date-picker>
            </v-menu>


            <v-btn
              color="ycmd-very-lightest-blue"
              style="border: none"
              text
              @click="$refs.calendar.next()"
            >
              <v-icon>chevron_right</v-icon>
            </v-btn>

            <v-spacer />
            <v-checkbox
                v-model="localShowUnavailable"
                label="Show unavailable"
                hide-details
                light
                :disabled="providerIds.length != 1"
            ></v-checkbox>

            <emr-timezone-select
                v-model="localTimezone"
                :providerIds="providerIds" 
                backgroundColor="ycmd-very-lightest-blue"
            />

        </v-toolbar>
        <v-sheet
          id="emr_container"
          height="400"
          v-resize="setScrollSize"
          >
          <v-calendar
              ref="calendar"
              v-model="calendarDate"
              :type="calendarTypeComputed"
              :weekdays="calendarWeekDaysComputed"
              :events="events"
              :event-color="eventColorFunction"
              :event-text-color="eventTextColorFunction"
              :event-name="eventNameFunction"
              :event-more="false"              
              :interval-count="96"
              :interval-minutes="15"
              :interval-height="24"
              :interval-format="formatGrid"
              :day-format="formatDayHeader"
              :weekday-format="formatWeekDayHeader"
              :end="weekMaxDays"
              stylexx="border: 1px solid red;"
              :key="`calendar_${refreshCalendar}_${localRefreshCalendar}`"
              @change="rangeChanged"
              @click:interval="onIntervalClick"
              @click:time="onTimeClick"
              @click:event="onEventClick"
              @click:day="onDayClick"
              @click:date="onDayClick"
          >
            <template v-slot:event="{ event }">
              <span
                v-if="event.eventType === 'unavailable_time' || event.eventType === 'unavailable_day'"
              :style="`display: inline-block;width: 100%;height: 100%;background-image: linear-gradient(135deg, ${eventColorFunction(event)} 10%, #fff 10%, #fff 50%, ${eventColorFunction(event)} 50%, ${eventColorFunction(event)} 60%, #fff 60%, #fff 100%);
background-size: 7.07px 7.07px;background-repeat: repeat;`"
              >
<!--              
                <span
                  style="color:0d406f"
                >
                {{event.name}}
                </span>
-->                
              </span>
              <span
                v-else
              >
                {{event.name}}                
              </span>
            </template>
          </v-calendar>
        </v-sheet>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { addDays } from 'date-fns'; 
import {formatSimple} from '@/methods/formatters';
import emrTimezoneSelect from '@/views/apps/emr/components/emrTimezoneSelect';
    export default {
        name: 'emrCalendar',
        components: {
            'emr-timezone-select': emrTimezoneSelect,          
        },
        props: {
            startDate: {
                type: Date,
                default: () => new Date()
            },       
            endDate: {
                type: Date,
                default: () => addDays(new Date(), 30)
            },
            events: {
                type: Array,
                default: () => []
            },
            loading: {
                type: Boolean,
                default: false
            }, 
            refreshCalendar: '',
            timezone: {
                default: null,
                type: String
            }, 
            providerIds: {
                default: [],
                type: Array
            },   
            showUnavailable: {
                default: false,
                type: Boolean
            },                                     
        },
        data: () => ({
            calendarType: 'week5',
            calendarTypes: [
                {name: '5 day Week', value: 'week5'},
                {name: '7 day Week', value: 'week7'},
                {name: 'Day', value: 'day'},            
            ],
            calendarDate: null,
            months: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'],
            days: ['Sun','Mon','Tues','Wed','Thur','Fri','Sat'],
            currentRange: null,
            localRefreshCalendar: ''

        }),
        created() {

        },
        async mounted() {
          let now = new Date()
          this.calendarDate = formatSimple(now,'yyyy-MM-dd')

          this.checkDate()

          this.setScrollSize()
          setTimeout(() => {
            console.log('scrolling', this.$refs.calendar)
            if (this.$refs.calendar) {
              this.$refs.calendar.scrollToTime('08:00')
            }
          }, 500);
        },
        watch: {
          calendarType (newValue, oldValue) {
            this.checkDate()
          },
          calendarDate (newValue, oldValue) {
            this.checkDate()
          }, 
          providerIds (newValue, oldValue) {
            if (newValue.length !== 1) {
              this.localShowUnavailable = false
            }
          },                 
        },
        methods: {
            ...mapActions('emr', ['loadProviders']),
            toggleCalendarType () {
              this.calendarType = this.calendarType === 'week5' ? 'week7' : 'week5'
            },
            checkDate () {
              if (this.calendarType === 'week5') {
                //if day is saturday or sunday move to monday so toggle between 5 day and 7 day works properly
                let dt = new Date(`${this.calendarDate} 00:00:00`)

                let dow = dt.getDay()
                if (dow === 6) {
                  let newdt = addDays(dt, 2)
                  this.calendarDate = formatSimple(newdt,'yyyy-MM-dd')

                } else if (dow === 0) {
                  let newdt = addDays(dt, 2)
                  this.calendarDate = formatSimple(newdt,'yyyy-MM-dd')
                }          
              }
            },           
            twoDigit (num) {
              let str = num + ''
              if (str.length === 1) {
                  str = '0' + str
              }
              return str
            },
            onTimeClick (dateTime) {
              
              if (dateTime.past) {
                //may have to do our own timezone aware checking
                this.$toasted.error('Appointments can not be made in the past');
                return
              }
              //let evenMin = Math.floor(dateTime.minute/15) * 15

              //let appointmentTime = `${dateTime.date} ${dateTime.hour}:${this.twoDigit(evenMin)}:00`

              //let dt = new Date(appointmentTime)

              let timepieces  = dateTime.time.split(':')

              if (timepieces[1] > '45') {
                timepieces[1] = '45'
              } else if (timepieces[1] > '30') {
                timepieces[1] = '30'
              } else if (timepieces[1] > '15') {
                timepieces[1] = '15'
              } else if (timepieces[1] > '0') {
                timepieces[1] = '00'
              }

              this.$emit('createAppointment', {
                date: dateTime.date,
                time: `${timepieces[0]}:${timepieces[1]}`
              })
            },
            onIntervalClick (params1, params2) {

            },
            onEventClick (params, nativeEvent) {

              if (params.event) {
                if (params.event.eventType === 'appointment') {

                  nativeEvent.preventDefault()
                  nativeEvent.stopPropagation()
                  this.$emit('editAppointment', {
                    id: params.event.id
                  })
              
                }
              }

            },
            onDayClick (date) {
              this.calendarDate = date.date
              this.calendarType = 'day'
            },

            //click:day-category
            eventColorFunction (item, queryText) {    
              return this.providerBg(item.providerid)
            },
            eventNameFunction (item, queryText) {  
              return item.input.patient && item.input.patient.status !== 'notfound' ? `${item.input.patient.firstname} ${item.input.patient.lastname}` : 'Unknown patient'
            },
            eventTextColorFunction (item, queryText) {
              return this.providerFg(item.providerid)
            },
            rangeChanged(range) {
              this.currentRange = range
              this.$emit('getEvents', range)
            },
            formatGrid(props) {
              let last2 = props.time.slice(-2)

              if (last2 === '00') {
                return `${props.hour > 12 ? props.hour - 12 : props.hour }${props.hour > 11 ? 'pm' : 'am'}`
              } else {
                return `:${last2}`
              }
            },
            formatDayHeader(props) {
              return this.days[props.weekday]
            }, 
            formatWeekDayHeader(props) {
              return `${this.months[props.month-1]} ${props.day}`
            },
            providerFg(providerId) {
                let fg = this.getProviderPaletteFg(providerId)
                return fg
            },
            providerBg(providerId) {
                let bg = this.getProviderPaletteBg(providerId)
                return bg
            },
            setScrollSize() {
              //console.log('this.$el',this.$el)
              //console.log('this.$el.top',this.$el.offsetTop)
              let element = this.$el.querySelector('#emr_container')
              //console.log('element.id', element.id)
              //console.log('element.offsetHeight', element.offsetHeight)

              let tbelement = this.$el.querySelector('#emr_toolbar')
              //console.log('tbelement.offsetHeight', tbelement.offsetHeight)

              //console.log('w',window.innerHeight)
              //console.log('w2',window.top)

              //element.style.height = `${window.innerHeight-this.$el.offsetTop-tbelement.offsetHeight-100}px`
              element.style.height = `${window.innerHeight-tbelement.offsetTop-tbelement.offsetHeight-57}px`
              //console.log('element.offsetHeight2', element.offsetHeight)

            }
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getloadedProviders','getProviderPaletteFg', 'getProviderPaletteBg', 'getTimezoneByProviderId']

            ),
            calendarTypeDescription () {
              let currentType = this.calendarType
              let item = this.calendarTypes.find(ct => {
                return ct.value === currentType
              })
              return item.name
            },
            calendarTypeComputed () {
              switch (this.calendarType) {
                case 'day':
                  return 'day'
                default: 
                  return 'week'
              }
            },
            weekMaxDays () {
              switch (this.calendarType) {
                case 'day':
                  return null
                case 'week7': 
                  return 7
                default:
                  return 5
              }
            },
            calendarWeekDaysComputed () {
              switch (this.calendarType) {
                case 'day':
                  return null
                case 'week7': 
                  return [0, 1, 2, 3, 4, 5, 6]
                default:
                  return [1, 2, 3, 4, 5]
              }
            },
            calendarRangeDescription () {

              if (!this.currentRange) {
                return ''
              }
              
              switch (this.calendarType) {
                case 'day':
                  return `${this.months[this.currentRange.start.month-1]} ${this.currentRange.start.day}`
                default: 
                  return `Week of ${this.months[this.currentRange.start.month-1]} ${this.currentRange.start.day}`
              }
            },
            localTimezone: {
                get: function() {
                    return this.timezone;
                },
                set: function(newValue) {
                    this.$emit('update:timezone', newValue)
                }
            },
            localShowUnavailable: {
                get: function() {
                  if (this.providerIds.length != 1) {
                    return false
                  } 
                  return this.showUnavailable;
                },
                set: function(newValue) {
                    this.$emit('update:showUnavailable', newValue)
                }
            },

        }
    }
</script>
