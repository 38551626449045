import gql from 'graphql-tag'

export default gql`query providerPageGet($operation: String!, $parameters: [NameIdValueInput]) {
	providerPageGet(operation: $operation, parameters: $parameters)
    {
      name
      id
      value
    }
}`
