<template>

    <div >
        
        <v-select flat 
            v-model="selectedDuration"
            :label="label"
            :items="durations"
            item-text="name"
            item-value="id"
            :background-color="backgroundColor"
            :outlined="outlined"
            :rules="rules"
        >
                

        </v-select>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'emrDurationSelect',
        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },  
            value: {
                default: 0,
                type: Number
            },               
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            label: {
                default: 'Duration',
                type: String
            },
            providerId: {
                default: '',
                type: String
            },
            reasonId: {
                default: '',
                type: String
            },            
        },
        data: () => ({
            //selectedDuration: '',
            maxDuration: 1440,
            setForProvider: '',
            setForDuration: ''
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
        },
        methods: {
        },
        computed: {
            durations () {
                let increment = 15
                let current = increment
                let arr = []

                while (current <= this.maxDuration) {
                    let hours = Math.floor(current / 60)
                    let minutes = current - (hours * 60)

                    let desc = ''
                    if (hours) {                        
                        desc  = `${hours} hr`
                    }

                    if (minutes) {
                        desc += ` ${minutes} min` 
                    }
                    arr.push({
                        name: desc,
                        id: current
                    })
                    current += increment
                }
                return arr
            },
            selectedDuration: {
                get: function() {
                    if (this.providerId && this.setForProvider === this.providerId) {
                        return this.value ;
                    }
                    return this.defaultDuration
                },
                set: function(newValue) {
                    this.$emit('input', newValue)
                }
            }, 
            defaultDuration () {
                debugger
                let defaultValue
                if (this.providerId) {
                    let provider = this.$store.getters['emr/getProviderById'](this.providerId)

                    debugger

                }

                if (!defaultValue) {
                    defaultValue = this.$store.getters['emr/getPracticePropertyByProviderId'](this.providerId, 'appointment_default_duration')                    
                }
                if (defaultValue) {
                    return parseInt(defaultValue+'')
                }

                return 15
            }


        }
    }
</script>
<style scoped>

.location-select >>> label {
    padding-left: 12px;
}
.location-select >>> .v-select__selections {
    padding-left: 12px;
}


</style>