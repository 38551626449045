<template>
    <div></div>
</template>

<script>

    import WaveSurfer from "wavesurfer.js";

    export default {
        props: [
            'src',
            'options'
        ],
        data() {
            return {
                waveSurfer: {}
            };
        },
        mounted() {
            let options = this.options;
            let wsOptions = Object.assign({ container: this.$el }, options);
            this.waveSurfer = new WaveSurfer.create(wsOptions);
        },
        beforeDestroy() {
            this.waveSurfer.destroy();
        }
    };
</script>

<style scoped>
    div {
        position: relative;
    }
</style>