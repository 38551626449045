<template>

    <div class="d-flex fill-height ycmd-dark-blue-radial-gradient full-width pa-2 br-2">

      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="false"
              :background-color="`#101622`"
              :color="`#637eb6`"
              :loader="`bars`">
      </loading>

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">REGISTER</div>
                <v-spacer />
                <div>Step 2</div>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                <div class="d-flex flex-column flex-grow-1 full-width pa-3 br-2">

                    <div class="d-flex flex-grow-1 justify-center fs-20 ycmd-medium-blue--text white--text">

                        <v-form
                                ref="registerForm"
                                v-model="validForm"
                                :lazy-validation="false"
                                class="mb-3">

                            <v-layout column class="mb-4 mt-2 fs-13" style="" style="text-align: center">
                                Please enter your YouCallMD username and password below
                            </v-layout>

                            <v-text-field dark flat single-line dense solo
                                          v-model="YCMDUsername"
                                          background-color="ycmd-dark-blue"
                                          :rules="[rules.required]"
                                          label="YCMD Username" />

                            <v-text-field dark flat single-line dense solo
                                          v-model="YCMDPassword"
                                          :append-icon="showYCMDPassword ? 'visibility' : 'visibility_off'"
                                          :rules="[rules.required]"
                                          :type="showYCMDPassword ? 'text' : 'password'"
                                          background-color="ycmd-dark-blue"
                                          label="YCMD Password"
                                          @click:append="showYCMDPassword = !showYCMDPassword" />

                            <div class="d-flex justify-center">
                                <v-btn dark text depressed color="white" class="fs-12" @click="route('registerForgotPassword', 'next')">Forgot Password?</v-btn>
                            </div>

                            <v-layout column class="fs-8" style="color: #FC5252; padding: 0px; text-align: center">
                                {{extraPasswordError}}
                            </v-layout>

                            <v-layout column class="fs-8" style="color: #FC5252; padding: 0px; text-align: center">
                                {{missingRegError}}
                            </v-layout>

                        </v-form>

                    </div>

                </div>

                <div class="d-flex flex-column align-center">

                    <div class="full-width pa-1 pl-0 pr-0">
                        <v-btn dark block large depressed color="ycmd-light-blue" :disabled="!validForm" @click="getYCMDToken()">Continue</v-btn>
                    </div>

                    <div class="pt-2">
                        <v-btn dark text depressed color="white" @click="route('register', 'prev')">Back</v-btn>
                    </div>
                </div>

            </div>

        </div>

        <v-dialog persistent v-model="dialog" max-width="330" style="">

          <v-card color="" dark style="background-color: #09283F; padding-top: 20px">
            <v-card-text class="fs-18" style="text-align: center;">
              Your account has been registered! Bringing you back to login.
            </v-card-text>

            <!-- <v-card-actions>
              <v-layout style="width: 100%;">
              </v-layout>
            </v-card-actions> -->

            <v-card-actions>
              <v-btn text color="white" @click="() => {this.route('login', 'prev'); this.resetFields();}" style="width: 100%;">Return</v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>

    </div>

</template>

<script>

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import AUTHENTICATE_YCMD from '../../graphql/mutations/authenticateGetYCMDToken';
    import REGISTER_USER from '../../graphql/mutations/authenticateRegisterUser';

    export default {
        name: 'registerAuthenticate',
        data: () => ({
            isLoading: false,
            YCMDUser: false,
            dialog: false,
            YCMDUsername: '',
            YCMDPassword: '',
            registrationCode: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false,
            showYCMDPassword: false,
            extraPasswordError: '',
            missingRegError: '',
            passwordsMatch: false,
            email: '',
            countryCode: '+1',
            mobileNumber: '',
            parsedMobileNumber: '',
            verificationCode: '',
            validForm: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  return pattern.test(value) || 'Invalid e-mail';
                }
            }
        }),
        components: {
          Loading
        },
        mounted() {
          const registrationCode = localStorage.getItem("registrationCode");
          this.registrationCode = registrationCode;
          if (!this.registrationCode) {
            this.missingRegError = 'Missing registration code. If expired, please contact an administrator to resend the link.';
          }
        },
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            async getYCMDToken() {
                localStorage.setItem("accessToken", null);


                this.isLoading = true;
                // console.log(this.YCMDUsername, this.YCMDPassword);
                try {
                    const response = await this.$apollo.mutate({
                        mutation: AUTHENTICATE_YCMD,
                        variables: {
                          ycmd_username: this.YCMDUsername,
                          ycmd_password: this.YCMDPassword
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('authenticateGetYCMDToken: ', response);
                    this.isLoading = false;

                    if (response.data.authenticateGetYCMDToken && response.data.authenticateGetYCMDToken.registration_token) {
                      localStorage.setItem("YCMDToken", response.data.authenticateGetYCMDToken.registration_token);
                      this.route('registerPassword', 'next')
                    }  else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message && typeof response.errors[0].message === 'string') {
                        this.extraPasswordError = response.errors[0].message || 'Error 2A. Try again later.';
                      } else {
                        this.extraPasswordError = 'Error 2B. Err did not come back.';
                      }
                    } else {
                      // data did not come back
                      console.log('authenticateGetYCMDToken data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.errorMessage = e.message;
                    console.log('CAUGHT: There was an error logging in *registerUser* : ', e);
                }
            },
            async register() {
                localStorage.setItem("accessToken", null);


                this.isLoading = true;
                // console.log(this.YCMDUsername, this.YCMDPassword);
                try {
                    const response = await this.$apollo.mutate({
                        mutation: REGISTER_USER,
                        variables: {
                          password: this.confirmPassword,
                          registration_token: this.registrationCode
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('response: ', response);
                    this.isLoading = false;

                    if (response.data.authenticateRegisterUser) {
                      this.dialog = true;
                    }  else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message && typeof response.errors[0].message === 'string') {
                        this.extraPasswordError = response.errors[0].message || 'Error 2A. Try again later.';
                      } else {
                        this.extraPasswordError = 'Error 2B. Err did not come back.';
                      }
                    } else {
                      // data did not come back
                      console.log('optIn data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.errorMessage = e.message;
                    console.log('CAUGHT: There was an error logging in *registerUser* : ', e);
                }
            },
            resetFields() {
              this.isLoading = false;
              this.dialog = false;
              this.password = '';
              this.confirmPassword = '';
              this.showPassword = false;
              this.showConfirmPassword = false;
              this.showYCMDPassword = false;
              this.YCMDPassword = '';
              this.YCMDUsername = '';
              this.extraPasswordError = '';
              this.missingRegError = '';
              this.email = '';
              this.countryCode = '+1';
              this.mobileNumber = '';
              this.parsedMobileNumber = '';
              this.verificationCode = '';
              this.validForm = false;
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            extraPasswordCheck() {
              // check if passwords match
              if (this.password !== this.confirmPassword) {
                this.extraPasswordError = 'Passwords do not match.';
                this.passwordsMatch = false;
              } else {
                this.extraPasswordError = '';
                this.passwordsMatch = true;
              }
            },
            clearYCMDForm() {
              this.YCMDUsername = '';
              this.YCMDPassword = '';
            },
            checkYCMDUser() {
              if (this.YCMDUser && this.YCMDUsername && this.YCMDPassword) {
                return true;
              } else if (this.YCMDUser && (!this.YCMDUsername || !this.YCMDPassword)) {
                return false;
              }
              return true;
            },
            validateCheck() {
              if (this.validForm && this.passwordsMatch && this.registrationCode && this.checkYCMDUser()) {
                return true;
              } else if (!this.registrationCode) {
                return false;
              } else {
                return false;
              }
            }
        }
    }

</script>

<style scoped>



</style>
