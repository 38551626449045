<template>

    <div>
        <v-expansion-panels
            v-model="panels"
            multiple  
            flat 
            ref="jobs_container"     
        >
            <v-expansion-panel
                v-for="job in reportJobs"
                :key="`job_${job.job_id}_${job.key}`"
            >
                <v-expansion-panel-header>

                    <template v-slot:default="{ open }">
                        <v-row no-gutters
                            align-left
                        >
                            <v-col cols="1">
                                <v-icon
                                    :size="36"
                                    v-if="!job || !job.running"
                                    color="primary"
                                >
                                    {{job.report.icon}}
                                </v-icon>
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    v-else
                                />
                            </v-col>
                            <v-col
                            cols="11"
                            >
                                <report-job-header :job="job" />
                            </v-col>
                        </v-row>
                    </template>
      
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <template v-slot:default>
                        <report-view 
                            :job="job"
                        />
                    </template>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>

</template>

<script>
import reportView from '@/views/apps/reporting/components/reportView.vue'
import reportJobHeader from '@/views/apps/reporting/components/reportJobHeader.vue'

import {mapGetters} from "vuex";

    export default {
        props: {
            report_id: {
                type: String,
                default: null
            },
            submittedKey: {
                type: String,
                default: null
            },
        },
        components: {
            'report-view': reportView,
            'report-job-header': reportJobHeader
        },
        data() {
            return {
                panels: [],
                moveToKey: null,
                scrollingContainer: null
            }
        },
        created() {},
        mounted() {
            let el = this.$el
            let container

            while (el) {
                if (el.classList.contains('i-card__scrollable')) {
                    container = el
                    break
                }

                el = el.parentElement
            }
            this.scrollingContainer = container ? container: null 
            //i-card__scrollable white full-width full-height
        },
        watch: {
            submittedKey (newValue, oldValue) {
                this.moveToKey = newValue
            },
            reportJobs (newValue, oldValue) {
                if (this.moveToKey && newValue && newValue.length) {
                    let index = newValue.findIndex(j => {
                        return j.key === this.moveToKey
                    })

                    if (index >= 0) {
                        setTimeout(() => {
                            this.panels.push(index)
                            this.scrollToBottom()
                        }, 1000);                        
                    }
                }

                this.moveToKey = null
            }
        },
        methods: {
            scrollToBottom() {
                if (this.scrollingContainer) {
                    this.$nextTick(() => {
                        this.scrollingContainer.scroll({
                            top: this.scrollingContainer.scrollHeight,
                            behavior: 'smooth'
                        });
                        setTimeout(() => {
                                this.scrollingContainer.scroll({
                                    top: this.scrollingContainer.scrollHeight,
                                    behavior: 'smooth'
                                });
                             }, 500);                        
                            
                    });
                }
            },
        },
        computed: {
            ...mapGetters('report', ['getReportJobs']),   
            reportJobs () {
                let arr = []
                console.log('this.getReportJobs', this.getReportJobs)
                this.getReportJobs.map(job => {
                    if (this.report_id == job.report.report_id) {
                        arr.push(job)
                    }
                })

                return arr //  .reverse() //messes up panels
            }  
        }
    }

</script>

<style scoped>

</style>
