import gql from 'graphql-tag'

export default gql`query ycmdChatAttachmentGet(
	$room_id: ID!, 
	$attachment_id: ID!,
	$page: Int) {
	ycmdChatAttachmentGet(
		room_id: $room_id, 
		attachment_id: $attachment_id,
		page: $page) {
		id
		content_type
		data
		meta {
			name
			value
		}
		page
		num_pages
	}
}`
