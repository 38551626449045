<template>

    <v-dialog persistent eager dark :max-width="maxWidth" v-model="audioPlayerDialog">

        <div class="ycmd-dark-blue-radial-gradient pa-4 white--text br-3">

            <div class="d-flex flex-column">
                <div class="text-center pa-4 pb-0">
                    <div class="relative full-width" style="height: 128px">
                        <div :class="`absolute full-width full-height audio-waveform opacity:${ isAudioLoading ? 0 : 1 }`">
                            <vue-wave-surfer ref="player" :options="options" />
                        </div>
                        <div v-show="isAudioLoading" class="relative full-width text-center pt-4" >
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column pa-4">
                    <div class="ycmd-light-blue" style="height: 1px"></div>
                    <div class="d-flex flex-column">
                        <div class="d-flex pt-2 pb-2">
                            <div class="fs-12 fade-1">
                                {{ audioProgress }}
                            </div>
                            <v-spacer />
                            <div class="fs-12 fade-1 text-right">
                                {{ audioDuration }}
                            </div>
                        </div>
                        <div class="d-flex flex-column align-center justify-center">
                            <div class="fs-18">
                                {{ title  }}
                            </div>
                            <div class="fs-12 fade-2 pt-3">
                                {{ subtitle }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">

                    <div class="d-flex align-end">
                        <v-tooltip bottom
                                   nudge-bottom="5"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark depressed
                                       v-on="on"
                                       color="ycmd-light-blue">
                                    <v-icon :size="22" @click="closeClick">close</v-icon>
                                </v-btn>
                            </template>
                            <span>Close</span>
                        </v-tooltip>
                    </div>
                    <div class="d-flex flex-grow-1 align-center justify-center pa-4">
                        <v-tooltip bottom
                                   nudge-bottom="5"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark depressed
                                       v-on="on"
                                       :disabled="isAudioLoading"
                                       color="white"
                                       class="mr-2">
                                    <v-icon :size="32" @click="seekToPosition(0)">skip_previous</v-icon>
                                </v-btn>
                            </template>
                            <span>Skip To Beginning</span>
                        </v-tooltip>

                        <v-tooltip bottom
                                   nudge-bottom="5"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark depressed
                                       v-on="on"
                                       :disabled="isAudioLoading"
                                       color="white"
                                       class="ml-4 mr-4">
                                    <v-icon :size="56" @click="toggleAudio">
                                        {{ isAudioPlaying ? 'pause_circle_filled' : 'play_circle_filled' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Play</span>
                        </v-tooltip>
                        <v-tooltip bottom
                                   nudge-bottom="5"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark depressed
                                       v-on="on"
                                       :disabled="isAudioLoading"
                                       color="white"
                                       class="ml-2">
                                    <v-icon :size="32" @click="skipForward">forward_10</v-icon>
                                </v-btn>
                            </template>
                            <span>Skip Ahead 10s</span>
                        </v-tooltip>
                    </div>
                    <div class="d-flex align-end">
                        <v-tooltip bottom
                                   nudge-bottom="5"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon 
                                        dark 
                                        depressed 
                                        v-on="on"
                                        :disabled="!id" 
                                        color="ycmd-light-blue">
                                    <v-icon :size="22" @click="download()">get_app</v-icon>
                                </v-btn>
                            </template>
                            <span>Download</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>

    import Cursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor";

    export default {
        props: {
            title: String,
            subtitle: String,
            audioPath: String,
            id: String,
            autoPlay: {
                type: Boolean,
                default: false
            },
            maxWidth: {
                type: Number,
                default: 340
            },
            waveSurferOptions: Object,
            open: Function,
            onOpen: Function,
            onClose: Function
        },
        data() {
            return {
                isAudioLoading: true,
                isAudioPlaying: false,
                audioProgress: '00:00',
                audioDuration: '00:00',
                audioPlayerDialog: false,
                options: {
                    skipLength: 10,
                    responsive: true,
                    waveColor: '#ffffff',
                    progressColor: '#00BFFF',
                    cursorColor: '#00BFFF',
                    cursorWidth: 1,
                    barWidth: 3,
                    barHeight: 2,
                    barRadius: 3,
                    height: 128,
                    barGap: 3,
                    plugins: [
                        Cursor.create({
                            showTime: true,
                            opacity: 1,
                            cursorWidth: 2,
                            customShowTimeStyle: {
                                'background-color': '#101622',
                                color: '#fff',
                                padding: '8px',
                                'font-size': '12px',
                                'border-radius': '8px',
                                'margin-left': '8px'
                            }
                        })
                    ]
                }
            };
        },
        mounted() {

            this.$nextTick(() => {

                if (this.player) {

                    this.player.on('ready', () => {

                        console.log('Loading audio ...');

                        this.isAudioLoading = false;
                        this.audioDuration = this.formatDuration(this.player.getDuration());

                    });

                    this.player.on('audioprocess', (seconds) => {

                        this.audioProgress = this.formatDuration(seconds);
                    });

                    this.player.on('finish', () => {

                        this.player.stop();
                        this.isAudioPlaying = false;
                    });
                }
                else {

                }
            });
        },
        methods: {
            download() {
                this.$emit('download', this.id)
            },
            closeClick() {

                this.player.stop();
                this.isAudioLoading = true;
                this.isAudioPlaying = false;
                this.audioProgress = '00:00';
                this.audioDuration = '00:00';
                this.audioPlayerDialog = false;

                if (this.onClose) this.onClose();
            },
            skipForward() {

                this.player.skipForward();
            },
            seekToPosition(position) {

                this.player.seekTo(position);
            },
            toggleAudio() {

                this.isAudioPlaying  ? this.player.pause() : this.player.play();

                this.isAudioPlaying = !this.isAudioPlaying;
            },
            formatDuration(sec) {

                sec = parseFloat(sec).toFixed(0);

                let hrs = Math.floor(sec/3600);
                (hrs >= 1) ? sec = sec - (hrs*3600) : hrs = '00';

                let min = Math.floor(sec/60);
                (min >= 1) ? sec = sec - (min*60) : min = '00';

                (sec < 1) ? sec='00' : void 0;

                (min.toString().length === 1) ? min = '0' + min : void 0;
                (sec.toString().length === 1) ? sec = '0' + sec : void 0;

                return [`${min}`, `${sec}`].join(':');
            },
        },
        watch: {
            audioPath(value) {

                if (value && value.length > 0) {

                    this.player.load(value);
                    this.isAudioLoading = true;
                    this.isAudioPlaying = false;
                    this.audioProgress = '00:00';
                    this.audioDuration = '00:00';
                    this.audioPlayerDialog = true;

                    if (this.onOpen) this.onOpen();
                }
            }
        },
        computed: {
            player() {
                return this.$refs.player ? this.$refs.player.waveSurfer : undefined;
            }
        }
    };
</script>

<style scoped>
    .lds-ellipsis div {
        background: #fff;
    }
</style>
