<template>
<div>

    <v-select 
        v-model="selectedTimezone"
        :label="label"
        :items="timezones"
        item-text="timezonename"
        item-value="timezoneid"
        :background-color="backgroundColor"
        :outlined="outlined"
        :rules="rules"
        light
        dense
        flat
        solo
        hide-details
        style="max-width: 200px;"
    >      

            <template v-slot:item="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        {{data.item.timezonename}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span 
                            v-for="(user) in data.item.users"
                            :key="`emrtz_user_${user.id}`"
                            :style="`background-color: ${user.bg};color: ${user.fg};padding-left: 4px;padding-right: 4px;border-radius: 4px;`"
                        >
                            {{user.name}}
                        </span>
                        
                        </v-col>
                    </v-row>
                </v-container>

            </template>
            <template v-slot:selection="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        {{data.item.timezonename}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span 
                            v-for="(user) in [data.item.users[0]]"
                            :key="`emrtz_user_${user.id}`"
                            :style="`background-color: ${user.bg};color: ${user.fg};padding-left: 4px;padding-right: 4px;border-radius: 4px;`"
                        >
                            {{user.name}}
                        </span>
                        
                        </v-col>
                    </v-row>
                </v-container>

            </template>



    </v-select>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'emrReasonSelect',
        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },  
            value: {
                default: '',
                type: String
            },                       
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            label: {
                default: 'Time zone',
                type: String
            },
            providerIds: {
                default: () => [],
                type: Array
            },
        },
        data: () => ({
            //selectedReason: ''
        }),
        created() {

        },
        async mounted() {

            if (!this.selectedTimezone) {
                this.$nextTick( () => {
                    this.selectedTimezone = this.currentTimezone 
                })
            }
        },
        watch: {
          providerIds (newValue, oldValue) {
            if (newValue && newValue.length) {
              let tz = this.getTimezoneByProviderId(newValue[newValue.length - 1])
              if (tz) {
                this.selectedTimezone = tz
              }
            } else {
                let first = this.timezones[0]
                this.selectedTimezone = first.timezoneid
            }
          },         

        },
        methods: {
            providerFg(providerId) {
                let fg = this.getProviderPaletteFg(providerId)
                return fg
            },
            providerBg(providerId) {
                let bg = this.getProviderPaletteBg(providerId)
                return bg
            }
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getPractices','getProviderPaletteFg', 'getProviderPaletteBg', 'getTimezoneByProviderId']
            ),    
            ...mapGetters(
                'profile',['getFirstName','getLastName','getUserID']
            ),
            currentTimezone () {
                return Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            providerMap () {
                let obj = {}
                this.getProviders.map(provider => {
                    obj[provider.id] = provider
                })
                return obj
            },

            practiceMap () {
                let obj = {}
                this.getPractices.map(practice => {
                    obj[practice.id] = practice
                })
                return obj
            },
            timezones () {
                let first = this.getFirstName
                let last = this.getLastName
                let timezones = {}

                timezones[this.currentTimezone] = {
                    id: this.currentTimezone,
                    name: this.currentTimezone,
                    users: {}
                }

                timezones[this.currentTimezone].users[this.getUserID] = {
                    name: `${first} ${last}`,
                    id: this.getUserID,
                    fg: 'black',
                    bg: '#deeffe'
                }

                console.log('initial timezones', timezones)

                if (this.providerIds && this.providerIds.length) {
                    this.providerIds.map(providerid => {
                        
                        let p = this.$store.getters['emr/getProviderById'](providerid)
                        
                        if (p) {
                            let practice = this.$store.getters['emr/getPracticeById'](p.practiceId)
                            if (practice) {
                                practice.locations.map(l => {
                                    let tz = timezones[l.timezone]
                                    if (!tz) {
                                        timezones[l.timezone] = {
                                            id: l.timezone,
                                            name: l.timezone,
                                            users: {}
                                        }
                                    }

                                    let u = timezones[l.timezone].users[p.id]
                                    
                                    if (!u) {                                        
                                        timezones[l.timezone].users[p.id] = {
                                            name: `${p.firstname} ${p.lastname}`,
                                            id: p.id,
                                            fg: this.providerFg(providerid),
                                            bg: this.providerBg(providerid)
                                        }  
                                    }
                                })
                            }
                        }
                        
/*
                        timezones[this.currentTimezone] = {
                            id: this.currentTimezone,
                            name: this.currentTimezone,
                            users: {}
                        }
*/
                    })
                }

                let arr = []
                for (let timezone in timezones) {
                    let tz = timezones[timezone]
                    let tzElement = {
                        timezoneid: tz.id,
                        timezonename: tz.name,
                        users: []
                    }

                    for (let u in tz.users) {
                        let user = tz.users[u]
                        tzElement.users.push(
                            {
                                name: user.name,
                                id: user.id,
                                fg: user.fg,
                                bg: user.bg
                            }
                        )
                    }
                    arr.push(tzElement)
                }

                return arr
            },
            selectedTimezone: {
                get: function() {
                    return this.value ;
                },
                set: function(newValue) {
                    this.$emit('input', newValue)
                }
            }, 
        }
    }
</script>
<style scoped>

</style>