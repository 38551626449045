import gql from 'graphql-tag'

export default gql`mutation phxBulletinSendSystem($message: String!, $title: String, $subtype: String!, $group_ids: [ID], $facility_ids: [ID], $user_ids: [ID], $pcp_user_ids: [ID], $notification: BulletinNotificationOption, $account_type: AccountType!) {
	phxBulletinSendSystem(message: $message, title: $title, subtype: $subtype, group_ids: $group_ids, facility_ids: $facility_ids, user_ids: $user_ids, pcp_user_ids: $pcp_user_ids, notification: $notification, account_type: $account_type) {
		id
		bulletin_id
		message
		title
		subtype
		detail
		group_ids
		facility_ids
		user_ids
		notification
		destination_users
		responses {
			username
			user_id
			device_id
			device_name
			response {
				DeliveryStatus
				StatusCode
				StatusMessage {
					errorMessage
					channelType
					pushProviderStatusCode
					pushProviderError
					pushProviderResponse
				}
			}
		}
  }
}`
