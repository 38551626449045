<template>

      <div class="d-flex align-center justify-center" v-if="center">
        <div :class="`lds-ellipsis${sizeModifier} ${ theme === 'light' ? 'light' : '' }`"><div></div><div></div><div></div><div></div></div>
      </div>
      <div v-else :class="`lds-ellipsis${sizeModifier} ${ theme === 'light' ? 'light' : '' }`"><div></div><div></div><div></div><div></div></div>


</template>

<script>

    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        components: {
        },
        props: {
          theme: {
            default: 'dark'
          },
          center: {
            default: false
          },
          size: {
            default: null,
            type: String
          }
        },
        computed: {
          sizeModifier () {
            if (this.size) {
              return `-${this.size}`
            }
            return ''
          }
        }
    }
</script>

<style scoped>

</style>
