export const pcpUserMessageTypes = [
  {
    name: 'All',
    value: ['A01', 'A03', 'A04']
  },
  {
    name: 'Registered',
    value: ['A04']
  },
  {
    name: 'Admitted',
    value: ['A01']
  },
  {
    name: 'Discharged',
    value: ['A03']
  }
]
