<template>

  <div class="i-card relative full-width full-height" style="overflow: hidden">

    <div class="absolute full-width full-height">

      <slot />

    </div>

    <div v-if="typeof(leftPanel) !== 'undefined'" class="icard-panel ycmd-medium-blue"
         :style="`transform: translateX(${ leftPanel ? '0' : '-100%' })`">

      <slot v-if="leftPanel" name="left-panel"/>
    </div>

    <div v-if="typeof(rightPanel) !== 'undefined'" class="icard-panel ycmd-medium-blue"
         :style="`transform: translateX(${ rightPanel ? '0' : '100%' })`">

      <slot v-if="rightPanel" name="right-panel"/>
    </div>

    <div v-if="typeof(topPanel) !== 'undefined'" class="icard-panel ycmd-medium-blue"
         :style="`transform: translateY(${ topPanel ? '0' : '-100%' })`">

      <slot v-if="topPanel" name="top-panel"/>
    </div>

    <div v-if="typeof(bottomPanel) !== 'undefined'" class="icard-panel ycmd-medium-blue"
         :style="`transform: translateY(${ bottomPanel ? '0' : '100%' })`">

      <slot v-if="bottomPanel" name="bottom-panel"/>
    </div>

  </div>

</template>

<script>
  export default {
    name: 'iCard',
    props: {
      viewCount: {
        type: Number,
        default: 1
      },
      viewIndex: {
        type: Number,
        default: 0
      },
      leftPanel: {
        type: Boolean,
        default: undefined
      },
      rightPanel: {
        type: Boolean,
        default: undefined
      },
      topPanel: {
        type: Boolean,
        default: undefined
      },
      bottomPanel: {
        type: Boolean,
        default: undefined
      },
      onPanelOpen: {
        type: Function
      },
      onPanelClose: {
        type: Function
      },
      panelClass: {
        type: String,
        default: 'black'
      },
      rippleEnabled: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        colors: ['green', 'orange']
      }
    },
    mounted() {
      this.$nextTick(() => {
      });
    },
    methods: {
      sendPanelEvents(val) {
        if (val)
          setTimeout(() => this.$emit('onPanelOpen'), 300);
        else
          setTimeout(() => this.$emit('onPanelClose'), 300);
      }
    },
    watch: {
      leftPanel: function (val) {
        this.sendPanelEvents(val)
      },
      rightPanel: function (val) {
        this.sendPanelEvents(val)
      },
      topPanel: function (val) {
        this.sendPanelEvents(val)
      },
      bottomPanel: function (val) {
        this.sendPanelEvents(val)
      }
    }
  }
</script>


<style scoped>

  .icard-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
    border-radius: inherit;
  }
</style>
