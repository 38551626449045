import gql from 'graphql-tag'

export default gql`query billingGroup($group_id: ID) {
	billingGroup(group_id: $group_id)
    {
      name
      altName
      shortCode
      group_id
      group_id_postgres
    }
}`
