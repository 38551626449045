<template>

    <v-layout class="view pa-2 green">

        <v-layout class="view-content white" style="overflow: hidden">

                <v-layout column class="relative">

                    <transition :name="transition">

                        <component v-bind:is="currentView" @onChangeView="changeView" />

                    </transition>

                </v-layout>

            </v-layout>

    </v-layout>


</template>

<script>

    import sampleList from './list.vue';
    import sampleDetails from './details.vue';

    export default {
        name: 'sample',
        components: {
            'list-view': sampleList,
            'details-view': sampleDetails
        },
        data() {
            return {
                view: 'list-view',
                transition: 'next'
            }
        },
        mounted() {},
        methods: {
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            changeView(value) {

                this.view = value;
            }
        },
        computed: {
            currentView: function() {

                this.transition = (this.view === 'list-view') ? 'prev' : 'next';

                return this.view;
            }
        }
    }

</script>

<style scoped>


</style>
