// Initial state
const state = {
  fullscreen: false
};

const getters = {
  fullscreen: state => state.fullscreen
};

const actions = {

  // setFullscreen: ({state, commit}, isFullscreen) => {
  //
  //   commit('setFullscreen', isFullscreen)
  // }
};

const mutations = {

  setFullscreen (state, isFullscreen) {
    state.fullscreen = isFullscreen;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
