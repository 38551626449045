<template>
  <v-container
    class="pa-0 ma-0"
  >

    <v-row
      class="pa-0 ma-0 align-center"
    >
      <v-col
        cols="12"
        class="pa-0 ma-0"
      >
        <label
            class="v-label v-label--active theme--light text-caption"
        >{{label}}</label>
      </v-col>
    </v-row>
    <v-row
      class="pa-0 ma-0 align-center"
    >
      <v-col
        class="pa-0 ma-0 shrink"
        style="min-width: 150px"
      >
        <time-picker
          v-model="startTime"
          label="Start Time"
          :nullable="nullable"
          />
      </v-col>
      <v-col
        class="pa-0 ma-0 shrink"
        style="min-width: 150px"
      >
        <time-picker
          v-model="endTime"
          label="End Time"  
          :nullable="nullable"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import timePicker from './timepicker'

export default {
    name: 'datePicker',
    components: {
      'time-picker': timePicker
    },
    props: {
      label: {
          type: String,
          default: 'Time Range'
      },
      value: {
        type: String,
        default: null
      },
      selectTime: {
        type: Boolean,
        default: true
      },
      required: {
        type: Boolean,
        default: true
      },     
      rules: {
        type: Array,
        default: () => []
      },
      nullable: {
        type: String,
        default: 'false'
      },     

    },
    data: () => ({
        startTime: null,
        endTime: null,
    }),
    mounted() {

      if (this.value) {
        let times = this.value.split('|')
        this.startTime = this.stripSeconds(times[0])
        this.endTime = this.stripSeconds(times[1])
      }
    },
    methods: {
      stripSeconds(time) {
        let pieces = time.split(':')

        return `${pieces[0]}:${pieces[1]}`
      },
      timeStringToSeconds(str) {
        let pieces = str.split(':')
        if (pieces.length !== 3) {
          return null
        }
        let amount = parseInt(pieces[0]) * 60 * 60 + parseInt(pieces[1]) * 60 + parseInt(pieces[2])
        return amount
      }
    },
    computed: {
      timeRange () {
        let startTime
        let endTime
        startTime = this.startTime
        endTime = this.endTime
        if (!startTime) {
          startTime = '00:00'
        }
        if (!endTime) {
          endTime = '00:00'
        }

        return `${startTime}:00|${endTime}:00`
      }
    },
    watch: {
      timeRange (newValue, oldValue) {
        let pieces = newValue.split('|')
        let startSeconds = this.timeStringToSeconds(pieces[0])
        let endSeconds = this.timeStringToSeconds(pieces[1])

        let daysSeconds = 24 * 60 * 60 

        let totalSeconds = 0
        if (startSeconds > endSeconds) {
          totalSeconds = daysSeconds - startSeconds
          totalSeconds += endSeconds
        } else {
          totalSeconds = endSeconds - startSeconds
        }

        this.$emit('input', newValue)
        this.$emit('changed', newValue)


      }
    }
}
</script>
