<template>
  <v-form
    ref="form"
    @submit.prevent="addText"
  >
    <v-container
      class="pa-0 ma-0"
    >

      <v-row
        class="pa-0 ma-0 align-center"
      >
        <v-col
          cols="12"
          class="pa-0 ma-0"
        >
            <v-text-field
              v-model="text"
              :label="label"
              class="pa-0 ma-0"
              ref="textbox"
            >
              <template
                  v-slot:append-outer
              >
                  <v-btn
                  icon
                  type="submit"
                  >
                      <v-icon
                          :color="text ? 'primary' : 'cancel'"
                      >
                          add_circle
                      </v-icon>
                  </v-btn>
              </template>
            </v-text-field>
        </v-col>
      </v-row> 

      <v-row
        class="pa-0 ma-0 align-center"
      >
        <v-col
          cols="12"
          class="pa-0 ma-0"
        >
          <v-chip
            v-for="(item, i) in value"
            :key="`chip_${i}`"
            close
            @click:close="deleteText(item)"
          >
            {{item}}
          </v-chip>
        </v-col>
        <v-col
          class="shrink pa-0 ma-0"
        >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>

export default {

  props: {
    value: {
      default: () => [],
      type: Array
    },
    label: {
      default: 'Text',
      type: String
    }
  },
  data: () => ({
    text: ''
  }),
  methods: {
    deleteText(text) {
      let index = this.localValue.findIndex(t => {
        return text==t
      })
      if (index >=0) {
        this.localValue.splice(index,1)
      }
    },
    addText() {
      if (this.text) {
        this.localValue.push(this.text)
        this.text = ''
      }
      if (this.$refs.textbox) {
        this.$refs.textbox.focus()
      }
      
    }
  },
  computed: {
    localValue: {
        get: function() {
            return this.value
        },
        set: function(newValue) {
            this.$emit('input', newValue)
        }
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>