<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable full-width full-height">

                        <div class="pa-2 pt-0">

                            <div class="header__details">
                                Profile Information
                            </div>
                            <v-container fluid class="ycmd-light-gray pa-0 b b-1 br-3">
                                <v-form
                                      ref="editProfileForm"
                                      v-model="validEditProfile"
                                      class="d-flex flex-column full-width full-height pl-4 pr-4">
                                    <div>
                                        <v-row>
                                            <v-col cols="8" sm="5" md="5">
                                                <div class="label-title__details">First Name</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-firstname"
                                                                  :value="first_name || 'N/A'" v-model="first_name" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ first_name || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                            <v-col cols="4" sm="2" md="2">
                                                <div class="label-title__details">M.I.</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-firstname"
                                                                  :value="middle_initial || 'N/A'" v-model="middle_initial" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ middle_initial || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="5" md="5">
                                                <div class="label-title__details">Last Name</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-lastname"
                                                                  :value="last_name || 'Not Provided'" v-model="last_name" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ last_name || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="4">
                                                <div class="label-title__details">Address</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-email"
                                                                  :value="address || 'Not Provided'" v-model="address" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ address || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="4">
                                                <div class="label-title__details">Address Cont</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-email"
                                                                  :value="addressCont || 'Not Provided'" v-model="addressCont" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ addressCont || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="4">
                                                <div class="label-title__details">City</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-phone"
                                                                  :value="email || 'Not Provided'" v-model="email" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ email || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="4">
                                                <div class="label-title__details">State</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-license"
                                                                  :value="state || 'N/A'" v-model="state" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ state || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                            <v-col cols="6" sm="6" md="4">
                                                <div class="label-title__details">Zip</div>
                                                <div v-if="isEditable">
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-reference"
                                                                  :value="zip || 'N/A'" v-model="zip" />
                                                </div>
                                                <div v-else class="label-value pt-2 pb-4">
                                                    {{ zip || 'Not Provided'}}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-form>
                            </v-container>

                            <div class="header__details">
                                Contact Information
                            </div>
                            <v-container fluid class="ycmd-light-gray pa-0 b b-1 br-3">
                                <div class="pa-4">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="label-title__details">Email</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-firstname"
                                                              :value="email || 'N/A'" v-model="email" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ email || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-container>

                            <div class="header__details">
                                Security Information
                            </div>
                            <v-container fluid class="ycmd-light-gray pa-0 b b-1 br-3">
                                <div class="pa-4">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" lg="6">
                                            <div class="label-title__details">Password Retrieval</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-firstname"
                                                              :value="passwordResetList || 'N/A'" v-model="passwordResetList" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ passwordResetList || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6">
                                            <div class="label-title__details">Answer</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-firstname"
                                                              :value="passwordResetAnswer || 'N/A'" v-model="passwordResetAnswer" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ passwordResetAnswer || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-container>

                            <div class="header__details">
                                Reset Password
                            </div>
                            <v-container fluid class="ycmd-light-gray pa-0 b b-1 br-3">
                                <div class="pa-4">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" lg="12">
                                            <div class="label-title__details">Old Password</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-firstname"
                                                              :value="oldPassword || 'N/A'" v-model="oldPassword" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ oldPassword || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6">
                                            <div class="label-title__details">New Password</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-firstname"
                                                              :value="newPassword || 'N/A'" v-model="newPassword" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ newPassword || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="6">
                                            <div class="label-title__details">Confirm New Password</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-firstname"
                                                              :value="newPasswordConfirm || 'N/A'" v-model="newPasswordConfirm" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ newPassowrdConfirm || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-container>

                            <v-col cols="12" class="d-flex justify-end mt-2">
                                <v-btn dark depressed
                                       color="ycmd-blue"
                                       class="mb-0 mr-0"
                                       @click="onSubmitEdit()">{{isEditable ? 'Save' : 'Edit'}}
                                </v-btn>
                            </v-col>

                        </div>

                    </div>

                </div>

            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import {refreshHandler} from '@/graphql/handler/refreshHandler';

    import GET_PROFILE from '@/graphql/queries/phoenix/ycmdProfileGet';

    export default {
        name: 'groupEditProfile',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                selectedModal: null,
                isLoading: false,
                errorMsg: '',
                successMsg: '',

                first_name: '',
                last_name: '',
                middle_initial: '',
                address: '',
                addressCont: '',
                city: '',
                state: '',
                zip: '',

                email: '',

                passwordResetList: [],
                passwordResetAnswer: '',

                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',

                isEditable: true,
                validEditProfile: false,

                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    },
                    email: email => {
                      const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(email) || 'Invalid email';
                    }
                }
            }
        },
        activated() {

        },
        mounted() {
            this.getProfile();
        },
        methods: {
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            selectAllProviders() {
              this.selectedProviders = this.searchedProviders;
            },
            async getProfile() {
                this.isLoading = true;

                try {
                    const response = await this.$apollo.query({
                        query: GET_PROFILE,
                        variables: {
                            accountType: 'all'
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('response: ', response);

                    // update
                    if (response.data && response.data.ycmdProfileGet) {
                        this.first_name = response.data.ycmdProfileGet.first_name || 'Not Provided';
                        this.last_name = response.data.ycmdProfileGet.last_name || 'Not Provided';
                        this.middle_initial = response.data.ycmdProfileGet.middle_initial || 'Not Provided';
                        this.address = response.data.ycmdProfileGet.street || 'Not Provided';
                        this.addressCont = response.data.ycmdProfileGet.first_name || 'Not Provided';
                        this.city = response.data.ycmdProfileGet.city || 'Not Provided';
                        this.state = response.data.ycmdProfileGet.state || 'Not Provided';
                        this.zip = response.data.ycmdProfileGet.zip_code || 'Not Provided';
                        this.email = response.data.ycmdProfileGet.email || 'Not Provided';
                    } else {

                    }
                } catch (e) {
                    this.isLoading = false;
                    // defaultErrorHandler(e);
                    console.log('Catch error: ', e);
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
          selectedGroup(val) {

            this.clearFields(true);

            if (!val || !val.id) return;

            this.getBillingGroup(val);

          }

        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
