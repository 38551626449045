<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card br-2 mr-2">

            <div class="i-card__main pb-2">

                <div class="i-card__main__header">

                  <v-tooltip bottom
                             nudge-bottom="0"
                             nudge-right="40"
                             color="ycmd-black"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             content-class="fade-0 pa-4 pt-2 pb-2">
                      <template v-slot:activator="{ on }">
                          <v-btn icon dark rounded
                                 @click="refreshData"
                                 v-on="on"
                                 class="">
                              <v-icon :size="22" color="">refresh</v-icon>
                          </v-btn>
                      </template>
                      <span>Refresh</span>
                  </v-tooltip>

                </div>

                <div class="i-card__main__content d-flex br-2 overflow-none">

                    <sub-header-content v-if="items.length">

                        <template v-slot:header-start>

                            <!-- Header: Make sure this matches layout below -->
                            <v-row no-gutters>
                                <v-col cols="4" sm="4" md="4" lg="4">
                                    <div class="fixed-header__column--group fixed-header__text">Date & Time
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" lg="4">
                                    <div class="fixed-header__column--group fixed-header__text">From
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="4" sm="4" md="4" lg="4">
                                    <div class="fixed-header__column--group fixed-header__text">Length (sec)
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Spacing for list buttons -->
                            <div class="" style="width: 68px">&nbsp;</div>

                        </template>

                        <template v-slot:header-end>

                            <counter :count="currentItems.toString()" :total="totalItems.toString()"/>

                        </template>

                    </sub-header-content>

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <v-container fluid v-for="(item, i) in items" :key="`user_${i}`" :class="`list-item flex-shrink-0`">

                                <div class="d-flex flex-grow-1">

                                    <div class="d-flex flex-grow-1 flex-column">

                                        <v-row no-gutters>

                                            <v-col cols="6" sm="6" md="4" lg="4">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">Date & Time</div>
                                                    <div class="label-value">
                                                        {{ item.unix_date_created ? format(parseISO(item.unix_date_created), 'MM-dd-yyyy h:mm a') : 'Not Available' }}
                                                    </div>
                                                </div>
                                            </v-col>

                                            <v-col cols="6" sm="6" md="4" lg="4">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">From</div>
                                                    <div class="label-value">
                                                        {{ item.from ? formatPhoneNumber(item.from) : 'N/A' }}
                                                    </div>
                                                </div>
                                            </v-col>

                                            <v-col cols="6" sm="6" md="4" lg="4">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">Length</div>
                                                    <div class="label-value">
                                                        {{ item.duration || 'No Length' }}
                                                    </div>
                                                </div>
                                            </v-col>

                                        </v-row>

                                    </div>

                                    <v-menu
                                      left
                                      nudge-left="40"
                                      origin="center center"
                                      transition="slide-x-transition"
                                      :rounded="'lg'"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-btn content-class="pa-4 pt-2 pb-2"
                                                 :disabled="!(item.recordings && item.recordings.length)"
                                                 :loading="item.isItemLoading"
                                                 v-on="on"
                                                 v-bind="attrs"
                                                 icon light depressed class="ml-2 mr-2" color="white">
                                              <v-icon :size="32" @click="" color="#177AD5">play_circle_outline</v-icon>
                                          </v-btn>
                                      </template>

                                      <div class="ycmd-dark-blue__actions d-flex flex-column justify-center align-center pa-2">

                                          <v-btn v-for="(recording, i) in item.recordings"
                                                 @click="openAudioPlayerClick(item, recording, i)"
                                                 content-class="fade-0 pa-4 pt-2 pb-2"
                                                 dark depressed class="" color="#177AD5">
                                              {{`${i+1}) `}}{{ recording.date_created ? format(parseISO(recording.date_created), 'MM-dd-yyyy h:mm a') : 'Recording' }}
                                          </v-btn>

                                      </div>

                                    </v-menu>

                                    <!-- <v-tooltip bottom
                                               nudge-bottom="10"
                                               nudge-right="50"
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn :disabled="!(item.recordings && item.recordings.length)" icon dark color="ycmd-blue" v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                                <v-icon :size="26">play_circle_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Listen To Call</span>
                                    </v-tooltip> -->

                                </div>

                            </v-container>

                            <infinite-loader :infiniteHandler="infiniteHandler"
                                            :infiniteId="infiniteId"
                                            :refreshHandler="refreshData"/>

                        </div>

                    </div>

                </div>

            </div>

            <template slot="right-panel">

                <div class="i-card__panel i-card__panel__right ycmd-blue-vertical-gradient">

                    <div class="i-card__panel__header br-2">

                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                        <v-icon :size="20">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>

                            <div class="white--text fs-14 ml-3">
                                Search Criteria
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2">

                        </div>
                    </div>

                    <div class="i-card__panel__content white br-2 br_tr-0 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">
                                Content
                            </div>
                        </div>
                    </div>
                </div>

            </template>

            <template slot="bottom-panel">

                <div class="i-card__panel i-card__panel__bottom ycmd-blue-vertical-gradient pa-2 pr-0 pt-0">

                    <div class="i-card__panel__header">

                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark v-on="on" @click="card.panels.bottom = false" class="ml-2 mr-2">
                                        <v-icon :size="20">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>

                            <div class="white--text fs-14 ml-3">
                                New Message
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2">

                        </div>
                    </div>

                    <div class="i-card__panel__content white br-2 br_tr-0 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">
                                Content
                            </div>
                        </div>
                    </div>
                </div>

            </template>

        </i-card>

        <i-audio-player :audioPath="audioPath" :title="recordingNumber" :subtitle="dateCreated" />

    </div>

</template>

<script>

    import {mapActions, mapState, mapGetters} from "vuex";
    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import {refreshHandler} from '@/graphql/handler/refreshHandler';

    import SubHeaderContent from '@/components/header/sub-header-content';
    import Counter from '@/components/header/items-counter';
    import InfiniteLoader from '@/components/loader/infinite-loader';

    import {formatPhoneNumber} from '@/methods/globalMethods';
    import GET_RECORDING_URL from '@/graphql/queries/phoenix/ycmdRecordingUrlGet';
    import GET_MESSAGES from '@/graphql/queries/phoenix/ycmdCallLogGet';

    export default {
        name: 'notifications',
        components: {
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'infinite-loader': InfiniteLoader,
            Loading
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                formatPhoneNumber,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                items: [],
                searchValue: '',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },

                infiniteId: 1,
                page: 1,
                limit: 20,
                currentItems: 0,
                totalItems: 0,

                recordingNumber: '',
                dateCreated: '',
                audioPath: ''
            }
        },
        created() {},
        mounted() {},
        activated() {},
        watch: {
            getUserID(value) {
                this.refreshData();
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            async openAudioPlayerClick(item, recording, index) {
                this.$set(item, 'isItemLoading', true);
                console.log('recording: ', recording);
                try {
                    const response = await this.$apollo.query({
                        query: GET_RECORDING_URL,
                        variables: {
                            recording_id: recording.sid
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    item.isItemLoading = false;

                    console.log('response: ', response);

                    const { ycmdRecordingUrlGet } = response.data;
                    if (ycmdRecordingUrlGet) {
                        this.recordingNumber = `Recording #${index+1}`;
                        this.dateCreated = format(parseISO(recording.date_created), 'MM/dd/yyyy h:mm a');
                        this.audioPath = ycmdRecordingUrlGet;
                    } else {

                    }
                } catch (e) {
                    item.isItemLoading = false;
                    // defaultErrorHandler(e);
                }
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => {
                    console.log('router error: ', err)
                });
            },
            refreshData() {
                this.currentItems = 0;
                this.totalItems = 0;
                this.page = 1;
                this.items = [];

                this.infiniteId++;
            },
            async infiniteHandler($state) {

                this.isLoading = true;

                try {
                    const response = await this.$apollo.query({
                        query: GET_MESSAGES,
                        variables: {
                            page: this.page,
                            limit: this.limit
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    console.log('response: ', response);
                    this.isLoading = false;

                    if (response.data.ycmdCallLogGet && response.data.ycmdCallLogGet && response.data.ycmdCallLogGet.calls) {

                        if (response.data.ycmdCallLogGet.calls.length) {
                            this.items.push(...response.data.ycmdCallLogGet.calls);
                            this.page++;

                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        // Update count
                        this.currentItems = this.items.length;
                        if (response.data.ycmdCallLogGet.total_count) {
                            this.totalItems = response.data.ycmdCallLogGet.total_count;
                        } else {
                            this.totalItems = 0;
                        }

                    } else {
                        // data did not come back
                        // console.log('pcpMessages data did not come back or is finished: ', response);
                        $state.complete();
                    }
                } catch (e) {
                    $state.complete();
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open
            }),
            ...mapGetters(
                'profile', ['getUserID']
            )
        }
    }

</script>

<style scoped>

    .tab {
        color: white !important;
    }

    .tabs-holder {
        width: 270px;
        margin-bottom: -8px;
        margin-left: -4px;
    }

    .tabs--active {
        background-color: white;
        color: #167DD7 !important;
    }

    .message__section {
        height: 200px;
    }

    .list-item__row {
        width: 100%;
        padding-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
