import dataClient from '@/graphql/clients/axios';

// Initial state
const state = {
  pods: [],
};

const getters = {
  getPods: state => state.pods
};

const actions = {
  loadgroup: async ({commit, getters, dispatch}, payload) => {
    return result
  }
};

const mutations = {
  setPods (state, data) {
    state.pods = data;
  }  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
