import gql from 'graphql-tag'

export default gql`query pcpMessageStats($start: AWSDate!, $end: AWSDate!, $basis: PCPMessageStatBasis!) {
	pcpMessageStats(start: $start, end: $end, basis: $basis) {
		date
		message_types {
			message_type
			count
			confirmed_count
			average_confirmation_seconds
		}
		facilities {
			facility_id
			name
			facility_id
			message_types{
				message_type
				count
				confirmed_count
				average_confirmation_seconds
			}
		}
		providers {
			reference_id
			first_name
			last_name
			message_types{
				message_type
				count
				confirmed_count
				average_confirmation_seconds
			}
		}
  }
}`
