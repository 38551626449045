<template>
  <span
    v-html="participantText"
  >
  </span>

</template>

<script>

export default {
  props: {
    queries: {
      type: Array,
      default: () => []
    },
    highlightClass: {
      type: String,
      default: ''
    },   
    participants: {
      type: Array,
      default: () => []
    },
    currentUserId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
  }),
  methods: {
  },
  computed: {
    participantText () {
      console.log('this.participants', this.participants)
      let newText = ''

      let interimTxt = ''
      let delimiter = ''
      this.participants.map(p => {
    
        if (p.user_id !== this.currentUserId) {
          interimTxt += `${delimiter}${p.first_name} ${p.last_name}`

          if (p.app_version && p.app_version=='notinstalled') {
            interimTxt += '\r&nbsp;'
          } else if (p.ooo) {
            interimTxt += '\t&nbsp;'
          }

          delimiter = ', '
        }
      })

      let search = this.queries.length ? this.queries[0].toLowerCase() : '' //for now just support one token

      if (search) {

        let interimTxtLower = interimTxt.toLowerCase()

        let currentIndex = 0
        let lastIndex = 0

        currentIndex = interimTxtLower.indexOf(search, 0)

        let i = 0
        while (i < 3 && currentIndex >= 0) {

          newText += interimTxt.substring(lastIndex, currentIndex)

          let foundText = interimTxt.substring(currentIndex, currentIndex+search.length)

          newText += `<mark index="${i}" text="${foundText}" class="text__highlight text__highlight2">${foundText}</mark>`

          lastIndex = currentIndex + search.length

          currentIndex = interimTxtLower.indexOf(search, currentIndex + search.length )

          i++
        }
        newText += interimTxt.substring(lastIndex)
        
/*
        let txt = ''
        if (interimTxt.toLowerCase.startsWith(search.toLowerCase())) {
          txt = interimTxt.substring(0, search.length)
        }
*/

      }
      else {
        newText = interimTxt
      }

      let ooo = '<span class="v-badge v-badge--avatar v-badge--dot theme--light pl-1"><span class="v-badge__wrapper"><span title="Out of Office" aria-atomic="true" aria-label="Badge" aria-live="polite" role="status" class="v-badge__badge yellow" style="inset: auto auto calc(100% - 2px) calc(100% - 2px);"></span></span></span>'

      let ret = newText.replaceAll('\t', ooo)
      console.log('ret', ret)

      let av = '<span class="v-badge v-badge--avatar v-badge--dot theme--light pl-1"><span class="v-badge__wrapper"><span title="App not installed" aria-atomic="true" aria-label="Badge" aria-live="polite" role="status" class="v-badge__badge red" style="inset: auto auto calc(100% - 2px) calc(100% - 2px);"></span></span></span>'

      ret = ret.replaceAll('\r', av)
      console.log('ret', ret)



      return ret

    },

  },
  /*
  mounted () {

    if (this.useParent) {
      this.$el.parentElement.setAttribute('title', 'Out of Office vmm')
    } else {
      this.$el.setAttribute('title', 'Out of Office vmm2')
    }
    
  } 
  */ 
}
</script>
