import gql from 'graphql-tag'

export default gql`query ycmdPatientTransferGet($page: Int, $limit: Int, $transferred_by_id: ID, $to_facility_id: ID, $from_facility_id: ID, $message_date: String)
 {
	ycmdPatientTransferGet(page: $page, 
							limit: $limit, 
							transferred_by_id: $transferred_by_id, 
							to_facility_id: $to_facility_id, 
							from_facility_id: $from_facility_id,
							message_date:  $message_date)
    {
		total_count
		patients_transferred {
			date_received
			date_transferred
			from_facility_id
			from_facility {
				address
				id
				name
				shortcode
				units {
					established_patients_only
					unit_id
					name
					shortcode
				}
			}
			patient_id
			patient {
				dob
				first_name
				has_notes
				id
				last_name
			}
			received_by_id
			received_by {
				id
				username
				first_name
				last_name
				middle_initial
				group_id
				email
			}
			to_facility_id
			to_facility {
				address
				id
				name
				shortcode
				units {
					established_patients_only
					unit_id
					name
					shortcode
				}
			}
			transferred_by_id
			transferred_by {
				id
				username
				first_name
				last_name
				middle_initial
				group_id
				email
			}
		}
    }
}`
