<template>

    <v-autocomplete
        v-model="selectedState"
        :items="states"
        :outlined="outlined"
        dense
        :label="label"
        item-value="value"
        item-text="name"
        @change="changed"
        :rules="rules"
        :background-color="backgroundColor"
    >
    </v-autocomplete>

</template>

<script>
    import { states } from '@/data/groupApp/dataTypes';

    export default {
        components: {
        },
        props: {
            label: {
                type: String,
                default: 'Select the users'
            },   
            value: {
                default: null
            },
            rules: {
                type: Array,
                default: () => []
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            outlined: {
                default: false,
                type: Boolean
            },
    
        },
        data: () => ({
            states
        }),
        async mounted() {
        },        
        methods: {
            changed (params) {
                this.$emit('changed', this.value)
            }
        },
        computed: {
            selectedState: {
                get: function() {
                    return this.value ;
                },
                set: function(newValue) {
                    this.$emit('input', newValue)
                }
            }, 
        },
        watch: {
        }
    }
</script>
