<template>

    <div class="full-width full-height d-flex align-center justify-center flex-column">
        <div class="mt-2 infinite-loading__text ycmd-light-blue__text">Unable to get data</div>
        <v-tooltip bottom
                   nudge-bottom="10"
                   :disabled="$vuetify.breakpoint.xsOnly"
                   color="ycmd-black"
                   content-class="fade-0 pa-4 pt-2 pb-2">
            <template v-slot:activator="{ on }">
                <v-btn icon dark v-on="on" @click="refreshHandler" color="green" class="mt-4">
                    <v-icon :size="36">refresh</v-icon>
                </v-btn>
            </template>
            <span>Retry</span>
        </v-tooltip>
    </div>

</template>

<script>

    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        name: 'refreshInitData',
        components: {
            'infinite-loading': InfiniteLoading
        },
        props: {
          refreshHandler: Function
        },
        data: () => ({
        }),
        mounted() {},
        methods: {

        },
        computed: {

        }
    }
    </script>

<style scoped>

</style>
