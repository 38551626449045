import gql from 'graphql-tag'

export default gql`mutation providerPageSet($operation: String!, $parameters: [NameIdValueInput]) {
	providerPageSet(operation: $operation, parameters: $parameters)
    {
      name
      id
      value
    }
}`
