<template>
  <div
    style="width: 100%;height: 100%"
  >
      <loading theme="light" :center="true" v-if="getCurrentRoomLoading" size="small" class="pr-5" />

      <v-container
        v-else-if="hasCurrentRoom"
        fluid
        class="ma-0 pa-0 text-none  ycmd-light-blue white--text br-3 fs-12 "
        height="100%"
        width="100%"
        style="min-height: 40px;max-height:40px;overflow: hidden;"
      > 
        <v-row
          class="ma-0 pl-1 pt-1 pb-0 pr-0 "
        >
          <v-col
            :cols="getCurrentRoomBadgeCount ? 11: 12"
            class="ma-0 pa-0 text-caption text-no-wrap text-truncate grow"
            style="fontSize: 8px !important;line-height: 10px;text-align: left"
          >
            <span>{{getCurrentRoomLastPostAuthor}}:</span>
          </v-col>
          <v-col
            v-if="getCurrentRoomBadgeCount"
            cols="1"
            class="ma-0 pa-0 text-caption text-no-wrap text-truncate "
          >
            <div class="d-flex align-center justify-center pink fw-500 text-right mr-1 white--text"
                  style="height: 15px; min-width: 15px; max-width: 15px; border-radius: 15px;fontSize: 8px ;line-height: 10px;">
              {{ getCurrentRoomBadgeCount }}
            </div>
          </v-col>
        </v-row>
        <v-row
          class="ma-0 pl-2 pt-1 pb-0 pr-0 "  
          style="white-space: normal !important;"      
        >
          <v-col
            class="ma-0 pa-0 text-caption"
            cols="12"
            style="fontSize: 8px !important;line-height: 10px;text-align: left;white-space: normal !important;"
          >
            <span style="white-space: normal;">{{getCurrentRoomLastPostText}}</span>
          </v-col>
        </v-row>
      </v-container>

      <v-container
        v-else
        fluid
        class="ma-0 pa-0 text-none  ycmd-light-blue white--text br-3 fs-12 "
        height="100%"
        width="100%"
        style="min-height: 40px;max-height:40px;overflow: hidden;"
      > 
        <v-row
          class="ma-0 pl-1 pt-1 pb-0 pr-0 "
        >
          <v-col
            cols="12"
            class="ma-0 pa-0 text-caption text-no-wrap text-truncate grow"
            style="fontSize: 10px !important;line-height: 12px;text-align: left"
          >
            No room selected.
          </v-col>
        </v-row>
      </v-container>



  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Loading from '@/components/loading-elipsis';

export default {
  props: {
  },
  data: () => ({
  }),
  methods: {

  },
  computed: {
    ...mapGetters('chat', ['getCurrentRoomLastPostAuthor', 'getCurrentRoomLastPostText','getCurrentRoomName','getCurrentRoomLastPostTime', 'getCurrentRoomLoading', 'getCurrentRoom']),
    ...mapGetters('chat', ['getBadgeCountGlobal','getCurrentRoomBadgeCount']),
    hasCurrentRoom () {

      let has = this.getCurrentRoom && this.getCurrentRoom.id ? true : false
      return has 
    }
  },
  watch: {
  },
  components: {
    loading: Loading
  }
}
</script>
