import gql from 'graphql-tag'

export default gql`mutation authenticateBegin($email: String!, $password: String!) {
	authenticateBegin(email: $email, password: $password) {
    ChallengeName
    Session
	AuthenticateResult {
		ycmdAuthenticate {
			accessToken
			accepted_terms_of_service_pcp
			is_device_registered_for_pp
			tokenType
			refreshToken
			idToken
			roles {
				role
				role_label
				group {
					id
					name
				}
				facilities {
					id
					name
				}
			}
		}
		phoenixAuthenticate {
			authentication_status {
				lastlogin
				logins
				authentication_type
				required_authentication_type
				prompt_for_twofactor
				twofactor_grace_period_days
				twofactor_grace_period_minutes_left
				twofactor_last_prompted
				twofactor_first_prompted
				twofactor_conversion_date 
			}
			first_name
			last_name
			accepted_terms_of_service
			is_device_registered
			token
			user_id
			link_id
			linked_users {
				id
				roles
			}
			linked_groups {
				user_id
				group_id
				group_name
			}
			provider_id
			member_id
			nurse_id
			pcp_user_id
			pcp_analyst_id
			roles
			legacy_roles
			refresh_token
			group_id
			group_name
			group {
				voicemail {
					mailboxes {
						name
					}
				}
				forwarding {
					days
					start_time
					end_time
				},
				settings {
					chat_allow_same_participant_rooms
					explicit_message_confirmation
					explicit_message_confirmation_can_override  					
				}
			}
			online_status
			reference_id
			settings {
				callback_number
				covr_delivery_type
				theme
				inactivity_timeout_minutes
				preferences {
					name
					value
				}
				enable_chat_notifications
				message_view
				message_grid_font_adjustment
				enable_chat_notifications_days {
					day
					start_time
					end_time
				}
				room_settings {
					room_id
					settings {
						enable_chat_notifications
						enable_chat_notifications_days {
							day
							start_time
							end_time
						}
					}
				}
			}
		}
	}
  }
}`
