import gql from 'graphql-tag'

export default gql`mutation ycmdContactAdd(
		$specialty: String,
		$first_name: String,
		$notes: String,
		$phone_numbers: [PhonenumberInput],
		$company: String,
		$addresses: [AddressInput],
		$last_name: String,
		$email_addresses: [EmailAddressInput]
	) {
		ycmdContactAdd(specialty: $specialty, first_name: $first_name, notes: $notes, phone_numbers: $phone_numbers, company: $company, addresses: $addresses, last_name: $last_name, email_addresses: $email_addresses) {
			... on ContactCustom {
				id
				type
				is_favorite    
				addresses{
					label
					street
					street2
					city
					state
					zip_code
				}
				company
				email_addresses {
					address
					label
				}
				first_name
				last_name
				group_id
				group_name 
				notes
				phone_numbers {
					label
					number
					extension
				}
				specialty
			}
		}
}`
