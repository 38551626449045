<template>
  <v-dialog
      v-model="value"
      persistent
      max-width="400"
      >
      <v-card>
          <v-card-title class="text-h5">
              Group Forwarding 
          </v-card-title>
          <v-card-subtitle
            class="pt-5"
          >
              Select Days
          </v-card-subtitle>

          <v-card-text>
            <v-btn-toggle
                v-model="days"
                multiple
                color="primary"
              >
                <v-btn>
                  Su
                </v-btn>
                <v-btn>
                  M
                </v-btn>
                <v-btn>
                  T
                </v-btn>
                <v-btn>
                  W
                </v-btn>
                <v-btn>
                  Th
                </v-btn>
                <v-btn>
                  F
                </v-btn>
                <v-btn>
                  Sa
                </v-btn>
              </v-btn-toggle>
          </v-card-text>
          <v-card-text>
            <v-container fluid
              class="pa-0 ma-0"
            >
              <v-row
                class="pa-0 ma-0"
              >
                <v-col
                  cols="6"
                  class="pa-0 ma-0"
                >
                  <time-picker
                      v-model="start"
                      label="Start Time"
                  ></time-picker>
                </v-col>
                <v-col
                  cols="6"
                  class="pa-0 ma-0"
                >
                  <time-picker
                      v-model="end"
                      label="End Time"
                  ></time-picker>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="clearGroupForwarding"
              :disabled="sending"
          >
              Clear Forwarding
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="sendGroupForwarding"
              :disabled="sending || !isValid"
          >
              Save
          </v-btn>
          <v-btn
              text
              @click="closeDialog()"
              :disabled="sending"
          >
              Cancel
          </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
  import timePicker from '@/components/datetime/timepicker'
  import UPDATE_GROUP_DATA from '@/graphql/mutations/phoenix/ycmdGroupUpdate';

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      forwarding: {
        default: () => {},
        type: Object
      }
    },
    data: () => ({
      days: [],
      start:null,
      end:null,
      sending:false
    }),
    mounted() {
    },
    methods: {
      closeDialog() {
        this.sending = false
        this.$emit('input', false)
      },
      async clearGroupForwarding () {
        this.days = []
        await this.doSendGroupForwarding()
      },
      async sendGroupForwarding () {
        await this.doSendGroupForwarding()
      },
      async doSendGroupForwarding() {
        this.sending = true
        const response = await this.$apollo.mutate({
          mutation: UPDATE_GROUP_DATA,
          variables: {
            forwarding: this.forwardingSave
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });

        this.sending = false;
        if (response && response.data && response.data.ycmdGroupUpdate && response.data.ycmdGroupUpdate.status === 'success') {
          this.$emit('saved', this.forwardingSave )
        }
        
        this.closeDialog()
      },
      convertDays (days) {
        let arr = []
        if (days && days.length) {
          days.map(d => {
            arr.push(d-1)
          })
        }
        return arr
      }
    },
    components: {
      'time-picker': timePicker
    },
    computed: {
      forwardingSave () {
        let obj = {
          days: [],
          start_time: `${this.start}:00`,
          end_time: `${this.end}:00`
        }
        let arr = this.days.sort()
        arr.map(a => {
          obj.days.push(a+1)
        })
        return obj
      },
      isValid () {
        return this.days && this.days.length
      }
    },
    watch: {
      value(newVal, oldVal) {
        if (newVal) {
          if (this.forwarding) {
            this.start = this.forwarding.start_time
            this.end = this.forwarding.end_time
            this.days = this.convertDays(this.forwarding.days)
          }
        }
      },
    }
  }
</script>

