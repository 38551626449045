<template>

    <div v-if="item">
        <v-card
          class="mx-auto full-width"
          style="min-height: 100%;"
          outlined
        >
            <v-card-title class="d-flex justify-center">
                <v-icon
                    large
                    left
                    :color="'ycmd-blue'"
                >
                    person
                </v-icon>
                <span class="title font-weight-light">{{ (item.first_name || item.last_name) ? `${item.first_name} ${item.last_name}` : 'N/A' }}</span>
            </v-card-title>
            <v-card-subtitle class="d-flex justify-center">{{ item.group_name || 'N/A' }}</v-card-subtitle>
        </v-card>

        <v-card
          class="mx-auto full-width mt-2"
          style="min-height: 100%;"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">
                  {{ item.type || 'N/A' }}
              </div>
              <template v-if="item.phone">
                  <v-list-item-title class="headline mt-2">
                      Office
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ formatPhoneNumber(item.phone) }}</v-list-item-subtitle>
              </template>
              <template v-if="item.device_phone">
                  <v-list-item-title class="headline mt-2">
                      Mobile
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ formatPhoneNumber(item.device_phone) }}</v-list-item-subtitle>
              </template>
              <template v-if="item.ycmd_phone">
                  <v-list-item-title class="headline mb-1 mt-2">
                      YouCallMD
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ formatPhoneNumber(item.ycmd_phone) }}</v-list-item-subtitle>
              </template>
            </v-list-item-content>

            <!-- <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar> -->
          </v-list-item>

          <v-card-actions class="ma-2 ml-0">
              <v-tooltip bottom
                 nudge-bottom="0"
                 nudge-right="0"
                 color="ycmd-black"
                 content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark rounded large
                         @click="toggleContactFavorite(item)"
                         v-on="on"
                         class=""
                         :color="`${item.is_favorite ? '#FFCC00' : 'grey'}`">
                      <v-icon :size="30">star</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.is_favorite ? 'Remove Favorite' : 'Favorite' }}</span>
              </v-tooltip>
          </v-card-actions>
        </v-card>
    </div>

</template>

<script>
    import { capitalizeFirstLetter } from '@/methods/utils';
    import {formatPhoneNumber} from '@/methods/globalMethods';

    export default {
        name: 'providerItemDetails',
        props: {
            item: Object,
            toggleContactFavorite: Function
        },
        data: () => ({
            formatPhoneNumber,
            capitalizeFirstLetter
        }),
        mounted() {},
        methods: {

        },
        computed: {

        }
    }

</script>

<style scoped>

</style>
