<template>

  <v-layout class="view ycmd-blue-gradient">

    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="false"
             :background-color="`#101622`"
             :color="`white`"
             :loader="`bars`">
    </loading>

    <i-card :viewIndex="card.index"
            :viewCount="1"
            :leftPanel="card.panels.left"
            :rightPanel="card.panels.right"
            :topPanel="card.panels.top"
            :bottomPanel="card.panels.bottom"
            class="i-card">

      <div class="i-card__main pa-2">

        <div class="i-card__main__header">
          <div class="d-flex align-center flex-grow-1 ml-1">
            <v-btn icon class="hidden-sm-and-up mr-2" @click="toggleMenu">
              <v-icon :size="22" color="white">menu</v-icon>
            </v-btn>
            <div class="d-flex align-center white--text fs-13" style="width: 168px">
              <div class="hidden-xs-only fade-2 hidden-sm-and-down">Showing</div>
              <div class="fw-500 fs-14 pl-1 pr-1">{{ this.analysts.length || '0' }}</div>
              <div class="fade-2">of</div>
              <div class="fw-500 fs-14 pl-1 pr-1">{{total_count || '0'}}</div>
              <v-tooltip bottom
                         nudge-bottom="5"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark depressed v-on="on" class="ml-2 mr-2">
                    <v-icon :size="22" @click="searchUsers()">refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh List</span>
              </v-tooltip>
            </div>
            <div class="d-flex flex-grow-1">
              <v-text-field hide-details dark flat single-line dense solo
                            ref="searchBarRef"
                            v-model="searchValue"
                            v-on:keyup.enter="searchUsers"
                            label="Search"
                            color="blue"
                            :class="`search-bar ycmd-medium-blue ml-2 ${ $vuetify.breakpoint.xsOnly ? 'br-2' : 'br-0' } br_tl-2 br_bl-2`"/>
              <v-btn icon dark depressed
                     @click="searchUsers()"
                     class="hidden-xs-only ycmd-dark-blue mr-2 br-0 br_tr-2 br_br-2"
                     style="width: 56px; height: 38px">
                  <v-icon>search</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center justify-end ml-1 mr-3">
            <v-tooltip bottom
                       v-if="this.isAdmin"
                       nudge-left="34"
                       nudge-bottom="5"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       color="ycmd-black"
                       content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn icon dark depressed v-on="on" @click="() => { slide('right'); clearCreateUserDialog(); onCreateUserWindowMount(); }" class="ml-2">
                  <v-icon :size="22">add</v-icon>
                </v-btn>
              </template>
              <span>Create User</span>
            </v-tooltip>
          </div>
        </div>

        <div class="i-card__main__content white pa-2 br-2">

          <v-container fluid class="fixed-header pl-4 hidden-sm-and-down">

                  <!-- Header: Make sure this matches layout below -->
                  <v-row no-gutters>
                      <v-col cols="6" sm="4" md="2" lg="2">
                          <div class="fixed-header__column">Name
                              <v-btn icon color="ycmd-blue">
                                  <v-icon :size="16">arrow_upward</v-icon>
                              </v-btn>
                          </div>
                      </v-col>
                      <v-col cols="2" sm="4" md="1" lg="1">
                          <div class="fixed-header__column">Status
                              <v-btn icon color="ycmd-blue" class="hidden">
                                  <v-icon :size="16">arrow_downward</v-icon>
                              </v-btn>
                          </div>
                      </v-col>
                      <v-col cols="4" sm="4" md="2" lg="2">
                          <div class="fixed-header__column">Phone
                              <v-btn icon color="ycmd-blue" class="hidden">
                                  <v-icon :size="16">arrow_downward</v-icon>
                              </v-btn>
                          </div>
                      </v-col>
                      <v-col cols="6" sm="4" md="3" lg="3">
                          <div class="fixed-header__column">Email
                              <v-btn icon color="ycmd-blue" class="hidden">
                                  <v-icon :size="16">arrow_downward</v-icon>
                              </v-btn>
                          </div>
                      </v-col>
                      <v-col cols="6" sm="4" md="2" lg="2">
                          <div class="fixed-header__column">Role
                              <v-btn icon color="ycmd-blue" class="hidden">
                                  <v-icon :size="16">arrow_downward</v-icon>
                              </v-btn>
                          </div>
                      </v-col>
                      <v-col cols="12" sm="4" md="2" lg="2">
                          <div class="fixed-header__column">Created
                              <v-btn icon color="ycmd-blue" class="hidden">
                                  <v-icon :size="16">arrow_downward</v-icon>
                              </v-btn>
                          </div>
                      </v-col>
                  </v-row>

                  <!-- Spacing for list buttons -->
                  <div style="width: 88px">&nbsp;</div>
          </v-container>

          <div class="i-card__scrollable">

            <div class="list-container">

              <v-container fluid class="list-item flex-shrink-0 pa-2 pl-4 pr-4" v-for="(user, i) in analysts" :key="`user_${i}`">

                  <v-row no-gutters>
                      <v-col cols="6" sm="4" md="2">
                          <div class="d-flex fill-height align-center">
                              <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                                  <div class="hidden-md-and-up label-title">Name</div>
                                  <div :class="`label-value fs-11 fw-500 ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                      {{ user.last_name + ', ' + user.first_name || 'Not Provided' }}
                                  </div>
                              </div>
                          </div>
                      </v-col>
                      <v-col cols="6" sm="4" md="1" >
                          <div class="d-flex fill-height align-center">
                              <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                                  <div class="hidden-md-and-up label-title">Status</div>
                                  <div :class="`label-value fs-11 fw-500 ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                      {{ user.deleted === 1 ? 'Inactive' : 'Active' }}
                                  </div>
                              </div>
                          </div>
                      </v-col>
                      <v-col cols="6" sm="4" md="2">
                          <div class="d-flex fill-height align-center">
                              <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                                  <div class="hidden-md-and-up label-title">Phone</div>
                                  <div :class="`label-value fs-11 fw-500 ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                      {{ user.phone || 'Not Provided' }}
                                  </div>
                              </div>
                          </div>
                      </v-col>
                      <v-col cols="6" sm="4" md="3">
                          <div class="d-flex fill-height align-center">
                              <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`" style="width: 100%;">
                                  <div class="hidden-md-and-up label-title">Email</div>
                                  <div class="label-value fs-11 fw-500" style="max-width: 100%; word-wrap: break-word;">{{ user.email || 'Not Provided' }}</div>
                              </div>
                          </div>
                      </v-col>
                      <v-col cols="6" sm="4" md="2">
                          <div class="d-flex fill-height align-center">
                              <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                                  <div class="hidden-md-and-up label-title">Role</div>
                                  <div class="label-value fs-11 fw-500">{{ user.roles ? user.roles.map((role) => rolesOriginalParsed[role] || 'undefined').join(', ') : 'No Roles' }}</div>
                              </div>
                          </div>
                      </v-col>
                      <v-col cols="6" sm="4" md="2">
                          <div class="d-flex fill-height align-center">
                              <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                                  <div class="hidden-md-and-up label-title">Created</div>
                                  <div class="label-value fs-10">{{ user.date_created !== null ? formatTime(user.date_created) + ' PST' || 'Not Provided' : 'Not Provided' }}</div>
                              </div>
                              <div class="hidden-sm-and-up">
                                <v-tooltip left
                                           :disabled="$vuetify.breakpoint.xsOnly"
                                           color="ycmd-black"
                                           content-class="fade-0">
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon dark
                                           @click="resendCode(user)"
                                           :disabled="user.resendingVerification === true" v-on="on" color="ycmd-blue" class="mr-0">
                                      <v-icon :size="18">email</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Resend Code</span>
                                </v-tooltip>
                                <v-tooltip left
                                           :disabled="$vuetify.breakpoint.xsOnly"
                                           color="ycmd-black"
                                           content-class="fade-0">
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon dark
                                           @click="disableUserPress(user)"
                                           v-on="on" color="ycmd-green" class="mr-0">
                                      <v-icon :size="20">delete</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactive User</span>
                                </v-tooltip>
                                <v-tooltip left
                                           :disabled="$vuetify.breakpoint.xsOnly"
                                           color="ycmd-black"
                                           content-class="fade-0">
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon dark text small
                                           @click="changeView('details-view', { data: { user: user }})"
                                           v-on="on" color="white" class="ycmd-blue ml-1">
                                      <v-icon :size="14">arrow_forward</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Show Details</span>
                                </v-tooltip>
                              </div>
                          </div>
                      </v-col>
                  </v-row>

                  <div class="hidden-xs-only">
                      <div class="d-flex flex-shrink-0 fill-height align-center justify-center">
                        <v-tooltip left
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark
                                   @click="resendCode(user)"
                                   :disabled="user.resendingVerification === true" v-on="on" color="ycmd-blue" class="mr-0">
                              <v-icon :size="24">email</v-icon>
                            </v-btn>
                          </template>
                          <span>Resend Code</span>
                        </v-tooltip>
                        <v-tooltip left
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark
                                   @click="disableUserPress(user)"
                                   v-on="on" color="ycmd-green" class="mr-3">
                              <v-icon :size="24">delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Deactive User</span>
                        </v-tooltip>
                        <v-tooltip left
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                          <template v-slot:activator="{ on }">
                            <v-btn icon dark text small
                                   @click="changeView('details-view', { data: { user: user }})"
                                   v-on="on" color="white" class="ycmd-blue mr-1">
                              <v-icon :size="14">arrow_forward</v-icon>
                            </v-btn>
                          </template>
                          <span>Show Details</span>
                        </v-tooltip>
                      </div>
                  </div>

              </v-container>

              <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" class="ycmd-blue--text relative fw-500 fs-20">
                <span slot="no-more" />
                <div slot="no-results">
                  <div class="pt-4 mt-4">No Results</div>
                </div>
                <div slot="spinner">
                  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
              </infinite-loading>

            </div>

          </div>

        </div>

      </div>

      <template slot="right-panel">

        <div class="i-card__panel i-card__panel__right ycmd-blue-gradient pa-2">

          <div class="i-card__panel__header">

            <div class="d-flex align-center flex-grow-1 ml-1">

              <v-tooltip bottom
                         nudge-bottom="10"
                         nudge-right="50"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark depressed v-on="on" @click="card.panels.right = false">
                    <v-icon :size="22">close</v-icon>
                  </v-btn>
                </template>
                <span>Cancel</span>
              </v-tooltip>

                <div class="white--text fs-18 ml-4">
                    New User
                </div>

            </div>
            <div class="d-flex align-center justify-end ml-2">

            </div>
          </div>

          <div class="i-card__panel__content white pa-2 br-2">

            <div class="i-card__scrollable">

                <v-container fluid class="ycmd-light-gray full-height b b-1 pa-0 pl-4 pr-4 br-2">

                <v-form
                      ref="createUserForm"
                      v-model="validCreateUser"
                      class="d-flex flex-column full-width full-height">

                    <div class="full-width">
                        <v-row>
                            <v-col cols="6" md="3">
                                <div class="label-title pt-2 pb-2">First Name</div>
                                <div class="full-width">
                                    <v-text-field
                                            light solo dense flat outlined
                                            :tabindex="4"
                                            v-on:keyup.enter="() => {creatingUser=true; createUserValidation()}"
                                            v-model="firstName"
                                            :rules="[rules.required]"
                                            label="First Name"/>
                                </div>
                            </v-col>
                            <v-col cols="6" md="3">
                                <div class="label-title pt-2 pb-2">Last Name</div>
                                <div>
                                    <v-text-field
                                            light solo dense flat outlined
                                            :tabindex="5"
                                            v-on:keyup.enter="() => {creatingUser=true; createUserValidation()}"
                                            v-model="lastName"
                                            :rules="[rules.required]"
                                            label="Last Name"/>
                                </div>
                            </v-col>
                            <v-col cols="6" md="3">
                                <div class="label-title pt-2 pb-2">Mobile Number</div>
                                <div>
                                    <v-text-field
                                            light solo dense flat outlined
                                            :tabindex="6"
                                            v-on:keyup.enter="() => {creatingUser=true; createUserValidation()}"
                                            v-model="mobile"
                                            :rules="[rules.required, rules.phone]"
                                            label="Mobile Number" />
                                </div>
                            </v-col>
                            <v-col cols="6" sm="6" md="3">
                                <div class="label-title pt-2 pb-2">Email</div>
                                <div>
                                    <v-text-field
                                            light solo dense flat outlined
                                            :tabindex="7"
                                            v-on:keyup.enter="() => {creatingUser=true; createUserValidation()}"
                                            v-model="email"
                                            :rules="[rules.required]"
                                            label="Email"/>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6" sm="3">
                                <div class="label-title pt-2 pb-2">Role</div>
                                <v-select
                                        light solo dense flat outlined
                                        :tabindex="1"
                                        :items="roles"
                                        :rules="[rules.required]"
                                        v-model="roleSelected"
                                        append-icon="expand_more" />
                            </v-col>
                            <v-col cols="6" sm="9">
                                <template v-if="this.roleSelected === 'Facility Admin' || this.roleSelected === 'Clinical Analyst'">
                                    <div class="label-title pt-2 pb-2">Facilities</div>
                                    <v-select
                                            light solo dense flat outlined
                                            v-model="selectedFacilitiesSearchedAll"
                                            :items="myFacilities"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Add Facilities"
                                            multiple
                                            item-text="name"
                                            append-icon="expand_more"
                                            return-object
                                    ></v-select>
                                </template>
                                <template v-if="this.roleSelected === 'Group Admin'">
                                    <div class="label-title pt-2 pb-2">Groups</div>
                                    <div>
                                        <v-select
                                                light solo dense flat outlined
                                                v-model="selectedGroup"
                                                :items="myGroups"
                                                :menu-props="{ maxHeight: '400' }"
                                                label="Add Group"
                                                item-text="name"
                                                :chips="true"
                                                return-object/>
                                    </div>
                                </template>
                            </v-col>
                        </v-row>

                        <div v-if="this.createUserError" class="fs-10" style="padding: 0px; padding-left: 18px; padding-right: 18px; margin-bottom: 10px; color: red;">
                            {{this.createUserError}}
                        </div>
                        <div v-if="this.createUserSuccess" class="fs-10" style="padding: 0px; padding-left: 18px; padding-right: 18px; margin-bottom: 10px; color: green;">
                            {{this.createUserSuccess}}
                        </div>
                    </div>

                  <div class="d-flex align-end justify-end flex-grow-1 pb-4">
                    <v-btn class="mr-2" v-if="this.YCMDUserId" dark depressed color="ycmd-blue" @click="clearCreateUserDialog()">
                        <span>Cancel</span>
                    </v-btn>
                    <v-btn dark depressed color="ycmd-blue" @click="creatingUser=true; createUserValidation()">
                        <span v-if="!this.YCMDUserId">Create</span>
                        <span v-else>Create With Existing User</span>
                    </v-btn>
                  </div>

                </v-form>

              </v-container>

            </div>
          </div>
        </div>

      </template>

    </i-card>

    <v-dialog light v-model="dialog" max-width="400">

<!--      <v-card v-if="currentDialog==='options'" color="ycmd-blue" light>-->
<!--        <v-card-text class="fs-18" style="text-align: center">-->
<!--          User Options-->
<!--        </v-card-text>-->

<!--        <v-card-actions>-->
<!--          <v-layout column>-->
<!--            &lt;!&ndash; <v-btn text color="white" @click="currentDialog = 'resetPassword'; dialog = true;">Reset Password</v-btn>-->
<!--            <v-spacer/> &ndash;&gt;-->
<!--            <v-btn text color="white" @click="currentDialog = 'removeUser'; dialog = true;">Remove User</v-btn>-->
<!--            <v-spacer/>-->
<!--            <v-btn text color="white" @click="clearDialog()">Cancel</v-btn>-->
<!--            </v-spacer/>-->
<!--            <v-spacer style="height: 3px;"/>-->
<!--          </v-layout>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->

      <v-card v-if="currentDialog==='removeUser'" color="ycmd-blue">

          <v-card-title class="white--text">
              <v-icon dark class="mr-4">error_outline</v-icon> Disable User
          </v-card-title>

        <v-card-text class="white pt-4">
            <div class="fs-14">Are you sure you want to disable the user?</div>
            <div class="red--text pt-4 fs-18">{{currentSelectedUser.first_name}} {{currentSelectedUser.last_name}} </div>
        </v-card-text>

        <v-card-actions>
          <v-btn dark text color="white" @click="clearDialog()">Cancel</v-btn>
          <v-spacer/>
          <v-btn dark text @click="disableAdmin()">Disable</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="currentDialog==='existingUserYCMD'" color="ycmd-blue">

          <v-card-title class="white--text">
              <v-icon dark class="mr-4">error_outline</v-icon> User already exists
          </v-card-title>

        <v-card-text class="white pt-4">
            <div class="fs-14">Do you want to use the existing YCMD account?</div>
            <div class="red--text pt-4 fs-18">{{currentSelectedUser.first_name}} {{currentSelectedUser.last_name}} </div>
        </v-card-text>

        <v-card-actions>
          <v-btn dark text color="white" @click="clearDialog()">Cancel</v-btn>
          <v-spacer/>
          <v-btn dark text @click="disableAdmin()">Create With Existing</v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>

  </v-layout>

</template>

<script>
  // import hardcoded facilities
  import { hardCodedFacilities, hardCodedGroups } from '@/data/pcpAppAdmin/hardCodedFacilities.js';
  // Import component
  import { format, parseISO } from 'date-fns';
  import Loading from 'vue-loading-overlay';
  import InfiniteLoading from 'vue-infinite-loading';

  import { defaultErrorHandler } from '../../../../graphql/handler/errorHandler';
  import { refreshHandler } from '../../../../graphql/handler/refreshHandler';

  import DISABLE_ADMIN from '../../../../graphql/mutations/adminDisableUser';
  import RESEND_ADMIN from '../../../../graphql/mutations/adminCreateUserResend';
  import CREATE_USER from '../../../../graphql/mutations/adminCreateUser';
  import GET_FACILITIES from '../../../../graphql/queries/pcpGetFacilities';
  import GET_GROUPS from '../../../../graphql/queries/pcpGetGroups';
  import GET_ADMINS from '../../../../graphql/queries/pcpGetAdmins';
  import {mapActions, mapState} from "vuex";

  export default {
    name: 'manage-users',
    components: {
      Loading,
      InfiniteLoading
    },
    props: {
        params: {
            type: Object
        }
    },
    data () {
      return {
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        test: [{name: 'hello'}, {name: 'test'}],
        model: 'tab-1',
        dialog: false,
        currentDialog: 'none',
        currentSelectedUser: null,
        creatingUser: false,
        createUserError: '',
        createUserSuccess: '',
        userRoles: [],
        roles: [],
        isAdmin: false,

        myGroups: [],
        myGroupSelected: {},
        myFacilities: [],
        myFacilitiesSelected: [],
        myFacilitiesAnalyst: [],
        myFacilitiesAnalystSelected: [],

        rolesParsed: {
            'PCP Admin': 'pcp_admin',
            'Group Admin': 'pcp_group_admin',
            'Facility Admin': 'pcp_facility_admin',
            'Clinical Analyst': 'pcp_analyst',
        },
        rolesOriginalParsed: {
            'pcp_admin': 'PCP Admin',
            'pcp_group_admin': 'Group Admin',
            'pcp_facility_admin': 'Facility Admin',
            'pcp_analyst': 'Clinical Analyst'
        },
        roleSelected: '',
        facilitySelected: '',
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',

        facilitiesSearched: [],
        selectedFacilitiesSearched: '',
        selectedFacilitiesSearchedAll: [],

        hardCodedFacilities: [],
        hardCodedGroups: [],
        selectedGroup: {},
        selectedGroupStart: {},

        groupsSearched: [],
        selectedGroupsSearched: {},
        selectedGroupsSearchedAll: [],
        groups: [],

        validCreateUser: false,

        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            phone: number => {
              const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
              return pattern.test(number) || 'Invalid phone number';
            }
        },
        searchFacility: null,
        searchGroup: null,
        isLoading: false,
        isLoadingSearch: false,
        isAnalyst: false,
        searchFilters: ['Email', 'License'],
        searchFilterSelected: 'Email',
        analysts: [],
        page: 1,
        searchValue: '',
        infiniteId: 1,
        total_count: '',
        YCMDUserId: null
      }
    },
    activated() {
        if (this.params.data && this.params.data.user && this.params.data.user.email) {
          for (let user in this.analysts) {
            const currentUser = this.analysts[user];
            // find the user
            if (currentUser.email === this.params.data.user.email) {
              this.analysts[user] = {
                ...this.analysts[user],
                ...this.params.data.user
              }
              this.$forceUpdate();
            }
          }
        }
        this.$nextTick(() => this.$refs.searchBarRef.focus());
    },
    mounted() {
      this.checkRolePermissions();
    },
    methods: {
      ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
      route(name, transition) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      },
      async resendCode(user) {
        try {
          user.resendingVerification = true;
          const response = await this.$apollo.mutate({
              mutation: RESEND_ADMIN,
              variables: {
                  email: user.email || null
              },
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
          });
          user.resendingVerification = false;
          this.$forceUpdate();
          if (response.data && response.data.adminCreateUserResend) {
            this.$toasted.success(`Successfully sent the code again!`);
          } else if (response.errors && response.errors.length > 0) {
            if (response.errors[0].message) {
              // console.log('adminCreateUserResend error message: ', response);
              this.$toasted.error(`Could not resend code - ${response.errors[0].message}`);
            } else {
              this.$toasted.error(`Error 3B. Invalid response.`);
            }
          } else {
            // data did not come back
            this.$toasted.error(`Did not resend code`);
            console.log('adminCreateUserResend data did not come back: ', response);
          }
        } catch (e) {
            user.resendingVerification = false;
            this.$toasted.error(`Error removing user`);
            console.log('adminDisableUser error catch: ', e);
            defaultErrorHandler(e);
        }
      },
      checkRolePermissions() {
        // check your own roles
        this.userRoles = JSON.parse(localStorage.getItem('roles'));

        this.myFacilities = hardCodedFacilities;
        this.myGroups = hardCodedGroups;

        for (let role in this.userRoles) {
          const currentRoleObj = this.userRoles[role];
          if (currentRoleObj.role === 'pcp_admin' || currentRoleObj.role === 'pcp_group_admin' || currentRoleObj.role === 'pcp_facility_admin') {
            this.isAdmin = true;
          }
          // parse for facilities to add here
          if (currentRoleObj.role === 'pcp_admin') {

            break;
          } else if (currentRoleObj.role === 'pcp_facility_admin') {
            if (currentRoleObj.facilities && currentRoleObj.facilities.length) {
              this.myFacilities = currentRoleObj.facilities;
              break;
            }
          } else if (currentRoleObj.role === 'pcp_group_admin') {
            // iterate over hardcoded groups and select the one that matches your user
            for (let group in hardCodedGroups) {
              const currentGroup = hardCodedGroups[group];
              if (currentGroup.shortName === currentRoleObj.group.name) {
                this.selectedGroupStart = currentGroup;
                this.selectedGroup = this.selectedGroupStart;
              }
            }
            break;
          } else if (currentRoleObj.role === 'pcp_facility_admin') {
            this.myFacilitiesAnalyst = currentRoleObj.facilities;
            break;
          }
        }
        if (!this.isAdmin) { this.isAnalyst = true };

        this.roles = this.rolesCanCreate();

        if (this.roles.length > 0) {
          this.roleSelected = this.roles[0];
        }
      },
      changeView(view, params) {

        this.$emit('onChangeView', {view: view, params: params});
      },
      slide(direction) {
        this.card.panels[direction] = !this.card.panels[direction];
      },
      formatTime(time) {
        return format(parseISO(time), 'iii MMM d, yyyy h:m a') || 'Not Provided'
      },
      onCreateUserWindowMount() {
        // set role
        if (this.roles.length > 0) {
          // console.log('setting role: ', this.roles[0])
          this.roleSelected = this.roles[0];
        }

        this.checkRolePermissions();
      },
      displayUserRoles(roles) {
        let roleParsed = '';
        for (let role in roles) {

        }
      },
      userPressed(user) {
          this.currentSelectedUser = user;
          this.currentDialog = 'options';
          this.dialog = true;
      },
      disableUserPress(user) {
          this.currentSelectedUser = user;
          this.currentDialog = 'removeUser';
          this.dialog = true;
      },
      clearDialog() {
          this.currentSelectedUser = null;
          this.currentDialog  = 'none';
          this.dialog = false;

          this.clearCreateUserDialog();
      },
      clearFacilitySearch() {
          this.facilitiesSearched = [];
          this.selectedFacilitiesSearched = '';
          this.selectedFacilitiesSearchedAll = [];
          this.searchFacility = '';
      },
      clearGroupSearch() {
          this.groupsSearched = [];
          this.selectedGroupsSearched = {};
          this.selectedGroup = {};
          this.selectedGroupsSearchedAll = [];
          this.searchGroup = '';
      },
      clearCreateUserDialog() {
          this.creatingUser = false;
          this.createUserError = '';
          this.createUserSuccess = '';
          this.validCreateUser = false;
          this.email = '';
          // this.roleSelected = '';
          this.facilitySelected = '';
          this.firstName = '';
          this.lastName = '';
          this.mobile = '';
          this.email = '';
          this.YCMDUserId = null;

          this.clearFacilitySearch();
          this.clearGroupSearch();

          if (this.$refs.createUserForm) {
              this.$refs.createUserForm.reset();
          }
      },
      removeUser() {

      },
      showDetails(name, transition, user) {

        this.$router.push({
          name: name, params: {
            transition: transition
          }
        }).catch(err => { console.log('router error: ', err) });
      },
      rolesCanCreate() {
        for (let role in this.userRoles) {
          const currentRoleObj = this.userRoles[role];
          if (currentRoleObj.role === 'pcp_admin') {
            return ['PCP Admin', 'Group Admin', 'Facility Admin', 'Clinical Analyst'];
          } else if (currentRoleObj.role === 'pcp_group_admin') {
            return ['Group Admin', 'Facility Admin', 'Clinical Analyst'];
          } else if (currentRoleObj.role === 'pcp_facility_admin') {
            return ['Facility Admin', 'Clinical Analyst'];
          }
        }
      },
      createUserValidation() {
        if (this.$refs.createUserForm.validate()) {
          this.createUser();
        }
      },
      async getFacilities(val) {
        this.isLoadingSearch = true;
        try {
          const response = await this.$apollo.query({
              query: GET_FACILITIES,
              variables: {
                  name: val,
                  limit: 10
              },
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
          });
          this.isLoadingSearch = false;
          if (response.data && response.data.pcpGetFacilities) {
            this.facilitiesSearched = response.data.pcpGetFacilities;
            return response.data.pcpGetFacilities;
          }
        } catch(e) {
          defaultErrorHandler(e);
        }
      },
      async getGroups(val) {
        this.isLoadingSearch = true;
        try {
          const response = await this.$apollo.query({
              query: GET_GROUPS,
              variables: {
                  name: val,
                  limit: 10
              },
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
          });
          this.isLoadingSearch = false;
          if (response.data && response.data.pcpGetGroups) {
            this.groupsSearched = response.data.pcpGetGroups;
            return response.data.pcpGetGroups;
          }
        } catch(e) {
          defaultErrorHandler(e);
        }
      },
      async disableAdmin() {
        this.isLoading = true;
        try {
          const response = await this.$apollo.mutate({
              mutation: DISABLE_ADMIN,
              variables: {
                  email: this.currentSelectedUser.email,
                  account: 'all'
              },
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
          });
          this.isLoading = false;
          if (response.data && response.data.adminDisableUser) {
            this.$toasted.success(`Successfully disabled the user!`);
            // optimistic removal
            this.analysts = this.analysts.filter(analyst => analyst.id !== this.currentSelectedUser.id);
            this.clearDialog();
          } else if (response.errors && response.errors.length > 0) {
            if (response.errors[0].message) {
              console.log('adminDisableUser error message: ', response);
              this.$toasted.error(`Error removing user - ${response.errors[0].message}`);
            } else {
              this.$toasted.error(`Error 3B. Invalid response.`);
            }
          } else {
            // data did not come back
            this.$toasted.error(`Error removing user`);
            console.log('adminDisableUser data did not come back: ', response);
          }
        } catch (e) {
            this.isLoading = false;
            this.$toasted.error(`Error removing user`);
            console.log('adminDisableUser error catch: ', e);
            defaultErrorHandler(e);
        }
      },
      addFacilityItem() {
        const currentFac = this.selectedFacilitiesSearched;
        if (!currentFac) return;
        for (let facility in this.selectedFacilitiesSearchedAll) {
          if (this.selectedFacilitiesSearchedAll[facility].name === this.selectedFacilitiesSearched.name) {
            return;
          }
        }
        const addObj = {
          name: currentFac.name,
          displayName: this.text_truncate(currentFac.name, 18, '...'),
          id: currentFac.id
        }
        this.selectedFacilitiesSearchedAll.push(addObj);

        this.selectedFacilitiesSearched = '';
        this.facilitiesSearched = [];
        this.searchFacility = '';
      },
      addGroupItem() {
        const currentGroup = this.selectedGroupsSearched;
        if (!currentGroup) return;
        if (this.selectedGroupsSearchedAll.length >= 1) return;
        for (let group in this.selectedGroupsSearchedAll) {
          if (this.selectedGroupsSearchedAll[group].name === this.selectedGroupsSearched.name) {
            return;
          }
        }
        const addObj = {
          name: currentGroup.name,
          displayName: this.text_truncate(currentGroup.name, 18, '...'),
          id: currentGroup.id
        }
        this.selectedGroupsSearchedAll.push(addObj);

        this.selectedGroupsSearched = '';
        this.groupsSearched = [];
        this.searchGroup = '';
      },
      text_truncate(str, length, ending) {
        if (length == null) {
          length = 100;
        }
        if (ending == null) {
          ending = '...';
        }
        if (str.length > length) {
          return str.substring(0, length - ending.length) + ending;
        } else {
          return str;
        }
      },
      async createUser() {
        this.createUserError = '';
        this.createUserSuccess = '';
        this.isLoading = true;

        // console.log('PARSED FACILITY ITEMS: ', this.parseFacilityItems());
        // console.log('first name: ', this.firstName);
        // console.log('last name: ', this.lastName);
        // console.log('role: ', this.rolesParsed[this.roleSelected]);
        // console.log('email: ', this.email);
        // console.log('mobile: ', this.mobile);
        // console.log('group id: ', this.parseGroupItems());
        try {
            const response = await this.$apollo.mutate({
                mutation: CREATE_USER,
                variables: {
                    role: this.rolesParsed[this.roleSelected],
                    first_name: this.firstName,
                    last_name: this.lastName,
                    mobile_number: this.mobile,
                    email: this.email,
                    facility_ids: this.parseFacilityItems() || null,
                    group_id: this.selectedGroup.id || null,
                    ycmd_user_id: this.YCMDUserId || null
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            this.isLoading = false;
            console.log('response: ', response);
            if (response.data && response.data.adminCreateUser) {
              this.clearCreateUserDialog();
              this.createUserSuccess = 'Successfully created the user!';
            } else if (response.errors && response.errors.length > 0) {
              // check if it's a current YCMD user
              if (response.errors[0].errorType === 'YCMDUserId' && response.errors[0].message) {
                const id = response.errors[0].message.split('user_id: ');
                if (id[1]) {
                  this.YCMDUserId = id[1];
                }
              }
              if (response.errors[0].message) {
                this.createUserError = response.errors[0].message;
              } else {
                this.createUserError = "Error 2A. Invalid response.";
              }
            } else {
              // data did not come back
              console.log('getPcpData data did not come back: ', response);
            }
        } catch (e) {
            this.isLoading = false;
            defaultErrorHandler(e);
        }
      },
      searchUsers() {
        this.page = 1;
        this.infiniteId += 1;
        this.analysts = [];
      },
      async infiniteHandler($state) {
        try {
            const response = await this.$apollo.query({
                query: GET_ADMINS,
                variables: {
                  limit: 20,
                  page: this.page,
                  sort_direction: 'asc',
                  sort_field: 'last_name',
                  search: this.searchValue,
                  suspended: false
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            this.isLoading = false;
            // console.log('getAnalyst: ', response);

            if (response.data.pcpAnalysts.total_count) {
              this.total_count = response.data.pcpAnalysts.total_count;
            }

            if (response.data.pcpAnalysts && response.data.pcpAnalysts.analysts && response.data.pcpAnalysts.analysts.length) {
              this.page += 1;
              this.analysts.push(...response.data.pcpAnalysts.analysts);
              $state.loaded();
            } else {
              // data did not come back
              // console.log('getAnalysts data did not come back or is finished: ', response);
              $state.complete();
            }
        } catch (e) {
          $state.complete();
          defaultErrorHandler(e);
        }
      },
      parseFacilityItems() {
        if (!this.selectedFacilitiesSearchedAll || this.selectedFacilitiesSearchedAll.length < 1) return null;
        return this.selectedFacilitiesSearchedAll.map(item => item.id);
      },
      parseGroupItems() {
        if (!this.selectedGroupsSearchedAll || this.selectedGroupsSearchedAll.length < 1) return null;
        // console.log(this.selectedGroupsSearchedAll[0].id);
        return this.selectedGroupsSearchedAll[0].id;
        // return this.selectedGroupsSearchedAll.map(item => item.id);
      }
    },
    watch: {
      searchFacility(val) {
        // Items have already been loaded
        // if (this.facilitiesSearched.length > 0) return
        this.getFacilities(val);

        // Items have already been requested
        // if (this.isLoadingSearch) return

      },
      selectedGroup(val) {
        // console.log('selected group: ', val);
      },
      searchGroup(val) {
          this.getGroups(val);
      },
      roleSelected(val) {
          this.clearFacilitySearch();
          this.clearGroupSearch();

          switch(val) {
            case 'Group Admin':
              this.selectedGroup = this.selectedGroupStart;
            break;
          }
      },
      selectedFacilitiesSearched (val) {
        // this.selectedFacilitiesSearched = '';
        // this.facilitiesSearched = [];
        // this.search = '';
      }
    },
    computed: {
      ...mapState({
        isMenuOpen: state => state.menu.open
      })
    }
  }

</script>

<style scoped>

  .message-item {
    /* cursor: pointer; */
  }

  .item-box {
    background-color: rgba(255, 255, 255, 1)
  }

  .item-box:hover {
    background-color: rgba(255, 255, 255, 0.8)
  }

  /*.view {*/
  /*  position: absolute;*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*}*/

</style>
