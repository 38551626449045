<template>

    <div class="d-flex">

        <v-container
            class="pa-0 ma-0"
            v-if="providerIds && providerIds.length === 1"
        >        
            <v-row
                class="pa-0 ma-0"
            >
                <v-col cols="12"
                    class="pa-0 ma-0 fw-700 fs-12"
                >
                    <label class="v-label v-label--active theme--light primary--text" style="font-size: 10px;font-weight: 400;">Provider</label>
                    <div>
                    {{`${providers[0].lastname ? providers[0].lastname+', ':''}${providers[0].firstname}${providers[0].credentials ? ': ' + providers[0].credentials: ''} `}}
                    </div>
                </v-col>
            </v-row>
            <v-row
                class="pa-0 ma-0"
                v-if="providers[0].specialty"
            >
                <v-col cols="12"
                    class="pa-0 ma-0"
                >
                    <span class="fw-300 fs-12">Specialty:</span> <span class="fw-400 fs-12">{{providers[0].specialty}}</span>
                </v-col>
            </v-row>
<!--
            <v-row
                class="pa-0 ma-0"
            >
                <v-col cols="12"
                    class="pa-0 ma-0"
                >
                    <span class="fw-300">Group:</span> <span class="fw-400">{{providers[0].practiceName}}</span>

                </v-col>
            </v-row>
-->            
        </v-container>
        <v-select light dense flat outlined
                v-else
                v-model="selectedProvider"
                label="Provider"
                :items="providers"
                item-text="name"
                item-value="id"
                background-color="white"
                :rules="rules"
                >
                
            <template v-slot:item="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                            {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                            <span class="fw-300 fs-12">Specialty:</span> <span class="fw-400 fs-12">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                            <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>

                        </v-col>
                    </v-row>
                </v-container>

            </template>
            <template v-slot:selection="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                        {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300 fs-12">Specialty:</span> <span class="fw-400 fs-12">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>
<!--
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>
                        <v-divider />
                        </v-col>
                    </v-row>
-->                    
                </v-container>

            </template>
        </v-select>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'emrProviderSelect',
        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },  
            value: {
                default: '',
                type: String
            },       
            appointmentTime: {
                default: null,
                type: String
            },
            providerIds: {
                default: () => [],
                type: Array
            }              
        },
        data: () => ({
            selectedProvider: '',
            refresh: ''
        }),
        created() {

        },
        async mounted() {
            if (this.providerIds && this.providerIds.length == 1){
                this.$emit('input', this.providerIds[0])
            }            
        },
        watch: {
            providerIds (newValue, oldValue) {
                if (newValue && newValue.length == 1){
                    this.$emit('input', newValue[0])
                }
            },            
            selectedProvider (newValue, oldValue) {
                this.$emit('input', newValue)
            }
        },
        methods: {
            ...mapActions('emr', ['loadProviders']),
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getloadedProviders']
            ),      
            providerMap () {
                let obj = {}
                this.getProviders.map(provider => {
                    obj[provider.id] = provider
                })
                return obj
            },
            providers () {
                let arr = []
                this.providerIds.map(id => {
                    arr.push(this.providerMap[id])
                })
                return arr
            }
        }
    }
</script>
