import gql from "graphql-tag";

export default gql`mutation ycmdVoicemailUpdateStatus(
  $voicemail_id: ID,
  $status: VoicemailStatus
  $sms_number: String
  $sms_message: String
  $note: String
  $additional_ids: [ID]
)
{
	ycmdVoicemailUpdateStatus(
	  voicemail_id: $voicemail_id,
	  status: $status
    sms_number: $sms_number
    sms_message: $sms_message
    note: $note,
    additional_ids: $additional_ids
  )
  {
    contact_id
    callback_phone
    callback_phone_sms
    date_created
    from_phone
    group_name
    recording_url
    spelled_first_name
    spelled_last_name
        status
    birth_date
    to_phone
    transcript
    transcribe_status
    transcribe_date
    mailbox
    address_street
    address_city
    address_state
    address_zipcode
    history {
      user_id
      last_name
      type
      value
      first_name
      timestamp
    }  
  }
}`
