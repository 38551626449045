<template>

    <div class="view">

        <v-overlay :value="isLoading" :absolute="true" color="#2389e8" opacity="0.3">
            <div class="d-flex align-center justify-center" >
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </v-overlay>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card mr-2 br-2">

            <div class="i-card__main pa-2 pr-0 pt-0">

                <div class="i-card__main__header pa-2 pl-0">

                    <div class="d-flex align-center flex-grow-1">

                        <v-tabs v-model="userType" dark background-color="transparent">

                            <v-tabs-slider color="ycmd-green"></v-tabs-slider>

                            <v-tab v-for="tab of tabs" :key="tab.id">
                                {{tab.name}}
                            </v-tab>

                        </v-tabs>

                    </div>

                </div>

                <div class="i-card__main__content white pa-2 br-2">

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <!-- Title first row -->
                            <section-title :title="`Create a Support Ticket`"/>

                            <section-content>

                                <template v-slot:section-content>

                                    <v-form
                                        v-model="ticketform"
                                        class="full-width"
                                        @submit.prevent="createTicket"
                                        ref="supportticket"
                                    >                                    
                                        <v-col cols="12" sm="12" md="12">
                                            <div class="label-title">Title</div>
                                            <v-text-field light solo dense flat outlined
                                                            :rules="[rules.required]"
                                                            v-model="title" />
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12">
                                            <div class="label-title">Message</div>
                                            <v-textarea light solo dense flat outlined
                                                            :rules="[rules.required]"
                                                            rows="3"
                                                            v-model="message" />
                                        </v-col>      
                                        <v-col cols="12" sm="12" md="12">
                                            <v-btn dark text small
                                                class="ycmd-light-blue mr-2"
                                                type="submit"
                                                :disabled="!ticketform || sending"
                                                >
                                                Submit
                                            </v-btn>
                                        </v-col>      

                                    </v-form>

                                </template>

                            </section-content>


                        </div>

                    </div>

                </div>

            </div>

        </i-card>

    </div>

</template>

<script>

    import {mapActions, mapState} from "vuex";

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';
    import dataClient from '@/graphql/clients/axios';

    export default {
        name: 'newAccountPage',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                isLoading: false,
                tabs: [
                    {
                        id: 0,
                        name: 'Support',
                        value: 'support'
                    },
                ],
                isEditable: true,
                userType: 'support',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v && v.length >= 8 || 'Min 8 characters',
                    email: value => {
                      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(value) || 'Invalid e-mail';
                    }
                },
                title: '',
                message: '',
                ticketForm: false,
                sending: false
            }
        },
        created() {},
        mounted() {},
        activated() {},
        watch: {
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            async createTicket() {
                this.sending = true
                let params = {
                    message: this.message,
                    title: this.title
                }

                let response = await dataClient('ycmdTicketCreate', params)
                if (response && response.created_by_id) {
                    this.$toasted.success(`Your support ticket has been created`);
                    this.title = ''
                    this.message = ''
                    this.$refs.supportticket.reset()
                } else {
                    this.$toasted.error('An error has occurred.  Please try again.');
                }
                this.sending = false
                
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => {
                    console.log('router error: ', err)
                });
            },
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open
            })
        }
    }

</script>

<style scoped>

    .tab {
        color: white !important;
    }

    .tabs-holder {
        width: 270px;
        margin-bottom: -8px;
        margin-left: -4px;
    }

    .tabs--active {
        background-color: white;
        color: #167DD7 !important;
    }

    .message__section {
        height: 200px;
    }

    .list-item__row {
        width: 100%;
        padding-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
