import gql from 'graphql-tag'

export default gql`query ycmdChatAttachmentUrlGet(
	$room_id: ID, 
	$attachment_id: ID, 
	$content_type: String, 
	$meta: [NameValueInput], 
	$type: urlDownloadUpload) {
	ycmdChatAttachmentUrlGet(
		room_id: $room_id, 
		attachment_id: $attachment_id, 
		content_type: $content_type, 
		meta: $meta, 
		type: $type) {
		url
		meta {
			name
			value
		}
	}
}`
