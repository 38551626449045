<template>

    <div class="full-width">

        <v-container
            fluid
            class="pa-0 ma-0 full-width"
        >
            <v-row
                class="pa-0 ma-0"
            >
                <v-col
                    class="pa-0 ma-0 shrink pointer"
                    @click="goHome"
                >

                  <img src="/images/ycmdlogo_transparent.png" class="pl-1 pt-1" style="height: 24px;"/>
                </v-col>
                <v-col
                    class="pa-0 ma-0 shrink pt-1 pointer"
                    @click="goHome"
                >
                
                    <span
                        text
                        class="white--text fs-10 pl-5 text-decoration-underline"
                    >
                        Home
                    </span>                 
                </v-col>

            </v-row>

            <v-row
                class="pa-0 ma-0"
            >
                <v-col
                    class="pa-0 ma-0"
                    cols="12"
                >

                    <v-progress-linear
                        :indeterminate="true"
                        background-color="ycmd-light-gray"
                        color="ycmd-dark-blue"
                        height="10"
                        v-if="loading"
                    />    

                    <v-card
                        class="error--text pt-5"
                        flat                                  
                        v-if="error"
                    >
                        <v-card-title
                        >
                            {{error}}
                        </v-card-title>

                    </v-card>
                </v-col>                
            </v-row>

            <v-row
                class="pa-0 ma-0"
            >
                <v-col
                    class="pa-0 ma-0"
                    cols=12
                >
                    <job-view 
                        :job="job"
                        v-if="loaded"
                    />                
                </v-col>
            </v-row>
        </v-container>

           
    </div>

</template>

<script>
import dataClient from '@/graphql/clients/axios';
import jobView from '@/views/apps/reporting/components/jobView.vue'
import { routeBasedOnRole } from '@/methods/globalMethods';

    export default {
        props: {
        },
        components: {
            'job-view': jobView
        },
        data() {
            return {
                panels: [],
                job: {},
                error: '',
                loading: true,
                timer: 0,
                loaded: false
            }
        },
        created() {},
        mounted() {    
            console.log('reportjob mounted')        
            this.loadJob()
        },
        watch: {

        },
        methods: {
            goHome () {
                routeBasedOnRole()
            },
            async loadJob () {

                let job_id
                if (this.$route.query && this.$route.query.job_id) {
                    job_id = this.$route.query.job_id
                }            

                if (!job_id) {
                    this.loading = false
                    this.error = `Invalid Report`
                    return
                }

                this.setScroll()

                let run = await dataClient('ycmdReportRun', {job_id: job_id})

                if (run.error) {
                    let message = 'An error has occurred'

                    if (run.error.message) {
                        message = run.error.message
                    }
                    this.error = message
                    this.loading = false
                } else {
                    run.report.definition = run.report_definition

                    this.job = run     
                    this.loading = false
                    this.loaded = true
                }


            }, 
            setScroll () {
                let el = this.$el
                let i=0

                while (el && ++i < 1000) {
                    console.log('el tag', el.tagName)

                    if (el.classList.contains('v-application--wrap')) {
                        break
                    }
                    el = el.parentElement
                }

                if (el) {
                    el.style.cssText = "overflow: scroll !important;";
                }
            }
        },
        computed: {
        }
    }

</script>

<style scoped>

</style>
