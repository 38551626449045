import gql from 'graphql-tag'

export default gql`query ycmdReportRun($report_id: ID, $parameters: [IdValueInput], $output_type: OutputType, $job_id: ID) {
	ycmdReportRun(report_id: $report_id, parameters: $parameters, output_type: $output_type, job_id: $job_id) {
		user_id
		type
		activity_date
		activity_date_end
		job_id
		error_message
		report {
			report_name
			report_id
			properties {
				name
				id
				value
				formatted
			}
			url
			columns {
				label
				field
				type
				width
			}
			lines
			format
		}
		report_definition {
			name
			id
			categories
			reportProperties {
				name
				value
			}
			parameters {
				id
				name
				data_type
				select_list_key
				select_multiple
				required
				attributes {
					name
					value
				}
			}
			lists {
				key
				values {
					name
					id
					value
				}
			}
			schedules {
				schedule_id
				scheduling_user_id
				schedule_repeat
				dow
				dom
				time
				parameters {
					id
					value
				}
				format
				emails
				last_run
			}
			progress {
				type
			}
		}


	}
}`
