<template>
  <v-dialog
      v-model="value"
      persistent
      :max-width="maxWidth"
      >
      <v-card>
          <v-card-title class="text-h5">
            {{title}}
          </v-card-title>
          <v-card-subtitle>
            {{subtitle}}
          </v-card-subtitle>

          <v-card-text>
            {{text}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <template
              v-for="btn in buttons"
            >
              <v-btn
                  :key="btn.caption"
                  :color="btn.color"
                  :text="btn.text"
                  :icon="btn.icon"
                  @click="buttonClick(btn)"
                  :disabled="btn.disabled"
              >
                {{btn.caption}}{{btn.icon}}
              </v-btn>
            </template>
          </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      'max-width': {
        default: '290',
        type: String
      },
      title: {
        default: '',
        type: String
      },
      subtitle: {
        default: '',
        type: String
      },
      text: {
        default: '',
        type: String
      },
      icon: {
        default: false,
        type: Boolean
      },
      buttons: {
        default: () => {
          return [
            {caption: 'OK'},
            {caption: 'Cancel'}
          ]
        }
      }
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
      buttonClick (btn) {
        this.$emit('click', btn)
      }
    },
    computed: {
    }
  }
</script>

