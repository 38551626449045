<template>

    <div >
     
      <v-data-table
          :headers="headers"
          :items="notes"
          dense
          :hide-default-footer="true"
          :disable-pagination="true"
          no-data-text="No notes"
          :show-expand="false"
      >
      </v-data-table>

    </div>

</template>

<script>


    export default {
        name: 'emrDurationSelect',
        components: {
        },
        props: {
            notes: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({

        }),
        created() {

        },
        async mounted() {

        },
        watch: {
        },
        methods: {
        },
        computed: {
            headers () {
                let headers = [
                    {
                        text: 'Date',
                        value: 'created',
                        width: '20%',
                        sortable: false,
                        class: "pl-0",
                        cellClass: "pl-0 fs-12",                        
                    },
                    {
                        text: 'Note',
                        value: 'text',
                        width: '80%',
                        sortable: false,
                        cellClass: "fs-12",                        
                    }
                ]
                return headers
            },
        }
    }
</script>
