import gql from 'graphql-tag'

export default gql`query ycmdVoicemailGet(
    $status: [VoicemailStatus],
    $search: String,
	$callback_phone: String,
    $mailbox: [String],
	$start_date: AWSDate,
    $end_date: AWSDate,	
	$output_type: OutputType
    $sort_field: VoicemailSortField,
    $sort_direction: SortDirection
	$voicemail_date: String,
    $limit: Int,
	$client_offset: Int,
    $nextToken: String) {
	ycmdVoicemailGet(
      status: $status,
      search: $search,
	  callback_phone: $callback_phone,
      mailbox: $mailbox,
	  start_date: $start_date,
      end_date: $end_date,	
	  output_type: $output_type,
      sort_field: $sort_field,
      sort_direction: $sort_direction,
	  client_offset: $client_offset,
	  voicemail_date: $voicemail_date,
      limit: $limit,
      nextToken: $nextToken)
    {
		voicemail {
			contact_id
			callback_phone
			callback_phone_sms
			date_created
			from_phone
			group_name
			recording_url
			spelled_first_name
			spelled_last_name
      		status
			birth_date
			to_phone
			transcript
			transcribe_status
			transcribe_date
			mailbox
			address_street
			address_city
			address_state
			address_zipcode
			history {
				user_id
				last_name
				type
				value
				first_name
				timestamp
			},
			language
    		call_initiation_time
		}
		voicemail_url
		nextToken
		total_count
    }
}`
