<template>

    <v-row no-gutters>

        <v-col cols="6" sm="6" md="4" lg="4">
            <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                <div class="hidden-md-and-up label-title">Type</div>
                <v-icon :color="'#28C96D'"> contacts </v-icon>
            </div>
        </v-col>

        <v-col cols="6" sm="8" md="8" lg="8">
            <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                <div class="hidden-md-and-up label-title">Name</div>
                <div class="label-value fw-500">
                    {{ item.first_name || item.last_name ? `${item.last_name}${item.last_name && item.first_name ? ', ' : ''} ${item.first_name}` : 'N/A' }}
                </div>
            </div>
        </v-col>

    </v-row>

</template>

<script>
    import { capitalizeFirstLetter } from '@/methods/utils';

    export default {
        name: 'customListItem',
        props: {
            item: Object,
            sendConsult: Function
        },
        data: () => ({
            capitalizeFirstLetter
        }),
        mounted() {},
        methods: {

        },
        computed: {

        }
    }

</script>

<style scoped>

</style>
