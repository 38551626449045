<template>

  <v-badge
    color="red"
    dot
    avatar
    :offset-x="offsetX"
    :offset-y="offsetY"
  />   

</template>

<script>

export default {
  props: {
    useParent: {
      default: true
    },
    offsetX: {
      default: 0
    },
    offsetY: {
      default: 0
    }    

  },
  data: () => ({
  }),
  methods: {
  },
  mounted () {

    if (this.useParent) {
      this.$el.parentElement.setAttribute('title', 'App not installed')
    } else {
      this.$el.setAttribute('title', 'App not installed')
    }

  }
}
</script>
