<template>
  <v-dialog
      v-model="value"
      persistent
      max-width="290"
      >
      <v-card>
          <v-card-title class="text-h5">
              Add Note
          </v-card-title>
          <v-card-subtitle>
              Add note to voicemail from {{voicemail.spelled_first_name}} {{voicemail.spelled_last_name}}
          </v-card-subtitle>

          <v-card-text>

              <v-select light dense flat outlined
                        v-model="status"
                        label="Type of note (optional)"
                        :items="statuses"
                        item-text="name"
                        item-value="value"
                        background-color="white"/>

              <v-textarea
                  counter
                  label="Note"
                  v-model="noteText"
                  rows="3"
                  :maxlength="1000"
                  background-color="grey lighten-3"
              ></v-textarea>

          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="addNote()"
              :disabled="!!!noteText || sending"
          >
              Add Note
          </v-btn>
          <v-btn
              text
              @click="closeNoteDialog()"
          >
              Cancel
          </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
  import MUTATE_VOICEMAIL_STATUS from '@/graphql/mutations/phoenix/ycmdVoicemailUpdateStatus';

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      voicemail: {
        default: () => {return {}},
        type: Object
      }
    },
    data: () => ({
      noteText: '',
      statuses: [
        {name: 'Left Voicemail', value: 'left_message'},
        {name: 'Patient Unavailable', value: 'unavailable'},
        {name: 'Archive Call', value: 'complete'},
      ],
      sending: false,
      status: null
    }),
    mounted() {
    },
    methods: {
      closeNoteDialog() {
        this.noteText = ''
        this.status = null
        this.sending = false
        this.$emit('input', false)
      },
      async addNote() {
          this.sending = true
          let response = await this.$apollo.mutate({
              mutation: MUTATE_VOICEMAIL_STATUS,
              variables: {
                  voicemail_id: this.voicemail.contact_id,
                  note: this.noteText,
                  status: this.status
              },
              client: 'phoenixClient',
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
          });

          this.$emit('changed', response && response.data ? response.data.ycmdVoicemailUpdateStatus : null )

          this.$set(this.voicemail, "hasNote", true);

          this.closeNoteDialog()
      },
    },
    computed: {
    }
  }
</script>

