<template>

  <div class="view" id="newChatMessage">

    <i-card :viewIndex="card.index"
            :viewCount="2"
            :ripple-enabled="true"
            :leftPanel="card.panels.left"
            :rightPanel="card.panels.right"
            :topPanel="card.panels.top"
            :bottomPanel="card.panels.bottom"
            class="i-card mr-1 mb-2 br-2 pb-2 ycmd-medium-blue">

      <div class="i-card__main">

        <div class="i-card__main__header ycmd-medium-blue">
          <div class="d-flex flex-column full-width">
            <div class="d-flex align-center full-width white--text pl-2 pr-2" style="height:56px">
              <div>
                <v-btn icon dark elevation="0" @click="changeView({view: 'chat-rooms', transition: 'prev'})">
                  <v-icon size="18">close</v-icon>
                </v-btn>
              </div>
              <div class="d-flex flex-grow-1 pl-4 pr-2 fs-12">
                NEW CHAT
                <div v-if="roomName" class="pl-2 fade-2">( {{ roomName }} )</div>
              </div>
<!--              
              <div class="d-flex align-center full-height pl-3 ml-1" style="border-left: solid 2px #125ea3">
                <v-tooltip top
                           nudge-top="5"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-1 pb-2">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        icon dark depressed
                        v-on="on"
                        class="ma-0"
                        @click="isRightMenuExpanded ? collapseRightMenu() : expandRightMenu()">
                      <v-icon dark size="20">{{ isRightMenuExpanded ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-no-wrap">{{ isRightMenuExpanded ? 'Minimize Chat' : 'Maximize Chat' }}</span>
                </v-tooltip>
              </div>
-->              
            </div>
            
            <div class="d-flex flex-column justify-center full-width white--text pl-4 pr-1"
                 style="border: solid 2px #125ea3; border-left:none;border-right:none;">

              <v-text-field dark flat solo hide-details clearable prepend-icon="search"
                            v-model="search"
                            ref="searchInput"
                            background-color="transparent"
                            @keydown="searchKeydown"
                            class="chat-search-bar"
                            :label="tab === 0 ? 'Find rooms...' : 'Find participants...'">
              </v-text-field>

            </div>
          </div>
        </div>

        <div class="i-card__main__content">

          <div class="d-flex white" style="border-bottom: solid 2px #125ea3">

            <v-expansion-panels flat accordian multiple v-model="panel" class="full-width ml-0">
              <v-expansion-panel
                  class="transparent fs-11 ma-0"
                  active-class="ma-0 pa-0 br-0 expansion-header-small">
                <v-expansion-panel-header hide-actions
                                          class="fs-12 pl-2 pr-2"
                                          style="height: 48px; min-height: 48px !important">
                  <div class="d-flex align-center">
                    <v-tooltip top
                               nudge-top="5"
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-1 pb-2">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon depressed
                            v-on="on"
                            :disabled="participants.length === 0"
                            class="ma-0"
                            elevation="0" @click.stop="deleteAllParticipants">
                          <v-icon size="18">delete</v-icon>
                        </v-btn>
                      </template>
                      <span class="text-no-wrap">Delete Participant List</span>
                    </v-tooltip>
                    <div class="pl-4">Participant List ( {{ participants.length }} )</div>
                    <v-spacer/>
                    <transition name="fade-transition" mode="out-in">
                      <div>
                        <v-tooltip v-if="participants.length > 0"
                                   top
                                   nudge-top="5"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-1 pb-2">
                          <template v-slot:activator="{ on }">
                            <v-btn
                                icon depressed
                                v-on="on"
                                class="ma-0"
                                elevation="0" @click.stop="customizeRoom">
                              <v-icon size="18">edit</v-icon>
                            </v-btn>
                          </template>
                          <span class="text-no-wrap">Edit Room Name</span>
                        </v-tooltip>
                      </div>
                    </transition>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="participants.length > 0">
                  <template v-for="(participant) in participants">
                    <v-chip dark small label close
                            :key="`participant_${participant.user_id}`"
                            @click:close="removeParticipant(participant.user_id)"
                            color="ycmd-light-blue" class="ma-1">
                      {{ participant.first_name }} {{ participant.last_name }} {{ participant.id }}
                    </v-chip>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <div class="i-card__scrollable white black--text">
            <template v-if="!loading">
              <transition name="fade-transition" mode="out-in">
                <div v-if="contactResults.length > 0">
                  <template v-for="(item, i) in contactResults">
                    <div class="pa-4 pt-3 pb-0"
                         :style="`border-bottom: solid ${ i < contactResults.length - 1 ? 1 : 0 }px #5894cc`">
                      <div class="d-flex pt-2 pb-1 mb-0">
                        <div class="relative" style="width: 42px">
                          <v-tooltip top
                                     nudge-top="5"
                                     :disabled="$vuetify.breakpoint.xsOnly"
                                     color="ycmd-black"
                                     content-class="fade-0 pa-4 pt-1 pb-2">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                  icon depressed
                                  v-on="on"
                                  class="ma-0"
                                  style="left:-8px;top:-8px"
                                  elevation="0" @click.stop="addContact(item.id, item.first_name, item.last_name)">
                                <v-icon size="16">add</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-no-wrap">Add Participant</span>
                          </v-tooltip>
                        </div>
                        <div class="d-flex full-width pa-0 pb-4">
                          <transition name="fade-transition" mode="out-in">
                            <v-badge dot :color="`${ item.online ? 'ycmd-light-green' : 'transparent' }`"
                                     class="mt-3 mr-3"/>
                          </transition>
                          <div class="d-flex flex-column full-width fs-12 pl-1">
                            <div>
                              <text-highlight :queries="search !== null ? search : []">
                                {{ item.last_name }}, {{ item.first_name }}
                              </text-highlight>
                              <span v-if="item.title && item.title !== null" class="fade-2"> , {{ item.title }}</span>
                            </div>
                            <div class="fs-11 pt-3">
                              <span class="fade-1">{{ item.group }}</span>
                              <span v-if="item.specialty" class="pl-2 fade-2">( {{ item.specialty }} )</span>
                            </div>
                          </div>
                          <!--                        <v-spacer/>-->
                          <!--                        <v-tooltip top-->
                          <!--                                   nudge-top="5"-->
                          <!--                                   :disabled="$vuetify.breakpoint.xsOnly"-->
                          <!--                                   color="ycmd-black"-->
                          <!--                                   content-class="fade-0 pa-4 pt-1 pb-2">-->
                          <!--                          <template v-slot:activator="{ on }">-->
                          <!--                            <v-btn-->
                          <!--                                icon dark depressed-->
                          <!--                                class="ma-0"-->
                          <!--                                style="top: -8px"-->
                          <!--                                elevation="0" @click.stop="createRoom(item.user_id)">-->
                          <!--                              <v-icon dark size="16">arrow_forward</v-icon>-->
                          <!--                            </v-btn>-->
                          <!--                          </template>-->
                          <!--                          <span class="text-no-wrap">Chat with Provider</span>-->
                          <!--                        </v-tooltip>-->
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <template v-else>
                  <div v-html="resultsMessage" class="d-flex align-center justify-center full-height fade-1"/>
                </template>
              </transition>
            </template>
            <template v-else>
              <div class="d-flex align-center justify-center full-height">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </template>
          </div>

          <div class="d-flex align-center full-width ycmd-dark-blue white--text pa-2"
               style="border-top: solid 2px #125ea3">
            <v-tooltip top
                       nudge-top="5"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       color="ycmd-black"
                       content-class="fade-0 pa-4 pt-1 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn
                    icon dark depressed
                    v-on="on"
                    class="ma-0"
                    @click.stop="selectFile"
                    :disabled="saving || participants.length === 0"
                    :loading="sendingAttachment"
                    color="white">
                  <v-icon dark size="18">insert_photo</v-icon>
                </v-btn>
              </template>
              <span class="text-no-wrap">Add Picture</span>
            </v-tooltip>
            <input type="file" ref="inputUpload" style="display:none;" v-on:change="fileSelected" multiple/>
            <v-textarea dark flat solo hide-details no-resize auto-grow
                        v-model="textToPost"
                        rows="1"
                        :disabled="saving || participants.length === 0"
                        background-color="#0d4171"
                        class="ml-2 mr-2"
                        :label="`${ participants.length > 0 ? 'Post a message...' : 'Add participants first...' }`"/>
            <v-tooltip top
                       nudge-top="5"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       color="ycmd-black"
                       content-class="fade-0 pa-4 pt-1 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn
                    icon dark depressed
                    v-on="on"
                    @click="createPost"
                    :disabled="saving || participants.length === 0 || textToPost.trim().length === 0"
                    :loading="sendingPost"
                    class="ma-0"
                    color="white">
                  <v-icon dark size="18">send</v-icon>
                </v-btn>
              </template>
              <span>Send</span>
            </v-tooltip>
          </div>
        </div>
      </div>

      <v-dialog persistent v-model="dialog" width="336" attach="#newChatMessage">
        <div class="d-flex full-width ycmd-blue br-2 white--text" style="height:260px">
          <div class="d-flex flex-column full-width ycmd-blue-vertical-gradient">
            <div class="d-flex align-center pa-2" style="border-bottom: solid 2px #125ea3">
              <v-btn icon dark depressed class="ma-0" @click="cancelCustomizeRoom">
                <v-icon dark size="20">close</v-icon>
              </v-btn>
              <div class="fs-13 pl-4">CUSTOMIZE ROOM</div>
            </div>
            <div class="d-flex align-center justify-center pa-4 flex-grow-1">
              <div class="d-flex flex-column">
                <div>
                  <v-text-field dark flat solo hide-details clearable
                                v-model="roomName"
                                background-color="ycmd-dark-blue"
                                label="Enter Chat Name...">
                  </v-text-field>
                </div>
                <div class="pt-4 fade-2">Note: If you attached an image you will need to re-add it after naming a room</div>
              </div>
            </div>
            <div class="d-flex pa-4 pt-0">
              <div class="d-flex flex-grow-1 pr-1">
                <v-btn dark medium text
                       :disabled="roomName.trim().length === 0"
                       class="ycmd-light-blue ma-0 full-width"
                       @click.stop="dialog = false">
                  <span>Save Name</span>
                </v-btn>
              </div>
              <div class="d-flex flex-grow-1 pl-1">
                <v-btn dark medium text
                       :loading="saving"
                       :disabled="roomName.trim().length === 0 || ( textToPost.trim().length === 0)"
                       class="ycmd-light-blue ma-0 full-width"
                       @click.stop="createPost">
                  <span>Create Room</span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-dialog>
    </i-card>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import axios from "axios";
import {format, utcToZonedTime} from "date-fns-tz";

import GET_CHAT_SEARCH from '@/graphql/queries/phoenix/ycmdChatSearch';
import CREATE_CHAT_ROOM from "@/graphql/mutations/phoenix/ycmdChatRoomCreate";
import CREATE_CHAT_POST from "@/graphql/mutations/phoenix/ycmdChatPostCreate";
import GET_CHAT_ATTACHMENT_URL from "@/graphql/queries/phoenix/ycmdChatAttachmentUrlGet";

const RESULTS_MESSAGE = 'Add participants to create a room';

let imageFile = null;
let imageKey = null;

export default {
  name: 'chatMessage',
  props: {
    changeView: Function,
    params: {
        data: Object
    }
  },   
  data() {
    return {
      format,
      card: {
        index: 0,
        panels: {left: false, right: false, top: false, bottom: false}
      },
      panel: [0],
      tab: null,
      selectedItems: [],
      multiSelect: false,
      name: '',
      searchResults: [],
      contactResults: [],
      participants: [],
      search: null,
      keyboardTimer: 300,
      lastTimer: 0,
      roomNameDialog: false,
      roomType: 'conversation',
      tempDays: null,
      showAddButton: true,
      loading: false,
      absolute: true,
      opacity: 1,
      overlay: false,
      saving: false,
      saveDisabled: true,
      dialog: false,
      roomName: '',
      roomNameOriginal: '',
      textToPost: '',
      sendingPost: false,
      sendingAttachment: false,
      attachmentLoaded: false,
      attachmentName: '',
      attachment_id: null,
      resultsMessage: RESULTS_MESSAGE,
      imageFile: null,
      imageKey: null
    }
  },
  mounted() {
      this.$emit('initialized', 'chat-message', this.event)

    // let testMatches = [
    //   ["1", "2", "3"],
    //   ["2", "3"]
    // ];
    // let testList = ["2"]
    //
    // testList.sort();
    //
    // let matched = false;
    //
    // for (let i = 0; i < testMatches.length; i++) {
    //   testMatches[i].sort();
    //   if (JSON.stringify(testList) === JSON.stringify(testMatches[i])) {
    //     matched = true;
    //     break;
    //   }
    // }
    //
    // console.log("MATCHED: ", matched);
  },
  methods: {
    ...mapActions('rightMenu', ['expandRightMenu', 'collapseRightMenu']),
    ...mapActions('chat', ['loadCurrentRoom']),
    async event (name, params) {

      console.log('name', name, params)
      switch (name) {
        case 'load':

          this.selectedItems = []
          this.searchResults = []
          this.contactResults = []
          this.participants = []
          this.search = null
          this.roomName = ''
          this.roomNameOriginal = ''
          this.textToPost = ''
          this.attachmentName = ''
          this.attachment_id = null
          this.imageFile = null
          this.imageKey = null
      }
    },      
    searchKeydown(e) {

      this.loading = true;
    },
    async addContact(id, first_name, last_name) {

      this.loading = true;
      this.showAddButton = false;

      if (!this.participants.some(s => s.user_id === id)) {

        this.participants.push({
          user_id: id,
          first_name: first_name,
          last_name: last_name
        });

        this.search = null;

        //await this.lookupRooms();

        // this.$nextTick(() => {
        //   this.showAddButton = true;
        //   this.loading = false;
        // });
      } else {
        // this.$nextTick(() => {
        //   this.showAddButton = true;
        //   this.loading = false;
        // });
      }
    },
    async addParticipants(id) {

      this.loading = true;
      this.showAddButton = false;

      this.searchResults[0].matches.map(m => {
        if (m.id === id) {
          m.participants.map(p => {
            if (!this.participants.some(s => s.user_id === p.user_id))
              this.participants.push({
                user_id: p.user_id,
                first_name: p.first_name,
                last_name: p.last_name
              });
          })
        }
      });

      //await this.lookupRooms();

      this.showAddButton = true;
      this.loading = false;
    },
    async removeParticipant(id) {

      this.loading = true;
      this.showAddButton = false;

      this.participants = this.participants.filter((p) => p.user_id !== id);

      if (this.participants.length === 0)
        this.resultsMessage = RESULTS_MESSAGE;

      this.showAddButton = true;
      this.loading = false;
    },
    async deleteAllParticipants() {

      this.loading = true;
      this.showAddButton = false;
      this.participants = [];
      this.resultsMessage = RESULTS_MESSAGE;
      this.showAddButton = true;
      this.loading = false;
    },
    async lookupRooms() {

      this.resultsMessage = '';

      const result = await this.$apollo.query({
        query: GET_CHAT_SEARCH,
        variables: {
          search: this.search,
          roles: ['provider','member'],
          objects: ['room', 'contact'],
          // objects: ['room', 'participant', 'contact'],
          deleted: false,
          participants: this.participants.map(p => p.user_id)
        },
        client: 'phoenixClient',
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });


      if (result.data.ycmdChatSearch !== null) {

        const {matches, contacts} = result.data.ycmdChatSearch;

        this.searchResults = [];
        this.contactResults = [];

        if (matches !== null)
          this.searchResults = matches;

        if (contacts) {

          contacts.map(c => {

            let group_name = c.group_name;

            if (c.group_id === "0") {
              if (c.roles.includes('provider'))
                group_name = 'Moonlighter';
              else
                group_name = 'NA';
            }

            this.contactResults.push({
              first_name: c.first_name,
              last_name: c.last_name,
              roles: `${c.roles.join(',')}`,
              title: c.title,
              specialty: c.specialty,
              group: group_name,
              id: c.user_id
            })
          });

          if (contacts.length === 0)
            this.resultsMessage = 'No Results'
        }
      }
    },
    selectRoom(id, name) {

      this.changeView({view: 'chat-main', transition: 'next', params: {room_id: id, room_name: name}})

    },
    async createPost() {

      if (this.participants.length > 0) {

        if (this.textToPost.length > 0) {

          this.sendingPost = true;

          await this.saveRoom(true);

          this.sendingPost = false;
        }
      }
    },
    async savePost(params) {

      return await this.$apollo.mutate({
        client: 'phoenixClient',
        mutation: CREATE_CHAT_POST,
        variables: params
      });
    },
    async saveRoom(addPost, attachment) {

      this.saving = true;

      let params = {
        participants: this.participants.map(p => p.user_id)
      };

      if (this.roomName && this.roomName.length > 0) {
        params['room_type'] = 'topic';
        params['name'] = this.roomName.trim();
      }
      else {
        // Do not let them create a room without a name if there is an existing one
        if (this.searchResults.length > 0) {

          let participant_list = [];
          this.participants.map((p) => {
            participant_list.push(p.user_id)
          });

          // Add logged in user id so they match
          participant_list.push(this.info.userID);

          let participant_matches = [];
          this.searchResults.map((r) => {
            let match = [];
            r.source.participant.map(p => {
              match.push(p.user_id)
            })
            participant_matches.push(match);
          });

          // console.log("PARTICIPANT LIST: ", participant_list);
          // console.log("PARTICIPANT MATCHES: ", participant_matches);

          let isSame = false;

          participant_list.sort();
          for (let i = 0; i < participant_matches.length; i++) {
            participant_matches[i].sort();
            if (JSON.stringify(participant_list) === JSON.stringify(participant_matches[i])) {
              isSame = true;
              break;
            }
          }

          //console.log("IS_SAME: ", isSame);

          // If they match then they need to name the room
          if (isSame) {
            this.saving = false;
            this.customizeRoom()
            return;
          }
        }
        params['room_type'] = 'conversation';
      }

      //console.log("PARAMS: ", params);

      await this.$apollo.mutate({
        client: 'phoenixClient',
        mutation: CREATE_CHAT_ROOM,
        variables: params,
        update: async (cache, {data: {ycmdChatRoomCreate}}) => {
          try {

            let id = ycmdChatRoomCreate.id
            let name = ycmdChatRoomCreate.name
            //const {id, name} = ycmdChatRoomCreate;

            if (addPost) {

              if (attachment) {

                await this.saveAttachment(id, attachment);
              }
              else {

                await this.savePost({
                  room_id: id,
                  text: this.textToPost
                });
              }

              //await this.setCurrentRoom({id, name});

              this.changeView({view: 'chat-main', transition: 'next', params: {room_id: id, room_name: name}})

            }
            else {

              //await this.setCurrentRoom({id, name});

              this.changeView({view: 'chat-main', transition: 'next', params: {room_id: id, room_name: name}})
            
            }

            this.saving = false;

            this.loadCurrentRoom(ycmdChatRoomCreate)            

          } catch (e) {
            console.error(e);
          }
        }
      });
    },
    customizeRoom() {
      this.roomNameOriginal = this.roomName;
      this.dialog = true;
    },
    cancelCustomizeRoom() {
      this.roomName = this.roomNameOriginal;
      this.dialog = false;
    },
    selectFile() {
      // Clear out old state in case they are re-adding an image
      this.$refs.inputUpload.value = null;

      this.$refs.inputUpload.click()
    },
    async fileSelected(e) {

      if (e.target.files.length > 0) {

        // Clear out the post text since we do not yet support image captions
        this.textToPost = '';

        for (let f in e.target.files) {

          const file = e.target.files[f];

          if (file  && typeof (file ) === 'object' && file.name) {

            const key = `tmp_${btoa(file.name)}`;

            await this.saveRoom(true, {
              type: 'saveAttachment', file, key
            });
          }
        }
      }
    },
    async saveAttachment(room_id, {file, key}) {

      try {

        this.sendingAttachment = true;

        const response = await this.$apollo.query({
          query: GET_CHAT_ATTACHMENT_URL,
          variables: {
            content_type: file.type,
            room_id: room_id,
            meta: [{'name': 'name', 'value': file.name}]
          },
          client: 'phoenixClient',
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });

        //console.log("RESPONSE", response);
        // check errors
        if (response.errors) {

          throw "No response returned when getting the attachment url";

        } else {

          const {url, meta} = response.data.ycmdChatAttachmentUrlGet;

          //save the file
          let saveResult = await this.saveFile({url, file, meta})

          const {status} = saveResult;

          if (status === 200) {

            const attachment_id = meta.find(m => m.name === 'attachment_id').value;

            await this.savePost({room_id, attachment_id, file, key});
          }
        }

        this.sendingAttachment = false;
      } catch (e) {
        throw `Unknown error occurred ${e}`;
      }
    },
    async saveFile({url, file, meta}) {

      let headers = {
        'Content-Type': file.type
      };

      meta.map(m => {
        headers[`x-amz-meta-${m.name}`] = m.value
      });

      let result = await axios.put(url,
          file,
          {
            headers: headers
          }
      )

      return result
    }
  },
  watch: {
    search() {

      if (this.lastTimer) {
        clearTimeout(this.lastTimer);
        this.lastTimer = 0
      }

      if (this.search === null || this.search.length === 0) {

        this.loading = true;

        this.contactResults = [];
        this.loading = false;
        this.resultsMessage = '<div class="text-center">Add additional participants <br/></br>OR<br/><br/>Post a message to create a room</div>';
      } else {
        this.lastTimer = setTimeout(async () => {

          this.loading = true;
          this.lastTimer = 0;
          await this.lookupRooms();
          this.loading = false;

        }, this.keyboardTimer)
      }


    },
    // roomName(name) {
    //
    //   this.saveDisabled = !(name && name.length > 0);
    // }
  },
  computed: {
    ...mapState('profile', ['info']),
    ...mapState('rightMenu', ['isRightMenuExpanded'])
  },
}
</script>

<style scoped>

.room-name >>> .v-overlay__content {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 100% !important;
}

#newChatMessage >>> .v-dialog {
  position: absolute;
  right: 0 !important;
}

</style>