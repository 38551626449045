import gql from 'graphql-tag'

export default gql`query ycmdChatPostDetailGet($post_id: ID, $silent_read: Boolean) {
	ycmdChatPostDetailGet(post_id: $post_id, silent_read: $silent_read) {
		text
		date_created
		id
		first_name
		last_name
		user_id
		type
		system_type
		attachment_id
		key
		recipients {
			delivered
			read
			user_id
			first_name
			last_name
			title
			specialty
			group_id
			group_name
			online_status
			status
		}
		reply_to_id
		reply_to_post {
			text
			date_created
			id
			first_name
			last_name
			user_id
			type
			system_type
			attachment_id
			key
			reply_to_id
			reply_to_user {
				id
				name
			}
			reply_to_text
			reactions {
				reaction_key
				reaction_users {
					id
					name
				}
			}
		}
		reactions {
			reaction_key
			reaction_users {
				id
				name
			}
		}
	}
}`
