// Initial state
const state = {
  open: true
};

const getters = {
  menuIndex: state => state.index
};

const actions = {

  changeMenu: ({commit}, index) => commit('change', index),
  toggleMenu: ({commit}) =>  commit('toggle'),
  openMenu: (store) => {
    if (store.state.open) {
      store.commit('close')
      setTimeout(() => {store.commit('open')}, 10);
    } else {
      store.commit('open')
    }
  },
  closeMenu: ({commit}) => commit('close'),
};

const mutations = {

  change(state, index) {

    state.index = index;
  },
  toggle (state) {

    state.open = !state.open;
  },
  open(state) {

    state.open = true;
  },
  close(state) {

    state.open = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
