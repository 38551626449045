<template>

  <div class="view">

    <div class="view">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 ycmd-medium-blue">

        <div class="i-card__main">

          <div class="i-card__main__header ycmd-medium-blue">
            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width white--text pl-2 pr-2" style="height:56px">
                <div>
                  <v-btn icon dark elevation="0" 
                    @click="changeView({view: 'chat-edit', transition: 'prev', params: {room_id: currentRoom.id}})"
                  >
                    <v-icon size="18">close</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-grow-1 pl-4 pr-2 fs-12">
                  EDIT ROOM
                  <div v-if="currentRoom && currentRoom.name" class="pl-2 fade-2">( {{ currentRoom.name }} )</div>
                </div>

              </div>
              <div class="d-flex flex-column justify-center full-width white--text pl-4 pr-1"
                   style="border: solid 2px #125ea3; border-left:none;border-right:none;">

                <v-text-field dark flat solo hide-details clearable prepend-icon="search"
                              v-model="search"
                              background-color="transparent"
                              @keydown="searchKeydown"
                              class="chat-search-bar"
                              label="Find contacts...">
                </v-text-field>

              </div>
            </div>
          </div>

          <div class="i-card__main__content ycmd-very-lightest-blue black--text">

            <div class="d-flex" >

              <v-expansion-panels flat accordian multiple
                                  v-model="panel"
                                  class="full-width ml-0">
                <v-expansion-panel class="transparent fs-11 ma-0"
                                   active-class="ma-0 pa-0 br-0 expansion-header-small">
                  <v-expansion-panel-header hide-actions
                                            class="fs-12 pl-2 pr-2"
                                            style="height: 48px; min-height: 48px !important">
                    <div class="d-flex align-center">
                      <v-tooltip top
                                 nudge-top="5"
                                 :disabled="$vuetify.breakpoint.xsOnly"
                                 color="ycmd-black"
                                 content-class="fade-0 pa-4 pt-1 pb-2">
                        <template v-slot:activator="{ on }">
                          <v-btn
                              icon depressed
                              v-on="on"
                              :disabled="participants.length === 0"
                              class="ma-0"
                              elevation="0" @click.stop="deleteAllParticipants">
                            <v-icon size="18">delete</v-icon>
                          </v-btn>
                        </template>
                        <span class="text-no-wrap">Delete Participant List</span>
                      </v-tooltip>
                      <div class="pl-4">Participant List ( {{ participants.length }} )</div>
                      <v-spacer />
                      <transition name="fade-transition" mode="out-in">
                        <div>
                          <v-tooltip v-if="participants.length > 0"
                                      top
                                     nudge-top="5"
                                     :disabled="$vuetify.breakpoint.xsOnly"
                                     color="ycmd-black"
                                     content-class="fade-0 pa-4 pt-1 pb-2">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                  icon depressed
                                  v-on="on"
                                  :loading="saving"
                                  class="ma-0"
                                  elevation="0" @click.stop="saveParticipants">
                                <v-icon size="22">save</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-no-wrap">Add To Room</span>
                          </v-tooltip>
                        </div>
                      </transition>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="participants.length > 0">
                    <template v-for="(participant) in participants">
                      <v-chip dark small label close
                        :key="`part_${participant.user_id}`"
                              @click:close="removeParticipant(participant.user_id)"
                              color="ycmd-light-blue" class="ma-1">
                        {{ participant.first_name }} {{ participant.last_name }} {{ participant.id }}
                      </v-chip>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <div class="i-card__scrollable white--text">

              <template v-if="!loading">
                <div v-if="contactResults.length > 0" class="pt-3 pb-3">
                  <template v-for="(item, i) in contactResults">
                    <div class="pa-4 pt-3 pb-0"
                      :key="`contact_${item.id}`"
                    >
                      <div class="d-flex pb-0 mb-0 black--text">
                        <div class="relative" style="width: 50px">
                          <v-tooltip top
                                     nudge-top="5"
                                     :disabled="$vuetify.breakpoint.xsOnly"
                                     color="ycmd-black"
                                     content-class="fade-0 pa-4 pt-1 pb-2">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                  icon depressed
                                  v-on="on"
                                  class="ma-0"
                                  style="left:-8px;top:-8px"
                                  elevation="0" @click.stop="addContact(item.id, item.first_name, item.last_name)">
                                <v-icon size="16">add</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-no-wrap">Add Participant to List</span>
                          </v-tooltip>
                          <app-not-installed-indicator v-if="item.app_version && item.app_version=='notinstalled'" offsetY="-6"/>
                          <ooo-indicator v-else-if="item.ooo" offsetY="-6"/>
                        </div>
                        <div class="d-flex full-width pa-0 pb-4">
                          <div class="d-flex flex-column full-width fs-12 pl-1">                            
                            <div>
                              <text-highlight :queries="search !== null ? search : []">
                                {{ item.first_name }} {{ item.last_name }}
                              </text-highlight>
                              <span v-if="item.title && item.title !== null" class="fade-2"> , {{ item.title }}</span>
                            </div>
                            <div class="fs-11 pt-3 ycmd-light-blue-text">
                              {{ item.group }} {{ item.specialty }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <template v-else>
                  <div class="d-flex align-center justify-center full-height black--text">No Results</div>
                </template>
              </template>
              <template v-else>
                <div class="d-flex align-center justify-center full-height"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
              </template>
            </div>

          </div>
        </div>

      </i-card>
    </div>

  </div>
</template>

<script>

  import {mapActions, mapState} from "vuex";
  import {format} from 'date-fns-tz';

  import GET_CHAT_SEARCH from '@/graphql/queries/phoenix/ycmdChatSearch';
  import ADD_CHAT_ROOM_PARTICIPANT from "@/graphql/mutations/phoenix/ycmdChatRoomParticipantAdd";
  import oooIndicator from '@/views/apps/chat/components/ooo-indicator.vue';
  import appNotinstalledIndicator from '@/views/apps/chat/components/app-not-installed-indicator.vue';

  export default {
    name: 'chatAddParticipant',
    data() {
      return {
        format,
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        panel: [0],
        selectedItems: [],
        multiSelect: false,
        contactResults: [],
        participants: [],
        search: null,
        keyboardTimer: 300,
        lastTimer: 0,
        showAddButton: true,
        loading: true,
        saving: false,
        saveDisabled: true,
        currentRoom: {}
      }
    },
    async mounted() {

      this.$emit('initialized', 'chat-add-participant', this.event)

      this.currentRoom = this.params.room

      await this.lookupContacts();

      this.loading = false;
    },
    props: {
      changeView: Function,
      params: {
          data: Object
      }
    }, 
    methods: {
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu']),
      
      async event (name, params) {
        console.log('name', name, params)
        switch (name) {
          case 'load':
            this.loading = true
            this.currentRoom = this.params.room
            this.selectedItems = []
            this.multiSelect = false
            this.contactResults = []
            this.participants = []
            await this.lookupContacts();

            this.loading = false;            
            break
        }
      },           
      searchKeydown(e) {

        this.loading = true;
      },
      async addContact(id, first_name, last_name) {

        this.loading = true;
        this.showAddButton = false;

        if (!this.participants.some(s => s.user_id === id)) {
          this.participants.push({
            user_id: id,
            first_name: first_name,
            last_name: last_name
          });

          this.search = null;

          await this.lookupContacts();

          this.showAddButton = true;
          this.loading = false;
        }
        else {
          this.showAddButton = true;
          this.loading = false;
        }
      },
      async addParticipants(id) {

        this.loading = true;
        this.showAddButton = false;

        this.searchResults[0].matches.map(m => {
          if(m.id === id) {
            m.participants.map(p => {
              if (!this.participants.some(s => s.user_id === p.user_id))
                this.participants.push({
                  user_id: p.user_id,
                  first_name: p.first_name,
                  last_name: p.last_name
                });
            })
          }
        });

        await this.lookupContacts();

        this.showAddButton = true;
        this.loading = false;
      },
      async removeParticipant(id) {
        this.loading = true;
        this.showAddButton = false;

        this.participants = this.participants.filter((p) => p.user_id !== id);

        await this.lookupContacts();

        this.showAddButton = true;
        this.loading = false;
      },
      async deleteAllParticipants() {

        this.loading = true;
        this.showAddButton = false;

        this.participants = [];

        await this.lookupContacts();

        this.showAddButton = true;
        this.loading = false;
      },
      saveParticipants() {

        this.saving = true;

        //console.log("SAVING", this.participants);

        if (this.participants.length > 0) {

          this.participants.forEach( async (p, index) => {

            // console.log(p.user_id)
            // console.log(this.currentRoom.id);

            try {

              let response = await this.$apollo.mutate({
                client: 'phoenixClient',
                mutation: ADD_CHAT_ROOM_PARTICIPANT,
                variables: {
                  room_id: this.currentRoom.id,
                  user_id: p.user_id
                },
                // update: async (cache, {data: {ycmdChatRoomParticipantAdd}}) => {
                //   try {
                //
                //     console.log("DATA: ", ycmdChatRoomParticipantAdd);
                //
                //     // const {id, name} = ycmdChatRoomCreate;
                //     //
                //     // await this.setCurrentRoom({id, name});
                //     //
                //     // this.route('CHAT.main', 'next');
                //   }
                //   catch (e) {
                //     console.error(e);
                //   }
                // }
              });

              if (this.participants.length-1 === index)
                this.changeView({view: 'chat-edit', transition: 'prev', params: {room_id: this.currentRoom.id}})
            }
            catch(e) {

              console.log("Error: ", e);
            }
          });
        }
        this.saving = false;

      },
      async lookupContacts () {

        const result = await this.$apollo.query({
          query: GET_CHAT_SEARCH,
          variables: {
            search: this.search,
            roles: ['provider','member'],
            objects: ['contact'],
            participants: this.participants.map(p => p.user_id)
          },
          client: 'phoenixClient',
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });

        if(result.data.ycmdChatSearch !== null) {

          const { contacts } = result.data.ycmdChatSearch;

          this.contactResults = [];

          if(contacts) {
            let currentParticipants = {}
            this.currentRoom.participants.map(p => {
              currentParticipants[p.user_id] = true
            })            
            console.log('currentParticipants', currentParticipants)
            contacts.map(c => {

              let group_name = c.group_name;

              if(c.group_id === "0") {
                if(c.roles.includes('provider'))
                  group_name = 'Moonlighter';
                else
                  group_name = 'NA';
              }

              if (!currentParticipants[c.user_id]) {
                this.contactResults.push({
                  first_name: c.first_name,
                  last_name: c.last_name,
                  roles: `${c.roles.join(',')}`,
                  title: c.title,
                  specialty: `${c.specialty ? `- ${ c.specialty }` : ''}`,
                  group: group_name,
                  id: c.user_id,
                  ooo: c.ooo
                })
              }
            });
            console.log('this.contactResults', this.contactResults)

          }
        }
      }
    },
    watch: {
      search (newValue, oldValue) {

        if (this.lastTimer) {
          clearTimeout(this.lastTimer);
          this.lastTimer = 0
        }

        this.lastTimer = setTimeout(async () => {

          this.loading = true;
          this.lastTimer = 0;
          await this.lookupContacts();
          this.loading = false;

        }, this.keyboardTimer)

      }
    },
    computed: {
      //...mapState('chat', ['currentRoom']),
      ...mapState('rightMenu', ['isRightMenuExpanded'])
    },
    components: {
      'ooo-indicator': oooIndicator,
      'app-not-installed-indicator': appNotinstalledIndicator
    }

  }
</script>

<style scoped>

.room-name >>> .v-overlay__content {
  display:flex;
  flex-direction: column;
  width: 100% !important;
  height: 100% !important;
}

</style>