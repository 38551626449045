<template>
  <div>
      <v-select
          light solo dense flat outlined
          :items="items"
          item-value="value"
          item-text="name"
          v-model="localValue"
          :chips="multiple"
          :multiple="multiple"
          clearable
          :rules="rules"
      >
        <template
            v-slot:append-outer
            v-if="multiple"
        >
            <v-btn
              icon
              @click.stop="all()"
            >
              <v-icon
                  color="primary"
              >
                  {{checkAllIcon}}
              </v-icon>
            </v-btn>
        </template>

      </v-select>   
  </div>
</template>

<script>

export default {

  props: {
    value: {
      default: null
    },
    list: {
      default: () => { return {} },
      type: Object
    },
    defaultValue: {
      default: null,
      type: String
    },
    multiple: {
      default: false,
      type: Boolean
    },
    rules: {
      type: Array,
      default: () => []
    }   
  },
  data: () => ({
    localValue: null
  }),
  methods: {
    all() {

      if (this.localValue && this.localValue.length == this.items.length) {
        this.localValue = []
      } else {

        let arr = []
        this.items.map (item => {
          arr.push(item.value)
        })
        this.localValue = arr
      }
    }
  },
  computed: {
    items () {
        let arr = []

        if (this.list && this.list.values && this.list.values.length) {
            this.list.values.map(l => {
                arr.push(l)
            })
        }

        return arr
    },
    checkAllIcon () {
      
      if (!this.localValue || this.localValue.length == 0) {
        return 'check_box_outline_blank'
      } else if (this.localValue.length == this.items.length) {
        return 'check_box'
      } else {
        return 'indeterminate_check_box'
      }
    }
  },
  watch: {
    localValue (newVal, oldVal) {
      this.$emit('input', newVal)
    }
  },
  mounted () {
    this.localValue = this.defaultValue
  },
  components: {
  }
}
</script>