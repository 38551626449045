<template>

    <v-row no-gutters>

        <v-col cols="6" sm="6" md="4" lg="4">
            <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                <div class="hidden-md-and-up label-title">Type</div>
                <v-icon :color="'red'"> local_hospital </v-icon>
            </div>
        </v-col>

        <v-col cols="6" sm="8" md="8" lg="8">
            <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                <div class="hidden-md-and-up label-title">Facility Name</div>
                <div class="label-value fw-500">
                    {{ item.facility_name  ? item.facility_name : 'N/A' }}
                </div>
            </div>
        </v-col>

    </v-row>

</template>

<script>

  export default {
    name: 'facilityListItem',
    props: {
        item: Object,
        selectedContact: Object
    },
    data: () => ({
    }),
    mounted() {},
    methods: {

    },
    computed: {

    }
  }
</script>

<style scoped>

</style>
