import gql from 'graphql-tag'

export default gql`mutation ycmdAuthenticateSwitchUser($user_id: ID!) {
	ycmdAuthenticateSwitchUser(user_id: $user_id) {
		token
		refresh_token
		user_id
		first_name
		last_name
		status
		link_id
		linked_users {
			id
			roles
		}
		linked_groups {
			user_id
			group_id
			group_name
		}
		provider_id
		member_id
		nurse_id
		pcp_user_id
		pcp_analyst_id
		roles
		legacy_roles
		group_id
		group_name
		group {
			name
			id
			voicemail {
				mailboxes {
					name
				}
			}
			forwarding {
				days
				start_time
				end_time
			},
			settings {
				chat_allow_same_participant_rooms
				explicit_message_confirmation
				explicit_message_confirmation_can_override  				
			}
		}
		reference_id
	}
}`
