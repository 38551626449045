export const practiceTypes = [
    {
        name: 'Group Practice',
        value: 'group'
    },
    {
        name: 'Solo Practice',
        value: 'solo'
    }
]
export const timeZoneTypes = [
    {
        name: 'Pacific',
        value: 'pacific'
    },
    {
        name: 'Arizona',
        value: 'arizona'
    },
    {
        name: 'Atlantic',
        value: 'atlantic'
    },
    {
        name: 'Central',
        value: 'central'
    },
    {
        name: 'Eastern',
        value: 'eastern'
    },
    {
        name: 'Mountain',
        value: 'mountain'
    },
]
export const timeZoneShorts = {
    'America/Los_Angeles': {
        short: 'Pacific'
    },
    'America/Denver': {
        short: 'Mountain'
    },
    'America/Chicago': {
        short: 'Central'
    },
    'America/New_York': {
        short: 'Eastern'
    },
    'America/Phoenix': {
        short: 'Arizona'
    },
    'Pacific/Honolulu':{
        short: 'Hawaii'
    }, 
}

export const entityTypes = [
    {
        name: 'Corporation',
        value: 'corporation'
    },
    {
        name: 'General Partnership',
        value: 'gen_partnership'
    },
    {
        name: 'Limited Liability Company',
        value: 'llc'
    },
    {
        name: 'Limited Partnership',
        value: 'ltd_partnership'
    },
    {
        name: 'Sole Proprietorship',
        value: 'proprietorship'
    },
    {
        name: 'S-Corporation',
        value: 's_corp'
    }
]

export const states = [
    {
        name: 'Alaska',
        value: 'AK'
    },
    {
        name: 'Arizona',
        value: 'AZ'
    },
    {
        name: 'Arkansas',
        value: 'AR'
    },
    {
        name: 'California',
        value: 'CA'
    },
    {
        name: 'Colorado',
        value: 'CO'
    },
    {
        name: 'Connecticut',
        value: 'CT'
    },
    {
        name: 'Delaware',
        value: 'DE'
    },
    {
        name: 'Florida',
        value: 'FL'
    },
    {
        name: 'Georgia',
        value: 'GA'
    },
    {
        name: 'Hawaii',
        value: 'HI'
    },
    {
        name: 'Idaho',
        value: 'ID'
    },
    {
        name: 'Illinois',
        value: 'IL'
    },
    {
        name: 'Indiana',
        value: 'IN'
    },
    {
        name: 'Iowa',
        value: 'IA'
    },
    {
        name: 'Kansas',
        value: 'KS'
    },
    {
        name: 'Kentucky',
        value: 'KY'
    },
    {
        name: 'Louisiana',
        value: 'LA'
    },
    {
        name: 'Maine',
        value: 'ME'
    },
    {
        name: 'Maryland',
        value: 'MD'
    },
    {
        name: 'Massachusetts',
        value: 'MA'
    },
    {
        name: 'Michigan',
        value: 'MI'
    },
    {
        name: 'Minnesota',
        value: 'MN'
    },
    {
        name: 'Mississippi',
        value: 'MS'
    },
    {
        name: 'Missouri',
        value: 'MO'
    },
    {
        name: 'Montana',
        value: 'MT'
    },
    {
        name: 'Nebraska',
        value: 'NE'
    },
    {
        name: 'Nevada',
        value: 'NV'
    },
    {
        name: 'New Hampshire',
        value: 'NE'
    },
    {
        name: 'New Jersey',
        value: 'NJ'
    },
    {
        name: 'New Mexico',
        value: 'NM'
    },
    {
        name: 'New York',
        value: 'NY'
    },
    {
        name: 'North Carolina',
        value: 'NC'
    },
    {
        name: 'North Dakota',
        value: 'ND'
    },
    {
        name: 'Ohio',
        value: 'OH'
    },
    {
        name: 'Oklahoma',
        value: 'OK'
    },
    {
        name: 'Oregon',
        value: 'OR'
    },
    {
        name: 'Pennsylvania',
        value: 'PA'
    },
    {
        name: 'Rhode Island',
        value: 'RI'
    },
    {
        name: 'South Carolina',
        value: 'SC'
    },
    {
        name: 'South Dakota',
        value: 'SD'
    },
    {
        name: 'Tennessee',
        value: 'TN'
    },
    {
        name: 'Texas',
        value: 'TX'
    },
    {
        name: 'Utah',
        value: 'UT'
    },
    {
        name: 'Vermont',
        value: 'VT'
    },
    {
        name: 'Virginia',
        value: 'VA'
    },
    {
        name: 'Washington',
        value: 'WA'
    },
    {
        name: 'West Virginia',
        value: 'WV'
    },
    {
        name: 'Wisconsin',
        value: 'WI'
    },
    {
        name: 'Wyoming',
        value: 'WY'
    }
]

export const memberRoles = [
    {
        name: 'Group Admin',
        value: 'admin'
    },
    {
        name: 'Billing Admin',
        value: 'billing'
    },
    {
        name: 'Operator',
        value: 'operator'
    },
    {
        name: 'Case Management (New/Established)',
        value: 'cm'
    },
    {
        name: 'Transfer Center (New)',
        value: 'tc'
    },
    {
        name: 'Utilization Review (Established)',
        value: 'ur'
    }
]

export const providerTitles = [
    {
        name: 'M.D.',
        value: 'M.D.'
    },
    {
        name: 'D.O.',
        value: 'D.O.'
    },
    {
        name: 'D.P.T.',
        value: 'D.P.T.'
    },
    {
        name: 'D.P.M.',
        value: 'D.P.M.'
    },
    {
        name: 'D.D.S',
        value: 'D.D.S'
    },
    {
        name: 'D.M.D.',
        value: 'D.M.D.'
    },
    {
        name: 'R.N.',
        value: 'R.N.'
    },
    {
        name: 'P.A.',
        value: 'P.A.'
    },
    {
        name: 'N.P.',
        value: 'N.P.'
    },
    {
        name: 'L.P.N.',
        value: 'L.P.N.'
    },
    {
        name: 'A.P.N.',
        value: 'A.P.N.'
    }
]

export const providerSpecialities = [
    {
        name: 'Anesthesiology',
        value: 'Anesthesiology'
    },
    {
        name: 'Bariatric Surgery',
        value: 'Bariatric Surgery'
    },
    {
        name: 'CCU Nurse',
        value: 'CCU Nurse'
    },
    {
        name: 'Cardiothoracic Surgery',
        value: 'Cardiothoracic Surgery'
    },
    {
        name: 'Cardiovascular Disease',
        value: 'Cardiovascular Disease'
    },
    {
        name: 'Colon/Rectal Surgery',
        value: 'Colon/Rectal Surgery'
    },
    {
        name: 'Critical Care Medicine',
        value: 'Critical Care Medicine'
    },
    {
        name: 'Dentistry',
        value: 'Dentistry'
    },
    {
        name: 'Dermatology',
        value: 'Dermatology'
    },
    {
        name: 'ER Nurse',
        value: 'ER Nurse'
    },
    {
        name: 'Emergency Medicine',
        value: 'Emergency Medicine'
    },
    {
        name: 'Endocrine',
        value: 'Endocrine'
    },
    {
        name: 'ENT / Otolaryngology',
        value: 'ENT / Otolaryngology'
    },
    {
        name: 'Family Medicine',
        value: 'Family Medicine'
    },
    {
        name: 'Fellow',
        value: 'Fellow'
    },
    {
        name: 'Diabetes',
        value: 'Diabetes'
    },
    {
        name: 'Gastroenterology',
        value: 'Gastroenterology'
    },
    {
        name: 'General Surgery',
        value: 'General Surgery'
    },
    {
        name: 'Geriatric Medicine',
        value: 'Geriatric Medicine'
    },
    {
        name: 'Gynecologic Oncology',
        value: 'Gynecologic Oncology'
    },
    {
        name: 'Hand Surgery',
        value: 'Hand Surgery'
    },
    {
        name: 'Hematology/Oncology',
        value: 'Hematology/Oncology'
    },
    {
        name: 'Hematology',
        value: 'Hematology'
    },
    {
        name: 'ICU',
        value: 'ICU'
    },
    {
        name: 'ICU Nurse',
        value: 'ICU Nurse'
    },
    {
        name: 'Infectious Diseases',
        value: 'Infectious Diseases'
    },
    {
        name: 'Internal Medicine',
        value: 'Internal Medicine'
    },
    {
        name: 'Interventional Cardiology',
        value: 'Interventional Cardiology'
    },
    {
        name: 'Med/Surg Nurse',
        value: 'Med/Surg Nurse'
    },
    {
        name: 'NICU Nurse',
        value: 'NICU Nurse'
    },
    {
        name: 'Nephrology',
        value: 'Nephrology'
    },
    {
        name: 'Neurological Surgery',
        value: 'Neurological Surgery'
    },
    {
        name: 'Neurology',
        value: 'Neurology'
    },
    {
        name: 'Neuroradiology',
        value: 'Neuroradiology'
    },
    {
        name: 'Oncology',
        value: 'Oncology'
    },
    {
        name: 'Oral Maxillofacial Surgery',
        value: 'Oral Maxillofacial Surgery'
    },
    {
        name: 'Orthopedics',
        value: 'Orthopedics'
    },
    {
        name: 'Otolaryngology',
        value: 'Otolaryngology'
    },
    {
        name: 'OB / GYN',
        value: 'OB / GYN'
    },
    {
        name: 'Pain Management',
        value: 'Pain Management'
    },
    {
        name: 'Palliative Care',
        value: 'Palliative Care'
    },
    {
        name: 'Pediatric Cardiology',
        value: 'Pediatric Cardiology'
    },
    {
        name: 'Pediatric Nurse',
        value: 'Pediatric Nurse'
    },
    {
        name: 'Pediatric Pulmonology',
        value: 'Pediatric Pulmonology'
    },
    {
        name: 'Pediatric Urology',
        value: 'Pediatric Urology'
    },
    {
        name: 'Pediatrics',
        value: 'Pediatrics'
    },
    {
        name: 'Physiatry / PMR',
        value: 'Physiatry / PMR'
    },
    {
        name: 'Plastic Surgery',
        value: 'Plastic Surgery'
    },
    {
        name: 'Podiatry',
        value: 'Podiatry'
    },
    {
        name: 'Psychiatry',
        value: 'Psychiatry'
    },
    {
        name: 'Pulmonary Medicine',
        value: 'Pulmonary Medicine'
    },
    {
        name: 'Radiation Oncology',
        value: 'Radiation Oncology'
    },
    {
        name: 'Radiology Diagnostic',
        value: 'Radiology Diagnostic'
    },
    {
        name: 'Resident',
        value: 'Resident'
    },
    {
        name: 'Rheumatology',
        value: 'Rheumatology'
    },
    {
        name: 'Spine Surgery',
        value: 'Spine Surgery'
    },
    {
        name: 'Surgical Scrub',
        value: 'Surgical Scrub'
    },
    {
        name: 'Urology',
        value: 'Urology'
    },
    {
        name: 'Others',
        value: 'Others'
    }
]
