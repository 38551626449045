<template>

  <div class="mainbox">
    <div class="err">404</div>
    <!-- <i class="far">0</i>
    <div class="err2">4</div> -->
    <div class="msg">Looks like you reached a page that does not exist!</div>
  </div>

</template>

<script>

  export default {
    name: 'pageNotFound',
    data () {
      return {

      }
    },
    methods: {
    }
  }

</script>

<style scoped>

  body {
    background-color: #95c2de;
  }

  .mainbox {
    background-color: #95c2de;
    margin: auto;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .err {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: relative;
    text-align: center;
    top: 8%;
  }

  .far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #ffffff;
  }

 .err2 {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
  }

  .msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }

</style>
