import gql from 'graphql-tag'

export default gql`mutation ycmdUserSettings(
	$enable_chat_notifications: Boolean,
	$enable_chat_notifications_days: [PCPEnableMessageDayInput],
	$room_settings: [UserRoomSettingsInput], 
	$message_view: String,
	$message_grid_font_adjustment: Int
	$out_of_office: OutOfOfficeSettingsInput
) {
	ycmdUserSettings(
		enable_chat_notifications: $enable_chat_notifications,
		enable_chat_notifications_days: $enable_chat_notifications_days,
		room_settings: $room_settings, 
		message_view: $message_view,
		message_grid_font_adjustment: $message_grid_font_adjustment
		out_of_office: $out_of_office
	) {
		callback_number
		covr_delivery_type
		theme
		message_view
		message_grid_font_adjustment
		enable_chat_notifications
		enable_chat_notifications_days {
			day
			start_time
			end_time
		}
		room_settings {
			room_id
			settings {
				enable_chat_notifications
				enable_chat_notifications_days {
					day
					start_time
					end_time
				}
			}
		}
		out_of_office {
			message
			id
			start
			end
		}
	}
}`
