import {format} from 'date-fns-tz';

export default function (year, month, day, hours, minutes, seconds, timezone) {

  let date = new Date(year, month-1,day, hours, minutes, seconds)

  let iso = format(date, "yyy-MM-dd'T'HH:mm:ssXXX",{timeZone: timezone})
  
  return iso

}