import gql from 'graphql-tag'

export default gql`mutation adminCreateUser($role: PCPRole!, $first_name: String!, $last_name: String!, $mobile_number: String!, $email: String!, $group_id: ID, $facility_ids: [ID], $ycmd_user_id: ID) {
	adminCreateUser(role: $role, first_name: $first_name, last_name: $last_name, mobile_number: $mobile_number, email: $email, group_id: $group_id, facility_ids: $facility_ids, ycmd_user_id: $ycmd_user_id) {
    email
    first_name
    last_name
    status
    date_created
    user_type
		phone
  }
}`
