<template>

    <div class="d-flex">
        <v-autocomplete
            ref="patient_select_autocomplete"
            dense flat
            v-model="selectedPatient"
            :items="computedItems"
            :loading="searching"
            :search-input.sync="searchPatients"
            :item-textxxx="searchMode"
            :filter="filterPatients"
            item-value="id"
            :placeholder="searchPlaceholder"
            :label="label"
            :rules="rules"
            clearable
            :auto-select-first="false"
            :key="`refresh_${refresh}`"
            @keydown.native="onKeydown"
            @change="patientClick"
            :background-color="backgroundColor"
            :outlined="outlined"
            :no-data-text="noDataText"
            class="align-top"
        >
            <template v-slot:prepend-inner>
                <v-tooltip bottom
                    v-if="searchIconMode=='inner'"
                >
                    <template #activator="{ on }">
                        <v-btn
                            icon
                            v-on="on"
                            class="ycmd-blue--text"
                            @click="toggleSearchMode"
                        >
                        <v-icon
                            :size="18"
                        > {{searchModeIcon}} </v-icon>
                        </v-btn>
                    </template>
                    <span>{{searchModeTooltip}}</span>
                </v-tooltip>
            </template>        
            <template v-slot:prepend>
                <v-tooltip bottom
                    v-if="searchIconMode!='inner'"
                    class="search-icon"
                >
                    <template #activator="{ on }">
                        <v-btn
                            icon
                            v-on="on"
                            class="ycmd-blue--text"
                            @click="toggleSearchMode"
                        >
                        <v-icon
                            :size="18"
                        > {{searchModeIcon}} </v-icon>
                        </v-btn>
                    </template>
                    <span>{{searchModeTooltip}}</span>
                </v-tooltip>
            </template>        
            <template v-slot:item="data">
                <span
                    v-if="data.item && data.item.action && data.item.action == 'new_patient'"
                    class="fs-10 ycmd-blue--text text-decoration-underline pointer"
                >
                    Create New Patient
                </span>
                <v-container
                    class="pa-0 ma-0"
                    v-else
                >
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                            {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="6"
                            class="pa-0 ma-0 fw-700"
                        >
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.dob"
                            >
                                <v-col cols="12"
                                    class="pa-0 ma-0"
                                >
                                <span class="fw-300">Dob:</span> <span class="fw-400">{{data.item.dob}}</span>
                                </v-col>
                            </v-row>
                            <template
                                v-if="data.item.phones && data.item.phones.length"
                            >
                                <v-row
                                    class="pa-0 ma-0"
                                    v-for="(phone,i) in data.item.phones"
                                    :key="`phone_${data.item.id}_${i}`"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                    <span class="fw-300">{{phone.name}}:</span> <span class="fw-400">{{scrubPhoneNumber(phone.value)}}</span>
                                    </v-col>
                                </v-row>
                            </template>

                        </v-col>
                        <v-col cols="6"
                            class="pa-0 ma-0 fw-700"
                        >
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.address1"
                            >
                                <v-col cols="12"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">Address: </span>
                                    <span class="fw-400">{{data.item.address1}}</span>
                                </v-col>
                            </v-row>
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.address1 && data.item.address2"
                            >
                                <v-col cols="12"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">Address (cont): </span>
                                    <span class="fw-400">{{data.item.address2}}</span>
                                </v-col>
                            </v-row>
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.address1"
                            >
                                <v-col cols="6"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">City: </span>
                                    <span class="fw-400">{{data.item.city}}</span>
                                </v-col>
                                <v-col cols="3"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">State: </span>
                                    <span class="fw-400">{{data.item.state}}</span>
                                </v-col>
                                <v-col cols="3"
                                    class="pa-0 ma-0"
                                    v-if="data.item.address1 && data.item.zip"
                                >
                                    <span class="fw-300">Zip: </span>
                                    <span class="fw-400">{{data.item.zip}}</span>
                                </v-col>                                
                            </v-row>



                        </v-col>
                    </v-row>

                    <v-divider />
                </v-container>

            </template>
            <template v-slot:selection="data">
                <v-container
                class="pa-0 ma-0"
                >
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                            {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="6"
                            class="pa-0 ma-0 fw-700"
                        >
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.dob"
                            >
                                <v-col cols="12"
                                    class="pa-0 ma-0"
                                >
                                <span class="fw-300">Dob:</span> <span class="fw-400">{{data.item.dob}}</span>
                                </v-col>
                            </v-row>
                            <template
                                v-if="data.item.phones && data.item.phones.length"
                            >
                                <v-row
                                    class="pa-0 ma-0"
                                    v-for="(phone,i) in data.item.phones"
                                    :key="`phone_${data.item.id}_${i}`"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                    <span class="fw-300">{{phone.name}}:</span> <span class="fw-400">{{scrubPhoneNumber(phone.value, false)}}</span>
                                    </v-col>
                                </v-row>
                            </template>

                        </v-col>
                        <v-col cols="6"
                            class="pa-0 ma-0 fw-700"
                        >
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.address1"
                            >
                                <v-col cols="12"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">Address: </span>
                                    <span class="fw-400">{{data.item.address1}}</span>
                                </v-col>
                            </v-row>
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.address1 && data.item.address2"
                            >
                                <v-col cols="12"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">Address (cont): </span>
                                    <span class="fw-400">{{data.item.address2}}</span>
                                </v-col>
                            </v-row>
                            <v-row
                                class="pa-0 ma-0"
                                v-if="data.item.address1"
                            >
                                <v-col cols="6"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">City: </span>
                                    <span class="fw-400">{{data.item.city}}</span>
                                </v-col>
                                <v-col cols="3"
                                    class="pa-0 ma-0"
                                >
                                    <span class="fw-300">State: </span>
                                    <span class="fw-400">{{data.item.state}}</span>
                                </v-col>
                                <v-col cols="3"
                                    class="pa-0 ma-0"
                                    v-if="data.item.address1 && data.item.zip"
                                >
                                    <span class="fw-300">Zip: </span>
                                    <span class="fw-400">{{data.item.zip}}</span>
                                </v-col>

                            </v-row>

                        </v-col>
                    </v-row>

                </v-container>
            </template>

        </v-autocomplete>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import dataClient from '@/graphql/clients/axios';
import {scrubPhoneNumber} from '@/methods/globalMethods';
    export default {
        name: 'emrPatientSelect',
        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },   
            providerId: {
                type: String,
                default: ''
            },                                 
            initialValue: null,
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            label: {
                default: 'Patient',
                type: String
            },
            searchIconMode: {
                default: 'outer',
                type: String
            },
            allowCreatePatient: {
                default: true,
                type: Boolean
            },
            patientList: {
                default: () => [],
                type: Array
            },
            value: {
                default: () => '',//{},
                type: String
            },            
        },
        data: () => ({
            searchPatients: '',
            refresh: 0,
            items: [],
            //selectedPatient: '',
            searching: false,
            searchMode: 'lastname',
            inputElement: null,
            scrubPhoneNumber,
            lastSearch: null,
            newPatientMode: false

        }),
        created() {

        },
        async mounted() {
            let ref = this.$refs.patient_select_autocomplete
            let input = ref.$el.querySelector('input')
            this.inputElement = input
            //input.addEventListener('search', this.checkClear, false)
        },
        watch: {
            searchPatients (newValue, oldValue) {
                this.doSearch(newValue)
            },
            selectedPatient (newValue, oldValue) {
                this.searchPatients = ''
            },
        },
        methods: {
            ...mapActions('emr', ['loadProviders']),
            patientClick (patient) {
                if (patient && patient.action) {
                    console.log('selectedPatient', this.selectedPatient)
                    this.selectedPatient = null
                    this.refresh += 1
                    this.$emit('newPatient')
                }
            },
            filterPatients(patient) {

                if (patient.action) {
                    return true
                }
                let property
                let search = this.searchPatients ? this.searchPatients.toLowerCase() : ''

                switch (this.searchMode) {
                    case 'lastname':
                        property = patient.lastname.toLowerCase()
                        return property.indexOf(search) >=0
                    case 'dob':

                        property = patient.dob
                        let pieces = search.split('/')
                        let comparison = ''
                        if (pieces.length == 3) {
                            comparison = `${pieces[2]}-${this.twoDigit(pieces[0])}-${this.twoDigit(pieces[1])}`
                            return property == comparison
                        }
                        break
                    case 'phone':

                        for (let i=0;i<patient.phones.length;i++) {
                            if (patient.phones[i].value.indexOf(search) >=0) {
                                return true
                            }
                        }
                        break
                }
                return false

            },
            twoDigit (num) {
                let str = num + ''
                if (str.length === 1) {
                    str = '0' + str
                }
                return str
            },
            async loadPatients(searchMode, keyword) {
                this.searching = true
                this.lastSearch = keyword

                let p = this.provider
                let params = {
                    operation: 'patient_search',
                    parameters: [
                    ]
                }
                let search = {id: searchMode, value: keyword}
                params.parameters.push(search)
                //let providerParam = {id: 'provider_id', value: p.id}
                params.parameters.push({
                    id: 'practice_id',
                    value: p.practiceId
                })
                this.$emit('clearNewPatient')

                let getResult = await dataClient('emrGet', params)

                let patients = []
                if (getResult.patients && getResult.patients.length) {
                    patients = getResult.patients.sort((a,b) => {
                        let ret = a.lastname.localeCompare(b.lastname)
                        if (ret != 0) {
                            return ret
                        }
                        ret = a.firstname.localeCompare(b.firstname)

                        return ret
                    })

                }
                this.$nextTick(() => {

                    this.items = patients

                    if (this.lastSearch === keyword) {
                        this.searching = false
                    }
                })
            },
            doSearch(keyword) {
                switch (this.searchMode) {
                    case 'lastname':
                        if (keyword && keyword.length >= 3) { //todo maybe other practices or APIs have different minimum search length
                            this.loadPatients(this.searchMode, keyword)
                        }
                        break
                    case 'dob':
                        
                        if (keyword && keyword.length == 10) { //todo maybe other practices or APIs have different minimum search length
                            this.loadPatients(this.searchMode, keyword)
                        }
                        
                        break
                    case 'phone':

                        if (keyword && keyword.length == 10) { //todo maybe other practices or APIs have different minimum search length

                            this.loadPatients(this.searchMode, keyword)
                        }

                        break
                }

            },
            onKeydown(event) {
                //return false;
                //console.log('event', event)
                let selectionStart = this.inputElement.selectionStart
                let selectionEnd = this.inputElement.selectionEnd
                console.log('selectionStart', selectionStart)
                console.log('selectionEnd', selectionEnd)

                switch (event.key) {
                    case 'Control':
                    case 'Shift':
                    case 'Backspace':
                    case 'Delete':
                    case 'CapsLock':
                    case 'ArrowRight':
                    case 'ArrowLeft':
                    case 'ArrowUp':
                    case 'ArrowDown':

                        return  //don't restrict these keys behavior

                    default:

                        if (event.ctrlKey) { //don't restrict and ctrl keys
                            return
                        }

                        switch (this.searchMode) {
                            case 'lastname':

                                if (!this.searchPatients) {
                                    this.searchPatients = ''
                                }
                                if (selectionStart === selectionEnd) {
                                    this.searchPatients += event.key
                                } else {

                                    let start = ''
                                    let end = ''

                                    if (selectionStart > 0) {
                                        start = this.searchPatients.substring(0,selectionStart)
                                    }
                                    if (selectionEnd < this.searchPatients.length) {
                                        end = this.searchPatients.substring(selectionEnd)
                                    }

                                    let temp = (start + event.key) + end
                                    this.searchPatients = temp 
                                }

                                event.preventDefault()
                                event.stopPropagation()

                                break
                            case 'dob':
                                event.preventDefault()
                                event.stopPropagation()

                                if (event.key === '-') {
                                    event.key = '/'
                                } 

                                //only numbers & /
                                if (event.key < '0' || event.key > '9') {
                                    if (event.key !== '/') {
                                        return
                                    } 
                                }

                                if (!this.searchPatients) {
                                    this.searchPatients = ''
                                }

                                let pieces = this.searchPatients.split('/')

                                let maxCount = 2
                                if (pieces.length === 3) {
                                    maxCount = 4
                                }

                                if (event.key === '/') {
                                    if (pieces.length < 3) {
                                        this.searchPatients += '/'
                                    }
                                    pieces = this.searchPatients.split('/')
                                    for (let i = 0;i<pieces.length;i++) {
                                        if (i < 2) {
                                            pieces[i] = this.twoDigit(pieces[i]) 
                                        }
                                    }
                                    this.searchPatients = pieces.join('/')
                                    return
                                }

                                if (pieces[pieces.length-1].length >= maxCount) {
                                    return
                                }
                                if (!this.willBeValidDatePiece(pieces, event.key)) {
                                    return
                                }

                                if (pieces[pieces.length-1].length === maxCount-1 && pieces.length <= 2) {
                                    this.searchPatients += event.key
                                    this.searchPatients += '/'
                                } else {
                                    this.searchPatients += event.key
                                }

                                console.log('this.searchPatients', this.searchPatients)

/*
                                switch (pieces.length) {
                                    case 1:
                                        this.searchPatients += event.key
                                        break
                                        
                                }
*/
                                break
                            case 'phone':
                                if (event.key < '0' || event.key > '9') {
                                    event.preventDefault()
                                    event.stopPropagation()                                    
                                    return false
                                }
                                break
                        }


                }

            },
            twoDigit (num) {
                let str = num + ''
                if (str.length === 1) {
                    str = '0' + str
                }
                return str
            },            
            toggleSearchMode() {
                console.log('this.searchMode', this.searchMode)
                console.log('this.searchFields', this.searchFields)

                let index = this.searchFields.findIndex((f) => {
                    return f === this.searchMode
                })
                let oldIndex = index

                if (index < 0) {
                    index = 0
                }
                if (index >= 0) {
                    index += 1
                    if (index >= this.searchFields.length) {
                        index = 0
                    }
                }

                if (oldIndex != index) {
                    this.searchPatients = ''
                }

                this.searchMode = this.searchFields[index]
/*
                switch (this.searchMode) {
                    case 'lastname':
                        this.searchMode = 'dob'
                        break
                    case 'dob':
                        this.searchMode = 'phone'
                        break
                    case 'phone':
                        this.searchMode = 'lastname'
                        break
                }
*/                
            },
            filterNonNumbers(str, otherChars) {
                let acceptableMap = {}
                if (otherChars && otherChars.length) {
                    otherChars.map(c => {
                        acceptableMap[c] = true
                    })
                }
                let stroutput = ''
                for (let i = 0; i < str.length; i++) {
                    let char = str.charAt(i)
                    if (char > '9' || char < '0') {
                        if (acceptableMap[char]) {
                            stroutput += char
                        }
                    } else {
                        stroutput += char
                    }
                }

                return stroutput
            },
            willBeValidDatePiece (pieces, char) {
                let currentPiece = pieces.length-1
                let currentValue = pieces[currentPiece]
                let proposed = currentValue + char
                
                switch (currentPiece) {
                    case 0:
                        if (parseInt(proposed) > 12) {
                            return false
                        }
                        if (char === '0' && currentValue.length) {
                            if (parseInt(proposed) <= 0) {
                                return false
                            }
                        } 
                        break
                    case 1:
                        let year = (new Date).getFullYear()
                        let dt = new Date(year,parseInt(pieces[0])-1, 0)
                        let days = dt.getDate()
                        if (parseInt(pieces[0]) === 2) {
                            days = 29
                        }

                        if (parseInt(proposed) > days) {
                            return false
                        }
                        if (char === '0' && currentValue.length) {
                            if (parseInt(proposed) <= 0) {
                                return false
                            }
                        } 
                        break
                }

                return true
            },
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getloadedProviders']
            ),
            computedItems() {
                let items = []

                if (this.patientList && this.patientList.length) {
                    console.log('this.patientList', this.patientList)

                    items.push(...this.patientList)

                    this.$nextTick(() => {
                        this.selectedPatient =this.patientList[0].id;
                    })
                    return items
                }

                items.push(...this.items)
                if (this.allowCreatePatient && this.lastSearch && !this.searching) {
                    items.push({action: 'new_patient'})
                }
                return items
            },
            selectedPatient: {
                get: function() {
                    return this.value ;
                },
                set: function(newValue) {
                    this.searchPatients = ''
                    this.$emit('input', newValue)
                }
            }, 
            searchModeTooltip () {
                switch (this.searchMode) {
                    case 'lastname':
                        return 'Last Name'
                    case 'dob':
                        return 'Date of Birth'
                    case 'phone':
                        return 'Phone Number'
                }
            },
            searchPlaceholder () {
                switch (this.searchMode) {
                    case 'lastname':
                        return 'Last Name'
                    case 'dob':
                        return 'Date of Birth (MM/dd/yyyy)'
                    case 'phone':
                        return 'Phone Number ##########'
                }
            },                        
            searchModeIcon () {
                switch (this.searchMode) {
                    case 'lastname':
                        return 'text_fields'
                    case 'dob':
                        return 'calendar_month'
                    case 'phone':
                        return 'phone'
                }
            },
            provider () {
                let p = this.$store.getters['emr/getProviderById'](this.providerId)
                return p
            },
            noDataText () {
                return this.searching ? 'Searching for patients' : 'No patients found'
            },
            practice () {
                let practice = this.$store.getters['emr/getPracticeByProviderId'](this.providerId)

                return practice
            },
            searchFields() {
                
                let practice = this.practice

                if (practice && practice.properties && practice.properties.length) {
                    let searchFieldProperty = this.practice.properties.find(p => {
                        return p.id === 'search_fields'
                    })
                    if (searchFieldProperty) {
                        return searchFieldProperty.value.split(',')
                    }
                }
                return ['lastname','dob','phone']                
            },
        }
    }
</script>

<style>
    div.v-input__prepend-outer:has(> span.search-icon) {
        margin-top: 0px;
    }
</style>