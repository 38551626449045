<template>

    <div class="view ycmd-blue-gradient">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__header">
                    <div class="d-flex align-center flex-grow-1 ml-1">

                        <v-btn icon class="hidden-sm-and-up mr-2" @click="toggleMenu">
                            <v-icon :size="22" color="white">menu</v-icon>
                        </v-btn>
                        <div class="d-flex align-center white--text fs-13" style="width: 168px">
                            <div class="hidden-xs-only fade-2 hidden-sm-and-down">Showing</div>
                            <div class="fw-500 fs-14 pl-1 pr-1">{{ this.providers.length || '0' }}</div>
                            <div class="fade-2">of</div>
                            <div class="fw-500 fs-14 pl-1 pr-1">{{total_count || '0'}}</div>
                            <v-tooltip bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark depressed v-on="on" class="ml-2 mr-2">
                                        <v-icon :size="22" @click="searchPCP()">refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh List</span>
                            </v-tooltip>
                        </div>
                        <div class="d-flex flex-grow-1">
                            <v-text-field hide-details dark flat single-line dense solo
                                          ref="searchBarRef"
                                          v-model="searchValue"
                                          v-on:keyup.enter="searchPCP"
                                          label="Search"
                                          color="blue"
                                          :class="`hidden-xs-only search-bar ycmd-medium-blue ml-2 br-0 br_tl-2 br_bl-2`"/>
                            <v-btn icon dark depressed
                                   @click="searchPCP()"
                                   class="hidden-xs-only ycmd-dark-blue mr-2 br-0 br_tr-2 br_br-2"
                                   style="width: 56px; height: 38px">
                                <v-icon>search</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="d-flex align-center justify-end ml-2" style="width: 150px">
                        <v-select hide-details light dense solo flat
                                  :items="searchFilters"
                                  :rules="[rules.required]"
                                  v-model="searchFilterSelected"
                                  append-icon="expand_more"
                                  class="full-width pt-1 ml-2 mr-0"/>
                    </div>
                </div>

                <div class="i-card__main__content white pa-2 br-2">

                    <v-container fluid class="fixed-header hidden-md-and-down">

                        <!-- Header: Make sure this matches layout below -->
                        <v-row no-gutters class="pa-0 pl-1 mr-2">
                            <v-col cols="12" sm="12" md="5" lg="6">
                                <v-row no-gutters>
                                    <v-col cols="12" md="6" lg="5">
                                        <div class="fixed-header__column">Provider
                                            <v-btn icon color="blue" class="ma-0">
                                                <v-icon :size="16">arrow_upward</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="7">
                                        <div class="fixed-header__column">Email
                                            <!-- <v-btn icon color="blue">
                                                <v-icon :size="16">arrow_downward</v-icon>
                                            </v-btn> -->
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" sm="4" md="2">
                                <div class="fixed-header__column">Mobile
                                    <!-- <v-btn icon color="blue">
                                        <v-icon :size="16">arrow_downward</v-icon>
                                    </v-btn> -->
                                </div>
                            </v-col>
                            <v-col cols="3" sm="4" md="2">
                                <div class="fixed-header__column">License
                                    <!-- <v-btn icon color="blue">
                                        <v-icon :size="16">arrow_downward</v-icon>
                                    </v-btn> -->
                                </div>
                            </v-col>
                            <v-col cols="3" sm="4" md="2">
                                <div class="fixed-header__column">Reference ID
                                    <!-- <v-btn icon color="blue">
                                        <v-icon :size="16">arrow_downward</v-icon>
                                    </v-btn> -->
                                </div>
                            </v-col>
                        </v-row>

                        <!-- Spacing for list buttons -->
                        <div class="pr-3" style="width: 234px">&nbsp;</div>
                        
                    </v-container>

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <v-container fluid :class="`list-item flex-shrink-0 ${ $vuetify.breakpoint.mdAndDown ? 'pa-1 pb-2 pl-4' : 'pa-0 pl-4' }`" v-for="(user, i) in providers" :key="`user_${i}`">

                                <v-row no-gutters>
                                    <v-row  no-gutters :class="$vuetify.breakpoint.name === 'xs' ? 'fs-11': 'fs-13'">
                                        <v-col cols="12" sm="12" md="5" lg="6">
                                            <v-row no-gutters class="d-flex fill-height">
                                                <v-col cols="12" lg="5">
                                                    <div :class="`d-flex fill-height align-center label-value fw-500  ${ $vuetify.breakpoint.mdAndDown ? 'pt-2 fs-16' : '' }`">
                                                        {{ user.last_name + ', ' + user.first_name || 'N/A' }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" lg="7" style="">
                                                    <div :class="`d-flex fill-height align-center label-value ${ $vuetify.breakpoint.mdAndDown ? 'pb-2' : '' }`">
                                                        {{ user.email || 'Email Not Provided' }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="6" sm="4" md="2" style="">
                                            <div class="d-flex fill-height flex-column justify-center">
                                                <div class="hidden-lg-and-up label-title">Mobile</div>
                                                <div class="label-value">{{ user.phone || 'N/A' }}</div>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" sm="4" md="2">
                                            <div class="d-flex fill-height flex-column justify-center">
                                                <div class="hidden-lg-and-up label-title">License</div>
                                                <div class="label-value">{{ user.license_number || 'N/A' }}</div>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" sm="4" md="2">
                                            <div class="d-flex fill-height flex-column justify-center">
                                                <div class="hidden-lg-and-up label-title">Reference ID</div>
                                                <div class="label-value">{{ user.reference_id || 'N/A' }}</div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-row>

                                <div :style="`${ $vuetify.breakpoint.smAndDown ? 'width: 120px' : 'width: 240px' }`">

                                    <v-container :class="`d-flex fill-height ${ $vuetify.breakpoint.smAndDown ? 'flex-column justify-center align-center' : 'align-center justify-end' }`">

                                        <v-row no-gutters>
                                            <v-col cols="12" md="6">
                                                <div :class="$vuetify.breakpoint.smAndDown ? 'pb-3' : ''">
                                                    <div v-if="parseStatusSelection(user.status).length <= 1" style="min-width: 96px" class="">
                                                        <div class="hidden-lg-and-up fs-10 fw-500 pt-1 pl-3">Status</div>
                                                        <div style="" class="fade-1 pl-3 fw-500 fs-13 pt-1">{{ mapStatuses[user.status] }}</div>
                                                    </div>
                                                    <div v-else>
                                                        <v-select solo light dense flat hide-details
                                                                  class="v-select__selection full-width b b-1"
                                                                  style="min-width: 96px"
                                                                  :items="parseStatusSelection(user.status)"
                                                                  :label="mapStatuses[user.status]"
                                                                  :disabled="isAnalyst"
                                                                  append-icon="expand_more"
                                                                  v-on:change="(status) => onStatusChangePress(status, user)">

                                                                  <template slot="selection" slot-scope="data">
                                                                    <!-- HTML that describe how select should render selected items -->
                                                                    <span style="color: 'white'">{{ mapStatuses[user.status] }}</span>
                                                                  </template>

                                                        </v-select>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <div class="d-flex fill-height align-center justify-center">
                                                    <v-tooltip v-if="user.status === 'verified'" left
                                                               :disabled="$vuetify.breakpoint.xsOnly"
                                                               color="ycmd-black"
                                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon light
                                                                   @click="resendRegistrationMutation(user)"
                                                                   :disabled="user.resendingVerification === true" v-on="on" color="ycmd-green">
                                                                <v-icon :size="22">email</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Resend Link</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <div class="d-flex fill-height align-center justify-center">
                                                    <v-tooltip left
                                                               :disabled="$vuetify.breakpoint.xsOnly"
                                                               color="ycmd-black"
                                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon dark text small
                                                                   @click.stop="changeView('details-view', { data: { user: user }})"
                                                                   v-on="on" color="white" class="ycmd-blue mr-1">
                                                                <v-icon :size="14">arrow_forward</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Show Details</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-container>
                                </div>

                            </v-container>

                            <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" class="ycmd-blue--text relative fw-500 fs-20">
                                <span slot="no-more" />
                                <div slot="no-results">
                                    <div class="pt-4 mt-4">No Results</div>
                                </div>
                                <div slot="spinner">
                                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </infinite-loading>
                        </div>
                    </div>
                </div>
            </div>

            <template slot="right-panel">

                <div class="i-card__panel i-card__panel__right ycmd-blue-gradient pa-2">

                    <div class="i-card__panel__header">
                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark depressed v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                        <v-icon :size="22">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Close Search Filter</span>
                            </v-tooltip>

                            <div class="white--text fs-18 ml-3">
                                Filters
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2"></div>
                    </div>

                    <div class="i-card__panel__content white br-2 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">

                                <div class="">
                                    <div class="hidden-lg-and-up fs-10 fw-500 pt-1 pb-1">Status</div>
                                    <v-select hide-details light dense
                                              :items="searchFilters"
                                              :rules="[rules.required]"
                                              v-model="searchFilterSelected"
                                              class="full-width"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </i-card>


        <v-dialog persistent v-model="dialog" max-width="330">

            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="false"
                     :background-color="`#101622`"
                     :color="`#637eb6`"
                     :loader="`bars`">
            </loading>

            <v-card v-if="currentDialog==='providerPressed'" color="" dark
                    style="background-color: #09283F; padding-top: 15px;">

                <v-card-text class="fs-20" style="text-align: center">
                    Enter Reference ID
                </v-card-text>

                <div class="d-flex justify-center">
                    <v-form
                            ref="updateForm"
                            v-model="validForm"
                            :lazy-validation="false"
                            style="max-width: 50%;">
                        <v-text-field
                                dark text
                                v-model="internalID"
                                label="Reference ID"
                                style=""
                                :rules="[rules.required]"/>
                    </v-form>
                </div>

                <v-card-text class="fs-8" style="text-align: center; padding-top: 0px; color: #FC5252;">
                    {{updateStatusError}}
                </v-card-text>

                <!-- <v-card-text class="fs-8" style="text-align: center; padding-top: 0px; color: #228B22;">
                    {{updateStatusSuccess}}
                </v-card-text> -->

                <v-card-actions style="margin-top: 0px;">
                    <v-layout column>
                        <!-- <v-btn text color="white" @click="currentDialog = 'resetPassword'; dialog = true;">Reset Password</v-btn>
                        <v-spacer/> -->
                        <v-btn text color="white" @click="updateStatus()">Save Changes</v-btn>
                        <v-spacer/>
                        <v-btn text color="white" @click="clearDialog()">Cancel</v-btn>
                        <v-spacer/>
                        <v-spacer style="height: 3px;"/>
                    </v-layout>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import { mapState, mapActions, mapMutations } from 'vuex';

    // Import component
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import UPDATE_STATUS from '../../../../graphql/mutations/pcpUpdateStatus';
    import GET_PCPS from '../../../../graphql/queries/pcpImportProviders';
    import RESEND_REGISTRATION from '../../../../graphql/mutations/authenticateResendRegistrationCode';

    const statusesStatic = ['Import', 'Opt-in', 'Sign-up', 'Verified', 'Registered', 'Active', 'Inactive', 'Rejected'];

    export default {
        name: 'providers',
        components: {
            Loading,
            InfiniteLoading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                format,
                parseISO,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                isLoading: false,
                isLoadingProvider: true,
                isAnalyst: false,
                editIndex: -1,
                dialog: false,
                currentDialog: 'providerPressed',
                currentProviderPressed: {},
                searchFilters: ['All',  ...statusesStatic, 'Tasks'],
                searchFilterSelected: 'All',
                statuses: [...statusesStatic],
                newStatus: 'Sign-up',
                updateStatusError: '',
                updateStatusSuccess: '',
                internalID: '',
                validForm: false,
                mapStatuses: {
                    'sign_up': 'Sign-Up',
                    'active': 'Active',
                    'import': 'Import',
                    'inactive': 'Inactive',
                    'opt_in': 'Opt-in',
                    'pending': 'Pending',
                    'registered': 'Registered',
                    'rejected': 'Rejected',
                    'sign_up': 'Sign-up',
                    'verified': 'Verified'
                },
                mapStatusesBack: {
                    'Sign-Up': 'sign_up',
                    'Active': 'active',
                    'Import': 'import',
                    'Inactive': 'inactive',
                    'Opt-in': 'opt_in',
                    'Pending': 'pending',
                    'Registered': 'registered',
                    'Rejected': 'rejected',
                    'Sign-up': 'sign_up',
                    'Verified': 'verified'
                },
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    }
                },
                providers: [],
                page: 1,
                limit: 20,
                searchValue: '',
                infiniteId: 1,
                total_count: ''
            }
        },
        activated() {
            this.$nextTick(() => this.$refs.searchBarRef.focus());
        },
        created() {
          if (localStorage.getItem("isClinicalAnalyst")) {
            this.isAnalyst = true;
          }
        },
        mounted() {
          if (this.toDoPressed) {
            this.searchFilterSelected = 'Tasks';
            this.setToDoPressed(false);
          } else {
            this.searchFilterSelected = 'All';
          }
        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            ...mapMutations('profile', ['setToDoPressed']),
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            providerEdit(event) {
                this.editIndex = event;
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            onStatusChangePress(status, user) {
                this.currentProviderPressed = user;
                this.changeStatus(status);
            },
            changeStatus(status) {
                // if no change
                if (this.currentProviderPressed.status === this.mapStatusesBack[status]) return
                this.newStatus = status;
                if (this.currentProviderPressed.status === 'sign_up' && status === 'Verified') {
                    // ask for interal id
                    this.dialog = true;
                } else {
                    this.updateStatus();
                }
                // console.log('change status: ', status);
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            clearDialog() {
                this.dialog = false;
                this.newStatus = 'Sign-up';
                this.updateStatusError = '';
                this.internalID = '';
                this.validForm = false;
                this.updateStatusSuccess = '';
            },
            providerPressed(user) {
                // console.log('user: ', user);
                this.currentProviderPressed = user;
                this.newStatus = this.mapStatuses[user.status];
                this.currentDialog = 'providerPressed';
                this.dialog = true;
            },
            parseStatusSelection(status) {
                switch (status) {
                    case 'opt_in':
                        return [this.mapStatuses[status], 'Verified', 'Rejected']
                        break;
                    case 'sign_up':
                        return [this.mapStatuses[status], 'Verified', 'Rejected']
                        break;
                    case 'active':
                        return [this.mapStatuses[status], 'Inactive']
                        break;
                    case 'inactive':
                        return [this.mapStatuses[status], 'Active']
                        break;
                    default:
                        // console.log('PROVIDERS: Could not parse this.currentProviderPressed.status - ', status);
                        return [this.mapStatuses[status]]
                }
            },
            validateCheck() {
                if (this.currentProviderPressed.status === 'sign_up' && this.newStatus === 'Verified') {
                    if (this.$refs.updateForm.validate()) {
                        return true;
                    } else {
                        return false;
                        // console.log('it is not valid');
                    }
                }
                return true;
            },
            async resendRegistrationMutation(user, i) {

                this.updateStatusError = '';
                this.updateStatusSuccess = '';

                user.resendingVerification = true;
                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: RESEND_REGISTRATION,
                        variables: {
                            email: user.email
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    this.isLoading = false;
                    user.resendingVerification = false;
                    console.log('Resend registration result: ', response);

                    if (response.data.authenticateResendRegistrationCode) {
                        if (response.data.authenticateResendRegistrationCode.status === 'success') {
                            this.updateStatusSuccess = 'Resent the verification code!';
                            this.$toasted.success(`Resent the verification code!`);
                        } else {
                            this.$toasted.info(`There was an issue resending the verification code`);
                            this.updateStatusError = 'There was an issue resending the verification code.';
                        }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            console.log('Resend Registration Error: ', response.errors[0].message);
                            this.$toasted.error(`There was an error resending the verification code - ${response.errors[0].message}`);
                            this.updateStatusError = response.errors[0].message;
                        } else {
                            this.$toasted.error(`There was an error resending the verification code`);
                            this.updateStatusError = "Error 3A. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('updateStatusError data did not come back: ', response);
                        this.$toasted.error(`There was an error resending the verification code`);
                    }
                } catch (e) {
                    this.isLoading = false;
                    user.resendingVerification = false;
                    this.$toasted.error(`There was an error resending the verification code`);
                    console.log('ERROR HANDLER!: ', e);
                    defaultErrorHandler(e);
                }
            },
            searchPCP() {
                this.page = 1;
                this.infiniteId += 1;
                this.providers = [];
            },
            async updateStatus() {
                this.updateStatusError = '';
                this.updateStatusSuccess = '';
                const validateResponse = this.validateCheck();
                if (!validateResponse) {
                    // console.log('It is not valid: ', validateResponse);
                    return;
                }
                this.createUserError = '';

                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: UPDATE_STATUS,
                        variables: {
                            email: this.currentProviderPressed.email,
                            status: this.mapStatusesBack[this.newStatus],
                            reference_id: this.internalID || null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    // console.log('Update status result: ', response);

                    if (response.data && response.data.pcpUpdateStatus && response.data.pcpUpdateStatus.status) {
                        if (this.internalID) {
                          this.currentProviderPressed.reference_id = this.internalID;
                          this.internalID = '';
                        }
                        this.dialog = false;
                        switch (response.data.pcpUpdateStatus.status) {
                            case 'no_change':
                                this.updateStatusSuccess = 'No change to status.';
                                this.$toasted.info(`No change to status.`);
                                break;
                            case 'success':
                                this.currentProviderPressed.status = this.mapStatusesBack[this.newStatus];
                                this.updateStatusSuccess = 'Successfully changed status!';
                                this.$toasted.success(`Successfully changed status!`);
                                break;
                        }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.updateStatusError = response.errors[0].message;
                            this.$toasted.error(response.errors[0].message);
                        } else {
                            this.updateStatusError = "Error 3A. Invalid response.";
                            this.$toasted.error(`There was an error.`);
                        }
                    } else {
                        // data did not come back
                        console.log('updateStatusError data did not come back: ', response);
                        this.updateStatusError = "Error 3A. Invalid response.";
                        this.$toasted.error(`There was an error.`);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
                this.isLoading = false;
            },
            async infiniteHandler($state) {

                let searchFilterSelectedParsed = this.mapStatusesBack[this.searchFilterSelected];
                if (this.searchFilterSelected === 'All') {
                    searchFilterSelectedParsed = null;
                } else if (this.searchFilterSelected === 'Tasks') {
                    searchFilterSelectedParsed = ["sign_up", "opt_in"];
                }

                try {
                    // console.log(this.searchFilterSelected, this.searchValue);
                    const response = await this.$apollo.query({
                        query: GET_PCPS,
                        variables: {
                            limit: this.limit,
                            page: this.page,
                            sort_direction: 'asc',
                            sort_field: 'last_name',
                            search: this.searchValue,
                            status: searchFilterSelectedParsed
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // this.isLoading = false;
                    // console.log('PROVIDERS - response for pcpImportProviders: ', response);

                    if (response.data.pcpImportProviders.total_count) {
                      this.total_count = response.data.pcpImportProviders.total_count;
                    }

                    if (response.data.pcpImportProviders && response.data.pcpImportProviders.pcp_imports && response.data.pcpImportProviders.pcp_imports.length) {
                        this.page += 1;
                        //alert(this.page);
                        this.providers.push(...response.data.pcpImportProviders.pcp_imports);
                        $state.loaded();
                    } else {
                        // data did not come back
                        // console.log('getPcpData data did not come back or is finished: ', response);
                        $state.complete();
                    }
                }
                catch (e) {
                    $state.complete();
                    defaultErrorHandler(e);
                }
            }
        },
        watch: {
            searchFilterSelected(val) {
                this.searchPCP();
            },
            toDoPressed(val) {
              if (val) {
                this.searchFilterSelected = 'Tasks';
                this.setToDoPressed(false);
                this.$nextTick(() => this.$refs.searchBarRef.focus());
              }
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open
            }),
            ...mapState('profile', ['toDoPressed'])
        }
    }

</script>

<style scoped>

    .v-select__selection {
        width: 100%;
        justify-content: center;
    }

</style>
