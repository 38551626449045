import gql from 'graphql-tag'

export default gql`mutation ycmdReportScheduleCreate(
	$schedule: ycmdReportScheduleInput
	) {
		ycmdReportScheduleCreate(
			schedule: $schedule
		)
		{
			status,
			data
		}
}`
