<template>

    <div class="view">

        <div class="d-flex flex-column flex-grow-1 relative">

            <transition :name="transition">

                <keep-alive >
                    <component v-bind:is="currentView.name" :changeView="changeView" :params="view.params"/>
                </keep-alive>

            </transition>

        </div>
    </div>
</template>

<script>

    import chatProfileList from './list.vue';
    import {mapActions, mapState} from "vuex";

    export default {
        name: 'chatProfile',
        components: {
            'list-view': chatProfileList
        },
        data() {
            return {
                visit_id: 0,
                view: {
                    name: 'list-view',
                    params: {}
                },
                transition: 'next'
            }
        },
        methods: {
            changeView({view, params}) {
                this.view.name = view;
                this.view.params = params || {};

            }
        },
        computed: {
            currentView: function() {

                this.transition = (this.view.name === 'list-view') ? 'prev' : 'next';

                return this.view;
            }
        }
    }

</script>

<style scoped>


</style>
