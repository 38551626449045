<template>

    <v-layout column class="ycmd-black full-width full-height">

        <v-layout column align-center justify-center class="full-height white--text">

            <v-layout column justify-center align-center class="full-width">

                <v-layout column class="full-width">

                    <v-layout shrink align-center class="white--text">

                        <v-layout class="fs-20 fw-300 pl-1">
                            Redirect To Mobile App
                        </v-layout>

                    </v-layout>

                    <v-layout column class="full-width mb-3">

                        <v-layout column justify-start class="ycmd-gray full-width pa-3 br-1">

                            <div class="pt-3">
                                Redirecting you to the YouCallMD mobile application in just a few seconds!
                            </div>

                            <div class="pt-3">
                                Please make sure you have the apps downloaded on your device.
                            </div>

                            <div class="pt-4">
                                iOS: <a href="https://apps.apple.com/us/app/youcallmd/id918662204">download</a>
                            </div>

                            <div class="pt-4">
                                Android: <a href="https://play.google.com/store/apps/details?id=com.youcallmd.ycmdapp&hl=en_US">download</a>
                            </div>

                            <div class="pt-6">
                                Haven't redirected yet? Click <a href="ycmdapp://?signup=pcp">here</a>
                            </div>

                        </v-layout>

                    </v-layout>

                </v-layout>

            </v-layout>

        </v-layout>

    </v-layout>

</template>

<script>

    export default {
        name: 'redirectMobile',
        data: () => ({
            test: ''
        }),
        mounted() {
          setTimeout(() => {
            window.open("ycmdapp://?signup=pcp");
          }, 5000);
        },
        methods: {
            async makeCall() {
                console.log('code sent')
            }
        }
    }

</script>

<style scoped>

</style>
