export function convertMillitaryTimeToAmPm(value) {
    if (!value) return '';
    // time = "16:30:00"; // your input

    const time = value.split(':'); // convert to array

    // fetch
    const hours = Number(time[0]);
    const minutes = Number(time[1]);
    // const seconds = Number(time[2]);

    // calculate
    let timeValue;

    if (hours > 0 && hours <= 12) {
        timeValue = "" + hours;
    } else if (hours > 12) {
        timeValue = "" + (hours - 12);
    } else if (hours == 0) {
        timeValue = "12";
    }

    timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
    // timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
    timeValue += (hours >= 12) ? " PM" : " AM";  // get AM/PM

    return timeValue
}

export function truncateText(str, num) {
    if (str.length <= num) {
        return str
    }

    if (num && str.length > num) {
        return str.slice(0, num) + '...'
    }

    return str
}

export function capitalizeFirstLetter(string) {
    if (!string) return 'N/A'
    return string.charAt(0).toUpperCase() + string.slice(1);
}
