<template>

    <v-layout v-if="isRightMenuEnabled" :key="getUserID" class="full-width full-height">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <transition :name="transition">

            <router-view class="absolute full-width full-height" />

        </transition>

    </v-layout>

</template>

<script>

    import {mapActions, mapState, mapGetters} from 'vuex';
    import innerHeight from 'ios-inner-height';
    import Loading from 'vue-loading-overlay';

    import GET_UNREAD_CHAT_COUNT from '@/graphql/queries/phoenix/ycmdChatPostUnreadCountGet';

    export default {
        name: 'chatIndex',
        components: {
            Loading
        },
        data: () => ({
            drawer: true,
            menuIndex: -1,
            card: {
                index: 0,
                panels: { left: false, right: false, top: false, bottom: false }
            },
            dialog: false,
            isLoading: false,
            transition: 'fade-in-transition',
            height: 0,
            innerHeight: 0,
            fullscreen: false
        }),
        created() {

        },
        mounted() {

            // console.log('chat mounted')

            this.height = innerHeight();
            this.innerHeight = window.innerHeight;

            // Add or remove the header based on which route meta matches
            this.fullscreen = this.$store.getters['layout/fullscreen'];

            // get chat badges
            this.getBadgeCount();
        },
        methods: {
            ...mapActions('chat', ['getBadgeCount']),
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
            '$route'(to) {

                this.transition = (to.params.transition) ? to.params.transition : 'fade-in-transition';

                if (to.matched.some(record => record.meta.fullscreen)) {
                    this.fullscreen = true;
                } else {
                    this.fullscreen = false;
                }

            }
        },
        computed: {
            ...mapState('rightMenu', ['isRightMenuEnabled']),
            ...mapGetters(
                'profile', ['getUserID']
            ),
            getInnerWidth: function () {
                return window.innerWidth;
            }
        }
    }

</script>

<style scoped>

    .menu-icon {
        color: rgba(255, 255, 255, 0.7) !important;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.25, .8, .50, 1);
    }

    .menu-icon.active {
        color: rgba(255, 255, 255, 1) !important;
        font-size: 28px;
        transition: all 0.3s cubic-bezier(.25, .8, .50, 1);
    }

    .menu-item.active {
        background-color: #177ad5;
    }

    .application--wrap {
        min-height: 100% !important;
        height: 100%;
    }

    .selected-white.v-list-group--active {
        color: white !important;
    }

    .small-input >>> .v-input {
        font-size: 12px !important;
    }
    .small-input >>> .v-input__control {
        min-height: 32px !important;
    }
    .small-input >>> .v-label {
        font-size: 12px !important;
    }

</style>
