<template>

    <v-app v-if="isRightMenuOpen" :class="`fixed ${ rightMenuClass }`" :style="`height: ${ innerHeight }px !important`">

      <div class="right-menu__content full-width full-height">
        <div class="d-flex flex-column full-width full-height br-2 relative">

          <router-view class="absolute full-width full-height" />
        </div>
      </div>

    </v-app>

</template>

<script>

  import {mapActions, mapState} from "vuex";

  import FETCH_USER_DATA from "@/graphql/queries/phoenix/ycmdMe";

  export default {
      name: 'rightMenu',
      data: () => ({
          transition: 'fade-transition',
          height: 0,
          innerHeight: 0,
          rightMenuClass: 'right-menu',
          fullscreen: false,
          prompt: false
      }),
      mounted() {},
      methods: {
        ...mapActions('app', ['logout']),
        async getUserData() {

          try {

            const response = await this.$apollo.query({
              query: FETCH_USER_DATA,
              variables: {
                include_linked_users: true
              },
              client: 'phoenixClient',
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
            });

            const { ycmdMe } = response.data;

            // update
            if (!ycmdMe)
              this.logout();

          }
          catch (e) {
            // defaultErrorHandler(e);
            console.log('Catch error: ', e);
          }
        },
        resizeMenu() {

          if(this.isRightMenuEnabled) {

            if(this.isRightMenuOpen) {

              if(this.isRightMenuExpanded) {

                if(this.isMenuOpen)
                  this.rightMenuClass = 'right-menu-expanded-with-menu';
                else
                  this.rightMenuClass = 'right-menu-expanded';
              }
              else {
                this.rightMenuClass = 'right-menu';
              }
            }
            else {
              this.rightMenuClass = 'right-menu-closed';
            }
          }
        }
      },
      computed: {
        ...mapState('rightMenu', ['isRightMenuEnabled','isRightMenuOpen','isRightMenuExpanded']),
        ...mapState({
          isMenuOpen: state => state.menu.open
        })
      },
      watch: {
        isRightMenuExpanded() {

          this.resizeMenu();
        },
        isRightMenuOpen() {

          this.resizeMenu();
        },
        isMenuOpen() {

          this.resizeMenu();
        }
      }
  }
</script>

<style>

  .right-menu {
    width: 380px;
    min-width: 380px;
    margin-left: 0;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .right-menu-closed {
    width: 0;
    min-width: 0;
    margin-left: 0;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .right-menu-expanded {
    width: 100% !important;
    margin-left: 4px;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .right-menu-expanded-with-menu {
    width: calc(100% - 224px);
    margin-left: 4px;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .right-menu__content {
    margin-top: 64px;
    margin-bottom: 8px;
    overflow: hidden;
  }

  .chat-search-bar .v-icon {
    font-size: 18px !important;
    opacity: 0.7;
  }

  @media (max-width: 600px) {
    .right-menu__content {
      margin-top: 8px;
    }
  }
</style>
