import gql from 'graphql-tag'

export default gql`query phxProviderSearch($search: String) {
	phxProviderSearch(search: $search) {
		name
		group {
			id
			name
		}
		user_id
		provider_id
  }
}`
