import gql from 'graphql-tag'

export default gql`query ycmdPatientGet($facility_id: ID, $page: Int, $limit: Int, $search: String, $mode: PatientGetMode, $user_id: ID) {
	ycmdPatientGet(facility_id: $facility_id, page: $page, limit: $limit, search: $search, mode: $mode, user_id: $user_id)
    {
		total_count
		patients {
			id
			first_name
			last_name
			dob
			location {
				name
				shortcode
				room
				unit {
					name
					shortcode
					number
				}
			}
		}
    }
}`
