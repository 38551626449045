<template>
  
  <v-card
    style="width: 420px;"
  >

    <v-card-title
      v-if="hasCurrentRoom"
    >
      <span>
      Room: {{getCurrentRoomName}}
      </span>
      <v-spacer />
      <span
      class="fs-8 ycmd-blue--text text-decoration-underline pointer"
      @click="selectRoom"
      >Change</span>    
    </v-card-title>
    <v-card-subtitle
      v-if="hasCurrentRoom"    
    >
      Participants: {{participantList}}
    </v-card-subtitle>
 
    <v-card-text
      v-if="hasCurrentRoom"
      style="max-height: 300px;min-height:300px"
      class="overflow-y-auto"
      id="chatScrollContainer"
    >
      <chat-status-room-post-list
        @scrollElementChanged="postsScrollElementChanged"
        @attachmentsLoaded="attachmentsLoaded"
       />
      <v-overlay
        :absolute="true"
        :value="loadingMore || initializing"
        opacity="0.75"
      >
        <loading theme="light" />
      </v-overlay>

    </v-card-text>
    <v-card-text
      v-if="!hasCurrentRoom"
      style="max-height: 300px;min-height:300px"
      class="overflow-y-auto"
      id="NoRoom"
    >
      No room selected
    </v-card-text>

    <v-card-actions
      v-if="hasCurrentRoom"    
    >
          <v-form
              ref="post"
              style="width:100%"
              @submit.prevent="savePost"
            >

            <div class=" br-3 d-flex align-center full-width ycmd-light-blue white--text pa-2"
                 >
              <v-tooltip top
                         nudge-top="5"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-1 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon dark depressed
                      v-on="on"
                      class="ma-0"
                      @click.stop="selectFile"
                      :disabled="sendingPost"
                      :loading="sendingAttachment"
                      color="white">
                    <v-icon dark size="18">insert_photo</v-icon>
                  </v-btn>
                </template>
                <span class="text-no-wrap">Add Picture</span>
              </v-tooltip>
              <input type="file" ref="inputUpload" style="display:none;" v-on:change="fileSelected" multiple />
              <v-text-field flat solo hide-details no-resize auto-grow
                          ref="txtTextToPost"
                          v-model="textToPost"
                          rows="1"
                          :disabled="sendingPost || sendingAttachment"
                          background-color="white"
                          class="ml-2 mr-2 ycmd-dark-blue--text"
                          label="Post a message..."/>
              <v-tooltip top
                         nudge-top="5"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-1 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon dark depressed
                      v-on="on"                      
                      :disabled="sendingAttachment || textToPost.length === 0"
                      :loading="sendingPost"
                      class="ma-0"
                      type="submit"
                      color="white">
                    <v-icon dark size="18">send</v-icon>
                  </v-btn>
                </template>
                <span>Send</span>
              </v-tooltip>
            </div>     
          </v-form> 
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import {mapGetters, mapActions} from "vuex";
import chatStatusRoomPostList from '@/views/apps/chat/components/chat-status-room-post-list.vue';
import Loading from '@/components/loading-elipsis';
import dataClient from '@/graphql/clients/axios';
export default {
  props: {
    moveToBottom: {
      type: Number,
      default: 0
    },
    changeView: Function,
    callbackChanged: Function
  },
  data: () => ({
    sendingPost: false,
    sendingAttachment: false,
    textToPost: '',
    scrollElement: null,
    loadingMore: false,
    loadingMoreScrollHeight: null,
    resizeObserver: null,
    resizeObserverInit: null,
    resizeIntervalTimer: 0,
    resizeIntervalCounter: 0,
    initializing: true,
    moveIntervalTimer: 0,
    moveIntervalCounter: 0,
    moveScrollHeight: 0,
    moveIntervalSameCounter: 0

  }),
  methods: {
    ...mapActions('chat', ['postToCurrentRoom','loadCurrentRoomMorePosts']),  
    selectRoom () {
      this.changeView()
    },   
    async callback() {
      console.log('getCurrentRoom', this.getCurrentRoom)
      console.log('this.getCurrentRoomPosts',this.getCurrentRoomPosts)
      this.textToPost = ''
      this.initializing = false
      this.doMoveToBotton(true)      
    },
    focusOnText () {
      this.$nextTick(() => {
        console.log('calling focus on ', this.$refs.txtTextToPost)
        this.$refs.txtTextToPost.focus()
      })      
    },
    doMoveToBotton (focusText) {      
      if (this.moveIntervalTimer) {
        return
      }

      let el = this.scrollElement

      if (!el) {
        return
      }

      console.log('el.scrollHeight', el.scrollHeight)


      this.moveIntervalCounter = 0
      this.moveScrollHeight = el.scrollHeight

      this.moveIntervalSameCounter = 0


      this.moveIntervalTimer = setInterval(() => {
        this.moveIntervalCounter += 1

        console.log('move interval counter', this.moveIntervalCounter)
        console.log('move interval same counter', this.moveIntervalSameCounter)
        console.log(`Original scrollHeight ${this.moveScrollHeight} `)
        console.log(`current scroll height ${el.scrollHeight} `)

        if (this.moveScrollHeight === el.scrollHeight) {
          this.moveIntervalSameCounter += 1
        } else {
          this.moveIntervalSameCounter = 0
        }
        console.log('move interval same counter', this.moveIntervalSameCounter)

        if (el.scrollHeight > this.moveScrollHeight || this.moveIntervalCounter < 2) {
          console.log('found change')
          clearInterval(this.moveIntervalTimer)
          this.moveIntervalTimer = 0
          this.performMove(focusText)
        } else if (this.moveIntervalCounter > 250) {
          console.log('cancelling')
          clearInterval(this.moveIntervalTimer)
          this.moveIntervalTimer = 0
        } else if (this.moveIntervalSameCounter > 50) {
          console.log('cancelling due to no change')
          clearInterval(this.moveIntervalTimer)
          this.moveIntervalTimer = 0
        }
      },50)

    },
    performMove (focusText) {
      let el = this.scrollElement
    
      console.log(`PM scrollHeight ${el.scrollHeight} sy:  ${el.scrollTop}`)

      el.scrollTop = el.scrollHeight;

      if (focusText) {
        this.focusOnText()
      }
    },
    onScroll (params) {
      let el = params.srcElement
      console.log(`ppp scrollTop: ${el.scrollTop}, scrollHeight: ${el.scrollHeight} clientHeight: ${el.clientHeight} `)

      if (el.scrollTop <= 0 && !this.loadingMore && this.getCurrentRoomPostsEarlierToken) {
        this.loadMore()
      }
    },
    
    async loadMore() {
      let el = this.scrollElement
      this.loadingMore = true
      this.loadingMoreScrollHeight = el.scrollHeight

      /*
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(el);
      */
      await this.loadCurrentRoomMorePosts()

      this.resizeIntervalCounter = 0

      this.resizeIntervalTimer = setInterval(() => {
        this.resizeIntervalCounter += 1

        console.log('interval counter', this.resizeIntervalCounter)
        console.log(`Original scrollHeight ${this.loadingMoreScrollHeight} current scroll height ${el.scrollHeight} diff: ${el.scrollHeight - this.loadingMoreScrollHeight} done: ${el.scrollHeight > this.loadingMoreScrollHeight}`)
        console.log(`typeof el.scrollHeight : ${typeof(el.scrollHeight)} this.resizeIntervalCounter: ${typeof(this.resizeIntervalCounter)}`)

        if (el.scrollHeight > this.loadingMoreScrollHeight) {
          console.log('found change')
          el.scrollTop = el.scrollHeight - this.loadingMoreScrollHeight
          clearInterval(this.resizeIntervalTimer)
          this.loadingMore = false
        } else if (this.resizeIntervalCounter > 500) {
          console.log('cancelling')
          clearInterval(this.resizeIntervalTimer)
          this.loadingMore = false
        }
      },50)

    },
    setScrollElement () {
      let element = this.$el.querySelector('#chatScrollContainer')
      
      if (element) {

          if (this.scrollElement) {
              this.scrollElement.removeEventListener('scroll', this.onScroll, false)
              this.scrollElement = null
          }

          this.scrollElement = element
          this.scrollElement.addEventListener('scroll', this.onScroll, false)
      }

    },
    attachmentsLoaded(room_id) {
      if (room_id == this.getCurrentRoom.id){// && this.initialRoomLoad) {
        if (!this.loadingMore) {
          this.doMoveToBotton (true)
        }
      }
    },
    postsScrollElementChanged() {
      this.setScrollElement()

      let el = this.scrollElement
      console.log(`or1 scrollTop: ${el.scrollTop}, scrollHeight: ${el.scrollHeight} clientHeight: ${el.clientHeight} `)
    }, 
    savePost () {
      this.doSavePost()
    },
    async doSavePost(attachmentId) {

      if (this.textToPost.length > 0 || attachmentId) {

        this.sendingPost = true;

        await this.postToCurrentRoom({
          text: this.textToPost,
          attachment_id: attachmentId
        });

        this.textToPost = ''
        this.sendingPost = false;
        //this.doMoveToBotton(true)
        this.performMove(true)

        //this.focusOnText()


        //this.scrollToBottom();
      }
    },
    selectFile() {
      this.$refs.inputUpload.click()
    },
    fileSelected(e) {

      if (e.target.files.length > 0) {

        for (let f in e.target.files) {

          let file = e.target.files[f]

          if (file && typeof(file) === 'object' && file.name) {

            if (file.size < 10000000) {

              let key=`tmp_${btoa(file.name)}`;

              this.saveAttachment({room_id: this.getCurrentRoom.id, type: 'saveAttachment', file, key})
            }
            else {
              this.showDialog(
                  'File Size Too Large',
                  'The file size exceeds the maximum size of 10MB. <br/>'
              )
              return;
            }
          }
        }
      }
    },
    async saveFile({url, file, meta}) {

      let headers = {
        'Content-Type': file.type
      };

      meta.map(m => {
        headers[`x-amz-meta-${m.name}`] = m.value
      });

      let result = await axios.put(url,
          file,
          {
            headers: headers
          }
      )

      return result
    },

    async saveAttachment({room_id, file, key}) {

      try {

        this.sendingAttachment = true;

        let variables = {
          content_type: file.type,
          room_id: room_id,
          meta: [{'name': 'name', 'value': file.name}]
        }

        let response = await dataClient('ycmdChatAttachmentUrlGet', variables)
        //console.log("RESPONSE", response);
        
        // check errors
        if (response.error || response.errors) {
          if (!response.error && response.errors && resoponse.errors.length){
            response.error = response.errors[0]
          }
 
          this.$toasted.error(response.error.message);          

        } else {
          const {url, meta} = response;

          //save the file
          let saveResult = await this.saveFile({url, file, meta})
          const {status} = saveResult;

          if (status === 200) {

            const attachment_id = meta.find(m => m.name === 'attachment_id').value;

            await this.doSavePost(attachment_id);
          }
        }

        this.sendingAttachment = false;
      } catch (e) {
        throw `Unknown error occurred ${e}`;
      }
    },

  },
  computed: {
    ...mapGetters('chat', ['getCurrentRoomName','getCurrentRoomParticipantList','getCurrentRoomPostsEarlierToken','getCurrentRoomPosts','getCurrentRoom']),
    ...mapGetters('profile', ['getUserID']),
    participantList () {

      let str = ''
      if (this.getCurrentRoomParticipantList && this.getCurrentRoomParticipantList.length) {
        for (let i=0;i<this.getCurrentRoomParticipantList.length;i++) {
          let participant = this.getCurrentRoomParticipantList[i]

          if (participant.user_id === this.getUserID) {
            continue
          }
          if (str) {
            str += ','
          }
          str += `${participant.first_name} ${participant.last_name}`
        }
      }
      return str
    },
    items () {
      return Array.from({ length: this.length }, (k, v) => v + 1)
    },
    hasCurrentRoom () {
      let has = this.getCurrentRoom && this.getCurrentRoom.id ? true : false
      return has 
    }
  },
  components: {
    'chat-status-room-post-list': chatStatusRoomPostList,
    loading: Loading
  },
  watch: {

  },
  mounted () {
    //this.$emit('callbackChanged','chat-status-room-display',this.callback)
    if (this.callbackChanged) {
      this.callbackChanged('chat-status-room-display',this.callback)
    } 
  
  }
}
</script>
