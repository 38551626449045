import gql from 'graphql-tag'

export default gql`query ycmdVoicemailGet($id: ID) {
	ycmdVoicemailGet(id: $id)
    {
		voicemail {
			contact_id
			callback_phone
			callback_phone_sms
			date_created
			from_phone
			group_name
			recording_url
			spelled_first_name
			spelled_last_name
			status
			birth_date
			to_phone
			transcript
			transcribe_status
			transcribe_date
			mailbox
			address_street
			address_city
			address_state
			address_zipcode
			history {
				user_id
				last_name
				type
				value
				first_name
				timestamp
			}
			language
    		call_initiation_time			
		}
		nextToken
	}
}`
