<template>

  <div class="view">

    <div class="view">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 ycmd-blue-vertical-gradient">

        <div class="i-card__main">

          <div class="i-card__main__header" style="height: 56px">

            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width white--text pl-3 pr-3" style="height:56px">
                <div>
                  <v-btn icon dark elevation="0" @click="routeRightMenu({name: 'CHAT.edit', transition: 'prev'})">
                    <v-icon size="18">arrow_back</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-column flex-grow-1 pl-2 pr-2 fs-12">
                  MEMBER PROFILE
                </div>
                <div class="d-flex align-center hidden pr-2">
                  <v-btn icon dark elevation="0" @click="routeRightMenu({name: 'CHAT.message', transition: 'up'})">
                    <v-icon size="18">add</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex align-center full-height pl-3 ml-1" style="border-left: solid 2px #125ea3">
                  <v-tooltip top
                             nudge-top="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-1 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon dark depressed
                          v-on="on"
                          class="ma-0"
                          @click="isRightMenuExpanded ? collapseRightMenu() : expandRightMenu()">
                        <v-icon dark size="20">{{ isRightMenuExpanded ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-no-wrap">{{ isRightMenuExpanded ? 'Minimize Chat' : 'Maximize Chat' }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>

          </div>

          <div class="i-card__main__content" style="border-top: solid 2px #125ea3">

            <div class="i-card__scrollable white--text">

              <div class="full-width full-height pa-4">
                <div class="d-flex">
                  <div class="d-flex justify-center full-width white br-2 ma-2" style="height: 108px">
                    <v-icon color="ycmd-medium-blue" :size="120" style="bottom:-12px">person</v-icon>
                  </div>
                  <div class="d-flex justify-center align-center flex-column pl-4 pr-4">
                    <v-tooltip top
                               nudge-top="5"
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-1 pb-2">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon dark depressed
                            v-on="on"
                            class="ma-0 mb-4"
                            @click="routeRightMenu({name: 'CHAT.edit', transition: 'next'})"
                            color="white">
                          <v-icon dark size="16">email</v-icon>
                        </v-btn>
                      </template>
                      <span class="text-no-wrap">Message</span>
                    </v-tooltip>
                    <v-tooltip top
                               nudge-top="5"
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-1 pb-2">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon dark depressed
                            v-on="on"
                            class="ma-0 mt-4"
                            @click="routeRightMenu({name: 'CHAT.edit', transition: 'next'})"
                            color="white">
                          <v-icon dark size="16">phone</v-icon>
                        </v-btn>
                      </template>
                      <span class="text-no-wrap">Call</span>
                    </v-tooltip>
                  </div>

                </div>
                <v-row class="pa-2 pt-4">
                  <v-col cols="6" sm="4" md="3" lg="2">
                    <div class="label-title dark">NAME</div>
                    <div class="label-value dark">Keith Shannon</div>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2">
                    <div class="label-title dark">TITLE</div>
                    <div class="label-value dark">MD</div>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2">
                    <div class="label-title dark">SPECIALTY</div>
                    <div class="label-value dark">Oncology</div>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2">
                    <div class="label-title dark">GROUP</div>
                    <div class="label-value dark">Platinum Health</div>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2">
                    <div class="label-title dark">MOBILE</div>
                    <div class="label-value dark">702-111-1111</div>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2">
                    <div class="label-title dark">OFFICE</div>
                    <div class="label-value dark">702-111-111</div>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2">
                    <div class="label-title dark">EMAIL</div>
                    <div class="label-value dark">keith.shannon@platinumhealth.com</div>
                  </v-col>
                </v-row>
              </div>

            </div>
          </div>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>

  import {mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: 'chatProfileList',
    data() {
      return {
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        transition: 'fade'
      }
    },
    mounted() {

    },
    methods: {
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu','routeRightMenu']),
    },
    computed: {
      ...mapState('rightMenu', ['isRightMenuExpanded'])
    }
  }
</script>

<style>


</style>