<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__header">
                    <div class="d-flex align-center flex-grow-1 ml-1">

                        <v-tooltip bottom
                                   nudge-right="56"
                                   nudge-bottom="10"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn dark icon depressed v-on="on" @click="() => { isEditable = false; changeView('list-view', { data: { user: savedData } }) }">
                                    <v-icon :size="22">arrow_back</v-icon>
                                </v-btn>
                            </template>
                            <span>Back To Providers</span>
                        </v-tooltip>
                        <div class="white--text fs-18 ml-3">
                            Provider Details
                        </div>
                    </div>
                    <div class="d-flex align-center justify-end ml-2 mr-2">

                        <!-- <v-tooltip bottom
                                   nudge-bottom="10"
                                   nudge-right="36"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark depressed v-on="on" @click="slide('right')" class="ml-2 mr-2">
                                    <v-icon :size="22">bar_chart</v-icon>
                                </v-btn>
                            </template>
                            <span>Show History</span>
                        </v-tooltip> -->
                    </div>
                </div>

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable pr-1">

                        <div class="d-flex align-center">

                            <!-- <div>
                                <template v-if="!isEditable">
                                    <v-tooltip left
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon light @click="isEditable = true"
                                                   v-on="on" color="ycmd-green" class="mr-3">
                                                <v-icon :size="22">edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>
                                </template>
                                <div v-else style="display: flex; flex-direction: row;">
                                    <v-tooltip left
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon light @click="onCancelEdit"
                                                   v-on="on" color="red" class="mr-3">
                                                <v-icon :size="22">cancel</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Cancel</span>
                                    </v-tooltip>
                                    <v-tooltip left
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon light @click="onSubmitEdit"
                                                   v-on="on" color="ycmd-green" class="mr-3">
                                                <v-icon :size="22">save</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Save</span>
                                    </v-tooltip>
                                </div>
                            </div> -->
                            <v-container fluid>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="6">
                                        <div class="d-flex align-center fs-16 fw-500">
                                            {{ params.data.user.first_name || 'N/A' }}
                                            {{ params.data.user.middle_name || '' }}
                                            {{ params.data.user.last_name || 'N/A' }}
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <div :class="`d-flex align-center ${ $vuetify.breakpoint.xsOnly ? 'fs-12 pt-2' : 'fs-14 justify-end' }`">
                                            <div class="fw-500 pr-3">ID:</div>
                                            {{ params.data.user.id || 'N/A' }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <v-container fluid class="ycmd-light-gray full-height pa-0 b b-1 br-2">
                            <v-form
                                  ref="editUserForm"
                                  v-model="validEditUser"
                                  class="d-flex flex-column full-width full-height pl-4 pr-4">
                                <div>
                                    <v-row>
                                        <v-col cols="6" sm="6" md="4">
                                            <div class="label-title pt-2 pb-2">First Name</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-firstname"
                                                              :value="first_name || 'N/A'" v-model="first_name" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ first_name|| 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="4">
                                            <div class="label-title pt-2 pb-2">Last Name</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-lastname"
                                                              :value="last_name || 'Not Provided'" v-model="last_name" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ last_name|| 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <div class="label-title pt-2 pb-2">Email</div>
                                            <div class="label-value pt-2 pb-4">
                                                {{ email || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="4">
                                            <div class="label-title pt-2 pb-2">Mobile</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-phone"
                                                              :value="phone || 'Not Provided'" v-model="phone" :rules="[rules.phone]" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ phone || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="4">
                                            <div class="label-title pt-2 pb-2">License</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-license"
                                                              :value="license_number || 'N/A'" v-model="license_number" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ license_number || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="4">
                                            <div class="label-title pt-2 pb-2">Reference ID</div>
                                            <div v-if="isEditable">
                                                <v-text-field light solo dense flat outlined
                                                              name="input-reference"
                                                              :value="reference_id || 'N/A'" v-model="reference_id" />
                                            </div>
                                            <div v-else class="label-value pt-2 pb-4">
                                                {{ reference_id || 'Not Provided'}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row no-gutters class="d-flex align-end pb-4">
                                    <v-col cols="12" class="d-flex justify-end">
                                        <v-btn dark depressed
                                               color="ycmd-blue"
                                               class="mb-0 mr-0"
                                               @click="onSubmitEdit()">{{isEditable ? 'Save' : 'Edit'}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </div>
                </div>
            </div>

            <template slot="right-panel">

                <div class="i-card__panel i-card__panel__right ycmd-blue-gradient pa-2">

                    <div class="i-card__panel__header">

                        <div class="d-flex align-center flex-grow-1">

                            <!-- <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="30"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark depressed v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                        <v-icon :size="22">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Close Menu</span>
                            </v-tooltip> -->

                            <div class="white--text fs-18 ml-3">
                                Provider Stats
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2"></div>
                    </div>

                    <div class="i-card__panel__content white br-2 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">
                                Content
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </i-card>
    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import GET_MESSAGES from '../../../../graphql/queries/pcpMessages';
    import SAVE_FIELDS from '../../../../graphql/mutations/pcpImportProviderUpdate';

    export default {
        name: 'providerDetails',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                format,
                parseISO,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                isLoading: false,
                isEditable: false,
                message: {},
                loadedData: false,
                savedData: {},

                saveFieldsError: '',
                validEditUser: false,
                phone: null,
                email: null,
                last_name: null,
                first_name: null,
                license_number: null,
                reference_id: null,

                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    }
                }
            }
        },
        activated() {
            this.setDataFromParams();
            this.loadedData = true;
        },
        mounted() {

        },
        methods: {
            onSubmitEdit() {
              // editUserForm
              if (!this.isEditable) { this.isEditable = true; return; };

              if (this.$refs.editUserForm.validate()) {
                this.isEditable = false;
                this.saveChanges();
              }
            },
            setDataFromParams() {
              this.phone = this.params.data.user.phone || null;
              this.email = this.params.data.user.email || null;
              this.last_name = this.params.data.user.last_name || null;
              this.first_name = this.params.data.user.first_name || null;
              this.license_number = this.params.data.user.license_number || null;
              this.reference_id = this.params.data.user.reference_id || null;
            },
            onCancelEdit() {
              this.setDataFromParams();
              this.isEditable = false;
            },
            setParamsOnSuccess() {
              this.phone = this.savedData.phone;
              this.params.data.user.phone = this.phone;

              this.params.data.user.email = this.email;
              this.params.data.user.last_name = this.last_name;
              this.params.data.user.first_name = this.first_name;
              this.params.data.user.license_number = this.license_number;
              this.params.data.user.reference_id = this.reference_id;
            },
            async saveChanges() {
                this.isLoading = true;
                try {
                    //console.log(this.searchFilterSelected, this.searchValue);
                    console.log(this.phone, this.reference_id, this.license_number);
                    const response = await this.$apollo.mutate({
                        mutation: SAVE_FIELDS,
                        variables: {
                          first_name: this.first_name,
                          last_name: this.last_name,
                          email: this.email,
                          license_number: this.license_number,
                          reference_id: this.reference_id,
                          phone: this.phone
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    // console.log('pcpImportProviderUpdate - response for pcpImportProviderUpdate: ', response);

                    if (response.data.pcpImportProviderUpdate) {
                        this.loadedData = true;
                        this.savedData = response.data.pcpImportProviderUpdate;
                        this.setParamsOnSuccess();
                        console.log('saved data?: ', this.savedData);
                        this.$toasted.success(`Successfully updated provider!`);
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.saveFieldsError = response.errors[0].message;
                            this.$toasted.error(`There was an error updating this provider: ${response.errors[0].message}`);
                            // this.$toasted.error(response.errors[0].message);
                        } else {
                            this.saveFieldsError = "Error 3A. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('pcpImportProviderUpdate data did not come back: ', response);
                        this.$toasted.error(`There was an error updating this provider`);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
