import gql from 'graphql-tag'

export default gql`mutation billingGroupUpdate($group_id: ID, $billing_group: BillingGroupInput) {
	billingGroupUpdate(group_id: $group_id, billing_group: $billing_group) {
		name
		altName
		shortCode
		group_id
		group_id_postgres
  }
}`
