<template>

  <div :key="forceReload" class="view">

    <div class="view">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 white black--text">

        <div class="i-card__main">

          <div class="i-card__main__header ycmd-medium-blue white--text">
            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width pl-2 pr-2" style="height:56px">
                <div class="d-flex flex-column flex-grow-1 pl-4 pr-2 fs-12 ">
                  MY CHAT ROOMS
                </div>
                <div class="d-flex align-center pr-2">
                  <v-btn icon elevation="0" @click="changeView({view: 'chat-general-settings', transition: 'next'})">
                    <v-icon size="18" class="white--text">settings</v-icon>
                  </v-btn>
                </div>
             
              </div>
              <div class="d-flex justify-left pl-4 pr-1 "
                   style="border:none;">

                <v-text-field flat solo hide-details clearable dark prepend-icon="search"
                              v-model="search"
                              background-color="transparent"
                              label="Search rooms and contacts ..."
                              class="pr-5"
                              style="max-width:260px;width:260px;"
                              ref="txtSearch"
                              >
                </v-text-field>

                <v-chip
                    v-for="item in selectedUsers"
                    :key="item.user_id"
                    class="ma-1 pa-1 fs-11 ycmd-very-lightest-blue"
                    close
                    @click:close="removeUser(item)"
                  >
                    {{item.name}}
                </v-chip>

                <v-spacer />


              </div>
            </div>
          </div>

          <div class="i-card__main__content">

            <div class="d-flex" 
              v-if="canStartNewConversation"
            >

              <div
                  class="d-flex align-center full-width pl-2 "
                  style="height: 48px"
                  >
                <v-form
                    ref="post"
                    style="width:100%;"
                    @submit.prevent="savePost"
                    class="pa-0"
                  >

                    <v-text-field flat solo hide-details clearable dark
                                  v-model="startConversation"
                                  :disabled="sendingPost"
                                  background-color="ycmd-light-blue"
                                  :label="newConversationText"
                                  class="px-0 white--text"
                                  id="startConversation"
                                          >
                      <v-icon
                        class="ycmd-light-blue--text"
                        slot="prepend"
                      >add_comment</v-icon>
                      <div
                      slot="append"
                      >
                        <v-tooltip top
                                  nudge-top="5"
                                  :disabled="$vuetify.breakpoint.xsOnly"
                                  color="ycmd-black"
                                  content-class="fade-0 pa-4 pt-1 pb-2"

                                  >
                          <template v-slot:activator="{ on }">
                            <v-btn
                                icon dark depressed
                                v-on="on"                      
                                :disabled="startConversation.length === 0"
                                :loading="sendingPost"
                                class="ma-0"
                                type="submit"
                                color="white">
                              <v-icon dark size="18">send</v-icon>
                            </v-btn>
                          </template>
                          <span>Send</span>
                        </v-tooltip>
                      </div>
                    </v-text-field>
                    
                </v-form>

              </div>
            </div>
            <div class="d-flex" style="border-bottom: solid 2px #125ea3">

              <div class="d-flex align-center full-width pl-2 " style="height: 48px">

                <div class="pl-4"

                >
                  <div class="fs-12">
                    <span >{{ matchCount }}</span>
                    <span class="pl-2 pr-2">Rooms</span>
                  </div>
                </div>
                
                <v-spacer
                />
                <transition
                  name="fade-transition"
                  mode="out-in"
                >
                  <div >
                    <v-tooltip top
                               nudge-top="5"
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-1 pb-2">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon depressed
                            v-on="on"
                            class="ma-0 mr-2"
                            elevation="0" @click.stop="lookupRooms(true)">
                          <v-icon size="18">refresh</v-icon>
                        </v-btn>
                      </template>
                      <span class="text-no-wrap">Refresh</span>
                    </v-tooltip>

                  </div>
                </transition>
              </div>
            </div>

            <div class="i-card__scrollable" id="chat_main_rooms_container">
              <template v-if="!loading">
                <room-list
                  :rooms="roomsParameter"
                  :contacts="contactsParameter"
                  :search="search"
                  :loading="loading"
                  :noMatchText="noMatch"
                  @selectUser="selectUser"
                  @changeRoom="changeRoom"
                />

              </template>
              <template v-else>
                <loading theme="light" :center="true" class="pr-5" />

              </template>
            </div>


          </div>
        </div>

      </i-card>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import {mapActions, mapState, mapGetters} from "vuex";
  import { format, utcToZonedTime } from 'date-fns-tz';
  import { differenceInHours } from 'date-fns';
  import { truncateText } from '@/methods/utils';

  import GET_CHAT_SEARCH from '@/graphql/queries/phoenix/ycmdChatSearch';
  import LEAVE_CHAT_ROOM from "@/graphql/mutations/phoenix/ycmdChatRoomLeave";
  import Loading from '@/components/loading-elipsis';
  import dataClient from '@/graphql/clients/axios';
  import roomList from '@/views/apps/chat/components/room-list.vue';
  export default {
    name: 'chatRoomList',
    props: {
        changeView: Function,
        params: {
            data: Object
        }
    },    
    components: {
      loading: Loading,
      'room-list': roomList
    },           
    data() {
      return {
        format,
        truncateText,
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        searchResults: [],
        participants: [],
        search: null,
        searchedFor: null,
        keyboardTimerLength: 500,
        searchTimer: null,
        loading: false,
        matchCount: 0,
        message_id: null,
        forceReload: 0,
        roomsParameter: [],
        contactsParameter: [],
        selectedUsers: [],
        nextToken: null,
        loadingMore: false,
        scrollElement: null,
        sendingPost: false,
        startConversation: '',
        canStartNewConversation: false,
        newConversationText: ''
      }
    },
    async mounted() {
      this.setScrollElement()
      if (this.$route.params && this.$route.params.message_id)
        this.message_id = this.$route.params.message_id;

      await this.lookupRooms(true);

      this.loading = false;
      this.$emit('initialized', 'chat-rooms', this.event)
    },
    destroyed () {
        // when unmounting
        //this.clearLastMessage(); // clear room list from subscriptions
    },
    methods: {
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu',]),
      ...mapActions('chat', ['loadCurrentRoom', 'createRoom']),      

      hasMatch () {
        let selUsersMap = {}
        this.selectedUsers.map(u => {
          selUsersMap[u.user_id] = true
        })
        selUsersMap[this.getUserID] = true
        
        if (!this.roomsParameter || !this.roomsParameter.length) {
          return false
        }

        let match
        for (let i=0;i<this.roomsParameter.length;i++){
          let item = this.roomsParameter[i]
          if (item.object_type === 'room') {
            let arr = item.participant
            if (!arr) {
              arr = []
            }
            
            if (arr.length === this.selectedUsers.length + 1) { // add current user to selected users
              match = true
              for (let j=0;j<arr.length;j++) {
                let part = arr[j]
                if (!selUsersMap[part.user_id]) {
                  match = false
                  continue
                }
              }              
            }
          }
          if (match) {
            return true
          }
        }

        return false          
      },


      selectUser (user) {
        this.search = ''
        this.selectedUsers.push(user)
        //this.lookupRooms(true,null,'selectUser')    search watch will do lookup
        this.$nextTick(() => {
          this.$refs.txtSearch.focus()
        })        
      },
      removeUser (item) {
        let index = this.selectedUsers.findIndex(i => {
            return i.user_id == item.user_id
        })

        if (index >= 0) {
            this.selectedUsers.splice(index, 1)
        }
        this.search = ''
        this.lookupRooms(true,null,'removeUser')
      },
      async changeRoom (room) {

        let element = this.$el.querySelector('#chat_main_rooms_container')

        let limitOverride
        if (!this.selectedUsers || !this.selectedUsers.length) {
          limitOverride = this.roomsParameter.length
        }

        this.changeView({view: 'chat-main', transition: 'next', 
                          params: {room_id: room.id, 
                                  room_name: room.name, 
                                  selectedUsers: this.selectedUsers, 
                                  position: element.scrollTop,
                                  limitOverride
                                  }
                        })  

        let response = await dataClient('ycmdChatRoomClearUnread', {room_id: room.id})
        
        this.$store.dispatch('app/syncBadges', {from: 'changeApp', type: 'chat_post'}, {root:true})                              
      },      
      async event (name, params) {

        this.setScrollElement()
        switch (name) {
          case 'load':
            await this.lookupRooms(true, params.limitOverride,'setScrollElement')

            setTimeout(() => {
              if (params.position) {
                let element = this.$el.querySelector('#chat_main_rooms_container')
                element.scrollTop = params.position
              }
              
            }, 500);
            break
        }        
      },

      convertMatches(matches) {
        let arr = []
        if (matches) {
          matches.map(m => {
            arr.push(m.source)
          })
        }
        
        return arr
      },
      async lookupRooms(clear, limitOverride, caller) {
   
        if (clear) {
          this.loading = true;
          this.canStartNewConversation = false
        }
        this.newConversationText = `Start your conversation with ${this.messageRecipients}` 
        let nextToken = clear ? null: this.nextToken

        let limit = this.selectedUsers.length ? 200 : 10
        
        if (limitOverride) {
          limit = limitOverride
        }

        let objects =  this.search ? ['contact','room'] : ['room']

        let params = {
          objects: objects,
                      participants: [],
                      roles: ['provider','member'],
                      search: this.search,
                      nextToken: nextToken,
                      limit: limit 
        // since we are now restricting the ability to create duplicate rooms, 
        // when they pin a user, make sure you get enough rooms so we can check for dup participants
        }

        if (this.selectedUsers && this.selectedUsers.length) {
          this.selectedUsers.map(u => {
            params.participants.push(u.user_id)
          })
        }

        let searchResults = await dataClient('ycmdChatSearch',params)
        this.nextToken = searchResults.nextToken
        

        let tempItems
        if (clear) {
          tempItems = this.convertMatches(searchResults.matches)
        } else {
          tempItems = this.roomsParameter
          tempItems.push(...this.convertMatches(searchResults.matches))
        }

        this.roomsParameter = tempItems
        this.contactsParameter = searchResults.contacts
        this.matchCount = searchResults.totalMatches
        this.startConversation = ''
        this.loading = false
        this.loadingMore = false
        
        if (this.selectedUsers.length && !this.search) {
          let hasMatch = this.hasMatch()
          if (hasMatch) {
            this.newConversationText = `Create another room with ${this.messageRecipients}` 
          }
          console.log('this.getGroup', this.getGroup)
          if (!this.search) {
            if (!hasMatch) {
              this.canStartNewConversation = true
            } else if (this.getGroup && this.getGroup.settings && this.getGroup.settings.chat_allow_same_participant_rooms) {
              this.canStartNewConversation = true
            }
          }
        }

      },      

      setScrollElement () {
        let element = this.$el.querySelector('#chat_main_rooms_container')
        
        if (element) {

            if (this.scrollElement) {
                this.scrollElement.removeEventListener('scroll', this.onScroll, false)
                this.scrollElement = null
            }

            this.scrollElement = element
            this.scrollElement.addEventListener('scroll', this.onScroll, false)
        }

      }, 
      onScroll (params) {
        let el = params.srcElement

        console.log(`room scrollTop: ${el.scrollTop}, scrollHeight: ${el.scrollHeight} clientHeight: ${el.clientHeight} max: ${el.scrollHeight - el.clientHeight}`)
                
        let lastPage = el.scrollHeight - el.clientHeight

        let current = el.scrollTop + el.clientHeight
        console.log(`lastPage: ${lastPage}, current: ${current} `)

        if (current > lastPage && this.nextToken && !this.loadingMore) {
          this.loadingMore = true
          this.lookupRooms(false,null,'onScroll')
        }

      },
      
      async savePost () {

        if (this.startConversation.length > 0 && this.selectedUsers.length) {

          this.loading = true      

          this.sendingPost = true;
          let arr = []
          this.selectedUsers.map(u => {
            arr.push(u.user_id)
          })

          let room = await this.createRoom({
            post_text: this.startConversation,
            participants: arr 
          });
        
          this.loadCurrentRoom(room)

          this.changeRoom(room)

          this.startConversation = ''
          this.sendingPost = false;
        
          this.loading = false  
        }

      },      

    },
    watch: {
        search (value) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(async () => {
                // remember what we searched for
                this.searchedFor = this.search;

                this.loading = true;
                this.searchTimer = null;
                await this.lookupRooms(true,null,'watchsearch');
                // this.loading = false;
            }, this.keyboardTimerLength)
        },
    },
    computed: {
      ...mapState('rightMenu', ['isRightMenuExpanded']),
      ...mapState('profile', ['info']),
      ...mapState('chat', ['subscriptionCounter','getCurrentRoom']),
      ...mapGetters('profile', ['getUserID', 'getGroup']),    
    
      messageRecipients () {
        let recipients = ''
        let delimiter = ''
        this.selectedUsers.map(u => {
          recipients += delimiter
          recipients += `${u.first_name} ${u.last_name}`

          delimiter = ', '
        })
        return recipients      
      },
      noMatch () {
        let text = 'No matches'
        
        if (this.selectedUsers.length) {
          return text
        }
        if (this.search) {
          return text
        }
        
        let has = this.getCurrentRoom && this.getCurrentRoom.id ? true : false
        if (!has) {
          text = 'No matches.  Search for contacts to start a chat room'
        } 
  
        return text
      }


    },
  }
</script>

<style scoped>
#startConversation {
  color: white
}

</style>
