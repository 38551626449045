<template>

    <v-autocomplete
        v-model="localValue"
        :items="items"
        outlined
        dense
        chips
        small-chips
        :label="label"
        item-value="id"
        :item-text="getName"
        :multiple="!single"
        :disabled="disabled"
        :rules="rules"
        @change="changed"
        background-color="white"
        :error-messages="errorMessages"  
        @focus="focused"

    >
        <template
            v-slot:append-outer
        >
<!--        
            <v-btn
                icon
                @click="selectAll"
            >
                <v-icon
                    :color="selectAllColor"
                >
                    {{selectAllIcon}}
                </v-icon>
            </v-btn>
-->

            <v-tooltip 
                        v-if="include_select_all"
                        bottom
                       nudge-bottom="0"
                       nudge-right="32"
                       color="ycmd-black"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                    <v-btn icon
                           @click="selectAll"
                           v-on="on">
                            <v-icon
                                :color="selectAllColor"
                            >
                                {{selectAllIcon}}
                            </v-icon>

                    </v-btn>
                </template>
                <span>{{selectAllTooltip}}</span>
            </v-tooltip>



        </template>    
    
    </v-autocomplete>

</template>

<script>
import ycmdUserSearch from '@/graphql/queries/phoenix/ycmdUserSearch'

    export default {
        name: 'searchBar',
        components: {
        },
        props: {
            label: {
                type: String,
                default: 'Select the users'
            },   
            value: {
                default: null
            },
            roles: {
                type: Array,
                default: () => []
            },  
            include_searching_user: {
                type: Boolean,
                default: false
            }, 
            include_select_all: {
                type: Boolean,
                default: false
            },              
            exclude_group_filter: {
                type: Boolean,
                default: false
            },  
            single: {
                type: Boolean,
                default: false
            },
            group_ids: {
                type: Array,
                default: null
            },
            rules: {
                type: Array,
                default: () => []
            },                
            disabled: {
                default: false,
                type: Boolean
            },
            errorMessages: {
                type: Array,
                default: () => []
            },              
            selectAllColor: {
                type: String,
                default: 'cancel'
            },              

        },
        data: () => ({
            items: [],
            localValue: null
        }),
        async mounted() {
            
            if (!this.items || !this.items.length) {
                await this.loadUsers(this.roles, this.include_searching_user, this.exclude_group_filter, this.group_ids)
            }
            
            this.localValue = this.value
        },
        methods: {
            selectAll () {
                let arr = []
                let icon = this.selectAllIcon
                switch (icon) {
                    case 'check_box_outline_blank':
                        this.items.map(i => {
                            arr.push(i.id)
                        })
                        
                        this.localValue = arr
                        break
                    
                    default:
                        this.localValue = []
                
                }
            },
            changed (params) {
                this.$emit('input', this.localValue)
            },
            focused (params) {
                this.$emit('focus', this.localValue)
            },
            getName(item) {              
              return `${item.first_name} ${item.last_name}`
            },
            async loadUsers(roles, include_searching_user, exclude_group_filter, group_ids) {
                let params = {
                    roles: roles,
                    include_searching_user: include_searching_user,
                    exclude_group_filter: exclude_group_filter
                }
                if (group_ids && group_ids.length) {
                    params.group_ids = group_ids
                }

                let response = await this.$apollo.query({
                    query: ycmdUserSearch,
                    variables: params,
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                if (!response.data || !response.data.ycmdUserSearch) {
                    return []
                }
                let all

                if (this.exclude_group_filter) {
                    all = response.data.ycmdUserSearch.find(r => {
                        return r.group_id === '0'
                    })
                } else {
                    all = {users: []}
                    response.data.ycmdUserSearch.map(g => {
                        all.users.push(...g.users)
                    })
                }

                let sorted = all.users.sort( (a,b) => {
                    let asortable = (a.first_name ? a.first_name : '') + (a.last_name ? a.last_name : '')
                    let bsortable = (b.first_name ? b.first_name : '') + (b.last_name ? b.last_name : '')
                    return asortable.localeCompare(bsortable)
                })

                this.$emit('update:users', sorted)
                this.items = sorted
                return sorted
            }
        },
        computed: {
            selectAllIcon () {
                
                if (this.localValue && this.localValue.length) {
                    return this.items.length === this.localValue.length ? 'check_box' : 'indeterminate_check_box'
                }
                
                return 'check_box_outline_blank'
            },
            selectAllTooltip () {
                let icon = this.selectAllIcon
                switch (icon) {
                    case 'check_box_outline_blank':
                        return 'Select all'
                    
                    default:
                        return 'Unselect all'
                
                }
            },
        },
        watch: {
            value (newValue, oldValue) {
                if (newValue !== this.localValue) {
                    this.localValue = newValue
                }
            },
            async roles (newValue, oldValue) {
                await this.loadUsers(newValue, this.include_searching_user, this.exclude_group_filter, this.group_ids)
            },
            async include_searching_user (newValue, oldValue) {
                await this.loadUsers(this.roles, newValue, this.exclude_group_filter, this.group_ids)
            },
            async exclude_group_filter (newValue, oldValue) {
                await this.loadUsers(this.roles, this.include_searching_user, newValue, this.group_ids)
            },
            async group_ids (newValue, oldValue) {
                await this.loadUsers(this.roles, this.include_searching_user, this.exclude_group_filter, newValue)
            },
        }
    }
</script>
