<template>

    <div class="d-flex">
        <v-autocomplete
            light dense flat outlined solo
            hide-details
            v-model="selectedProvider"
            :items="items"
            :loading="!getloadedProviders"
            item-text="lastname"
            item-value="id"
            label="Select Provider"
            :rules="rules"
            clearable
            :filter="displayItem"
            :auto-select-first="false"
            :key="`refresh_${refresh}`"
        >
            <template v-slot:item="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                        {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Specialty:</span> <span class="fw-400">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>
                        <v-divider />
                        </v-col>
                    </v-row>
                </v-container>

            </template>
            <template v-slot:selection="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                        {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Specialty:</span> <span class="fw-400">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>
                        <v-divider />
                        </v-col>
                    </v-row>
                </v-container>

            </template>

        </v-autocomplete>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'emrProviderSearch',
        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },            
            excludedProviders: {
                type: Array,
                default: () => []
            },            
            initialValue: null,
            clearOnSelect: {
                type: Boolean,
                default: false
            },
            forceClear: {
                type: String,
                default: ''
            },
        },
        data: () => ({
            selectedProvider: '',
            refresh: ''
        }),
        created() {

        },
        async mounted() {

            this.loadProviders()
            this.selectedProvider = null
        },
        watch: {
            selectedProvider (newValue, oldValue) {
                if (newValue) {
                    this.$emit('providerSelected', newValue)
                    if (this.clearOnSelect) {
                        this.selectedProvider = null
                    }
                }
            },
            forceClear (newValue, oldValue) {
                this.$nextTick(() => {
                    this.selectedProvider = null
                    this.refresh = newValue
                })
            }
        },
        methods: {
            ...mapActions('emr', ['loadProviders']),

            displayItem(item, queryText, itemText) {
                if (item.header) {
                    let groupTotals = this.groupTotals

                    return groupTotals[item.id]
                } else {
                    return this.matchedFilter(item, queryText)
                }
            },
            matchedFilter (item, queryText) {      
                      
                let qt = queryText.toLowerCase()
                console.log('qt', qt)

                let found = false
                found = item.searchLastName.indexOf(qt) >= 0
                if (found) {
                    console.log('found1', item.searchLastName)
                    return true
                }
                found = item.searchFirstName.indexOf(qt) >= 0
                if (found) {
                    console.log('found2', item.searchFirstName)
                    return true
                }
                found = item.searchSpecialty.indexOf(qt) >= 0
                if (found) {
                    console.log('found3', item.searchSpecialty)
                    return true
                }
                found = item.searchCredentials.indexOf(qt) >= 0
                if (found) {
                    console.log('found4', item.searchCredentials)
                    return true
                }

                return false
            },
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getloadedProviders']
            ),
            items () {
                let arr = []

                this.getProviders.map(p => {
                    if (!this.excludedProviderMap[p.id]) {
                        p.searchLastName = p.lastname ? p.lastname.toLowerCase() : ''
                        p.searchFirstName = p.firstname ? p.firstname.toLowerCase() : ''
                        p.searchSpecialty = p.specialty ? p.specialty.toLowerCase() : ''
                        p.searchCredentials = p.credentials ? p.credentials.toLowerCase() : ''
                        p.sort = p.lastname ? p.lastname : '' +
                                    p.firstname ? p.firstname : '' +
                                    p.practiceName ? p.practiceName : '' 

                        arr.push(p)
                    }
                })
                let sorted = arr.sort((a,b) => {
                    return a.sort.localeCompare(b.sort)
                })
                console.log('sorted', sorted)
                return sorted
            },
            excludedProviderMap () {
                let ret = {}
                if (this.excludedProviders && this.excludedProviders.length) {
                    this.excludedProviders.map(p => {
                        ret[p] = true
                    })
                }
                return ret
            }
        }
    }
</script>
