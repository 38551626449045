<template>

    <div>

        <!-- Title first row -->
        <section-title :title="`Profile Information`"/>

        <section-content>

            <template v-slot:section-content>

                <div>
                        <v-row>
                            <v-col cols="8" sm="5" md="5" >
                                <div class="label-title">First Name</div>
                                <v-text-field light solo dense flat outlined
                                              :rules="[rules.required]"
                                              v-model="userDataLocal.first_name" />
                            </v-col>
                            <v-col cols="4" sm="2" md="2" >
                                <div class="label-title">M.I.</div>
                                <v-text-field light solo dense flat outlined
                                              :maxlength="1"
                                              v-model="userDataLocal.middle_initial" />
                            </v-col>
                            <v-col cols="6" sm="5" md="5" >
                                <div class="label-title">Last Name</div>
                                <v-text-field light solo dense flat outlined
                                              :rules="[rules.required]"
                                              v-model="userDataLocal.last_name" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" >
                                <div class="label-title">Address</div>
                                <v-text-field light solo dense flat outlined
                                              :rules="[rules.required]"
                                              v-model="userDataLocal.street" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" >
                                <div class="label-title">Address Cont</div>
                                <v-text-field light solo dense flat outlined
                                              v-model="userDataLocal.street2" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" >
                                <div class="label-title">City</div>
                                <v-text-field light solo dense flat outlined
                                              :rules="[rules.required]"
                                              v-model="userDataLocal.city" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" >
                                <div class="label-title">State</div>
                                <v-text-field light solo dense flat outlined
                                              :rules="[rules.required]"
                                              v-model="userDataLocal.state" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" >
                                <div class="label-title">Zip</div>
                                <v-text-field light solo dense flat outlined
                                              :rules="[rules.required]"
                                              v-model="userDataLocal.zip_code" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" >
                                <div class="label-title">Require Explicit Message Confirmation</div>
                                <v-switch
                                    class="flex-grow-0"
                                    hide-details
                                    v-model="userDataLocal.explicit_message_confirmation"
                                    :disabled="userDataLocal.explicit_message_confirmation_can_override ? false : true"
                                ></v-switch>
                            </v-col>                            
                        </v-row>
                    </div>

            </template>

        </section-content>

        <!-- Title first row -->
        <section-title :title="`Contact Information`"/>

        <section-content>

            <template v-slot:section-content>

                <v-row>
                    <v-col cols="6" sm="6" md="4" >
                        <div class="label-title">Email</div>
                        <v-text-field light solo dense flat outlined
                                      :rules="[rules.required]"
                                      v-model="userDataLocal.email" />
                    </v-col>
                    <v-col cols="6" sm="6" md="4">
                        <div class="label-title" style="width:100%;" >
                            <span>Mobile Number</span>
                            <v-tooltip
                                top
                                v-model="showMobileHelp"
                                v-if="legacyRoleMap['nurse']"
                                >
                                <template v-slot:activator="{ on, attrs }">

                                    <v-icon :size="16"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="float-right pr-3"
                                        @click="showMobileHelp = !showMobileHelp"
                                    >
                                        info
                                    </v-icon>
                                </template>
                                <span>Once the mobile number is saved, you can verify it by clicking the icon and entering in the code that was texted to you.  Once your number is verified, when you are are online you will receive a text when a message that you sent was replied to.</span>
                            </v-tooltip>

                        </div>
                        <v-text-field light solo dense flat outlined
                                      v-model="userDataLocal.mobile_number" 
                                      class="pb-0 mb-0"
                        >
                            <template
                                v-slot:append-outer
                                v-if="originalValues.mobile_number &&  originalValues.mobile_number == userDataLocal.mobile_number"
                            >
                                <v-icon
                                    color="green"
                                    v-if="userDataLocal.mobile_number_verified"
                                >
                                    mobile_friendly
                                </v-icon>
                                <v-btn
                                icon
                                v-else
                                @click.stop=""
                                >
                                    <v-icon
                                        color="red"
                                        @click.stop="showMobileVerify"
                                    >
                                        app_blocking
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-form 
                            ref="mobile_verification"> 
                            <v-text-field light solo dense flat outlined
                                        v-model="mobile_verification_code" 
                                        label="Enter the verification code"
                                        :rules="[verify_rule]"
                                        v-if="verifying"
                            >
                                <template v-slot:append-outer>
                                    <v-btn
                                        icon
                                        @click.stop="finishVerification">
                                        <v-icon
                                            color="green"
                                        >
                                            check
                                        </v-icon>
                                    </v-btn>
                                    <v-btn 
                                        icon
                                        @click.stop="verifying = false">
                                        <v-icon
                                            color="red"
                                        >
                                            cancel
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-form>
                    </v-col>
                </v-row>

            </template>

        </section-content>

        <section-title :title="`Chat`"/>

        <section-content>

            <template v-slot:section-content>

                <v-container fluid
                    class="pa-0 ma-0"
                >
                    <v-row
                        class="pa-0 ma-0"                    
                    >
                        <v-col
                            cols="12"
                            class="pa-0 ma-0"
                        >

                            <v-switch  
                                color="ycmd-blue"
                                v-model="userDataLocal.enable_chat_notifications"
                                class="pa-0 ma-0"
                            >
                                <template v-slot:label>
                                    <div class="label-title">Enable Notifications for all Chats</div>
                                </template> 
                            </v-switch>   
                        </v-col>
                    </v-row>    

                    <v-row
                        class="pa-0 ma-0"                    
                    >
                        <v-col
                            cols="12"
                            class="pa-0 ma-0"
                        >
                            <v-checkbox
                                v-model="userDataLocal.out_of_office_ooo"
                                class="mt-0 pt-0"
                            >
                                <template v-slot:label>
                                    <div class="label-title pl-4">Out of Office</div>
                                </template>                            
                            
                            </v-checkbox>
                            <v-textarea
                                light
                                solo
                                dense
                                flat outlined
                                rows="3"
                                v-model="userDataLocal.out_of_office_message"
                                :disabled="!userDataLocal.out_of_office_ooo"
                                class="pa-0 ma-0"
                                :rules="startDateRules"
                                label="Out of Office Message"
                            />
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col
                            cols="3"
                            sm="6"
                            md="4"
                            class="pa-0 ma-0"                            
                        >
                            <div class="label-title pa-0 ma-0">Out of Office Starts on</div>
                            <date-picker
                                v-model="userDataLocal.out_of_office_startDate"
                                :includeCustomIcon="false"
                                :key="`drps_${refreshDates}`"
                                :disabled="!userDataLocal.out_of_office_ooo"
                                :rules="startDateRules"
                            />

                        </v-col>
                        <v-col
                            cols="3"
                            sm="6"
                            md="4"
                            class="pa-0 ma-0"
                        >
                            <div class="label-title" style="visibility: hidden">Time</div>                        
                            <time-picker
                                v-model="userDataLocal.out_of_office_startTime"
                                label=""
                                class="pt-4 pl-5"
                                :disabled="!userDataLocal.out_of_office_ooo"
                            />

                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"                    
                    >
                        <v-col
                            cols="3"
                            sm="6"
                            md="4"
                            class="pa-0 ma-0"
                            >
                            <div class="label-title">Out of Office Ends on</div>
                            <date-picker
                                v-model="userDataLocal.out_of_office_endDate"
                                :includeCustomIcon="false"
                                :key="`drpe_${refreshDates}`"
                                :disabled="!userDataLocal.out_of_office_ooo"
                                :rules="endDateRules"
                            />

                        </v-col>
                        <v-col
                            cols="3"
                            sm="6"
                            md="4"
                            class="pa-0 ma-0"
                        >
                            <div class="label-title" style="visibility: hidden">Time</div>                        
                            <time-picker
                                v-model="userDataLocal.out_of_office_endTime"
                                label=""
                                class="pt-4 pl-5"
                                :disabled="!userDataLocal.out_of_office_ooo"
                            />

                        </v-col>
                    </v-row>                
                </v-container>

            </template>

        </section-content>


        <!-- Title first row -->
        <section-title :title="`Browser Notifications`"/>

        <section-content>

            <template v-slot:section-content>

                <v-row>
                    <v-col class="shrink" >
                        <div class="label-title text-no-wrap">Notification Status</div>

                        <v-btn
                            color="ycmd-green"
                            class="ma-0 white--text"
                            @click="toggleNotifications"
                            v-if="notificationPermissionStatus === 'granted'"
                        >
                            <v-icon
                                left
                                dark
                            >
                                check_circle
                            </v-icon>
                            Active   
                        </v-btn>
                        <v-btn
                            color="error"
                            class="ma-0 "
                            @click="toggleNotifications"
                            v-else
                        >
                            <v-icon
                                left
                                dark
                            >
                                check_circle
                            </v-icon>
                            Inactive
                        </v-btn>
                    </v-col>
                    <v-col class="shrink" >
                        <div class="label-title text-no-wrap">Test Notification</div>
                        <v-btn
                            color="ycmd-medium-gray"
                            class="white--text"
                            @click="testNotifications"
                            v-if="notificationPermissionStatus === 'granted'"
                        >
                            <v-icon
                                left
                                dark
                            >
                                send
                            </v-icon>
                            Send
                        </v-btn>
                    </v-col>
                </v-row>

            </template>

        </section-content>


    </div>

</template>

<script>

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';
    import MOBILE_VERIFY from "@/graphql/mutations/phoenix/ycmdMobileNumberVerify";
    import {mapActions, mapState, mapGetters} from "vuex";
    import { askNotificationPermission2, getNotificationPermissionStatus, displayNotification } from '@/methods/notificationSystem';
    import DatePicker from '@/components/datetime/date-picker';
    import TimePicker from '@/components/datetime/timepicker';

    export default {
        name: 'editProfileEditInfo',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent,
            'date-picker': DatePicker,
            'time-picker': TimePicker,

        },
        apollo: {
            $client: 'phoenixClient'
        },
        props: {
            userData: Object,
            originalValues: Object,
            refreshDates: Number
        },
        data() {
            return {
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v && v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    },
                    email: email => {
                      const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(email) || 'Invalid email';
                    },
                    passwordMatch: password => {
                      return this.passwordData.newPassword === this.passwordData.newPasswordConfirm || 'New passwords do not match';
                    },
                    validDateRange: value => {
                        if (!value) {
                            return true
                        }
                        if (!this.userDataLocal.out_of_office_startDate) {
                            return 'Start date must be less than End date'
                        }

                        return this.userDataLocal.out_of_office_startDate < value || 'Start date must be less than End date';

                    }
                },
                verifying: false,
                verifyingError: '',
                showMobileHelp: false,
                notificationPermissionStatus: 'default'
            }
        },
        created() {},
        mounted() {
            console.log('userDataLocal: ', this.userDataLocal);

            this.notificationPermissionStatus = getNotificationPermissionStatus()
            console.log('notificationPermissionStatus: ', this.notificationPermissionStatus);

        },
        activated() {},
        watch: {

        },
        methods: {
            async testNotifications () {
                const title = 'YoucallMd Group Portal Test Notification';
                const body = `This is a test notification`;

                displayNotification(title, body);
            },
            async toggleNotifications () {

                let permission = await askNotificationPermission2()
                console.log('permission', permission)
                switch (permission.status) {
                    case 'alreadyGranted':
                        //do nothing
                        break
                    case 'alreadyDenied':
                        this.$toasted.error(`Notifications have already been denied.  You must change this in your browser settings and try again`);

                        break
                    case 'userGranted':
                        this.notificationPermissionStatus = 'granted'
                        this.$toasted.success(`Notifications are active`);
                        break
                    case 'userDenied':
                        this.$toasted.error(`You denied notifications.  If you change your mind, you must change this in your browser settings and try again`);
                        break
                    case 'userDenied':
                        this.$toasted.error(`You denied notifications.  If you change your mind, you must change this in your browser settings and try again`);
                        break
                    case 'userWaiting':
                        this.$toasted.error(`Waiting for acceptance of notifications.  You must click allow on your browser's notification dialog`);
                        break
                }

            },
            verify_rule () {
                if (this.verifyingError) {
                    return this.verifyingError
                }
                if (!this.mobile_verification_code) {
                    return 'Required'
                }
                return ''
            },
            async showMobileVerify () {
                this.verifyingError = null
                this.mobile_verification_code = ''
                if (this.verifying) {
                    this.verifying = false
                } else {
                    this.verifying = true
                    let response = await this.$apollo.mutate({
                        client: 'phoenixClient',
                        mutation: MOBILE_VERIFY,
                        variables: {
                            send_code: true,
                            verify_code: null
                        }
                    });
                }
            },
            async finishVerification () { 
                this.verifyingError = ''
                this.$refs.mobile_verification.validate()             
                if (this.mobile_verification_code) {
                    try {
                        let response = await this.$apollo.mutate({
                            client: 'phoenixClient',
                            mutation: MOBILE_VERIFY,
                            variables: {
                                send_code: false,
                                verify_code: this.mobile_verification_code
                            },
                            errorPolicy: 'all'
                        });
                        console.log('finish response', response)
                        if (response.errors && response.errors.length) {
                            this.verifyingError = response.errors[0].message
                            this.$refs.mobile_verification.validate()
                        } else {
                            console.log('response.data', response.data)
                            if (response && response.data && response.data.ycmdMobileNumberVerify && response.data.ycmdMobileNumberVerify.status == "success") {
                                this.userDataLocal.mobile_number_verified = true
                                this.verifying = false
                            }

                        }
                    } catch (err) {
                        console.log('verification error', err)
                    }

                }
            }
        },
        computed: {
            userDataLocal: {
                get: function() {
                    return this.userData;
                },
                set: function(newValue) {
                    this.$emit('update:userData', newValue)
                }
            },
            legacyRoleMap () {
                let map = {}
                let roles = this.getLegacyRoles
                console.log('roles', roles)
                if (roles && roles.length) {
                    roles.map(r => {
                        map[r] = true
                    })
                }
                return map
            },
            startDateRules () {
                if (this.userDataLocal.out_of_office_ooo) {
                    return [this.rules.required]
                }
                return []
            },
            endDateRules () {
                if (this.userDataLocal.out_of_office_ooo) {
                    return [this.rules.required, this.rules.validDateRange]
                }
                return []
            },
            ...mapGetters('profile', ['getLegacyRoles'])
        }
    }

</script>

<style scoped>

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
