<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                      <div class="pr-1 pa-2">

                          <div>

                              <v-text-field
                                      solo light dense flat outlined
                                      class="small-input"
                                      style="max-width: 400px;"
                                      v-model="startDate"
                                      label="Start Date"
                                      @click.native="() => { dialog = true; selectedModal = 'start' }"
                                      background-color="white" />

                              <v-text-field
                                      solo light dense flat outlined
                                      class="small-input"
                                      style="max-width: 400px;"
                                      v-model="endDate"
                                      label="End Date"
                                      @click.native="() => { dialog = true; selectedModal = 'end' }"
                                      background-color="white" />

                              <v-autocomplete
                                    solo light dense flat outlined
                                    v-model="selectedGroup"
                                    :disabled="!allGroups.length"
                                    :items="allGroups"
                                    :loading="isLoadingGroupsSearch"
                                    label="Select Group"
                                    item-value="id"
                                    item-text="name"
                                    style="max-width: 400px;"
                                    clearable
                                    return-object
                              ></v-autocomplete>

                              <v-autocomplete
                                      v-if="selectedGroup"
                                      :disabled="Boolean(!podsSearched.length)"
                                      solo light dense flat outlined
                                      v-model="selectedPod"
                                      :items="podsSearched"
                                      :loading="isLoadingPodSearch"
                                      item-value="id"
                                      item-text="name"
                                      color="white"
                                      label="Select Pod"
                                      style="max-width: 400px;"
                                      clearable
                                      return-object/>

                              <v-autocomplete
                                      v-if="selectedPod"
                                      :disabled="!templateSearched.length"
                                      solo light dense flat outlined
                                      v-model="selectedTemplate"
                                      :items="templateSearched"
                                      :loading="isLoadingPodSearch"
                                      item-value="id"
                                      item-text="name"
                                      color="white"
                                      label="Select Template"
                                      style="max-width: 400px;"
                                      clearable
                                      return-object/>

                                <div v-if="selectedPod" class="d-flex">
                                  <v-autocomplete
                                      light solo dense flat outlined
                                      v-model="selectedProviders"
                                      :disabled="!searchedProviders.length"
                                      :items="searchedProviders"
                                      :loading="isLoadingPodSearch"
                                      item-value="user_id"
                                      item-text="name"
                                      label="Excluded Provider List (Optional)"
                                      class=""
                                      style="max-width: 400px;"
                                      multiple
                                      clearable
                                      return-object/>
                                      <v-btn depressed dark class="ycmd-blue ml-2" style="width: 100px; margin-top: 2px;"
                                             @click="selectAllProviders">Select All
                                      </v-btn>
                                </div>

                                <v-btn depressed dark class="ycmd-blue" style="width: 180px;" @click="autoInjectShifts">Auto Inject Shifts</v-btn>

                                <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                                <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </i-card>

        <v-dialog v-model="dialog" max-width="330">

          <v-date-picker :reactive="true" v-model="picker"></v-date-picker>

        </v-dialog>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import AUTO_INJECT_SHIFTS from '../../../../graphql/mutations/phxAutoInjectShifts';
    import SEARCH_PODS from '../../../../graphql/queries/phxPods';
    import SEARCH_GROUPS from '../../../../graphql/queries/phxGroups';
    import SEARCH_TEMPLATE from '../../../../graphql/queries/phxShifts';

    export default {
        name: 'providerLink',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                picker: null,
                dialog: false,
                selectedModal: null,
                isLoading: false,
                errorMsg: '',
                successMsg: '',

                startDate: '',
                endDate: '',
                templateID: '',

                searchPods: null,
                selectedPod: null,
                podsSearched: [],
                isLoadingPodSearch: false,

                allGroups: [],

                selectedGroup: null,
                groupsSearched: [],
                isLoadingGroupsSearch: false,

                selectedTemplate: null,
                templateSearched: [],
                isLoadingTemplateSearch: false,

                selectedProviders: [],
                searchedProviders: [],
            }
        },
        activated() {

        },
        mounted() {
          this.getGroups(null);
        },
        methods: {
            selectAllProviders() {
              this.selectedProviders = this.searchedProviders;
            },
            async getGroups(val) {
                this.isLoadingGroupsSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_GROUPS,
                        variables: {
                            group_id: this.selectedGroup ? this.selectedGroup.id : null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingGroupsSearch = false;
                    console.log('phxGroups response: ', response);
                    if (response.data.phxGroups) {
                      if (!val) {
                        // console.log('setting all groups: ', response.data.phxGroups);
                        this.allGroups = response.data.phxGroups;
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxGroups data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getPods(val) {
                this.isLoadingPodSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_PODS,
                        variables: {
                            group_id: val
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingPodSearch = false;
                    console.log('pods response: ', response.data);
                    if (response.data.phxPods) {
                      this.podsSearched = response.data.phxPods;
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxPods data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingPodSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getShifts(val) {
                this.isLoadingShiftSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_TEMPLATE,
                        variables: {
                            pod_id: val,
                            rounding_or_rotation: true
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingShiftSearch = false;
                    console.log('shifts response: ', response);
                    if (response.data.phxShifts) {
                      this.templateSearched = response.data.phxShifts;
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxShifts data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingShiftSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async autoInjectShifts() {
                this.errorMsg = '';
                this.successMsg = '';
                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: AUTO_INJECT_SHIFTS,
                        variables: {
                          start_date_inclusive: this.startDate,
                          end_date_inclusive: this.endDate,
                          pod_id: this.selectedPod ? this.selectedPod.id : null,
                          template_id: this.selectedTemplate ? this.selectedTemplate.id : null,
                          filter_list: this.selectedProviders ? this.selectedProviders.map((val) => val.provider_id) : null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('response: ', response);
                    if (response.data && response.data.phxAutoInjectShifts) {
                      this.successMsg = 'Successfully injected shifts';
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message) {
                        this.errorMsg = response.errors[0].message;
                        console.log('setting error message');
                      } else {
                        this.errorMsg = "Error 2E. Invalid response.";
                      }
                    } else {
                      // data did not come back
                      console.log('phxAutoInjectShifts data did not come back: ', response);
                      this.errorMsg = 'Server error';
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
          picker(val) {
            // console.log('val: ', val);
            switch(this.selectedModal) {
              case 'start':
                this.startDate = val;
              break;
              case 'end':
                this.endDate = val;
              break;
            }
            this.selectedModal = null;
            this.picker = null;
            this.dialog = false;
          },
          searchPods(val) {

              if (!val) return;

              this.getPods(val);

              // Items have already been requested
              if (this.isLoadingPodSearch) return;

          },
          selectedGroup(val) {

            this.selectedPod = null;
            this.podsSearched = [];

            this.selectedTemplate = null;
            this.templateSearched = [];

            this.selectedProviders = [];
            this.searchedProviders = [];

            if (!val || !val.id) return;

            this.getPods(val.id);

          },
          selectedPod(val) {

            this.selectedTemplate = null;
            this.templateSearched = [];

            this.selectedProviders = [];
            this.searchedProviders = [];

            if (!val || !val.id) return;

            if (val.providers && val.providers.length) {
              this.searchedProviders = val.providers;
            }

            this.getShifts(val.id);

          }

        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
