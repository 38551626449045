<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">
                        <div class="pr-1 pa-2">
                            <!-- <v-text-field
                                    solo light dense flat outlined
                                    class="small-input"
                                    style="max-width: 400px;"
                                    v-model="searchedFacility"
                                    label="Search for facility"
                                    background-color="white"
                                    v-on:keyup.enter="() => searchFacility()" /> -->
                            <div class="d-flex full-height">
                                <v-autocomplete
                                        solo light dense flat outlined
                                        v-model="selectedFacility"
                                        :items="facilitiesSearched"
                                        :loading="isLoadingSearch"
                                        :search-input.sync="searchFacility"
                                        item-value="id"
                                        item-text="name"
                                        color="white"
                                        label="Search Facility"
                                        style="max-width: 400px;"
                                        return-object/>
                                <v-btn depressed dark class="ycmd-blue ml-2 mt-1" style="width: 150px;"
                                       @click="deleteFacility">Delete Facility
                                </v-btn>
                            </div>

                            <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                            <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                        </div>
                    </div>

                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import {format, parseISO} from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import {refreshHandler} from '@/graphql/handler/refreshHandler';
    import FACILITY_SEARCH from '@/graphql/queries/phxFacilitySearch';
    import FACILITY_DELETE from '@/graphql/mutations/phxFacilityDelete';

    export default {
        name: 'deleteFacility',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: {left: false, right: false, top: false, bottom: false}
                },
                isLoading: false,
                isLoadingSearch: false,
                facilitiesSearched: [],
                errorMsg: '',
                successMsg: '',
                selectedFacility: null,
                searchFacility: null,
                input: ''
            }
        },
        activated() {

        },
        mounted() {

        },
        methods: {
            async deleteFacility() {
                this.errorMsg = '';
                this.successMsg = '';

                if (!this.selectedFacility) {
                    this.errorMsg = 'Please re-select a facility'
                    return;
                }
                try {
                    const response = await this.$apollo.mutate({
                        mutation: FACILITY_DELETE,
                        variables: {
                            facility_id: this.selectedFacility.id
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('response: ', response);
                    if (response.data.phxFacilityDelete) {
                        this.successMsg = `Deleted facility ${this.selectedFacility.name}`
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxFacilityDelete data did not come back: ', response);
                        this.errorMsg = 'Server error';
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async getFacilities(val) {

                if (val.length >= 3) {
                    //
                    // Check token expiration
                    this.isLoadingSearch = true;
                    try {
                        const response = await this.$apollo.mutate({
                            mutation: FACILITY_SEARCH,
                            variables: {
                                search: val
                            },
                            fetchPolicy: 'no-cache',
                            errorPolicy: 'all'
                        });
                        this.isLoadingSearch = false;
                        // console.log('response: ', response);
                        if (response.data.phxFacilitySearch) {
                          this.facilitiesSearched = response.data.phxFacilitySearch;
                        } else if (response.errors && response.errors.length > 0) {
                            if (response.errors[0].message) {
                                this.errorMsg = response.errors[0].message;
                            } else {
                                this.errorMsg = "Error 2E. Invalid response.";
                            }
                        } else {
                            // data did not come back
                            console.log('phxFacilitySearch data did not come back: ', response);
                        }
                    } catch (e) {
                        this.isLoadingSearch = false;
                        defaultErrorHandler(e);
                    }

                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {

                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
            searchFacility(val) {

                if (!val) return;

                this.getFacilities(val);

                // Items have already been requested
                if (this.isLoadingSearch) return;

            },
            selectedFacility(val) {
              console.log('selected facility: ', val);
            }
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
