<template>

    <div v-if="item">
        <v-card
          outlined
        >
            <v-card-title class="d-flex justify-center">
                <v-icon
                    large
                    left
                    :color="'red'"
                >
                    local_hospital
                </v-icon>
                <span class="title font-weight-light">{{ item.facility_name || 'N/A' }}</span>
            </v-card-title>
            <!-- <v-card-subtitle class="d-flex justify-center pb-0">{{ item.specialty || 'No Specialty Provided' }}</v-card-subtitle> -->
        </v-card>

        <v-card
          class="mt-2"
          outlined
        >
          <div class="overline ma-3 ml-4">
              {{ item.type || 'N/A' }}
          </div>

          <v-card-actions class="ma-2 ml-0">
              <v-tooltip bottom
                 nudge-bottom="0"
                 nudge-right="0"
                 color="ycmd-black"
                 content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark rounded large
                         @click="toggleContactFavorite(item)"
                         v-on="on"
                         class=""
                         :color="`${item.is_favorite ? '#FFCC00' : 'grey'}`">
                      <v-icon :size="30">star</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.is_favorite ? 'Remove Favorite' : 'Favorite' }}</span>
              </v-tooltip>
          </v-card-actions>
        </v-card>

        <!-- units -->
        <v-card
          v-if="item.units"
          class="mt-2"
          outlined
        >

            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-2">
                        Units
                    </div>
                    <template v-for="(item, i) in item.units">
                        <v-list-item-title class="headline mb-1 mt-2">
                            {{ item.name || 'No Name' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ item.number ? formatPhoneNumber(item.number) : 'No Number' }}</v-list-item-subtitle>
                    </template>
                </v-list-item-content>
            </v-list-item>

        </v-card>

    </div>

</template>

<script>
    import { capitalizeFirstLetter } from '@/methods/utils';
    import {formatPhoneNumber} from '@/methods/globalMethods';

    export default {
        name: 'customItemDetails',
        props: {
            item: Object,
            toggleContactFavorite: Function
        },
        data: () => ({
            formatPhoneNumber,
            capitalizeFirstLetter
        }),
        mounted() {},
        methods: {

        },
        computed: {

        }
    }

</script>

<style scoped>

</style>
