import phoenixClient from '@/graphql/clients/phoenix';
import GET_UNREAD_CHAT_COUNT from '@/graphql/queries/phoenix/ycmdChatPostUnreadCountGet';
import CLEAR_CHAT_ROOM_UNREAD from '@/graphql/mutations/phoenix/ycmdChatRoomClearUnread';
import dataClient from '@/graphql/clients/axios';
import {formatSimple} from '@/methods/formatters';
import { format, utcToZonedTime } from 'date-fns-tz';
// Initial state
const state = {
    chatBadgeCount: 0,
    currentRoom: {
        id: null,
        name: '',
        created_by_id: null,
        date_created: null,
        date_updated: null,
        last_post_id: null,
        last_post_time: null,
        last_post_text: null,
        last_post_type: null,
        unread_count: null,
        group_id: null,
        room_type: null,
        participants: [],
        meta: [],
        tags: [],
    },
    attachmentCache: {},
    currentRoomLastPost: {},
    currentRoomPosts: [],
    //roomPosts: [],
    subscriptionCounter: 0,
    rooms: [],
    roomsNextToken: null,
    currentRoomLoading: true,
    currentRoomPostsEarlierToken: null,
    badgeCountGlobal: 0,
    badgeCountRooms: [],
    chatOpened: false,
    currentRoomMain: {},
    currentRoomPostsMain: {},
    currentRoomBadgeCount: 0
};

const getters = {
    getChatOpened: state => state.chatOpened,
    chatBadgeCount: state => state.chatBadgeCount,
    getCurrentRoomId: state => state.currentRoom.id,
    getCurrentRoom: state => state.currentRoom,
    getCurrentRoomLastPost: state => state.currentRoomLastPost,
    getCurrentRoomName: state => { 
        let currentRoom = state.currentRoom

        switch (currentRoom.room_type) {
            case 'conversation':
                return 'Conversation'
            case 'topic':
                return `${currentRoom.name ? currentRoom.name : 'Unnamed room' }`
            default:
                return currentRoom.name
        }
    },
    getCurrentRoomLastPostTime: state => {
       
        let currentRoomLastPost = state.currentRoomLastPost

        if (currentRoomLastPost && currentRoomLastPost.date_created) {
            let time = new Date(currentRoomLastPost.date_created)

            return formatSimple(time, 'MMM dd, hh:ss')
        }
        return ''
    },
    getCurrentRoomLastPostText: state => {
       
        let currentRoomLastPost = state.currentRoomLastPost

        if (currentRoomLastPost && currentRoomLastPost.type) {
            switch (currentRoomLastPost.type) {
                case 'attachment':
                  return currentRoomLastPost.text ? `${currentRoomLastPost.text} - Attached a file`: 'Attached a file'
                case 'message':
                  return 'Attached a message'
                default:
                  return currentRoomLastPost.text
              }

        }
        return ''
    },
    getCurrentRoomLastPostAuthor: state => {
       
        let currentRoomLastPost = state.currentRoomLastPost

        if (currentRoomLastPost && currentRoomLastPost.type) {
            return `${currentRoomLastPost.first_name} ${currentRoomLastPost.last_name}`
        }
        return ''
    },
    getCurrentRoomParticipantList: state => {

        let currentRoom = state.currentRoom

        if (currentRoom && currentRoom.participants && currentRoom.participants.length) {
            return currentRoom.participants
        }
        return []
    },
    getCurrentRoomPosts: state => state.currentRoomPosts,
    getCurrentRoomLoading: state => state.currentRoomLoading,
    getCurrentRoomPostsEarlierToken: state => state.currentRoomPostsEarlierToken,

    getBadgeCountGlobal: state => state.badgeCountGlobal,
    getBadgeCountRooms: state => state.badgeCountRooms,
    getCachedAttachment: (state) => (id) => {
        if (state.attachmentCache && state.attachmentCache[id]) {
            return state.attachmentCache[id]
        }
        return null
    },
    getCurrentRoomMain: state => state.currentRoomMain,
    getCurrentRoomPostsMain: state => state.currentRoomPostsMain,
    getBadgeCountByRoomId: (state) => (id) => {

        if (state.badgeCountRooms && state.badgeCountRooms.length) {
            let badge = state.badgeCountRooms.find(r => {
                return r.id === id
            })
            if (badge) {
                return parseInt(badge.value)
            }
                                                      
        }
        return null
    },
    getCurrentRoomBadgeCount: state => state.currentRoomBadgeCount,
    getCurrentRoomProvider: (state) => (user_id) => {
        if (state.currentRoom && state.currentRoom.participants.length) {
            let provider = state.currentRoom.participants.find(p => {
                return p.user_id === user_id
            })
            return provider                           
        }
        return null
    },
    formatTempPost: (state) => (postd) => {


    }
};

const actions = {

    loadRooms: async ({commit, getters, dispatch}, loadMore) => {

        let params = {
        }
        if (loadMore) {
            params.nextToken = getters.nextToken
        }

        let result = await dataClient('ycmdChatRoomGet', params)

        if (result && result.nextToken) {
            commit('setRoomsNextToken',result.nextToken)
        }
        console.log('room result', result)

        if (result && result.rooms && result.rooms.length ) {
            if (!getters.getCurrentRoom || !getters.getCurrentRoom.id) {
                dispatch('loadCurrentRoom',result.rooms[0])            
            }
        } else {
            commit('setCurrentRoomLoading', false)
        }
    },
    subscriptionReceived: ({commit}) => {
        commit('subscriptionReceived');
    },
    addRoomPost: ({commit}, post) => {
        commit('addRoomPost', post);
    },
    loadCurrentRoom: async ({commit, getters}, room) => {

        commit('setCurrentRoomLoading', true)

        let promises = []
        let detailIndex = -1
        if (room.last_post_id) {
            detailIndex = 0
            let params = {
                post_id: room.last_post_id,
                silent_read: true
            }
   
            promises.push(dataClient('ycmdChatPostDetailGet', params))    
        }
        
        let postParams = {
            room_id: room.id,
            silent_read: true,
            start: 'mostRecent'
        }
        promises.push(dataClient('ycmdChatPostGet', postParams))

        let allResults = await Promise.all(promises)

        let result
        let posts
        let postsResult

        if (detailIndex >= 0) {
            result = allResults[0] //await dataClient('ycmdChatPostDetailGet', params)
            postsResult = allResults[1]
            posts = allResults[1].posts.reverse()
        } else {
            posts = allResults[0].posts.reverse()
            if (posts.length) {
                result = posts[posts.length-1]
            }
            postsResult = allResults[0]
        }

        /*
        for (let i = allResults[1].posts.length - 1;i>=0;i--) {
            posts.push(allResults[1].posts[i])
        }
        */
        commit('setCurrentRoomLastPost', result)

        commit('setCurrentRoom', room);

        commit('setCurrentRoomPosts', posts);
        /*
        allResults[1].posts.map(p => {
            console.log(`p ${p.text}`, p.date_created)
        })
        */

        commit('setCurrentRoomLoading', false)

        commit('setCurrentRoomPostsEarlierToken', postsResult.prevToken)

        let currentRoomBadgeCount = getters.getBadgeCountByRoomId(room.id)
        commit('setCurrentRoomBadgeCount', currentRoomBadgeCount);  


    },
    clearCurrentRoom: ({commit}) => {

        commit('setCurrentRoomLastPost', {})

        commit('setCurrentRoom', {
            id: null,
            name: '',
            created_by_id: null,
            date_created: null,
            date_updated: null,
            last_post_id: null,
            last_post_time: null,
            last_post_text: null,
            last_post_type: null,
            unread_count: null,
            group_id: null,
            room_type: null,
            participants: [],
            meta: [],
            tags: [],
        });

        commit('setCurrentRoomPosts', []);

        commit('setCurrentRoomLoading', false)

        commit('setCurrentRoomPostsEarlierToken', null)

    },

    loadCurrentRoomMorePosts: async ({commit, getters} ) => {

        let currentRoom = getters.getCurrentRoom
        let prevToken = getters.getCurrentRoomPostsEarlierToken

        if (currentRoom && currentRoom.id) {
            let postParams = {
                room_id: currentRoom.id,
                prevToken: prevToken
            }
            let results = await dataClient('ycmdChatPostGet', postParams)
            let currentPosts = getters.getCurrentRoomPosts
            let newPosts = results.posts.reverse().concat(currentPosts)
            commit('setCurrentRoomPosts', newPosts);
    
            commit('setCurrentRoomPostsEarlierToken', results.prevToken)            
        }
        

    },
    clearUnreadCount: async({commit, dispatch}, roomId) => {
        console.log('Clearing unread count');
        try {
            await phoenixClient.mutate({
              client: 'phoenixClient',
              mutation: CLEAR_CHAT_ROOM_UNREAD,
              variables: {
                room_id: roomId
              }
            });
        } catch (e) {
            console.log('WARNING Error clearing badge count (Caught): ', e);
        }
    },
    getBadgeCount: async ({commit}, room) => {
        try {
            //todo switch to axios
            const response = await phoenixClient.query({
                query: GET_UNREAD_CHAT_COUNT,
                variables: {
                },
                client: 'phoenixClient',
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });

            const { data } = response;
            if (data && data.ycmdChatPostUnreadCountGet) {
                // get ycmdChatPostUnreadCountGet.post_count
                commit('setBadgeCount', data.ycmdChatPostUnreadCountGet.post_count);
            }
            // console.log('chat count response from actions: ', response);
        }
        catch (e) {
            console.log('WARNING Error getting badge count (Caught): ', e);
        }
    },
    setBadgeCount: ({commit}, value) => {
        commit('setBadgeCount', value)
    },
    postToCurrentRoom: async ({commit, getters, rootGetters}, params) => {

        if (getters.getCurrentRoom && getters.getCurrentRoom.id) {
            params.room_id = getters.getCurrentRoom.id

            let now = new Date()
            let tempPost = {
                attachment_id: params.attachment_id,
                text: params.text,                
                date_created: now.toISOString(),
                first_name: rootGetters['profile/getFirstName'],
                last_name: rootGetters['profile/getLastName'],
                id: `temp_${now.getTime()}`,
                type: "post",
                user_id: rootGetters['profile/getUserID'],
            }

            commit('addPostToCurrentRoom', tempPost);

            setTimeout(async () => {
                let result = await dataClient('ycmdChatPostCreate', params)
                console.log('post result', result)
                if (result && result.error) {
                    commit('updatePostInCurrentRoom', {tempPost, update: {error: true}});                    
                }
                else if (result && result.id) {
                    commit('updatePostInCurrentRoom', {tempPost, post: result});                    
                }
            }, 1)

            return tempPost

            //let response = await dataClient('ycmdChatPostCreate', params)
            //commit('addPostToCurrentRoom', response);
            
            //return response
        }

    },
    repostToCurrentRoom: async ({commit, getters, rootGetters}, payload) => {

        if (getters.getCurrentRoom && getters.getCurrentRoom.id) {

            let params = {                
                room_id: getters.getCurrentRoom.id,
                text: payload.tempPost.text,
                attachment_id: payload.tempPost.attachmentId,
                resend_time: payload.tempPost.date_created
            }

            let response = await dataClient('ycmdChatPostCreate', params)

            if (response && response.id) {
                commit('updatePostInCurrentRoom', {tempPost: payload.tempPost, post: response});       

                return response    
            }
            return payload.tempPost
        }

    },    
    createRoom: async ({commit, getters}, params) => {

        if (!params.room_type) {
            params.room_type = 'conversation'
        }

        let response = await dataClient('ycmdChatRoomCreate', params)

        return response

    },
};

const mutations = {
    resetState(state) {
        state.chatBadgeCount = 0
        state.currentRoom = {
            id: null,
            name: '',
            created_by_id: null,
            date_created: null,
            date_updated: null,
            last_post_id: null,
            last_post_time: null,
            last_post_text: null,
            last_post_type: null,
            unread_count: null,
            group_id: null,
            room_type: null,
            participants: [],
            meta: [],
            tags: [],
        }
        state.attachmentCache = {}
        state.currentRoomLastPost = {}
        state.currentRoomPosts = []

        state.subscriptionCounter = 0
        state.rooms = []
        state.roomsNextToken = null
        state.currentRoomLoading = true
        state.currentRoomPostsEarlierToken = null
        state.badgeCountGlobal = 0
        state.badgeCountRooms = []
        state.chatOpened = false
        state.currentRoomMain = {}
        state.currentRoomPostsMain = {}
        state.currentRoomBadgeCount = 0
    },
    addRoomList(state, post) {
        // we format the post because this is just how it was set up originally
        let participantString = '';
        let concat = '';

        post.participants.map(p => {
            // Don't display logged in user in the info list
            if (p.user_id) {
                participantString += concat
                participantString += `${p.first_name} ${p.last_name}`
                concat=', '
            }
        });
        const formattedPost = {
            id: post.id,
            type: 'room_created',
            title: post.name,
            participants: post.participants,
            participantString: participantString,
            roomLastPostTime: post.last_post_time || post.date_created,
            roomLastPostText: post.last_post_text,
            unreadCount: 1,
            creator: post.created_by_id
        }
        state.roomList = [formattedPost, ...state.roomList];
        console.log('CHECK THIS 2: ', state.roomList);
    },
    subscriptionReceived(state) {
        state.subscriptionCounter++;
    },
    setCurrentRoom(state, room) {
        state.currentRoom = room;
    },
    setCurrentRoomMain(state, room) {
        state.currentRoomMain = room;
    },
    setCurrentRoomPostsMain(state, posts) {
        state.currentRoomPostsMain = posts
    },
    setCurrentRoomLoading(state, value) {
        state.currentRoomLoading = value;
    },
    setChatOpened(state, value) {
        state.chatOpened = value;
    },
    setCurrentRoomPosts(state, posts) {
        let grouped_date = null;

        if(posts && posts.length) {
            
            posts.map((post, i) => {
                posts[i]["grouped_date"] = null //clear out old
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const localDate = utcToZonedTime(post.date_created, timeZone)

                const date_created = format(localDate, 'iiii MM/dd/yyyy', { timeZone });

                if(grouped_date === null || date_created !== grouped_date) {
                    posts[i]["grouped_date"] = grouped_date = date_created;
                }
            });
        }

        state.currentRoomPosts = posts;
    },
    addRoomPost(state, post) {
        state.roomPosts = state.roomPosts.concat(post);
    },
    setBadgeCount(state, value) {
        state.chatBadgeCount = value;
    },
    setRooms(state, value) {
        if (value.loadMore) {
            state.rooms.push(...value.rooms);
        } else {
            state.rooms = value.rooms
        }
    },
    setRoomsNextToken(state, value) {
        state.roomsNextToken = value;
    },
    setCurrentRoomLastPost(state, value) {
        state.currentRoomLastPost = value;
    },
    addPostToCurrentRoom(state, post) {
        let posts = state.currentRoomPosts
        if (posts && Array.isArray(posts)) {
            let found = posts.find(p => {
                return p.id === post.id
            })

            if (!found) {
                posts.push(post)
                this.commit('chat/setCurrentRoomPosts', posts);
                this.commit('chat/updateCurrentRoomLastPost', post);
                
            }
        } 
    },
    updatePostInCurrentRoom(state, payload) {

        let posts = state.currentRoomPosts
        if (posts && Array.isArray(posts)) {
            let index = posts.findIndex(p => {
                return p.id === payload.tempPost.id
            })

            let post = payload.post
            if (!post) {
                post = posts[index]
                for (let prop in payload.update) {
                    post[prop] = payload.update[prop]
                }
            }

            if (index >= 0) {
                posts.splice(index, 1, post)

                this.commit('chat/setCurrentRoomPosts', posts);

                if (index == posts.length - 1) {
                    //the last post.  
                    this.commit('chat/updateCurrentRoomLastPost', post);
                }
            }
        }
    },

    setCurrentRoomPostsEarlierToken(state, value) {
        state.currentRoomPostsEarlierToken = value;
    },    
    setBadgeCountGlobal(state, value) {
        state.badgeCountGlobal = value
    },
    setBadgeCountRooms (state, value) {
        state.badgeCountRooms = value
    },
    cacheAttachment (state, attachment) {
        state.attachmentCache[attachment.attachment_id] = attachment
    },
    setCurrentRoomName (state, room) {
        if (state.currentRoom.id === room.id) {
            state.currentRoom.name = room.name
            state.currentRoom.room_type = 'topic'
        }
    },
    setCurrentRoomBadgeCount(state, value) {
        state.currentRoomBadgeCount = value;
    },
    updateCurrentRoomLastPost (state, post) {
        console.log('post', post)
        state.currentRoomLastPost = post
        state.currentRoom.last_post_id = post.id
        state.currentRoom.last_post_time = post.date_created
        state.currentRoom.last_post_text = post.text
        state.currentRoom.last_post_type = post.type
    },         
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
