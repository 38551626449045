import gql from 'graphql-tag'

export default gql`query pcpAnalysts($sort_field: PCPAnalystSortField, $sort_direction: SortDirection, $page: Int, $limit: Int, $search: String, $suspended: Boolean) {
	pcpAnalysts(sort_field: $sort_field, sort_direction: $sort_direction, page: $page, limit: $limit, search: $search, suspended: $suspended) {
		analysts {
			id
			user_id
			first_name
			last_name
			deleted
			email
			source
			date_created
			date_updated
			roles
			facilities{
        id
        name
      }
      groups{
        id
        name
      }
			phone
		}
		total_count
  }
}`
