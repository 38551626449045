<template>

    <div class="d-flex fill-height ycmd-dark-blue-radial-gradient full-width pa-2 br-2">

      <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      :background-color="`#101622`"
      :color="`#637eb6`"
      :loader="`bars`">
      </loading>

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">OPT-IN</div>
                <v-spacer />
                <v-btn dark icon text @click="route('login', 'down')">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                <div class="d-flex flex-column flex-grow-1 full-width pa-3 br-2">

                    <v-form
                            ref="registerForm"
                            v-model="validForm"
                            :lazy-validation="false"
                            style="padding: 15px; height: 100%;">

                        <v-text-field
                                dark solo dense flat
                                v-model="firstName"
                                :rules="[rules.required]"
                                label="First Name"
                                background-color="ycmd-dark-blue"
                                @input="" />

                        <v-text-field
                                dark solo dense flat
                                v-model="lastName"
                                :rules="[rules.required]"
                                label="Last Name"
                                background-color="ycmd-dark-blue"
                                @input="" />

                        <v-text-field
                                dark solo dense flat
                                v-model="license"
                                :rules="[rules.required]"
                                label="License Number"
                                background-color="ycmd-dark-blue"
                                @input="" />

                        <v-layout row style="padding-left: 12px; padding-right: 12px;">

                            <v-text-field
                                    dark solo dense flat
                                    v-model="countryCode"
                                    style="max-width: 25px; margin-right: 5px;"
                                    background-color="ycmd-dark-blue"
                                    disabled />

                            <v-text-field
                                    dark solo dense flat
                                    v-model="mobileNumber"
                                    label="Mobile Number"
                                    :rules="[rules.required, rules.phone]"
                                    background-color="ycmd-dark-blue"
                                    @input="phoneInput()" />

                        </v-layout>

                        <v-text-field
                                dark solo dense flat
                                v-model="email"
                                :rules="[rules.required, rules.email]"
                                label="Email"
                                background-color="ycmd-dark-blue"
                                @input="" />

                    </v-form>
                </div>

                <div class="d-flex flex-column align-center">

                    <div class="full-width pa-1 pl-0 pr-0">
                        <v-btn dark block large depressed color="ycmd-light-blue" :class="`${ !validateCheck() ? 'disabled' : '' }`" @click="optin">Submit</v-btn>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog persistent v-model="dialog" max-width="330" style="">

          <v-card dark v-if="dialogType === 'success'" class="ycmd-dark-blue pt-2">
            <v-card-text class="fs-18" style="text-align: center;">
              Success! Your request to Opt-In to PCP Link has been received by a VHS analyst. You will receive an email to complete your account setup once verified. Please allow 1-2 business days to receive your YouCallMD verification email.
            </v-card-text>

            <v-card-actions>
              <v-btn text color="white" @click="() => {this.route('login', 'prev'); this.resetFields();}" style="width: 100%;">Return</v-btn>
            </v-card-actions>
          </v-card>

          <v-card dark v-if="dialogType === 'error'" class="ycmd-dark-blue pt-2">
            <v-card-text class="fs-18" style="text-align: center;">
              {{optInErrorMessage}}
            </v-card-text>

            <v-card-actions>
              <v-btn text color="white" @click="() => {this.dialog = false; this.dialogType = '';}" class="full-width">Return</v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>

    </div>

</template>

<script>

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import OPTIN_USER from '@/graphql/mutations/authenticateOptIn';

    export default {
        name: 'register',
        data: () => ({
            isLoading: false,
            dialog: false,
            dialogType: '',
            optInErrorMessage: '',
            email: '',
            license: '',
            firstName: '',
            lastName: '',
            countryCode: '+1',
            mobileNumber: '',
            parsedMobileNumber: '',
            validForm: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  return pattern.test(value) || 'Invalid e-mail';
                },
                phone: number => {
                  const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                  return pattern.test(number) || 'Invalid phone number';
                }
            }
        }),
        components: {
          Loading
        },
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            checkOptInErrors(err) {
              switch(err) {
                case 'EmailNotFound':
                  return 'Email does not match our records.'
                break;
                case 'ExecutionTimeout':
                  return 'Your request has timed out. Please try again.'
                break;
                case 'BadMobileNumber':
                  return 'Phone number does not match our records.'
                break;
                case 'BadReferenceId':
                  return 'Reference ID does not match our records.'
                break;
                default:
                  return false
              }
            },
            async optin() {
                if (!this.validateCheck()) return;
                // We are setting the accesstoken to null here because we don't want access token header to send for login
                localStorage.setItem("accessToken", null);
                
                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: OPTIN_USER,
                        variables: {
                          mobile_number: (this.countryCode.concat(this.mobileNumber)),
                          email: this.email,
                          license_number: this.license,
                          last_name: this.lastName,
                          first_name: this.firstName
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    
                    this.isLoading = false;
                    console.log('opt in response: ', response);
                    if (response.data.authenticateOptIn) {
                      this.dialogType = 'success';
                      this.dialog = true;
                    } else if (response.errors && response.errors.length > 0) {
                      if (response.errors[0].message && typeof response.errors[0].message === 'string') {
                        this.dialogType = 'error';
                        this.dialog = true;
                        this.optInErrorMessage = this.capitalizeFirstLetter(response.errors[0].message) || 'Error 1A. Try again later.';
                      } else {
                        this.optInErrorMessage = 'Error 1B. Err did not come back.';
                      }
                    } else {
                      // data did not come back
                      console.log('optIn data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.errorMessage = e.message;
                    console.log('CAUGHT: There was an error logging in *registerUser* : ', e);
                }
            },
            resetFields() {
              this.isLoading = false;
              this.dialog = false;
              this.dialogType = '';
              this.password = '';
              this.confirmPassword = '';
              this.showPassword = false;
              this.showConfirmPassword = false;
              this.optInErrorMessage = '';
              this.email = '';
              this.countryCode = '+1';
              this.mobileNumber = '';
              this.parsedMobileNumber = '';
              this.verificationCode = '';
              this.validForm = false;
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            phoneInput() {
              this.parsedMobileNumber = this.countryCode.concat(this.mobileNumber.replace(/\D/g, ''));
            },
            validateCheck() {
              if (this.validForm) {
                return true;
              }
              return false;
            }
        }
    }

</script>

<style scoped>

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #2D323D;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #202c45;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1c273e;
  }

</style>
