<template>

    <div>
        <!-- Title first row -->
        <section-title :title="`Change Password`"></section-title>

        <section-content>

            <template v-slot:section-content>

                <v-row class="full-width">
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Enable Desktop Notifications</div>
                        <div class="label-title fw-400 fs-10 ma-0">Browser Setting: {{getDesktopNotificationStatus()}}</div>
                        <div class="d-flex">
                          <v-switch
                              class="flex-grow-0"
                              hide-details
                              v-model="enableDesktopNotifications"
                              :label="`${enableDesktopNotifications ? 'Enabled' : 'Disabled'}`"
                          ></v-switch>
                        </div>
                    </v-col>
                </v-row>

            </template>

        </section-content>
    </div>

</template>

<script>

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import {mapActions, mapState} from "vuex";

    export default {
        name: 'editProfileSettings',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent
        },
        apollo: {
            $client: 'phoenixClient'
        },
        props: {

        },
        data() {
            return {
                enableDesktopNotifications: true
            }
        },
        created() {},
        mounted() {

        },
        activated() {},
        watch: {

        },
        methods: {
            getDesktopNotificationStatus() {
                return Notification.permission
            }
        },
        computed: {

        }
    }

</script>

<style scoped>

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
