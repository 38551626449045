import { render, staticRenderFns } from "./list-container.vue?vue&type=template&id=826e6ff6&scoped=true"
import script from "./list-container.vue?vue&type=script&lang=js"
export * from "./list-container.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "826e6ff6",
  null
  
)

export default component.exports