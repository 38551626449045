<template>

    <transition name="fade-in-transition-slow" mode="out-in">

        <div v-if="showHeader" class="d-flex flex-grow-1 pb-1">

            <div class="d-flex align-center flex-grow-1">
                <slot />
            </div>

            <div class="d-flex align-center ml-2 mr-3">

                <slot name="actions" />

            </div>

        </div>

    </transition>

</template>

<script>

    export default {
        name: 'communicationHeader',
        props: {
            fadeIn: {
                type: Boolean,
                default: true
            },
            fadeInSpeed: {
                type: Number,
                default: 0
            },
            toggleMenuRight: Function
        },
        data: () => ({
            showHeader: false
        }),
        mounted() {

            if (this.fadeIn) {
                setTimeout(() => {
                    this.showHeader = true;
                }, this.fadeInSpeed);
            }
            else
                this.showHeader = true;
        }
    }
</script>