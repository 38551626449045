const hardCodedFacilities = [
  {
    name: "Centennial Hills Hospital",
    shortName: "CHH",
    id: "510836dedb8ae51e93f27e3a"
  },
  {
    name: "Desert Springs Hospital Medical Center",
    shortName: "DSH",
    id: "510836dedb8ae51e93f27e3b"
  },
  {
    name: "Henderson Hospital",
    shortName: "HENH",
    id: "58042df2f613e6cb57000049"
  },
  {
    name: "Spring Valley Hospital Medical Center",
    shortName: "SVH",
    id: "510836dedb8ae51e93f27e3c"
  },
  {
    name: "Summerlin Hospital Medical Center",
    shortName: "SMR",
    id: "510836dedb8ae51e93f27e3d"
  },
  {
    name: "Valley Hospital Medical Center",
    shortName: "VAL",
    id: "510836dedb8ae51e93f27e3e"
  }
];

const hardCodedGroups = [
  {
    name: "United Health Services",
    shortName: "UHS",
    id: "5dd6f2d86c341b0008cd9ed0"
  }
]

export { hardCodedFacilities, hardCodedGroups }
