<template>

    <div class="d-flex">

        <v-autocomplete
                light dense flat outlined solo
                v-model="patientSearchSelectedLocal"
                :items="items"
                :loading="isLoadingSearch"
                :search-input.sync="searchPatients"
                :item-text="getNamePatient"
                label="Search Patients"
                background-color="white"
                :rules="rules"
                clearable
                return-object/>
    </div>

</template>

<script>

    import GET_PATIENTS from '@/graphql/queries/phoenix/ycmdPatientGet';

    export default {
        name: 'ycmdPatientSearch',
        components: {
        },
        props: {
            patientSearchSelected: Object,
            userId: Object,
            rules: Array,
            facility_id: String,
            nameFormat: {
                type: String,
                default: 'first'
            },
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data: () => ({
            testModel: {},
            searchValue: '',
            isLoadingSearch: false,
            items: [],
            searchPatients: null
        }),
        mounted() {

        },
        watch: {
            searchPatients(val) {
                if (!val || val.length < 3) return
                this.getPatients(val);
            },
            facility_id(newval, oldval) {
                this.getPatients(this.searchPatients)
            }
        },
        methods: {
            getNamePatient(item) {
                if (this.nameFormat === 'last' ) {
                    return `${item.last_name}, ${item.first_name}`
                } else {
                    return `${item.first_name} ${item.last_name}`
                }
            },
            async getPatients(val) {
                this.isLoadingSearch = true;
                // console.log('TEST USER ID: ', this.userId && this.userId.id ? this.userId.id : null);
                try {
                    const response = await this.$apollo.query({
                        query: GET_PATIENTS,
                        variables: {
                            search: val,
                            mode: 'search',
                            facility_id: this.facility_id,
                            user_id: this.userId && this.userId.id ? this.userId.id : null,
                            limit: 30
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingSearch = false;
                    console.log('ycmd patients response: ', response);
                    const { ycmdPatientGet } = response.data;
                    if (ycmdPatientGet && ycmdPatientGet.patients) {
                        this.items = ycmdPatientGet.patients;
                    } else {
                        // data did not come back
                        console.log('ycmdPatientGet data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingSearch = false;
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            patientSearchSelectedLocal: {
                get: function() {
                    // handle items array
                    if (!this.patientSearchSelected) {
                        this.items = [];
                    } else {
                        this.items = [this.patientSearchSelected];
                    }
                    return this.patientSearchSelected;
                },
                set: function(newValue) {
                    this.$emit('update:patientSearchSelected', newValue)
                }
            }
        }
    }
</script>
