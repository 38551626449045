import gql from 'graphql-tag'

export default gql`query ycmdChatPostGet(
	$room_id: ID, 
	$chatpost_id: ID, 
	$from: ID,
	$search: String,
	$meta: [NameValueInput],
	$tags: [String],
	$limit: Int,
	$start: ChatPostStart
	$silent_read: Boolean,
	$prevToken: String, 
	$nextToken: String) {
	ycmdChatPostGet(
		room_id: $room_id, 
		chatpost_id: $chatpost_id,
		from: $from,
		search: $search,
		meta: $meta,
		tags: $tags,
		limit: $limit,
		start: $start,
		silent_read: $silent_read,
		prevToken: $prevToken, 
		nextToken: $nextToken) {
		posts {
			date_created
			first_name
			id
			last_name
			text
			type
			user_id
			attachment_id
			key
			system_type,
			reply_to_id
			reply_to_user{
				name
				id
			}
			reply_to_text
			reactions {
				reaction_key
				reaction_users {
					name
					id
				}
			}
		}
		nextToken
		prevToken
		starting_post_id
		unread_count
	}
}`
