<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">
                        <div class="pr-1 pa-2">

                          <div class="d-flex flex-column full-height">
                              <div class="d-flex flex-row">
                                <v-autocomplete
                                        light dense flat outlined
                                        v-model="facilitiesEnrolledSelected"
                                        :items="facilitiesEnrolled"
                                        :loading="isLoadingFacilities"
                                        item-value="id"
                                        item-text="name"
                                        label="Open Enrollment Facilities"
                                        background-color="white"
                                        style="max-width: 400px;"
                                        multiple
                                        clearable
                                        return-object/>
                                <v-btn depressed dark class="ycmd-blue ml-2" style="width: 180px; margin-top: 2px;"
                                       @click="removeEnrollment">Remove Enrollment
                                </v-btn>
                              </div>


                              <div class="d-flex">
                                  <v-autocomplete
                                          light dense flat outlined
                                          v-model="selectedFacility"
                                          :items="facilitiesSearched"
                                          :loading="isLoadingFacilitySearch"
                                          :search-input.sync="searchFacility"
                                          item-value="id"
                                          item-text="name"
                                          label="Search Facility"
                                          style="max-width: 400px;"
                                          background-color="white"
                                          clearable
                                          return-object/>
                              </div>

                              <div class="d-flex flex-row">

                                <v-select
                                    light dense flat outlined
                                    v-model="facilitiesSelected"
                                    :items="facilitiesSelected"
                                    label="Facilities"
                                    item-text="name"
                                    multiple
                                    class=""
                                    style="max-width: 400px;"
                                    background-color="white"
                                    clearable
                                ></v-select>
                                <v-btn depressed dark class="ycmd-blue ml-2" style="width: 180px; margin-top: 2px;"
                                       @click="addEnrollment">Add Enrollment
                                </v-btn>

                              </div>

                          </div>

                          <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                          <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                        </div>
                    </div>

                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import FETCH_ENROLLED from '../../../../graphql/queries/phxFacilityOpenEnrollment';
    import FACILITY_SEARCH from '../../../../graphql/queries/phxFacilitySearch';
    import REMOVE_ENROLLMENT from '../../../../graphql/mutations/phxFacilityRemoveOpenEnrollment';
    import ADD_ENROLLMENT from '../../../../graphql/mutations/phxFacilityAddOpenEnrollment'

    export default {
        name: 'providerLink',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                isLoading: false,
                errorMsg: '',
                successMsg: '',

                isLoadingFacilitySearch: false,
                selectedFacility: null,
                searchFacility: null,
                facilitiesSearched: [],
                facilitiesSelected: [],

                isLoadingFacilities: false,
                facilitiesEnrolled: [],
                facilitiesEnrolledSelected: []

            }
        },
        activated() {

        },
        mounted() {
          this.getFacilitiesEnrolled();
        },
        watch: {
          searchFacility(val) {
              if (!val || val.length < 3) return;
              this.getFacilities(val);
          },
          selectedFacility(val) {
              this.addFacilityToList();
          },
        },
        methods: {
            clearFacilitiesEnrolled() {
              this.facilitiesEnrolledSelected = [];
              this.getFacilitiesEnrolled();
            },
            clearFacilitiesAdd() {
              this.facilitiesSearched = [];
              this.facilitiesSelected = [];
            },
            addFacilityToList() {
                if (this.selectedFacility && this.selectedFacility.id) {
                    for (let facility in this.facilitiesSelected) {
                        const currentFacility = this.facilitiesSelected[facility];
                        if (currentFacility.name === this.selectedFacility.name) {
                          return;
                        }
                    }
                    this.facilitiesSelected.push(this.selectedFacility);
                }
                this.selectedFacility = null;
                this.facilitiesSearched = [];
                this.$nextTick(() => {
                    this.selectedFacility = null;
                    this.facilitiesSearched = [];
                });
            },
            async getFacilitiesEnrolled() {
                this.isLoadingFacilities = true;
                try {
                    const response = await this.$apollo.query({
                        query: FETCH_ENROLLED,
                        variables: {
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingFacilities = false;
                    console.log('phxFacilityOpenEnrollment response: ', response);
                    if (response.data.phxFacilityOpenEnrollment) {
                      this.facilitiesEnrolled = response.data.phxFacilityOpenEnrollment;
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxFacilityOpenEnrollment data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingFacilities = false;
                    defaultErrorHandler(e);
                }
            },
            async removeEnrollment() {
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: REMOVE_ENROLLMENT,
                        variables: {
                          facility_ids: this.facilitiesEnrolledSelected.map((item) => item.id)
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    this.clearFacilitiesEnrolled();
                    console.log('phxFacilityRemoveOpenEnrollment response: ', response);
                    if (response.data.phxFacilityRemoveOpenEnrollment) {
                      this.$toasted.success(`Successfully removed facility!`);
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxFacilityRemoveOpenEnrollment data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async addEnrollment() {
                if (!this.facilitiesSelected.length) return;
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: ADD_ENROLLMENT,
                        variables: {
                          facility_ids: this.facilitiesSelected.map((item) => item.id)
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    this.clearFacilitiesEnrolled();
                    this.clearFacilitiesAdd();
                    console.log('phxFacilityAddOpenEnrollment response: ', response);
                    if (response.data.phxFacilityAddOpenEnrollment) {
                      this.$toasted.success(`Successfully added facility`);
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxFacilityAddOpenEnrollment data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async getFacilities(val) {

                this.isLoadingFacilitySearch = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: FACILITY_SEARCH,
                        variables: {
                            search: val
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingFacilitySearch = false;
                    // console.log('response: ', response);
                    if (response.data.phxFacilitySearch) {
                      this.facilitiesSearched = response.data.phxFacilitySearch;
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxFacilitySearch data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingSearch = false;
                    defaultErrorHandler(e);
                }

            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
