<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="800"
  >
        <v-form
          ref="frmAppointment"
          v-model="patientFormValid"
        >
        <v-card>
            <v-card-title
              class="fs-18 primary white--text"
            >
              New Patient
            </v-card-title>
            <v-card-text class="fs-18 pt-5">
                <v-container
                class="pa-0 ma-0"
                >


                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 py-0 ma-0 fw-700"
                    >
                      NP-{{newPatient}}

                    </v-col>
                  </v-row>



                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="4"
                        class="pl-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="First Name"
                        v-model="patientFirstName"
                        background-color="ycmd-very-lightest-blue"
                        :rules="[rules.required]"
                      />

                    </v-col>
                    <v-col cols="4"
                        class="pl-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Last Name"
                        v-model="patientLastName"
                        background-color="ycmd-very-lightest-blue"
                        :rules="[rules.required]"
                      />

                    </v-col>
                    <v-col cols="4"
                        class="pl-0 pr-0 py-0 ma-0 fw-700"
                    >
                      <date-picker
                          v-model="patientDob"
                          :includeCustomIcon="false"
                          backgroundColor="ycmd-very-lightest-blue"
                          iconStyle="inner"
                          :rules="[rules.required]"
                          label="Date of Birth"
                      />

                    </v-col>
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="3"
                        class="pl-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Mobile Phone"
                        v-model="patientMobilePhone"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesPhone"
                      />

                    </v-col>
                    <v-col cols="3"
                        class="pl-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Home Phone"
                        v-model="patientHomePhone"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesPhone"
                      />

                    </v-col>
                    <v-col cols="3"
                        class="pl-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Work Phone"
                        v-model="patientWorkPhone"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesPhone"
                      />

                    </v-col>
                    <v-col cols="3"
                        class="pl-0 pr-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Email"
                        v-model="patientEmail"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesEmail"
                      />

                    </v-col>                    
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="6"
                        class="pa-0 ma-0 pr-3 fw-700"
                    >
                      <emr-location-select
                          v-model="patientLocation"
                          :providerId="providerId"
                          :outlined="false"
                          backgroundColor="ycmd-very-lightest-blue"
                          :rules="rulesLocation"
                          label="Office / Location"
                      />
                    </v-col>

                    <v-col cols="6"
                        class="pa-0 ma-0 fw-700"
                    >

                      <v-select flat 
                          v-model="patientPrimaryProvider"
                          label="Primary Care Physician"
                          :items="practiceProviders"
                          item-text="name"
                          item-value="id"
                          background-color="ycmd-very-lightest-blue"
                          :rules="primaryProviderRules"
                      >
                        <template v-slot:item="data">
                            <v-container
                            class="pa-0 ma-0"
                            >        
                                <v-row
                                    class="pa-0 ma-0"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0 fw-700"
                                    >
                                        {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"
                                    v-if="data.item.specialty"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                        <span class="fw-300 fs-12">Specialty:</span> <span class="fw-400 fs-12">{{data.item.specialty}}</span>
                                    </v-col>
                                </v-row>

                                <v-row
                                    class="pa-0 ma-0"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>

                                    </v-col>
                                </v-row>
                            </v-container>

                        </template>     
                                    
                        <template v-slot:selection="data">
                            <v-container
                            class="pa-0 ma-0"
                            >        
                                <v-row
                                    class="pa-0 ma-0"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0 fw-700"
                                    >
                                    {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"
                                    v-if="data.item.specialty"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                    <span class="fw-300 fs-12">Specialty:</span> <span class="fw-400 fs-12">{{data.item.specialty}}</span>
                                    </v-col>
                                </v-row>
            <!--
                                <v-row
                                    class="pa-0 ma-0"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                    <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>
                                    <v-divider />
                                    </v-col>
                                </v-row>
            -->                    
                            </v-container>

                        </template>


                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Address 1"
                        dense
                        v-model="patientAddress1"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesAddress"
                      />

                    </v-col>
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Address 2"
                        dense
                        v-model="patientAddress2"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesAddress"
                      />

                    </v-col>
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="4"
                        class="pl-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="City"
                        dense
                        v-model="patientCity"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesAddress"
                      />

                    </v-col>
                    <v-col cols="4"
                        class="pl-0 py-0 ma-0 fw-700"
                    >
                      <state-picker
                          v-model="patientState"
                          :includeCustomIcon="false"
                          backgroundColor="ycmd-very-lightest-blue"
                          iconStyle="inner"
                          :rules="rulesAddress"
                          label="State"
                      />

                    </v-col>
                    <v-col cols="4"
                        class="pa-0 py-0 ma-0 fw-700"
                    >
                      <v-text-field
                        label="Zip"
                        dense
                        v-model="patientZip"
                        background-color="ycmd-very-lightest-blue"
                        :rules="rulesAddress"
                      />

                    </v-col>

                  </v-row>
  
                                             
                </v-container>


            </v-card-text>

            <v-card-subtitle
            >
              <div
                class="error white--text"
                v-if="error"
              >
                {{error}}
              </div>
              <div
                v-else
              >
                &nbsp;
              </div>
            </v-card-subtitle>
            
            <v-card-actions>
                <v-spacer/>
                <v-btn
                  @click="save"
                  class="primary"
                  :disabled="sending || !patientFormValid"
                  :loading="sending"
                >
                  Save
                </v-btn>
                <v-btn
                  @click="cancel"
                  :disabled="sending"
                >
                  Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-form>

    </v-dialog>

</template>

<script>
  import emrLocationSelect from '@/views/apps/emr/components/emrLocationSelect';
  import DatePicker from '@/components/datetime/date-picker';
  import StatePicker from '@/components/state-picker';
  import emrProvider from '@/views/apps/emr/components/emrProvider.vue'
  import {mapActions, mapGetters} from "vuex";

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      providerId: {
        default: null,
        type: String
      },
      error: {
        default: null,
        type: String
      },
    },
    data: () => ({
      sending:false,
      patientFormValid: false,
      patientLocation: null,
      patientFirstName: null,
      patientLastName: null,
      patientMobilePhone: null,
      patientWorkPhone: null,
      patientHomePhone: null,
      patientDob: null,
      patientEmail: null,
      patientPrimaryProvider: null,
      patientAddress1: null,
      patientAddress2: null,
      patientCity: null,
      patientState: null,
      patientZip: null,

      rules: {
        required: (value) => {
          return value ? true : 'Required'
        },
      },    

    }),
    mounted() {
    },
    methods: {
      cancel() {
        this.sending = false
        this.$emit('input', false)
      },
      save() {
        this.sending = true
        this.$refs.frmAppointment.validate()
        console.log('this.patientFormValid', this.patientFormValid)
        if (this.patientFormValid) {
          this.$emit('save',{patient: this.newPatient, callback: this.saveCallback})
        }
      },
      saveCallback (params) {
        console.log('saveCallback', params)
        this.sending = false
      }
    },
    components: {
      'emr-location-select': emrLocationSelect,
      'date-picker': DatePicker,
      'emr-provider':emrProvider,
      'state-picker': StatePicker
    },
    computed: {
      ...mapGetters(
          'emr',['getProviders']
      ),
      rulesLocation () {
        return []
      },
      rulesPhone () {
        return []
      },
      rulesEmail () {
        return []
      },
      rulesAddress () {
        return []
      },
      primaryProviderRules () {
        return []
      },
      practice () {
        let p = this.$store.getters['emr/getPracticeByProviderId'](this.providerId)

        return p
      },
      practiceProviders () {
        let arr = []

        let practice = this.practice

        this.getProviders.map(provider => {
          if (provider && practice && provider.practiceId === practice.id) {
            arr.push(provider)
          }
        })

        let sorted = arr.sort( (a,b) => {
          let aname = a.lastname
          if (aname) {
            aname += ', '
          }
          aname += a.firstname

          let bname = b.lastname
          if (bname) {
            bname += ', '
          }
          bname += b.firstname

          return aname.localeCompare(bname)
        })

        return sorted

      },
      newPatient () {
        let np = {
          location_id: this.patientLocation,
          firstname: this.patientFirstName,
          lastname: this.patientLastName,
          phones: this.newPatientPhones,
          dob: this.patientDob,
          emails: this.newPatientEmails,
          primary_provider_id: this.patientPrimaryProvider,
          address1: this.patientAddress1,
          address2: this.patientAddress2,
          city: this.patientCity,
          state: this.patientState,
          zip: this.patientZip,
          practice_id: this.practice ? this.practice.id: ''
        }

        return np
      },
      newPatientEmails () {
        let arr = []
        if (this.patientEmail) {
          arr.push({
            id: 'email',
            value: this.patientEmail
          })
        }
        return arr
      },
      newPatientPhones () {
        let arr = []
        
        if (this.patientMobilePhone) {
          arr.push({
            id: 'mobile',
            value: this.patientMobilePhone
          })
        }
        if (this.patientHomePhone) {
          arr.push({
            id: 'home',
            value: this.patientHomePhone
          })
        }
        if (this.patientWorkPhone) {
          arr.push({
            id: 'work',
            value: this.patientWorkPhone
          })
        }

        return arr

      }
    },
    watch: {
    }
  }
</script>

