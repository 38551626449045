<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">

                <div class="i-card__main__content br-2 pb-2 pr-2"
                    stylexxx="border: 1px solid blue;"
                >

                    <v-container
                        class="pa-0 ma-0 full-width"
                        style="width: 100%;max-width: 100%"
                    >
                        <v-row
                            class="pa-0 ma-0"
                        >
                            <v-col
                                class="pa-0 ma-0 fw-700"
                                cols="12"
                                stylexxx="border: 1px solid green;"
                            >
                                <emr-provider-multiselect 
                                    v-model="providerIds"
                                    :timezone.sync="timezone"
                                    :showUnavailable.sync="showUnavailable"
                                />
                            </v-col>
                        </v-row>
                        <v-row
                            class="pa-0 ma-0"
                        >
                            <v-col
                                class="pa-0 ma-0 fw-700"
                                cols="12"
                                stylexxx="border: 1px solid pink;"
                            >

                                <emr-calendar 
                                    :events="eventsArray"
                                    :refreshCalendar="refreshCalendar"
                                    :loading="loading"
                                    :timezone.sync="timezone"
                                    :providerIds="providerIds"
                                    :showUnavailable.sync="showUnavailable"
                                    @getEvents="checkRange"
                                    @createAppointment="createAppointment"
                                    @editAppointment="editAppointment"
                                />
                            </v-col>
                        </v-row>

                    </v-container>



                </div>

            </div>

            <template slot="right-panel">

                <div>VMM Hello right panel</div>

            </template>

            <template slot="bottom-panel">

                <div>VMM Hello Bottom Panel</div>

            </template>

        </i-card>

        <emr-appointment
            v-model="openAppointment"
            :appointmentTime.sync="newAppointmentTime"
            :appointmentDate.sync="newAppointmentDate"
            :providerIds="providerIds"
            :error="appointmentError"
            :timezone="timezone"            
            @save="saveAppointment"
            @close="closeAppointment"
            :key="`ap-modal-${appRefresh}`"
        />
       <emr-appointment-edit
            v-model="openEditAppointment"
            :appointment="appointmentEditObject"
            :error="appointmentError"
            @save="updateAppointment"
            @cancelAppointment="cancelAppointment"
            @close="closeAppointment"
            :key="`ape-modal-${appRefresh}`"
            :timezone="timezone"
        />

        <confirmation
            v-model="confirmConflictingSchedule"
            title="Schedule Conflict"
            subtitle="Confirm Schedule"
            text="This is appointment occurs during a time the provider has listed as unavailable.  Are you sure you want to schedule this appointment?"
            @click="confirmConflictingScheduleClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />     

    </div>

</template>

<script>
    import {utcToZonedTime} from 'date-fns-tz';
    import {mapActions, mapGetters} from "vuex";
    import SubHeaderContent from '@/components/header/sub-header-content';
    import emrProviderMultiselect from '@/views/apps/emr/components/emrProviderMultiselect';
    import emrCalendar from '@/views/apps/emr/components/emrCalendar';
    import { addDays, addMonths, addMinutes, min, differenceInMinutes } from 'date-fns'
    import dataClient from '@/graphql/clients/axios';
    import emrAppointment from '@/views/apps/emr/components/emrAppointment';
    import emrAppointmentEdit from '@/views/apps/emr/components/emrAppointmentEdit';
    import {timezoneIsoDate} from '@/methods/formatters';
    import Confirmation from '@/components/modals/confirmation.vue'

;

    export default {
        name: 'EMR.schedule',
        components: {
            'sub-header-content': SubHeaderContent,
            'emr-provider-multiselect': emrProviderMultiselect,
            'emr-calendar': emrCalendar,
            'emr-appointment': emrAppointment,
            'emr-appointment-edit': emrAppointmentEdit,
            'confirmation': Confirmation,
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false },
                },
                providerIds: [],
                calendarEvents: [],
                currentRange: null,
                refreshCalendar: '',
                lastProviderIds: [],
                openAppointment: false,
                appointmentError: null,
                newAppointmentTime: null,
                newAppointmentDate: null,
                appRefresh: 0,
                loading: false,
                timezone: '',
                showUnavailable: false,
                utcToZonedTime,
                availableProviderIds: [],
                openEditAppointment: false,
                appointmentEditObject: {},
                conflictingAppointment: null,
                confirmConflictingSchedule: false
            }
        },
        created() {},
        async mounted() {

        },

        activated() {},
        watch: {
            providerIds (newValue, oldValue) {
                this.checkProviders(newValue, this.lastProviderIds)
                this.lastProviderIds = JSON.parse(JSON.stringify(newValue))
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            confirmConflictingScheduleClicked (btn) {
                this.confirmConflictingSchedule = false

                if (btn.caption === 'OK') {
                    this.newAppointmentTime = this.conflictingAppointment.appt.time
                    this.newAppointmentDate = this.conflictingAppointment.appt.date
                    this.appointmentError = null
                    this.appRefresh += 1
                    this.openAppointment = true
                }
            },
            async editAppointment (params) {

                let appointment = this.calendarEvents.find( a => {
                    return a.id === params.id
                })

                if (appointment) {
                    //let p = this.$store.getters['emr/getProviderById'](appointment.providerid)
                    console.log('appointment', appointment)
                    this.appointmentEditObject = appointment
                    this.appointmentError = null
                    this.openEditAppointment = true
                }
            },
            createAppointment (appt) {
                console.log('appt', appt)
                console.log('this.providerIds', this.providerIds)
                this.conflictingAppointment = null

                if (!this.providerIds || !this.providerIds.length) {
                    this.$toasted.error('No providers are selected');
                    return
                }
                let dt = new Date(appt.date.replace('-','/'))
                let pieces = appt.time.split(':')
       
                let hours = parseInt(pieces[0])
                let minutes = parseInt(pieces[1])

                let iso = timezoneIsoDate(dt, hours, minutes, 0, this.timezone)
                let date = new Date(iso)

                this.availableProviderIds = []
                this.providerIds.map(providerId => {
                    
                    let availablePeriods = this.$store.getters['emr/findAvailablePeriods'](providerId, date)
                    if (!availablePeriods || !availablePeriods.length) {
                        this.confirmConflictingSchedule = true
                        this.conflictingAppointment = {
                            appt: appt
                        }
                    }
                })   

                if (this.confirmConflictingSchedule) {
                    return
                }

                this.newAppointmentTime = appt.time
                this.newAppointmentDate = appt.date
                this.appointmentError = null
                this.appRefresh += 1

                this.openAppointment = true
            },
            async updateAppointment (payload) {
                this.appointmentError = null
                let callback = payload.callback
            },
            async cancelAppointment (payload) {
                this.appointmentError = null
                let callback = payload.callback
                let result = await this.$store.dispatch('emr/cancelAppointment', payload.appointment)
                if (result && result.appointments && result.appointments.length) {

                    let index = this.calendarEvents.findIndex(ce => {
                        return payload.appointment.id === ce.id
                    })

                    if (index >= 0) {
                        this.calendarEvents.splice(index, 1);
                    }

                    this.openEditAppointment = false

                    let successMessage = `Appointment cancelled`
                    
                    this.$toasted.success(successMessage);

                } else if (result && result.error) {
                    this.appointmentError = result.error.message
                    if (!this.appointmentError) {
                        this.appointmentError = 'An error has occurred'
                    }
                }
                if (callback) {
                    callback()
                }

            },
            async saveAppointment (payload) {

                this.appointmentError = null
                let appt = payload.appointment
                let callback = payload.callback

                let appointment = await this.$store.dispatch('emr/saveAppointment', appt)
                appointment.eventType = 'appointment'

                if (appointment && appointment.id) {
                    this.calendarEvents.push(appointment)
                    this.openAppointment = false

                    let successMessage = `Appointment scheduled`
                    if (appointment && appointment.patient){
                        successMessage += ` for ${appointment.patient.firstname} ${appointment.patient.lastname} `
                    }
                    
                    this.$toasted.success(successMessage);
                } else if (appointment && appointment.error) {
                    this.appointmentError = appointment.error.message
                    if (!this.appointmentError) {
                        this.appointmentError = 'An error has occurred'
                    }
                }
                if (callback) {
                    callback()
                }
            },
            closeAppointment () {
                this.openAppointment = false
                this.openEditAppointment = false
            },
            calcStartDate (string)  {
                let dt = new Date(`${string} 00:00:00`)
                //dt = addDays(dt, -15) //go 2 weeks back plus 1 day to account for time zone differences
                dt = addMonths(dt, -1) //go one month backward
                dt = addDays(dt, -1) //minus 1 day to account for time zones

                return dt
            },
            calcEndDate (string) {
                let dt = new Date(`${string} 23:59:59`)
                dt = addMonths(dt, 2) //go 2 month forward
                dt = addDays(dt, 1) //plus 1 day to account for time zones

                return dt
            },
            
            checkRange(range) {
                //the date strings are in yyyy-MM-dd
                let retrieve = false
                
                if (!this.currentRange) {
                    retrieve = true
                } else {
                    let rangeStart = new Date(`${range.start.date} 00:00:00`)                    
                    let rangeEnd = new Date(`${range.end.date} 23:59:59`)

                    // expand the range on each end and check to see if buffered range is still within the current range 
                    let bufferStart = addDays(rangeStart, -8)
                    let bufferEnd = addDays(rangeEnd, 8)

                    console.log('this.currentStart', this.currentStart)                    
                    let startBetween = bufferStart > this.currentStart && bufferStart < this.currentEnd
                    console.log('this.currentEnd', this.currentEnd)
                    let endBetween = bufferEnd > this.currentStart && bufferEnd < this.currentEnd

                    if (!startBetween || !endBetween) {
                        retrieve = true
                    }
                }
                if (retrieve) {
                    this.loadEvents(range, this.providerIds)
                }

                this.currentRange = range

            },
            checkProviders(newValue, oldValue) {
                
                if (!newValue || !newValue.length) {
                    this.$store.commit('emr/clearPalette')
                    this.calendarEvents = []
                } else if (this.currentRange){
                    if (newValue.length > oldValue.length) {
                        this.loadEvents(this.currentRange, newValue)
                    }
                }
                //this.refreshCalendar = (new Date()).toISOString()
            },
            async loadEvents(range, providerIds) {
                let start = this.calcStartDate(range.start.date)
                let end = this.calcEndDate(range.end.date)
                if (!start || !end) {
                    return
                }

                if (!providerIds || !providerIds.length) {
                    return
                }
                this.loading = true

                let filters = []
                filters.push({
                    id: 'start',
                    value: start.toISOString()
                })
                filters.push({
                    id: 'end',
                    value: end.toISOString()
                })
                providerIds.map(id => {
                    filters.push({
                        id: 'providerid',
                        value: id
                    })                
                })

                let params = {
                    operation: 'schedule_get',
                    parameters: filters
                }
                let getResult = await dataClient('emrGet', params)

                this.currentStart = start
                this.currentEnd = end

                getResult.appointments.map(e => {
                    e.eventType = 'appointment'
                    this.calendarEvents.push(e)
                })

                for (let i =0;i<this.providerIds.length;i++) {
                    let id = this.providerIds[i]

                    let availabilities = {}
                    let timezones = {}

                    let provider = this.$store.getters['emr/getProviderById'](id)
                    let practice = this.$store.getters['emr/getPracticeById'](provider.practiceId)

                    timezones[id] = practice.locations[0].timezone

                    if (provider.availabilities && provider.availabilities.length) {
                        let pAvailabilities = availabilities[id]
                        if (!pAvailabilities) {
                            availabilities[id] = {}
                            pAvailabilities = availabilities[id]
                        }
                        provider.availabilities.map(avail => {
                            let avails = availabilities[id][avail.weekday+'']

                            if (!avails) {
                                availabilities[id][avail.weekday+''] = []
                                avails = availabilities[id][avail.weekday+'']
                            }
                            avails.push(avail)
                        })
                    }
                    console.log('this.calendarEvents', this.calendarEvents)
                    console.log('availabilities', availabilities)
                    console.log('timezones', timezones)


                    let day = start
                    while (day <=end) {

                        let unavailable = await this.getUnavailable(provider, practice, new Date(day), timezones[id])

                        if (unavailable && unavailable.length) {
                            //console.log('unavailable', unavailable)

                            this.calendarEvents.push(...unavailable)
                        }
                        day = addDays(day, 1)
                    }
                }

                this.loading = false

            },
            getDateString(date, hours, minutes, timezone) {
                let temp = date
                temp.setHours(hours)
                temp.setMinutes(minutes)
                temp.setSeconds(0)

                let str = this.utcToZonedTime(temp, timezone)

                return str
            },
            async getUnavailable(provider, practice, day, timezone) {

                let unavail2 = await this.$store.dispatch('emr/getUnavailablePeriods',
                    {
                        day: new Date(day), 
                        providerId: provider.id, 
                        timezone: timezone,
                        includeEndpoints: true
                    })


                let ret = []
                unavail2.map(e => {
                    ret.push({
                            id: `${e.eventType}^${provider.id}^${e.start}`,
                            eventType: e.eventType,
                            datetimeutc: e.datetimeutc,
                            duration: e.duration,
                            end: e.end,
                            practiceid: practice.id,
                            practicename: practice.name,
                            provider_firstname: provider.firstname,
                            provider_lastname: provider.lastname,
                            providerid: provider.id,
                            start: e.start,
                            name: `${provider.firstname} ${provider.lastname} unavailable`

                    })
                })
                return ret
            },
            getUnavailableOld (availabilities, timezone, day, provider, practice) {

                let dow = day.getDay()

                let dayAvailabilities = availabilities[dow+'']

                let ret = []
                if (!dayAvailabilities) {
                    //this day is not available.  set the type to unavailable_day.  unavailale_day will not adjust 
                    //start/end based on timezone.  It makes the calendar look weird.  Instead, that day will e blocked out, 
                    //regardless of timezone
               
                    ret.push(
                        {
                            id: `unavailday^${provider.id}^${day}`,
                            eventType: 'unavailable_day',
                            //datestringlocal: day,
                            //datetimelocal: new Date(day),
                            datetimeutc: day,
                            duration: 24 * 60,
                            end: addMinutes(day, 24 * 60),
                            practiceid: practice.id,
                            practicename: practice.name,
                            provider_firstname: provider.firstname,
                            provider_lastname: provider.lastname,
                            providerid: provider.id,
                            start: day,
                            name: `${provider.firstname} ${provider.lastname} unavailable`
/*                            
                            timed: true
                            timezone: timezone
                            type
                            typedisplay
                            typeid
*/
                        }
                    )
                } else {
                    let startTime = day
                    let hours = 0
                    let minutes = 0
                    let temp = []
                    for (let i=0;i<dayAvailabilities.length;i++) {
                        let pieces = dayAvailabilities[i].startlocal.split(':')
                        hours = parseInt(pieces[0])
                        minutes = parseInt(pieces[1])

                        let startString = this.getDateString(startTime, hours,minutes,timezone)
                        let start = new Date(startString)
                        let end = addMinutes(start, dayAvailabilities[i].duration)

                        temp.push({
                            start,
                            end,
                            hours,
                            minutes,
                            availability: dayAvailabilities[i]
                        })
                    }

                    let sorted = temp.sort((a, b) => {
                        return (a.start < b.start) ? -1 : ((a.start > b.start) ? 1 : 0)
                    });

                    for (let i=1;i<sorted.length;i++) {
              
                        let first = sorted[i-1]
                        let second = sorted[i]

                        let start = first.end
                        let end = second.start

                        let duration = differenceInMinutes(end, start)

                        ret.push(
                            {
                                id: `unavailtime^${provider.id}^${start.toISOString()}`,
                                eventType: 'unavailable_time',
                                //datestringlocal: this.utcToZonedTime(start, timezone),
                                //datetimelocal: new Date(this.utcToZonedTime(start, timezone)),
                                datetimeutc: start,
                                duration: duration,
                                end: end,
                                practiceid: practice.id,
                                practicename: practice.name,
                                provider_firstname: provider.firstname,
                                provider_lastname: provider.lastname,
                                providerid: provider.id,
                                start: start,
                                name: `${provider.firstname} ${provider.lastname} unavailable`
                            }
                        )
                    }                 
                }

                return ret

            }
        },
        computed: {
            ...mapGetters(
                'profile', ['getUserID']
            ),
            confirmationButtons () {
                return [
                    {caption: 'OK'},
                    {caption: 'Cancel'}
                ]
            },
            providerMap () {
                let obj = {}
                this.providerIds.map(id => {
                    obj[id] = true
                })
                return obj
            } ,
            eventsArray () {
                let arr = []
                let added = {}

                let tz = this.timezone

                this.calendarEvents.map(ce => {

                    if (!added[ce.id] && this.providerMap[ce.providerid]) {

                        //console.log(`converted time ${ce.datetimeutc}: `, this.utcToZonedTime(ce.datetimeutc, tz))

                        if (this.showUnavailable || (ce.eventType !== 'unavailable_day' && ce.eventType !== 'unavailable_time') ) {


                            switch (ce.eventType) {
                                case 'unavailable_day':
                                    ce.start = ce.datetimeutc  // don't translate times for unavailable days
                                    ce.end = addMinutes(ce.start, 60 * 24)  // don't translate times for unavailable days

                                    break

                                default:
                                    //console.log('ce', ce)

                                    ce.start = new Date(tz ? this.utcToZonedTime(ce.datetimeutc, tz) :ce.datetimeutc)
                                    
                                    if (!ce.name) {
                                        if (ce.patient) {
                                            ce.name = `${ce.patient.firstname} ${ce.patient.lastname}`
                                        }
                                    }
                                    
                                    break

                            }
                            ce.end = addMinutes(ce.start, ce.duration)

                            ce.timed = true
                            arr.push(ce)
                            added[ce.id] = true
                        }
                    }
                })
                return arr
            }
        }
    }

</script>

<style scoped>

</style>
