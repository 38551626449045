import gql from 'graphql-tag'

export default gql`query phxPods($group_id: ID) {
	phxPods(group_id: $group_id) {
		id
    name
		providers {
			user_id
			name
			provider_id
		}
  }
}`
