<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">

                      <div class="pr-1 pa-2">

                          <div>

                              <v-autocomplete
                                    light dense flat outlined
                                    v-model="selectedGroup"
                                    :disabled="!allGroups.length"
                                    :items="allGroups"
                                    :loading="isLoadingGroupsSearch"
                                    label="Select Group"
                                    item-value="id"
                                    item-text="name"
                                    style="max-width: 400px;"
                                    background-color="white"
                                    clearable
                                    return-object
                              ></v-autocomplete>

                              <div v-if="(currentBillingGroup && selectedGroup)">
                                <v-text-field
                                        light dense flat outlined
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="name"
                                        label="Name"
                                        background-color="white" />

                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="altName"
                                        label="Alt Name"
                                        background-color="white" />

                                <v-text-field
                                        light dense flat outlined
                                        v-if="selectedGroup"
                                        class="small-input"
                                        style="max-width: 400px;"
                                        v-model="shortCode"
                                        label="Short Code"
                                        background-color="white" />
                              </div>

                                <v-btn depressed light class="ycmd-blue white--text" :disabled="(!selectedGroup || !currentBillingGroup)" style="width: 180px;" @click="updateBillingGroup">Update Group</v-btn>

                                <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                                <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import {refreshHandler} from '@/graphql/handler/refreshHandler';
    import SEARCH_GROUPS from '@/graphql/queries/phxGroups';
    import GET_GROUP_BILLING from '@/graphql/queries/billingGroup'
    import UPDATE_BILLING_GROUP from '@/graphql/mutations/billingGroupUpdate'

    export default {
        name: 'updateGroup',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                selectedModal: null,
                isLoading: false,
                errorMsg: '',
                successMsg: '',

                allGroups: [],

                selectedGroup: null,
                groupsSearched: [],
                isLoadingGroupsSearch: false,

                name: '',
                altName: '',
                shortCode: '',

                currentBillingGroup: null
            }
        },
        activated() {

        },
        mounted() {
          this.getGroups(null);
        },
        methods: {
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            selectAllProviders() {
              this.selectedProviders = this.searchedProviders;
            },
            async getGroups(val) {
                this.isLoadingGroupsSearch = true;
                try {
                    const response = await this.$apollo.query({
                        query: SEARCH_GROUPS,
                        variables: {
                            group_id: null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingGroupsSearch = false;
                    console.log('phxGroups response: ', response);
                    if (response.data.phxGroups) {
                      if (!val) {
                        // console.log('setting all groups: ', response.data.phxGroups);
                        this.allGroups = response.data.phxGroups;
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('phxGroups data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingGroupsSearch = false;
                    defaultErrorHandler(e);
                }
            },
            async getBillingGroup(val) {
                // console.log('val: ', val);
                this.isLoading = true;
                try {
                    const response = await this.$apollo.query({
                        query: GET_GROUP_BILLING,
                        variables: {
                            group_id: val.id
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('billingGroup response: ', response);
                    if (response.data && response.data.billingGroup) {
                      if (response.data.billingGroup[0]) {
                          console.log('currentBillingGroup Data: ', response.data.billingGroup[0]);
                          this.currentBillingGroup = response.data.billingGroup[0];

                          // this.altName = this.currentBillingGroup.altName;
                          // this.name = this.currentBillingGroup.name;
                          this.altName = val.alt_name;
                          this.name = val.name;
                          this.shortCode = this.currentBillingGroup.shortCode;
                      }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('billingGroup data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            async updateBillingGroup() {
                this.isLoading = true;
                console.log(this.selectedGroup.id, this.name, this.altName, this.shortCode);
                try {
                    const response = await this.$apollo.mutate({
                        mutation: UPDATE_BILLING_GROUP,
                        variables: {
                            group_id: this.selectedGroup.id,
                            billing_group: {
                            	name: this.name,
                            	altName: this.altName,
                            	shortCode: this.shortCode
                            }
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('billingGroupUpdate response: ', response);
                    if (response.data.billingGroupUpdate) {
                      // this.templateSearched = response.data.phxShifts;
                      this.successMsg = `You have successfully updated the group! The name is ${response.data.billingGroupUpdate.name}. The short code is ${response.data.billingGroupUpdate.shortCode}. The alt name is ${response.data.billingGroupUpdate.altName}`
                      this.getGroups(null);
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message) {
                            this.errorMsg = response.errors[0].message;
                        } else {
                            this.errorMsg = "Error 2E. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        console.log('billingGroupUpdate data did not come back: ', response);
                    }
                    this.clearFields();
                } catch (e) {
                    this.isLoading = false;
                    this.clearFields();
                    defaultErrorHandler(e);
                }
            },
            clearFields(fromWatch) {
              if (!fromWatch) {
                this.selectedGroup = null;
              }
              this.currentBillingGroup = null;
              this.name = '';
              this.altName = '';
              this.shortCode = '';
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        watch: {
          selectedGroup(val) {

            this.clearFields(true);

            if (!val || !val.id) return;

            this.getBillingGroup(val);

          }

        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
