<template>

  <div class="view">

    <div class="view">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 ycmd-medium-blue">

        <div class="i-card__main">

          <div class="i-card__main__header">

            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width white--text pl-3 pr-3"
                   style="height:56px;border-bottom: solid 2px #125ea3;">
                <div class="d-flex align-center">
                  <v-btn icon dark elevation="0" @click="changeView({view: 'chat-main', transition: 'prev', params: {room_id: room.id}})">
                    <v-icon size="18">arrow_back</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-column flex-grow-1 pl-4 pr-2 fs-12">
                  EDIT ROOM
                </div>
                <div class="d-flex align-center pr-2">
                  <v-tooltip top
                             nudge-top="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-1 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon dark depressed
                          v-on="on"
                          class="ma-0"
                          @click="changeView({view: 'chat-settings', transition: 'next', params: {room_id: room.id}})"
                          color="white">
                        <v-icon dark size="18" class="pl-1 pr-1">settings</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-no-wrap">Settings</span>
                  </v-tooltip>
                </div>
<!--                
                <div class="d-flex align-center full-height pl-3 ml-1" style="border-left: solid 2px #125ea3">
                  <v-tooltip top
                             nudge-top="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-1 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon dark depressed
                          v-on="on"
                          class="ma-0"
                          @click="isRightMenuExpanded ? collapseRightMenu() : expandRightMenu()">
                        <v-icon dark size="20">{{ isRightMenuExpanded ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-no-wrap">{{ isRightMenuExpanded ? 'Minimize Chat' : 'Maximize Chat' }}</span>
                  </v-tooltip>
                </div>
-->                
              </div>
            </div>

          </div>

          <div class="i-card__main__content">

            <div class="i-card__scrollable black--text white">

              <template v-if="loading">
                  <loading theme="light" :center="true" class="pr-5" />
              </template>

              <template v-else>

                <v-tabs-items v-model="tab" class="full-tabs">

                  <v-tab-item>

                    <div class="d-flex align-center full-width ycmd-medium-blue white--text pa-1 pr-1"
                         style="border-bottom: solid 2px #125ea3; height: 50px">
                      <div class="pl-4 ml-1">{{ creatorCount + memberCount }}
                        <span class="pl-2 fs-12 fade-2">Users</span>
                      </div>
                      <v-spacer />
                      <v-tooltip top
                                 nudge-top="5"
                                 :disabled="$vuetify.breakpoint.xsOnly"
                                 color="ycmd-black"
                                 content-class="fade-0 pa-4 pt-1 pb-2">
                        <template v-slot:activator="{ on }">
                          <v-btn
                              icon dark depressed small
                              v-on="on"
                              :disabled="!isCreator"
                              :loading="loading"
                              class="ma-0 mr-3"
                              elevation="0" @click.stop="changeView({view: 'chat-add-participant', transition: 'next', params: {room}})">
                            <v-icon dark size="16">add</v-icon>
                          </v-btn>
                        </template>
                        <span class="text-no-wrap">Add participants</span>
                      </v-tooltip>
                    </div>

                    <div class="pa-2">

                      <div class="d-flex align-center pa-4 pb-1">
                        <div class="fade-2 fs-12">CREATOR</div>
                        <v-spacer/>
                        <div class="fs-14 pr-2">
                          {{ creatorCount }}
                        </div>
                        <div class="fade-2 fs-11">User(s)</div>
                      </div>

                      <template v-for="(participant) in room.participants">
                        <provider 
                          :key="`provider_${participant.user_id}`"
                          v-if="participant.creator && participant.status != 'suspended'" 
                          :provider="getProvider(participant)" 
                          class="pa-2 pb-4"
                          foregroundColor="white--text"
                          backgroundColor="ycmd-blue"
                        >
                          <v-spacer/>
                          <template v-if="isCreator">

                            <v-tooltip top
                                       nudge-top="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                    icon depressed
                                    v-on="on"
                                    :loading="deleting"
                                    @click="deleteRoom"
                                    class="ma-0">
                                  <v-icon light size="18">delete</v-icon>
                                </v-btn>
                              </template>
                              <span class="text-no-wrap">Delete Room</span>
                            </v-tooltip>
                          </template>
                        </provider>
                      </template>

                      <div class="d-flex align-center pa-4 pb-2">
                        <div class="fade-2 fs-12">MEMBERS</div>
                        <v-spacer/>
                        <div class="fs-14 pr-2">
                          {{ memberCount }}
                        </div>
                        <div class="fade-2 fs-11">User(s)</div>
                      </div>

                      <template v-for="(participant) in room.participants">
                        <provider
                          :key="`provider_${participant.user_id}`"
                          v-if="!participant.creator && participant.status != 'suspended'"
                          :provider="getProvider(participant)"
                          class="pa-2 pb-4"
                          foregroundColor="white--text"
                          backgroundColor="ycmd-blue"                          
                          >
                          <v-spacer/>
                          <template v-if="isCreator">
                            <v-tooltip top
                                       nudge-top="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                    :ref="`participant_${ participant.user_id }`"
                                    icon depressed
                                    v-on="on"
                                    @click="removeParticipant(participant.user_id)"
                                    class="ma-0">
                                  <v-icon dark size="18">delete</v-icon>
                                </v-btn>
                              </template>
                              <span class="text-no-wrap">Remove Participant</span>
                            </v-tooltip>
                          </template>
                          <template v-if="participant.user_id === info.userID">
                            <v-tooltip top
                                       nudge-top="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                    icon depressed
                                    v-on="on"
                                    :loading="leaving"
                                    @click="leaveRoom"
                                    class="ma-0">
                                  <v-icon size="18">logout</v-icon>
                                </v-btn>
                              </template>
                              <span class="text-no-wrap">Leave Room</span>
                            </v-tooltip>
                          </template>
                        </provider>
                      </template>
                    </div>

                  </v-tab-item>

                  <v-tab-item>

                    <div class="d-flex align-center full-width white--text pl-4 pr-1 mb-4 ycmd-medium-blue"
                         style="border-bottom: solid 2px #125ea3;">
                      <v-text-field dark flat solo hide-details clearable
                                    v-model="roomName"
                                    :disabled="!isCreator || saving"
                                    prepend-icon="edit"
                                    background-color="transparent"
                                    class="txt-room-edit ml-1 mr-1"
                                    :label="`${ room.name ? 'Change' : 'Add' } Room Name...`"/>

                      <v-btn dark small full-width
                             @click="editRoom"
                             :loading="saving"
                             :disabled="!isCreator || saveDisabled"
                             color="ycmd-green"
                             elevation="0"
                             class="fs-10 mr-1">
                        Save
                      </v-btn>
                      <v-tooltip top
                                 nudge-top="5"
                                 :disabled="$vuetify.breakpoint.xsOnly"
                                 color="ycmd-black"
                                 content-class="fade-0 pa-4 pt-1 pb-2">
                        <template v-slot:activator="{ on }">
                          <v-btn
                              icon dark depressed
                              v-on="on"
                              :loading="loading"
                              class="ma-0 mr-2"
                              elevation="0" @click.stop="refreshRoom">
                            <v-icon dark size="18">refresh</v-icon>
                          </v-btn>
                        </template>
                        <span class="text-no-wrap">Refresh</span>
                      </v-tooltip>
                    </div>

                    <div v-if="room" class="d-flex flex-column pa-2">
                      <div class="d-flex align-center pb-4 pl-4 pr-4">
                        <div class="fade-2 fs-12">Room Name:</div>
                        <v-spacer/>
                        <div class="fade-2 fs-11">{{ room.name || 'None' }}</div>
                      </div>
                      <div class="d-flex align-center pa-4 pt-2">
                        <div class="fade-2 fs-12">Room Type:</div>
                        <v-spacer/>
                        <div class="fade-2 fs-11">
                          {{ room.room_type }}
                        </div>
                      </div>
                      <div class="d-flex align-center pa-4 pt-2">
                        <div class="fade-2 fs-12">Date Created:</div>
                        <v-spacer/>
                        <div class="fade-2 fs-11">
                          {{ formatDate(room.date_created) }}
                        </div>
                      </div>
                      <div class="d-flex align-center pa-4 pt-2">
                        <div class="fade-2 fs-12">Last Updated:</div>
                        <v-spacer/>
                        <div class="fade-2 fs-11">
                          {{ formatDate(room.date_updated) }}
                        </div>
                      </div>
                      <template v-if="room.tags && room.tags.length > 0">
                        <div class="d-flex flex-column pa-4 pt-2">
                          <div class="fade-2 fs-12 pb-4">Tags:</div>
                          <div class="d-flex">
                            <v-chip v-for="(tag, i) in room.tags"
                              :key="`${tag}_${i}`"
                                    dark small label
                                    class="ycmd-light-blue mr-2">
                              {{ tag }}
                            </v-chip>
                          </div>
                        </div>
                      </template>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </div>

            <div style="border-top: solid 2px #125ea3">
              <div class="pa-2 ycmd-dark-blue">
                <v-tabs dark centered elevation="0" v-model="tab" background-color="transparent">
                  <v-tabs-slider color="ycmd-light-green"></v-tabs-slider>
                  <v-tab class="fs-11">Members</v-tab>
                  <v-tab class="fs-11">Details</v-tab>
                </v-tabs>
              </div>
            </div>
          </div>
        </div>
      </i-card>
    </div>
  </div>
</template>

<script>

  import {format, utcToZonedTime} from "date-fns-tz";
  import {mapActions, mapState} from "vuex";

  import provider from "@/views/apps/chat/components/provider";

  import GET_CHAT_ROOM from "@/graphql/queries/phoenix/ycmdChatRoomGet";
  import EDIT_CHAT_ROOM from "@/graphql/mutations/phoenix/ycmdChatRoomEdit";
  import DELETE_CHAT_ROOM_PARTICIPANT from "@/graphql/mutations/phoenix/ycmdChatRoomParticipantRemove";
  import LEAVE_CHAT_ROOM from "@/graphql/mutations/phoenix/ycmdChatRoomLeave";
  import DELETE_CHAT_ROOM from "@/graphql/mutations/phoenix/ycmdChatRoomDelete";
  import oooIndicator from '@/views/apps/chat/components/ooo-indicator.vue';
  import appNotinstalledIndicator from '@/views/apps/chat/components/app-not-installed-indicator.vue';

  import dataClient from '@/graphql/clients/axios';
import Loading from '@/components/loading-elipsis';

  export default {
    name: 'chatEditList',
    props: {
      changeView: Function,
      params: {
          data: Object
      }
    },   
    components: {
      'provider': provider,
      loading: Loading,
      'ooo-indicator': oooIndicator,
      'app-not-installed-indicator': appNotinstalledIndicator

    },
    data() {
      return {
        format,
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        transition: 'fade',
        tab: null,
        room: null,
        loading: true,
        originalRoomName: '',
        roomName: '',
        saving: false,
        saveDisabled: true,
        leaving: false,
        deleting: false,
        removing: false,
        selectedId: null
      }
    },
    apollo: {
      $client: 'phoenixClient',
      /*
      ycmdChatRoomGet: {
        query: GET_CHAT_ROOM,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        skip: true,
        variables() {
          return {
            room_id: this.currentRoom.id,
            search_objects: ['room'],
            limit: 1
          }
        },
        result({data}) {

          if (data) {

            const {rooms} = data.ycmdChatRoomGet;

            this.room = rooms[0];
            this.originalRoomName = rooms[0].name
            this.roomName = this.originalRoomName;
          }

          this.loading = false;
        },
        error(e) {
          console.error('ERROR: In ycmdChatPostGet Query', e)
        }
        
      }*/
    },
    mounted() {

      // Only start this after we set the reactive room id
      //this.$apollo.queries.ycmdChatRoomGet.skip = false;
      this.$emit('initialized', 'chat-edit', this.event)
      console.log('this.params', this.params)
      this.loadRoom(this.params.room_id)

    },
    methods: {
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu',]),
      //...mapActions('chat', ['setCurrentRoomName']),
      async loadRoom (room_id) {
          this.loading = true

          let response = await dataClient('ycmdChatRoomGet', 
            {room_id: room_id,
            search_objects: ['room'],
            limit: 1          
            }
          )

          this.room = response.rooms[0];
          this.originalRoomName = this.room.name
          this.roomName = this.originalRoomName;
          this.loading = false
      },

      event (name, params) {
        console.log('name', name, params)
        switch (name) {
          case 'load':
            this.loadRoom(params.room_id)
            break
        }
      },      
      async refreshRoom() {

        await this.loadRoom(this.room.id);

      },
      async editRoom() {

        this.saving = true;

        const params = {
          room_id: this.room.id,
          name: this.roomName,

        };

        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: EDIT_CHAT_ROOM,
          variables: params,
          update: async (cache, { data: { ycmdChatRoomEdit } }) => {
            try {

              const { rooms } = ycmdChatRoomEdit;

              this.room = ycmdChatRoomEdit;

              this.originalRoomName = this.room.name
              this.roomName = this.originalRoomName;

              // Update the store with the new name
              /*
              this.$store.commit('chat/setCurrentRoomName',{
                id: this.room.id, name: this.room.name
              })
              */
              this.saving = false;
              this.saveDisabled = true;
            }
            catch (e) {
              console.error(e);
            }
          }
        });
      },
      async removeParticipant(user_id) {
        //this.removing = true;

        const params = {
          room_id: this.room.id,
          user_id: user_id,
        };

        /*
        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: DELETE_CHAT_ROOM_PARTICIPANT,
          variables: params,
          update: async (cache, { data: { ycmdChatRoomParticipantRemove } }) => {
            try {
              
              //this.room = ycmdChatRoomParticipantRemove;

              this.removing = false;
            }
            catch (e) {
              console.error(e);
            }
          }
        });
        */
        let response = await dataClient('ycmdChatRoomParticipantRemove', 
          params
        )

        this.room = response

      },
      async deleteRoom() {

        this.deleting = true;

        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: DELETE_CHAT_ROOM,
          variables: {
            room_id: this.room.id
          },
          update: async (cache, { data: { ycmdChatRoomDelete: { status }} }) => {
            try {

              if(status === 'success')
                this.changeView({view: 'chat-rooms', transition: 'prev'})
            }
            catch (e) {
              console.error(e);
            }

            this.deleting = false;
          }
        });
      },
      async leaveRoom() {

        this.leaving = true;

        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: LEAVE_CHAT_ROOM,
          variables: {
            room_id: this.room.id
          },
          update: async (cache, { data: { ycmdChatRoomLeave: { status }} }) => {
            try {

              if(status === 'success')
                this.changeView({view: 'chat-rooms', transition: 'prev', params: {left_room_id: this.room.id}})
            }
            catch (e) {
              console.error(e);
            }

            this.leaving = false;
          }
        });
      },
      getProvider({ first_name, last_name, title, specialty, group_name, online_status, ooo }) {
        console.log('provider first_name', first_name)
        return {
          first_name, last_name, title, specialty, group_name, online_status, ooo
        }
      },
      formatDate(date) {

        if (date) {
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const localDate = utcToZonedTime(date, timeZone);

          return format(localDate, 'MM-dd-yyyy h:mm a', { timeZone })
        }
        else
          return 'NA'
      },
    },
    computed: {
      ...mapState('rightMenu', ['isRightMenuExpanded']),
      //...mapState('chat', ['currentRoom']),
      ...mapState('profile', ['info']),
      isCreator: function() {

        return this.room.created_by_id === this.info.userID;
      },
      creatorCount: function() {

        if(this.room && this.room.participants.length > 0)
          return this.room.participants.filter(p => p.creator).length;
        else
          return 0;
      },
      memberCount: function() {

        if(this.room && this.room.participants.length > 0)
          return this.room.participants.filter(p => !p.creator).length;
        else
          return 0;
      }
    },
    watch: {
      roomName (name) {

        if (this.originalRoomName !== name) {
          this.saveDisabled = !(name && name.length > 0);
        }
        else
          this.saveDisabled = true;
      }
    }
  }
</script>

<style>

  .txt-room-edit .v-icon {
    font-size: 18px !important;
    opacity: 0.7;
  }

</style>