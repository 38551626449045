<template>

  <div class="d-flex">
    <div class="d-flex flex-column justify-center full-width">
      <div class="d-flex pt-2 pb-0" style="margin-left: 64px">
        <div v-if="showUsername" class="fade-2 fs-11">{{ post.first_name }} {{ post.last_name }}</div>
        <div v-else class="fade-2 fs-10">They wrote</div>
        <v-spacer />
        <div class="fade-2 fs-10 pb-2">
          {{ getTime({ start_date: post.date_created}) }}
        </div>
      </div>
      <div class="d-flex full-width">
        <app-not-installed-indicator v-if="appVersion && appVersion=='notinstalled'"/>      

        <ooo-indicator 
          v-else-if="isOOO"
        />
        <div v-if="showAvatar" class="d-flex align-center justify-center ycmd-medium-gray mr-1 white--text fw-500"
             style="min-width: 42px; height: 42px; border-radius: 50%">
          {{ getUserInitials(post.first_name, post.last_name) }}
        </div>
        <div class="d-flex flex-grow-1 ycmd-medium-gray fw-400 white--text br-3 fs-12 talk-bubble tri-right left-top">
          <div v-html="formatPost(post)" class="d-flex flex-grow-1 pa-3" style="word-break: break-word" />
          <div class="text-right pt-1">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import oooIndicator from '@/views/apps/chat/components/ooo-indicator.vue';
import appNotinstalledIndicator from '@/views/apps/chat/components/app-not-installed-indicator.vue';

import {format, utcToZonedTime} from "date-fns-tz";
import {mapGetters} from "vuex";


export default {
  props: {
    post: Object,
    showAvatar: {
      type: Boolean,
      default: true
    },
    showUsername: {
      type: Boolean,
      default: true
    },
    exactTimestamps: Boolean
  },
  data: () => ({
    format
  }),
  methods: {
    getUserInitials(first_name, last_name) {

      if(first_name && last_name)
        return first_name.charAt(0) + last_name.charAt(0);
      else
        return 'NA'
    },
    getTime({ start_date, exact = false }) {

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localDate = utcToZonedTime(start_date, timeZone);

      if(this.exactTimestamps)
        exact = this.exactTimestamps;

      if(!exact)
        return format(localDate, 'h:mm a', { timeZone })
      else
        return format(localDate, 'MM-dd-yyyy h:mm a', { timeZone })
    },
    formatPost(post) {

      let text = post.text

      if ( (post.key && post.key.startsWith('ooo_')) || post.systenType == 'ooo') {
        text += '\n(Automated reply)'
      }
      return text.replace(/\n/g,"<br/>")
    }
  },
  components: {
    'ooo-indicator': oooIndicator,
    'app-not-installed-indicator': appNotinstalledIndicator
  },
  computed: {
    appVersion () {
      console.log('this.$store', this.$store)

      let participant = this.$store.getters['chat/getCurrentRoomProvider'](this.post.user_id)
      return participant ? participant.app_version : null
    },
    isOOO () {
      console.log('this.$store', this.$store)

      let participant = this.$store.getters['chat/getCurrentRoomProvider'](this.post.user_id)
      return participant ? participant.ooo : false
    }

  }
}
</script>
