import gql from 'graphql-tag'

export default gql`mutation pcpImportProviderUpdate($email: String!, $first_name: String, $last_name: String, $phone: String, $license_number: String, $reference_id: String, $new_email: String ) {
	pcpImportProviderUpdate(email: $email, first_name: $first_name, last_name: $last_name, phone: $phone, license_number: $license_number, reference_id: $reference_id, new_email: $new_email) {
    id
		first_name
		last_name
		email
		title
		group_name
		phone
		fax
		specialty
		license_number
		status
		source
		date_created
		reference_id
		phone
  }
}`
