<template>
  <v-dialog
      v-model="value"
      persistent
      max-width="290"
      >
      <v-card>
          <v-card-title class="text-h5">
              Reply to: 
          </v-card-title>
          <v-card-subtitle>
              {{voicemail.spelled_first_name}} {{voicemail.spelled_last_name}}
          </v-card-subtitle>
          <v-card-subtitle>
              Send text message to {{voicemail.callback_phone}}
          </v-card-subtitle>

          <v-card-text>
              <v-select light dense flat outlined
                        v-model="sendOption"
                        label="Send option"
                        :items="sendOptions"
                        item-text="name"
                        item-value="value"
                        background-color="white"/>


              <v-textarea
                  counter
                  label="Reply"
                  v-model="smsReply"
                  rows="3"
                  :maxlength="1000"
                  background-color="grey lighten-3"
              ></v-textarea>

          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="sendSMS"
              :disabled="!!!smsReply || sending"
          >
              Send
          </v-btn>
          <v-btn
              text
              @click="closeDialog()"
          >
              Cancel
          </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
  import MUTATE_VOICEMAIL_STATUS from '@/graphql/mutations/phoenix/ycmdVoicemailUpdateStatus';

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      voicemail: {
        default: () => {return {}},
        type: Object
      }
    },
    data: () => ({
      smsReply: '',
      sending: false,
      sendOption: 'send',
      sendOptions: [
        {name: 'Send SMS', value: 'send'},
        {name: 'Send SMS and Archive', value: 'archive'}
      ],
    }),
    mounted() {
    },
    methods: {
      closeDialog() {
        this.smsReply = ''
        this.sending = false
        this.$emit('input', false)
      },
      async sendSMS() {
        let variables = {
          voicemail_id: this.voicemail.contact_id,
          sms_number: this.voicemail.callback_phone,
          sms_message: this.smsReply
        }
        if (this.sendOption === 'archive') {
          variables.status = "complete"
        }

        this.sending = true
        let response = await this.$apollo.mutate({
            mutation: MUTATE_VOICEMAIL_STATUS,
            variables: variables,
            client: 'phoenixClient',
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
        });
        this.$emit('changed', response && response.data ? response.data.ycmdVoicemailUpdateStatus : null )
/*
        if (this.sendOption === 'archive' && response && response.data) {

          response = await this.$apollo.mutate({
              mutation: MUTATE_VOICEMAIL_STATUS,
              variables: {
                  voicemail_id: this.voicemail.contact_id,
                  status: 'complete'
              },
              client: 'phoenixClient',
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
          });

          this.$emit('changed', response && response.data ? response.data.ycmdVoicemailUpdateStatus : null )
        }
*/

        this.closeDialog()
      },
    },
    computed: {
    }
  }
</script>

