// Initial state
const state = {
  pcpData: {

  }
};

const getters = {
  getPcpData: state => state.pcpData
};

const actions = {

};

const mutations = {

  setPcpData (state, data) {
    state.pcpData = data;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
