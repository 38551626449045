<template>

    <div class="view ycmd-blue-gradient">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="false"
                     :background-color="`#101622`"
                     :color="`white`"
                     :loader="`bars`">
            </loading>

            <div class="i-card__main pa-2">

                <div class="i-card__main__header">
                    <div class="d-flex align-center flex-grow-1 ml-1">

                        <v-tooltip bottom
                                   nudge-right="74"
                                   nudge-bottom="7"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn dark icon depressed v-on="on"
                                       @click="() => { changeView('list-view', { data: { user: savedData } }); onCancelEdit(); }">
                                    <v-icon :size="22">arrow_back</v-icon>
                                </v-btn>
                            </template>
                            <span>Back To Manage Users</span>
                        </v-tooltip>
                        <div class="white--text fs-18 ml-4">
                            User Details
                        </div>
                    </div>
                    <div class="d-flex align-center justify-end ml-2 mr-2">

                        <!-- <v-tooltip bottom
                                   nudge-bottom="10"
                                   nudge-right="36"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   color="ycmd-black"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark depressed v-on="on" @click="slide('right')" class="ml-2 mr-2">
                                    <v-icon :size="22">bar_chart</v-icon>
                                </v-btn>
                            </template>
                            <span>Show History</span>
                        </v-tooltip> -->
                    </div>
                </div>

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable pr-1">

                        <div class="d-flex align-center">

                            <div>
                                <!-- <template v-if="!isEditable">
                                    <v-tooltip left
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon light @click="isEditable = true"
                                                   v-on="on" color="ycmd-green" class="mr-3">
                                                <v-icon :size="22">edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>
                                </template> -->
                                <!-- <div v-else style="display: flex; flex-direction: row;">
                                    <v-tooltip left
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon light @click="onCancelEdit"
                                                   v-on="on" color="red" class="mr-3">
                                                <v-icon :size="22">cancel</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Cancel</span>
                                    </v-tooltip>
                                    <v-tooltip left
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon light @click="onSubmitEdit"
                                                   v-on="on" color="ycmd-green" class="mr-3">
                                                <v-icon :size="22">save</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Save</span>
                                    </v-tooltip>
                                </div> -->
                            </div>
                            <v-container fluid>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="6">
                                        <div class="d-flex align-center fs-16 fw-500">{{ params.data.user.first_name || '' }} {{ params.data.user.last_name || '' }}</div>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <div :class="`d-flex align-center ${ $vuetify.breakpoint.xsOnly ? 'fs-12 pt-2' : 'fs-14 justify-end' }`">
                                            <div class="fw-500 pr-3">ID:</div>
                                            {{ params.data.user.id || 'Not Provided' }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <v-container fluid class="ycmd-light-gray full-height b b-1 pa-0 pl-4 pr-4 br-2">
                          <v-form
                                  ref="updateForm"
                                  v-model="validForm"
                                  :lazy-validation="false"
                                  class="d-flex flex-column full-height">
                              <div class="full-width">
                                  <v-row>
                                      <v-col cols="6" md="3">
                                          <div class="label-title pt-2">First Name</div>
                                          <div v-if="isEditable">
                                              <v-text-field light solo dense flat outlined
                                                            name="input-firstname"
                                                            :value="first_name || 'Not Provided'" v-model="first_name"
                                                            class="pt-2" :rules="[rules.minNameLength]" />
                                          </div>
                                          <div v-else class="label-value pt-2 pb-4">
                                              {{ first_name || 'Not Provided'}}
                                          </div>
                                      </v-col>
                                      <v-col cols="6" md="3">
                                          <div class="label-title pt-2">Last Name</div>
                                          <div v-if="isEditable">
                                              <v-text-field light solo dense flat outlined
                                                            name="input-lastname"
                                                            :value="last_name || 'Not Provided'" v-model="last_name"
                                                            class="pt-2" :rules="[rules.minNameLength]" />
                                          </div>
                                          <div v-else class="label-value pt-2 pb-4">
                                              {{ last_name || 'Not Provided'}}
                                          </div>
                                      </v-col>
                                      <v-col cols="6" md="3">
                                          <div class="label-title pt-2">Mobile</div>
                                          <div v-if="isEditable">
                                              <v-text-field light solo dense flat outlined
                                                            name="input-lastname"
                                                            :value="phone || 'Not Provided'" v-model="phone"
                                                            class="pt-2" :rules="[rules.phone]" />
                                          </div>
                                          <div v-else class="label-value pt-2 pb-4">
                                              {{ phone || 'Not Provided'}}
                                          </div>
                                      </v-col>
                                      <v-col cols="6" md="3">
                                          <div class="label-title pt-2">Email</div>
                                          <div class="label-value pt-4 pb-4">
                                              {{ email || 'Not Provided'}}
                                          </div>
                                      </v-col>
                                  </v-row>
                              </div>
                              <div class="full-width">
                                  <v-row>
                                      <v-col cols="6" sm="3">
                                          <div class="label-title pt-2 pb-2">Role</div>
                                          <v-select v-if="isEditable"
                                                    light solo dense flat outlined
                                                    :items="rolesCanSelect"
                                                    :rules="[rules.required]"
                                                    v-model="roleSelected"
                                                    label="Role"
                                                    append-icon="expand_more"/>
                                          <div class="label-value" v-else>{{ roleSelected }}</div>
                                      </v-col>

                                      <v-col  v-if="this.roleSelected === 'Facility Admin'" cols="6" sm="9">
                                          <div class="label-title pt-2 pb-2">Facilities Selected</div>
                                          <v-select v-if="isEditable"
                                                    light solo dense flat outlined
                                                    v-model="myFacilitiesSelected"
                                                    :items="myFacilities"
                                                    :menu-props="{ maxHeight: '400' }"
                                                    label="Add Facilities"
                                                    multiple
                                                    item-text="name"
                                                    return-object/>
                                          <div class="label-value" v-else>{{ myFacilitiesSelected.map((facility) => facility.name).join(', ') }}</div>
                                      </v-col>

                                      <v-col  v-if="this.roleSelected === 'Clinical Analyst'" cols="6" sm="9">
                                          <div class="label-title pt-2 pb-2">Facilities</div>
                                          <v-select v-if="isEditable"
                                                    light solo dense flat outlined
                                                    v-model="myFacilitiesAnalystSelected"
                                                    :items="myFacilities"
                                                    :menu-props="{ maxHeight: '400' }"
                                                    label="Add Facilities"
                                                    multiple
                                                    item-text="name"
                                                    return-object/>
                                          <div class="label-value" v-else>{{ myFacilitiesAnalystSelected.map((facility) => facility.name).join(', ') }}</div>
                                      </v-col>

                                      <v-col v-if="this.roleSelected === 'Group Admin'" cols="6" sm="9">
                                          <div class="label-title pt-2 pb-2">Groups</div>
                                          <div>
                                              <v-select v-if="isEditable"
                                                        light solo dense flat outlined
                                                        v-model="myGroupSelected"
                                                        :items="myGroup"
                                                        :menu-props="{ maxHeight: '400' }"
                                                        label="Add Groups"
                                                        item-text="name"
                                                        return-object/>
                                              <div class="label-value" v-else>{{ myGroupSelected.name }}</div>
                                          </div>
                                      </v-col>

                                  </v-row>

                                  <v-row class="pl-2">
                                      <div v-if="this.changeRoleError" class="fs-10" style="padding: 0px; padding-left: 18px; padding-right: 18px; margin-bottom: 10px; color: red;">
                                          {{this.changeRoleError}}
                                      </div>
                                      <div v-if="this.changeRoleSuccess" class="fs-10" style="padding: 0px; padding-left: 18px; padding-right: 18px; margin-bottom: 10px; color: green;">
                                          {{this.changeRoleSuccess}}
                                      </div>
                                  </v-row>
                              </div>
                              <v-row no-gutters class="d-flex align-end flex-grow-1 pb-4" >
                                  <v-col cols="12" class="d-flex justify-end">
                                      <v-btn dark depressed color="#177AD5" @click="changeRoleValidation();">{{isEditable ? 'Save' : 'Edit'}}</v-btn>
                                  </v-col>
                              </v-row>
                          </v-form>
                        </v-container>
                    </div>
                </div>
            </div>

            <template slot="right-panel">

                <div class="i-card__panel i-card__panel__right ycmd-blue-gradient pa-2">

                    <div class="i-card__panel__header">

                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="30"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark depressed v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                        <v-icon :size="22">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Close Menu</span>
                            </v-tooltip>

                            <div class="white--text fs-18 ml-3">
                                Provider Stats
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2"></div>
                    </div>

                    <div class="i-card__panel__content white br-2 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">
                                Content
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </i-card>

        <v-dialog v-model="dialog" max-width="330" style="">

          <loading :active.sync="isLoading"
                   :can-cancel="false"
                   :is-full-page="false"
                   :background-color="`#101622`"
                   :color="`white`"
                   :loader="`bars`">
          </loading>

        </v-dialog>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import GET_MESSAGES from '../../../../graphql/queries/pcpMessages';
    import GET_FACILITIES from '../../../../graphql/queries/pcpGetFacilities';
    import GET_GROUPS from '../../../../graphql/queries/pcpGetGroups';
    import SAVE_FIELDS from '../../../../graphql/mutations/pcpImportProviderUpdate';

//    import REMOVE_ROLE from '../../../../graphql/mutations/adminRemoveRole';
//    import ADD_ROLE from '../../../../graphql/mutations/adminAddRole';
    import CHANGE_ROLE from '../../../../graphql/mutations/adminChangeRole';

    // import hardcoded facilities
    import { hardCodedFacilities, hardCodedGroups } from '@/data/pcpAppAdmin/hardCodedFacilities.js';

    export default {
        name: 'manageUserDetails',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                format,
                parseISO,
                dialog: false,
                currentDialog: '',
                validAddRole: false,
                userID: localStorage.getItem("userID"),
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    minNameLength: v => v.length >= 1 || 'Min 1 character',
                    email: value => {
                      const pattern = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
                      return pattern.test(value) || 'Invalid e-mail';
                    },
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    }
                },
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                validForm: false,
                isInternalAdmin: false,
                isFacilityAdmin: false,
                isGroupAdmin: false,
                isAnalyst: false,

                isLoading: false,
                isEditable: false,
                message: {},
                savedData: {},

                rolesTest: ['test1', 'test2'],

                rolesCurrent: [],

                addRoleSuccess: '',
                addRoleError: '',
                roleSelected: '',

                removedRole: '',
                addedRole: '',

                rolesParsed: {
                    'PCP Admin': 'pcp_admin',
                    'Group Admin': 'pcp_group_admin',
                    'Facility Admin': 'pcp_facility_admin',
                    'Clinical Analyst': 'pcp_analyst',
                },
                rolesOriginalParsed: {
                    'pcp_admin': 'PCP Admin',
                    'pcp_group_admin': 'Group Admin',
                    'pcp_facility_admin': 'Facility Admin',
                    'pcp_analyst': 'Clinical Analyst'
                },
                userRoles: [],
                rolesCanSelect: [],

                isLoadingSearch: false,
                searchFacility: null,

                facilitiesSearched: [],
                selectedFacilitiesSearched: '',
                selectedFacilitiesSearchedAll: [],

                groupsSearched: [],
                selectedGroupsSearched: '',
                selectedGroupsSearchedAll: [],

                myGroup: [],
                myGroupSelected: {},
                myFacilities: [],
                myFacilitiesSelected: [],
                myFacilitiesAnalystSelected: [],
                canChangeRole: true,

                searchGroup: null,
                changeRoleError: '',
                changeRoleSuccess: '',

                saveFieldsError: '',

                phone: null,
                email: null,
                last_name: null,
                first_name: null
            }
        },
        activated() {
          this.setDataFromParams();
          this.createRolePermissions();
        },
        mounted() {
          this.setDataFromParams();
          this.createRolePermissions();
        },
        computed: {
          rolesCanSelectTest: function () {
            return this.message.split('').reverse().join('')
          }
        },
        watch: {
            phone(val) {
              // console.log('new phone val: ', val);
            },
            searchFacility(val) {
              this.getFacilities(val);
            },
            searchGroup(val) {
              this.getGroups(val);
            }
        },
        methods: {
            createRolePermissions() {

              this.userRoles = JSON.parse(localStorage.getItem('roles'));
              for (let role in this.userRoles) {
                const currentRoleObj = this.userRoles[role];
              }

              this.rolesCanSelect = this.rolesCanCreate();
              if (this.rolesCanSelect && this.rolesCanSelect.length) {
                this.roleSelected = this.rolesCurrent[0];
              }
              // console.log('roles?: ', this.roles);

              for (let role in this.userRoles) {
                const currentRoleObj = this.userRoles[role];

                // check if the same role exists for the user you want to edit
                if (this.params.data.user.roles.includes(currentRoleObj.role)) { this.canChangeRole = false; };

                // parse for facilities to add here
                if (currentRoleObj.role === 'pcp_admin') {
                  this.myFacilities = hardCodedFacilities;
                  this.myGroup = hardCodedGroups;
                } else if (currentRoleObj.role === 'pcp_facility_admin') {
                  if (currentRoleObj.facilities && currentRoleObj.facilities.length) {
                    this.myFacilities = currentRoleObj.facilities;
                  }
                } else if (currentRoleObj.role === 'pcp_group_admin') {
                  this.myFacilities = hardCodedFacilities;
                  this.myGroup = [currentRoleObj.group];
                  // this.myGroupSelected = currentRoleObj.groups[0];
                }
              }
            },
            updateFieldsValidation() {
              if (this.$refs.updateForm.validate()) {
                  return true;
              } else {
                  return false;
              }
            },
            async changeRoleValidation() {
              if (!this.isEditable) { this.isEditable = true; return; };
              // TODO: add validation check for roles possibly?
              if (this.updateFieldsValidation()) {
                  if (this.checkRoleChange()) {
                    const changeRoleResult = await this.changeRole();
                    if (changeRoleResult) {
                      await this.saveChanges();
                    }
                  } else {
                    await this.saveChanges();
                  }

                  this.isEditable = false;
              }
            },
            clearFacilitySearch() {
                this.facilitiesSearched = [];
                this.selectedFacilitiesSearched = '';
                this.selectedFacilitiesSearchedAll = [];
                this.searchFacility = '';
                this.facilitySelected = '';
            },
            clearGroupSearch() {
                this.groupsSearched = [];
                this.selectedGroupsSearched = '';
                this.selectedGroupsSearchedAll = [];
                this.searchGroup = '';
            },
            clearChangeRole() {
                // on successful change, clear everything except new submitted value
                // set overwrite
                let overwrite = {
                  name: null,
                  value: null
                }
                switch(this.roleSelected) {
                  case 'PCP Admin':

                  break;
                  case 'Group Admin':
                    overwrite.name = 'myGroupSelected';
                    overwrite.value = this.myGroupSelected;
                  break;
                  case 'Facility Admin':
                    overwrite.name = 'myFacilitiesSelected';
                    overwrite.value = this.myFacilitiesSelected;
                  break;
                  case 'Clinical Analyst':
                    overwrite.name = 'myFacilitiesAnalystSelected';
                    overwrite.value = this.myFacilitiesAnalystSelected;
                  break;
                }

                this.myGroupSelected = {};
                this.myFacilitiesSelected = [];
                this.myFacilitiesAnalystSelected = [];

                if (overwrite.name && overwrite.value) {
                  this[overwrite.name] = overwrite.value;
                }
            },
            clearCreateUserDialog() {
                this.isLoadingSearch = false;
                this.changeRoleError = '';
                this.changeRoleSuccess = '';
                this.roleSelected = '';

                this.clearFacilitySearch();
                this.clearGroupSearch();
            },
            addFacilityItem() {
              const currentFac = this.selectedFacilitiesSearched;
              if (!currentFac) return;
              for (let facility in this.selectedFacilitiesSearchedAll) {
                if (this.selectedFacilitiesSearchedAll[facility].name === this.selectedFacilitiesSearched.name) {
                  return;
                }
              }
              const addObj = {
                name: currentFac.name,
                displayName: this.text_truncate(currentFac.name, 18, '...'),
                id: currentFac.id
              }
              this.selectedFacilitiesSearchedAll.push(addObj);

              this.selectedFacilitiesSearched = '';
              this.facilitiesSearched = [];
              this.searchFacility = '';
            },
            addGroupItem() {
              const currentGroup = this.selectedGroupsSearched;
              if (!currentGroup) return;
              if (this.selectedGroupsSearchedAll.length >= 1) return;
              for (let group in this.selectedGroupsSearchedAll) {
                if (this.selectedGroupsSearchedAll[group].name === this.selectedGroupsSearched.name) {
                  return;
                }
              }
              const addObj = {
                name: currentGroup.name,
                displayName: this.text_truncate(currentGroup.name, 18, '...'),
                id: currentGroup.id
              }
              this.selectedGroupsSearchedAll.push(addObj);

              this.selectedGroupsSearched = '';
              this.groupsSearched = [];
              this.searchGroup = '';
            },
            text_truncate(str, length, ending) {
              if (length == null) {
                length = 100;
              }
              if (ending == null) {
                ending = '...';
              }
              if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
              } else {
                return str;
              }
            },
            async getFacilities(val) {
              this.isLoadingSearch = true;
              try {
                const response = await this.$apollo.query({
                    query: GET_FACILITIES,
                    variables: {
                        name: val,
                        limit: 10
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                this.isLoadingSearch = false;
                if (response.data && response.data.pcpGetFacilities) {
                  this.facilitiesSearched = response.data.pcpGetFacilities;
                  return response.data.pcpGetFacilities;
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            async getGroups(val) {
              this.isLoadingSearch = true;
              try {
                const response = await this.$apollo.query({
                    query: GET_GROUPS,
                    variables: {
                        name: val,
                        limit: 10
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                this.isLoadingSearch = false;
                if (response.data && response.data.pcpGetGroups) {
                  this.groupsSearched = response.data.pcpGetGroups;
                  return response.data.pcpGetGroups;
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            rolesCanCreate() {
              for (let role in this.userRoles) {
                const currentRoleObj = this.userRoles[role];
                if (currentRoleObj.role === 'pcp_admin') {
                  return ['PCP Admin', 'Group Admin', 'Facility Admin', 'Clinical Analyst'];
                } else if (currentRoleObj.role === 'pcp_group_admin') {
                  return ['Group Admin', 'Facility Admin', 'Clinical Analyst'];
                } else if (currentRoleObj.role === 'pcp_facility_admin') {
                  return ['Facility Admin', 'Clinical Analyst'];
                }
              }
            },
            clearDialog() {
              this.dialog = false;
              this.currentDialog = '';
            },
            onSubmitEdit() {
              if (!this.updateFieldsValidation()) return;
              this.isEditable = false;
              this.saveChanges();
            },
            setDataFromParams() {
              // console.log('params: ', this.params.data.user);
              this.roles = this.params.data.user.roles || [];

              this.rolesCurrent = this.params.data.user.roles ? this.params.data.user.roles.map((role) => this.rolesOriginalParsed[role]) : [];
              this.roleSelected = this.rolesCurrent[0];

              this.phone = this.params.data.user.phone || null;
              this.email = this.params.data.user.email || null;
              this.last_name = this.params.data.user.last_name || null;
              this.first_name = this.params.data.user.first_name || null;
              this.license_number = this.params.data.user.license_number || null;
              this.reference_id = this.params.data.user.reference_id || null;

              // reset
              this.isInternalAdmin = false;
              this.isGroupAdmin = false;
              this.isFacilityAdmin = false;
              this.isAnalyst = false;

              this.myGroupSelected = {};
              this.myFacilitiesSelected = [];
              this.myFacilitiesAnalystSelected = [];

              // check user you are editing roles
              for (let role in this.roles) {
                const currentRole = this.roles[role];
                switch (currentRole) {
                  case 'pcp_admin':
                      this.isInternalAdmin = true;
                  break;
                  case 'pcp_group_admin':
                      this.isGroupAdmin = true;
                      if (this.params.data.user.groups && this.params.data.user.groups.length) this.myGroupSelected = this.params.data.user.groups[0];
                  break;
                  case 'pcp_facility_admin':
                      this.isFacilityAdmin = true;
                      if (this.params.data.user.facilities && this.params.data.user.facilities.length) this.myFacilitiesSelected = this.params.data.user.facilities;
                  break;
                  case 'pcp_analyst':
                  // console.log('IS ANALYST');
                      this.isAnalyst = true;
                      if (this.params.data.user.facilities && this.params.data.user.facilities.length) this.myFacilitiesAnalystSelected = this.params.data.user.facilities;
                      // console.log('myFacilitiesAnalystSelected: ', this.myFacilitiesAnalystSelected);
                  break;
                }
              }

            },
            setParamsOnSuccess() {
              this.phone = this.savedData.phone;
              this.params.data.user.phone = this.phone;

              this.params.data.user.email = this.email;
              this.params.data.user.last_name = this.last_name;
              this.params.data.user.first_name = this.first_name;
              this.params.data.user.license_number = this.license_number;
              this.params.data.user.reference_id = this.reference_id;
            },
            setParamsRoleOnSuccess() {
              // clear everything but new value
              this.clearChangeRole();
              // send new facilities back up to parent
              if (this.roleSelected.includes('Clinical Analyst')) { this.params.data.user.facilities = this.myFacilitiesAnalystSelected; };
              if (this.roleSelected.includes('Facility Admin')) { this.params.data.user.facilities = this.myFacilitiesSelected; };
              if (this.roleSelected.includes('Group Admin')) { this.params.data.user.groups = [this.myGroupSelected]; };
              // set role
              if (this.roleSelected) { this.params.data.user.roles = [this.rolesParsed[this.roleSelected]] }; // set role at parent level
              this.rolesCurrent = this.params.data.user.roles ? this.params.data.user.roles.map((role) => this.rolesOriginalParsed[role]) : []; // set role at current level
            },
            setParamsRolesOnFail() {
              this.setDataFromParams();
            },
            setParamsOnFail() {
              this.phone = this.params.data.user.phone || null;
              this.email = this.params.data.user.email || null;
              this.last_name = this.params.data.user.last_name || null;
              this.first_name = this.params.data.user.first_name || null;
              this.license_number = this.params.data.user.license_number || null;
              this.reference_id = this.params.data.user.reference_id || null;
            },
            onCancelEdit() {
              this.setDataFromParams();
              this.isEditable = false;
            },
            checkValidValues() {

            },
            checkFacilitiesSubmit() {
              // console.log(this.roleSelected);
              switch(this.roleSelected) {
                case 'Facility Admin':
                  // console.log('selected facilities: ', this.myFacilitiesSelected.map((facility) => facility.id));
                  return this.myFacilitiesSelected.map((facility) => facility.id);
                break;
                case 'Clinical Analyst':
                  // console.log('selected facilities analyst: ', this.myFacilitiesAnalystSelected.map((facility) => facility.id));
                  return this.myFacilitiesAnalystSelected.map((facility) => facility.id);
                break;
                default:
                  return null;
              }
            },
            checkGroupSubmit() {
              switch(this.roleSelected) {
                case 'Group Admin':
                  return this.myGroupSelected.id;
                break;
                default:
                  return null;
              }
            },
            checkRoleChange() {
              // check if role was changed
              if (this.roleSelected !== this.rolesCurrent[0]) {
                return true;
              }
              // check if specifics on role changed
              switch(this.roleSelected) {
                case 'PCP Admin':
                  // can't change anything in this section, skip..
                break;
                case 'Group Admin':
                  // check if the group selected changed
                  if (JSON.stringify(this.myGroupSelected) !== JSON.stringify(this.params.data.user.groups[0])) { return true }
                break;
                case 'Facility Admin':
                  // check if facilities selected changed
                  if (JSON.stringify(this.myFacilitiesSelected) !== JSON.stringify(this.params.data.user.facilities)) { return true }
                break;
                case 'Clinical Analyst':
                  // check if facilities for clinical analysts changed
                  if (JSON.stringify(this.myFacilitiesAnalystSelected) !== JSON.stringify(this.params.data.user.facilities)) { return true }
                break;
              }
            },
            async changeRole() {
                this.isLoading = true;
                try {
                    //console.log(this.searchFilterSelected, this.searchValue);
                    // console.log('CHANGING ROLE TO: ', this.roleSelected);
                    // console.log('FACILITIES: ', this.myFacilitiesSelected);
                    const response = await this.$apollo.mutate({
                        mutation: CHANGE_ROLE,
                        variables: {
                          email: this.email || null,
                          role: this.rolesParsed[this.roleSelected] || null,
                          group_id: this.checkGroupSubmit(),
                          facility_ids: this.checkFacilitiesSubmit()
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    // console.log('adminChangeRole - response for adminChangeRole: ', response);
                    // set overwrite

                    if (response.data.adminChangeRole) {
                        this.setParamsRoleOnSuccess();
                        return true;
                        // this.$toasted.success(`Successfully updated role!`);
                    } else if (response.errors && response.errors.length > 0) {
                        this.setParamsRolesOnFail();
                        if (response.errors[0].message) {
                            this.saveFieldsError = response.errors[0].message;
                            this.$toasted.error(`There was an error updating this provider's role: ${response.errors[0].message}`);
                            // this.$toasted.error(response.errors[0].message);
                        } else {
                            this.saveFieldsError = "Error 3A. Invalid response.";
                        }
                        return false;
                    } else {
                        // data did not come back
                        this.setParamsRolesOnFail();
                        // console.log('adminChangeRole data did not come back: ', response);
                        this.$toasted.error(`There was an error updating this provider`);
                        return false;
                    }
                } catch (e) {
                    this.setParamsOnFail();
                    this.isLoading = false;
                    defaultErrorHandler(e);
                    return false;
                }
            },
            async saveChanges() {
                this.isLoading = true;
                try {
                    //console.log(this.searchFilterSelected, this.searchValue);
                    const response = await this.$apollo.mutate({
                        mutation: SAVE_FIELDS,
                        variables: {
                          first_name: this.first_name,
                          last_name: this.last_name,
                          email: this.email,
                          phone: this.phone
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    // console.log('pcpImportProviderUpdate - response for pcpImportProviderUpdate: ', response);

                    if (response.data.pcpImportProviderUpdate) {
                        // emit up to admin level the name change if you changed value for yourself
                        if (this.params.data.user.user_id === this.userID) {
                          if (this.last_name && this.first_name) {
                            this.$store.commit('profile/setMyInfo', {
                              firstName: this.first_name,
                              lastName: this.last_name
                            });
                          }
                        }

                        this.savedData = response.data.pcpImportProviderUpdate;
                        this.setParamsOnSuccess();
                        this.$toasted.success(`Successfully updated provider!`);
                    } else if (response.errors && response.errors.length > 0) {
                        this.setParamsOnFail();
                        if (response.errors[0].message) {
                            this.saveFieldsError = response.errors[0].message;
                            this.$toasted.error(`There was an error updating this provider: ${response.errors[0].message}`);
                            // this.$toasted.error(response.errors[0].message);
                        } else {
                            this.saveFieldsError = "Error 3A. Invalid response.";
                        }
                    } else {
                        // data did not come back
                        this.setParamsOnFail();
                        // console.log('pcpImportProviderUpdate data did not come back: ', response);
                        this.$toasted.error(`There was an error updating this provider`);
                    }
                } catch (e) {
                    this.setParamsOnFail();
                    this.isLoading = false;
                    defaultErrorHandler(e);
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
