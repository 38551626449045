import gql from 'graphql-tag'

export default gql`
    subscription onGroupActivity($group_id: ID){
      onGroupActivity(group_id: $group_id) {

        activity {
          data
          group_id
          ids
          type
        }

      }
    }`
