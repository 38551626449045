import gql from 'graphql-tag'

export default gql`query ycmdMessageFields($to_user_id: ID!, $nurse_name: String, $patient_status: PatientStatus, $patient_id: ID, $patient_fname: String, $patient_lname: String, $patient_dob: String, $facility_id: ID, $unit_shortcode: ID, $callback: String, $extension: String, $direct_admit: Boolean, $message_answers: [IdValueInput]) {
	ycmdMessageFields(to_user_id: $to_user_id, nurse_name: $nurse_name, patient_status: $patient_status, patient_id: $patient_id, patient_fname: $patient_fname, patient_lname: $patient_lname, patient_dob: $patient_dob, facility_id: $facility_id, unit_shortcode: $unit_shortcode, callback: $callback, extension: $extension, direct_admit: $direct_admit, message_answers: $message_answers) {
		fields {
			id
			date_deleted
			default_value
			deleted
			description
			field_type
			filter_id
			group
			label
			last_edit_by_id
			last_edit_date
			name
			options {
				id
				value
			}
			required
			user_id
			include
			value
		}
		device {
			device_auth_type
			message
		}
	}
}`
