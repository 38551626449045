import store from '@/store'

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then();
    } catch(e) {
        return false;
    }
    return true;
}

export function getNotificationPermissionStatus () {
    let status

    try {
        status = Notification.permission
    } catch (err) {
        console.log('error with Notification.permission', err)
    }

    return status
}

export function askNotificationPermission() {
    // function to actually ask the permissions
    // NOTE: You can have a button for this to be useful in the settings page incase they denied permissions originally

    let ret = 'default'
    function handlePermission(permission) {
        // set the button to shown or hidden, depending on what the user answers
        ret = permission
        /*
        if (Notification.permission === 'denied' || Notification.permission === 'default') {
            // notificationBtn.style.display = 'block';
        } else {
            // notificationBtn.style.display = 'none';
        }
        */
    }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
        console.log("This browser does not support notifications.");
    } else {

        if(checkNotificationPromise()) {
            Notification.requestPermission()
            .then((permission) => {

                handlePermission(permission);
            })
        } else {
            Notification.requestPermission(function(permission) {

                handlePermission(permission);
            });
        }
    }
    return ret
}

function isNotificationSupported() {
    return !!window.Notification
}

export async function askNotificationPermission2() {
    // function to actually ask the permissions
    // NOTE: You can have a button for this to be useful in the settings page incase they denied permissions originally

    if (!isNotificationSupported()) {
        return {
            status: 'notSupported'
        }
    }

    let currentPermission = Notification.permission

    switch (currentPermission) {
        case 'denied':
            return {
                status: 'alreadyDenied'
            }

        case 'granted':
            return {
                status: 'alreadyGranted'
            }
    }

    //if we are here, the response is default.  So we can ask for permission and then see if they accept

    //console.log('checkNotificationPromise', checkNotificationPromise())

    let permission = await Notification.requestPermission()

    switch (permission) {
        case 'denied':
            return {
                status: 'userDenied'
            }

        case 'granted':
            return {
                status: 'userGranted'
            }

        case 'default':
            return {
                status: 'userWaiting' //we asked for permission but still is default.  Must be waiting for acceptance
            }
    }

    return {
        status: 'userGranted'
    }
}

export function displayNotification(title, body, onClick) {
    const img = '/images/ycmdlogo.png';
    const notification = new Notification(title || 'You received a notification', { body: body || 'Click here', icon: img });
    // when notificaiton is clicked, focus the window
    notification.onclick = async function () {
        window.focus();
        if (onClick) onClick();
    };
}
