<template>
  <div class="list-box-wrapper">
    <div class="list-box-item">
      <div class="label-title d-flex flex-row align-center ma-0 pa-0 mb-2">
          <v-checkbox hide-details
              class="d-flex align-center ma-0"
              v-model="allTotalFacilities"
          ></v-checkbox>
        <span v-html="titleLeft" />
      </div>
      <div class="list-box">
        <div
          v-for="(item,key) in source.map((item,inx) => ({inx,...item})).filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchSource.toLowerCase()))"
          v-bind:key="key"
          class="ma-2 d-flex flex-row align-center"
        >
            <v-icon class="mr-2" @click="selectSource(searchSource?item.inx:key)" :color="item.selected ? '#1976D2' : 'gray'" style="pointer: cursor">{{item.selected ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
            <!-- <v-checkbox hide-details
                :ripple="false"
                class="d-flex align-center ma-0 mr-2"
                color="#1976D2"
                v-model="item.selected"
            ></v-checkbox> -->
            {{item[label in item ? label : 'label']}}
        </div>
        <div
          v-if="source && source.length && source.filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchSource.toLowerCase())).length == 0"
          class="list-item"
        >No results found</div>
    </div>
      <div class="search-box d-flex align-center mt-2">
        <v-icon class="ml-2" :size="22">search</v-icon>
        <input v-model="searchSource" type="text" placeholder="Search" />
        <!-- <div
          v-if="searchSource"
          class="clear-search"
          title="Clear Search"
          @click=" searchSource='' "
        >&times;</div> -->
        <v-btn v-if="searchSource" @click="searchSource='';" icon color="pink" class="clear-search">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <!-- <div class="bulk-action">
        <div class="select-all" @click="selectAllSource">Select All</div>
        <div class="deselect-all" @click="deSelectAllSource">None</div>
      </div> -->
    </div>
    <div class="actions mb-8">
      <div class="btn-action d-flex justify-center align-center justify-center" @click="moveDestination">
          <v-icon color="white" class="" :size="28">keyboard_arrow_right</v-icon>
      </div>
      <!-- <div class="btn-action d-flex justify-center align-center" @click="moveAllDestination">
        <svg height="18" viewBox="0 0 448 512">
          <path
            fill="#ffffff"
            d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"
          />
        </svg>
      </div> -->
      <div class="btn-action d-flex justify-center align-center" @click="moveSource">
          <v-icon color="white" class="" :size="28">keyboard_arrow_left</v-icon>
      </div>
      <!-- <div class="btn-action d-flex justify-center align-center" @click="moveAllSource">
        <svg height="18" viewBox="0 0 448 512">
          <path
            fill="#ffffff"
            d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"
          />
        </svg>
      </div> -->
    </div>
    <div class="list-box-item">
      <div class="label-title d-flex flex-row align-center ma-0 pa-0 mb-2">
          <v-checkbox hide-details
              class="d-flex align-center ma-0"
              v-model="allSelectedFacilities"
          ></v-checkbox>
        <span v-html="titleRight" />
      </div>
      <ul class="list-group list-group-flush border rounded list-box">
        <li
          v-for="(item,key) in destination.map((item,inx) => ({inx,...item})).filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchDestination.toLowerCase()))"
          v-bind:key="key"
          class="ma-2 d-flex flex-row align-center"
        >
            <v-icon class="mr-2" @click="selectDestination(searchDestination?item.inx:key)" :color="item.selected ? '#1976D2' : 'gray'" style="pointer: cursor">{{item.selected ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
            <!-- <v-checkbox hide-details
                :ripple="false"
                class="d-flex align-center ma-0"
                v-model="item.selected"
            ></v-checkbox> -->
            {{item[label in item ? label : 'label']}}
        </li>
        <li
          v-if="destination && destination.length && destination.filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchDestination.toLowerCase())).length == 0"
          class="list-item"
        >No results found</li>
      </ul>
      <div class="search-box d-flex align-center mt-2">
        <v-icon class="ml-2" :size="22">search</v-icon>
        <input v-model="searchDestination" type="text" placeholder="Search" />
        <!-- <div
          v-if="searchDestination"
          class="clear-search"
          title="Clear Search"
          @click=" searchDestination='' "
        >&times;</div> -->
        <v-btn v-if="searchDestination" @click="searchDestination='';" icon color="pink" class="clear-search">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <!-- <div class="bulk-action">
        <div class="select-all" @click="selectAllDestination">Select All</div>
        <div class="deselect-all" @click="deSelectAllDestination">None</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import angleRight from "./assets/angle-right-solid.svg";
import angleLeft from "./assets/angle-left-solid.svg";
import angleDoubleLeft from "./assets/angle-double-left-solid.svg";
import angleDoubleRight from "./assets/angle-double-right-solid.svg";
export default {
  props: {
    source: Array,
    destination: Array,
    label: String,
    titleLeft: String,
    titleRight: String
  },
  data: function() {
    return {
      angleRight,
      angleLeft,
      angleDoubleLeft,
      angleDoubleRight,
      searchSource: "",
      searchDestination: "",

      allTotalFacilities: false,
      allSelectedFacilities: false
    };
  },
  watch: {
      allTotalFacilities(value) {
          if (value) {
              this.selectAllSource();
          } else {
              this.deSelectAllSource();
          }
      },
      allSelectedFacilities(value) {
          if (value) {
              this.selectAllDestination();
          } else {
              this.deSelectAllDestination();
          }
      },
      searchSource(value) {
          // if you change search input clear select all checkbox and any selected facilities
          this.allTotalFacilities = false;
          this.allSelectedFacilities = false;
          this.deSelectAllSource();
      },
      searchDestination(value) {
          // if you change search input clear select all checkbox and any selected facilities
          this.allTotalFacilities = false;
          this.allSelectedFacilities = false;
          this.deSelectAllDestination();
      }
  },
  methods: {
    resetForms() {
        this.searchDestination = '';
        this.searchSource = '';
        // delselect all
        if (this.allSelectedFacilities) {
          this.allSelectedFacilities = false;
        }
        // deselect all
        if (this.allTotalFacilities) {
          this.allTotalFacilities = false;
        }
    },
    moveDestination: function() {
      let selected = this.source.filter(f => f.selected);
      if (!selected || !selected.length) return;
      selected = selected.map(item => ({
        ...item,
        selected: false
      }));
      let destination = [...selected, ...this.destination];
      destination = destination.sort((a,b) => (a.name.trim() > b.name.trim()) ? 1 : ((b.name.trim() > a.name.trim()) ? -1 : 0));
      let source = this.source.filter(f => !f.selected);
      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination,
        moved: true
      });
      this.$nextTick(() => {
          this.resetForms();
      })
    },
    moveSource: function() {
      this.resetForms();
      let selected = this.destination.filter(f => f.selected);
      if (!selected || !selected.length) return;
      selected = selected.map(item => ({
        ...item,
        selected: false
      }));
      let source = [...selected, ...this.source];
      let destination = this.destination.filter(f => !f.selected);
      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination,
        moved: true
      });
      this.$nextTick(() => {
          this.resetForms();
      })
    },
    moveAllDestination: function() {
      let destination = [
        ...this.source.map(item => ({ ...item, selected: false })),
        ...this.destination
      ];
      let source = [];
      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination,
        moved: true
      });
    },
    moveAllSource: function() {
      let source = [
        ...this.destination.map(item => ({ ...item, selected: false })),
        ...this.source
      ];
      let destination = [];
      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination,
        moved: true
      });
    },
    selectDestination: function(key) {
      let source = this.source;
      let destination = this.destination.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected;
        }
        return i;
      });
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    selectSource: function(key) {
      let destination = this.destination;
      let source = this.source.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected;
        }
        return i;
      });
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    selectAllSource: function() {
      let source = this.source.map(item => {
          if (item.name.toLowerCase().includes(this.searchSource.toLowerCase())) {
              return {
                ...item,
                selected: true
              }
          } else {
              return item
          }
      });
      let destination = this.destination;
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    deSelectAllSource: function() {
      let source = this.source.map(item => ({ ...item, selected: false }));
      let destination = this.destination;
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    selectAllDestination: function() {
      let destination = this.destination.map(item => {
          if (item.name.toLowerCase().includes(this.searchDestination.toLowerCase())) {
              return {
                ...item,
                selected: true
              }
          } else {
              return item
          }
      });
      let source = this.source;
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    deSelectAllDestination: function() {
      let destination = this.destination.map(item => ({
        ...item,
        selected: false
      }));
      let source = this.source;
      this.$emit("onChangeList", {
        source,
        destination
      });
    }
  }
};
</script>
<style scoped>
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    .list-box {
      background-color: white;
      border: solid 1px #cccccc;
      border-radius: 6px;
    }
    .list-box-wrapper {
      font-family: sans-serif;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .list-box-wrapper > div {
      flex: 1;
    }
    .list-box-wrapper .list-box-item {
      /* border: solid 1px #cccccc;
      border-radius: 3px; */
    }
    .list-box-wrapper .list-box-item .search-box {
      background-color: white;
      border: solid 1px #cccccc;
      border-radius: 6px;
      position: relative;
    }
    .list-box-wrapper .list-box-item .search-box input {
      border: none;
      width: 100%;
      padding: 0.5rem 1rem;
      outline-width: 0;
    }
    .list-box-wrapper .list-box-item .search-box .clear-search {
      position: absolute;
      padding: 0.5rem;
      right: 0;
      top: 0;
      cursor: pointer;
      font-weight: bold;
      color: #e74c3c;
    }
    .list-box-wrapper .list-box-item .list-box {
      height: 250px;
      overflow: auto;
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .list-box-wrapper .list-box-item .list-box .list-item {
      padding: 0.5rem 1rem;
      border-bottom: solid 1px #cccccc;
      cursor: pointer;
    }
    .list-box-wrapper .list-box-item .list-box .list-item.active {
      background-color: #eeeeee;
    }
    .list-box-wrapper .list-box-item .bulk-action {
      display: flex;
      border-top: solid 1px #cccccc;
      text-align: center;
    }
    .list-box-wrapper .list-box-item .bulk-action .select-all {
      width: 100%;
      padding: 0.8rem;
      background-color: #007bff;
      color: #fff;
    }
    .list-box-wrapper .list-box-item .bulk-action .deselect-all {
      width: 100%;
      padding: 0.8rem;
      background-color: #6c757d;
      color: #fff;
    }
    .list-box-wrapper .actions {
      flex-grow: 0;
      padding: 0 1rem;
    }
    .list-box-wrapper .actions .btn-action {
      margin-bottom: 0.5rem;
    }

    .btn-action {
      font-weight: 400;
      color: #212529;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 15px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      display: block;
      width: 30px;
      height: 30px;
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
      cursor: pointer;
    }
    .btn-action svg {
      vertical-align: middle;
    }
</style>
