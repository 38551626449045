import dataClient from '@/graphql/clients/axios';
import Vue from 'vue'

const state = {
    reportDefinitions: null,
    jobs: [],
    referentialCache: {},
    jobKeys: {},
    reportSchedules: null
};

const getters = {
  getReportDefinitions: state => state.reportDefinitions,
  getReportSchedules: state => state.reportSchedules,
  getReferentialByKey: (state) => (key) => {
    return state.referentialCache[key]
  },
  getReportJobs: state => state.jobs,
  getJobKeys: state => state.jobKeys,
};

const actions = {
    async loadReferential ({ commit, getters, dispatch, rootGetters }, payload) {

      let query = payload.query
      let variables = payload.variables

      let cacheKey = `${query}_${JSON.stringify(variables)}`  //this may vary due to property order but worst case is we cache same data twice

      let referential = getters.getReferentialByKey(cacheKey)

      if (referential) {
        console.log('data from cache', payload)
        return referential
      }

      switch (query) {
        case 'phxGroups':
          //reports in group portal don't allow the user to select any group
          let group = rootGetters['profile/getGroup']
          return [
            group
          ]
      }



      let data = await dataClient(query, variables)
      console.log('data', data)


      switch (query) {
        case 'phxGroups':
          if (data) {
            let sorted = data.sort(function(a, b) {
              return a.name.trim().localeCompare(b.name.trim());
            });

            data = sorted
          }
      }

      commit('setReferentialByKey', {key: cacheKey, value: data})

      return data
    },  
    async findReportDefinition ({ commit, getters }, payload) {

      if (!getters.getReportDefinitions) {
        let defs = await dataClient('ycmdReportDefinitionGet')

        commit('setReportDefinitions', defs)
      }

      let def
      if (getters.getReportDefinitions && getters.getReportDefinitions.length) {
        def = getters.getReportDefinitions.find(d => {
          return d.id === payload.report_id
        })
      }

      return def
    },
    async findReportDefinitionsByCategory ({ commit, getters }, payload) {
      console.log('payload', payload)
      if (!getters.getReportDefinitions) {
        let defs = await dataClient('ycmdReportDefinitionGet')

        commit('setReportDefinitions', defs)
      }
      let arr=[]
      if (getters.getReportDefinitions && getters.getReportDefinitions.length) {
        getters.getReportDefinitions.map(d => {
          if (d.categoryMap[payload.category]) {
            arr.push(d)
          }
        })
      }

      return arr
    },    
    async runReport ({ commit, getters, dispatch }, payload) {
      let definition = await dispatch('findReportDefinition', payload)

      if (definition) { 
        let run = await dataClient('ycmdReportRun', payload)
        console.log('run', run)          
        return run    
      }

    },
    async updateAppReports ({ commit, getters, dispatch }, payload) {
      console.log('payload', payload)

      let reportIndex = payload.app.items.findIndex(i => {
        return i.name === 'Reports'
      })

      if (reportIndex < 0){
        return
      }
      let reportItem = payload.app.items[reportIndex]

      let defs = await dispatch('findReportDefinitionsByCategory',{category: payload.app.name})

      let arr = []
      defs.map(d => {        
        arr.push(
          {appName: payload.app.name, 
            appIndex: payload.appIndex,
            itemIndex: reportIndex,
            itemRoute: reportItem.route,
            name: d.name, 
            icon: d.reportPropertiesMap.icon ? d.reportPropertiesMap.icon : payload.app.icon, 
            params: {report_id: d.id}
          }
        )      
      })
      console.log('reportItem', reportItem)

      let commitPayload = {
        childItems: arr,
        appName: payload.app.name,
        itemName: reportItem.name,
      }

      commit(payload.mutation, commitPayload, {root: true})
    },
    async scheduleSave ({ commit, getters, dispatch, rootGetters }, payload) {
      let schedule = await dataClient('ycmdReportScheduleCreate', payload)
      
      let scheduleObject = JSON.parse(schedule.data)
      let def = await dispatch('findReportDefinition',{report_id: payload.schedule.report_id})

      commit('addSchedule', {schedule: scheduleObject, report_id: payload.schedule.report_id})
      return schedule
    },
    async scheduleDelete ({ commit, getters, dispatch, rootGetters }, payload) {
      console.log('scheduleDelete payload', payload)

      let result = await dataClient('ycmdReportScheduleDelete', payload)
      console.log('schedule delete result', result)     
      if (result.status === 'success') {
        commit('removeSchedule', payload)
      }     
    },    
    async submitReport ({ commit, getters, dispatch, rootGetters }, payload) {
      let definition = await dispatch('findReportDefinition', payload) 
    
      let tempJob = {
                      type: "asynchronous_report",
                      user_id: rootGetters['profile/getUserID'],
                      submitted: new Date(),
                      report: {
                        report_name: definition.name,
                        report_id: definition.id,
                        properties: payload.parameters,
                        definition: definition,
                        output_type: payload.format,
                        icon: definition.reportPropertiesMap.icon ? definition.reportPropertiesMap.icon : rootGetters['app/app'].icon
                      },
                      key: payload.key,
                      progress: null
                    }
      commit('addJob',tempJob)  

      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'job_id',
        value: null
      })
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'running',
        value: true
      })
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'activity_date',
        value: null
      })
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'activity_date_end',
        value: null
      })    
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'columns',
        value: null
      })    
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'lines',
        value: null
      })    
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'url',
        value: null
      })    
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'running',
        value: true
      })
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'output',
        value: 'none'
      })
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'output_type',
        value: payload.format
      })
  
      let progress = {
        type: definition.progress.type,
        max: 10,
        min: 0,
        current: 0,
        description: 'Starting Report'
      }
      //to make sure the props are response, use vue.set 
      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'progress',
        value: progress
      })
      
      payload.report_id = definition.id
  
      //dispatch('data/triggerGlobalEvent', {app: 'Report', item: job.job_id, command: 'started', data: progress  }, {root: true})
      dispatch('submitReportToBackEnd', payload)
  
      return tempJob  
    },
    async submitReportToBackEnd ({ commit, getters, dispatch }, payload) {
      let job = await dataClient('ycmdReportRun', payload)
      console.log('job', job)          
      commit('addJobIdAndKey',{
        key: payload.key,
        job_id: job.job_id
      })

      commit('setJobPropertyByKey',{
        key: payload.key,
        property: 'job_id',
        value: job.job_id
      })
    },    
    updateJob ({ commit, getters, dispatch }, activity) {

      let key = getters.getJobKeys[activity.job_id]

      let jobIndex = getters.getReportJobs.findIndex(job => {
        return job.key == key
      })
          
      if (jobIndex >= 0) {
        let jobBefore = getters.getReportJobs[jobIndex]
        
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'activity_date',
          value: activity.activity_date
        })
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'activity_date_end',
          value: activity.activity_date_end
        })    
        if (activity.report) {
          commit('setJobPropertyByIndex',{
            index: jobIndex,
            property: 'columns',
            value: activity.report.columns
          })    
          commit('setJobPropertyByIndex',{
            index: jobIndex,
            property: 'lines',
            value: activity.report.lines
          })    
          commit('setJobPropertyByIndex',{
            index: jobIndex,
            property: 'url',
            value: activity.report.url
          })   
  
  
          if (activity.report.lines) {
            if(activity.report.url) {
              commit('setJobPropertyByIndex',{
                index: jobIndex,
                property: 'output',
                value: 'both'
              })
            } else {
              commit('setJobPropertyByIndex',{
                index: jobIndex,
                property: 'output',
                value: 'json'
              })
            }
          } else {
            if(activity.report.url) {
              commit('setJobPropertyByIndex',{
                index: jobIndex,
                property: 'output',
                value: 'url'
              })
            } else {
              commit('setJobPropertyByIndex',{
                index: jobIndex,
                property: 'output',
                value: 'none'
              })
            }
          }
  
  
        }
  
        let progress = {
          type: jobBefore.progress.type,
          max: jobBefore.progress.max,
          min: jobBefore.progress.min,
          current: jobBefore.progress.max,
          description: 'Report Finished'
        }
        //to make sure the props are response, use vue.set 
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'progress',
          value: progress
        })
    
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'running',
          value: false
        })
  
        if (activity.error_message) {
          commit('setJobPropertyByIndex',{
            index: jobIndex,
            property: 'error',
            value: activity.error_message
          })
        }
        
        //dispatch('data/triggerGlobalEvent', {app: 'Report', item: activity.job_id, command: 'finished', data: {}}, {root: true})
      }
    },
    updateJobProgress ({ commit, getters, dispatch }, activity) {
      let jobIndex = getters.getJobIndex(activity.job_id)
  
      if (jobIndex >= 0) {
  
        let progress = {
          type: activity.progress.type,
          max: activity.progress.max,
          min: activity.progress.min,
          current: activity.progress.current,
          description: activity.progress.description
        }
        //to make sure the props are response, use vue.set 
        commit('setJobPropertyByIndex',{
          index: jobIndex,
          property: 'progress',
          value: progress
        })
          
        //dispatch('data/triggerGlobalEvent', {app: 'Report', item: activity.job_id, command: 'progress', data: progress}, {root: true})
      }
    },  
};

const mutations = {
  resetState (state) {
    state.reportDefinitions = null
    state.jobs = []
    state.referentialCache = {}
    state.jobKeys = {}
    state.reportSchedules = null
  },
  removeSchedule (state, payload) {

    let index = state.reportSchedules.findIndex(s => {
      return s.id = payload.schedule_id
    })

    if (index >= 0) {
      state.reportSchedules.splice(index, 1)
    }
  },
  setReportDefinitions (state, data) {
    let arr = data
    let schedules = []
    if (arr && arr.length) {
      arr.map(r => {
        r.categoryMap = {}
        if (r.categories && r.categories.length) {
          r.categories.map(c => {
            r.categoryMap[c] = true
          })
        }
        r.reportPropertiesMap = {}
        if (r.reportProperties && r.reportProperties.length) {
          r.reportProperties.map(p => {
            r.reportPropertiesMap[p.name] = p.value
          })
        }

        if (r.schedules && r.schedules.length) {
          r.schedules.map(s => {
            schedules.push({
              report_id: r.id,
              schedule: s
            })
          })
        }
      })
    } 

    state.reportDefinitions = arr;
    state.reportSchedules = schedules
  },
  async addSchedule (state, data) {

    if (state.reportDefinitions && state.reportDefinitions.length) {
      let def = state.reportDefinitions.find(d => {
        return d.id === data.report_id
      })
      console.log('add sccheule', def)
      if (def) {
        if (!def.schedules || !def.schedules.length) {
          def.schedules = []  
        }
        def.schedules.push(data.schedule)
      }
    }

    state.reportSchedules.push(data)
  },
  setReferentialByKey: (state, payload) => {
    state.referentialCache[payload.key] = payload.value
  },
  setJobPropertyByIndex(state, payload) {    
    if (payload.index >= 0) {
      Vue.set(state.jobs[payload.index], payload.property, payload.value);
    }
  },

  setJobPropertyByKey(state, payload) {    
    console.log('setJobPropertyByKey', payload)
    let jobIndex = state.jobs.findIndex(job => {
      return job.key == payload.key
    })

    if (jobIndex >= 0) {
      console.log('setting setJobPropertyByKey', state.jobs[jobIndex])
      Vue.set(state.jobs[jobIndex], payload.property, payload.value);
    }
  },
  setJobPropertyById(state, payload) {
    let key = state.jobKeys[payload.job_id]
    let jobIndex = state.jobs.findIndex(job => {
      return job.key == key
    })

    if (jobIndex >= 0) {
      Vue.set(state.jobs[jobIndex], payload.property, payload.value);
    }
  },  
  addJob (state, job) {   
    state.jobs.push(job)
  },
  addJobIdAndKey(state, payload) {
    state.jobKeys[payload.job_id] = payload.key
  },  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
