<template>
  <v-container
    class="pa-0 ma-0"
  >
    <v-row
      class="pa-0 ma-0 align-center"
    >
      <v-col
        cols="12"
        class="pa-0 ma-0"
      >
        <label
          class="v-label v-label--active theme--light text-caption">{{label}}
        </label>
      </v-col>
      <v-col
        class="shrink pa-0 ma-0"
      >
      </v-col>
    </v-row>
    <v-row
      class="pa-0 ma-0 align-center"
    >
      <v-col
        class="shrink pa-0 ma-0"
      >
        <v-select
            class="align-center"
            :useIcon="false"
            :label="''"
            :items="hourArray"
            v-model="selectedHours"
            :disabled="disabled"
            :rules="rules"
            dense
            style="width: 50px;"
        />
      </v-col>
      <v-col
        class="shrink pa-0 ma-0 pl-1"
      >
        <v-select
            class="align-center"
            :useIcon="false"
            :label="''"
            :items="minuteArray"
            v-model="selectedMinutes"
            :disabled="disabled"
            :rules="rules"
            dense
            style="width: 50px;"
        />
      </v-col>

      <v-col
        class="shrink pa-0 ma-0"
      >
        <v-btn
            text
            @click="toggleAmPm"
            style="font-size: 12px;min-width: 25px;"
            width="25px"
            class="pb-4 pr-0 pl-1"
            :disabled="disabled"            
        >
            {{ampm}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {

  props: {
    value: {
      default: null,
      type: String
    },
    format: {
      default: '',
      type: String
    },
    label: {
      default: 'Time',
      type: String
    },
    rules: {
      default: () => { return [] },
      type: Array
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    selectedHours: '12',
    selectedMinutes: '00',
    hourArray: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    minuteArray: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
    ampm: 'AM'
  }),
  methods: {
    convertTimeto24: (time) => {
      let tm = time.toLowerCase().trim()
      let pm = false
      if (tm.indexOf('pm') >= 0) {
        pm = true
      }

      tm = tm.replace('am', '').replace('pm', '').trim()

      let pieces = tm.split(':')
      let hours = parseInt(pieces[0])
      if (pm) {
        hours += 12
      }

      let minutes = parseInt(pieces[1])

      return `${hours < 10 ? 0 : ''}${hours}:${minutes < 10 ? 0 : ''}${minutes}`
    },
    twoDigit (num) {
        let str = num + ''
        if (str.length === 1) {
            str = '0' + str
        }
        return str
    },

    toggleAmPm () {
      this.ampm = this.ampm === 'AM' ? 'PM' : 'AM'
    },
    setTime (time) {
      if (!time) {
        this.selectedHours = null
        this.selectedMinutes = null
        return
      }

      let pieces = time.split(':')
      let hours = parseInt(pieces[0])
      if (hours > 12) {
        this.ampm = 'PM'
        hours = hours - 12
      } else if (hours === 0) {
        hours = 12
        this.ampm = 'AM'
      } else {
        this.ampm = hours == 12 ? 'PM' : 'AM'
      }

      let minutes = pieces[1]

      this.selectedHours = hours + ''
      this.selectedMinutes = minutes
    },
    calculateTime () {
      let hours = parseInt(this.selectedHours)
      if (this.ampm === 'PM') {
        if (hours < 12) {
            hours += 12
        }
      } else if (hours === 12) {
            hours = 0
      }

      return `${this.twoDigit(hours)}:${this.twoDigit(this.selectedMinutes)}`
    }
  },
  computed: {
    timeArray () {
      let arr = []
      for (let hour = 0; hour < 12; hour++) {
        for (let minute15 = 0; minute15 < 4; minute15++) {
            let value = `${twoDigit(hour)}:${twoDigit(minute15 * 15)}`
            let h = hour
            // let ap = 'am'
            if (h === 0) {
                h = 12
            }
          /*
          else if (h === 12) {
            ap = 'pm'
          } else if (h > 12) {
            h -= 12
            ap = 'pm'
          }
          */
          let name = `${h}:${twoDigit(minute15 * 15)}`
          arr.push({
            name: name,
            value: value
          })
        }
      }

      return arr
    }
  },
  watch: {
    selectedHours (newValue, oldValue) {
      this.$emit('input', this.calculateTime())
    },
    selectedMinutes (newValue, oldValue) {
      this.$emit('input', this.calculateTime())
    },
    ampm (newValue, oldValue) {
      this.$emit('input', this.calculateTime())
    },
    value (newValue, oldValue) {
      if (newValue !== this.calculateTime()) {
        this.setTime(newValue)
      }
    }
  },
  mounted () {
    if (this.value) {
      /*
      let times = this.timeArray
      times.map(t => {
        console.log('time', t)
      })
      */
      this.setTime(this.convertTimeto24(this.value))
    }
  }
}
</script>