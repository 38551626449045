import gql from 'graphql-tag'

export default gql`query ycmdChatSearch($search: String, $roles: [YcmdRole], $objects: [ChatObject], $participants: [ID], $deleted: Boolean, $nextToken: String, $limit: Int) {
	ycmdChatSearch(search: $search, roles: $roles, objects: $objects, participants: $participants, deleted: $deleted, nextToken: $nextToken, limit: $limit) {
		contacts {
			first_name
			last_name
			user_id
			group_id
			group_name
			title
			specialty
			roles
			ooo
			app_version
		}
		matches {
			source {
				attachment_name
				attachment_type
				creator_first_name
				creator_last_name
				creator_user_id
				date_created
				date_updated
				deleted
				group_id
				group_name
				deleted
				id
				object_type
				post_type
				poster_first_name
				poster_last_name
				poster_user_id
				room_id
				room_name
				room_type
				room_unread_count
				room_last_post_id
				room_last_post_time
				room_last_post_text
				system_type
				meta {
					name
					value
				}
				tags
				text
				participant {
					creator
					date_last_seen
					date_joined
					date_left
					first_name
					last_name
					last_post_seen
					user_id
					status
					ooo
					app_version
				}
			}
		}
		nextToken,
		totalMatches
	}
}`
