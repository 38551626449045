<template>
  
  <v-list
    dense
    class="pa-0 ma-0"
    v-if="items && items.length"
  >
    <template
      v-for="(item,i) in items"
    >
      <v-divider 
        v-if="item.id === 'divider'"
        :key="`divider_${i}`"
        style="margin-left: 40px;"
      />

      <v-list-item 
        two-line
        :key="`user_${item.user_id}`"
        :id="`user_${item.user_id}`"
        v-else-if="item.user_id"
        @click="selectUser(item)"
        :class="`contact pa-0 ma-0`"
        :style="`${item.odd ? 'background-color: #F0F9FF' : ''}`"
      >

        <v-list-item-avatar
          class="pa-0 ma-0"
        >
          <app-not-installed-indicator v-if="item.app_version && item.app_version=='notinstalled'"/>      

          <ooo-indicator 
            v-else-if="item.ooo"
          />
         
          <v-icon
            class="ycmd-light-blue--text"
          >{{item.type==='contact' ? 'person' : 'wechat'}} </v-icon>
        
        </v-list-item-avatar>


        <v-list-item-content
          class="pa-0 ma-0"
        >
          <v-list-item-title
            class="pa-0 ma-0"
          >
            <text-highlight
              :queries="search !== null ? search : []"
              :highlightClass="'text__highlight2'"
            >
              {{ item.name }}
            </text-highlight>

          </v-list-item-title>
          <v-list-item-subtitle
            class="pa-0 ma-0"
          >
            {{item.group_name}}

          </v-list-item-subtitle>                
        </v-list-item-content>
      </v-list-item>

      <v-list-item three-line
        v-else
        :key="`${item.type}_${item.id}`"
        :id="`${item.type}_${item.id}`"

        :class="`pa-0 ma-0 room`"
        :style="`${item.odd ? 'background-color: #F0F9FF': ''}`"

        @click="changeRoom(item)"
      >
        <v-list-item-avatar
          class="pa-0 ma-0"
        >
          <v-icon
            class="ycmd-light-blue--text"
          >{{item.type==='contact' ? 'person' : 'wechat'}} </v-icon>
        </v-list-item-avatar>
        <v-list-item-content
          class="pa-0 ma-0"
        >
          <v-list-item-title
            class="pa-0 ma-0"
          >
            <text-highlight
              :queries="search !== null ? search : []"
              :highlightClass="'text__highlight2'"
            >
              {{ item.name }}
            </text-highlight>

          </v-list-item-title>
          <v-list-item-subtitle
            class="pa-0 ma-0"
          >
            <provider-list
              :queries="search !== null ? [search] : []"
              :highlightClass="'text__highlight2'"
              :currentUserId="getUserID"
              :participants="item.participantArray"
            />

          </v-list-item-subtitle>

          <v-list-item-subtitle
            class="pa-0 ma-0"
            v-if="item.last_post_id"
          >
            <span class='fs-10'>Last Post:</span> {{item.lastPostText}} <span class='fs-10'>{{item.lastPostTimeToday ? 'at': 'on'}}: {{item.lastPostTime}}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar
          v-if="item.unreadCount"
          class="pa-0 ma-0"
        >
          <div 
            class="d-flex align-center justify-center pink fw-500 text-right mr-1 white--text"
                style="height: 15px; min-width: 15px; max-width: 15px; border-radius: 15px;fontSize: 8px ;line-height: 10px;">
            {{ item.unreadCount }}
          </div>
        </v-list-item-avatar>

      </v-list-item>
    </template>      

  </v-list>
  <div
    v-else-if="!loading"
    style="width: 100%;"
    class="text-center"
  >
    {{noMatchText}}
  </div>

</template>

<script>
import { format, utcToZonedTime } from 'date-fns-tz';
import {mapGetters, mapActions} from "vuex";
import oooIndicator from '@/views/apps/chat/components/ooo-indicator.vue';
import appNotinstalledIndicator from '@/views/apps/chat/components/app-not-installed-indicator.vue';

import providerList from '@/views/apps/chat/components/provider-list.vue';
export default {
  props: {
    rooms: {
      type: Array,
      default: () => []
    },
    contacts: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ''
    },
    loading:{
      type: Boolean,
      default: true
    },
    noMatchText: {
      type: String,
      default: 'No matches'
    } 
  },
  data: () => ({

  }),
  methods: {

    selectUser (item) {
      this.$emit('selectUser', item)
    },
    async changeRoom (item) {
      this.$emit('changeRoom', item)
    },
    mergeContacts(mergedResults, contacts, search) {

      if (!mergedResults) {
        mergedResults = []
      }

//      let regex = new RegExp(search, 'gi');

      contacts.map(c => {
        let name

//        if (c.first_name.match(regex)) {
          name = `${c.first_name} ${c.last_name}`
  //      } else {
    //      name = `${c.last_name}, ${c.first_name}`
    //    }

        if (c.title) {
          name += `, ${c.title}`
        }
        c.type = 'contact'
        c.name = name
      })

      let sorted = contacts.sort(function(a, b) {
          return a.name.trim().localeCompare(b.name.trim());
      });

      let finalArray = []

      finalArray.push(...sorted)

      finalArray.push(...mergedResults)

      return finalArray
    },

    translateRoom(item)  {
      let newItem = {}
      newItem.id = item.id
      newItem.name = item.room_name ? item.room_name : 'Conversation'
      newItem.lastPostText = item.room_last_post_text
      newItem.last_post_id = item.room_last_post_id      
      
      let time = this.getTime(item.room_last_post_time)

      newItem.lastPostTime = time.format
      newItem.lastPostTimeToday = time.today
      //newItem.lastPostTime = this.getTime({ start_date: item.room_last_post_time})
      newItem.type = item.object_type
      newItem.unreadCount = item.room_unread_count,
      newItem.participants = this.getParticipantList(item.participant)
      newItem.participantArray = item.participant

      /*
      item.participant.map(p => {
        p.ooo = true
      })
      */
      return newItem
    },  
    getParticipantList(arr) {
      let list = ''
      let concat = ''
      for (let i=0;i<arr.length;i++) {
        let a = arr[i] 
        if (a.user_id === this.getUserID) {
          continue
        }

        let name = `${a.first_name} ${a.last_name}`
        if (name) {
          list += concat
          list += name
          concat = ', '
        }
      }
      return list
    },
    getTime(start_date) {

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localDate = utcToZonedTime(start_date, timeZone);

      let today = false
      let form
      if ( format(localDate, 'MM-dd-yyyy') === format(new Date(), 'MM-dd-yyyy') ) {
        today = true
        form = format(localDate, 'h:mm a', { timeZone }) 
      } else if ( format(localDate, 'yyyy') === format(new Date(), 'yyyy') ) {
        form = format(localDate, 'MMM do', { timeZone })
      } else {
        form = format(localDate, 'MM-dd-yyyy',{ timeZone })
      }

      return {
        today,
        format: form
      }
    },
    getTimeOld({ start_date, exact = false }) {

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localDate = utcToZonedTime(start_date, timeZone);

      if(this.exactTimestamps)
        exact = this.exactTimestamps;

      if(!exact)
        return format(localDate, 'h:mm a', { timeZone })
      else
        return format(localDate, 'MM-dd-yyyy h:mm a',{ timeZone })
    },
  },
  computed: {
    ...mapGetters('profile', ['getUserID']),   
    
    items() {

      let tempItems = []

      let mergedResults = this.mergeContacts(this.rooms, this.contacts, this.search)

      let j = 0
      mergedResults.map(r => {
        if (r.user_id) {
          if (j % 2) {
            r.odd = true
          }
          tempItems.push(r)
        } else {
          let i = this.translateRoom(r)
          if (j % 2) {
            i.odd = true
          }          
          tempItems.push(i)
        }
        j++

        tempItems.push({
          id: 'divider'
        })
      })

      return tempItems

    }, 
  },
  components: {
    'ooo-indicator': oooIndicator,
    'provider-list': providerList,
    'app-not-installed-indicator': appNotinstalledIndicator
  },
  mounted () {

  },
  watch: {

  },
}
</script>
<style scoped>
  #chatRoomSelectorList >>> div.v-list-item
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  


</style>