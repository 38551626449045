<template>

  <div>
    <div v-if="provider" class="d-flex align-center">
      <app-not-installed-indicator v-if="provider.app_version && provider.app_version=='notinstalled'"/>      
      <ooo-indicator 
        v-else-if="provider.ooo"
      />
      
      <div :class="`d-flex align-center justify-center ${backgroundColor} ml-2 mt-1 br-2 ${foregroundColor} fs-18 fw-500 uppercase`"
           style="width: 48px; height:48px; min-width: 48px">
        {{ getUserInitials(provider.first_name, provider.last_name) }}
      </div>
      <div class="d-flex flex-column fs-12 pl-4">
        <div>
          {{ provider.first_name }} {{ provider.last_name }}
          <span v-if="provider.title" class="fade-2">, {{ provider.title }}</span>
        </div>
        <div class="fs-11 pt-2">
          <span>{{ provider.group_name }}</span>
          <span v-if="provider.specialty" class="fade-2 pl-2">( {{ provider.specialty }} )</span>
        </div>
      </div>
      <slot />
    </div>
  </div>

</template>

<script>
import oooIndicator from '@/views/apps/chat/components/ooo-indicator.vue';
import appNotinstalledIndicator from '@/views/apps/chat/components/app-not-installed-indicator.vue';

export default {
  props: {
    provider: Object,
    backgroundColor: {
      default: 'white',
      type: String
    },
    foregroundColor: {
      default: 'ycmd-blue--text',
      type: String
    }
  },
  data: () => ({}),
  methods: {
    getUserInitials(first_name, last_name) {

      if(first_name && last_name)
        return first_name.charAt(0) + last_name.charAt(0);
      else
        return 'NA'
    },
  },
  components: {
    'ooo-indicator': oooIndicator,
    'app-not-installed-indicator': appNotinstalledIndicator
  }
}
</script>
