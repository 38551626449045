import gql from 'graphql-tag'

export default gql`mutation ycmdAuthenticateTokenRefresh($username: String!, $refresh_token: String!, $user_id: ID) {
	ycmdAuthenticateTokenRefresh(username: $username, refresh_token: $refresh_token, user_id: $user_id) {
		token
		authentication_status {
			lastlogin
			logins
			authentication_type
			required_authentication_type
			prompt_for_twofactor
			twofactor_grace_period_days
			twofactor_grace_period_minutes_left
			twofactor_last_prompted
			twofactor_first_prompted
			twofactor_conversion_date 
        }
	}
}`
