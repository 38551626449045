<template>

    <div class="i-card__panel i-card__panel__bottom ycmd-blue-vertical-gradient pa-2 pt-0 pr-0">

        <v-overlay :value="Boolean(isLoading)" :absolute="true" color="#2389e8" opacity="0.3">
            <div class="d-flex align-center justify-center" >
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </v-overlay>

        <div class="i-card__panel__header">

            <div class="d-flex align-center flex-grow-1">

                <v-tooltip bottom
                           nudge-bottom="10"
                           nudge-right="50"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on" @click="closePanel" class="ml-2 mr-2">
                            <v-icon :size="20">close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cancel</span>
                </v-tooltip>

                <div class="white--text fs-14 ml-3">
                    New Custom Contact
                </div>
            </div>
            <div class="d-flex align-center justify-end ml-2">

                <v-spacer />

                <v-btn dark text small
                       @click="onCreateNewContact"
                       class="mr-4 ycmd-light-blue">
                    Save
                </v-btn>
            </div>
        </div>

        <div class="i-card__panel__content white br-2 pa-2">

            <div class="i-card__scrollable">
                <div class="">

                    <v-container fluid class="ycmd-light-gray pa-0 b b-1 br-2">

                        <v-form ref="newContactForm" v-model="createCustomContactData.validContactForm"
                              class="d-flex flex-column full-width full-height pl-4 pr-4 pb-2 pt-2">

                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="label-title pb-2">First Name</div>
                                    <v-text-field light solo dense flat outlined
                                                  :hide-details="createCustomContactData.validContactForm"
                                                  :rules="[rules.required]"
                                                  v-on:input="$refs.newContactForm.validate()"
                                                  v-model="createCustomContactData.first_name" />
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="label-title pb-2">Last Name</div>
                                    <v-text-field light solo dense flat outlined
                                                  :hide-details="createCustomContactData.validContactForm"
                                                  :rules="[rules.required]"
                                                  v-on:input="$refs.newContactForm.validate()"
                                                  v-model="createCustomContactData.last_name" />
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="label-title pb-2">Specialty</div>
                                    <v-text-field light solo dense flat outlined
                                                  :hide-details="createCustomContactData.validContactForm"
                                                  v-model="createCustomContactData.specialty" />
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <div class="label-title pb-2">Company</div>
                                    <v-text-field light solo dense flat outlined
                                                  :hide-details="createCustomContactData.validContactForm"
                                                  v-model="createCustomContactData.company" />
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <div class="label-title pb-2">Notes</div>
                                    <v-textarea
                                            light dense flat outlined
                                            :hide-details="createCustomContactData.validContactForm"
                                            class="small-input"
                                            auto-grow
                                            v-model="createCustomContactData.notes"
                                            rows="3"
                                            background-color="white" />
                                </v-col>
                            </v-row>
                            <!-- phones -->
                            <template v-for="(item, i) in createCustomContactData.phone_numbers">
                            <v-row :key="`rowkey_phone_${i}`">
                                <v-col cols="12" sm="4" md="3">
                                    <div class="label-title pb-2">Phone {{i+1}}</div>
                                    <v-text-field light solo dense flat outlined
                                                  label="Label"
                                                  v-model="item.label" />
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <!-- <div class="label-title pb-2">Number</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="Number"
                                                  v-model="item.number" />
                                </v-col>
                                <v-col cols="6" sm="2" md="2">
                                    <!-- <div class="label-title pb-2">Ext.</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="Ext."
                                                  v-model="item.extension" />
                                </v-col>
                                <v-col cols="6" sm="2" md="2" class="d-flex">
                                    <div v-if="i !== 0" class="d-flex align-center">
                                        <v-tooltip bottom
                                                   nudge-bottom="0"
                                                   nudge-right="0"
                                                   color="ycmd-black"
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark small rounded
                                                       @click="createCustomContactData.phone_numbers.splice(i, 1)"
                                                       v-on="on"
                                                       class="mr-2 red">
                                                    <v-icon :size="16">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Phone</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-if="i === createCustomContactData.phone_numbers.length - 1" class="d-flex align-center">
                                        <v-tooltip bottom
                                                   nudge-bottom="0"
                                                   nudge-right="0"
                                                   color="ycmd-black"
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark small rounded
                                                       @click="createCustomContactData.phone_numbers.push({
                                                           label: null,
                                                           number: null,
                                                           extension: null
                                                       })"
                                                       v-on="on"
                                                       class="ml-2 ycmd-light-blue">
                                                    <v-icon :size="16">add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Phone</span>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                            </v-row>
                            </template>
                            <!-- addresses -->
                            <template v-for="(item, i) in createCustomContactData.addresses"> 
                            <v-row  :key="`rowkey_address_${i}`">
                                <v-col cols="12" sm="4" md="4">
                                    <div class="label-title pb-2">Address {{i+1}}</div>
                                    <v-text-field light solo dense flat outlined
                                                  label="Label"
                                                  v-model="item.label" />
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <!-- <div class="label-title pb-2">Street</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="Street"
                                                  v-model="item.street" />
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <!-- <div class="label-title pb-2">Street Cont..</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="Street Cont.."
                                                  v-model="item.street2" />
                                </v-col>
                                <v-col cols="6" sm="2" md="2">
                                    <!-- <div class="label-title pb-2">City</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="City"
                                                  v-model="item.city" />
                                </v-col>
                                <v-col cols="6" sm="2" md="2">
                                    <!-- <div class="label-title pb-2">State</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="State"
                                                  v-model="item.state" />
                                </v-col>
                                <v-col cols="6" sm="2" md="2">
                                    <!-- <div class="label-title pb-2">Zip Code</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="Zip Code"
                                                  v-model="item.zip_code" />
                                </v-col>
                                <v-col cols="6" sm="2" md="2" class="d-flex">
                                    <div v-if="i !== 0" class="d-flex align-center">
                                        <v-tooltip bottom
                                                   nudge-bottom="0"
                                                   nudge-right="0"
                                                   color="ycmd-black"
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark small rounded
                                                       @click="createCustomContactData.addresses.splice(i, 1)"
                                                       v-on="on"
                                                       class="mr-2 red">
                                                    <v-icon :size="16">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Phone</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-if="i === createCustomContactData.addresses.length - 1" class="d-flex align-center">
                                        <v-tooltip bottom
                                                   nudge-bottom="0"
                                                   nudge-right="0"
                                                   color="ycmd-black"
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark small rounded
                                                       @click="createCustomContactData.addresses.push({
                                                           label: null,
                                                           street: null,
                                                           street2: null,
                                                           city: null,
                                                           state: null,
                                                           zip_code: null
                                                       })"
                                                       v-on="on"
                                                       class="ml-2 ycmd-light-blue">
                                                    <v-icon :size="16">add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Phone</span>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                            </v-row>
                            </template>
                            <!-- email -->
                            <template v-for="(item, i) in createCustomContactData.email_addresses">
                            <v-row  :key="`rowkey_email_${i}`">
                                <v-col cols="12" sm="4" md="4">
                                    <div class="label-title pb-2">Email Address {{i+1}}</div>
                                    <v-text-field light solo dense flat outlined
                                                  label="Label"
                                                  v-model="item.label" />
                                </v-col>
                                <v-col cols="12" sm="5" md="5">
                                    <!-- <div class="label-title pb-2">Number</div> -->
                                    <v-text-field light solo dense flat outlined
                                                  class="mt-6"
                                                  label="Email Address"
                                                  v-model="item.address" />
                                </v-col>
                                <v-col cols="6" sm="2" md="2" class="d-flex">
                                    <div v-if="i !== 0" class="d-flex align-center">
                                        <v-tooltip bottom
                                                   nudge-bottom="0"
                                                   nudge-right="0"
                                                   color="ycmd-black"
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark small rounded
                                                       @click="createCustomContactData.email_addresses.splice(i, 1)"
                                                       v-on="on"
                                                       class="mr-2 red">
                                                    <v-icon :size="16">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Phone</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-if="i === createCustomContactData.email_addresses.length - 1" class="d-flex align-center">
                                        <v-tooltip bottom
                                                   nudge-bottom="0"
                                                   nudge-right="0"
                                                   color="ycmd-black"
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark small rounded
                                                       @click="createCustomContactData.email_addresses.push({
                                                           label: null,
                                                           address: null
                                                       })"
                                                       v-on="on"
                                                       class="ml-2 ycmd-light-blue">
                                                    <v-icon :size="16">add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Phone</span>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                            </v-row>
                            </template>
                        </v-form>

                    </v-container>

                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import { capitalizeFirstLetter } from '@/methods/utils';
    import {formatPhoneNumber} from '@/methods/globalMethods';

    import CREATE_CUSTOM_CONTACT from '@/graphql/mutations/phoenix/ycmdContactAdd';

    export default {
        name: 'newContactForm',
        apollo: {
            $client: 'phoenixClient'
        },
        props: {
            togglePanels: Function,
            onNewContact: Function
        },
        data: () => ({
            formatPhoneNumber,
            capitalizeFirstLetter,
            isLoading: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters'
            },
            createCustomContactData: {
                validContactForm: true,
                first_name: null,
                last_name: null,
                specialty: null,
                company: null,
                notes: null,
                phone_numbers: [
                    {
                        label: null,
                        number: null,
                        extension: null
                    }
                ],
                addresses: [
                    {
                        label: null,
                        street: null,
                        street2: null,
                        city: null,
                        state: null,
                        zip_code: null
                    }
                ],
                email_addresses: [
                    {
                        label: null,
                        address: null
                    }
                ]
            }
        }),
        mounted() {
            console.log('mounted')
        },
        methods: {
            closePanel() {
                this.togglePanels('bottom', false);
            },
            resetCustomContactForm() {
                this.createCustomContactData = {
                    validContactForm: true,
                    first_name: null,
                    last_name: null,
                    specialty: null,
                    company: null,
                    notes: null,
                    phone_numbers: [
                        {
                            label: null,
                            number: null,
                            extension: null
                        }
                    ],
                    addresses: [
                        {
                            label: null,
                            street: null,
                            street2: null,
                            city: null,
                            state: null,
                            zip_code: null
                        }
                    ],
                    email_addresses: [
                        {
                            label: null,
                            address: null
                        }
                    ]
                }
            },
            async onCreateNewContact() {
                // validate
                this.$refs.newContactForm.validate();
                if (!this.createCustomContactData.validContactForm) return;

                this.isLoading = true;
                console.log('createCustomContactData: ', this.createCustomContactData);
                try {
                    let newContact = {
                            specialty: this.createCustomContactData.specialty,
                    		first_name: this.createCustomContactData.first_name,
                    		notes: this.createCustomContactData.notes,
                    		phone_numbers: this.createCustomContactData.phone_numbers,
                    		company: this.createCustomContactData.company,
                    		addresses: this.createCustomContactData.addresses,
                    		last_name: this.createCustomContactData.last_name,
                    		email_addresses: this.createCustomContactData.email_addresses
                        }
                    const response = await this.$apollo.mutate({
                        mutation: CREATE_CUSTOM_CONTACT,
                        variables: newContact,
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;

                    console.log('response: ', response);

                    const { ycmdContactAdd } = response.data

                    if (ycmdContactAdd) {

                        if (this.onNewContact) {
                            this.onNewContact(ycmdContactAdd)
                        }

                        this.$refs.newContactForm.reset();
                        // this.resetCustomContactForm();
                        this.$toasted.success(`Successfully created contact`);
                        this.closePanel()
                    } else {
                        // data did not come back
                        this.$toasted.error(`There was an error creating the contact`);
                        console.log('ycmdContactFavorite did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.$toasted.error(`Caught an error creating contact`);
                    console.log('WARNING: Caught error - ', e);
                    // defaultErrorHandler(e);
                }
            },
        },
        computed: {

        }
    }

</script>

<style scoped>

</style>
