import {format} from 'date-fns-tz';

export default function (date, formatString) {

  let dt = date
  if (typeof(date) != 'object') {
    dt = new Date(date)
  }

  if (dt) {
    return format(dt, formatString)
  }
  else
    return ''
}