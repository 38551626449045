<template>
    <div
        class="pa-0 ma-0 full-width"
    >        
        <v-row
            class="pa-0 ma-0"
        >
            <v-col cols="4"
                class="pa-0 ma-0 fw-700"
            >
                <v-row
                    class="pa-0 ma-0"
                >
                    <v-col cols="12"
                        class="pa-0 ma-0"
                    >
                        <emr-provider         
                            :excludedProviders="selectedProviders"     
                            :clearOnSelect="true"
                            @providerSelected="providerSelected"
                            :forceClear="clearProvider"
                        />
                    </v-col>
                </v-row>
<!--                
                <v-row
                    class="pa-0 ma-0"
                    align="start"
                >
                    <v-col
                        class="pl-0 pr-0 py-2 ma-0 shrink"
                    >
                        <emr-timezone-select
                            v-model="localTimezone"
                            :providerIds="selectedProviders" 
                        />
                    </v-col>
                    <v-col
                        class="pl-2 pr-0 pt-0 pb-0 ma-0 shrink"
                    >
                        <v-checkbox
                            v-model="localShowUnavailable"
                            label="Show unavailable times"
                            hide-details
                            dark
                            style="min-width: 175px;"
                        ></v-checkbox>
                    </v-col>
                </v-row>
-->                
            </v-col>
            <v-col cols="8"
                class="pa-0 ma-0"
                stylexx="border: 1px solid red;"
            >

                <v-chip-group   
                    selected-class="text-primary"
                    column
                >
                    <v-chip
                    v-for="providerId in selectedProviders"
                    :key="`selected_provider_${providerId}`"
                    :color="providerBg(providerId)"
                    :text-color="providerFg(providerId)"
                    close
                    @click:close="removeProvider(providerId)"
                    >
                        {{providerName(providerId)}} 
                    </v-chip>
                </v-chip-group>

            </v-col>
        </v-row>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import emrProvider from '@/views/apps/emr/components/emrProvider.vue'
    import emrTimezoneSelect from '@/views/apps/emr/components/emrTimezoneSelect';
    import emrReasonSelect from '@/views/apps/emr/components/emrReasonSelect';
    export default {
        name: 'emrProviderMultiSelect',
        components: {
            'emr-provider':emrProvider,
            'emr-timezone-select': emrTimezoneSelect,
            'emr-reason-select':emrReasonSelect
        },
        props: {
            rules: Array,
            initialValue: null,
            timezone: {
                default: null,
                type: String
            },
            showUnavailable: {
                default: false,
                type: Boolean
            },
        },
        data: () => ({
            selectedProviders: [],
            clearProvider: null,
            ex4: false
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
            /*
            localTimezone (newValue, oldValue) {
                this.$emit('update:timezone', newValue)
            }
            */
        },
        methods: {
            ...mapActions('emr', ['loadProviders']),
            removeProvider(providerId) {
                let index = this.selectedProviders.findIndex(p => {
                    return p === providerId
                })

                if (index >= 0) {
                    this.selectedProviders.splice(index, 1)
                    this.clearProvider = (new Date()).toISOString()
                }
            },
            providerSelected(providerId) {
                this.selectedProviders.push(providerId)
                this.$emit('input', this.selectedProviders)
            },
            providerName(providerId) {
                let ret = 'Unknown'

                let provider = this.providerMap[providerId]
                
                if (provider) {

                    ret = provider.lastname
                    
                    if (provider.firstname) {
                        ret += ', '
                        ret += provider.firstname
                    }
                }
                return ret                
            },
            providerFg(providerId) {
                let fg = this.getProviderPaletteFg(providerId)
                return fg
            },
            providerBg(providerId) {
                let bg = this.getProviderPaletteBg(providerId)
                return bg
            }

        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getloadedProviders','getProviderPaletteFg', 'getProviderPaletteBg']
            ),
            providerMap () {
                let map = {}
                console.log('this.getProviders', this.getProviders)

                this.getProviders.map(p => {
                    map[p.id] = p
                })

                return map
            },
            localTimezone: {
                get: function() {
                    return this.timezone;
                },
                set: function(newValue) {
                    this.$emit('update:timezone', newValue)
                }
            },
            localShowUnavailable: {
                get: function() {
                    return this.showUnavailable;
                },
                set: function(newValue) {
                    this.$emit('update:showUnavailable', newValue)
                }
            },

        }
    }
</script>
