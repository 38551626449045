<template>

  <v-app light :class="`relative ${ mainClass } `" :style="`height: ${ innerHeight }px !important`">

   <div v-if="fetchingUserData"
        class="d-flex flex-column ycmd-dark-blue-radial-gradient full-width full-height justify-center align-center">

     <div class="fulfilling-bouncing-circle-spinner">
       <div class="circle"></div>
       <div class="orbit"></div>
     </div>

     <span class="fw-700 fs-20 mt-6 white--text">Loading profile...</span>

   </div>

   <div v-else-if="failedToFetchUserData"
        class="d-flex flex-column ycmd-dark-blue-radial-gradient full-width full-height justify-center align-center">

     <span class="fw-700 fs-20 mt-6 white--text">Failed to get user data... please retry</span>

     <div class="d-flex">
       <v-tooltip bottom
                  nudge-bottom="10"
                  :disabled="$vuetify.breakpoint.xsOnly"
                  color="ycmd-black"
                  content-class="fade-0 pa-4 pt-2 pb-2">
         <template v-slot:activator="{ on }">
           <v-btn icon dark v-on="on" @click="getUserData" color="green" class="mt-4">
             <v-icon :size="36">refresh</v-icon>
           </v-btn>
         </template>
         <span>Retry</span>
       </v-tooltip>
       <v-tooltip bottom
                  nudge-bottom="10"
                  :disabled="$vuetify.breakpoint.xsOnly"
                  color="ycmd-black"
                  content-class="fade-0 pa-4 pt-2 pb-2">
           <template v-slot:activator="{ on }">
               <v-btn icon dark v-on="on" @click="logUserOut" color="red" class="mt-4 ml-4">
                     <v-icon :size="36">power_settings_new</v-icon>
               </v-btn>
           </template>
         <span>Log Out</span>
       </v-tooltip>
     </div>
  
   </div>

   <router-view v-else class="absolute full-width full-height vmmrouter"/>

    <two-factor v-model="authenticationStatusOpen" v-if="authenticationStatusOpen" />
  </v-app>

</template>

<script>

import {mapActions, mapState, mapGetters} from "vuex";
import debounce from 'debounce';
import innerHeight from 'ios-inner-height';

import SubscriptionService from '@/methods/subscriptionInstance'

import {logout, routeBasedOnRole} from '@/methods/globalMethods';
import { askNotificationPermission } from '@/methods/notificationSystem';

import FETCH_USER_DATA from '@/graphql/queries/phoenix/ycmdMe';

import config from '@/data/config';

import twofactor from '@/components/modals/two-factor'

export default {
  name: 'app',
  data: () => ({
    transition: 'fade-transition',
    height: 0,
    innerHeight: 0,
    fullscreen: false,
    prompt: false,
    mainClass: 'main',
    fetchingUserData: false,
    failedToFetchUserData: false,
    rightMenuEnabledFlag: true
  }),
  components: {
    'two-factor': twofactor
  },
  apollo: {
    $client: 'phoenixClient'
  },
  created() {

    let installPrompt;

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted") {
          console.log("Install accepted!")
        } else {
          console.log("Install denied!")
        }
      });
    };

    // Init our static data
    this.$store.dispatch('app/init', {index: 0, itemIndex: 0}, {root: true});

    // Make sure this is closed when it is disabled
    if (!this.isRightMenuEnabled)
      this.closeRightMenu();

  },
  mounted() {

    // this.enableRightMenu(process.env.VUE_APP_RIGHT_MENU_ENABLED);
    this.height = innerHeight();
    this.innerHeight = window.innerHeight;

    window.onresize = debounce(this.updateResize, 66);

    // Add or remove the header based on which route meta matches
    this.fullscreen = this.$store.getters['layout/fullscreen'];

    // TODO: currently fetching user data is only in phoenix, with different clients. Clients need ot be the same
    if (localStorage.accessToken) {
        this.getUserData();
        // Ask permission to use Notifications system
        askNotificationPermission();
    }
    //console.log("USER LOGGED IN: ", this.idleTimer);
  },
  methods: {
    ...mapActions('chat', ['loadRooms']),
    ...mapActions('profile', ['setProfile', 'enableChatNotifications', 'setChatRoomNotifications', 'setInactivityTimeoutMinutes']),
    ...mapActions('group', ['initGroups']),
    ...mapActions('menu', ['openMenu', 'closeMenu', 'toggleMenu']),
    ...mapActions('rightMenu', ['enableRightMenu', 'disableRightMenu', 'openRightMenu', 'closeRightMenu']),
    ...mapActions('idleTimer', ['startTimer','stopTimer','extendSession','onRemind']),
    ...mapGetters('profile', ['getHasChat']),
    logout,
    logUserOut() {
      this.fetchingUserData = false;
      this.failedToFetchUserData = false;

      logout();
    },
    updateResize() {

      this.innerHeight = window.innerHeight;

      if (this.$vuetify.breakpoint.smAndDown) {
        console.log('close the chat', this.innerHeight)
        this.disableRightMenu();
      } else {
        if (this.rightMenuEnabledFlag && localStorage.accessToken) {
            this.enableRightMenu();
        }
      }

    },
    resizeMenu() {
        if (this.isRightMenuEnabled) {
            if (this.isRightMenuOpen) {
                if (this.isRightMenuExpanded) {
                    if (this.isMenuOpen)
                        this.mainClass = 'main-menu-open-right-menu-expanded';
                    else
                        this.mainClass = 'main-menu-closed-right-menu-expanded';
                } else {
                    this.mainClass = 'main';
                }
            } else {
                this.mainClass = 'main';
            }
        }
    },
    async getUserData() {

        //
        this.fetchingUserData = true;
        this.failedToFetchUserData = false;

        try {

            // close the menu and disable it until we get roles back
            await this.disableRightMenu();
            const response = await this.$apollo.query({
                query: FETCH_USER_DATA,
                variables: {
                    include_linked_users: true
                },
                client: 'phoenixClient',
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            this.fetchingUserData = false;
            // console.log('YCMD response: ', response);

            // if the token expired or access token was removed, clear the values so they can see the login page
            if (!localStorage.accessToken) {
                console.log('app.vue - missing access token')
                this.failedToFetchUserData = false;
                this.fetchingUserData = false;
                return;
            }

            const {ycmdMe} = response.data;

            // update
            if (ycmdMe) {
                if (ycmdMe.me) {
                    const {
                        first_name,
                        last_name,
                        id,
                        reference_id,
                        group,
                        roles,
                        legacy_roles,
                        settings,
                        online_status
                    } = ycmdMe.me;
                    const {
                        linked_groups,
                        linked_users
                    } = ycmdMe;
                    // console.log('ycmdme: ', ycmdMe);
                    this.setProfile({
                        firstName: first_name || '',
                        lastName: last_name || '',
                        userID: id,
                        referenceID: reference_id,
                        group: {
                            id: group.id,
                            name: group.name,
                            voicemail: group.voicemail,
                            forwarding: group.forwarding,
                            settings: group.settings
                        },
                        roles: roles,
                        legacy_roles: legacy_roles,
                        avatar: null,
                        online: online_status,
                        settings: settings
                    });

                    // set groups
                    this.initGroups({
                        myGroup: {
                            group_id: group.id,
                            group_name: group.name,
                            user_id: id,
                            settings: group.settings
                        },
                        groups: linked_groups || [],
                        myUser: {id: id, roles: roles},
                        linkedUsers: linked_users || []
                    });
                    // check if they have chat


                    if (this.getHasChat) {
                      SubscriptionService.init({userID: id, groupID: group.id});                    

                      this.rightMenuEnabledFlag = true
                      // enable the menu
                      this.enableRightMenu();
                      this.loadRooms()
                    } else {
                        SubscriptionService.init({userID: null, groupID: group.id});                    

                        this.rightMenuEnabledFlag = false
                        this.disableRightMenu();
                    }


                    if (settings) {

                        const {enable_chat_notifications, room_settings} = settings;

                        this.enableChatNotifications({enable_chat_notifications});
                        this.setInactivityTimeoutMinutes(settings.inactivity_timeout_minutes)

                        if (room_settings)
                        this.setChatRoomNotifications(room_settings);
                    }
                }

                routeBasedOnRole(this.$route);
                //need to run through the responsive stuff
                this.$nextTick(() => {
                    this.updateResize();
                })
            }
            else {
                // this.failedToFetchUserData = true;
                console.log('ycmdMe data did not come back: ', response);
            }
        }
        catch (e) {
            this.failedToFetchUserData = true;
            this.fetchingUserData = false;
            // defaultErrorHandler(e);
            console.log('Catch error: ', e);
        }
    },
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({type: "SKIP_WAITING"});
    }
  },
  computed: {
    ...mapState({
      isMenuOpen: state => state.menu.open
    }),
    ...mapState('rightMenu', ['isRightMenuEnabled','isRightMenuOpen','isRightMenuExpanded']),
    ...mapState('idleTimer', ['idleTimer','idleDialog','idleValue','idleReminders','idleTimeout','idleWait']),
    ...mapGetters(
        'app', ['authenticationStatusOpen', '']
    ),
  },
  watch: {
    '$route'(to) {
      this.transition = (to.params.transition) ? to.params.transition : 'fade-in-transition';
    },
    isRightMenuOpen() {

      this.resizeMenu();
    },
    isRightMenuExpanded() {

      this.resizeMenu();
    },
    isMenuOpen() {

      this.resizeMenu();
    }
  }
}
</script>

<style scoped>

.main {
  width: 100% !important;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.main >>> .v-main__wrap {
  overflow: hidden;
}

.main-menu-open-right-menu-expanded {
  width: 220px !important;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.main-menu-closed-right-menu-expanded {
  width: 0 !important;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

</style>

<style>

.v-input, .v-label {
  font-size: 12px;
}

.v-navigation-drawer__border {
  width: 0;
}

</style>
