<template>

    <div class="view ycmd-blue-gradient">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2">

                <div class="i-card__main__content white br-2 pa-2">

                    <div class="i-card__scrollable ycmd-light-gray full-width full-height">
                        <div class="pr-1 pa-2">

                            <input class="mb-4" id="userImportInput" type="file" />

                            <v-btn depressed dark class="ycmd-blue" style="width: 150px;" @click="importUsers">Import Users</v-btn>

                            <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                            <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                        </div>
                    </div>

                </div>
            </div>

        </i-card>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import * as Papa from 'papaparse';
    import Loading from 'vue-loading-overlay';

    import {defaultErrorHandler} from '../../../../graphql/handler/errorHandler';
    import {refreshHandler} from '../../../../graphql/handler/refreshHandler';
    import IMPORT_USER from '../../../../graphql/mutations/phxUserImport';

    export default {
        name: 'userImport',
        components: {
            Loading
        },
        props: {
            params: {
                type: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                isLoading: false,
                currentProviderID: '',
                providerIDS: [],
                errorMsg: '',
                successMsg: ''
            }
        },
        activated() {

        },
        mounted() {

        },
        methods: {
            addProviderID() {
              if (!this.currentProviderID) return;
              this.providerIDS.push(this.currentProviderID);
              this.currentProviderID = '';
            },
            async importUsers() {
                this.errorMsg = '';
                this.successMsg = '';

                if (!document.getElementById('userImportInput').files.length) {
                  this.errorMsg = 'Please select a file from your computer'
                  return;
                }

                const selectedFile = document.getElementById('userImportInput').files[0];
                console.log('selected file: ', selectedFile);

                this.isLoading = true;
                Papa.parse(selectedFile,
                	{
                		// base config to use for each file
                    header: true,
                    before: (file, inputElem) => {
                      // executed before parsing each file begins;
                      // what you return here controls the flow
                    },
                    error: (err, file, inputElem, reason) => {
                      this.isLoading = false;
                      console.log('there was an error: ', err);
                      this.errorMsg = err;
                      // executed if an error occurs while loading the file,
                      // or if before callback aborted for some reason
                    },
                    complete: async (results, file) => {
                      console.log("Parsing complete:", results, file);
                      try {
                          const response = await this.$apollo.mutate({
                              mutation: IMPORT_USER,
                              variables: {
                                import_users: results.data
                              },
                              fetchPolicy: 'no-cache',
                              errorPolicy: 'all'
                          });
                          this.isLoading = false;
                          console.log('response: ', response);
                          if (response.data && response.data.phxUserImport) {
                            this.successMsg = 'Successfully imported users';
                          } else if (response.errors && response.errors.length > 0) {
                            if (response.errors[0].message) {
                              this.errorMsg = response.errors[0].message;
                            } else {
                              this.errorMsg = "Error 2E. Invalid response.";
                            }
                          } else {
                            // data did not come back
                            console.log('phxUserImport data did not come back: ', response);
                            this.errorMsg = 'Server error';
                          }
                      } catch (e) {
                          this.isLoading = false;
                          defaultErrorHandler(e);
                      }
                    }
                	}
                );
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
