<template>

    <v-row class="ma-0 pa-0">
        <v-col
            class="shrink"
        >
            <span
                style="white-space: nowrap"
            >{{submittedTime}}</span>
        </v-col>
    </v-row>

</template>

<script>
    import {format} from "date-fns-tz";

    export default {
        props: {
            job: {
                type: Object,
                default: () => {}
            }
        },
        components: {
        },
        data() {
            return {
                panels: []
            }
        },
        created() {},
        async mounted() {
        },

        watch: {

        },
        methods: {

        },
        computed: {
            submittedTime () {
                console.log('this.job', this.job)

                let ret = ''
                if (this.job.submitted) {
                    let finished = this.finishedTime
                    if (finished) {
                        finished = ', ' + finished
                    }
                    ret = `${this.job.report.report_name} - Submitted: ${format(this.job.submitted,'HH:mm:ss')}${finished}`
                }
                return ret
            },
            finishedTime () {
                console.log('this.job', this.job)

                let ret = ''
                if (this.job.activity_date_end) {
                    ret = `Finished: ${format(new Date(this.job.activity_date_end),'HH:mm:ss')}`
                }
                return ret
            },

        }
    }

</script>

<style scoped>

</style>
