import gql from 'graphql-tag'

export default gql`mutation ycmdAuthenticateTwofactorSetup($postpone: Boolean, $email: String, $mobile_number: String, $allow_different_emails: Boolean) {
	ycmdAuthenticateTwofactorSetup(postpone: $postpone, email: $email, mobile_number: $mobile_number, allow_different_emails: $allow_different_emails) {

		lastlogin
		logins
		authentication_type
		required_authentication_type
		prompt_for_twofactor
		twofactor_grace_period_days
		twofactor_grace_period_minutes_left
		twofactor_last_prompted
		twofactor_first_prompted
		twofactor_conversion_date 
        
	}
}`
