//import router from '@/routerRightMenu'

const state = {
    isRightMenuEnabled: false,
    isRightMenuOpen: false,
    isRightMenuExpanded: false
};

const getters = {};

const actions = {
    enableRightMenu: ({commit}) => {
        commit('enable');
        commit('open');
    },
    disableRightMenu: ({commit}) => {
        commit('disable');
        commit('close');
    },
    expandRightMenu: ({commit}) => {
        if (state.isRightMenuEnabled) {
            commit('expand')
        }
        else {
            console.log("Right Menu is disabled in the environment variables");
        }
    },
    collapseRightMenu: ({commit}) => {
        commit('collapse');
    },
    openRightMenu: ({commit}) => {
        if (state.isRightMenuEnabled) {
            commit('open')
        } else {
            console.log("Right Menu is disabled in the environment variables");
        }
    },
    closeRightMenu: ({commit}) => {
        commit('close');
    },
    routeRightMenu: ({commit}, {name, transition, params}) => {
        /*
        router.push({
            name: name,
            params: {
                transition: transition || 'fade-transition',
                ...params
            }
        }).catch(err => {
            console.log('router error: ', err)
        });
        */
    }
};

const mutations = {
    enable(state) {
        state.isRightMenuEnabled = true;
    },
    disable(state) {
        state.isRightMenuEnabled = false;
    },
    expand(state) {
        state.isRightMenuExpanded = true;
    },
    collapse(state) {
        state.isRightMenuExpanded = false;
    },
    toggle (state) {
        state.isRightMenuOpen = !state.open;
    },
    open (state) {
        state.isRightMenuOpen = true;
        state.isRightMenuExpanded = false;
    },
    close (state) {
        state.isRightMenuOpen = false;
        state.isRightMenuExpanded = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
