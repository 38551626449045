<template>
  <v-dialog
      v-model="value"
      persistent
      max-width="290"
      >
      <v-card>
          <v-card-title class="text-h5">
              Schedule
          </v-card-title>
          <v-card-subtitle>
              Schedule this report
          </v-card-subtitle>

          <v-card-text>

            <v-select light dense flat outlined
                      v-model="repeat"
                      label="Repeat"
                      :items="repeats"
                      item-text="name"
                      item-value="value"
                      background-color="white"/>

            <v-select light dense flat outlined
                      v-model="dow"
                      label="Day of the week"
                      :items="dows"
                      item-text="name"
                      item-value="value"
                      background-color="white"
                      v-if="repeat=='weekly'"
                      />

            <v-select light dense flat outlined
                      v-model="dom"
                      label="Day of the month"
                      :items="doms"
                      item-text="name"
                      item-value="value"
                      background-color="white"
                      v-if="repeat=='monthly'"                      
                      />

            <timepicker v-model="time" />

            <textlist v-model="emails" label="Emails" />

          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="ok()"
              :disabled="sending || !valid"
          >
              OK
          </v-btn>
          <v-btn
              text
              @click="close()"
          >
              Cancel
          </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
import timepicker from '@/components/datetime/timepicker';
import textlist from '@/components/textlist';

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      schedule: {
        default: () => {return {}},
        type: Object
      }
    },
    data: () => ({
      sending: false,
      status: null,
      time: '0:00',
      repeat: 'monthly',
      day: '0',
      dom: '1',
      dow: '0',
      emails: [],
      repeats: [
        {
          value: 'daily',
          name: 'Daily'
        },
        {
          value: 'weekly',
          name: 'Weekly'
        },
        {
          value: 'monthly',
          name: 'Monthly'
        }
      ],
      dows: [
        {value: '0', name: 'Sunday'}, 
        {value: '1', name: 'Monday'},
        {value: '2:', name: 'Tuesday'}, 
        {value: '3', name: 'Wednesday'},
        {value: '4', name: 'Thursday'},
        {value: '5', name: 'Friday'},
        {value: '6', name: 'Saturday'}
      ],
      doms: [
        { name: '1st', value: '1' },
        { name: '2nd', value: '2' },
        { name: '3rd', value: '3' },
        { name: '4th', value: '4' },
        { name: '5th', value: '5' },
        { name: '6th', value: '6' },
        { name: '7th', value: '7' },
        { name: '8th', value: '8' },
        { name: '9th', value: '9' },
        { name: '10th', value: '10' },
        { name: '11th', value: '11' },
        { name: '12th', value: '12' },
        { name: '13th', value: '13' },
        { name: '14th', value: '14' },
        { name: '15th', value: '15' },
        { name: '16th', value: '16' },
        { name: '17th', value: '17' },
        { name: '18th', value: '18' },
        { name: '19th', value: '19' },
        { name: '20th', value: '20' },
        { name: '21st', value: '21' },
        { name: '22nd', value: '22' },
        { name: '23rd', value: '23' },
        { name: '24th', value: '24' },
        { name: '25th', value: '25' },
        { name: '26th', value: '26' },
        { name: '27th', value: '27' },
        { name: '28th', value: '28' },
        { name: '29th', value: '29' },
        { name: '30th', value: '30' },
        { name: '31st', value: '31' },
        { name: 'Last day of the month', value: 'Last' }
    ]
    }),
    mounted() {
    },
    methods: {
      close() {
        this.$emit('input', false)
      },
      ok() {
        this.$emit('save', this.scheduleObject)
        this.$emit('input', false)
      }
    },
    computed: {
      scheduleObject () {
        let obj = {
          repeat: this.repeat,
          time: this.time
        }
        if (this.repeat === 'monthly') {
          obj.dom = this.dom
        }
        if (this.repeat === 'weekly') {
          obj.dow = this.dow
        }
        
        obj.emails = this.emails
        return obj
      },
      valid () {
        if (!this.emails || !this.emails.length) {
          return false
        }
        return true
      }
    },
    components: {
      timepicker,
      textlist
    },
    watch: {
    }
  }
</script>

