<template>

    <v-row
        class="pa-0 ma-0"
        align="center"
    >
        <v-col
        cols="3" md="2"
        class="pa-0 ma-0"
        >
            {{parameter.name}}
        </v-col>
        <v-col
        cols="9" md="6"
        class="pa-0 ma-0"
        >
            <v-autocomplete
                light dense flat outlined
                v-model="groupId"
                :disabled="!allGroups.length"
                :items="allGroups"
                :loading="isLoadingGroupsSearch"
                label="Select Group"
                item-value="id"
                item-text="name"
                background-color="white"
                clearable
                :multiple="!pickerSingle"
                v-if="parameter.data_type==='GROUP_ID'"
                :rules="[validateField]"
            ></v-autocomplete>
            <v-select
                :disabled="!allFacilities.length"
                light dense flat outlined
                v-model="facilityId"
                :items="allFacilities"
                :loading="loadingFacilities"
                label="Select Facility"
                item-value="id"
                item-text="name"
                :multiple="!pickerSingle"
                class=""
                style="max-width: 400px;"
                background-color="white"
                clearable
                :rules="[validateField]"
                v-else-if="parameter.data_type==='FACILITY_ID'"
            ></v-select>

            <date-range-picker
                v-model="dateRange"
                v-else-if="parameter.data_type==='daterange' || parameter.data_type==='datetimerange'"
                :includeTime="parameter.data_type==='datetimerange'"
                :rules="[validateField]"
                />

            <date-picker
                v-model="datePicker"
                v-else-if="parameter.data_type==='date'"
                :rules="[validateField]"
            />

            <template
                v-else-if="parameter.data_type==='timerange'"
            >
                <div
                class="pb-4"

                >
                    <span
                        class="fs-8 ycmd-blue--text text-decoration-underline pointer"
                            @click="overrideNull = true"
                        v-if="showNullableAddLabel"
                    >
                        {{attributeMap['nullable_label_add'] ? attributeMap['nullable_label_add'] : 'Add value'}}
                    </span>       
                    <span
                        class="fs-8 ycmd-blue--text text-decoration-underline pointer"
                            @click="overrideNull = false"
                        v-if="showNullableClearLabel"
                    >
                        {{attributeMap['nullable_label_clear'] ? attributeMap['nullable_label_clear'] : 'Clear value'}}
                    </span>       

                    <time-range-picker
                        v-model="timeRange"
                        :rules="[validateField]"
                        :label="parameter.name ? parameter.name : 'Time range'"
                        v-if="showNullable"
                        />
                </div>
            </template>

            <v-checkbox
                v-model="bool"
                v-else-if="parameter.data_type==='boolean'"
            />
            <user-picker
                v-else-if="parameter.data_type==='AGENT_IDS' || parameter.data_type==='PROVIDER_IDS' || parameter.data_type==='MEMBER_IDS' || parameter.data_type==='USER_IDS' "
                v-model="users"
                :label="attributeMap['caption2'] ? attributeMap['caption2'] : parameter.name"
                :roles="pickerRoles"
                :include_searching_user="true" 
                :single="pickerSingle"
                :users.sync="userlist"
                :rules="[validateField]"
                :filter="userFilter"
                :showGroup="true"
                :additionalVariables="userAdditionalVariables"
                :exclude_group_filter="exclude_group_filter"
            />
            <list
                v-else-if="parameter.data_type==='string' && list"
                v-model="listValue"
                :list="list"
                :defaultValue="attributeMap['default']"
                :multiple="parameter.select_multiple"
                :rules="[validateField]"
            />
            <list
                v-else-if="parameter.data_type==='FACILITY_UNIT'"
                style="max-width: 400px;"
                v-model="facilityUnit"
                :list="facilityUnits"
                :defaultValue="attributeMap['default']"
                :multiple="parameter.select_multiple"
                :rules="[validateField]"
            />
            <textlist
                v-else-if="parameter.data_type==='array'"
                v-model="array"
                :label="attributeMap['caption2'] ? attributeMap['caption2'] : parameter.name"
                :rules="[validateField]"
            />
            <v-text-field
                v-else
                solo light dense flat
                class="small-input"
                v-model="string"
                :label="parameter.name"
                background-color="ycmd-light-gray"
                :rules="[validateField]"
            />

        </v-col>
    </v-row>
</template>

<script>

import TimeRangePicker from '@/components/datetime/time-range-picker'
import DateRangePicker from '@/components/datetime/date-range-picker'
import DatePicker from '@/components/datetime/date-picker'
import UserPicker from '@/components/searches/user-picker'
import List from '@/views/apps/reporting/components/list'
import textlist from '@/components/textlist';

export default {

  props: {
    parameter: {
      default: () => { return {} },
      type: Object
    },
    value: {
      default: null
    },
    list: {
      default: () => { return {} },
      type: Object
    },
    variables: {
      default: () => { return [] },
      type: Array
    }

  },
  components: {
    'date-range-picker': DateRangePicker,
    'date-picker': DatePicker,
    'time-range-picker': TimeRangePicker,
    'user-picker': UserPicker,
    'list': List,
    textlist
  },
  data: () => ({
    groupId: null,
    facilityId: null,
    facilityUnit: null,
    facilityUnitFacility: null,
    dateRange: null,
    groups: [],
    facilities: [],
    isLoadingGroupsSearch: false,
    bool: false,
    string: '',
    users: [],
    listValue: '',
    userlist: [],
    array: [],
    userAdditionalVariables: {},
    timeRange: null,
    overrideNull: false,
    datePicker: null,
    loadingFacilities: false
  }),
  methods: {
    updateObject (obj, values) {
        for (let prop in values) {
            obj[prop] = values[prop]
        }
    },        
    propertyCount (obj) {
        let count=0
        for (let p in obj) {
            count++
        }
        return count
    },
    validateField (v, message) {
        if (!this.parameter || !this.parameter.required) {
            return true //not required.  Passes validation
        }

        return v ? true : 'Required'
    },
    async loadFacilityUnitFacility (variables) {

        if (variables && !variables.facility_id) {
            this.facilityUnitFacility = null
            return
        }

        if (variables && variables.facility_id && this.facilityUnitFacility && this.facilityUnitFacility.id == variables.facility_id) {
            console.log('no need to reload facility unit')
            return
        }

        let facilities = await this.$store.dispatch('report/loadReferential', {query: 'ycmdFacilitySearch', variables: variables})

        this.facilityUnitFacility = facilities[0];

    },
    async loadFacilities (updatedValues) {
        if (this.facilities && this.facilities.length) {
            return
        }
        this.loadingFacilities = true
        let variables = {
        }
        if (updatedValues) {
            this.updateObject(variables, updatedValues)
        }

        //let facilities = await dataClient('ycmdFacilitySearch', variables)
        let facilities = await this.$store.dispatch('report/loadReferential', {query: 'ycmdFacilitySearch', variables: variables})
        this.facilities = facilities
        this.loadingFacilities = false

    },
    async loadGroups (updatedValues) {
    
        if (this.isLoadingGroupsSearch) {
            return
        }

        this.isLoadingGroupsSearch = true;

        let variables = {
            group_id: null
        }
        if (updatedValues) {
            this.updateObject(variables, updatedValues)
        }

        this.groups = await this.$store.dispatch('report/loadReferential', {query: 'phxGroups', variables: variables})

        this.isLoadingGroupsSearch = false;
       
        return this.groups
    },
    updateValue () {
        this.$emit('input', this.propertyValue)
    },
    async refreshValues (newValues) {

        switch (this.parameter.data_type) {
            case 'GROUP_ID':
                await this.loadGroups(newValues)
                break
            case 'FACILITY_ID':
                await this.loadFacilities(newValues)
                break
            case 'FACILITY_UNIT':
                await this.loadFacilityUnitFacility(newValues)
                break
            case 'USER_IDS':
            case 'AGENT_IDS':
            case 'MEMBER_IDS':
            case 'PROVIDER_IDS':
                console.log('this.parameter', this.parameter)

                this.userAdditionalVariables = newValues
                break
            case 'array':
                return this.array.join('|')
        }
        return null

    }
  },
  computed: {
    exclude_group_filter () {
        switch (this.parameter.data_type) {
            case 'AGENT_IDS':
                return true
        }
        return false
    },
    facilityUnits () {
        let arr = []
        if (this.facilityUnitFacility) {   
            if (this.facilityUnitFacility && this.facilityUnitFacility.units && this.facilityUnitFacility.units.length) {
                this.facilityUnitFacility.units.map(u => {
                    arr.push({name: u.name, value: u.shortcode})
                })
            }
        }
        return {
            values: arr
        }
    },
    allGroups () {
        return this.groups
    },
    allFacilities () {
        if (!this.facilities.length) {
            this.loadFacilities()
        }

        return this.facilities
    },
    pickerRoles () {
        switch (this.parameter.data_type) {
            case 'AGENT_IDS':
                return ['agent']
            case 'PROVIDER_IDS':
                return ['provider']
            case 'MEMBER_IDS':
                return ['member']
            case 'USER_IDS':
                return null
        }
        return []
    },
    pickerSingle () {
        return !!this.attributeMap['single']
    },
    propertyValue () {
        console.log('this.parameter', this.parameter.name)
        
        if (this.attributeMap.nullable) {
            if (!this.overrideNull) {
                return null
            }
        }

        switch (this.parameter.data_type) {
            case 'GROUP_ID':
                return this.groupId
            case 'FACILITY_ID':
                return this.facilityId
            case 'FACILITY_UNIT':
                return this.facilityUnit
            case 'daterange':
            case 'datetimerange':
                return this.dateRange
            case 'timerange':
                return this.timeRange
            case 'date':
                return this.datePicker
            case 'boolean':
                return this.bool
            case 'string':
                if (this.list) {
                    return JSON.stringify(this.listValue)
                } else {
                    return this.string
                }
            case 'USER_IDS':
            case 'AGENT_IDS':
            case 'MEMBER_IDS':
            case 'PROVIDER_IDS':
                return this.users && this.users.length ? JSON.stringify(this.users) : ""
            case 'array':
                return this.array.join('|')
        }
        return null
    },
    attributeMap () {
        let ret = {}
        if (this.parameter.attributes && this.parameter.attributes.length) {
            this.parameter.attributes.map(a => {
                ret[a.name] = a.value
            })
        }
        return ret
    },
    userFilter () {
        let filter = []
        if (this.parameter.attributes && this.parameter.attributes.length) {
            this.parameter.attributes.map(a => {
                if (a.name.startsWith('filter|')) {
                    let pieces = a.name.split('|')
                    filter.push({
                        field: pieces[1],
                        value: a.value
                    })
                }
            })
        }
        return filter
    },
    componentParameters () {
        let params = {}
        let variables = {}

        this.variables.map(v => {
            if (typeof(v.value) !== 'undefined' && v.value !== null) {
                variables[v.id] = v.value
                //atLeastOne = true
            }
        })

        let atLeastOne = false

        if (this.parameter.attributes && this.parameter.attributes.length) {
            this.parameter.attributes.map(a => {
                if (a.name.startsWith('parameter|')) {
                    //if (typeof(variables[a.value]) !== 'undefined' && variables[a.value] !== null) {
                    params[a.value] = variables[a.value]
                    //}
                    atLeastOne = true

                }
            })
        }
        if (!atLeastOne) {
            return {}
        }

        return params
    },
    showNullableAddLabel () {
        if (this.attributeMap.nullable) {
            if (!this.overrideNull) {
                return true
            }
        }
        return false
    },
    showNullable () {
        if (this.attributeMap.nullable) {
            if (this.overrideNull) {
                return true
            }
        }
        return false
    },
    showNullableClearLabel () {
        if (this.attributeMap.nullable) {
            if (this.overrideNull) {
                return true
            }
        }
        return false
    }

  },
  watch: {
    groupId (newVal, oldVal) {
        this.updateValue()
    },
    facilityId (newVal, oldVal) {
        this.updateValue()
    },
    facilityUnit (newVal, oldVal) {
        this.updateValue()
    },    
    dateRange (newVal, oldVal) {
        this.updateValue()
    },
    datePicker (newVal, oldVal) {
        this.updateValue()
    },    
    timeRange (newVal, oldVal) {
        this.updateValue()
    },
    bool (newVal, oldVal) {
        this.updateValue()
    },
    string (newVal, oldVal) {
        this.updateValue()
    },
    users (newVal, oldVal) {
        this.updateValue()
    },
    listValue (newVal, oldVal) {
        this.updateValue()
    },
    userlist (newVal, oldVal) {
        this.$emit('update:users', newVal)
    },
    array (newVal, oldVal) {
        this.updateValue()
    },
    componentParameters: {
        async handler (newValue, oldValue) {
            console.log('parameter', this.parameter.name,'newValue', newValue)
            if (this.propertyCount (newValue) ) {
                await this.refreshValues(newValue)
            }
        }, 
        deep: true
    }
  },
  async mounted () {
    
    if (this.parameter.data_type === 'GROUP_ID') {

        await this.loadGroups()
        console.log('this.groups', this.groups)

    }
    
  }
}
</script>