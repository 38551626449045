import Vue from 'vue'
import Vuex from 'vuex'
import layout from './modules/layout'
import menu from './modules/menu'
import rightMenu from './modules/right-menu'
import app from './modules/app'
//import mode from './modules/mode'
import group from './modules/group'
import profile from './modules/profile'
import data from './modules/data'
import chat from './modules/chat'
import report from './modules/report'
import emr from './modules/emr'
import schedule from './modules/schedule'
import idleTimer from './modules/idle-timer'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    layout,
    menu,
    rightMenu,
    app,
    //mode, no longer use group/single view 
    group,
    profile,
    data,
    chat,
    idleTimer,
    report,
    emr,
    schedule
  },
  strict: debug
})
