<template>

  <div>

    <v-dialog v-model="value" persistent max-width="800">

        <v-form
          ref="frmAppointment"
          v-model="appointmentForm"
        >
        <v-card>
            <v-card-title
              class="fs-18 primary white--text"
            >
              Appointment - {{practiceName}}
            </v-card-title>
            <v-card-text class="fs-12 pt-5 pb-0">
                <v-container
                class="pa-0 ma-0"
                >
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0 fw-700"
                    >


                      <v-container
                      class="pa-0 ma-0"
                      >        
                  
                          <v-row
                              class="pa-0 ma-0"
                          >
                              <v-col cols="12"
                                  class="pa-0 ma-0 fw-700"
                              >
                              <div>
                                <label class="v-label v-label--active theme--light primary--text" style="font-size: 10px;font-weight: 400;">Provider</label>

                                <span
                                  class="unobtrusive-on-white"
                                  style="float: right"
                                >id: {{appointment.id}}</span>                              
                              </div>

                              <div>
                                {{`${appointment.provider_lastname ? appointment.provider_lastname+', ':''}${appointment.provider_firstname}${appointment.provider_credentials ? ': ' + appointment.provider_credentials: ''} `}}
                              </div>
                              </v-col>
                          </v-row>
                          <v-row
                              class="pa-0 ma-0"
                              v-if="appointment.provider_specialty"
                          >
                              <v-col cols="12"
                                  class="pa-0 ma-0"
                              >
                              <span class="fw-300">Specialty:</span> <span class="fw-400">{{appointment.provider_specialty}}</span>
                              </v-col>
                          </v-row>

                          <v-row
                              class="pa-0 ma-0"
                          >
                              <v-col cols="12"
                                  class="pa-0 ma-0"
                              >
                              <span class="fw-300">Group:</span> <span class="fw-400">{{practiceName}}</span>
                              <v-divider />
                              </v-col>
                          </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0 fw-700"
                    >

                      <v-container
                      class="pa-0 ma-0"
                      >
                          <v-row
                              class="pa-0 ma-0"
                          >
                              <v-col cols="12"
                                  class="pa-0 ma-0"
                              >
                                <label class="v-label v-label--active theme--light primary--text" 
                                      style="font-size: 10px;font-weight: 400;">
                                      Patient
                                </label>
                                <div
                                  class="fw-700"
                                >
                                  {{`${patient.lastname ? patient.lastname+', ':''}${patient.firstname}${patient.credentials ? ': ' + patient.credentials: ''} `}}
                                </div>
                              </v-col>
                          </v-row>
                          <v-row
                              class="pa-0 ma-0"
                          >
                              <v-col cols="6"
                                  class="pa-0 ma-0 fw-700"
                              >
                                  <v-row
                                      class="pa-0 ma-0"
                                      v-if="patient.dob"
                                  >
                                      <v-col cols="12"
                                          class="pa-0 ma-0"
                                      >
                                      <span class="fw-300">Dob:</span> <span class="fw-400">{{patient.dob}}</span>
                                      </v-col>
                                  </v-row>
                                  <template
                                      v-if="patient.phones && patient.phones.length"
                                  >
                                      <v-row
                                          class="pa-0 ma-0"
                                          v-for="(phone,i) in patient.phones"
                                          :key="`phone_${patient.id}_${i}`"
                                      >
                                          <v-col cols="12"
                                              class="pa-0 ma-0"
                                          >
                                          <span class="fw-300">{{phone.name}}:</span> <span class="fw-400">{{scrubPhoneNumber(phone.value)}}</span>
                                          </v-col>
                                      </v-row>
                                  </template>

                              </v-col>
                              <v-col cols="6"
                                  class="pa-0 ma-0 fw-700"
                              >
                                  <v-row
                                      class="pa-0 ma-0"
                                      v-if="patient.address1"
                                  >
                                      <v-col cols="12"
                                          class="pa-0 ma-0"
                                      >
                                          <span class="fw-300">Address: </span>
                                          <span class="fw-400">{{patient.address1}}</span>
                                      </v-col>
                                  </v-row>
                                  <v-row
                                      class="pa-0 ma-0"
                                      v-if="patient.address1 && patient.address2"
                                  >
                                      <v-col cols="12"
                                          class="pa-0 ma-0"
                                      >
                                          <span class="fw-300">Address (cont): </span>
                                          <span class="fw-400">{{patient.address2}}</span>
                                      </v-col>
                                  </v-row>
                                  <v-row
                                      class="pa-0 ma-0"
                                      v-if="patient.address1"
                                  >
                                      <v-col cols="6"
                                          class="pa-0 ma-0"
                                      >
                                          <span class="fw-300">City: </span>
                                          <span class="fw-400">{{patient.city}}</span>
                                      </v-col>
                                      <v-col cols="3"
                                          class="pa-0 ma-0"
                                      >
                                          <span class="fw-300">State: </span>
                                          <span class="fw-400">{{patient.state}}</span>
                                      </v-col>
                                      <v-col cols="3"
                                          class="pa-0 ma-0"
                                          v-if="patient.address1 && patient.zip"
                                      >
                                          <span class="fw-300">Zip: </span>
                                          <span class="fw-400">{{patient.zip}}</span>
                                      </v-col>                                
                                  </v-row>



                              </v-col>
                          </v-row>

                          <v-divider />
                      </v-container>



                    </v-col>
                  </v-row>
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                        class="pa-0 ma-0 fw-700"
                    >



                    <v-container
                    class="pa-0 ma-0"
                    >
                        <v-row
                            class="pa-0 ma-0"
                        >
                            <v-col cols="12"
                                class="pa-0 ma-0 fw-700"
                            >
                                <label class="v-label v-label--active theme--light primary--text" 
                                      style="font-size: 10px;font-weight: 400;">
                                      Location
                                </label>
                                <div>                            
                                  {{appointment.location_name}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row
                            class="pa-0 ma-0"
                        >
                            <v-col cols="6"
                                class="pa-0 ma-0 fw-700"
                            >
                                <v-row
                                    class="pa-0 ma-0"
                                    v-if="appointment.location_phone"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                    <span class="fw-300">Phone:</span> <span class="fw-400">{{appointment.location_phone}}</span>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"
                                    v-if="appointment.location_fax"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                    <span class="fw-300">Fax:</span> <span class="fw-400">{{appointment.location_fax}}</span>
                                    </v-col>
                                </v-row>

                            </v-col>
                            <v-col cols="6"
                                class="pa-0 ma-0 fw-700"
                            >
                                <v-row
                                    class="pa-0 ma-0"
                                    v-if="appointment.location_address1"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                        <span class="fw-300">Address: </span>
                                        <span class="fw-400">{{appointment.location_address1}}</span>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"
                                    v-if="appointment.location_address1 && appointment.location_address2"
                                >
                                    <v-col cols="12"
                                        class="pa-0 ma-0"
                                    >
                                        <span class="fw-300">Address (cont): </span>
                                        <span class="fw-400">{{appointment.location_address2}}</span>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"
                                    v-if="appointment.location_address1"
                                >
                                    <v-col cols="6"
                                        class="pa-0 ma-0"
                                    >
                                        <span class="fw-300">City: </span>
                                        <span class="fw-400">{{appointment.location_city}}</span>
                                    </v-col>
                                    <v-col cols="3"
                                        class="pa-0 ma-0"
                                    >
                                        <span class="fw-300">State: </span>
                                        <span class="fw-400">{{appointment.location_state}}</span>
                                    </v-col>
                                    <v-col cols="3"
                                        class="pa-0 ma-0"
                                        v-if="appointment.location_address1 && appointment.location_zip"
                                    >
                                        <span class="fw-300">Zip: </span>
                                        <span class="fw-400">{{appointment.location_zip}}</span>
                                    </v-col>                                
                                </v-row>



                            </v-col>
                        </v-row>

                        <v-divider />
                    </v-container>



                    </v-col>
                  </v-row>
                  
                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="6"
                      class="pa-0 ma-0 fw-700"
                    >
                      <label class="v-label v-label--active theme--light primary--text" 
                            style="font-size: 10px;font-weight: 400;">
                            Scheduled on
                      </label>                    
                    </v-col>
                    <v-col cols="6"
                      class="pa-0 ma-0 fw-700"
                    >
                      <label class="v-label v-label--active theme--light primary--text" 
                            style="font-size: 10px;font-weight: 400;">
                            Duration
                      </label>                    
                    </v-col>
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="6"
                      class="pa-0 ma-0 fw-700"
                    >
                      {{scheduledOn}}
                    </v-col>
                    <v-col cols="6"
                      class="pa-0 ma-0 fw-700"
                    >
                      {{formatDuration}}
                    </v-col>
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                      v-if="reason"
                  >
                    <v-col cols="12"
                      class="pa-0 ma-0 fw-700"
                    >
                      <label class="v-label v-label--active theme--light primary--text" 
                            style="font-size: 10px;font-weight: 400;">
                            Reason
                      </label>    
                      <div>
                        {{reason}}
                      </div>                
                    </v-col>
                  </v-row>


                  <v-row
                      class="pa-0 ma-0"
                      v-if="appointmentType"
                  >
                    <v-col cols="12"
                      class="pa-0 ma-0 fw-700"
                    >
                      <label class="v-label v-label--active theme--light primary--text" 
                            style="font-size: 10px;font-weight: 400;">
                            Type
                      </label>    
                      <div>
                        {{appointmentType}}
                      </div>                
                    </v-col>
                  </v-row>

                  <v-row
                      class="pa-0 ma-0"
                  >
                    <v-col cols="12"
                      class="pa-0 ma-0 fw-300"
                    >
                      <label class="v-label v-label--active theme--light primary--text" 
                            style="font-size: 10px;font-weight: 400;">
                            Notes
                      </label>    
                      <emr-notes :notes="appointmentNotes" />              
                    </v-col>
                  </v-row>                                        

                </v-container>


            </v-card-text>
            <v-card-subtitle
              class="pa-0 ma-0"
            >
              <div
                class="error white--text pl-4"
                v-if="error"
              >
                {{error}}
              </div>
              <div
                v-else
              >
                &nbsp;
              </div>
            </v-card-subtitle>
            <v-card-actions
              class="pt-0 pl-2 pb-3 pr-3 ma-0 "
            >
                <v-btn
                  color="#db6d7d"
                  @clickxxx="cancelAppointment"
                  @click="confirmAppointmentCancel=true"
                  text
                  :disabled="sending || deleting"
                  :loading="deleting"
                >
                  Cancel Appointment
                </v-btn>
                <v-spacer/>
                <!--
                <v-btn
                  @click="save"
                  class="primary"
                  :disabled="sending"
                  :loading="sending"
                >
                  Save
                </v-btn>
                -->
                <v-btn
                  @click="cancel"
                  :disabled="sending || deleting"
                >
                  Close
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-form>

    </v-dialog>

      <confirmation
          v-model="confirmAppointmentCancel"
          title="Cancel Appointment"
          subtitle=""
          :text="confirmationText"
          @click="confirmationClicked"
          :buttons="confirmationButtons"
          max-width="500"
      />     

  </div>

</template>

<script>
  import { mapGetters  } from "vuex";
  import emrProviderSelect from '@/views/apps/emr/components/emrProviderSelect';
  import emrLocationSelect from '@/views/apps/emr/components/emrLocationSelect';
  import emrPatientSelect from '@/views/apps/emr/components/emrPatientSelect';
  import emrDurationSelect from '@/views/apps/emr/components/emrDurationSelect';
  import emrReasonSelect from '@/views/apps/emr/components/emrReasonSelect';
  import timeEntry from '@/components/datetime/time-entry';
  import DatePicker from '@/components/datetime/date-picker';
  import {formatSimple} from '@/methods/formatters';
  import {scrubPhoneNumber} from '@/methods/globalMethods';
  import emrNotes from '@/views/apps/emr/components/emrNotes';
  import Confirmation from '@/components/modals/confirmation.vue'
  import {utcToZonedTime} from 'date-fns-tz';
  export default {
    name: 'emrAppointmentEdit',
    props: {
      value: {
        default: false,
        type: Boolean
      },
      appointment: {
        default: () => {},
        type: Object
      },
      error: {
        default: null,
        type: String
      },
      timezone: {
        default: null,
        type: String
      },      

    },
    components: {
        'emr-provider-select': emrProviderSelect,
        'emr-location-select': emrLocationSelect,
        'emr-duration-select': emrDurationSelect,
        'emr-patient-select': emrPatientSelect,
        'emr-reason-select': emrReasonSelect,
        'time-entry': timeEntry,
        'date-picker': DatePicker,
        'emr-notes': emrNotes,
        'confirmation': Confirmation,
    },
    data: () => ({
      sending: false,
      deleting: false,
      selectedProvider: null,
      selectedLocation: null,
      selectedPatient: null,
      selectedDuration: null,
      refresh: '',
      formatSimple,
      selectedReason: null,
      description: null,
      rules: {
          required: (value) => {
            return value ? true : 'Required'
          },
      },    
      appointmentForm: false,  
      confirmAppointmentCancel: false,
      scrubPhoneNumber,
      utcToZonedTime
    }),
    mounted() {
    },
    methods: {
      async confirmationClicked (btn) {
          if (btn.caption === 'OK') {
            this.deleting = true
            this.cancelAppointment()
          }
          this.confirmAppointmentCancel = false          
      },

      clearAll () {
        this.sending = false
        this.selectedProvider = null
        this.refresh = (new Date()).toISOString()
      },
      saveFinished () {
        this.sending = false
      },
      cancelFinished () {
        this.sending = false
        this.deleting = false
        this.confirmAppointmentCancel = false
      },
      save () {

        console.log('this.appointmentForm', this.appointmentForm)

        let isValid = this.$refs.frmAppointment.validate()

        if (isValid) {
          this.sending = true
          this.$emit('save', {appointment: this.appointment, callback: this.saveFinished})
        }

      },
      cancel () {
        this.clearAll()
        this.$emit('close')
      },   
      cancelAppointment () {
        this.sending = true
        this.$emit('cancelAppointment', {appointment: this.appointment, callback: this.cancelFinished})
      },
      allowedDates (date) {
        let now = this.formatSimple(new Date(),'yyyy-MM-dd')
        if (now > date) {
          return false
        }
        return true
      }  
    },
    computed: {
      ...mapGetters(
          'emr',['getProviders','getPractices','getProviderPaletteFg', 'getProviderPaletteBg']
      ),
      confirmationText () {

        let txt = "Are you sure you want to cancel the appointment?"
        if (this.appointment) {
          let so = this.scheduledOn ? ` on ${this.scheduledOn}`: ''
          let w = this.appointment.provider_lastname || this.appointment.provider_firstname ? ` with ${this.appointment.provider_firstname} ${this.appointment.provider_lastname}`: ''

          txt = `Are you sure you want to cancel the appointment for ${this.patient.firstname} ${this.patient.lastname}${so}${w}`
        }
        return txt
      },
      confirmationButtons () {
          return [
              {caption: 'OK', disabled: this.deleting},
              {caption: 'Cancel', disabled: this.deleting}
          ]
      },
      requiredRules () {
        return [this.rules.required]
      },
      localAppointmentDate: {
          get: function() {
            return this.appointmentDate;
          },
          set: function(newValue) {

            this.$emit('update:appointmentDate', newValue)
            //this.$emit('input', newValue)
          }
      },
      localAppointmentTime: {
          get: function() {
            return this.appointmentTime;
          },
          set: function(newValue) {
            this.$emit('update:appointmentTime', newValue)
            //this.$emit('input', newValue)
          }
      }, 
      patient () {
        console.log('this.appointment', this.appointment)
        return this.appointment && this.appointment.patient ? this.appointment.patient : {}
      },
      providerId () {
        return this.appointment && this.appointment.providerid ? this.appointment.providerid : ''
      },
      practice () {
        if (this.appointment && this.appointment.practiceid) {
          let p = this.$store.getters['emr/getPracticeById'](this.appointment.practiceid)
          return p
        }

        return {}
      },
      
      practiceName () {
        return this.practice.name
      },
      reason () {
        if (this.appointment && this.appointment.practiceid && this.appointment.reasonid) {
          let r = this.$store.getters['emr/getReferential'](this.appointment.practiceid, 'reason', this.appointment.reasonid)
          return r
        }

        return ''
      },
      scheduledOn () {
        if (this.appointment && this.appointment.start) {
          let dt = new Date(this.appointment.start)
          let fs =  `${this.formatSimple(dt,'MMMM do yyyy')} at ${this.formatSimple(dt,'h:mm aaa')}`
          console.log('fs', fs)

          return fs
        }
      },
      formatDuration () {
        if (this.appointment && this.appointment.duration) {
          
          let sec = (parseFloat(this.appointment.duration) * 60).toFixed(0);

          let hrs = Math.floor(sec/3600);
          if (hrs >= 1) {
            sec = sec - (hrs*3600)
          }

          let min = Math.floor(sec/60);
          if (min >= 1) {
            sec = sec - (min*60)
          }

          let hours = ''          
          if (hrs) {
            hours = `${hrs} hours`
          }
          let minutes = '' 
          if (min) {
            if (hours) {
              minutes = ' and '
            }           
            minutes += `${min} minutes`
          }

          return `${hours}${minutes}`

        }

      },
      appointmentType () {

        if (this.appointment && this.appointment.type) {
          return this.appointment.type
        }
        return null
      },
      appointmentNotes () {
        let notes = []
        if (this.appointment && this.appointment.notes && this.appointment.notes.length) {
          this.appointment.notes.map(note => {
            debugger

            let tz = this.timezone
            
            let dt = new Date(tz ? this.utcToZonedTime(note.created, tz) :note.created)

            let formatted =  `${this.formatSimple(dt,'MM/dd/yy')} ${this.formatSimple(dt,'h:mm aaa')}`
          
            notes.push({
              created: formatted,
              text: note.text
            })

          })
        }

        return notes

      }
    }
  }
</script>

