import gql from 'graphql-tag'

export default gql`query ycmdGroupGet {
	ycmdGroupGet {
        address {
  			street
  			street2
  			city
  			state
  			zip_code
        }
  		alt_name
  		entity_type
  		facilities {
			id
  			name
        }
  		fax
  		group_type
  		phone
  		settings {
  			transfer_patient
  			force_app_lock
  			show_provider_phone_numbers
			inactivity_timeout_minutes
			chat_allow_same_participant_rooms
			explicit_message_confirmation
			explicit_message_confirmation_can_override  			
			authentication_type
			authentication_grace_days
        }
  		mail_address {
  			street
  			street2
  			city
  			state
  			zip_code
        }
		greeting
  		name
  		time_zone
  		website
  		ycmd_phone
  		patient_tags {
            label
  			color
			id
        }
		hours {
			day
			open
			close
		}
		lunch_phone
    }
}`
