import gql from 'graphql-tag'

export default gql`query ycmdProfileGet($accountType: AccountType) {
	ycmdProfileGet(accountType: $accountType)
    {
        first_name
        last_name
        middle_initial
        street
        street2
        city
        state
        zip_code
        email
        mobile_number
        mobile_number_verified
        settings {
            explicit_message_confirmation
            explicit_message_confirmation_can_override

            out_of_office {
                message
                id
                start
                end
            }

            enable_chat_notifications
            enable_chat_notifications_days {
                day
                start_time
                end_time
            }
            room_settings {
                room_id
                settings {
                    enable_chat_notifications
                    enable_chat_notifications_days {
                        day
                        start_time
                        end_time
                    }
                }
            }
        }        
    }
}`
