import gql from 'graphql-tag'

export default gql`query ycmdCallLogGet($call_id: ID, $page: Int, $limit: Int) {
	ycmdCallLogGet(call_id: $call_id, page: $page, limit: $limit)
    {
		total_count
		calls {
			from
			unix_date_created
			duration
			recordings {
				_id
				sid
				date_created
				date_updated
				account_sid
				call_sid
				duration
				amazon_s3
			}
	    }
    }
}`
