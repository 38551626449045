<template>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      
      offset-y    
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="btnChatStatus"
          color="primary"
          dark
          plain
          v-bind="attrs"
          v-on="on"
          width="100%"
          class="white--text pa-0 ma-0"
        >
          <template v-slot>
            <slot>
              <button-faceplate style="height: 100%" />
            </slot>
          </template>
        </v-btn>
      </template>
      <chat-status-dialog 
        :initialize="initialize"
      />
    </v-menu>

  

</template>

<script>
import buttonFaceplate from '@/views/apps/chat/components/chat-status-button-faceplate.vue';
//import chatRoomDisplay from '@/views/apps/chat/components/chat-status-room-display.vue';
import chatStatusDialog from '@/views/apps/chat/components/chat-status-dialog.vue';
import {mapGetters, mapActions} from "vuex";

export default {
  props: {
  },
  data: () => ({
    menu: false,
    initialize: 0
  }),
  methods: {
    ...mapActions('chat', ['loadCurrentRoom']),
  },
  computed: {
    ...mapGetters('chat', ['getCurrentRoom']),
  },
  components: {
    'button-faceplate': buttonFaceplate,
//    'chat-status-room-display': chatRoomDisplay,
    'chat-status-dialog': chatStatusDialog
  },
  watch : {
    menu (newValue, oldValue) {
      if (newValue) {
        let newInit = (new Date()).getTime()
        if (!this.initialize) {
          //the very first time we open this, we need time to create the component otherwise the watch on the parameter won't fire
          //give it 500 ms to create
          setTimeout(() => {
            this.initialize = newInit 
          }, 500);
        } else {
          this.initialize = newInit
        }      
      }
      this.$store.commit('chat/setChatOpened', newValue)

    } 
  }

}
</script>

<style scoped>
#btnChatStatus >>> span.v-btn__content {
  width: 100%; white-space: normal; 
}
</style>