<template>

        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" class="full-height mt-2">
            <div slot="no-more" class="infinite-loading__text ycmd-light-blue__text"></div>
            <div slot="no-results">
                <div :class="`mt-4 pt-4 fs-20 fw-500 ${ theme === 'dark' ? 'white--text' : 'ycmd-medium-blue--text' }`">No Results</div>
                <v-tooltip bottom
                           nudge-bottom="10"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark
                               v-on="on"
                               @click="refreshHandler()"
                               :color="theme === 'dark' ? 'white' : 'ycmd-green'"
                               class="mt-4">
                            <v-icon :size="36">refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Refresh</span>
                </v-tooltip>
            </div>
            <div slot="spinner">
                <div :class="`lds-ellipsis ${ theme === 'light' ? 'light' : '' }`"><div></div><div></div><div></div><div></div></div>
            </div>
        </infinite-loading>

</template>

<script>

    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        name: 'infiniteLoader',
        components: {
            'infinite-loading': InfiniteLoading
        },
        props: {
          infiniteHandler: Function,
          infiniteId: Number,
          refreshHandler: Function,
          theme: {
            default: 'dark'
          }
        }
    }
    </script>

<style scoped>

</style>
