import gql from 'graphql-tag'

export default gql`mutation ycmdChatRoomParticipantAdd(
    $room_id: ID!, 
    $user_id: ID!) {
    ycmdChatRoomParticipantAdd(
        room_id: $room_id,
        user_id: $user_id) 
    {
        id
        created_by_id
        date_created
        date_updated
        last_post_id
        last_post_id
        unread_count
        name
        room_type
        participants {
            date_joined
            date_left
            date_last_seen
            last_post_seen
            user_id
            first_name
            last_name
            creator
            title
            specialty
            group_id
            group_name
            online_status
            status
            ooo
            app_version
        }
        meta {
            name
            value
        }
        tags
	}
}`
