import router from '../router'
import store from '../store'
import SubscriptionService from '@/methods/subscriptionInstance'

export function clearLocalStorage() {

    // keep these ones
    const profilePicture = localStorage.getItem("profilePicture");

    localStorage.clear();

    // re-set items
    localStorage.setItem("profilePicture", profilePicture);
}

export function logout() {

    //save whether we are truly logged in
    let refresh = false
    if (localStorage.accessToken) {
        refresh = true
    }

    console.log("TERMINATING SUBSCRIPTIONS...");

    SubscriptionService.terminateAll();

    console.log("LOGGING OUT...");

    store.dispatch('rightMenu/closeRightMenu');

    console.log("CLOSING RIGHT MENU...");

    clearLocalStorage();

    console.log("CLEARING LOCAL STORAGE...");

    store.commit('app/setAuthenticationStatusOpen', false)
    store.commit('app/resetState')
    store.commit('chat/resetState')
    store.commit('data/setPcpData',{})
    store.commit('emr/resetState')
    store.commit('group/resetState')
    store.commit('idleTimer/resetState')
    store.commit('profile/resetState')
    store.commit('report/resetState')

/*
    console.log('store', store)
    Object.keys(store._mutations).forEach(mutationName => {
        console.log('mutationName', mutationName)
        if (mutationName.endsWith('resetState')) {
            let mutation = store._mutations[mutationName]
            console.log('mutation', mutation)
            mutation[0]()
        }

    })
*/
    /*

    Object.keys(store._modules).forEach(moduleName => {
        let module = store._modules[moduleName]
    })

    store._modules.map(module => {
        console.log('module', module)

    })
    */
   
    //store.dispatch('rightMenu/routeRightMenu', {name: 'CHAT.rooms', transition: 'next'});

    route('login', 'fade-in-transition')

    //having problems with UI controls (tooltips) hanging around after page is ripped out from underneath. 
    //for now, just reload after we log out.
    //
    //Problem is that if we just reload, we get an infinite loop as apparently the logout method is called
    //when the app is loaded.  So when we enter this routine, check if there is a token.  If there is, set a flag
    //then refresh when everything is cleaned up

    //no longer needed
    if (refresh) {
        //location.reload();
    }
}

export function checkHasTokensPhoenix() {

    return localStorage.getItem("accessToken");
}
export function checkHasTokensPCP() {

    return localStorage.getItem("accessToken");
}
export function routeBasedOnRole(route) {

    const userRoles = store.getters['profile/getRoles']();

    //console.log('user roles?: ', userRoles);

    // check if roles do not exist
    if (!userRoles || userRoles.length === 0){
        logout();
    }
    else{
        let token = localStorage.getItem('accessToken')
        if (token) {
            let appIndex = 0

            console.log('route', route)

            if (route && route.fullPath) {               
                appIndex = store.getters['app/appIndexFromPath'](route.fullPath)
            } else {
                appIndex =  0
            }

            if (appIndex >=  0) {
                store.dispatch('app/changeApp', appIndex);
            }
        }
    }
}

export function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}

export function scrubPhoneNumber (phone, add_country) {
    if (!phone) {
        return phone
    }

    let numbers=(phone+'').replace(/\D/g, '').replace(/ /g, '');
    if (add_country){
        if (numbers[0] != '1'){
            numbers=`1${numbers}`;
        }
        return `+${numbers}`;    
    } else {
        numbers = numbers.substring(numbers.length-10)
    }
    
    return `(${numbers.substring(0,3)}) ${numbers.substring(3,6)}-${numbers.substring(6,10)}`;
};

export const parsePcpStatus = {
    "A01": "Admitted",
    "A02": "Patient Transfer",
    "A03": "Discharged",
    "A04": "Registered",
    "A05": "Preadmit a Patient",
    "A06": "Change an Outpatient to Inpatient",
    "A07": "Inpatient to outpatient transfer",
    "A08": "Update patient information",
    "A11": "Cancel admission ",
    "A12": "Cancel transfer",
    "A13": "Cancel discharge",
    "A17": "Swap patients",
    "A18": "Merge Records",
    "A23": "Delete a patient record",
    "A31": "Update Person ",
    "A35": "Change Patient Account Number",
    "A36": "Medical record number change",
    "A40": "Merge two Patients based on internal patient id"
}

export const parseUserRoles = {
    'provider': 'Provider',
    'member': 'Provider',
    'member.admin': 'Provider Admin',
    'pcp_admin': 'PCP Admin',
    'pcp_group_admin': 'PCP Group Admin',
    'pcp_facility_admin': 'PCP Facility Admin',
    'pcp_analyst': 'PCP Analyst',
    'pcp_user': 'PCP User'
}

export function route(name, transition) {

    router.push({
        name: name,
        params: {
            transition: transition || 'fade-in-transition'
        }
    }).catch(err => {
        console.log('router error: ', err)
    });
}

export function escape_for_regex (str) {

    let special_characters = [
        '\\',
        '^',
        '$',
        '.',
        '|',
        '?',
        '*',
        '+',
        '(',
        ')',
        '[',
        '{',
    ];

    special_characters.map(c => {
        str = str.replace(c,'\\'+c);
    })
    
    return str;
};

