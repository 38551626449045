<template>

  <div class="view" id="mainChat">

      <i-card :viewIndex="card.index"
              :viewCount="2"
              :ripple-enabled="true"
              :leftPanel="card.panels.left"
              :rightPanel="card.panels.right"
              :topPanel="card.panels.top"
              :bottomPanel="card.panels.bottom"
              class="i-card mr-1 mb-2 br-2 pb-2 white">

        <div class="i-card__main">

          <div class="i-card__main__header ycmd-medium-blue">

            <div class="d-flex flex-column full-width">
              <div class="d-flex align-center full-width white--text pl-3 pr-3"
                   style="height:56px;border-bottom: solid 2px #125ea3">
                <div v-if="!imageMaximized" class="d-flex align-center full-width pr-2" >
                  <div>
                    <v-btn icon dark elevation="0" @click="changeView({view: 'chat-rooms', transition: 'prev', params: {room_id, position: parentPosition, limitOverride: parentLimitOverride}})">
                      <v-icon size="18">arrow_back</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 pl-2 pr-2 fs-12 text-uppercase">
                    {{ room_name || 'Conversation' }}
                  </div>
                  <div class="d-flex align-center">
                    <v-tooltip top
                               nudge-top="5"
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-1 pb-2">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            icon dark depressed
                            v-on="on"
                            class="ma-0"
                            @click="changeView({view: 'chat-edit', transition: 'next', params: {room_id}})"
                            color="white">
                          <v-icon dark size="16" class="pl-1 pr-1">edit</v-icon>
                        </v-btn>
                      </template>
                      <span class="text-no-wrap">Edit</span>
                    </v-tooltip>
                  </div>
                </div>
<!--                
                <div class="d-flex align-center full-height pl-3 ml-1" style="border-left: solid 2px #125ea3">
                  <v-tooltip top
                             nudge-top="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-1 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon dark depressed
                          v-on="on"
                          class="ma-0"
                          @click="isRightMenuExpanded ? collapseRightMenu() : expandRightMenu()">
                        <v-icon dark size="20">{{ isRightMenuExpanded ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-no-wrap">{{ isRightMenuExpanded ? 'Minimize Chat' : 'Maximize Chat' }}</span>
                  </v-tooltip>
                </div>
-->                
              </div>
              
<!--              <div class="d-flex align-center full-width white&#45;&#45;text pl-4 pr-1"-->
<!--                   style="border: solid 2px #125ea3; border-left:none;border-right:none;background-color: #1465b0">-->
<!--                <v-text-field dark flat solo hide-details clearable prepend-icon="search"-->
<!--                              background-color="transparent"-->
<!--                              :class="`chat-search-bar ${ imageMaximized ? 'hidden' : '' }`"-->
<!--                              label="Search in room..."/>-->
<!--              </div>-->
            </div>

          </div>

          <div class="i-card__main__content relative">

            <template v-if="loading">
                  <loading theme="light" :center="true" class="pr-5" />
            </template>

            <template v-else>

              <div id="chatContainer" class="i-card__scrollable white--text">

                <div v-if="prevToken" class="pa-4 pb-0">
                  <template v-if="loadingPrevious">
                    <div class="d-flex align-center justify-center" style="height: 36px"><div class="lds-ellipsis" style="top:-6px"><div></div><div></div><div></div><div></div></div></div>
                  </template>
                  <div v-else class="d-flex justify-center">
                    <v-btn dark block small text class="ycmd-light-blue" @click.stop="loadMore('prev')">
                      <span>Load Previous</span>
                    </v-btn>
                  </div>
                </div>

                <div class="mt-4 mb-4 black--text">
                  <div v-for="(post, index) in posts"
                       :key="index"
                       :value="post.id"
                       @click="downloadAttachment(post, $event)"
                       :class="getImageClass(post.attachment_id)">

                    <template v-if="post.grouped_date">
                      <div class="d-flex align-center justify-center flex-grow-1 fs-13 fade-1 ml-4 mr-4 pt-4 pb-4"
                           style="border-bottom: solid 1px #5894cc">
                        {{ post.grouped_date }}
                      </div>
                    </template>

                    <template v-if="post.type === 'system'">
                      <div class="d-flex align-center pa-4" style="margin-right: 8px">
                        <div class="pl-1 fs-11">{{ post.text }}</div>
                        <v-spacer/>
                        <div class="pl-4 fade-2 fs-10 no-wrap">
                          {{ getTime({ start_date: post.date_created}) }}
                        </div>
                      </div>
                    </template>

                    <template v-if="post.type === 'post'">

                      <template v-if="post.user_id === info.userID">
                        <my-post :post="post" class="pa-4 pt-1 mr-2" :style="`margin-left: ${post.error ? '6' : '42'}px`" @resend="resendPost">
                          <v-tooltip top
                                     nudge-top="5"
                                     :disabled="$vuetify.breakpoint.xsOnly"
                                     color="ycmd-black"
                                     content-class="fade-0 pa-4 pt-1 pb-2">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                  icon dark depressed
                                  v-on="on"
                                  class="ma-0"
                                  @click="changeView({view: 'chat-details', transition: 'next', params: { post_id: post.id, room_id, room_name }})"
                                  color="white">
                                <v-icon dark size="18" class="pl-1 pr-1">more_vert</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-no-wrap">Details</span>
                          </v-tooltip>
                        </my-post>
                      </template>

                      <template v-else>
                        <user-post :post="post" class="pa-4 pt-1" style="margin-right: 8px">
                          <v-tooltip top
                                     nudge-top="5"
                                     :disabled="$vuetify.breakpoint.xsOnly"
                                     color="ycmd-black"
                                     content-class="fade-0 pa-4 pt-1 pb-2">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                  icon dark depressed
                                  v-on="on"
                                  class="ma-0"
                                  @click="changeView({view: 'chat-details', transition: 'next', params: { post_id: post.id, room_id, room_name }})"
                                  color="white">
                                <v-icon dark size="18" class="pl-1 pr-1">more_vert</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-no-wrap">Details</span>
                          </v-tooltip>
                        </user-post>
                      </template>

                    </template>

                    <template v-if="post.attachment_id">

                      <template v-if="post.msg_attachment">
                        <div class="pa-4">
                          <div class="d-flex full-width ycmd-medium-gray br-2 pa-4">
                            <div>
                              <v-icon dark size="48">person</v-icon>
                            </div>
                            <div class="d-flex justify-space-around flex-column pl-4">
                              <div>
                                <span>{{ post.msg_attachment.patient_name }}</span>
                                <span class="fade-2 pl-2">({{ post.msg_attachment.patient_dob }})</span>
                              </div>
                              <div class="fade-2">
                                {{ post.msg_attachment.facility_name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-else>
                        <div :class="`d-flex flex-column ycmd-light-grey ${ imageMaximized ? 'pa-0' : 'selectable mt-4 mb-4' }`">
                          <div v-if="!imageMaximized" class="d-flex pa-4">
                            <div class="fade-2 fs-11 pb-2">
                              {{ post.user_id === info.userID ? 'You uploaded' : post.first_name + ' ' + post.last_name }}
                            </div>
                            <v-spacer />
                            <div class="fade-2 fs-10 pb-2">
                              {{ getTime({ start_date: post.date_created}) }}
                            </div>
                          </div>
                          <chat-attachment class="pb-2" :room_id="room_id" :attachment_id="post.attachment_id" view="full">
                            <template v-if="!imageMaximized">
                              <v-tooltip top
                                         nudge-top="5"
                                         :disabled="$vuetify.breakpoint.xsOnly"
                                         color="ycmd-black"
                                         content-class="fade-0 pa-4 pt-1 pb-2">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                      icon dark depressed
                                      v-on="on"
                                      class="ma-0 mr-4"
                                      @click="changeView({view: 'chat-details', transition: 'next', params: { post_id: post.id, room_id, room_name }})"
                                      color="white">
                                    <v-icon dark size="18" class="pl-1 pr-1">more_vert</v-icon>
                                  </v-btn>
                                </template>
                                <span class="text-no-wrap">Details</span>
                              </v-tooltip>
                            </template>
                          </chat-attachment>
                        </div>
                      </template>
                    </template>

                  </div>
                </div>

<!--                <div v-if="nextToken" class="pa-4">-->
<!--                  <template v-if="loadingNext">-->
<!--                    <div class="d-flex align-center justify-center" style="height: 36px"><div class="lds-ellipsis" style="top:-6px"><div></div><div></div><div></div><div></div></div></div>-->
<!--                  </template>-->
<!--                  <div v-else class="d-flex justify-center">-->
<!--                    <v-btn dark small text class="ycmd-light-blue full-width" @click.stop="loadMore('next')">-->
<!--                      <span>Load Next</span>-->
<!--                    </v-btn>-->
<!--                  </div>-->
<!--                </div>-->

              </div>

            </template>

            <div class="d-flex align-center full-width ycmd-dark-blue white--text pa-2"
                 style="border-top: solid 2px #125ea3"
                  v-if="!loading"
                 >
              <v-tooltip top
                         nudge-top="5"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-1 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon dark depressed
                      v-on="on"
                      class="ma-0"
                      @click.stop="selectFile"
                      :disabled="sendingPost"
                      :loading="sendingAttachment"
                      color="white">
                    <v-icon dark size="18">insert_photo</v-icon>
                  </v-btn>
                </template>
                <span class="text-no-wrap">Add Picture</span>
              </v-tooltip>
              <input type="file" ref="inputUpload" style="display:none;" v-on:change="fileSelected" multiple />
              <v-textarea dark flat solo hide-details no-resize auto-grow
                          v-model="textToPost"
                          rows="1"
                          :disabled="sendingPost || sendingAttachment"
                          background-color="#0d4171"
                          class="ml-2 mr-2"
                          label="Post a message..."/>
              <v-tooltip top
                         nudge-top="5"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-1 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon dark depressed
                      v-on="on"
                      @click="savePost"
                      :disabled="sendingAttachment || textToPost.length === 0"
                      :loading="sendingPost"
                      class="ma-0"
                      color="white">
                    <v-icon dark size="18">send</v-icon>
                  </v-btn>
                </template>
                <span>Send</span>
              </v-tooltip>
            </div>
          </div>
        </div>

        <v-dialog persistent v-model="dialog" width="336" attach="#mainChat">
          <div class="d-flex full-width ycmd-blue br-2 white--text">
            <div class="d-flex flex-column full-width ycmd-blue-vertical-gradient pb-4">
              <div class="d-flex align-center pa-2" style="border-bottom: solid 2px #125ea3">
                <v-btn icon dark depressed class="ma-0" @click="closeDialog">
                  <v-icon dark size="20">close</v-icon>
                </v-btn>
                <div class="fs-13 pl-4 uppercase">{{ dialogTitle }}</div>
              </div>
              <div v-html="dialogMessage" class="d-flex align-center justify-center pa-4 flex-grow-1 fade-2" />
            </div>
          </div>
        </v-dialog>
      </i-card>

  </div>
</template>

<script>

  import {mapActions, mapState, mapGetters} from "vuex";
  import { format, utcToZonedTime } from 'date-fns-tz';
  import axios from 'axios';

  import myPost from '../components/my-post'
  import userPost from "@/views/apps/chat/components/user-post";
  import chatAttachment from '../components/attachment'

  import GET_CHAT_POSTS from '@/graphql/queries/phoenix/ycmdChatPostGet';
  import GET_CHAT_ATTACHMENT from '@/graphql/queries/phoenix/ycmdChatAttachmentGet';
  import CLEAR_CHAT_ROOM_UNREAD from "@/graphql/mutations/phoenix/ycmdChatRoomClearUnread";

  import Loading from '@/components/loading-elipsis';
  import dataClient from '@/graphql/clients/axios';

  export default {
    name: 'chatMainList',
    components: {
      'my-post': myPost,
      'user-post': userPost,
      'chat-attachment': chatAttachment,
      loading: Loading
    },
    props: {
      changeView: Function,
      params: {
          data: Object
      }
    },    
    data() {
      return {
        format,
        card: {
          index: 0,
          panels: { left: false, right: false, top: false, bottom: false }
        },
        loading: true,
        loadingPrevious: false,
        loadingNext: false,
        transition: 'fade',
        showNotification: true,
        imageMaximized: false,
        sendingPost: false,
        sendingAttachment: false,
        attachmentLoaded: false,
        rooms_id: null,
        posts: [],
        //sortedPosts: [],
        attachments: [],
        starting_post_id: '',
        prevToken: null,
        nextToken: null,
        textToPost: '',
        unread_count: 0,
        attachmentName: '',
        attachment_id: null,
        groupedDate: null,
        dialog: false,
        dialogTitle: '',
        dialogMessage: '',
        room_name: '',
        room_id: '',
        parentPosition: null,
        parentLimitOverride: null
      }
    },
    apollo: {
      $client: 'phoenixClient'
    },
    mounted () {
      //first time we don't get events so load posts.  after that, load in event
      this.doLoadPosts(this.params.room_id)
      this.room_name = this.params.room_name
      this.room_id = this.params.room_id
      this.parentPosition = this.params.position
      this.parentLimitOverride = this.params.limitOverride

      this.$emit('initialized', 'chat-main', this.event)
    },
/*    
    beforeDestroy() {
      // Cleanup
      this.$apollo.subscriptions.onChatActivity.stop();
    },
*/    
    methods: {
      ...mapActions('chat', ['getBadgeCount','loadCurrentRoom']),
      ...mapActions('rightMenu', ['expandRightMenu','collapseRightMenu',]),

    async resendPost(params) {
      console.log('params', params)
      
      let posts = this.posts 
      let postIndex = posts.findIndex(p => {
        return p.id == params.id
      })

      let post = posts[postIndex]

      if (post) {

        let newPostParams = {                
            room_id: this.params.room_id,
            text: post.text,
            attachment_id: post.attachmentId,
            resend_time: post.date_created
        }

        let response = await dataClient('ycmdChatPostCreate', newPostParams)

        if (response && response.id) {
          this.$set(this.posts, postIndex, response)

          if (this.room_id !== this.getCurrentRoomId) {
            let room = await dataClient('ycmdChatRoomGet', {room_id: this.room_id})

            if (room && room.rooms && room.rooms.length) {
              this.loadCurrentRoom(room.rooms[0])
            }

          } else {
            this.$store.commit('chat/addPostToCurrentRoom', response)
          }

        }

        if (params.callback) {
          params.callback({status: 'success'})
        }

      }
      

    },


      event (name, params) {

        switch (name) {
          case 'load':
            this.loading = true

            this.posts = []
            //this.sortedPosts = []

            this.room_name = params.room_name
            this.room_id = params.room_id
            this.parentPosition = params.position
            this.parentLimitOverride = this.params.limitOverride

            this.doLoadPosts(params.room_id)
            break
        }
      },
      async doLoadPosts(room_id) {

        // console.log("CHATROOM USER_ID: ", this.info.userID);
        // console.log("ROOM_ID: ", this.currentRoom.id);

        // Make sure we make sure the subscription is reconnected in case there is a disconnect
        //this.$apollo.subscriptions.onChatActivity.refresh();

        // clear any previous posts
        //await this.clearCurrentRoomPostsMain([]);
        this.room_id = room_id
        console.log('this.params', this.params)

        this.posts = await this.loadPosts({
          room_id: room_id,
          start: 'mostRecent'
        });
        console.log('this.posts', this.posts)

        this.groupDates()

        this.loading = false;
        this.goToBottom();

        // clear

        // grab badge count
        this.getBadgeCount();

      },      
      async loadMore (direction) {

        let params = {
          room_id: this.room_id
        };

        if(direction === 'prev') {

          this.loadingPrevious = true;

          if(this.prevToken)
            params["prevToken"] = this.prevToken;
        }
        // else {
        //
        //   this.loadingNext = true;
        //
        //   if(this.nextToken)
        //     params["nextToken"] = this.nextToken;
        // }

        const posts = await this.loadPosts(params);

        if(direction === 'prev') {

          for (let i=posts.length-1; i>=0; i--) {
            this.posts.unshift(posts[i]) ;
          }

          this.loadingPrevious = false;
          this.loading = false;
          this.scrollToTop();
        }
        else {

          // for (let i=0; i<posts.length-1; i++) {
          //   this.sortedPosts.unshift(this.posts[i]) ;
          // }
          //
          // this.loadingNext = false;
          // this.loading = false;
          // this.scrollToBottom();
        }
      },
      async loadPosts(params, direction) {

        const response = await this.$apollo.query({
          client: 'phoenixClient',
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
          query: GET_CHAT_POSTS,
          variables: params
        });

        if (response) {

          const { posts, prevToken, nextToken } = response.data.ycmdChatPostGet;

          let mergedPosts = [];

          for (let i = 0; i < posts.length; i++) {

            if (posts[i].attachment_id && posts[i].attachment_id.startsWith('msg_')) {

              const result = await this.getAttachment(posts[i].attachment_id);

              if (result) {

                const { meta } = result.data.ycmdChatAttachmentGet;

                const msg_attachment = {
                  patient_name: meta.find(x => x.name === 'patient_name').value,
                  patient_dob: meta.find(x => x.name === 'patient_dob').value,
                  facility_name: meta.find(x => x.name === 'facility_name').value,
                }

                const newPost = {...posts[i], msg_attachment};
                mergedPosts.push(newPost);
              }
            }
            else
              mergedPosts.push(posts[i]);
          }

          this.prevToken = prevToken;
          this.nextToken = nextToken;

          return mergedPosts.reverse();
        }
      },
      async savePost() {

        if (this.textToPost.length > 0) {

          this.sendingPost = true;

          await this.createPost({
            room_id: this.room_id,
            text: this.textToPost
          });

          this.sendingPost = false;

          this.scrollToBottom();
        }
      },
      async saveAttachment({ file, key }) {

        try {

          this.sendingAttachment = true;

          let variables = {
            content_type: file.type,
            room_id: this.room_id,
            meta: [{'name': 'name', 'value': file.name}]
          }

        let response = await dataClient('ycmdChatAttachmentUrlGet', variables)

        //console.log("RESPONSE", response);
        
        // check errors
        if (response.error || response.errors) {
          if (!response.error && response.errors && resoponse.errors.length){
            response.error = response.errors[0]
          }
 
          this.$toasted.error(response.error.message);     

          // check errors
        }
        else {
            const { url, meta } = response;

            //save the file
            let saveResult = await this.saveFile({url, file, meta})

            const { status } = saveResult;

            if (status === 200) {

              const attachment_id = meta.find(m => m.name === 'attachment_id').value;

              await this.createPost({
                room_id: this.room_id,
                attachment_id,
                file,
                key
              });
            }
          }

          this.sendingAttachment = false;
        }
        catch(e) {
          throw `Unknown error occurred ${ e }`;
        }
      },
      selectFile() {
        this.$refs.inputUpload.click()
      },
      fileSelected(e) {

        if (e.target.files.length > 0) {

          for (let f in e.target.files) {

            let file = e.target.files[f]

            if (file && typeof(file) === 'object' && file.name) {

              // Max number of bytes (10MB = 10000000 Bytes)
              if (file.size < 10000000) {

                let key=`tmp_${btoa(file.name)}`;

                this.saveAttachment({type: 'saveAttachment', file, key})
              }
              else {
                this.showDialog(
                    'File Size Too Large',
                    'The file size exceeds the maximum size of 10MB.'
                )
                return;
              }
            }
          }
        }
      },
      async saveFile({url, file, meta}) {

        let headers = {
          'Content-Type': file.type
        };

        meta.map(m => {
          headers[`x-amz-meta-${m.name}`] = m.value
        });

        let result = await axios.put(url,
            file,
            {
              headers: headers
            }
        )

        return result
      },
      async createPost(params) {

        let now = new Date()

        let tempPost = {
            attachment_id: params.attachment_id,
            text: params.text,                
            date_created: now.toISOString(),
            first_name: this.getFirstName,
            last_name: this.getLastName,
            id: `temp_${now.getTime()}`,
            type: "post",
            user_id: this.getUserID,
        }


        //let post = await dataClient('ycmdChatPostCreate', params)
        if (tempPost) {
          this.posts = [...this.posts, tempPost];
        }

        let tempId = tempPost.id

        setTimeout(async () => {
          let result = await dataClient('ycmdChatPostCreate', params)
          console.log('post result', result)

          if (result && result.error) {
            //error

            let posts = this.posts

            let index = posts.findIndex(p => {
                return p.id === tempId
            })

            if (index >= 0) {
              let tempPost = posts[index]
              tempPost.error = true
              this.$set(this.posts, index, tempPost)
            }

          }
          else if (result && result.id) {
            let posts = this.posts

            let index = posts.findIndex(p => {
                return p.id === tempId
            })

            if (index >= 0) {
                posts.splice(index, 1, result)

                this.posts = posts
            }
            console.log('this.params', this.params)

            if (this.room_id !== this.getCurrentRoomId) {
              let room = await dataClient('ycmdChatRoomGet', {room_id: this.room_id})

              if (room && room.rooms && room.rooms.length) {
                this.loadCurrentRoom(room.rooms[0])
              }

            } else {
              this.$store.commit('chat/addPostToCurrentRoom', result)
            }

          }
        }, 1)

        /*
        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: CREATE_CHAT_POST,
          variables: params,
          update: (cache, { data: { ycmdChatPostCreate } }) => {
            try {

              if(ycmdChatPostCreate) {
                this.posts = [...this.posts, ycmdChatPostCreate];
              }
            }
            catch (e) {
              console.error(e);
            }
          }
        });
        */
        this.parentPosition = null
        this.parentLimitOverride = null
        //this stuff is to maintain scroll position.  If we post, we change the order of the rooms 
        //so we don't want to scroll down to where the room last was since it will float to the top


        this.textToPost = ''
      },
      async clearUnread() {

        await this.$apollo.mutate({
          client: 'phoenixClient',
          mutation: CLEAR_CHAT_ROOM_UNREAD,
          variables: {
            room_id: this.room_id
          }
        });
      },
      async getAttachment(message_id) {

        return await this.$apollo.query({
          client: 'phoenixClient',
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
          query: GET_CHAT_ATTACHMENT,
          variables: {
            room_id: this.room_id,
            attachment_id: message_id
          }
        });
      },
      scrollToTop() {

        this.$nextTick(() => {

          const container = document.getElementById('chatContainer');

          container.scroll({
            top: 0,
            behavior: 'smooth'
          });
        });
      },
      scrollToBottom() {

        this.$nextTick(() => {

          const container = document.getElementById('chatContainer');

          container.scroll({
            top: container.scrollHeight,
            behavior: 'smooth'
          });
        });
      },
      goToBottom() {

        this.$nextTick(() => {

          const container = document.getElementById('chatContainer');

          container.scrollTop = container.scrollHeight;
        });
      },
      async downloadAttachment(post, ev) {

        if (post.attachment_id) {

          if (ev.target.innerText === 'launch') {
            //since we already have the attachment loaded it is in the cache.
            //get it and create a blank document with an object tag

            let cached = this.getCachedAttachment(post.attachment_id)

            var obj = document.createElement('object');
            obj.style.width = "100%";
            obj.style.height = "100%";
            
            obj.data = cached.attachment

            let  w = window.open("", '_blank');
            w.document.write(obj.outerHTML);
            w.document.close();


          } else {

            let variables = {
              attachment_id: post.attachment_id,
              type: 'download'
            }

            let response = await dataClient('ycmdChatAttachmentUrlGet', variables)
            
            window.open(response.url, '_blank');

          }

        }
      },
      minimizeAttachment() {

        this.imageMaximized = false;
      },
      getUserInitials(first_name, last_name) {

        return first_name.charAt(0) + last_name.charAt(0);
      },
      groupDates() {

        let grouped_date = null;

        if(this.posts && this.posts.length > 0) {
          console.log('this.posts', this.posts)

          this.posts.map((post, i) => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const localDate = utcToZonedTime(post.date_created, timeZone)

            const date_created = format(localDate, 'iiii MM/dd/yyyy', { timeZone });

            if(grouped_date === null || date_created !== grouped_date) {
              this.posts[i]["grouped_date"] = grouped_date = date_created;
            }
          });
        }
      },
      getTime({ start_date, exact = false }) {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localDate = utcToZonedTime(start_date, timeZone);

        if(this.exactTimestamps)
          exact = this.exactTimestamps;

        if(!exact)
          return format(localDate, 'h:mm a', { timeZone })
        else
          return format(localDate, 'MM-dd-yyyy h:mm a',{ timeZone })
      },
      getImageClass(attachment_id) {

        if (attachment_id) {
          if (this.imageMaximized && attachment_id === this.attachment_id) {
            return 'image-maximized'
          }
          else
            return 'image-expandable'
        }
      },
      showDialog(title, message) {
        this.dialogTitle = title;
        this.dialogMessage = message;
        this.dialog = true;
      },
      closeDialog() {
        this.dialog = false;
        this.dialogTitle = '';
        this.dialogMessage = '';
      }
    },
    computed: {
      ...mapState('profile', ['info']),
      ...mapState('rightMenu', ['isRightMenuExpanded']),
      ...mapGetters(
          'chat', ['getCachedAttachment','getCurrentRoomId'],
      ),
      ...mapGetters(
          'profile', ['getFirstName', 'getLastName', 'getUserID'],
      ),

    },
    watch: {
      /*
        getCurrentRoomPostsMain(posts) {
            console.log('watched getRoomPosts: ', posts);
            const postsNoDupes = posts.filter((post) => {
                for (let fetchedPost in this.posts) {
                    if (this.posts[fetchedPost].id !== post.id) return true
                }
            })
            this.posts = [...this.posts, ...postsNoDupes];
            this.scrollToBottom();
            // Since the user received the subscription while in the chatroom we assume they read the post
            this.clearUnread();
            // update badge count
        },
        */
        /*
        posts (newPosts) {

            if(this.sortedPosts.length === 0) {
                for (let i = newPosts.length-1; i >= 0; i--){
                    this.sortedPosts.push(newPosts[i]) ;
                }
            }
            else
            this.sortedPosts.push(newPosts[newPosts.length-1]) ;

            this.groupDates();

            // console.log("SORTED_POSTS FINAL: ", this.sortedPosts);
            console.log('room post test: ', this.roomPosts);
            console.log('room post getter test: ', this.getCurrentRoomPostsMain);
        }
        */
    }
  }
</script>

<style>

  #mainChat .v-dialog {
    position: absolute !important;
    right: 0 !important;
  }

  .image-expandable {
    position: relative;
    width:100%;
    object-fit: contain;
    border-radius: 8px;
    transition: all 2.4s cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: width, height;
  }

  .image-maximized {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width:100%;
    height: 100%;
    top: 0;
    object-fit: contain;
    background-color: #000;
    z-index: 9999;
    transition: all 2.4s cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: width, height;
  }

</style>
