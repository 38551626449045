<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">
               <div class="i-card__main__content white br-2 pa-2">
                    <div class="i-card__scrollable white full-width full-height">
                        <v-container
                                fluid
                        >

                            <v-row
                                class="pa-0 ma-0 align-center"
                            >
                                <v-col
                                cols="12"
                                class="pa-0 ma-0"
                                >

                                    <v-row
                                        class="pa-0 ma-0 align-center"
                                    >
                                        <v-col
                                        cols="6"
                                        class="pa-0 ma-0"
                                        >
                                            <h1>{{reportDefinition.name}}</h1>
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        class="pa-0 ma-0"
                                        >
                                            <h3>{{reportDefinition.name}} Schedules</h3>
                                        </v-col>                                                    
                                    </v-row>

                                    <v-row
                                        class="pa-0 ma-0 align-top"
                                    >
                                        <v-col
                                        cols="6"
                                        class="pa-0 ma-0"

                                        >
                                            <v-form
                                                ref="frmValidateParameters"
                                            >

                                                <template
                                                    v-for="(parameter) in reportDefinition.parameters"
                                                >
                                                    <report-parameter :parameter="parameter"
                                                                    :key="`par_${parameter.id}_${refreshKey}`"
                                                                    v-model="propertyValues[parameter.id]"
                                                                    :users.sync="userlists[parameter.id]"
                                                                    :list="findList(parameter)"
                                                                    :variables="currentVariables"
                                                                    @changed="parameterChanged"
                                                    />
                                                </template>
                                            </v-form>

                                            <v-row
                                                class="pa-0 ma-0"
                                                align="center"
                                            >
                                                <v-col
                                                cols="3" md="2"
                                                class="pa-0 ma-0"
                                                >
                                                    &nbsp;
                                                </v-col>
                                                <v-col
                                                cols="9" md="6"
                                                class="pa-0 ma-0"
                                                >
                                                    <v-select
                                                        :items="formats"
                                                        v-model="reportFormat"
                                                        label="Format"
                                                        style="max-width: 75px;"                                                 
                                                    ></v-select>

                                                </v-col>
                                            </v-row>

                                            <v-row
                                                class="pa-0 ma-0"
                                                align="center"
                                            >
                                                <v-col
                                                cols="3" md="2"
                                                class="pa-0 ma-0"
                                                >
                                                    &nbsp;
                                                </v-col>
                                                <v-col
                                                cols="9" md="6"
                                                class="pa-0 ma-0"
                                                >
                                                <v-btn
                                                        @click="run"
                                                        color="primary"
                                                        width="100%"
                                                        :disabled="!isValid || running"
                                                    >
                                                        Run
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                class="pl-0 ma-0"
                                                align="center"
                                            >
                                                <v-col
                                                cols="3" md="2"
                                                class="pa-0 ma-0"
                                                >
                                                    &nbsp;
                                                </v-col>
                                                <v-col
                                                cols="9" md="6"
                                                class="px-0 pt-5 pb-0 ma-0"
                                                >
                                                    <v-btn
                                                        @click="schedule"
                                                        color="ycmd-light-gray"
                                                        width="100%"
                                                        :disabled="!isValid || running"
                                                    >
                                                        Schedule
                                                    </v-btn>                                                                                                                
                                                </v-col>
                                            </v-row>


                                            
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        class="pa-0 ma-0"
                                        >
                                            <schedules-view
                                                :schedules="reportSchedules"
                                                @deleteSchedule="deleteSchedule"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>    
                            <v-row
                                class="pa-0 ma-0 align-center"
                            >
                                <v-col
                                cols="12"
                                class="pa-0 ma-0"
                                >
                                    <report-jobs 
                                    :report_id="$route.params.report_id"
                                    :submittedKey="submittedKey"
                                    />
                                </v-col>
                            </v-row>                            

                        </v-container>                     
                    </div>
               </div>
            </div>

            <template slot="right-panel">

                <div>VMM Hello right panel</div>

            </template>

            <template slot="bottom-panel">

                <div>VMM Hello Bottom Panel</div>

            </template>

        </i-card>

        <schedules-modal v-model="openSchedule" @save="saveSchedule"
            :key="`schedule_${refreshSchedule}`"
        />
        <confirmation
            v-model="confirmScheduleDelete"
            title="Delete Schedule"
            subtitle="Confirm Schedule Delete"
            text="Are you sure you want to delete the schedule?"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />
    </div>

</template>

<script>
    import reportParameter from '@/views/apps/reporting/components/reportParameter.vue'
    import reportJobs from '@/views/apps/reporting/components/reportJobs.vue'
    import SchedulesModal from '@/views/apps/reporting/components/schedulesModal';
    import SchedulesView from '@/views/apps/reporting/components/schedulesView';
    import Confirmation from '@/components/modals/confirmation.vue'
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'reportRun',
        components: {
            'report-jobs': reportJobs,
            'report-parameter': reportParameter,
            'schedules-modal': SchedulesModal,
            'schedules-view': SchedulesView,
            confirmation: Confirmation
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false },
                },
                reportId: null,
                reportDefinition: {},
                userlists: {},
                refreshKey: 0,
                currentVariables: [],
                propertyValues: {},
                formats: ['xls', 'csv'],
                reportFormat: 'xls',
                running: false,
                openSchedule: false,
                refreshSchedule: 0,
                confirmScheduleDelete: false,
                deleteScheduleId: null,
                submittedKey: null
            }
        },
        created() {},
        async mounted() {
            console.log('route', this.$route)

            if (this.$route.params.report_id) {
                this.loadReportDefinition(this.$route.params.report_id)
            } else {
                this.$router.push(`/scheduling/schedule/report/group_schedules`)
            }
        },

        watch: {
            '$route.params': {
                handler (val, oldVal) {
                    this.loadReportDefinition(val.report_id)
                },
                deep: true
            },
            propertyValues: {
                async handler (newValue, oldValue) {
                    this.$refs.frmValidateParameters.validate()
                    if (this.updatingDefinition) {
                        return
                    }

                    let count = 0
                    for (let prop in newValue) {
                        count++
                    }

                    if (count) {
                        let refreshParameters = false
                        this.updatingDefinition = true

                        if (this.reportDefinition && this.reportDefinition.reportProperties && this.reportDefinition.reportProperties.length) {
                            let obj = this.reportDefinition.reportProperties.find(p => {
                                return p.name == 'refreshParameters' 
                            })
                            if (obj) {
                                refreshParameters = obj.value.toLowerCase() === 'true' ? true : false
                            }
                        }
                        let variables = {
                            report_id: this.reportDefinition.id,
                            parameters: this.getParameters()
                        }

                        let arr = []
                        variables.parameters.map(v => {
                            arr.push(v)
                        })
                        this.currentVariables = arr

                        if (refreshParameters) {
                            let response = await this.$apollo.query({
                                query: ycmdReportDefinitionGet,
                                variables: variables,
                                fetchPolicy: 'no-cache',
                                errorPolicy: 'all'
                            });

                            if (response.data && response.data.ycmdReportDefinitionGet && response.data.ycmdReportDefinitionGet.length) {
                                this.updatedDefinition = response.data.ycmdReportDefinitionGet[0]
                            }
                        }
                        this.updatingDefinition = false
                    }
                }, 
                deep: true
            },
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            async loadReportDefinition(reportId) {
                this.reportDefinition = await this.$store.dispatch('report/findReportDefinition', {report_id: reportId})
                if (!this.reportDefinition) {
                    this.reportDefinition = {}
                }
            },
            findList(par) {
                if (par.select_list_key) {
                    return this.listMap[par.select_list_key]
                }
                return null
            },
            parameterChanged(value, formattedValue) {

                if (formattedValue) {
                    
                }
            },
            getParameters () {
                let parameters = []

                this.reportDefinition.parameters.map(p => {
                    parameters.push({
                        id: p.id,
                        value: this.propertyValues[p.id]
                    })
                })

                return parameters
            },
            async run () {
                let parameters = this.getParameters()
                this.running = true

                this.submittedKey = `key_${(new Date()).getTime()}`

                let job = await this.$store.dispatch('report/submitReport', {
                    report_id: this.reportDefinition.id,
                    parameters: parameters,
                    output_type: this.reportFormat,
                    key: this.submittedKey
                })

                this.running = false
                this.propertyValues = {}             
                this.refreshKey++                
            },
            schedule () {
                this.openSchedule = true
            },
            async saveSchedule (schedule) {
                let scheduleObject = {
                    report_id: this.reportDefinition.id,
                    schedule_repeat: schedule.repeat,
                    dow: schedule.dow,
                    dom: schedule.dom,
                    time: schedule.time,
                    parameters: this.getParameters(),
                    format: this.reportFormat,
                    emails: schedule.emails
                }

                let result = await this.$store.dispatch('report/scheduleSave', {
                    schedule: scheduleObject
                })

            },
            deleteSchedule (schedule_id) {
                console.log('schedule_id', schedule_id)
                this.deleteScheduleId = schedule_id
                this.confirmScheduleDelete = true
            },
            confirmationClicked (btn) {
                if (btn.caption === 'OK') {
                    this.doDeleteSchedule () 
                }
                this.confirmScheduleDelete = false
            },
            async doDeleteSchedule () {
                let result = await this.$store.dispatch('report/scheduleDelete', {
                    schedule_id: this.deleteScheduleId,
                })
            },            
        },
        computed: {
            ...mapGetters('report', ['getReportJobs','getReportDefinitions','getReportSchedules']),
         
            isValid () {
                if (!this.reportDefinition.id) {
                    return false
                }

                let valid = true
                this.reportDefinition.parameters.map(p => {
                    if (p.required && p.data_type !== 'boolean') {
                        if (!this.propertyValues[p.id]) {
                            valid = false
                        }
                    }
                })
                return valid
            },
            reportSchedules () {
                let arr = []
                if (this.getReportSchedules && this.getReportSchedules.length) {
                    this.getReportSchedules.map(s => {

                        if (s.report_id === this.reportDefinition.id) {
                            arr.push(s.schedule)
                        }
                    })
                }
                return arr
            },
            confirmationButtons () {
                return [
                    {caption: 'OK', disabled: this.sending},
                    {caption: 'Cancel', disabled: this.sending}
                ]
            },

        }
    }

</script>

<style scoped>

</style>
