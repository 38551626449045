<template>

    <div class="d-flex">
        <v-autocomplete
                ref="vauto"
                light dense flat outlined solo
                v-model="nonComputedLocal"
                :disabled="disabled"
                :items="items"
                :loading="isLoadingSearch"
                :filter="customFilter"
                item-text="name"
                item-value="displayID"
                label="Search Facilities"
                background-color="white"
                :rules="rules"
                clearable
                @click:clear="onPressClear"
                return-object
                />
    </div>

</template>

<script>

    import GET_FACILITIES from '@/graphql/queries/phoenix/ycmdFacilitySearch';

    export default {
        name: 'ycmdFacilitySearch',
        components: {
        },
        props: {
            disabled: Boolean,
            shouldIncludeFacilities: Boolean,
            facilitySearchSelected: Object,
            groupIDS: Array,
            rules: Array,
            value: null,
            initialValue: null
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data: () => ({
            isLoadingSearch: false,
            items: [],
            itemMap: {},
            nonComputedLocal: null
        }),
        created() {

        },
        async mounted() {
            let iv
            console.log('this.initialValue', this.initialValue)
            if (this.initialValue) {
                iv = this.initialValue
                if (typeof(iv) === 'object') {
                    iv = iv.displayID
                    if (!iv) {
                        iv = this.initialValue.id
                    }
                }
            }
            
            if (!this.items || !this.items.length) {
                await this.getFacilities(null, this.initialValue);
            }
            
            if (this.items && this.items.length && iv) {
                let found = this.items.find(i => {
                    return i.displayID == iv
                })
                this.$nextTick(() => {
                    this.nonComputedLocal = found
                })                                       
            }

        },
        watch: {
            nonComputedLocal(newValue) {
                this.facilitySearchSelectedLocal = newValue
            }

        },
        methods: {
            customFilter (item, queryText, itemText) {
                let query = queryText.toLowerCase()
                let itemfields = this.itemMap[item.id]
                console.log('itemfields', itemfields)
                if (itemfields && itemfields.length) {
                    for (let i = 0;i<itemfields.length;i++) {
                        if (itemfields[i].toLowerCase().indexOf(query) >= 0) {
                            return true
                        }
                    }
                }
                return false
            },
            onPressClear() {
                //this.items = [];
                this.facilitySearchSelectedLocal = null;
            },
            generateFacilityList(data) {
                let sortedList = [];

                // iterate over all facilities
                for (let facility in data) {
                    const currentFacility = data[facility];
                    // console.log('current facility: ', currentFacility);
                    // check if units exist
                    if (currentFacility.units && currentFacility.units.length) {
                        // add header
                        sortedList.push({ header: `${currentFacility.name} (${currentFacility.shortcode})` || 'Group', id: currentFacility.id } )//, { divider: true, id: currentFacility.id  });
                        // iterate over units
                        if (!this.itemMap[currentFacility.id]) {
                            this.itemMap[currentFacility.id] = []
                        }
                        this.itemMap[currentFacility.id].push(currentFacility.name)
                        this.itemMap[currentFacility.id].push(currentFacility.shortcode)
                        
                        for (let unit in currentFacility.units) {
                            const currentUnit = currentFacility.units[unit];
                            // console.log('current unit: ', currentUnit);
                            // add if name and id exist
                            if (currentUnit.name && currentUnit.shortcode) {
                                sortedList.push({ name: `${currentFacility.shortcode} ${currentUnit.name}`, 
                                                    facilityName: currentFacility.name,
                                                    unitName: currentUnit.name,
                                                    id: currentFacility.id, 
                                                    displayID: `${currentFacility.id}|${currentUnit.shortcode}`, 
                                                    shortcode: currentUnit.shortcode, 
                                                    number: currentUnit.number,
                                                    new_patients: currentUnit.new_patients,
                                                    default_patient_status: currentUnit.default_patient_status
                                                    });
                            }
                        }
                    }
                }
                // console.log('sorted list: ', sortedList);
                return sortedList
            },
            generateFacilityListIncludeFacilities(data) {
                let sortedList = [];
                // iterate over all facilities
                for (let facility in data) {
                    const currentFacility = data[facility];
                    // check if units exist
                    if (currentFacility.units && currentFacility.units.length) {
                        // add header
                        if (!this.itemMap[currentFacility.id]) {
                            this.itemMap[currentFacility.id] = []
                        }
                        this.itemMap[currentFacility.id].push(currentFacility.name)
                        this.itemMap[currentFacility.id].push(currentFacility.shortcode)

                        sortedList.push({ name: `FACILITY - ${currentFacility.shortcode} ${currentFacility.name}`, id: currentFacility.id, facility: currentFacility.id, displayID: currentFacility.id})//, { divider: true, id: currentFacility.id });
                        // iterate over units

                        for (let unit in currentFacility.units) {
                            const currentUnit = currentFacility.units[unit];
                            // console.log('current unit: ', currentUnit);
                            // add if name and id exist
                            if (currentUnit.default_patient_status === 'est') {
                                currentUnit.default_patient_status = 'established'
                            }
                            if (currentUnit.name && currentUnit.shortcode) {
                                sortedList.push({ name: `${currentFacility.shortcode} ${currentUnit.name}`, 
                                                    facilityName: currentFacility.name,
                                                    unitName: currentUnit.name,
                                                    id: currentFacility.id, 
                                                    shortcode: currentUnit.shortcode, 
                                                    number: currentUnit.number,
                                                    new_patients: currentUnit.new_patients,
                                                    default_patient_status: currentUnit.default_patient_status
                                                });
                            }
                        }
                    }
                }
                // console.log('sorted list: ', sortedList);
                return sortedList
            },
            //just get all facilities 
            async getFacilities(val, location) {
                this.isLoadingSearch = true;
                // console.log('sending facility: ', this.groupIDS);
                try {
                    const response = await this.$apollo.query({
                        query: GET_FACILITIES,
                        variables: {
                            //search: val,
                            group_ids: this.groupIDS,
                            // location: location,
                            //limit: 20
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingSearch = false;

                    // console.log('ycmd facilities response: ', response);
                    const { ycmdFacilitySearch } = response.data;
                    if (ycmdFacilitySearch && ycmdFacilitySearch.length) {
                        if (this.shouldIncludeFacilities) {
                            this.items = this.generateFacilityListIncludeFacilities(ycmdFacilitySearch);
                        } else {
                            this.items = this.generateFacilityList(ycmdFacilitySearch);
                        }
                    } 
                } catch (e) {
                    this.isLoadingSearch = false;
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            facilitySearchSelectedLocal: {
                get: function() {
                    // handle items array
                    /*
                    if (!this.facilitySearchSelected) {
                        this.items = [];
                    } else {
                        this.items = [this.facilitySearchSelected];
                    }
                    */
                    return this.facilitySearchSelected;
                },
                set: function(newValue) {
                    // console.log('SELECTED: ', newValue);
                    this.$emit('update:facilitySearchSelected', newValue)
                    this.$emit('input', newValue)
                }
            }
        }
    }
</script>
