import gql from 'graphql-tag'

export default gql`mutation ycmdTicketCreate(
	$title: String!, 
	$message: String!) {
	ycmdTicketCreate(
		title: $title, 
		message: $message) {
		comment
        created_by_id
        created_by_first_name
        created_by_last_name
        date_created
        date_updated
        message
        message_id
        priority
        status
        title
	}
}`
