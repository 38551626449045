import * as cloneDeep from 'lodash/cloneDeep';
import appsConfig from '@/data/config/apps';
import { logout, route } from '@/methods/globalMethods';
//import modeStore from './mode'
import dataClient from '@/graphql/clients/axios';
import { displayNotification } from '@/methods/notificationSystem';

const state = {
  index: 0,
  itemIndex: 0,
  list: [],
  filteredList: [],
  authenticationStatus: null,
  authenticationStatusOpen: false,
  numberMessages: null,
  numberMessagesGroup: null,
  numberMessagesForwarding: null,
  numberVoicemail: null,
  syncingBadges: {},
  badgeInbox: 0,
  badgeInboxGroup: 0,
  badgeMessages: 0,
  badgeForwardingCenter: 0,
  globalEvent: null,
  apps: []
};

const getters = {
  index: state => state.index,
  apps: state => state.apps,
  syncingBadges: state => state.syncingBadges,
  numberMessages: state => state.numberMessages,
  numberMessagesGroup: state => state.numberMessagesGroup,
  numberMessagesForwarding: state => state.numberMessagesForwarding,
  numberVoicemail: state => state.numberVoicemail,
  badgeInbox: state => state.badgeInbox,
  badgeInboxGroup: state => state.badgeInboxGroup,

  badgeMessages: state => state.badgeMessages,
  badgeForwardingCenter: state => state.badgeForwardingCenter,

  globalEvent: (state) => {
    if (!state.globalEvent) {
        return {}
    }
    let pieces = state.globalEvent.split('|')
    return {
        mode: pieces[0],
        app: pieces[1],
        item: pieces[2],
        command: pieces[3],
        data: pieces[4],
        time: pieces[5]
    }
  },
  app: (state, getters) => {
    return getters.filteredList[state.index]
  },
  list: state => state.list,
  authenticationStatus: state => state.authenticationStatus,
  authenticationStatusOpen: state => state.authenticationStatusOpen,
  filteredList: (state, getters, rootState, rootGetters) => {
      // const sortedApps = cloneDeep(apps);
      const sortedApps = [];
    
      const roles = rootGetters['profile/getRoles']()

      for (let app in getters.apps) {
          const filteredItems = [];
          for (let index in getters.apps[app].items) {
              const item = getters.apps[app].items[index];
              // check if view type (single / group) hides view
              // we don't use viewtype
              if (!item.roleRequired) {
                  filteredItems.push(item);
              } else {
                  // iterate over your roles to see what to display

                  for (let index in roles) {
                      const role = roles[index];
                      // check if roles match or role does not need matching
                      if ((item.roleRequired && (role === item.roleRequired))) {
                          filteredItems.push(item);
                          break;
                      }
                  }
              }
          }
          // check if items still exist after filtering
          if (filteredItems.length) {
            sortedApps.push(
                  {
                      ...getters.apps[app],
                      items: filteredItems
                  }
              )
          }
      }

      return sortedApps
    },
    itemIndexFromKey: (state, getters) => (appKey, itemKey) => {
        let filteredList = getters.filteredList
        let appIndex = getters.appIndexFromKey(appKey)
        let app = filteredList[appIndex]
        let itemIndex = app.items.findIndex(i => {
            return i.name == itemKey
        })
        return itemIndex >= 0 ? itemIndex : 0
    },
    appIndexFromKey: (state, getters) => (appKey) => {
        let filteredList = getters.filteredList

        let appIndex = filteredList.findIndex(a => {
            return a.name == appKey
        })
        return appIndex >= 0 ? appIndex : 0
    },
    appIndexFromPath: (state, getters) => (path) => {
        let filteredList = getters.filteredList
        let p = path.startsWith('/') ? path.substr(1) : path
        let pieces = p.split('/')

        let appIndex = -1

        if (pieces && pieces.length) {
            appIndex = filteredList.findIndex(a => {
                return a.path == pieces[0]
            })    
        }
        return appIndex
    },    
    noNegative: (state, getters) => (val) => {
        if (val < 0) {
            return 0
        }
        return val
    },
    v2SchedulingUrl: state => {
        const VUE_APP_V2_SCHEDULING_URL=process.env.VUE_APP_V2_SCHEDULING_URL

        return VUE_APP_V2_SCHEDULING_URL
    },
    v2GroupUrl: state => {
        const VUE_APP_V2_GROUP_URL=process.env.VUE_APP_V2_GROUP_URL

        return VUE_APP_V2_GROUP_URL
    }
};

const actions = {
    init: ({commit, getters}, { index = 0, itemIndex = 0 }) => {

        if (!getters.apps.length) {
            commit('setApps',appsConfig)
        }
        // TODO: Enforce application permissions here
        // TODO: Get menu item counts
        let list = [ getters.apps[0], getters.apps[1], getters.apps[2] ];

        commit('init', { index, itemIndex, list })
    },
    changeApp: ({commit, dispatch, getters }, payload) => {// index, itemIndex) => {
        
        let itemIndex = 0
        let index = 0
        if (typeof(payload) === 'object') {
            itemIndex = typeof(payload.itemIndex) == 'string' ? getters.itemIndexFromKey(payload.index, payload.itemIndex) : payload.itemIndex 
            index = typeof(payload.index) == 'string' ? getters.appIndexFromKey(payload.index) : payload.index 
        } else { 
            index = payload
        }

        commit('changeItem', 0);
        commit('changeApp', index);

        //dispatch('syncBadges', {from: 'changeApp'})
        //split these into separate calls so less likely to timeout
        //dispatch('syncBadges', {from: 'changeApp', type: 'message_group'})
        dispatch('syncBadges', {from: 'changeApp', type: 'message'})
        dispatch('syncBadges', {from: 'changeApp', type: 'message_forwardcenter'})
        dispatch('syncBadges', {from: 'changeApp', type: 'voicemail'})
        dispatch('syncBadges', {from: 'changeApp', type: 'chat_post'})

        if (getters.filteredList[index] && getters.filteredList[index].items.length) {
            dispatch('report/updateAppReports', {app: getters.filteredList[index], appIndex: index, mutation: 'app/setAppItemChildItems'}, {root:true})
          // Don't try to route to the current route otherwise warnings are thrown
          //if(index !== state.index)
            route(getters.filteredList[index].items[itemIndex].route, 'fade-in-transition');
        }
    },
    changeItem: ({rootState, commit}, itemIndex) => {
        console.log('rootState', rootState)
        commit('changeItem', itemIndex);
        //dispatch('syncBadges')

    },
    setCount: ({commit}, {appIndex, itemIndex, count, itemName }) => {
        commit('setCount', { appIndex, itemIndex, count, itemName })
    },
    logout: ({commit}) => {

      console.log("STORE LOGOUT")

      logout();
    },
    syncBadges: async ({rootState, dispatch, commit, getters, rootGetters}, {type}) => {
        let app = getters.app
        let group = rootGetters['profile/getGroup']

        //if (app.name === 'Communications') { //because of notifications, sync badges always
            let btypes = []    
            let notifications = {}
            if (!type) {
                //btypes.push('message_group')
                btypes.push('message_forwardcenter')
                btypes.push('message')
        
                if (group.voicemail) {
                    btypes.push('voicemail')
                }
            } else {
                if (typeof(type) === 'object') {
                    for (let t in type) {
                        let tobj = type[t]

                        btypes.push(t)
                        if (tobj.notification) {
                            notifications[t] = true
                        }
                    }
                } else {
                    let arr = type.split('|')
                    arr.map(t => {
                        if (t === 'voicemail') {
                            if (group.voicemail) {
                                btypes.push('voicemail')
                            }                                        
                        } else {
                            btypes.push(t)
                        }
                    })
                }
            }

            let sortedbtypes = btypes.sort((a,b) => {
                return a.localeCompare(b);
            })
            let key = sortedbtypes.join('|')

            let syncingObj = getters.syncingBadges
            if (syncingObj[key]) {
                console.log(`Already syncing badges ${key}, aborting`)
            }

/*            
            if (!type || type === 'message') {
                if (mode.name == 'Group') {
                    btypes.push('message_group')
                    btypes.push('message_forwardcenter')
    
                    let group = rootGetters['profile/getGroup']
    
                    if (group.voicemail) {
                        btypes.push('voicemail')
                    }
    
                } else {
                    btypes.push('message')
                }    
            }
*/
/*
            if (!type || type === 'voicemail') {
                if (mode.name == 'Group') {
                    let group = rootGetters['profile/getGroup']
    
                    if (group.voicemail) {
                        btypes.push('voicemail')
                    }
                }
            }
*/
            if (btypes && btypes.length) {
                commit('setSyncingBadges', {key: key, value: true})

                let response = await dataClient('ycmdBadgeCount', {badge_type: btypes})

                if (response && response.length) {
                    response.map(b => {

                        switch (b.badge_type) {
                            case 'message': 
                                if (getters.numberMessages !== null) {
                                    if (getters.numberMessages != b.count) {
                                        //do notification
                                        let title = 'New Message';
                                        const body = 'You have received a new message in your inbox';
                                        if (notifications['message']) {
                                            displayNotification(title, body, 
                                                () => {
                                                    dispatch('changeApp',{index: 'Communications', itemIndex: 'Messages'}) //communications, messages
                                                });                                                 
                                        }
                                    }
                                }
                                commit('setNumberMessages', b.count);
                                break
                            case 'message_group':
/*
                                if (getters.numberMessagesGroup !== null) {
                                    if (getters.numberMessagesGroup !== b.count) {
                                        //do notification

                                        //this is never done for group but putting it in here in case they change their minds
                                        let title = 'New Group Message';
                                        const body = 'You have received a new group message in your group inbox';
                                        if (notifications['message_group']) {
                                            displayNotification(title, body, 
                                                () => {
                                                    dispatch('changeApp',{index: 'Communications', itemIndex: 'Messages'}) //communications, messages
                                                });                                                 
                                        }
                                    }
                                }
                            
                                commit('setNumberMessagesGroup', b.count);
                                //not doing a notification
*/                                
                                break
                            case 'message_forwardcenter':
                                if (getters.numberMessagesForwarding !== null) {
                                    if (getters.numberMessagesForwarding !== b.count) {
                                        //do notification

                                        //this is never done for group but putting it in here in case they change their minds
                                        let title = 'New Forwarding Center Message';
                                        const body = 'You have received a new forwarding center message in your forwarding center inbox';
                                        if (notifications['message_forwardcenter']) {

                                            displayNotification(title, body, 
                                                () => {
                                                    dispatch('changeApp',{index: 'Communications', itemIndex: 'Messages'}) //communications, messages
                                                    
                                                    setTimeout(()=> {
                                                        dispatch('triggerGlobalEvent', {mode: 1, app: 'Communications', item: 'Messages', command: 'changetab', data: 'Forwarding'})
                                                    }, 500)
                                                });                                                 
                                        }
                                    }
                                }

                                commit('setNumberMessagesForwarding', b.count);
                                break
                            case 'voicemail':
                                if (getters.numberVoicemail !== null) {
                                    if (getters.numberVoicemail != b.count) {
                                        //do notification 
                                        let title = 'New Voicemail';
                                        const body = 'You have received a new voicemail inbox';
                                            displayNotification(title, body, 
                                                () => {
                                                    dispatch('changeApp',{index: 'Communications', itemIndex: 'Voicemail'}) //communications, messages
                                                });                                                 

                                    }
                                }

                                commit('setNumberVoicemail', b.count);
                                break

                            case 'chat_post':

                                let hasChat = rootGetters['profile/getHasChat']

                                if (hasChat) {
                                    commit('chat/setBadgeCountGlobal', b.count, { root: true })
                                    commit('chat/setBadgeCountRooms', b.additional_data, { root: true })    
                                }

                                break
                        }
                    })      
                }
                commit('setSyncingBadges', {key: key, value: false})
                dispatch('setBadges')    
            }

        //}
    },
    setBadges: ({dispatch, commit, getters, rootGetters}, params) => {
        let app = getters.app

        //if (app.name === 'Communications') {

            /*
            let messages
            if (mode.name == 'Group') {
                messages = (getters.numberMessagesGroup ? getters.numberMessagesGroup : 0) + (getters.numberMessagesForwarding ? getters.numberMessagesForwarding : 0)
                commit('setBadgeInbox', getters.noNegative(getters.numberMessagesGroup));
            } else {
                messages = getters.numberMessages
                commit('setBadgeInbox', getters.noNegative(messages));
            }
            */
            commit('setBadgeInbox', getters.noNegative(getters.numberMessages));
            commit('setBadgeInboxGroup', 0) // getters.noNegative(getters.numberMessagesGroup));

            commit('setBadgeForwardingCenter', getters.noNegative(getters.numberMessagesForwarding));
            commit('setBadgeMessages', getters.noNegative(messages));

            let messages = getters.noNegative(getters.numberMessagesGroup) + getters.noNegative(getters.numberMessages);

            let messagesIndex = app.items.findIndex(i => {
                return i.name === 'Messages'
            })

            if (messagesIndex >= 0) {
                dispatch('setCount', {appIndex: 0, itemIndex: messagesIndex, itemName: 'Messages', count: getters.noNegative(messages)});
            }
            
            let voicemailIndex = app.items.findIndex(i => {
                return i.name === 'Voicemail'
            })

            if (voicemailIndex >= 0) {
                dispatch('setCount', {appIndex: 0, itemIndex: voicemailIndex, itemName: 'Voicemail', count: getters.noNegative(getters.numberVoicemail)});
            }
   
            let chatIndex = app.items.findIndex(i => {
                return i.name === 'Chat'
            })

            if (chatIndex >= 0) {
                dispatch('setCount', {appIndex: 0, itemIndex: chatIndex, itemName: 'Chat', count: rootGetters['chat/getBadgeCountGlobal']});
            }

            //set quick chat badge
            let currentRoom = rootGetters['chat/getCurrentRoom']
            console.log('currentRoom', currentRoom)
            if (currentRoom && currentRoom.id) {
                let currentRoomBadgeCount = rootGetters['chat/getBadgeCountByRoomId'](currentRoom.id)
                console.log('currentRoomBadgeCount', currentRoomBadgeCount)

                commit('chat/setCurrentRoomBadgeCount', currentRoomBadgeCount, {root: true});  
            }
            //dispatch('chat/loadCurrentRoomBadgeCount', b.additional_info ,{ root: true })            


        //}
    },
    adjustBadge: ({commit, getters, dispatch, rootGetters}, {badgeKey, count}) => {

        switch (badgeKey.toLowerCase()) {
            case 'inbox': 
            case 'tab-inbox': 

                let valueInbox = getters.numberMessages + count
                commit('setNumberMessages', valueInbox);
                break
/*
            case 'tab-inbox-group': 
                valueInbox = getters.numberMessagesGroup + count
                commit('setNumberMessagesGroup', valueInbox);
                break
*/    
            case 'tab-forwarding':
            case 'forwardingcenter':
                let valueForwarding = getters.numberMessagesForwarding + count

                commit('setNumberMessagesForwarding', valueForwarding);

                break

            case 'voicemail':
                let valueVM = getters.numberVoicemail + count

                commit('setNumberVoicemail', valueVM);

                break
        }
        dispatch('setBadges')
    },
    triggerGlobalEvent: ({commit, getters, dispatch, rootGetters}, {mode, app, item, command, data}) => {
        let time = (new Date()).getTime()
        commit('setGlobalEvent', `${mode}|${app}|${item}|${command}|${data}|${time}`)
    }

};

const mutations = {
    resetState(state) {
        state.index = 0
        state.itemIndex = 0
        state.list = []
        state.filteredList = []
        state.authenticationStatus = null
        state.authenticationStatusOpen = false
        state.numberMessages = null
        state.numberMessagesGroup = null
        state.numberMessagesForwarding = null
        state.numberVoicemail = null
        state.syncingBadges = {}
        state.badgeInbox = 0
        state.badgeInboxGroup = 0
        state.badgeMessages = 0
        state.badgeForwardingCenter = 0
        state.globalEvent = null
        //apps: []
    },
    init(state, {index, itemIndex, list}) {
        state.index = index;
        state.itemIndex = itemIndex;
        state.list = list;
        //state.filteredList = list;
    },
    changeApp(state, index) {
        state.index = index;
    },
    changeItem(state, itemIndex) {
        state.itemIndex = itemIndex;
    },
    setApps(state, apps) {
        state.apps = apps;
    },
    setCount(state, {appIndex, itemIndex, count, itemName}) {

        let item = state.list[appIndex].items.find(i => {
            return i.name === itemName
        })
        if (item) {
            item['count'] = count
        } else {
            state.list[appIndex].items[itemIndex]['count'] = count;
        }
    },
    setAuthenticationStatus(state, authenticationStatus) {
        state.authenticationStatus = authenticationStatus;
    },
    setAuthenticationStatusOpen(state, authenticationStatusOpen) {
        state.authenticationStatusOpen = authenticationStatusOpen;
    },
    setSyncingBadges(state, payload) {
        state.syncingBadges[payload.key] = payload.value;
    },    
    setNumberMessages(state, data) {
        state.numberMessages = data;
    },   
    setNumberMessagesGroup(state, data) {
        state.numberMessagesGroup = data;
    },   
    setNumberMessagesForwarding(state, data) {
        state.numberMessagesForwarding = data;
    },   
    setNumberVoicemail(state, data) {
        state.numberVoicemail = data;
    },
    setBadgeInbox(state, data) {
        state.badgeInbox = data;
    },
    setBadgeInboxGroup(state, data) {
        state.badgeInboxGroup = data;
    },
    setBadgeMessages(state, data) {
        state.badgeMessages = data;
    },
    setBadgeForwardingCenter(state, data) {
        state.badgeForwardingCenter = data;
    },
    setGlobalEvent(state, data) {
        state.globalEvent = data;
    },
    setAppItemChildItems(state, payload) {
        let app = state.apps.find(a => {
            return a.name == payload.appName 
        })
        if (app) {
            let item = app.items.find(i => {
                return i.name == payload.itemName                 
            })
            if (item) {
                return item.childItems = payload.childItems
            }
        }
    }
}; 

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
