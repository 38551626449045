<template>

  <div class="d-flex">
    <div class="d-flex flex-column justify-center full-width">
      <div class="d-flex pt-2 pb-0" style="margin-left: 64px">
        <v-spacer />
        <div class="fade-2 fs-10 pb-2">
          {{ getTime({ start_date: post.date_created}) }}
        </div>
      </div>

      <div class="d-flex full-width">
          <v-tooltip bottom
            color="ycmd-darkest-blue"
            v-if="post.error"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                  icon dark depressed
                  v-on="on"
                  class="ma-0"
                  @click.stop="resend"
                  >
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        v-if="errorResend"
                        size="18"
                    />  
                    <v-icon v-else dark size="18" color="error">error_outline</v-icon>
              </v-btn>
            </template>
            <span class="text-no-wrap">Resend post</span>
          </v-tooltip>

        <div class="d-flex flex-grow-1 ycmd-light-blue white--text br-3 fs-12 talk-bubble tri-right right-top">
          <div v-html="formatPost(post)" class="d-flex flex-grow-1 pa-3" style="word-break: break-word" />
          <div class="text-right pt-1">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {format, utcToZonedTime} from "date-fns-tz";

export default {
  props: {
    post: Object,
    exactTimestamps: Boolean
  },
  data: () => ({
    format,
    errorResend: false
  }),
  methods: {
    resendDone(params) {
      if (params.status = 'success') {
        
      }
      this.errorResend = false

    },
    resend() {
      this.errorResend =  true
      this.$emit('resend', {id: this.post.id, callback: this.resendDone})
    },
    getTime({ start_date, exact = false }) {

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localDate = utcToZonedTime(start_date, timeZone);

      if(this.exactTimestamps)
        exact = this.exactTimestamps;

      if(!exact)
        return format(localDate, 'h:mm a', { timeZone })
      else
        return format(localDate, 'MM-dd-yyyy h:mm a', { timeZone })
    },
    formatPost(post) {

      let text = post.text

      if ( (post.key && post.key.startsWith('ooo_')) || post.systemType == 'ooo') {
        text += '\n(Automated reply)'
      }
      return text.replace(/\n/g,"<br/>")
    }
  }
}
</script>
