<template>

    <div class="d-flex">

        <v-text-field v-if="!hideSearchInput"
                      :disabled="disabled"
                      hide-details dark flat single-line dense solo clearable
                      label="Search" color="blue"
                      v-model="searchValueLocal"
                      @keyup.enter="searchClick"
                      clear-icon="close"
                      @click:clear="clearClick"
                      :tabindex="1"
                      :class="`hidden-xs-only search-bar ycmd-dark-blue ml-2 br-0 br_tl-2 br_bl-2`"/>

        <v-btn v-if="!hideSearchInput" icon dark depressed
               :disabled="disabled"
               @click="searchClick"
               class="ycmd-blue hidden-xs-only br-0 br_tr-2 br_br-2 mr-0"
               style="width: 56px; height: 38px; margin-left: 2px;">
            <v-icon :size="18">search</v-icon>
        </v-btn>

        <div v-if="hasAdvancedSearch" class="text-right" style="min-width: 48px">
            <v-tooltip bottom
                       nudge-bottom="0"
                       nudge-right="32"
                       color="ycmd-black"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                    <v-btn icon dark
                           :disabled="!hasAdvancedSearch"
                           @click="openRightPanel"
                           v-on="on">
                        <v-icon
                            :size="20"
                            :color="advancedSearchColor ? advancedSearchColor :'white'"                            
                            >filter_list</v-icon>
                    </v-btn>
                </template>
                <span>Advanced Search</span>
            </v-tooltip>
        </div>


    </div>

</template>

<script>

    export default {
        name: 'searchBar',
        components: {
        },
        props: {
            disabled: Boolean,
            searchValue: String,
            openRightPanel: Function,
            onSearchChange: Function,
            onSearchClick: Function,
            hasAdvancedSearch: Boolean,
            hideSearchInput: Boolean,
            advancedSearchColor: String
        },
        data: () => ({

        }),
        mounted() {

        },
        methods: {
            clearClick(e) {

                this.onSearchClick(e, '');
            },
            searchClick(e) {

                if (this.onSearchClick)
                    this.onSearchClick(e, this.searchValueLocal);
            },
            searchChange(e) {
                if (this.onSearchChange)
                    this.onSearchChange(e, this.searchValueLocal);
            },
            searchKeydown(e) {

                console.log('keydown');

                if(e.keyCode === 13) this.searchClick(e);
            }
        },
        computed: {
            searchValueLocal: {
                get: function() {
                    return this.searchValue;
                },
                set: function(newValue) {
                    // console.log('SELECTED: ', newValue);
                    this.$emit('update:searchValue', newValue)
                }
            }
        }
    }
</script>
