<template>

    <div >
        
        <v-select flat 
            v-model="selectedLocation"
            :label="label"
            :items="locations"
            item-text="name"
            item-value="id"
            :background-color="backgroundColor"
            :outlined="outlined"
            :rules="rules"
            hide-details
        >
                
            <template v-slot:itemXXX="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                        {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Specialty:</span> <span class="fw-400">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>
                        <v-divider />
                        </v-col>
                    </v-row>
                </v-container>

            </template>
            <template v-slot:selectionXXX="data">
                <v-container
                class="pa-0 ma-0"
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0 fw-700"
                        >
                        {{`${data.item.lastname ? data.item.lastname+', ':''}${data.item.firstname}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Specialty:</span> <span class="fw-400">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.practiceName}}</span>
                        <v-divider />
                        </v-col>
                    </v-row>
                </v-container>

            </template>
        </v-select>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'emrLocationSelect',
        components: {
        },
        props: {
            rules: {
                type: Array,
                default: () => []
            },  
            value: {
                default: '',
                type: String
            },               
            practiceId: {
                default: '',
                type: String
            },               
            providerId: {
                default: '',
                type: String
            }, 
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            label: {
                default: 'Location',
                type: String
            },
        },
        data: () => ({
            //selectedLocation: ''
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
            /*
            selectedLocation (newValue, oldValue) {
                this.$emit('input', newValue)
            }*/
        },
        methods: {
        },
        computed: {
            ...mapGetters(
                'emr',['getProviders','getPractices']
            ),      
            providerMap () {
                let obj = {}
                this.getProviders.map(provider => {
                    obj[provider.id] = provider
                })
                return obj
            },

            practiceMap () {
                let obj = {}
                this.getPractices.map(practice => {
                    obj[practice.id] = practice
                })
                return obj
            },
            locations () {

                if (!this.practiceId && !this.providerId) {
                    return []
                }

                let practiceId = this.practiceId
                if (!practiceId) {
                    let provider = this.providerMap[this.providerId]
                    practiceId = provider.practiceId
                }

                let practice = this.practiceMap[practiceId]

                return practice.locations
            },
            selectedLocation: {
                get: function() {
                    return this.value ;
                },
                set: function(newValue) {
                    this.$emit('input', newValue)
                }
            }, 
        }
    }
</script>
<style scoped>

.location-select >>> label {
    padding-left: 12px;
}
.location-select >>> .v-select__selections {
    padding-left: 12px;
}


</style>