<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">

            <v-card
                class="full-height"
            >
                <v-card-text>
                    <a :href="v2SchedulingUrl" target="_blank">
                        Scheduling can be accessed here
                    </a>
                </v-card-text>

            </v-card>

            </div>

            <template slot="right-panel">

                <div>Hello right panel</div>

            </template>

            <template slot="bottom-panel">

                <div>Hello Bottom Panel</div>

            </template>

        </i-card>

    </div>

</template>

<script>

    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'redirect',
        components: {
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false },
                },
            }
        },
        created() {},
        async mounted() {
        },

        activated() {},
        watch: {
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
        },
        computed: {
            ...mapGetters(
                'app', ['v2SchedulingUrl', '']
            ),
        }
    }

</script>

<style scoped>

</style>
