<template>

    <div class="d-flex fill-height ycmd-dark-blue-radial-gradient full-width pa-2 br-2">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`#637eb6`"
                 :loader="`bars`">
        </loading>

        <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">REGISTER</div>
                <v-spacer />
                <div>Step 3</div>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                <div class="d-flex flex-column flex-grow-1 full-width pa-3 br-2">

                    <div class="d-flex flex-grow-1 justify-center fs-20 ycmd-medium-blue--text white--text">

                        <v-form
                                ref="registerForm"
                                v-model="validForm"
                                :lazy-validation="false"
                                class="mb-3">

                            <v-layout column class="mb-4 fs-12" style="" style="text-align: center; color: white;">
                                Password must be at least 8 characters, 1 lowercase, 1 uppercase, and at least 1 number
                            </v-layout>

                            <v-text-field dark flat single-line dense solo
                                          v-model="password"
                                          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                          :rules="[rules.required, rules.min]"
                                          :type="showPassword ? 'text' : 'password'"
                                          :tabindex="1"
                                          label="Create New Password"
                                          @input="extraPasswordCheck()"
                                          background-color="ycmd-dark-blue"
                                          @click:append="showPassword = !showPassword" />

                            <v-text-field dark flat single-line dense solo
                                          v-model="confirmPassword"
                                          :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                                          :rules="[rules.required, rules.min]"
                                          :type="showConfirmPassword ? 'text' : 'password'"
                                          :tabindex="2"
                                          label="Confirm New Password"
                                          v-on:keyup.enter="register"
                                          @input="extraPasswordCheck()"
                                          background-color="ycmd-dark-blue"
                                          @click:append="showConfirmPassword = !showConfirmPassword" />

                            <v-layout column class="fs-8" style="color: #FC5252; padding: 0px; text-align: center">
                                {{extraPasswordError}}
                            </v-layout>

                            <v-layout column class="fs-8" style="color: #FC5252; padding: 0px; text-align: center">
                                {{missingRegError}}
                            </v-layout>

                        </v-form>

                    </div>

                </div>

                <div class="d-flex flex-column align-center">

                    <div class="full-width pa-1 pl-0 pr-0">
                        <v-btn dark block large depressed color="ycmd-light-blue" :class="`${ !validateCheck() ? 'disabled' : '' }`" @click="register">Register</v-btn>
                    </div>

                    <div class="pt-2">
                        <v-btn dark text depressed color="white" @click="route('register', 'prev')">Back</v-btn>
                    </div>
                </div>

            </div>


        </div>

        <v-dialog persistent v-model="dialog" max-width="330" style="">

            <v-card color="" dark style="background-color: #09283F; padding-top: 20px">
                <v-card-text class="fs-18" style="text-align: center;">
                    Your account has been registered!
                </v-card-text>

                <v-card-text style="text-align: center; font-size: 20">
                  REDIRECTING IN: <span style="font-weight: bold; font-size: 22px;">{{this.redirectCounter}}</span>
                </v-card-text>

                <v-card-text class="pt-3">
                    Please make sure you have the YouCallMD App downloaded on your device.
                </v-card-text>

                <div v-if="redirectFail">
                  <v-card-text>
                    Haven't redirected yet? Please click one of the corresponding links below
                  </v-card-text>

                  <v-card-text class="pt-4">
                      <a href="https://apps.apple.com/us/app/youcallmd/id918662204">iOS Download</a>
                  </v-card-text>

                  <v-card-text class="pt-4">
                      <a href="https://play.google.com/store/apps/details?id=com.youcallmd.ycmdapp&hl=en_US">Android Download</a>
                  </v-card-text>
                </div>

                <!-- <v-card-actions>
                  <v-layout style="width: 100%;">
                  </v-layout>
                </v-card-actions> -->

                <v-card-actions>
                    <v-btn text color="white" @click="() => {this.route('login', 'prev'); this.resetFields();}" style="width: 100%;">Return</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>

</template>

<script>

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import REGISTER_USER from '../../graphql/mutations/authenticateRegisterUser';

    export default {
        name: 'registerPassword',
        data: () => ({
            isLoading: false,
            isIOS: true,
            redirectFail: false,
            redirectCounter: 5,
            YCMDUser: false,
            dialog: false,
            registrationCode: '',
            YCMDToken: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false,
            showYCMDPassword: false,
            extraPasswordError: '',
            missingRegError: '',
            passwordsMatch: false,
            verificationCode: '',
            validForm: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail';
                }
            }
        }),
        components: {
            Loading
        },
        mounted() {
            this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            // testing
            // this.dialog = true;
            // this.redirectCountDown();

            const registrationCode = localStorage.getItem("registrationCode");
            const YCMDToken = localStorage.getItem("YCMDToken");
            this.registrationCode = registrationCode;
            if (YCMDToken && YCMDToken !== 'null') { this.YCMDToken = YCMDToken };
            if (!this.registrationCode) {
              this.missingRegError = 'Missing registration code. Please resend and navigate from email link.';
            }
        },
        apollo: {
            $client: 'publicClient'
        },
        methods: {
            async register() {
                if (!this.validateCheck) return;

                localStorage.setItem("accessToken", null);


                this.isLoading = true;
                // console.log("YCMD Token: ", this.YCMDToken);
                try {
                    const response = await this.$apollo.mutate({
                        mutation: REGISTER_USER,
                        variables: {
                            password: this.confirmPassword,
                            registration_token: this.registrationCode,
                            ycmd_registration_token: this.YCMDToken || null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // console.log('response: ', response);
                    this.isLoading = false;

                    if (response.data.authenticateRegisterUser) {
                        this.dialog = true;
                        this.redirectCountDown();
                    }  else if (response.errors && response.errors.length > 0) {
                        if (response.errors[0].message && typeof response.errors[0].message === 'string') {
                            this.extraPasswordError = response.errors[0].message || 'Error 2A. Try again later.';
                        } else {
                            this.extraPasswordError = 'Error 2B. Err did not come back.';
                        }
                    } else {
                        // data did not come back
                        console.log('optIn data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.errorMessage = e.message;
                    console.log('CAUGHT: There was an error logging in *registerUser* : ', e);
                }
            },
            redirectCountDown() {
              if (this.redirectCounter > 0) {
                setTimeout(() => {
                  this.redirectCounter--;
                  this.redirectCountDown();
                }, 1000)
              } else {
                // fire the redirect
                if (!this.redirectFail) {
                  setTimeout(() => {
                    this.redirectFail = true;
                  }, 1500);
                }
                this.isIOS ? window.location.href = 'https://apps.apple.com/us/app/youcallmd/id918662204' : window.location.href = 'https://play.google.com/store/apps/details?id=com.youcallmd.ycmdapp&hl=en_US';
              }
            },
            resetFields() {
                this.isLoading = false;
                this.dialog = false;
                this.password = '';
                this.confirmPassword = '';
                this.showPassword = false;
                this.showConfirmPassword = false;
                this.showYCMDPassword = false;
                this.extraPasswordError = '';
                this.missingRegError = '';
                this.validForm = false;
                this.redirectCounter = 5;
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            extraPasswordCheck() {
                // check if passwords match
                if (this.password !== this.confirmPassword) {
                    this.extraPasswordError = 'Passwords do not match.';
                    this.passwordsMatch = false;
                } else {
                    this.extraPasswordError = '';
                    this.passwordsMatch = true;
                }
            },
            validateCheck() {
                if (this.validForm && this.passwordsMatch) {
                  return true;
                }
                return false;
            }
        }
    }

</script>

<style scoped>



</style>
