import {format, utcToZonedTime} from 'date-fns-tz';

export default function ({date, isUTC = false, includeTime = true, overrideFormat}) {

  if (date) {
    let dateFormat = 'MM-dd-yyyy h:mm a';

    if (!includeTime)
      dateFormat = 'MM-dd-yyyy';

    if (overrideFormat) {
      dateFormat = overrideFormat
    }

    if (isUTC) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localDate = utcToZonedTime(date, timeZone);
      let ret = format(localDate, dateFormat, {timeZone})
      console.log(`date: ${date} localDate: ${localDate} ret: ${ret}`)
      return ret
    }
    else {
      return format(new Date(date.slice(0, -5)), 'MM-dd-yyyy h:mm a')
    }
  }
  else
    return 'NA'
}