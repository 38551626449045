import jwt_decode from "jwt-decode";
import axios from "axios";
import store from '@/store'

import REFRESH_TOKEN from '@/graphql/mutations/phoenix/ycmdAuthenticateTokenRefresh';

const { definitions, loc } = REFRESH_TOKEN;
const { name, operation } = definitions[0];
const { body } = loc.source;

// Craft this object based on graphql tag so we don't maintain the query in 2 places
const authenticateRefreshToken = { authorization: 'apikey', operation, name, query: body };

const post = async (dobj, params) => {

    let url = process.env.VUE_APP_APPSYNC_URL;

    let data = {}

    data.query = dobj.query
    data.variables = params || {}

    let headers = {
        'Content-Type': 'application/graphql',
        'cache-control': 'no-cache',
    }

    if (dobj.authorization === 'token') {
        headers.Authorization = localStorage.getItem('accessToken')
    } else {
        headers['x-api-key'] = process.env.VUE_APP_APPSYNC_API_KEY
    }

    // console.log("URL: ", url)
    // console.log("DATA: ", data)
    // console.log("HEADERS: ", headers);

    try {
        return await axios.post(url, data, {headers: headers}, {timeout: 180000});
    }
    catch (e) {
        console.log('ERROR: Retrieving refresh token from the backend: ', JSON.stringify(e))
        return null;
    }
}

const checkAccessToken = (accessToken) => {

    let decoded = jwt_decode(accessToken);
    // console.log('decoded: ', decoded);
    const currentTime = Math.floor(new Date().getTime() / 1000);
    // console.log('difference check: ', decoded.exp - decoded.iat)
    let timeLeft = decoded && decoded.exp ? decoded.exp - currentTime : 0;
    // check edge case after login where exp does not exist (maybe backend sends back token data differently there?) TODO

    return { decoded, timeLeft }
};

async function refreshHandler() {
    // Check expiration of auth token
    const refreshToken = localStorage.getItem("refreshToken");
    const username = localStorage.getItem("username");
    const userID = localStorage.getItem("userID");
    const accessToken = localStorage.getItem("accessToken");
    // const accessToken = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJncm91cF9pZCI6IjUxZGFlYjZlMmMwYTYyNmM2MTAwMDAwNyIsInVzZXJfaWQiOiI1ZTdjMmNkZjUyYWVjZTE5Mjc3MTVjZjIiLCJ1c2VyX2xpbmtfaWQiOm51bGwsImlhdCI6MTYyMzE5OTA0MywiZXhwIjoxNjIzMjAyNjQzfQ.kkTaMXoVbG2r6te4kF_SWFpuvxuMZpnjyXfNRdx2CYBZ1sTIftVgrWbzU0F8_Nw-g8sZtsA5s1Xig0L66IT3tA"

    // console.log('username: ', username);
    // console.log('userID: ', userID);
    // console.log('refresh token in refreshHandler: ', refreshToken);
    // console.log('access token: ', accessToken);
    const { decoded, timeLeft } = checkAccessToken(accessToken);

    if (decoded) {
        localStorage.setItem("userID", decoded.user_id);
    }

    console.log('time left access: ', timeLeft);
    // if (timeLeft < 30)
 
    if (timeLeft < 30) {

        if (refreshToken && (username || userID)) {

            try {
                const response = await post(authenticateRefreshToken, {
                    username: username || null,
                    user_id: userID || null,
                    refresh_token: refreshToken
                })
                console.log('refresh response: ', response);
                if (response.data && 
                    response.data.data && 
                    response.data.data.ycmdAuthenticateTokenRefresh && 
                    response.data.data.ycmdAuthenticateTokenRefresh.authentication_status &&
                    response.data.data.ycmdAuthenticateTokenRefresh.authentication_status.prompt_for_twofactor  ){
                    store.commit('app/setAuthenticationStatus', response.data.data.ycmdAuthenticateTokenRefresh.authentication_status)
                    store.commit('app/setAuthenticationStatusOpen', true)
                }
                            
                // response will be null if no connection

                if (response) {
                    if (response.data && response.data.data && response.data.data.ycmdAuthenticateTokenRefresh && response.data.data.ycmdAuthenticateTokenRefresh.token) {
                        console.log('INFO: Refreshed the token successfully!');
                        localStorage.setItem("accessToken", response.data.data.ycmdAuthenticateTokenRefresh.token);
                        return true;
                    } else {
                        console.log("WARNING: Access token couldn't be retrieved from the refresh token");
                        console.log('response for refresh failure: ', response, 'timeleft: ', timeLeft);
                        return false;
                    }
                } else {
                    // no internet connection
                    console.log("WARNING: Refresh token response is null, probably do not have connection");
                    return true; // returning true so it does not log you out
                }

            }
            catch (e) {
                console.log('ERROR: There was an error refreshing token: ', e);
            }

            return false;
        }
        else {

            if (!refreshToken)
            console.log("WARNING: No refresh token available");

            if (!username || userID)
            console.log("WARNING: No user info available");
        }

    }
    else {
        // Token is still valid
        return true;
    }
}

export { refreshHandler }
