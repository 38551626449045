<template>

    <v-container fluid class="header__container br_tl-2 br_tr-2 pa-3 hidden-sm-and-down relative">

        <slot name="header-start"></slot>

        <slot name="header-end"></slot>

    </v-container>

</template>

<script>

    export default {
        name: 'itemsTotal',
        components: {
        },
        props: {
          count: String,
          total: String
        },
        data: () => ({
        }),
        mounted() {},
        methods: {

        },
        computed: {

        }
    }
    </script>

<style scoped>



</style>
