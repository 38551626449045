<template>

    <div class="d-flex align-center ma-3">

        <div class="section-title" :style="titleStyle">{{ title }}</div>

        <v-spacer />

        <div v-if="(endTitle || endContent)" class="fs-12">

            <span class="section-title">{{ endTitle }} </span> {{ endContent }}

        </div>

        <slot name="end-content"></slot>

    </div>

</template>

<script>

    export default {
        name: 'sectionTitle',
        components: {
        },
        props: {
          title: String,
          endTitle: String,
          endContent: String,          
            titleStyle: {
                type: String,
                default: null
            },          
        },
        data: () => ({
        }),
        mounted() {},
        methods: {

        },
        computed: {

        }
    }
    </script>

<style scoped>

</style>
