<template>

    <div>

        <v-container fluid v-for="(item, i) in items" :key="`item_${i}`" :class="`list-item flex-shrink-0 pa-3 pl-4 pr-2`">

            <slot name="list-item"></slot>

        </v-container>

    </div>

</template>

<script>

  export default {
    name: 'listContainer',
    props: {
      items: Array
    },
    data: () => ({

    }),
    mounted() {

    },
    methods: {

    },
    computed: {

    }
  }
</script>

<style scoped>

</style>
