import gql from 'graphql-tag'

export default gql`mutation authenticateRegisterUser($password: String!, $registration_token: String!, $ycmd_registration_token: String) {
	authenticateRegisterUser(password: $password, registration_token: $registration_token, ycmd_registration_token: $ycmd_registration_token) {
		email
		mobile_number
		user_id
		pcp_id
  }
}`
