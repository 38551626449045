import gql from 'graphql-tag'

export default gql`mutation ycmdMessageSend($to_user_id: ID!, $guest_name: String, $patient_status: PatientStatus, $patient_id: ID, $patient_fname: String, $patient_lname: String, $patient_initial: String, $patient_dob: String, $facility_id: ID, $guest_name: String, $unit_shortcode: ID, $callback: String, $extension: String, $bypass_coverage: Boolean, $direct_admit: Boolean, $contents: String!, $message_answers: [IdValueInput], $unit_shortcode: ID) {
	ycmdMessageSend(to_user_id: $to_user_id, guest_name: $guest_name, patient_status: $patient_status, patient_id: $patient_id, patient_fname: $patient_fname, patient_lname: $patient_lname, patient_initial: $patient_initial, patient_dob: $patient_dob, facility_id: $facility_id, guest_name: $guest_name, unit_shortcode: $unit_shortcode, callback: $callback, extension: $extension, bypass_coverage: $bypass_coverage, direct_admit: $direct_admit, contents: $contents, message_answers: $message_answers, unit_shortcode: $unit_shortcode) {
		status
		message_id
    	message
    	to_name
    	to_title
    	to_specialty
    	to_group
    	version
	}
}`
