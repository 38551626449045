import gql from 'graphql-tag'

export default gql`query ycmdGroupMessageFields($field_id: ID) {
	ycmdGroupMessageFields(field_id: $field_id)
    {
		id
		date_deleted
		default_value
		deleted
		description
		field_type
		filter_id
		group
		label
		last_edit_by_id
		last_edit_date
		name
		options {
			id
			value
		}
		required
		user_id
		include
		value
    }
}`
