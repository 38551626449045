import iAudioPlayer from './i-audio-player.vue';

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(iAudioPlayer);
}

export default {
    install(Vue, options) {
        Vue.component('i-audio-player', iAudioPlayer);
    }
}