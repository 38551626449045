<template>

    <div class="view pb-2"
        ref="messages_root"
    >
        <i-card :viewIndex="card.index"
            :viewCount="1"
            :leftPanel="card.panels.left"
            :rightPanel="card.panels.right"
            :topPanel="card.panels.top"
            :bottomPanel="card.panels.bottom"
            class="i-card mr-2 br-1">

            <div class="i-card__main">

                <div class="i-card__main__header">

                    <transition name="fade-in-transition" mode="out-in">

                        <communication-header :toggleMenuRight="toggleMenuRight">

                            <template v-slot:default>

                                <div >
                                    <v-tooltip bottom
                                        nudge-bottom="0"
                                        nudge-right="40"
                                        color="ycmd-black"
                                        :disabled="$vuetify.breakpoint.xsOnly"
                                        content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark small rounded
                                                    @click="openNewMessage"
                                                    v-on="on"
                                                    class="ml-3 mr-4 ycmd-light-blue">
                                                    <v-icon :size="16">add</v-icon>
                                                </v-btn>
                                            </template>
                                        <span>Compose</span>
                                    </v-tooltip>
                                </div>
                                <v-tabs dark
                                    background-color="transparent"
                                    v-model="headerTabs"
                                    
                                    >

                                    <v-tabs-slider color="ycmd-light-blue"></v-tabs-slider>

                                    <v-tab :disabled="isLoading" href="#tab-inbox" :class="inboxClass">
                                        Inbox
                                        <v-badge
                                            v-if="badgeInbox"
                                            :content="badgeInbox"
                                            class="ml-2 white--text"
                                            color="pink"
                                        />
                                    </v-tab>
                                    <v-tab :disabled="isLoading" href="#tab-sent" class="text-none">Sent</v-tab>
                                    <v-tab :disabled="isLoading" :class="`${groupID ? 'hidden' : ''} text-none`" href="#tab-trash">Trash</v-tab>

                                    <v-tab :disabled="isLoading" href="#tab-inbox-group" :class="inboxClassGroup">
                                        Group Inbox
                                        <v-badge
                                            v-if="badgeInboxGroup"
                                            :content="badgeInboxGroup"
                                            class="ml-2 white--text"
                                            color="pink"
                                        />
                                    </v-tab>
                                    <v-tab :disabled="isLoading" href="#tab-sent-group" :class="sentClassGroup">Group Sent</v-tab>
<!--
                                    <v-tab :disabled="isLoading" href="#tab-trash-group" :class="trashClassGroup">Group Trash</v-tab>
-->
                                    <v-tab :disabled="isLoading" :class="forwardingClass" href="#tab-forwarding">
                                        Forwarding Center
                                        <v-badge
                                            v-if="badgeForwardingCenter"
                                            :content="badgeForwardingCenter"
                                            class="ml-2 white--text"
                                            color="pink"
                                        />
                                    </v-tab>
                                </v-tabs>


                                <v-tooltip bottom
                                    v-if="listView && showTooltips"
                                    nudge-bottom="0"
                                    nudge-right="40"
                                    color="ycmd-black"
                                    content-class="fade-0 px-2 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                            @click="adjustFontSize(1)"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">text_increase</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Grow Text</span>
                                </v-tooltip>

                                <v-tooltip bottom
                                    v-if="listView && showTooltips"
                                    nudge-bottom="0"
                                    nudge-right="40"
                                    color="ycmd-black"
                                    content-class="fade-0 px-2 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                            @click="adjustFontSize(-1)"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">text_decrease</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Shrink Text</span>
                                </v-tooltip>

                                <v-tooltip bottom
                                    v-if="showTooltips"
                                    nudge-bottom="0"
                                    nudge-right="40"
                                    color="ycmd-black"
                                    :disabled="$vuetify.breakpoint.xsOnly"
                                    content-class="fade-0 px-2 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                            @click="toggleListView"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">{{listView ? 'view_agenda' : 'reorder'}}</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{listView ? 'Form view' : 'List view'}}</span>
                                </v-tooltip>

                                <template
                                    v-if="!headerSelectionActionInProgress"
                                >
                                    <span
                                        v-for="a in headerSelectionActionsArray"
                                        :key="`hsa_${a.action}`"
                                        class="white--text pl-2 text-decoration-underline"
                                        style="cursor: pointer;"
                                        @click="confirmHeaderSelectionAction(a)"
                                    >
                                        {{a.action}}
                                    </span>
                                </template>

                                <v-tooltip bottom
                                    v-if="forwardingCenter && showTooltips"
                                    nudge-bottom="0"
                                    nudge-right="40"
                                    color="ycmd-black"
                                    :disabled="$vuetify.breakpoint.xsOnly"
                                    content-class="fade-0 px-2 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                            @click="showForwarding=true"
                                            :loading="isLoadingDownload"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">forward</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Group Forwarding</span>
                                </v-tooltip>

                                <v-tooltip bottom
                                        v-if="showTooltips"
                                        nudge-bottom="0"
                                        nudge-right="40"
                                        color="ycmd-black"
                                        :disabled="$vuetify.breakpoint.xsOnly"
                                        content-class="fade-0 px-2 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon dark rounded
                                            @click="refreshData"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refresh</span>
                                </v-tooltip>
<!--
                            </template>

                            <template v-slot:actions>

                                <input-search :hideSearchInput="true" :hasAdvancedSearch="true"
                                :openRightPanel="_ => card.panels.right = true"/>
-->
                                <v-tooltip bottom
                                    nudge-bottom="0"
                                    v-if="showTooltips"
                                    nudge-right="40"
                                    color="ycmd-black"
                                    :disabled="$vuetify.breakpoint.xsOnly"
                                    content-class="fade-0 px-2 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                            @click="card.panels.right = true"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">filter_list</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Advanced Search</span>
                                </v-tooltip>


                                <v-tooltip bottom
                                    nudge-bottom="0"
                                    v-if="showTooltips"
                                    nudge-right="40"
                                    color="ycmd-black"
                                    :disabled="$vuetify.breakpoint.xsOnly"
                                    content-class="fade-0 px-2 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                            @click="getMessages(true,'0')"
                                            :loading="isLoadingDownload"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">cloud_download</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Download Report</span>
                                </v-tooltip>
                              
                                <v-tooltip bottom
                                    v-if="true"
                                    nudge-bottom="0"
                                    nudge-right="40"
                                    color="ycmd-black"
                                    :disabled="$vuetify.breakpoint.xsOnly"
                                    content-class="fade-0 px-2 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                            @click="selectAll()"
                                            v-on="on"
                                            class="ml-1">
                                            <v-icon :size="18" color="">{{headerSelectIcon}}</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Select all</span>
                                </v-tooltip>


                            </template>

                        </communication-header>

                    </transition>

                </div>

                <div class="i-card__main__content br-1">

                    <div class="i-card__scrollable">

                        <div class="list-container full-height">

                            <v-tabs-items v-model="headerTabs" class="full-tabs">

                                <v-tab-item value="tab-inbox"
                                    transition="fade-in-transition"
                                    reverse-transition="fade-in-transition"
                                     ref="tab-inbox"
                                    >

                                    <list-content-grid
                                        :fontAdjustment="fontAdjustment" 
                                        @onselected="onselected"
                                        :items="items"
                                        :loading="isLoading"
                                        :parent="headerTabs"
                                        :actionList="groupMessagesActionList[messagesEnums.INBOX]"
                                        :reset="resetinboxGrid"
                                        :mailbox="mailbox"
                                        @scrollElementChanged="gridScrollElementChanged"
                                        @gridScroll="gridScroll"
                                        @doAction="doAction"
                                        v-if="listView"
                                        >

                                    </list-content-grid>

                                    <list-content-form :changeView="changeView"
                                        :key="folderTypes['tab-inbox'].key"
                                        :view="folderTypes['tab-inbox'].name"
                                        :messageType="messagesEnums.INBOX"
                                        :actionList="groupMessagesActionList[messagesEnums.INBOX]"
                                        @onselected="onselected"
                                        :items="items"
                                        @doAction="doAction"
                                        :loading="isLoading"
                                        v-else
                                        >

                                    </list-content-form>


                                </v-tab-item>

                                <v-tab-item value="tab-sent"
                                    transition="fade-in-transition"
                                    reverse-transition="fade-in-transition"
                                    ref="tab-sent"                                    
                                    >
                                    <list-content-grid 
                                        :fontAdjustment="fontAdjustment" 
                                        @onselected="onselected"
                                        :items="items"
                                        :loading="isLoading"
                                        :parent="headerTabs"
                                        :actionList="groupMessagesActionList[messagesEnums.SENT]"
                                        :reset="resetsentGrid"
                                        :mailbox="mailbox"
                                        @scrollElementChanged="gridScrollElementChanged"
                                        @gridScroll="gridScroll"
                                        @doAction="doAction"
                                        v-if="listView"
                                        >

                                    </list-content-grid>

                                    <list-content-form :changeView="changeView"
                                        :key="folderTypes['tab-sent'].key"
                                        :view="folderTypes['tab-sent'].name"
                                        :messageType="messagesEnums.SENT"
                                        :actionList="groupMessagesActionList[messagesEnums.SENT]"
                                        @onselected="onselected"
                                        :items="items"
                                        @doAction="doAction"
                                        :loading="isLoading"
                                        v-else
                                        >

                                    </list-content-form>


                                </v-tab-item>

                                <v-tab-item value="tab-trash"
                                    transition="fade-in-transition"
                                    reverse-transition="fade-in-transition"
                                    ref="tab-trash"                                                                        
                                    >

                                    <list-content-grid 
                                        :fontAdjustment="fontAdjustment" 
                                        @onselected="onselected"
                                        :items="items"
                                        v-if="listView"
                                        :loading="isLoading"
                                        :parent="headerTabs"
                                        :actionList="groupMessagesActionList[messagesEnums.TRASH]"
                                        :reset="resettrashGrid"
                                        :mailbox="mailbox"
                                        @scrollElementChanged="gridScrollElementChanged"
                                        @gridScroll="gridScroll"
                                        @doAction="doAction"
                                        >

                                    </list-content-grid>


                                    <list-content-form :changeView="changeView"
                                        :key="folderTypes['tab-trash'].key"
                                        :view="folderTypes['tab-trash'].name"
                                        :messageType="messagesEnums.TRASH"
                                        :actionList="groupMessagesActionList[messagesEnums.TRASH]"
                                        @onselected="onselected"
                                        :items="items"
                                        @doAction="doAction"
                                        :loading="isLoading"
                                        v-else
                                        >

                                    </list-content-form>


                                </v-tab-item>

                                <v-tab-item value="tab-inbox-group"
                                    transition="fade-in-transition"
                                    reverse-transition="fade-in-transition"
                                     ref="tab-inbox-group"
                                    >

                                    <list-content-grid
                                        :fontAdjustment="fontAdjustment" 
                                        @onselected="onselected"
                                        :items="items"
                                        :loading="isLoading"
                                        :parent="headerTabs"
                                        :actionList="groupMessagesActionList[messagesEnums.INBOX]"
                                        :reset="resetinboxGrid"
                                        :mailbox="mailbox"
                                        @scrollElementChanged="gridScrollElementChanged"
                                        @gridScroll="gridScroll"
                                        @doAction="doAction"
                                        v-if="listView"
                                        >

                                    </list-content-grid>

                                    <list-content-form :changeView="changeView"
                                        :key="folderTypes['tab-inbox-group'].key"
                                        :view="folderTypes['tab-inbox-group'].name"
                                        :messageType="messagesEnums.INBOX"
                                        :actionList="groupMessagesActionList[messagesEnums.INBOX]"
                                        @onselected="onselected"
                                        :items="items"
                                        :groupID="savedGroupID"
                                        @doAction="doAction"
                                        :loading="isLoading"
                                        v-else
                                        >

                                    </list-content-form>


                                </v-tab-item>

                                <v-tab-item value="tab-sent-group"
                                    transition="fade-in-transition"
                                    reverse-transition="fade-in-transition"
                                    ref="tab-sent-group"                                    
                                    >
                                    <list-content-grid 
                                        :fontAdjustment="fontAdjustment" 
                                        @onselected="onselected"
                                        :items="items"
                                        :loading="isLoading"
                                        :parent="headerTabs"
                                        :actionList="groupMessagesActionList[messagesEnums.SENT]"
                                        :reset="resetsentGrid"
                                        :mailbox="mailbox"
                                        @scrollElementChanged="gridScrollElementChanged"
                                        @gridScroll="gridScroll"
                                        @doAction="doAction"
                                        v-if="listView"
                                        >

                                    </list-content-grid>

                                    <list-content-form :changeView="changeView"
                                        :key="folderTypes['tab-sent-group'].key"
                                        :view="folderTypes['tab-sent-group'].name"
                                        :messageType="messagesEnums.SENT"
                                        :actionList="groupMessagesActionList[messagesEnums.SENT]"
                                        @onselected="onselected"
                                        :items="items"
                                        :groupID="savedGroupID"
                                        @doAction="doAction"
                                        :loading="isLoading"
                                        v-else
                                        >

                                    </list-content-form>


                                </v-tab-item>

                                <v-tab-item value="tab-trash-group"
                                    transition="fade-in-transition"
                                    reverse-transition="fade-in-transition"
                                    ref="tab-trash"                                                                        
                                    >

                                    <list-content-grid 
                                        :fontAdjustment="fontAdjustment" 
                                        @onselected="onselected"
                                        :items="items"
                                        v-if="listView"
                                        :loading="isLoading"
                                        :parent="headerTabs"
                                        :actionList="groupMessagesActionList[messagesEnums.TRASH]"
                                        :reset="resettrashGrid"
                                        :mailbox="mailbox"
                                        @scrollElementChanged="gridScrollElementChanged"
                                        @gridScroll="gridScroll"
                                        @doAction="doAction"
                                        >

                                    </list-content-grid>


                                    <list-content-form :changeView="changeView"
                                        :key="folderTypes['tab-trash'].key"
                                        :view="folderTypes['tab-trash'].name"
                                        :messageType="messagesEnums.TRASH"
                                        :actionList="groupMessagesActionList[messagesEnums.TRASH]"
                                        @onselected="onselected"
                                        :items="items"
                                        :groupID="savedGroupID"
                                        @doAction="doAction"
                                        :loading="isLoading"
                                        v-else
                                        >

                                    </list-content-form>


                                </v-tab-item>



                                <v-tab-item value="tab-forwarding"
                                    transition="fade-in-transition"
                                    reverse-transition="fade-in-transition"
                                    ref="tab-forwarding"
                                    >

                                    <list-content-grid 
                                        :fontAdjustment="fontAdjustment" 
                                        @onselected="onselected"
                                        :items="items"
                                        :loading="isLoading"
                                        :parent="headerTabs"
                                        :actionList="groupMessagesActionList[messagesEnums.INBOX]"
                                        :reset="resetforwardingGrid"
                                        :mailbox="mailbox"
                                        @scrollElementChanged="gridScrollElementChanged"
                                        @gridScroll="gridScroll"
                                        @doAction="doAction"
                                        v-if="listView"
                                        >

                                    </list-content-grid>


                                        <list-content-form :changeView="changeView"
                                            :key="folderTypes['tab-forwarding'].key"
                                            :view="folderTypes['tab-forwarding'].name"
                                            :messageType="messagesEnums.INBOX"
                                            :actionList="groupMessagesActionList[messagesEnums.INBOX]"
                                            @onselected="onselected"
                                            :items="items"
                                            :groupID="savedGroupID"
                                            @doAction="doAction"
                                            :loading="isLoading"
                                            v-else
                                            >
                                        </list-content-form>


                                </v-tab-item>



                            </v-tabs-items>

                        </div>

                    </div>

                </div>

            </div>

            <template slot="right-panel">

                <messages-advanced-search :advancedSearch.sync="advancedSearch"
                    :advancedSearchCustomFields.sync="advancedSearchCustomFields"
                    :submitAdvancedSearch="submitAdvancedSearch"
                    :clearAdvancedSearch="clearAdvancedSearch"
                    :folder="folderLocal"
                    :inbox_mode="inbox_mode_local"
                    :exclude_folders="excludedFolders"
                    :groupID="groupID"
                    :refresh="clear"
                    @getMessageParameters="advancedGetMessageParameters"
                    />

            </template>

            <template slot="bottom-panel">

                <new-message-nurse
                    v-if="nurseView"
                    :setPanel="setPanel" :newMessageProvider="newMessageProvider"
                />

                <new-message
                    v-else
                    :setPanel="setPanel" :newMessageProvider="newMessageProvider"
                />

            </template>

        </i-card>

        <v-dialog light v-model="dialog" max-width="600">

            <v-card v-if="currentDialog==='forwardMessage'" color="ycmd-blue">

                <v-card-title class="white--text">
                    <v-icon dark class="mr-4">forward_to_inbox</v-icon>
                    Forward Message
                </v-card-title>

                <v-card-text class="white pt-4">
                    <div v-if="(currentSelectedMessage.from_user && currentSelectedMessage.from_user.last_name)"
                        class="ycmd-blue--text mt-0 mb-2 fs-18">{{
                            `${(currentSelectedMessage.from_user && (currentSelectedMessage.from_user.first_name || currentSelectedMessage.from_user.last_name)) ? `Message from - ${currentSelectedMessage.from_user.first_name} ${currentSelectedMessage.from_user.last_name}` : ''}`
                        }}
                    </div>
                    <div class="fs-14">Please search for provider to forward to</div>
                    <ycmd-provider-search
                        class="mt-4"
                        :providerSearchSelected.sync="providerSearchSelected"
                        @selected="selectedProvider"
                        :initialValue="providerSearchValue"
                    />
                </v-card-text>
                <v-card-text>
                    <v-chip
                        v-for="provider in forwardToProviders"
                        :key="provider.id"
                        class="ma-2"
                        close
                        @click:close="removeForwardProvider(provider)"
                    >
                    {{provider.first_name}} {{provider.last_name}} - {{provider.group_name}}
                    </v-chip>
                </v-card-text>
                <v-card-actions>
                    <v-btn dark text color="white" @click="clearDialog()">Cancel</v-btn>
                    <v-spacer/>
                    <v-btn
                        :disabled="!canForward"
                        dark
                        text
                        @click="forwardMessage()">
                        Forward
                    </v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>

        <group-forwarding
            v-model="showForwarding"
            :forwarding="forwarding"
            @saved="saveForwarding"
        />
        <confirmation
            v-model="confirmMultiselectOperation"
            :title="confirmMultiselectOperationTitle"
            subtitle=""
            :text="confirmMultiselectOperationText"
            @click="confirmationClicked"
            :buttons="[
                    {caption: 'OK', disabled: this.headerSelectionActionInProgress},
                    {caption: 'Cancel', disabled: this.headerSelectionActionInProgress}
                ]"
            max-width="500"
        /> 
    </div>

</template>

<script>

import {mapActions, mapState, mapGetters} from "vuex";
import {format, parseISO} from 'date-fns';
import Loading from 'vue-loading-overlay';

import * as messagesEnums from '@/data/communications/enums';
import {groupMessagesActions} from '@/data/communications/constants';

import SubHeaderContent from '@/components/header/sub-header-content';
import Counter from '@/components/header/items-counter';
import ListContainer from '@/components/content/list-container';
import CommunicationHeader from "../components/communication-header";
import YCMDProviderSearch from '@/components/searches/ycmd-provider';

import ListContentGrid from "./list-content-grid";
import ListContentForm from "./list-content-form";
import MessagesAdvancedSearch from './advanced-search';
import NewMessage from './new-message';
import NewMessageNurse from './new-message-nurse';

import GET_MESSAGES from '@/graphql/queries/phoenix/ycmdMessageGet';
import RESEND_MESSAGE from '@/graphql/mutations/phoenix/ycmdMessageResend';
import TRASH_MESSAGE from '@/graphql/mutations/phoenix/ycmdMessageTrash';
import UNTRASH_MESSAGE from '@/graphql/mutations/phoenix/ycmdMessageUntrash';
import FORWARD_MESSAGE from '@/graphql/mutations/phoenix/ycmdMessageForward';
import CONFIRM_MESSAGE from '@/graphql/mutations/phoenix/ycmdMessageConfirm';
import GET_GROUP_MESSAGE_FIELDS from '@/graphql/queries/phoenix/ycmdGroupMessageFields';

import groupForwarding from '@/components/modals/forwarding-center'
import Confirmation from '@/components/modals/confirmation.vue'

import dataClient from '@/graphql/clients/axios';


export default {
    name: 'messages',
    components: {
        'group-forwarding': groupForwarding,
        'communication-header': CommunicationHeader,
        'sub-header-content': SubHeaderContent,
        'counter': Counter,
        'list-items-container': ListContainer,
        'new-message': NewMessage,
        'new-message-nurse': NewMessageNurse,
        'list-content-grid': ListContentGrid,
        'list-content-form': ListContentForm,
        'messages-advanced-search': MessagesAdvancedSearch,
        'ycmd-provider-search': YCMDProviderSearch,
        Loading,
        'confirmation':Confirmation
    },
    apollo: {
        $client: 'phoenixClient'
    },
    props: {
        changeView: Function,
        params: {
            data: Object
        }
    },
    data() {
        return {
            clear: 0,
            format,
            parseISO,
            messagesEnums,
            card: {
                index: 0,
                panels: {left: false, right: false, top: false, bottom: false}
            },
            isLoading: false,
            isLoadingDownload: false,
            isPaging: false,
            headerTabs: null,
            items: [],
            item: {},
            totalItems: 0,

            groupMessagesActions,

            folderTypes: {
                'tab-inbox': {
                    name: 'INBOX',
                    key: 0
                },
                'tab-sent': {
                    name: 'SENT',
                    key: 0
                },
                'tab-trash': {
                    name: 'TRASH',
                    key: 0
                },
                'tab-forwarding': {
                    name: 'Forwarding Center',
                    key: 0
                },
                'tab-inbox-group': {
                    name: 'GROUP INBOX',
                    key: 0
                },
                'tab-sent-group': {
                    name: 'GROUP SENT',
                    key: 0
                },
                'tab-trash-group': {
                    name: 'GROUP TRASH',
                    key: 0
                },

            },
            folder: null,
            page: 1,
            nextToken: '0',
            limit: 20,

            searchValue: '',
            infiniteId: 1,
            loadingMessageResult: '',

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters'
            },

            nextToken: null,
            groupID: null,

            // advanced search
            advancedSearch: {
                facility_id: null,
                from_user_id: null,
                from_role: null,
                to_user_id: null,
                patient_type: null,
                confirmation_status: null,
                messageDateRangeSelected: null,
                customDateRange: null,
                customTimeStart: '00:00',
                customTimeEnd: '23:59',
                message_date: null,
                date_type: null
            },
            advancedSearchCustomFields: null,

            dialog: false,
            currentDialog: '',
            currentSelectedMessage: {},
            action: null,
            actionCompleteCallback: null,
            providerSearchSelected: null,
            newMessageProvider: null,
            showForwarding: false,
            forwarding: {},
            forwardToProviders: [],
            providerSearchValue: null,
            selectedMessages: {},
            headerSelectIcon: 'radio_button_unchecked',
            headerSelectionActions: {},
            headerSelectionActionInProgress: false,
            selectedHeaderAction: null,
            confirmMultiselectOperation: false,
            confirmMultiselectOperationTitle: '',
            confirmMultiselectOperationText: '',
            listView: false,
            scrollElement: null,
            resetinboxGrid: 0,
            resetsentGrid: 0,
            resetforwardingGrid: 0,
            resettrashGrid: 0,
            fontAdjustment: 0
        }
    },
    created() {
    },
    mounted() {
        console.log('this.getMessageView', this.getMessageView)
        this.listView = this.getMessageView == 'grid'

        this.fontAdjustment  = this.getMessageGridFontAdjust ? this.getMessageGridFontAdjust : 0

        this.folder = this.nurseView ? 'SENT' : 'INBOX'
        this.headerTabs = this.nurseView ? 'tab-sent' : 'tab-inbox';

        this.getGroupMessageFields();

        this.forwarding = this.getGroup.forwarding

    },
    activated() {
        console.log('this.params activated', this.params)
    },
    watch: {
        globalEvent: {
            handler (val, oldVal) {

                console.log('globalevent', val)
                if (val.mode == 1 && val.app == 'Communications' && val.item == 'Messages') {
                    if (val.command == 'changetab') {
                        this.headerTabs = 'tab-forwarding'
                    } 
                }
            },
            deep: true
        },
        getUserID(value) {
            //console.log('getUserID changed: ', value);
            this.refreshData();
        },
        headerTabs(value, oldValue) {

            this.folder = this.folderTypes[value].name;
            this.folderTypes[value].key++;
            // clear filter
            this.clearAdvancedSearch();
            // fetch messages

            //this.fetchMessages({shouldIncrementInfiniteID: false});
            this.refreshData()

            switch (value) {
                case 'tab-inbox':
                    this.resetinboxGrid += 1
                    break
                case 'tab-sent':
                    this.resetsentGrid += 1
                    break
                case 'tab-trash':
                    this.resettrashGrid += 1
                    break
                case 'tab-forwarding':
                    this.resetforwardingGrid += 1
                    break

            }

            this.setUpScroll()
        },
        openBottomPanel(value) {
            if (value) {
                this.newMessageProvider = value
                this.card.panels.bottom = true
            }
        },        
        listView (newValue, oldValue) {
            //this.saveListState()
            this.setUpScroll()
        },
        getGroup: {
            handler: function (val, oldVal) {
                this.forwarding = this.getGroup.forwarding
            },
            deep: true
        },
        'params.startingId' (newVal, oldVal) {
            console.log('params.startingId', newVal)
            if (newVal) {
                setTimeout(() => {
                    this.setStartingPosition(newVal)
                }, 500);
                this.params.startingId = ''
            }
        }
    },
    methods: {
        ...mapActions('menuRight', ['openMenuRight', 'closeMenuRight', 'toggleMenuRight']),
        advancedGetMessageParameters(params) {

            let pars = this.getMessageParameters(params.folder)

            if (params.parameters) {
                for (let p in pars) {
                    params.parameters[p] = pars[p]
                }
            }
        },
        getMessageParameters(folder) {

            switch (folder.toLowerCase()) {
                case 'forwarding center':
                    return {folder: 'INBOX',
                            inbox_mode: 'group',
                            excluded: null, 
                            group_id: this.savedGroupID
                    }
                
                case 'inbox':
                case 'group inbox':
                    return {folder: 'INBOX',
                            inbox_mode: 'all',
                            excluded: [this.folderTypes['tab-sent'].name, this.folderTypes['tab-trash'].name],
                            group_id: folder.toLowerCase().startsWith('group') ? this.savedGroupID: null
                    }
                case 'sent':
                case 'group sent':
                    return {folder: 'SENT',
                            inbox_mode: 'all',
                            excluded: null,
                            group_id: folder.toLowerCase().startsWith('group') ? this.savedGroupID: null
                    }
                case 'trash':
                case 'group trash':
                    return {folder: 'TRASH',
                            inbox_mode: 'all',
                            excluded: null,
                            group_id: folder.toLowerCase().startsWith('group') ? this.savedGroupID: null
                    }
            }
        },
        toggleListView () {
            this.listView = !this.listView
            this.saveListState()
        },
        adjustFontSize (adjustment) {
            this.fontAdjustment += adjustment
            this.saveFontAdjustmentState()
        },
        async saveListState () {

            let params = {
                message_view: this.listView ? 'grid': 'form'
            }

            let result = await dataClient('ycmdUserSettings', params)

            console.log('result: ', result);
        },
        async saveFontAdjustmentState () {

            let params = {
                message_grid_font_adjustment: this.fontAdjustment ? this.fontAdjustment: 0
            }

            let result = await dataClient('ycmdUserSettings', params)

        },
        gridScroll (params) {
            console.log('gridScroll', params)
            this.onScroll(params)
        },
        gridScrollElementChanged(element) {
            //Obsolete
            if (element) {

                if (this.scrollElement) {
                    this.scrollElement.removeEventListener('scroll', this.onScroll, false)
                    this.scrollElement = null
                }

                this.scrollElement = element
                this.scrollElement.addEventListener('scroll', this.onScroll, false)
            } 
        },        
        onScroll (params) {
            // console.log(`${new Date}`, params)

            //let window 

            let el = params.srcElement
            console.log(`scrollTop: ${el.scrollTop}, scrollHeight: ${el.scrollHeight} clientHeight: ${el.clientHeight} max: ${el.scrollHeight - el.clientHeight}`)
        
            let lastPage = el.scrollHeight - el.clientHeight

            let current = el.scrollTop + el.clientHeight
            console.log(`lastPage: ${lastPage}, current: ${current} page: ${this.page}`)

            if (current > lastPage && !this.paging) {
                if (this.page < 99999) {
                    this.page += 1
                    this.getMessages(false,this.nextToken)
                }
            }

        },
        setUpScroll() {

            if (this.listView) {
                return
                // the grids will emit their scroll element.  We can't reliably find it from here
            }

            console.log('set up scroll', this.headerTabs, this.listView)
            

            if (this.scrollElement) {
                this.scrollElement.removeEventListener('scroll', this.onScroll, false)
                this.scrollElement = null
            }

            let tab = this.headerTabs

            this.scrollElement = document.getElementById(tab)

            if (this.scrollElement) {
                this.scrollElement.addEventListener('scroll', this.onScroll, false)
            } else {
                //some times element doesn't exist yet.  wait a couple of seconds and then try
                setTimeout(() => {
                    console.log('scroll delaying')
                    this.setUpScroll()
                }, 2000);
            }
            
        },
        doAction (params) {
            //let the tooltips dismiss
            this.$nextTick(() => {
                this.continueDoAction(params)
            })
        },
        continueDoAction(params) {
            this.action = params.action.function
            this.currentSelectedMessage = params.item;
            this.actionCompleteCallback = params.completeCallback

            switch (params.action.function) {
                case 'forwardMessage':
                    this.providerSearchSelected = null;
                    this.forwardToProviders = []
                    this.currentDialog = 'forwardMessage';
                    this.dialog = true;
                    break
                case 'resendMessage':
                    this.resendMessage(params.item, params.completeCallback)
                    break
                case 'trashMessage':
                    this.trashMessage(params.item, params.completeCallback)
                    break
                case 'restoreMessage':
                    this.restoreMessage(params.item, params.completeCallback)
                    break
                case 'confirmMessage':
                    this.confirmMessage(params.item, params.completeCallback)
            }
        },
        confirmHeaderSelectionAction(action) {
            this.selectedHeaderAction = action

            this.confirmMultiselectOperationText = `Are you sure you want to ${action.action.toLowerCase()} ${action.items.length} messages?`
            this.confirmMultiselectOperationTitle = `Confirm ${action.action}`

            this.confirmMultiselectOperation = true
        },
        async confirmationClicked (btn) {
            if (btn.caption === 'OK') {
                await this.doHeaderSelectionAction()
            }
            this.confirmMultiselectOperation = false
        },            

        async doHeaderSelectionAction() {
            let action = this.selectedHeaderAction
            let promises = []
            this.headerSelectionActionInProgress = true
            let actionName

            let silent = action.items.length > 1 ? true : false
            action.items.map(i => {
                switch (action.action) {
                    case 'Trash':
                        promises.push(this.trashMessage(i, null, silent))
                        actionName = 'trashed'
                        break
                    case 'Confirm':
                        promises.push(this.confirmMessage(i, null, silent))
                        actionName = 'confirmed'
                        break
                    case 'Restore':
                        promises.push(this.restoreMessage(i, null, silent))
                        actionName = 'restored'
                        break
                }
            })

            let results = await Promise.all(promises)
            this.selectAll(false)
            this.updateHeaderSelectIcon()
            this.headerSelectionActionInProgress = false
            if (silent) {
                this.$toasted.success(`Submitted ${promises.length} messages to be ${actionName}`);
            }
        },
        selectAll(optionalValue) {
            let value = this.headerSelectIcon ===  'check_circle_outline' ? false: true

            if (typeof(optionalValue) !== 'undefined') {
                value = optionalValue
            }

            let length = this.items.length
            for (let i = 0;i < length; i++){
                let newItem = this.items[i]

                this.$set(newItem, 'selected',value)
                this.$set(this.items, i, newItem)
            }
            this.updateHeaderSelectIcon()            
        },
        onselected(item, newValue) {
            let existingItemIndex = this.items.findIndex(i => {
                return item.id === i.id
            })
            if (existingItemIndex >= 0) {
                let existingItem = this.items[existingItemIndex]

                this.$set(existingItem, 'selected', newValue)
                this.$set(this.items, existingItemIndex, existingItem)
            }
            this.updateHeaderSelectIcon()
        },
        updateHeaderSelectIcon () {

            let oneSelected = false
            let oneUnselected = false

            let actions = {}
            this.items.map(i => {
                console.log('this.folder', this.folder)
                if (i.selected) {

                    oneSelected = true
                    if (!i.date_confirmed && this.folder !== 'SENT') {
                        if (!actions.Confirm) {
                            actions.Confirm = {
                                items: []
                            }
                        }
                        actions.Confirm.items.push(i)
                    }

                    if (this.folder === 'INBOX') {
                        if (!actions.Trash) {
                            actions.Trash = {
                                items: []
                            }
                        }
                        actions.Trash.items.push(i)
                    }
                    if (this.folder === 'TRASH') {
                        if (!actions.Restore) {
                            actions.Restore = {
                                items: []
                            }
                        }
                        actions.Restore.items.push(i)
                    }
                } else {
                    oneUnselected = true
                }
            })

            if (oneSelected && oneUnselected) {
                this.headerSelectIcon = 'remove_circle_outline'
            } else {
                this.headerSelectIcon =  oneSelected ? 'check_circle_outline' : 'radio_button_unchecked'
            }

            this.headerSelectionActions = actions
        },
        saveForwarding(forwarding) {
            this.$store.commit('profile/setForwarding', forwarding)
            this.forwarding = forwarding
        },
        openNewMessage() {
            this.newMessageProvider = null
            this.card.panels.bottom = true
        },
        isAllowed(action) {
            switch (action.name) {
                case 'Resend':
                case 'Forward':
                    if (this.legacyRoleMap['provider']) {
                        return true
                    }
                    if (this.legacyRoleMap['member']) {
                        return true
                    }
                    if (this.legacyRoleMap['agent']) {
                        return true
                    }
                    
                    return false
                default:
                    return true
            }
        },
        setPanel(side, value) {
            this.card.panels[side] = value;
        },
        showDetail(data) {
            this.changeView({view: 'details-view', params: {data}});
        },
        parseCustomFields(data) {
            let filteredFields = [];
            for (let item in data) {
                const currentItem = data[item];
                //console.log('currentItem: ', currentItem);
                if (currentItem.id && currentItem.value) {
                    filteredFields.push({
                        id: currentItem.id,
                        value: currentItem.value.id || currentItem.value
                    })
                }
            }
            //console.log('returning custom: ', filteredFields);
            return filteredFields.length ? filteredFields : null
        },
        clearAdvancedSearch() {
            for (let item in this.advancedSearch) {
                this.advancedSearch[item] = null;
            }
            // reset time
            this.advancedSearch.customTimeStart = '00:00';
            this.advancedSearch.customTimeEnd = '23:59';

            if (this.advancedSearchCustomFields) {
                for (let f in this.advancedSearchCustomFields) {
                    let field = this.advancedSearchCustomFields[f]
                    field.value = null
                }
            }

            this.clear++
        },
        submitAdvancedSearch({shouldRefresh, closePanel}) {
            if (shouldRefresh) {
                // refresh data
                this.refreshData();
            }
            if (closePanel) {
                // close panel
                this.setPanel('right', false);
            }
        },
        closeDialog() {
            this.dialog = false;
            this.currentDialog = null;
        },
        clearDialog() {
            this.dialog = false;
            this.currentDialog = null;
            this.currentSelectedMessage = {};
            if (this.actionCompleteCallback) {
                this.actionCompleteCallback(this.action, this.currentSelectedMessage, {
                    status: 'cancel'
                })
            }

        },
        // onSearchClick(e, value) {
        //
        //     console.log(value);
        //
        // },
        // onSearchChange(e, value) {
        //
        //     console.log(value);
        // },
        slide(direction) {
            this.card.panels[direction] = !this.card.panels[direction];
        },
        route(name, transition) {

            this.$router.push({
                name: name, params: {
                    transition: transition
                }
            }).catch(err => {
                console.log('router error: ', err)
            });
        },
        async getGroupMessageFields() {

            try {
                const response = await this.$apollo.query({
                    query: GET_GROUP_MESSAGE_FIELDS,
                    variables: {},
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });

                //console.log('response: ', response);

                const {ycmdGroupMessageFields} = response.data

                if (ycmdGroupMessageFields) {
                    this.advancedSearchCustomFields = ycmdGroupMessageFields;
                } else {
                    // data did not come back
                    console.log('ycmdGroupMessageFields did not come back: ', response);
                }
            } catch (e) {
                // this.$toasted.error(`Caught an error trying to get custom fields`);
                console.log('WARNING: Caught error - ', e);
                // defaultErrorHandler(e);
            }
        },
        /*
        fetchMessages({shouldIncrementInfiniteID}) {
            this.items = [];
            this.page = 1;
            if (shouldIncrementInfiniteID) {
                this.infiniteId += 1;
            }
            //console.log('running fetch messages');
        },
        */
        async resendMessage(item, completedCallback) {

            //console.log('item: ', item);

            // optimistic
            //this.$set(item, 'isItemLoading', true);

            try {
                const response = await this.$apollo.mutate({
                    mutation: RESEND_MESSAGE,
                    variables: {
                        message_id: item.id
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                //item.isItemLoading = false;

                //console.log('response: ', response);

                const {ycmdMessageResend} = response.data

                if (ycmdMessageResend) {
                    this.$toasted.success(`Successfully resent message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`);

                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'success'
                        })
                    }
                
                } else {
                    // data did not come back
                    let errormessage = `There was an error resending message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`
                    this.$toasted.error(errormessage);
                    console.log('ycmdMessageResend did not come back: ', response);
                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'error',
                            message: errormessage
                        })
                    }
                
                }
            } catch (e) {
                //item.isItemLoading = false;
                this.$toasted.error(`There was an error resending`);
                console.log('WARNING: Caught error - ', e);
                if (completedCallback) {
                    completedCallback(this.action, this.currentSelectedMessage, {
                        status: 'error',
                        message: `There was an error resending`
                    })
                }
                // defaultErrorHandler(e);
            }
        },
        async restoreMessage(item, completedCallback, skipToast) {
            // const currentHeaderTabs = this.headerTabs;

            // optimistic
            this.$set(item, 'isItemLoading', true);

            try {
                const response = await this.$apollo.mutate({
                    mutation: UNTRASH_MESSAGE,
                    variables: {
                        message_id: item.id
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                item.isItemLoading = false;

                //console.log('response: ', response);

                const {ycmdMessageUntrash} = response.data

                if (ycmdMessageUntrash) {
                    // remove item
                    for (let index in this.items) {
                        if (this.items[index].id === item.id) this.items.splice(index, 1);
                    }
                    this.totalItems--;
                    // notify user
                    if (!skipToast) {
                        this.$toasted.success(`Successfully restored message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`);
                    }
                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'success'
                        })
                    }

                } else {
                    // data did not come back
                    // item.isItemLoading = false;
                    let errormessage = `There was an error restoring message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`
                    this.$toasted.error(errormessage);
                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'error',
                            message: errormessage
                        })
                    }

                    console.log('ycmdMessageUntrash did not come back: ', response);
                }
            } catch (e) {
                item.isItemLoading = false;

                this.$toasted.error(`There was an error trying to restore message`);
                console.log('WARNING: Caught error - ', e);

                if (completedCallback) {
                    completedCallback(this.action, this.currentSelectedMessage, {
                        status: 'error',
                        message: `There was an error trying to restore message`
                    })
                }

                // defaultErrorHandler(e);
            }
        },
        selectMessage (item) {
            console.log('select message', item)
            if (this.selectedMessages[item.id]) {
                this.selectedMessages[item.id] = false
            } else {
                this.selectedMessages[item.id] = true
            }
        },
        async trashMessage(item, completedCallback, skipToast) {
            // const currentHeaderTabs = this.headerTabs;
            // optimistic
            this.$set(item, 'isItemLoading', true);

            try {

                const response = await this.$apollo.mutate({
                    mutation: TRASH_MESSAGE,
                    variables: {
                        message_id: item.id
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                item.isItemLoading = false;

                //console.log('response: ', response);

                const {ycmdMessageTrash} = response.data

                if (ycmdMessageTrash) {
                    // remove item
                    for (let index in this.items) {
                        if (this.items[index].id === item.id) this.items.splice(index, 1);
                    }
                    this.totalItems--;
                    // notify user
                    if (!skipToast) {
                        this.$toasted.success(`Successfully trashed message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`);
                    }
                
                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'success'
                        })
                    }                
                
                } else {
                    // data did not come back
                    // item.isItemLoading = false;


                    let errormessage = `There was an error trashing message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`
                    this.$toasted.error(errormessage);
                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'error',
                            message: errormessage
                        })
                    }
                    console.log('ycmdMessageTrash did not come back: ', response);
                }
            } catch (e) {
                item.isItemLoading = false;

                this.$toasted.error(`There was an error trying to trash message`);
                if (completedCallback) {
                    completedCallback(this.action, this.currentSelectedMessage, {
                        status: 'error',
                        message: `There was an error trying to trash message`
                    })
                }

                console.log('WARNING: Caught error - ', e);
                // defaultErrorHandler(e);
            }
        },
        async confirmMessage(item, completedCallback, skipToast ) {
            // optimistic

            this.$set(item, 'isItemLoading', true);

            // Set timestamp here since it ignores latency and matches what gets written to database more accurately
            const date = new Date();
            const timestamp = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

            try {
                const response = await this.$apollo.mutate({
                    mutation: CONFIRM_MESSAGE,
                    variables: {
                        message_id: item.id
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                item.isItemLoading = false;

                // console.log('response: ', response);

                const {ycmdMessageConfirm} = response.data

                if (ycmdMessageConfirm) {

                    let confirmedDate = ycmdMessageConfirm.find(arr => {
                        return arr.name == 'group_date_confirmed'
                    })

                    if (confirmedDate) {
                        confirmedDate = confirmedDate.value
                    } else {
                        confirmedDate = timestamp
                    } 

                    let existingItemIndex = this.items.findIndex(i => {
                        return item.id === i.id
                    })
                    if (existingItemIndex >= 0) {
                        let existingItem = this.items[existingItemIndex]

                        this.$set(existingItem, 'date_confirmed', confirmedDate)
                        this.$set(this.items, existingItemIndex, existingItem)
                    }

                    // set date clientside
                    //item.date_confirmed = timestamp;

                    // notify user
                    if (!skipToast) {
                        this.$toasted.success(`Successfully confirmed message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`);
                    }

                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'success'
                        })
                    }

                    this.$store.dispatch('app/adjustBadge', 
                        {
                            badgeKey: this.headerTabs,// this.headerTabs === 'tab-forwarding' ? 'forwardingcenter': 'inbox',
                            count: -1
                        })
                } else {
                    // data did not come back
                    item.isItemLoading = false;

                    let error = `There was an error confirming message ${(item.from_user && (item.from_user.first_name || item.from_user.last_name)) ? `from ${item.from_user.first_name} ${item.from_user.last_name}` : ''}`

                    if (response.errors && response.errors.length && response.errors[0].message) {
                        error += `: '${response.errors[0].message}'`
                    }

                    this.$toasted.error(error);
                    console.log('ycmdMessageConfirm did not come back: ', response);
                    if (completedCallback) {
                        completedCallback(this.action, this.currentSelectedMessage, {
                            status: 'error',
                            message: error
                        })
                    }                    
                }
            } catch (e) {
                item.isItemLoading = false;

                let error = `There was an error when trashing the message`
                this.$toasted.error(error);
                console.log('WARNING: Caught error - ', e);
                if (completedCallback) {
                    completedCallback(this.action, this.currentSelectedMessage, {
                        status: 'error',
                        message: error
                    })
                }                    

                // defaultErrorHandler(e);
            }
        },

        async forwardMessage() {
            this.closeDialog();
            this.$set(this.currentSelectedMessage, 'isItemLoading', true);

            let to_user_id = null
            let to_user_ids = null

            if (this.forwardToProviders.length === 1) {
                to_user_id = this.forwardToProviders[0].id
            } else if (this.forwardToProviders.length > 1) {
                to_user_ids = []
                this.forwardToProviders.map(u => {
                    to_user_ids.push(u.id)
                })
            }

            try {
                const response = await this.$apollo.mutate({
                    mutation: FORWARD_MESSAGE,
                    variables: {
                        message_id: this.currentSelectedMessage ? this.currentSelectedMessage.id : null,
                        to_user_id: to_user_id,
                        to_user_ids: to_user_ids
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                this.currentSelectedMessage.isItemLoading = false;
                //console.log('response: ', response);

                const {ycmdMessageForward} = response.data
                if (ycmdMessageForward) {
                    let messages = this.interpretMessage(ycmdMessageForward)
                    this.showToast(messages)
                    let inbox_mode = this.headerTabs === 'tab-forwarding' ? 'group': 'all'
                    //need to update the message
                    let newMessage = await this.$apollo.query({
                        query: GET_MESSAGES,
                        variables: {
                            group_id: this.groupID || null,
                            message_id: this.currentSelectedMessage ? this.currentSelectedMessage.id : null,
                            inbox_mode: inbox_mode
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });                    

                    if (newMessage && newMessage.data && newMessage.data.ycmdMessageGet && newMessage.data.ycmdMessageGet.messages && newMessage.data.ycmdMessageGet.messages.length) {

                        let nm = newMessage.data.ycmdMessageGet.messages[0]
                        
                        let messageIndex = this.items.findIndex(m => {
                            return m.id == nm.id
                        })

                        if (messageIndex >= 0) {
                            this.items.splice(messageIndex, 1, nm);
                        }

                    }

                    if (this.actionCompleteCallback) {
                        this.actionCompleteCallback(this.action, this.currentSelectedMessage, {
                            status: 'success'
                        })
                        this.onselected(this.currentSelectedMessage, false) // this forces reactivity to the grid
                    }
                    this.updateHeaderSelectIcon()

                    //this.$toasted.success(`Successfully forwarded message to ${this.forwardedNames}`);
                } else {
                    // data did not come back
                    let error = `There was an error forwarding message to ${this.forwardedNames}`
                    if (response.errors && response.errors.length && response.errors[0].message) {
                        error += `: '${response.errors[0].message}'`
                    }
                    this.$toasted.error(error);
                    console.log('ycmdMessageForward did not come back: ', response);
                    if (this.actionCompleteCallback) {
                        this.actionCompleteCallback(this.action, this.currentSelectedMessage, {
                            status: 'error',
                            message: error
                        })
                    }
                }
            } catch (e) {
                this.currentSelectedMessage.isItemLoading = false;
                this.$toasted.error(`There was an error forwarding the message`);
                console.log('WARNING: Caught error - ', e);
                if (this.actionCompleteCallback) {
                    this.actionCompleteCallback(this.action, this.currentSelectedMessage, {
                        status: 'error',
                        message: 'There was an error forwarding the message'
                    })
                }
                // defaultErrorHandler(e);
            }
        },
        refreshData() {
            this.page = 1;
            this.nextToken = '0'
            this.items = [];
            this.currentItems = 0;
            this.totalItems = 0;

            //this.infiniteId++;
            this.getMessages(false, this.nextToken)

        },
        getExcludedFolders() {
            return this.excludedFolders
            /*
            switch (this.folder) {
                case this.folderTypes['tab-inbox'].name:
                    return [this.folderTypes['tab-sent'].name, this.folderTypes['tab-trash'].name];
                break;
                case this.folderTypes['tab-sent'].name:
                    return null;
                break;
                case this.folderTypes['tab-trash'].name:
                    return null;
                break;
                default:
                    return null;
            }
            */
        },
        async getMessages(inCSV, nextToken) {
            console.log('nextToken', nextToken)
            this.isLoadingDownload = true;
            this.combineDateAndTime();
            if (this.page == 99999) {
                //no more data
                return
            }

            try {
                this.paging = true
                console.log('parameters folder', this.folder)
                let parameters = this.getMessageParameters(this.folder)
                console.log('parameters ', parameters)


                if (nextToken == '0') {
                    this.isLoading = true
                }

                let params =  {
                        inbox_mode: parameters.inbox_mode,
                        folder: parameters.folder,
                        exclude_folders: parameters.excluded,
                        //page: this.page,
                        nextToken: nextToken,
                        limit: this.limit,
                        group_id: parameters.group_id,
                        facility_id: (this.advancedSearch.facility_id && this.advancedSearch.facility_id.facility) ? this.advancedSearch.facility_id.facility : null,
                        facility_unit_shortcode: (this.advancedSearch.facility_id && this.advancedSearch.facility_id.shortcode) ? this.advancedSearch.facility_id.shortcode : null,
                        from_user_id: this.advancedSearch.from_user_id ? this.advancedSearch.from_user_id.id : null,
                        from_role: this.advancedSearch.from_role ? this.advancedSearch.from_role.value : null,
                        to_user_id: this.advancedSearch.to_user_id ? this.advancedSearch.to_user_id.id : null,
                        patient_type: this.advancedSearch.patient_type ? this.advancedSearch.patient_type.value : null,
                        confirmation_status: this.advancedSearch.confirmation_status ? this.advancedSearch.confirmation_status.value : null,
                        start_date: (this.advancedSearch.messageDateRangeSelected && this.advancedSearch.messageDateRangeSelected.start_date) ? this.advancedSearch.messageDateRangeSelected.start_date : null,
                        end_date: (this.advancedSearch.messageDateRangeSelected && this.advancedSearch.messageDateRangeSelected.end_date) ? this.advancedSearch.messageDateRangeSelected.end_date : null,
                        message_answers: this.advancedSearchCustomFields ? this.parseCustomFields(this.advancedSearchCustomFields) : null,
                        output_type: inCSV ? 'csv' : null,
                        message_date: (this.advancedSearch.message_date && this.advancedSearch.message_date) ? this.advancedSearch.message_date : null,
                        include_bulletins: true
                    }

                this.isLoadingDownload = false;

                let result = await dataClient('ycmdMessageGet', params)
                this.nextToken = result.nextToken ? result.nextToken: "99999"

                this.updateHeaderSelectIcon()

                if (result && result.messages_url) {
                    this.$toasted.success('Generated CSV. Please make sure pop-up blocker is not enabled');
                    window.open(result.messages_url, '_blank');
                } else {
                    if (result && result.messages && result.messages.length) {
                        if (this.page <= 1) {
                            this.items.length = 0    
                        }
                        this.items.push(...result.messages)
                    } else {
                        // no more data.  set page to 99999
                        this.page = 99999
                    }
                }
                this.paging = false
                this.isLoading = false
                
            } catch (e) {
                this.isLoadingDownload = false;
                console.log('e', e)

                this.$toasted.error('Caught error downloading CSV');
                console.log('Caught error getting messages: ', e);
                // defaultErrorHandler(e);
                this.paging = false
                this.isLoading = false
            }
        },

        combineDateAndTime() {
            if (this.advancedSearch.messageDateRangeSelected && this.advancedSearch.messageDateRangeSelected.end_date) {
                // combine the date with the hours/minutes set
                if (this.advancedSearch.customTimeEnd) {
                    const time = this.advancedSearch.customTimeEnd.split(':');
                    if (time[0]) this.advancedSearch.messageDateRangeSelected.end_date.setHours(time[0]); // Set Hours
                    if (time[1]) this.advancedSearch.messageDateRangeSelected.end_date.setMinutes(time[1]); // Set Minutes
                    this.advancedSearch.messageDateRangeSelected.end_date.setSeconds(59); // Set Seconds
                }
            }
            if (this.advancedSearch.messageDateRangeSelected && this.advancedSearch.messageDateRangeSelected.start_date) {
                if (this.advancedSearch.customTimeStart) {
                    const time = this.advancedSearch.customTimeStart.split(':');
                    if (time[0]) this.advancedSearch.messageDateRangeSelected.start_date.setHours(time[0]); // Set Hours
                    if (time[1]) this.advancedSearch.messageDateRangeSelected.start_date.setMinutes(time[1]); // Set Minutes
                    this.advancedSearch.messageDateRangeSelected.end_date.setSeconds(0); // Set Seconds
                }
            }
        },
        selectedProvider (provider) {
            if (provider && provider.id) {
                this.forwardToProviders.push(provider)
                this.$nextTick(() => {
                    this.providerSearchValue = "aaa"
                    this.providerSearchSelected = null
                })
            }
        },
        removeForwardProvider (provider) {
            if (provider && provider.id) {
                let index = this.forwardToProviders.findIndex(i => {
                    return i.id == provider.id
                })

                if (index >= 0) {
                    this.forwardToProviders.splice(index, 1)
                }
            }
        },
        interpretMessage (ycmdMessageForward) {
            let messages = []

            if (ycmdMessageForward && ycmdMessageForward.data) {
                let info = JSON.parse(ycmdMessageForward.data) 
                console.log('info', info)
                
                let successUsers = ''

                if (info && info.success_sent && info.success_sent.length) {
                    info.success_sent.map(s => {
                        let provider = this.forwardToProviders.find(i => {
                            return i.id == s
                        })
                        if (provider) {
                            if (successUsers) {
                                successUsers+=', '
                            }
                            successUsers+=`${provider.first_name} ${provider.last_name}`
                        }
                    })
                }

                let errorUsers = ''
                if (info && info.errors && info.errors.length) {
                    info.errors.map(e => {
                        let provider = this.forwardToProviders.find(i => {
                            return i.id == e.user_id
                        })
                        if (provider) {
                            if (errorUsers) {
                                errorUsers+=', '
                            }
                            errorUsers+=`${provider.first_name} ${provider.last_name}: '${e.error}'`
                        }
                    })
                }

                if (successUsers) {
                    let m = {
                            message: `Successfully forwarded message to ${successUsers}`,
                            options: { type: 'success'},
                            }
                    messages.push(m)

                }
                
                if (errorUsers) {
                    let m = {
                        message: `${errorUsers}.`,
                        options: { duration: false, timeout: false, type: 'error',
                            action : {
                                text : 'Close',
                                onClick : (e, toastObject) => {
                                    toastObject.goAway(0);
                                }
                            },
                        }
                    }
                    messages.push(m)
                }
            }
            return messages
        }, showToast (messages) {
            messages.map(m => {
                this.$toasted.show(m.message,m.options);
            })
        },
        setStartingPosition (id) {
            let target = document.getElementById(id)
            let container = this.$refs[this.headerTabs]

            this.$vuetify.goTo(target, { container: container} ) //this.$refs.messages_container });
        }
    },
    computed: {
        ...mapState({
            isMenuOpen: state => state.menu.open,
            isMenuRightOpen: state => state.menuRight.open,
            appItemIndex: state => state.app.itemIndex,
            savedGroupID: state => state.profile.info.group.id
        }),
        ...mapGetters(
            'profile', ['getUserID','openBottomPanel','getGroup', 'getMessageView', 'getMessageGridFontAdjust', 'hasRole']
        ),
        ...mapGetters('profile', ['getLegacyRoles']),
        ...mapGetters(
            'app', ['badgeInbox','badgeForwardingCenter','badgeInboxGroup', 'globalEvent']
        ),
        headerSelectionActionsArray () {
            let arr = []
            for (let action in this.headerSelectionActions) {
                arr.push({action: action, items: this.headerSelectionActions[action].items})
            }

            return arr
        },
        selectAllActions (newVal, oldVal) {
            let act = []

            if (this.headerSelectIcon !==  'check_circle_outline') {
                console.log('folder', this.folder)
            }

            return act
        },

        folderLocal () {
            let folder = this.folder === 'Forwarding Center' ? 'INBOX': this.folder
            return folder
        },
        inbox_mode_local () {
            let inbox_mode = this.headerTabs === 'tab-forwarding' ? 'group': 'all'
            return inbox_mode
        },
        mailbox () {
            let mb = this.headerTabs.replace('tab-','')
            return mb
        },
        excludedFolders() {
            switch (this.folder) {
                case this.folderTypes['tab-inbox'].name:
                    return [this.folderTypes['tab-sent'].name, this.folderTypes['tab-trash'].name];

                case this.folderTypes['tab-sent'].name:
                    return null;

                case this.folderTypes['tab-trash'].name:
                    return null;

                default:
                    return null;
            }
        },        
        legacyRoleMap () {
            let map = {}
            let roles = this.getLegacyRoles
            console.log('roles', roles)
            if (roles && roles.length) {
                roles.map(r => {
                    map[r] = true
                })
            }
            return map
        },

        nurseView () {
            if (this.legacyRoleMap['provider']) {
                return false
            }
            if (this.legacyRoleMap['member']) {
                return false
            }
            if (this.legacyRoleMap['nurse']) {
                return true
            }

            return false

        },

        groupMessagesActionList () {
            let lists = ['INBOX','SENT','TRASH']

            let list = {}

            lists.map(l => {
                list[l] = []
                let actionList = this.groupMessagesActions[l]

                actionList.map(a => {
                    if (this.isAllowed(a)) {
                        list[l].push(a)
                    }
                })
            })

            return list
        },
        forwardingClass () {
            if (this.nurseView) {
                return 'hidden'
            }

            if (this.hasRole('messages.group')) {
                return 'text-none'
            }

            return 'hidden'
        },
        inboxClass () {
            if (this.nurseView) {
                return 'hidden'
            }

            return 'text-none'
        },
        inboxClassGroup () {

            if (this.nurseView) {
                return 'hidden'
            }

            if (this.hasRole('messages.group')) {
                return 'text-none'
            }

            return 'hidden'
        },
        trashClassGroup () {
            if (this.nurseView) {
                return 'hidden'
            }

            if (this.hasRole('messages.group')) {
                return ''
            }

            return 'hidden'
        },
        sentClassGroup () {
            if (this.nurseView) {
                return 'hidden'
            }

            if (this.hasRole('messages.group')) {
                return 'text-none'
            }

            return 'hidden'
        },

        forwardingCenter () {
            if (this.nurseView) {
                return false
            }

            if (!this.hasRole('messages.group')) {
                return false
            }

            return this.headerTabs === 'tab-forwarding' ? true : false
        },
        canForward () {
            if (this.forwardToProviders && this.forwardToProviders.length) {
                return true
            }
            return false
        },
        forwardedNames () {
            let names = ''
            if (this.forwardToProviders && this.forwardToProviders.length) {
                this.forwardToProviders.map(p => {
                    if (p.first_name || p.last_name) {
                        if (names) {
                            names += ', '
                        }
                        names += `${p.first_name} ${p.last_name}`
                    }
                })
            }
            return names ? names : 'N/A'
        },
        showTooltips () {
            if (this.headerSelectionActionsArray && this.headerSelectionActionsArray.length) {
                return false
            }
            return true
        }
    }
}

</script>

<style scoped>

.tab {
    color: white !important;
}

.tabs-holder {
    width: 270px;
    margin-bottom: -8px;
    margin-left: -4px;
}

.tabs--active {
    background-color: white;
    color: #167DD7 !important;
}

.message__section {
    height: 200px;
}

.list-item__row {
    width: 100%;
    padding-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.list-item__row--even {
    background-color: #F0F9FF;
}

.list-item__row--odd {

}

@media (max-width: 600px) {
    .list-container {
        font-size: 10px;
        padding: 0 0 8px 0;
    }
}

/* Small Breakpoint */
@media (min-width: 600px) {

}

/* Medium Breakpoint */
@media (min-width: 960px) {
    .list-item__column {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .label-value {
        padding-bottom: 0;
    }
}

/* Large Breakpoint */
@media (min-width: 1264px) {

}

/* XLarge Breakpoint */
@media (min-width: 1904px) {

}

</style>
