import * as enums from './enums';

export const groupMessagesActions = {
    [enums.INBOX]: [
        { name: 'Forward', icon: 'forward_to_inbox', function: 'forwardMessage' },
        { name: 'Resend', icon: 'reply', function: 'resendMessage' },
        { name: 'Trash', icon: 'delete', function: 'trashMessage' },
        { name: 'Confirm Message', icon: 'check_circle_outline', function: 'confirmMessage', condition: ({item}) => { return !item.date_confirmed } }
    ],
    [enums.SENT]: [
        { name: 'Forward', icon: 'forward_to_inbox', function: 'forwardMessage' },
        { name: 'Resend', icon: 'reply', function: 'resendMessage' }
    ],
    [enums.TRASH]: [
        { name: 'Forward', icon: 'forward_to_inbox', function: 'forwardMessage' },
        { name: 'Resend', icon: 'reply', function: 'resendMessage' },
        { name: 'Restore', icon: 'restore_page', function: 'restoreMessage' },
    ]
}

export const patientType = [
    {
        name: 'Established',
        value: 'established'
    },
    {
        name: 'New',
        value: 'new'
    }
]

export const ycmdProviderRoles = [
    {
        name: 'All',
        value: null
    },
    {
        name: 'Group',
        value: 'group'
    },
    {
        name: 'Member',
        value: 'member'
    },
    {
        name: 'Provider',
        value: 'provider'
    },
    {
        name: 'Admin',
        value: 'admin'
    },
    {
        name: 'Nurse',
        value: 'nurse'
    }
]

export const ycmdPatientStatus = [
    {
        name: 'Default',
        value: null
    },
    {
        name: 'Both',
        value: 'both'
    },
    {
        name: 'New',
        value: 'new'
    },
    {
        name: 'Established',
        value: 'established'
    },
    {
        name: 'None',
        value: 'none'
    },
    {
        name: 'Unknown',
        value: 'unknown'
    }
]

export const ycmdMessageConfirmedStatus = [
    {
        name: 'Default',
        value: null
    },
    {
        name: 'Confirmed',
        value: 'confirmed'
    },
    {
        name: 'Unconfirmed',
        value: 'unconfirmed'
    },
    {
        name: 'Escalated',
        value: 'escalated'
    }
]
