import gql from 'graphql-tag'

export default gql`query ycmdBadgeCount($badge_type: [BadgeType]) {
	ycmdBadgeCount(badge_type: $badge_type, client: "portal", message_user_mode: unlinked)
    {
		badge_type
    	count
		additional_data {
			name
			id
			value
		}
    }
}`
