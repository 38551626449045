<template>

  <div
    class="full-width full-height"  
  >
    <loading theme="dark" :center="true" v-if="pageState === 'loading'"/>


            <v-card
              width="100%"
              height="100%"
              color="ycmd-very-lightest-gray"
              v-else-if="pageState === 'success'"
            >              
                <v-card-title
                  class="py-0 my-0"
                >             
                {{successMessage}}                                 
                </v-card-title>
                <v-card-actions>

                <v-btn
                    color="ycmd-lightest-blue"
                    @click="complete"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>


    <v-container
      v-else
      fluid
      class="pa-0 ma-0 full-width full-height overflow-y-auto"
    >
      <v-row
        class="pa-0 ma-0"
        align="center"
        justify="center" 
      >
        <v-col
          cols="12"
          lg="6"
          md="8"
          sm="10"
          xs="12"
          class="py-5 full-height"
        >
          <v-form
            ref="messageForm"
            v-model="valid"
            @submit.prevent="sendMessage"
          >
            <v-card
              width="100%"
              height="100%"
              color="ycmd-very-lightest-gray"
              
            >              
                <v-card-text
                  class="py-0 my-0"
                >
              <v-row
              >
                <v-col 
                  class="shrink pt-0"
                >

                  <v-img
                    :src="`${providerData.avatar_url.value}-small`"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    max-height="200px"
                    height="200px"
                    max-width="200px"
                    v-if="providerData.avatar_url"
                  >

                  </v-img>
                </v-col>
                <v-col class="grow">                
                  <v-card-title class="text-h5 ml-n5"
                  >
                      {{providerName}}
                  </v-card-title>
                  <v-card-subtitle
                    class="ml-n5 pb-0 "
                    v-if="providerData.specialty"
                  >
                      {{providerData.specialty.value}}
                  </v-card-subtitle>

                  <v-row :class="`ma-0 pt-0 {{providerData.avatar_url ? 'pb-5' :'pb-3'`">
                    <v-col class="ma-0 pa-0">
                      <span class="fs-10 ml-n1">{{sendMessageText}}</span>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>

                    <v-select 
                      v-model="patient_status"
                      label="Are you a new or returning patient?"
                      :items="statuses"
                      item-text="name"
                      item-value="value"
                      :background-color="backgroundColor"
                      :style="`max-width: ${max_width};max-height: ${max_select_height};`"     
                      class="pl-0 pr-0 pb-0 pt-3 ma-0"                
                      />     

                    <v-text-field
                      v-model="first_name"
                      label="First name"
                      :rules="[validation_rules.required]"
                      :background-color="backgroundColor" 
                      :style="`max-width: ${max_width};max-height: ${max_height};`" 
                      class="pa-0 ma-0"          
                    >
                    </v-text-field>

                    <v-text-field
                      v-model="last_name"
                      label="Last name"
                      :rules="[validation_rules.required]"            
                      :background-color="backgroundColor" 
                      :style="`max-width: ${max_width};max-height: ${max_height};`"  
                      class="pa-0 ma-0"          
                    >
                    </v-text-field>

                    <div
                      :style="`max-width: ${max_width};max-height: ${max_height};`"  
                      class="mt-n5"
                    >
                    <date-picker
                      v-model="dob"
                      label="Date of Birth"
                      :includeIcon="false"
                      :backgroundColor="backgroundColor"
                      :rules="[validation_rules.required]"   
                      :key="`dob_${refresh}`"     
                      textClass="pa-0 ma-0"
                    />
                    </div>


                    <v-text-field
                      v-model="callback_number"
                      label="Please enter your callback number"
                      :rules="[validation_rules.required, validation_rules.phone]" 
                      v-mask="masks.phone"           
                      :background-color="backgroundColor" 
                      :style="`max-width: ${max_width};max-height: ${max_height};`"    
                      class="pa-0 ma-0 pt-2"                
                    >
                    </v-text-field>  

                    <v-text-field
                      v-model="email"
                      label="Please enter your email address"
                      :rules="[validation_rules.validateEmail]" 
                      :background-color="backgroundColor" 
                      :style="`max-width: ${max_width};max-height: ${max_height};`"    
                      class="pa-0 ma-0 pt-2"                
                    >
                    </v-text-field>  

                    <v-select 
                      v-model="insurance"
                      label="Will you be using Insurance?"
                      :items="insuranceChoices"
                      item-text="name"
                      item-value="value"
                      :background-color="backgroundColor"
                      :style="`max-width: ${max_width};max-height: ${max_select_height};`"     
                      class="pa-0 ma-0 pt-1"                
                      />                                          
<!--
                    <v-text-field
                      v-model="insurance"
                      label="Please enter your insurance"
                      :rules="[validation_rules.required]"            
                      :background-color="backgroundColor"  
                      :style="`max-width: ${max_width};max-height: ${max_height};`"   
                      class="pa-0 ma-0"                
                    >
                    </v-text-field>     
-->

                    <v-select 
                      v-model="messageType"
                      label="What is your message regarding?"
                      :rules="[validation_rules.required]"   
                      :items="messageChoices"
                      item-text="name"
                      item-value="value"
                      :background-color="backgroundColor"
                      :style="`max-width: ${max_width};max-height: ${max_select_height};`"     
                      class="pa-0 ma-0 mt-n2"                
                      />    

                    <v-textarea
                        counter
                        label="Enter your message"
                        v-model="message"
                        rows="3"
                        :maxlength="1000"
                        :background-color="backgroundColor"   
                        :error-messages="messageValidation"  
                        class="pa-0 ma-0"    
                        :disabled="messageType !== 'Other'"           
                    ></v-textarea>                                           
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="ycmd-lightest-blue"
                    :disabled="sending || !valid"
                    type="submit"
                >
                    Send
                </v-btn>
                <v-btn
                    @click="clear()"
                    text
                >
                    Cancel
                </v-btn>
                </v-card-actions>
            </v-card>
          </v-form>

        </v-col>
      
      </v-row>
    </v-container>

  </div> 

</template>

<script>
import DatePicker from '@/components/datetime/date-picker';
import dataClient from '@/graphql/clients/axios';
import Loading from '@/components/loading-elipsis';
export default {
  props: {
  
  },
  data: () => ({
    validation_rules: {
        required: value => !!value || 'Required.',
        //min: v => v.length >= 8 || 'Min 8 characters',
        phone: (number, other) => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(number) || 'Invalid number. Please use (###) ###-####';
        },
        validateEmail: (v, message) => {
          const emailRegex = /.+@.+\..+/
          if (!v) {
            return true
          }
          return emailRegex.test(v) || (message || 'Invalid Email address')
        }
    },
    masks: {
        phone: '(###) ###-####'
    },
    first_name: null,
    last_name: null,
    sending: false,
    valid: false,
    dob: null,
    insurance: null,
    callback_number: null,
    statuses: [
          {name: 'New Patient', value: 'new'},
          {name: 'Returning Patient', value: 'established'}
        ],
    insuranceChoices: [
          {name: 'Yes', value: 'insurance'},
          {name: 'No', value: 'cash'}
        ],
    messageChoices: [
          {name: 'Weight Loss', value: 'Weight Loss'},
          {name: 'Hormones', value: 'Hormones'},
          {name: 'Concierge', value: 'Concierge'},
          {name: 'Other', value: 'Other'},
        ],        
    patient_status: 'new',
    message: null,
    backgroundColor: 'ycmd-light-gray',
    providerDataArray: [],
    pageState: 'loading',
    sendResponseArray: [],
    refresh: 0,
    successMessage: null,
    messageType: null,
    email: null
  }),
  methods: {
    complete () {
      this.clearForm()
      this.pageState = 'message'
    },
    clearForm () {
      this.first_name = null
      this.last_name = null
      this.dob = null
      this.insurance = null
      this.callback_number = null
      this.patient_status = 'new'
      this.message = null
      this.refresh+=1
      this.messageType = null
      this.email = null
    },
    async sendMessage () {

      let parameters = []
      parameters.push({
        id: 'user_id',
        value: this.$route.params.id
      })

      parameters.push({
        id: 'first_name',
        value: this.first_name
      })
      parameters.push({
        id: 'last_name',
        value: this.last_name
      })
      parameters.push({
        id: 'dob',
        value: this.dob
      })
      parameters.push({
        id: 'insurance',
        value: this.insurance
      })
      parameters.push({
        id: 'callback_number',
        value: this.callback_number
      })
      parameters.push({
        id: 'patient_status',
        value: this.patient_status
      })
      parameters.push({
        id: 'email',
        value: this.email
      })

      let messageValue = this.messageType
      if (messageValue == 'Other') {
        messageValue = this.message
      }

      parameters.push({
        id: 'message',
        value: messageValue
      })

      this.sending = true

      let response = await dataClient('providerPageSet', {operation: 'provider_message', parameters: parameters})

      let success = false
      if (Array.isArray(response)) {
        this.sendResponseArray = response
        if (this.sendResponse.status && this.sendResponse.status.value === 'success') {
          success = true
        }
      }

      if (success) {
        let message = this.sendResponse.sent_message_response ? this.sendResponse.sent_message_response.value : null
        if (!message) {
          message = `Your message has been sent`
        }

        this.successMessage = message

        this.pageState = 'success'
        //this.$toasted.success(message);
        //this.clearForm()
      }
      else {
        this.$toasted.error(`An error occurred.  Please try again or call the office`);
      }

      this.sending = false
    }
  },
  computed: {
    messageValidation () {
      if (this.message) {
        return [] 
      } else {
        if (this.messageType && this.messageType !== 'Other') {
          return []
        }
      }
      return ['Required.']
    },
    providerData () {
      let ret = {}
      if (this.providerDataArray && this.providerDataArray.length) {
        this.providerDataArray.map(d => {
          ret[d.id] = d
        })
      }
      return ret
      
    },
    providerName () {
      let name = ''
      if (this.providerData.name && this.providerData.name.value) {
        name = this.providerData.name.value.trim()
        if (this.providerData.title) {
          name += `, ${this.providerData.title.value.trim()}`
        }
      }
      return name
    },
    sendResponse () {
      let ret = {}
      if (this.sendResponseArray && this.sendResponseArray.length) {
        this.sendResponseArray.map(d => {
          ret[d.id] = d
        })
      }
      return ret
    },
    max_height () {
      return '48px'
    },
    max_select_height () {
      return '60px'
    },

    max_width () {
      let mw = '500px'

      switch (this.$vuetify.breakpoint.name) {
        case 'lg':
        case 'xl':
        case 'md':
           
          mw = '300px'
          break
      }

      return mw
    } ,
    sendMessageText () {
      let t = 'Send a message to the office'
      console.log('this.providerData', this.providerData)
      if (this.providerData.send_message_text) {
        t = this.providerData.send_message_text.value
      }
      return t
    }
  },
  components: {
    'date-picker': DatePicker,
    loading: Loading
  },
  async mounted () {
    let parameters = []
    parameters.push({
      id: 'user_id',
      value: this.$route.params.id
    })
    let response = await dataClient('providerPageGet', {operation: 'get_provider', parameters: parameters})

    let hasError = false
    if (Array.isArray(response) ) {
      this.providerDataArray = response
      if (!this.providerData.name) {
        hasError = true
      }
    } else {
      hasError = true
    }

    if (hasError) {
      this.$router.push({ name: '404NoOptions'})
    } else {
      this.pageState = 'complete'
    }
    
  }
}
</script>
