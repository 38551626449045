import gql from 'graphql-tag'

export default gql`mutation ycmdChatRoomEdit (
    $room_id: ID!, 
    $name: String, 
    $meta: [NameValueInput], 
    $tags: [String]) {
    ycmdChatRoomEdit(
      room_id: $room_id, 
      name: $name, 
      meta: $meta, 
      tags: $tags) {
    id
    created_by_id
    date_created
    date_updated
    last_post_id
    last_post_id
    unread_count
    name
    room_type
    participants {
        date_joined
        date_left
        date_last_seen
        last_post_seen
        user_id
        first_name
        last_name
        creator
        title
        specialty
        group_id
        group_name
        online_status
        status
        ooo
        app_version
    }
    meta {
        name
        value
    }
    tags
	}
}`
