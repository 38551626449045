<template>

    <div class="view full-height">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">

                <section-title :title="`Send Group Bulletin`" titleStyle="color: #FFFFFF !important;"/>

                <section-content
                    sectionClass="full-height"
                >

                    <template v-slot:section-content>
                        <v-form
                                ref="bulletinValidation"
                                v-model="validBulletin"
                                class="full-width full-height">

                            <div class="full-width full-height">
                                <v-row class="pa-0 ma-0">
                                    <v-col class="shrink pa-0 ma-0">
                                        <v-checkbox 
                                            label="Providers"
                                            v-model="allProviders"
                                            style="min-width: 100px;"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col class="shrink pa-0 ma-0 pl-5">
                                        <v-checkbox 
                                            label="Members"
                                            v-model="allMembers"
                                            style="min-width: 100px;"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>                    
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <div class="label-title">Group Users</div>
                                        <user-picker
                                            v-model="sendToUsers"
                                            label="Select Users"
                                            :roles="selectedRoles"
                                            :include_searching_user="true" 
                                            :exclude_group_filter="false"
                                            :group_ids=[groupId]
                                            :rules="[rules.arrayRequired]"
                                            :include_select_all="true"
                                        /> 
                                    </v-col>
                                </v-row>        
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <div class="label-title">Notification Options</div>
                                        <v-select light dense flat outlined
                                                    :items="notificationOptions"
                                                    v-model="notification"
                                                    item-text="name"
                                                    item-value="val"
                                                    label="Select Notification Option"
                                                    :rules="[rules.required]"
                                                    background-color="white"
                                                    style="max-width: 400px;"                                                    
                                                    />
                                    </v-col>
                                </v-row>                                   
                                <v-row>
                                    <v-col cols="12">
                                        <div class="label-title">Title</div>
                                        <v-text-field
                                                light dense flat outlined
                                                class="small-input"
                                                style="max-width: 400px;"
                                                v-model="title"
                                                label="Enter Title"
                                                :rules="[rules.required]"
                                                background-color="white" />

                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                                light dense flat outlined
                                                class="small-input"
                                                style="max-width: 400px;"
                                                auto-grow
                                                v-model="message"
                                                label="Message"
                                                :rules="[rules.required]"
                                                background-color="white" />        

                                    </v-col>
                                </v-row>    
                                <v-row>
                                    <v-col cols="12">

                                                <v-btn light small
                                                    @click="sendBulletin"
                                                    class="primary"
                                                    :disabled="!validBulletin || sending"
                                                    >
                                                    Send Bulletin
                                                    <v-icon color="white" :size="18" class="pl-2">send</v-icon>
                                                </v-btn>

                                    </v-col>
                                </v-row>    

                            </div>

                        </v-form>

                    </template>

                </section-content>

            </div>

            <template slot="right-panel">

                <div>Hello right panel</div>

            </template>

            <template slot="bottom-panel">

                <div>Hello Bottom Panel</div>

            </template>

        </i-card>

    </div>

</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    import userPicker from '@/components/searches/user-picker';
    import dataClient from '@/graphql/clients/axios';
    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    export default {
        name: 'bulletin-portal',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent,
            'user-picker': userPicker,
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false },
                },

                rules: {
                    required: value => !!value || 'Required.',
                    arrayRequired: value => !value || !value.length ? 'Required.': true,
                },                
                sendToUsers: null,
                title: null,
                message: null,
                allProviders: false,
                allMembers: false,
                validBulletin: false,
                notification: 'user_default',
                notificationOptions: [
                  {
                    name: 'Do Not Notify',
                    val: 'do_not_notify'
                  },
                  {
                    name: 'Notify Always',
                    val: 'notify_always'
                  },
                  {
                    name: 'User Default',
                    val: 'user_default'
                  }
                ],
                sending: false
            }
        },
        created() {},
        async mounted() {
        },

        activated() {},
        watch: {
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),

            async sendBulletin () {

                let isValid = this.$refs.bulletinValidation.validate()
                if (isValid) {
                    let params = {
                        message: this.message,   
                        title: this.title,
                        notification: this.notification,
                        subtype: 'group',
                        account_type: 'ycmd',
                        user_ids: this.sendToUsers
                    }

                    let roles = []

                    if (this.allMembers) {
                        roles.push('member')
                    }
                    if (this.allProviders) {
                        roles.push('provider')
                    }

                    if (roles.length) {
                        params.roles = roles
                    }
                    this.sending = true
                    let response = await dataClient('phxBulletinSendSystem', params)
                    this.sending = false

                    if (response && response.id) {
                        this.$toasted.success(`Successfully Sent Bulletin`);
                        this.title = ''
                        this.message = ''
                        this.sendToUsers = []
                    } else {
                        this.$toasted.error(`An error has occurred`);
                        
                    }

                }
            },

        },
        computed: {
            ...mapGetters('profile', ['getGroup']),
            selectedRoles () {
                let roles = []
                if (this.allProviders) {
                    roles.push('provider')
                }
                if (this.allMembers) {
                    roles.push('member')
                }

                if (roles.length) {
                    return roles
                }

                return ['provider','member']

            },
            groupId () {

                if (this.getGroup && this.getGroup.id) {
                    return this.getGroup.id
                }

                return '0'
            },
            isUsersValid () {
                if (this.allMembers) {
                    return true
                }
                if (this.allProviders) {
                    return true
                }
                if (this.sendToUsers && this.sendToUsers.length) {
                    return true
                }

                return false

            }
        }
    }

</script>

<style scoped>

</style>
