<template>

  <div class="list-container full-height white pa-0" style="margin-right: 2px">
    <template v-if="loading">
      <loading theme="light" :center="true" />
    </template>
    <v-container fluid
                  v-else
                 v-for="(item, i) in items"
                 :key="`user_${i}`"
                 :class="`list-item ${messageColor(item)} flex-shrink-0 pa-0 relative`">

      <div class="d-flex flex-column full-width" :id="`${item.id}`">

        <div class="d-flex align-center pa-4">
          <v-row style="border-bottom: solid #C3D7E8 2px;">
            <v-col cols="12" md="4" :class="$vuetify.breakpoint.xsOnly ? 'pr-0' : ''">
              <div class="d-flex align-center">

                  <v-tooltip bottom
                             nudge-bottom="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-2 pb-2">
                      <template v-slot:activator="{ on }">
                        <v-icon :color="messageTypeColor(item)" dark v-on="on" class="fs-24 pb-1 pr-2">{{messageTypeIcon(item)}}</v-icon>
                      </template>
                      <span>{{messageTypeDescription(item)}}</span>
                  </v-tooltip>


                <div v-if="item.message_type === 'bulletin'" class="fs-18 fw-500 ycmd-medium-blue--text uppercase">

                    {{bulletinTitle(item)}}

                </div>

                <div v-else class="fs-18 fw-500 ycmd-medium-blue--text uppercase">
                  {{
                    (item.patient && (item.patient.first_name || item.patient.last_name)) ? `${item.patient.first_name} ${item.patient.last_name}` : 'Patient Name N/A'
                  }}
                </div>
                <div v-if="item.patient && item.patient.dob" class="fs-12 fw-500 pl-3">
                  ({{ formatDOB({date: item.patient.dob})}})
                </div>
                <template v-if="$vuetify.breakpoint.smAndDown">
                  <v-spacer />
                  <v-tooltip v-if="item.message_type != 'bulletin'" 
                            bottom
                             nudge-bottom="5"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon dark depressed
                             @click="() => { changeView({ view: 'details-view', params: { data: item } }); }"
                             v-on="on"
                             :disabled="item.message_type === 'bulletin'"
                             class="ml-1 mr-1 ycmd-light-blue">
                        <v-icon :size="14">arrow_forward</v-icon>
                      </v-btn>
                    </template>
                    <span>Details</span>
                  </v-tooltip>
                </template>
              </div>
            </v-col>
            <v-col cols="12" md="5" :class="$vuetify.breakpoint.xsOnly ? 'pr-0' : ''">
              <div class="d-flex align-center full-height fs-12 fw-400" v-if="showField(item,'facility')">
                {{ `${item.facility_name && item.facility_name ? item.facility_name : 'Facility Name N/A'}` }}
                <template v-if="$vuetify.breakpoint.smAndDown">
                  <v-spacer />
                  <div class="d-flex align-center justify-end full-height">
<!-- Select is not going through the action list nonsense because it is not reactive and is needlessly complex -->

                    <v-tooltip 
                                bottom
                                nudge-bottom="5"
                                color="ycmd-black"
                                content-class="fade-0">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon light depressed
                                @click="doSelect(item)"
                                v-on="on"
                                color="ycmd-blue"
                                class="mr-1">
                          <v-icon :size="22">{{item.selected ? 'check_circle_outline': 'radio_button_unchecked'}}</v-icon>
                        </v-btn>
                      </template>
                      <span>Select</span>
                    </v-tooltip>

                    <v-tooltip v-if="action.condition ? action.condition({ item }) : true"
                              :key="`item_id_${ i }`"
                                bottom
                                nudge-bottom="5"
                                :disabled="$vuetify.breakpoint.xsOnly"
                                color="ycmd-black"
                                content-class="fade-0">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon light depressed
                                @click="doAction(action, item)"
                                :loading="processing && selectedAction === action.name && selectedId === item.id"
                                :disabled="processing && selectedAction !== action.name && selectedId === item.id"
                                v-on="on"
                                color="ycmd-blue"
                                class="mr-1">
                          <v-icon :size="22">{{ action.iconCondition ? action.iconCondition() : action.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ action.name }}</span>
                    </v-tooltip>



                    <template v-for="(action, i) in actionList">
                      <template v-if="(action.name !== 'Trash')">
                        <template v-if="action.name !== 'Confirm Message'">
                          <v-tooltip v-if="action.condition ? action.condition({ item }) : true"
                                    :key="`item_id_${ i }`"
                                     bottom
                                     nudge-bottom="5"
                                     :disabled="$vuetify.breakpoint.xsOnly"
                                     color="ycmd-black"
                                     content-class="fade-0">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon light depressed
                                     @click="doAction(action, item)"
                                     :loading="processing && selectedAction === action.name && selectedId === item.id"
                                     :disabled="processing && selectedAction !== action.name && selectedId === item.id"
                                     v-on="on"
                                     color="ycmd-blue"
                                     class="mr-1">
                                <v-icon :size="22">{{ action.iconCondition ? action.iconCondition() : action.icon }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ action.name }}</span>
                          </v-tooltip>
                        </template>
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="3">
              <div class="d-flex align-center justify-end full-height">

<!-- Select is not going through the action list nonsense because it is not reactive and is needlessly complex -->

                <v-tooltip 
                            bottom
                            nudge-bottom="5"
                            color="ycmd-black"
                            content-class="fade-0">
                  <template v-slot:activator="{ on }">
                    <v-btn small icon light depressed
                            @click="doSelect(item)"
                            v-on="on"
                            color="ycmd-blue"
                            class="mr-1">
                      <v-icon :size="22">{{item.selected ? 'check_circle_outline': 'radio_button_unchecked'}}</v-icon>
                    </v-btn>
                  </template>
                  <span>Select</span>
                </v-tooltip>

                <template v-for="(action, i) in actionList">
                  <template v-if="(action.name !== 'Trash')">
                    <template v-if="action.name !== 'Confirm Message'">
                      <v-tooltip v-if="action.condition ? action.condition({ item }) : true"
                                :key="`item_id_${ i }`"
                                 bottom
                                 nudge-bottom="5"
                                 :disabled="$vuetify.breakpoint.xsOnly"
                                 color="ycmd-black"
                                 content-class="fade-0">
                        <template v-slot:activator="{ on }">
                          <v-btn small icon light depressed
                                 @click="doAction(action, item)"
                                 :loading="processing && selectedAction === action.name && selectedId === item.id"
                                 :disabled="processing && selectedAction !== action.name && selectedId === item.id"
                                 v-on="on"
                                 color="ycmd-blue"
                                 class="mr-4">
                            <v-icon :size="22">{{ action.iconCondition ? action.iconCondition({item}) : action.icon }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ action.name }}</span>
                      </v-tooltip>
                    </template>
                  </template>
                </template>
                <v-tooltip v-if="item.message_type != 'bulletin'"
                          bottom
                           nudge-bottom="5"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                  <template v-slot:activator="{ on }">
                    <v-btn small icon dark depressed
                           @click="() => { changeView({ view: 'details-view', params: { data: item } }); }"
                           v-on="on"
                          :disabled="item.message_type === 'bulletin'"
                           class="ml-2 mr-2 ycmd-light-blue">
                      <v-icon :size="14">arrow_forward</v-icon>
                    </v-btn>
                  </template>
                  <span>Details</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="pa-4">
          <v-row>
            <v-col cols="12" md="4">
              <div class="d-flex flex-column">
                <div v-if="$vuetify.breakpoint.mdAndUp && showField(item,'from_user')" class="label-title pb-3">FROM</div>
                <div class="fs-12 fw-500 ycmd-medium-blue--text pb-3" v-if="showField(item,'from_user')">
                  {{ getFromUser(item.from_user, item.guest_name) }}
                </div>
                <div class="label-value pb-3"  v-if="showField(item,'callback')">
                  {{ item.callback ? formatPhoneNumber(item.callback) : 'N/A' }}
                </div>
              </div>
            </v-col>
<!--            <v-col cols="12" sm="3">-->
<!--              <div class="d-flex align-start">-->
<!--                <v-icon small color="ycmd-blue" class="pr-4">arrow_forward</v-icon>-->
<!--                <div class="d-flex flex-column full-height">-->
<!--                  <template v-if="item.guest_name && item.guest_name !== 'null'">-->
<!--                    <div class="label-value pb-3">ON BEHALF OF</div>-->
<!--                    <div class="label-title pb-3">-->
<!--                      {{ item.guest_name }}-->
<!--                    </div>-->
<!--                  </template>-->
<!--&lt;!&ndash;                  <div class="label-title pb-3">ORIGINALLY TO</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="label-value pb-3">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{&ndash;&gt;-->
<!--&lt;!&ndash;                      `${item.orig_user && (item.orig_user.first_name || item.orig_user.last_name) ? `${item.orig_user.first_name || ''} ${item.orig_user.last_name || ''}` : 'N/A'}`&ndash;&gt;-->
<!--&lt;!&ndash;                    }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-col>-->
            <v-col cols="12" md="4">
              <div class="d-flex align-start">
                <v-icon v-if="$vuetify.breakpoint.mdAndUp" small color="ycmd-blue" class="pr-4">arrow_forward</v-icon>
                <div class="d-flex flex-column full-height">
                  <div class="label-title pb-3">SENT TO</div>
                  <div class="fs-12 fw-500 ycmd-medium-blue--text pb-3">
                    {{
                      `${item.to_user && (item.to_user.first_name || item.to_user.last_name) ? `${item.to_user.first_name || ''} ${item.to_user.last_name || ''}` : 'N/A'}`
                    }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="d-flex flex-column full-height">
                <div class="d-flex align-center pb-3">
                  <div class="label-title" style="width: 88px">SENT</div>
                  <div class="d-flex align-center justify-end label-value full-width">
                    {{ formatDate({date: item.date_created, isUTC: false }) }}
                  </div>
                </div>
                <div class="d-flex align-center pb-3" v-if="showField(item,'last_sent')">
                  <div class="label-title" style="width: 88px">LAST SENT</div>
                  <div class="d-flex justify-end label-value full-width">
                    {{ formatDate({date: item.last_sent, isUTC: false }) }}
                  </div>
                </div>
                <div class="d-flex full-height pb-3">
                  <div class="d-flex align-center full-height label-title" style="width: 88px">CONFIRMED</div>
                  <div class="d-flex align-center justify-end  full-width">
                    <template v-if="item.date_confirmed">
                      <div class="label-value">
                        {{ formatDate({date: item.date_confirmed, isUTC: false }) }}
                      </div>
                    </template>
                    <template v-else v-for="(action, i) in actionList">
                      <template v-if="action.name === 'Confirm Message'">
                        <v-btn dark x-small text
                              :key="`item_id_${ i }`"
                               class="red"
                               @click="doAction(action, item)"
                               :loading="processing && selectedAction === action.name && selectedId === item.id"
                               :disabled="processing && selectedAction !== action.name && selectedId === item.id">
                          Confirm
                        </v-btn>
                      </template>
                    </template>
                  </div>
                </div>

                <div class="d-flex full-height pb-3" v-if="showField(item,'patient_status')">
                  <div class="d-flex align-center full-height label-title" style="width: 88px">PATIENT STATUS</div>
                  <div class="d-flex align-center justify-end  full-width">
                    <div class="label-value">
                      {{ item.patient_status === 'established' ? 'Established' : 'New' }}
                    </div>
                  </div>
                </div>

                <div class="d-flex full-height pb-3" v-if="showField(item,'bypass_coverage')">
                  <div class="d-flex align-center full-height label-title" style="width: 88px">BYPASS</div>
                  <div class="d-flex align-center justify-end  full-width">
                    <div class="label-value">
                      {{ item.bypass_coverage ? 'True' : 'False' }}
                    </div>
                  </div>
                </div>



<!--                <div class="d-flex align-center">-->
<!--                  <div class="label-title" style="width: 88px">RESPONSE</div>-->
<!--                  <div class="d-flex align-center justify-end label-value full-width">-->
<!--                    {{ item.response && item.response != 0 ? item.response : 'No Response' }}-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </v-col>
            <v-col cols="12" :class="`pt-0 ${ item.response && item.response !== '0' ? 'pb-1' : 'pb-3'  }`">
              <template v-if="$vuetify.breakpoint.smAndDown">
                <div class="d-flex flex-column">
                  <div class="label-title pb-4">Message</div>
                  <div class="ycmd-light-gray fw-400 pa-4 br-2 flex-grow-1">
                    {{ `${item.contents ? item.contents : 'Message N/A'}` }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="d-flex">
                  <div class="label-title pt-4" style="min-width: 88px">Message</div>
                  <div class="ycmd-light-gray fw-400 pa-4 br-2 flex-grow-1">
                    {{ `${item.contents ? item.contents : 'Message N/A'}` }}
                  </div>
                </div>
              </template>
            </v-col>
            <template v-if="item.response && item.response !== '0'">
              <v-col cols="12" class="pb-3">
                <div class="d-flex flex-column">
                  <div class="label-title pb-4">Response</div>
                  <div class="ycmd-lightest-blue fw-400 pa-4 br-2 flex-grow-1">
                    {{ item.response }}
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>
        </div>

        <div class="full-width ycmd-blue" style="height: 2px"></div>
      </div>

    </v-container>

<!-- use the same html as data table for no messages so it looks the same -->
    <div 
      v-if="items.length <= 0"
    class="v-data-table flex-table v-data-table--dense v-data-table--fixed-header theme--light" width="100%">
      <div class="v-data-table__wrapper">
        <table>
          
          <tbody>
            <tr class="v-data-table__empty-wrapper">
              <td colspan="6">No messages</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>



    <i-audio-player :audioPath="audioPath" :title="recordingNumber" :subtitle="dateCreated"/>

  </div>

</template>

<script>


import {formatDate, formatDOB} from '@/methods/formatters';
import {truncateText} from '@/methods/utils';
import {formatPhoneNumber} from '@/methods/globalMethods';
import * as messagesEnums from '@/data/communications/enums';

import Loading from '@/components/loading-elipsis';

import GET_RECORDING_URL from '@/graphql/queries/phoenix/ycmdRecordingUrlGet';
import {mapActions, mapState} from "vuex";

export default {
  name: 'messagesListContentForm',
  props: {
    messageType: String,
    actionList: Array,
    //resendMessage: Function,
    //trashMessage: Function,
    //restoreMessage: Function,
    //forwardMessage: Function,
    //confirmMessage: Function,
    //selectMessage: Function,
    //showDetail: Function,
    changeView: Function,
    items: Array,
    //infiniteHandler: Function,
    //infiniteId: Number,
    //refreshData: Function,
    //groupID: String,
    view: String,
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    loading: Loading
  },
  apollo: {
    $client: 'phoenixClient'
  },
  data: () => ({
    messagesEnums,
    truncateText,
    formatDate,
    formatDOB,
    formatPhoneNumber,
    recordingNumber: '',
    dateCreated: '',
    audioPath: '',
    selectedAction: null,
    selectedId: null,
    processing: false
  }),
  mounted() {},
  methods: {
    messageTypeColor(item) {
      return (item.message_type === 'bulletin') ? 'ycmd-light-red' : 'ycmd-light-blue'
    },  
    messageTypeDescription(item) {
      if (item.message_type === 'bulletin') {
        switch (item.message_sub_type) {

            case 'system.software_update':
              return 'Software Update'
            case 'system.system_maintenance':
              return 'System Maintenance'                
            case 'system.system_message':
              return 'System Message'
            case 'patients_assigned_to_you':
              return 'Patient Assigned to You'
            case 'patient_transferred_to_you':
              return 'Patient Transferred to You'      
            case 'system.group':
              return 'Group Message'
        }

      } else {
        switch (item.message_sub_type) {

          case 'agent':
            return 'Agent Message'
          case 'provider':
            return 'Provider Message'
          case 'facility':
            return 'Facility Message'
          case 'admin':
            return 'Admin Message'
        }
      }
      return 'Unknown'
    },
    messageTypeIcon(item) {
      if (item.message_type === 'bulletin') {

        switch (item.message_sub_type) {

            case 'system.software_update':
              return 'save'
            case 'system.system_maintenance':
              return 'construction'                
            case 'system.system_message':
              return 'message'
            case 'patients_assigned_to_you':
              return 'directions_walk'
            case 'patient_transferred_to_you':
              return 'transfer_within_a_station'   
            case 'system.group':
              return 'groups'                  
        }

      } else {
        switch (item.message_sub_type) {

          case 'agent':
            return 'support_agent'
          case 'provider':
            return 'assignment_ind'
          case 'facility':
            return 'local_hospital'
          case 'admin':
            return 'business'
        }
      }
      return 'question_mark'
    },
    showField(item, field) {
      let show = true
      switch (item.message_type) {
        case 'bulletin':

          switch (field) {
            case 'from_user':

              if (item.message_sub_type === 'system.group'){
                return item.from_user && item.from_user.id
              } else if (item.message_sub_type.startsWith('system')){
                  show = false
              } 
              break            
            case 'callback':
            case 'facility':
              if (item.message_sub_type.startsWith('system')){
                  show = false
              }
              break

            case 'bypass_coverage':
            case 'last_sent':
            case 'patient_status':
              show = false
              break
          }
          break
      }

      return show

    },
    bulletinTitle(item) {
      let title=''

      if (item.message_type === 'bulletin') {
        switch (item.message_sub_type) {
          case 'system.software_update':
            title = 'System Bulletin - Software Update'
            break  
          case 'system.system_maintenance':                
            title = 'System Bulletin - System Maintenance'
            break  
          case 'system.system_message':
            title = 'System Bulletin - System Message'
            break  
          case 'system.group':
            title = 'Group Bulletin'
            break  
          case 'patients_assigned_to_you':
            title = `Patient Assigned Bulletin (${item.patient.first_name} ${item.patient.last_name})`
            break
          case 'patient_transferred_to_you':
            title = `Patient Transferred Bulletin (${item.patient.first_name} ${item.patient.last_name})`
            break
          default:
            title = 'System Bulletin'
            break  

        }
      }
      return title
    },   
    doSelect(item) {
      //this.$set(item, 'selected', !item.selected)
      this.$emit('onselected', item, !item.selected)
    },
    async doAction(action, item) {
      // console.log('action: ', action);
      // console.log('item: ', item);

      this.processing = true;
      this.selectedAction = action.name;
      this.selectedId = item.id;

      //await this[action.function](item);

      let params = {
        action, 
        item, 
        completeCallback: this.actionComplete
      }

      this.$emit('doAction', params)

    },
    actionComplete(action, item, result) {

      this.processing = false;
      this.selectedAction = null;
      this.selectedId = null;
    },
    async openAudioPlayerClick(item, recording, index) {
      this.$set(item, 'isItemLoadingAudio', true);
      console.log('recording: ', recording);
      try {
        const response = await this.$apollo.query({
          query: GET_RECORDING_URL,
          variables: {
            recording_id: recording
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });
        item.isItemLoadingAudio = false;

        console.log('response: ', response);

        const {ycmdRecordingUrlGet} = response.data;
        if (ycmdRecordingUrlGet) {
          this.recordingNumber = `Recording #${index + 1}`;
          this.audioPath = ycmdRecordingUrlGet;
        } else {

        }
      } catch (e) {
        item.isItemLoadingAudio = false;
        // defaultErrorHandler(e);
      }
    },
    getFromUser(user, guest_name) {
      if (guest_name)
        return guest_name
      else
        return user && (user.first_name || user.last_name) ? `${user.first_name || ''} ${ user.last_name || ''}` : 'N/A';
    },
    messageColor (item) {
      return this.view === 'Forwarding Center' && !item.date_confirmed ? 'ycmd-very-lightest-blue' : 'white'
    }
  },
  computed: {
    ...mapState('profile', ['info', 'roles'])
  }
}
</script>
