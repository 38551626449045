import gql from 'graphql-tag'

export default gql`
  subscription onUserActivity($user_id: ID){
    onUserActivity(user_id: $user_id) {
      user_id
      activity {
        type
        activity_date
        activity_date_end
        user_id
        job_id
        error_message
        report {
          report_name
          report_id
          properties {
            name
            id
            value
            formatted
          }
          url
          columns {
            label
            field
            type
            width
          }
          lines
          format
        }
      }
    }
  }`
