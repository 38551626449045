import gql from 'graphql-tag'

export default gql`mutation ycmdMobileNumberVerify(
	$verify_code: String, 
	$send_code: Boolean) {
	ycmdMobileNumberVerify(
		verify_code: $verify_code, 
		send_code: $send_code) {
		status
	}
}`
