<template>

    <v-layout column align-center justify-center class="absolute full-width full-height blue">
        <div class="fs-32 white--text pb-4" >Details</div>
        <v-btn dark depressed color="ycmd-black" @click="changeView('list-view')">Back</v-btn>

        <!--                    <i-card :viewIndex="card.index"-->
        <!--                            :viewCount="1"-->
        <!--                            :leftPanel="card.panels.left"-->
        <!--                            :rightPanel="card.panels.right"-->
        <!--                            :topPanel="card.panels.top"-->
        <!--                            :bottomPanel="card.panels.bottom"-->
        <!--                            class="full-height elevation-0 white"-->
        <!--                            style="border-radius: inherit; width: 100%">-->

        <!--                        <v-layout fill-height>-->

        <!--                            <v-layout column class="full-width full-height">-->

        <!--                                <v-toolbar flat class="transparent">-->

        <!--                                    <v-btn flat icon @click="slide('left')">-->
        <!--                                        <v-icon>search</v-icon>-->
        <!--                                    </v-btn>-->

        <!--                                    <v-toolbar-title>Active Jobs</v-toolbar-title>-->

        <!--                                    <v-spacer/>-->

        <!--                                    <v-btn flat icon @click="setFullscreen(!fullscreen)">-->
        <!--                                        <v-icon>view_module</v-icon>-->
        <!--                                    </v-btn>-->

        <!--                                    <v-btn flat icon @click="slide('right')">-->
        <!--                                        <v-icon>bar_chart</v-icon>-->
        <!--                                    </v-btn>-->

        <!--                                </v-toolbar>-->


        <!--                            </v-layout>-->

        <!--                        </v-layout>-->

        <!--                        <template slot="left-panel">-->

        <!--                            <v-layout column class="ma-0 full-height green">-->

        <!--                                <v-toolbar dark flat color="accent accentText&#45;&#45;text" class="pa-2">-->

        <!--                                    <v-toolbar-title class="fw-400 fs-20">-->

        <!--                                        Left Panel-->

        <!--                                    </v-toolbar-title>-->

        <!--                                    <v-spacer />-->

        <!--                                    <v-btn icon @click="card.panels.left = false">-->
        <!--                                        <v-icon class="accent accentText&#45;&#45;text">close</v-icon>-->
        <!--                                    </v-btn>-->

        <!--                                </v-toolbar>-->
        <!--                            </v-layout>-->

        <!--                        </template>-->

        <!--                        <template slot="right-panel">-->

        <!--                            <v-layout column class="ma-0 full-height green">-->

        <!--                                <v-toolbar dark flat color="accent accentText&#45;&#45;text" class="pa-2">-->

        <!--                                    <v-toolbar-title class="fw-400 fs-20">-->

        <!--                                        Right Panel-->

        <!--                                    </v-toolbar-title>-->

        <!--                                    <v-spacer></v-spacer>-->

        <!--                                    <v-btn icon @click="card.panels.right = false">-->
        <!--                                        <v-icon class="accent accentText&#45;&#45;text">close</v-icon>-->
        <!--                                    </v-btn>-->

        <!--                                </v-toolbar>-->
        <!--                            </v-layout>-->

        <!--                        </template>-->

        <!--                        <template slot="top-panel">-->

        <!--                            <v-layout column class="ma-0 full-height green">-->

        <!--                                <v-toolbar dark flat color="accent accentText&#45;&#45;text" class="pa-2">-->

        <!--                                    <v-toolbar-title class="fw-400 fs-20">-->

        <!--                                        Top Panel-->

        <!--                                    </v-toolbar-title>-->

        <!--                                    <v-spacer></v-spacer>-->

        <!--                                    <v-btn icon @click="card.panels.top = false">-->
        <!--                                        <v-icon class="accent accentText&#45;&#45;text">close</v-icon>-->
        <!--                                    </v-btn>-->

        <!--                                </v-toolbar>-->
        <!--                            </v-layout>-->

        <!--                        </template>-->

        <!--                        <template slot="bottom-panel">-->

        <!--                            <v-layout column class="ma-0 full-height green">-->

        <!--                                <v-toolbar dark flat color="accent accentText&#45;&#45;text" class="pa-2">-->

        <!--                                    <v-toolbar-title class="fw-400 fs-20">-->

        <!--                                        Bottom Panel-->

        <!--                                    </v-toolbar-title>-->

        <!--                                    <v-spacer></v-spacer>-->

        <!--                                    <v-btn icon @click="card.panels.bottom = false">-->
        <!--                                        <v-icon class="accent accentText&#45;&#45;text">close</v-icon>-->
        <!--                                    </v-btn>-->

        <!--                                </v-toolbar>-->
        <!--                            </v-layout>-->

        <!--                        </template>-->

        <!--                    </i-card>-->

    </v-layout>

</template>

<script>

    export default {
        name: 'sampleDetails',
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                searchValue: '',
            }
        },
        mounted() {

        },
        methods: {
            changeView(view) {

                this.$emit('onChangeView', view);
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        }
    }

</script>
