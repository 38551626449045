<template>
  <div>

    <v-select light dense flat outlined
        v-model="datetypeLocal"
        :items="dateTypes"
        hide-details
        item-text="name"
        item-value="id"
        background-color="white"
        return-object
        class="pb-5"
        :rules="rules"
        />
    <date-picker
      v-model="startDate"
      label="Start Date"
      :includeIcon="includeCustomIcon"
      v-if="datetypeLocal && datetypeLocal.id ==='customrange'"
    />
    <time-picker
      v-model="startTime"
      label="Start Time"
      v-if="datetypeLocal && datetypeLocal.id ==='customrange' && selectTime"
      />
    <date-picker
      v-model="endDate"
      label="End Date"
      :includeIcon="includeCustomIcon"
      v-if="datetypeLocal && datetypeLocal.id ==='customrange'"
    />
    <time-picker
      v-model="endTime"
      label="End Time"  
      v-if="datetypeLocal && datetypeLocal.id ==='customrange' && selectTime"
    />

  </div>
</template>

<script>
import timePicker from './timepicker'
import { format, startOfDay, endOfDay, startOfMonth, endOfMonth, startOfWeek, endOfWeek, subDays, add } from 'date-fns';
import DatePicker from './date-picker'

export default {
    name: 'datePicker',
    components: {
      'date-picker': DatePicker,
      'time-picker': timePicker
    },
    props: {
      label: {
          type: String,
          default: 'Date'
      },
      value: {
        type: String,
        default: null
      },
      includeCustomIcon: {
        type: Boolean,
        default: true
      },
      includeTime: {
        type: Boolean,
        default: true
      },
      selectTime: {
        type: Boolean,
        default: true
      },
      required: {
        type: Boolean,
        default: true
      },     
      rules: {
        type: Array,
        default: () => []
      }   
    },
    data: () => ({
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        datetypeLocal: null
    }),
    mounted() {
      let search = this.value && this.value.indexOf('|') >= 0 ? 'customrange' : this.value

      this.datetypeLocal = this.dateTypes.find(d => {
        return d.id == search
      })

      if (this.datetypeLocal && this.datetypeLocal.id === 'customrange' && this.value) {
        let dates = this.value.split('|')
        
        for (let i = 0;i<dates.length;i++) {
          let date = dates[i]
          let pieces = date.split('T')
          if (i === 0) {
            this.startDate = pieces[0]
          } else {
            this.endDate = pieces[0]
          }

          if (pieces.length === 2) {
            if (i === 0) {
              this.startTime = pieces[1]
            } else {
              this.endTime = pieces[1]
            }
          }

        }

      }
    },
    methods: {
    },
    computed: {
      dateTypes () {
        let arr = []
        if (!this.required) {
          arr.push(
            {
                name: 'Display All',
                id: null,
                start_date: null,
                end_date: null
            }
          )
        }
        arr.push(
            {
                name: 'Last Week',
                id: 'lastweek',
                start_date: startOfWeek(subDays(new Date(), 7)),
                end_date: endOfWeek(subDays(new Date(), 7))
            })
        arr.push(

            {
                name: 'This Week',
                id: 'thisweek',
                start_date: startOfWeek(new Date()),
                end_date: endOfWeek(new Date())
            })
        arr.push(

            {
                name: 'Yesterday',
                id: 'yesterday',
                start_date: subDays(startOfDay(new Date()), 1),
                end_date: subDays(endOfDay(new Date()), 1)
            })

        let lastmonth = add(new Date(), {
          months: -1,
        })
            
        arr.push(

            {
                name: 'Last Month',
                id: 'lastmonth',
                start_date: startOfMonth(startOfDay(lastmonth)),
                end_date: endOfMonth(startOfDay(lastmonth))
            })

        arr.push(

            {
                name: 'This Month',
                id: 'thismonth',
                start_date: startOfMonth(startOfDay(new Date())),
                end_date: endOfMonth(startOfDay(new Date()))
            })
        arr.push(

            {
                name: 'Custom Range',
                id: 'customrange',
                start_date: null,
                end_date: null
            })
        return arr
      },
      dateRange () {
        let startDate
        let endDate
        let startTime
        let endTime
        if (this.datetypeLocal && this.datetypeLocal.id ==='customrange') {
          startDate = this.startDate
          endDate = this.endDate

          if (!startDate || !endDate) {
            return null
          }

          startTime = this.startTime
          endTime = this.endTime
          if (!startTime) {
            startTime = '00:00:00'
          }
          if (!endTime) {
            endTime = '00:00:00'
          }

        } else if (this.datetypeLocal){
          /*
          startDate = format(this.datetypeLocal.start_date, 'yyyy-MM-dd' ) 
          endDate = format(this.datetypeLocal.end_date, 'yyyy-MM-dd' )
          startTime = '00:00:00'
          endTime = '23:59:59'
          */
         return this.datetypeLocal.id
        } else {
          return null
        }

        return this.includeTime ? `${startDate}T${startTime}|${endDate}T${endTime}` : `${startDate}|${endDate}`
      }
    },
    watch: {
        dateRange (newValue, oldValue) {
          this.$emit('input', newValue)
          this.$emit('changed', newValue)
          // this.$emit('update:datetype', this.datetype)
        }
    }
}
</script>
