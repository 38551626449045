<template>

    <div class="list-container full-height" ref="container">
        <v-container fluid
                     v-for="(item) in items"
                     :key="item.contact_id"
                     class="list-item flex-shrink-0 pa-3 pt-2 pb-2 relative">

            <div class="d-flex flex-grow-1" :id="`${item.contact_id}`" >
                <div class="d-flex flex-grow-1 flex-column">

                    <v-row no-gutters>

                        <v-col cols="6" md="2" lg="2">

                            <div class="list-item__column">
                                <v-tooltip
                                        bottom
                                        nudge-bottom="5"
                                        :disabled="$vuetify.breakpoint.xsOnly"
                                        color="ycmd-black"
                                        v-if="item.status !== 'new' && item.status !== 'complete'"
                                        content-class="fade-0 pa-4 pt-1 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="small"
                                            color="ycmd-blue"
                                            dense
                                            style="margin-left: -10px;"
                                            v-on="on"
                                            >
                                            {{item.status === 'left_message' ? 'settings_phone' :  (item.status === 'sent_text') ? 'textsms' : (item.status === 'unavailable') ? 'sentiment_neutral' : 'pending'  }}
                                        </v-icon>
                                    </template>
                                    <span>{{statusTooltip(item)}} </span>
                                </v-tooltip>
                                <v-tooltip
                                        bottom
                                        nudge-bottom="5"
                                        :disabled="$vuetify.breakpoint.xsOnly"
                                        color="ycmd-black"
                                        v-if="item.status === 'complete'"
                                        content-class="fade-0 pa-4 pt-1 pb-2">
                                    <template v-slot:activator="{ on }"
                                        v-if="item.lastStatus"
                                    >
                                        <v-icon
                                            size="small"
                                            color="ycmd-blue"
                                            dense
                                            style="margin-left: -10px;"
                                            v-on="on"
                                            >
                                            {{item.lastStatus === 'left_message' ? 'settings_phone' :  (item.lastStatus === 'sent_text') ? 'textsms' : (item.lastStatus === 'unavailable') ? 'sentiment_neutral' : 'pending'  }}
                                        </v-icon>
                                    </template>
                                    <span>{{statusTooltip(item, item.lastStatusIndex)}} </span>
                                </v-tooltip>


                                <div class="d-flex flex-column align-start label-value ml-1">                                    
                                    <text-highlight :queries="searchField !== null ? searchField : []">
                                        {{ (item.spelled_first_name || item.spelled_last_name) ?
                                        `${item.spelled_first_name} ${item.spelled_last_name}` : 'N/A' }}
                                    </text-highlight>

                                    <div v-if="item.birth_date"><span class="fw-500">DOB:</span> {{ formatBirthDate(item.birth_date) }}</div>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="2" md="2" lg="2">
                            <div class="list-item__column">
                                <div class="d-flex align-center label-value">
                                    <text-highlight :queries="searchField !== null ? searchField : []">
                                        {{ item.mailbox  }}
                                    </text-highlight>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="6" md="2" lg="2">
                            <div class="list-item__column">
                                <div :class="{'label-value': true, 'text-decoration-underline' : item.callback_phone_sms}" @click="openSendSMS(item)" :style="{'cursor': item.callback_phone_sms ? 'pointer' : 'default'}">
                                    <text-highlight :queries="searchField !== null ? searchField : []">
                                        {{ item.callback_phone ? formatPhoneNumber(item.callback_phone) : 'Not Available' }}
                                    </text-highlight>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="6" md="3" lg="2">
                            <div class="list-item__column">
                                <div class="label-value">
                                    <text-highlight :queries="searchField !== null ? searchField : []">
                                        {{ item.date_created ? format(parseISO(item.date_created), 'MM-dd-yyyy h:mm a') : 'N/A' }}
                                    </text-highlight>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" md="3" lg="4">
                            <div class="list-item__column">
                                <div class="label-value">
                                    <text-highlight :queries="searchField !== null ? searchField : []">
                                        {{ item.transcript ? item.transcript : 'N/A' }}
                                    </text-highlight>
                                </div>
                            </div>
                        </v-col>

                    </v-row>

                </div>

                <div class="hidden-sm-and-down">

                    <div class="d-flex full-height pl-2 justify-end align-center">

                        <template>
                            <v-tooltip bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        size="x-large"
                                        color="ycmd-blue"
                                        dense
                                        v-on="on"
                                        @click="toggleSelect(item)"
                                        v-if="item.selected"
                                        >
                                            check_circle_outline
                                    </v-icon>
                                    <v-icon
                                        size="x-large"
                                        color="ycmd-blue"
                                        dense
                                        v-on="on"
                                        @click="toggleSelect(item)"
                                        v-else
                                        >
                                            radio_button_unchecked
                                    </v-icon>
                                </template>
                                <span>{{item.selected ? 'Selected' : 'Not selected'}}</span>
                            </v-tooltip>
                        </template>

                        <!--
                        <template v-if="status === 'complete'">
                            <v-tooltip bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                                <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="x-large"
                                            color="ycmd-blue"
                                           v-on="on"
                                           @click="changeStatusClick(item, item.contact_id, 'new')"
                                        >
                                            settings_backup_restore
                                        </v-icon>
                                </template>
                                <span>Restore</span>
                            </v-tooltip>
                        </template>
                        -->

                        <template>
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="item.isLoading"
                                :size="24"
                                ></v-progress-circular>
                            <v-tooltip v-else
                                        bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                                <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="x-large"
                                            color="ycmd-green"
                                            v-on="on"
                                           @click="openAudioPlayerClick(item)"                                            
                                            >
                                            play_circle_outline
                                        </v-icon>
                                </template>
                                <span>Play</span>
                            </v-tooltip>
                        </template>
                        
                        <template v-if="status === 'new'">
                            <v-tooltip bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                                <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="x-large"
                                            :color="item.hasNote ? 'ycmd-green' : 'ycmd-blue'"
                                           v-on="on"
                                           @click="startAddNote(item)"                                            
                                            >
                                            note_add
                                        </v-icon>
                                </template>
                                <span>Add Note</span>
                            </v-tooltip>

                        </template>
                        <v-icon
                            v-else
                            size="x-large"
                            :color="item.hasNote ? 'ycmd-green' : 'ycmd-blue'"
                            >
                            description
                        </v-icon>

                        
                        <template>
                            <v-tooltip bottom
                                       nudge-bottom="5"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-1 pb-2">
                                <template v-slot:activator="{ on }">
                                        <v-icon
                                            size="x-large"
                                            color="ycmd-blue"
                                            v-on="on"
                                            @click="showDetailClick(item.contact_id)"
                                            >
                                            arrow_forward
                                        </v-icon>
                                </template>
                                <span>Details</span>
                            </v-tooltip>
                        </template>
                    </div>
                </div>

            </div>
        </v-container>

        <infinite-loader :infiniteHandler="infiniteHandler" :infiniteId="infiniteId" :refreshHandler="refreshData"/>

        <voicemail-sms 
            v-model="sms"
            :voicemail="currentItem"
            v-on:changed="onVoicemailChangeSms"             
            />

        <voicemail-note 
            v-model="note"
            :voicemail="currentNoteItem"
            v-on:changed="onVoicemailChangeNote" 
            />

    </div>

</template>

<script>
    import { format, parseISO } from 'date-fns';
    import { truncateText } from '@/methods/utils';
    import { formatPhoneNumber } from '@/methods/globalMethods';
    import InfiniteLoader from '@/components/loader/infinite-loader';
    import VoicemailSMS from '@/components/modals/voicemail-sms';
    import VoicemailNote from '@/components/modals/voicemail-action';

    export default {
        name: 'voicemailListContent',
        props: {
            loading: Boolean,
            status: String,
            searchField: String,
            refreshData: Function,
            items: Array,
            // infiniteHandler: Function,
            // infiniteId: Number,
            // scrollToBottom: Function,
            showDetail: Function,
            changeStatus: Function,
            openAudioPlayer: Function,
            infiniteHandler: Function,
            infiniteId: Number,
        },
        components: {
            'infinite-loader': InfiniteLoader,
            'voicemail-sms': VoicemailSMS,
            'voicemail-note': VoicemailNote
        },
        data: () => ({
            format,
            parseISO,
            truncateText,
            formatPhoneNumber,
            page: 1,
            sms: false,
            currentItem: {},
            note: false,
            currentNoteItem: {},
            noteSending: false
        }),
        mounted() {},
        methods: {
            toggleSelect (item) {
                item.selected = !item.selected
            },
            statusTooltip(voicemail, index) {
                let ttip = ''                
                if (voicemail) {
                    

                    let name = ''
                    if (voicemail.history && voicemail.history.length) {
                        let h
                        if (index >= 0 && index < voicemail.history.length) {
                            h = voicemail.history[index]
                        } else {
                            h = voicemail.history[voicemail.history.length-1]
                        }
                        name = `${h.first_name} ${h.last_name} `
                    }

                    let status = voicemail.status

                    if (index >= 0) {
                        status = voicemail.history[index].value
                    }

                    switch (status) {
                        case 'left_message':
                            ttip = `${name} left a voicemail`
                            break
                        case 'sent_text':
                            ttip = `${name} sent a text`
                            break
                        case 'waiting':
                            ttip = `${name} waiting on patient`
                            break
                        case 'unavailable':
                            ttip = `${name} - patient is unavailable`
                            break
                    } 
                }
                return ttip
            },

            onVoicemailChangeNote(voicemail) {
                this.$set(this.currentNoteItem, "status", voicemail.status);
                this.$set(this.currentNoteItem, "history", voicemail.history);
                // this.$set(this.currentNoteItem, "hasNote", true);

                if (voicemail.status === 'complete' && this.status === 'new') {
                    
                    let index = this.items.findIndex(i => {
                        return i.contact_id == voicemail.contact_id
                    })

                    if (index >= 0) {
                        this.items.splice(index, 1)
                    }
                }
            },
            onVoicemailChangeSms(voicemail) {
                this.$set(this.currentItem, "status", voicemail.status);
                this.$set(this.currentItem, "history", voicemail.history);
                this.$set(this.currentItem, "hasNote", true);
                if (voicemail.status === 'complete' && this.status === 'new') {
                    
                    let index = this.items.findIndex(i => {
                        return i.contact_id == voicemail.contact_id
                    })

                    if (index >= 0) {
                        this.items.splice(index, 1)
                    }
                }

            },
            startAddNote(item) {
                this.currentNoteItem = item
                this.note = true
            },
            openSendSMS(item) {
                if (item.callback_phone && item.callback_phone_sms) {
                    this.currentItem = item
                    this.sms = true
                }
            },
            showDetailClick(contact_id) {
                if(this.showDetail) this.showDetail(contact_id);
            },
            changeStatusClick(item, voicemail_id, status) {

                console.log('CHANGE STATUS CLICK');

                if(this.changeStatus)
                    this.changeStatus(item, voicemail_id, status);
            },
            openAudioPlayerClick(item) {

                if(this.openAudioPlayer){
                    this.$set(item, "isLoading", true);
                    this.openAudioPlayer(item);
                }
            },
          formatBirthDate(date) {

              if (date) {

                const dob = date.split('-');

                return `${ dob[1] }-${ dob[2] }-${ dob[0] }`;
              }
              else
                return 'NA'
          }
        }
    }
</script>
