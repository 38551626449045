<template>
    <v-card
       style="width: 420px;"
    >
      <v-overlay
        :absolute="true"
        :value="initializing"
        opacity="0.75"
      >
        <loading theme="light" />
      </v-overlay>

      <v-card-title
      >
        <span>
          Search for rooms or contacts
        </span>
        <v-spacer />
        <span
        class="fs-8 ycmd-blue--text text-decoration-underline pointer"
        @click="cancel"
        >Cancel</span>        

      </v-card-title>
      <v-card-actions>
        <div class=" br-3 d-flex align-center full-width ycmd-light-blue white--text pa-2">

          <v-text-field flat solo hide-details no-resize auto-grow
                      v-model="search"
                      background-color="white"
                      class="ml-2 mr-2 ycmd-dark-blue--text"
                      label="Search text"
                      ref="txtSearch"
                      />
        </div>  

      </v-card-actions>
      <v-card-subtitle
      class="pl-1"
      >
        <v-chip
            v-for="item in selectedUsers"
            :key="item.user_id"
            class="ma-1 pa-1 fs-11 ycmd-very-lightest-blue"
            close
            @click:close="removeUser(item)"

          >
            {{item.name}}
        </v-chip>
      </v-card-subtitle>
  
      <v-card-text
        style="max-height: 300px;min-height:300px"
        class="overflow-y-auto pa-0 ma-0"
        id="chatRoomSelectorList"      
      >
        <v-form
            ref="post"
            style="width:100%"
            @submit.prevent="savePost"
            v-if="canStartNewConversation"
            class="pl-2 pr-2"
          >

          <div class=" br-3 d-flex align-center full-width ycmd-light-blue white--text pa-2"
              >
            <v-text-field flat solo hide-details no-resize auto-grow
                        ref="txtTextToPost"
                        v-model="textToPost"
                        rows="1"
                        :disabled="sendingPost"
                        background-color="white"
                        class="ml-2 mr-2 ycmd-dark-blue--text"
                        :label="startRoomMessage"/>
            <v-tooltip top
                      nudge-top="5"
                      :disabled="$vuetify.breakpoint.xsOnly"
                      color="ycmd-black"
                      content-class="fade-0 pa-4 pt-1 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn
                    icon dark depressed
                    v-on="on"                      
                    :disabled="textToPost.length === 0"
                    :loading="sendingPost"
                    class="ma-0"
                    type="submit"
                    color="white">
                  <v-icon dark size="18">send</v-icon>
                </v-btn>
              </template>
              <span>Send</span>
            </v-tooltip>
          </div>     
        </v-form>       
        <room-list
          :rooms="roomsParameter"
          :contacts="contactsParameter"
          :search="search"
          :loading="initializing"
          @selectUser="selectUser"
          @changeRoom="changeRoom"
        />

      </v-card-text>
      <v-card-actions>

      </v-card-actions>      
    </v-card>

</template>

<script>
import dataClient from '@/graphql/clients/axios';
import { format, utcToZonedTime } from 'date-fns-tz';
import {mapGetters, mapActions} from "vuex";
import Loading from '@/components/loading-elipsis';
import roomList from '@/views/apps/chat/components/room-list.vue';

export default {
  props: {
    changeView: Function,
    callbackChanged: Function
  },
  data: () => ({
    selected: {},
    search: '',
    items: [],
    lastTimer: 0,
    keyboardTimer: 300,
    selectedUsers: [],
    initializing: true,
    scrollElement: null,
    loadingMore: false,
    nextToken: null,
    textToPost: '',
    sendingPost: false,
    roomsParameter: [],
    contactsParameter: []
  }),
  methods: {
    ...mapActions('chat', ['loadCurrentRoom', 'createRoom']),
    isParticipantMatch () {
                  
      let selUsersMap = {}
      this.selectedUsers.map(u => {
        selUsersMap[u.user_id] = true
      })
      selUsersMap[this.getUserID] = true

      for (let i=0;i<this.roomsParameter.length;i++){
        let item = this.roomsParameter[i]
        if (item.object_type === 'room') {
          let arr = item.participant
          if (!arr) {
            arr = []
          }
          
          if (arr.length === this.selectedUsers.length + 1) { // add current user to selected users

            let isMatch = true
            for (let j=0;j<arr.length;j++) {
              let part = arr[j]
              if (!selUsersMap[part.user_id]) {
                isMatch = false
                break
              }
            }
            
            if (isMatch) {
              return true
            }
          }
        }
      }
      return false
    },
    
    async savePost () {

      if (this.textToPost.length > 0 && this.selectedUsers.length) {

        this.initializing = true      

        this.sendingPost = true;
        let arr = []
        this.selectedUsers.map(u => {
          arr.push(u.user_id)
        })

        let room = await this.createRoom({
          post_text: this.textToPost,
          participants: arr 
        });

        this.textToPost = ''
        this.sendingPost = false;

        await this.loadCurrentRoom(room)

        this.changeView()
      
        this.initializing = false  
      }

    },
    onScroll (params) {
      let el = params.srcElement

      console.log(`room scrollTop: ${el.scrollTop}, scrollHeight: ${el.scrollHeight} clientHeight: ${el.clientHeight} max: ${el.scrollHeight - el.clientHeight}`)
              
      let lastPage = el.scrollHeight - el.clientHeight

      let current = el.scrollTop + el.clientHeight
      console.log(`lastPage: ${lastPage}, current: ${current} page: ${this.page}`)

      if (current > lastPage && this.nextToken && !this.loadingMore) {
              //this.page += 1
              this.loadingMore = true
              this.getRoomsAndPeople(false, this.search)
              //this.loadMore()
      }

    },   

    setScrollElement () {
      let element = this.$el.querySelector('#chatRoomSelectorList')
      
      if (element) {

          if (this.scrollElement) {
              this.scrollElement.removeEventListener('scroll', this.onScroll, false)
              this.scrollElement = null
          }

          this.scrollElement = element
          this.scrollElement.addEventListener('scroll', this.onScroll, false)
      }

    },    
    cancel () {
      this.changeView()
    },
    removeUser (item) {
      let index = this.selectedUsers.findIndex(i => {
          return i.user_id == item.user_id
      })

      if (index >= 0) {
          this.selectedUsers.splice(index, 1)
      }
      this.search = ''
      this.getRoomsAndPeople(true, '')
    },
    selectUser (item) {

      this.search = ''
      this.selectedUsers.push(item)
      this.getRoomsAndPeople(true, this.search)
      this.$nextTick(() => {
        this.$refs.txtSearch.focus()
      })        

    },
    async changeRoom (item) {
      this.initializing = true      
      let result = await dataClient('ycmdChatRoomGet',{
        room_id: item.id
      })

      let promises = []
      promises.push(this.loadCurrentRoom(result.rooms[0]))
      promises.push(dataClient('ycmdChatRoomClearUnread', {room_id: result.rooms[0].id}))

      let arrResults = await Promise.all(promises)
      console.log('result.rooms[0]',  result.rooms[0])
        
      this.$store.dispatch('app/syncBadges', {from: 'changeApp', type: 'chat_post'}, {root:true})


      this.changeView()
      this.initializing = false      

    },
    callback() {
      this.initializing = true
      this.search = ''
      this.selectedUsers = []      
      this.getRoomsAndPeople(true) //start fresh
      this.setScrollElement()
      this.$nextTick(() => {
        this.$refs.txtSearch.focus()
      })  
    },
    convertMatches(matches) {
      let arr = []
      if (matches) {
        matches.map(m => {
          arr.push(m.source)
        })
      }
      
      return arr
    },
    async getRoomsAndPeople(clear, search) {

      let nextToken = clear ? null: this.nextToken

      let objects =  search ? ['contact','room'] : ['room']

      let params = {
        objects: objects,
                    participants: [],
                    roles: ['provider','member'],
                    search: search,
                    nextToken: nextToken,
                    limit: this.selectedUsers.length ? 100 : 10 
      // since we are now restricting the ability to create duplicate rooms, 
      // when they pin a user, make sure you get enough rooms so we can check for dup participants
      }

      if (this.selectedUsers && this.selectedUsers.length) {
        this.selectedUsers.map(u => {
          params.participants.push(u.user_id)
        })
      }

      let searchResults = await dataClient('ycmdChatSearch',params)
      this.nextToken = searchResults.nextToken

      let tempItems
      if (clear) {
        tempItems = this.convertMatches(searchResults.matches)
      } else {
        tempItems = this.roomsParameter
        tempItems.push(...this.convertMatches(searchResults.matches))
      }

      this.roomsParameter = tempItems
      this.contactsParameter = searchResults.contacts

      this.initializing = false
      this.loadingMore = false
    },

  },
  computed: {
    ...mapGetters('profile', ['getUserID','getGroup']),    
    startRoomMessage () {

      let isMatch = this.isParticipantMatch()
      if (isMatch) {
        return `Create another room with ${this.messageRecipients}`
      }
      return `Start your conversation with ${this.messageRecipients}`
    },
    messageRecipients () {
      let recipients = ''
      let delimiter = ''
      this.selectedUsers.map(u => {
        recipients += delimiter
        recipients += `${u.first_name} ${u.last_name}`

        delimiter = ', '
      })
      return recipients      
    },
    canStartNewConversation () {
      if (this.search && this.search.length) {
        return false
      }
      if (this.initializing) {
        return false
      }
      if (!this.selectedUsers || !this.selectedUsers.length) {
        return false
      }


      if (!this.roomsParameter.length) {        
        return true
      }

      if (this.getGroup && this.getGroup.settings && this.getGroup.settings.chat_allow_same_participant_rooms) {
        return true
      }
      
      let ismatch = this.isParticipantMatch()      
      return !ismatch
    }
  },
  components: {
    loading: Loading,
    'room-list': roomList
  },
  mounted () {

    //this.$emit('callbackChanged', this.callback)
    if (this.callbackChanged) {
      this.callbackChanged('chat-status-room-select',this.callback)
    }

  },
  watch: {

    search(newValue, oldValue) {

      if (this.lastTimer) {
        clearTimeout(this.lastTimer);
        this.lastTimer = 0
      }

      this.lastTimer = setTimeout(async () => {

        await this.getRoomsAndPeople(true, newValue)

      }, this.keyboardTimer)

    },

  },
}
</script>
<style scoped>
  #chatRoomSelectorList >>> div.v-list-item
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  


</style>