import jwt_decode from "jwt-decode";
import axios from "axios";

import REFRESH_TOKEN from '@/graphql/mutations/authenticateRefreshToken';

const { definitions, loc } = REFRESH_TOKEN;
const { name, operation } = definitions[0];
const { body } = loc.source;

// Craft this object based on graphql tag so we don't maintain the query in 2 places
const authenticateRefreshToken = { authorization: 'apikey', operation, name, query: body };

const post = async (dobj, params) => {

    let url = process.env.VUE_APP_APPSYNC_URL;

    let data = {}

    data.query = dobj.query
    data.variables = params || {}

    let headers = {
        'Content-Type': 'application/graphql',
        'cache-control': 'no-cache',
    }

    if (dobj.authorization === 'token') {
        headers.Authorization = localStorage.getItem('accessTokenPCP')
    } else {
        headers['x-api-key'] = process.env.VUE_APP_APPSYNC_API_KEY
    }

    // console.log("URL: ", url)
    // console.log("DATA: ", data)
    // console.log("HEADERS: ", headers);

    try {
        return await axios.post(url, data, {headers: headers}, {timeout: 180000});
    }
    catch (e) {
        console.log('ERROR: Retrieving refresh token from the backend: ', JSON.stringify(e))
        return null;
    }
}

const checkAccessToken = (accessToken) => {
    let decoded = jwt_decode(accessToken);
    // console.log('decoded: ', decoded);
    const currentTime = Math.floor(new Date().getTime() / 1000);
    let timeLeft = decoded && decoded.exp ? decoded.exp - currentTime : 0;

    return { decoded, timeLeft }
};

async function refreshHandler() {
    // Check expiration of auth token
    const refreshToken = localStorage.getItem("refreshTokenPCP");
    const username = localStorage.getItem("username"); // this should be the user's email
    const accessToken = localStorage.getItem("accessTokenPCP");

    // console.log('username: ', username);
    // console.log('refresh token in refreshHandler PCP: ', refreshToken);
    // console.log('access token: ', accessToken);

    const { decoded, timeLeft } = checkAccessToken(accessToken);

    // NOTE: no idea why this was added?
    // if (decoded) {
    //     localStorage.setItem("userID", decoded.user_id);
    // }

    console.log('time left access PCP: ', timeLeft);
    // timeLeft < 30
    if (timeLeft < 30) {

        if (refreshToken && username) {

            try {
                const response = await post(authenticateRefreshToken, {
                    email: username,
                    refreshToken: refreshToken
                })
                // console.log('refresh response: ', response);

                // response will be null if no connection

                if (response) {
                    if (response.data && response.data.data && response.data.data.authenticateRefreshToken && response.data.data.authenticateRefreshToken.accessToken) {
                        console.log('INFO: Refreshed PCP token successfully!');
                        localStorage.setItem("accessTokenPCP", response.data.data.authenticateRefreshToken.accessToken);
                        return true;
                    } else {
                        console.log("WARNING: Access token couldn't be retrieved from the refresh token PCP");
                        console.log('response for refresh failure PCP: ', response, 'timeleft: ', timeLeft);
                        return false;
                    }
                } else {
                    // no internet connection
                    console.log("WARNING: Refresh token response is null, probably do not have connection PCP");
                    return true; // returning true so it does not log you out
                }

            }
            catch (e) {
                console.log('ERROR: There was an error refreshing token PCP: ', e);
            }

            return false;
        }
        else {

            if (!refreshToken)
            console.log("WARNING: No refresh token available");

            if (!username)
            console.log("WARNING: No user info available");
        }

    }
    else {
        // Token is still valid
        return true;
    }
}

export { refreshHandler }
