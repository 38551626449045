<template>

    <div class="view pb-2">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card mr-2">

            <div class="i-card__main">

                <div class="i-card__main__header">

                    <communication-header :toggleMenuRight="toggleMenuRight">

                        <template v-slot:default>

                            <v-tabs dark
                                    background-color="transparent"
                                    v-model="headerTabs">

                                <v-tabs-slider color="ycmd-green"></v-tabs-slider>

                                <v-tab href="#tab-queue" @click="onChangeTab($event, 'tab-queue')">Queue</v-tab>
                                <v-tab href="#tab-archive" @click="onChangeTab($event, 'tab-archive')">Archive</v-tab>

                            </v-tabs>
                        </template>

                        <template v-slot:actions>

                          <v-tooltip bottom
                                   nudge-bottom="0"
                                   nudge-right="40"
                                   color="ycmd-black"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark rounded
                                       @click="refreshData"
                                       v-on="on"
                                        :disabled="sending"
                                       class="">
                                    <v-icon :size="22" color="">refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh</span>
                          </v-tooltip>

                          <input-search :searchValue.sync="searchValue" :onSearchClick="onSearchClick" :openRightPanel="openRightPanel" :hasAdvancedSearch="true" :advancedSearchColor="advancedSearchColor"/>
                          
                            <v-tooltip bottom

                                nudge-bottom="0"
                                nudge-right="40"
                                color="ycmd-black"
                                content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark rounded
                                        @click="getVoicemail(true)"
                                        :loading="isLoadingDownload"
                                        v-on="on"
                                        class="ml-4"
                                        :disabled="sending"
                                        >
                                        <v-icon :size="22" color="">cloud_download</v-icon>
                                    </v-btn>
                                </template>
                                <span>Download Report</span>
                            </v-tooltip>

                        </template>

                    </communication-header>

                </div>

                <div class="i-card__main__content">

                    <sub-header-content v-if="this.status === 'new' || this.status === 'complete'">

                        <template v-slot:header-start>

                            <v-row no-gutters
                            >
                                <v-col cols="2" md="2" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text"
                                    style="cursor: default;"
                                    >Caller</div>
                                </v-col>
                                <v-col cols="2" md="2" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text pl-4" 
                                        @click="toggleSort('mailbox')"
                                        style="cursor: pointer;"
                                    >Mailbox&nbsp;
                                        <v-icon :size="16"
                                        v-if="sortField === 'mailbox'"
                                        >{{sortIcon}}</v-icon>
                                    </div>
                                </v-col>
                                <v-col cols="2" md="2" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text"
                                    @click="toggleSort('callback_phone')"
                                    style="cursor: pointer;padding-left: 32px;"
                                    >Callback
                                        <v-icon :size="16"
                                        v-if="sortField === 'callback_phone'"
                                        >{{sortIcon}}</v-icon>

                                    </div>
                                </v-col>
                                <v-col cols="2" md="2" lg="2">
                                    <div class="fixed-header__column--group fixed-header__text"                                        
                                        @click="toggleSort('date_created')"
                                        style="cursor: pointer;padding-left: 52px;"
                                        >Date&nbsp;

                                        <v-icon
                                            v-if="sortField === 'date_created'"
                                            :size="16">{{sortIcon}}</v-icon>
                                    </div>
                                </v-col>
                                <v-col cols="3" md="3" lg="4"
                                    style="cursor: default;padding-left: 70px;"
                                >
                                    <div class="fixed-header__column--group fixed-header__text">Transcription

                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Spacing for list buttons -->
                            <div class="scrollbar-width-padding" style="width: 120px;">&nbsp;</div>

                        </template>

                        <template v-slot:header-end>
                            <v-icon
                                size="x-large"
                                color="white"
                                dense
                                @click="toggleSelectAll()"
                                v-if="allSelected"
                                >
                                    {{headerSelectIcon}}
                            </v-icon>

                            <span
                                class="white--text pr-0"
                                :style="{'min-width': status == 'new' ? '80px' : '75px', 'text-align': 'center'}"
                            >
                               <span
                                    v-if="headerSelectIcon === 'radio_button_unchecked'"
                               >
                                   {{currentItems}} of {{totalItems}}
                                </span>
                                <span
                                    class="text-decoration-underline"
                                    @click="multiOperation"
                                    style="cursor: pointer;"
                                    v-else
                                >
                                    {{status == 'new' ? 'Archive':'Restore'}}
                                </span>
                            </span>

                        </template>

                    </sub-header-content>

                    <div class="i-card__scrollable" ref="voicemail_container">

                        <div class="list-container">

                            <v-tabs-items v-model="headerTabs">

                                <v-tab-item value="tab-queue"
                                            transition="v-fade-transition"
                                            reverse-transition="v-fade-transition">

                                    <template v-if="status === 'new'">
                                        <voicemail-list-content
                                            :status="status"
                                            :items="newItems"
                                            :searchField="searchValue"
                                            :changeStatus="changeStatus"
                                            :openAudioPlayer="openAudioPlayer"
                                            :showDetail="showDetail"
                                            :refreshData="refreshData"
                                            :infiniteHandler="infiniteHandler"
                                            :infiniteId="infiniteId"/>
                                    </template>

                                </v-tab-item>

                                <v-tab-item value="tab-archive"
                                            transition="v-fade-transition"
                                            reverse-transition="v-fade-transition">

                                    <template v-if="status === 'complete'">
                                        <voicemail-list-content
                                            :status="status"
                                            :items="newItems"
                                            :searchField="searchValue"
                                            :changeStatus="changeStatus"
                                            :openAudioPlayer="openAudioPlayer"
                                            :showDetail="showDetail"
                                            :refreshData="refreshData"
                                            :infiniteHandler="infiniteHandler"
                                            :infiniteId="infiniteId"/>
                                    </template>

                                </v-tab-item>

                            </v-tabs-items>

                        </div>

                    </div>

                </div>

            </div>

            <template slot="right-panel">
                <voicemail-advanced-search 
                    :status="status"
                    :advancedSearch.sync="advancedSearch"
                    :submitAdvancedSearch="submitAdvancedSearch"
                    :clearAdvancedSearch="clearAdvancedSearch"/>

            </template>

        </i-card>

        <i-audio-player :audioPath="audioPath" :title="fromPhone" :subtitle="dateCreated" :id="contactId"  @download="download" />

        <confirmation
            v-model="confirmation"
            :title="status == 'new' ? 'Archive':'Restore'"
            :subtitle="status == 'new' ? 'Confirm Batch Archive':'Confirm Batch Restore'"
            :text="status == 'new' ? 'Are you sure you want to archive the selected voicemails?':'Are you sure you want to restore the selected voicemails?'"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />
    </div>

</template>

<script>
    import axios from "axios";
    import {mapActions, mapState, mapGetters} from "vuex";
    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import InfiniteLoader from '@/components/loader/infinite-loader';
    import { formatPhoneNumber } from '@/methods/globalMethods';
    import SubHeaderContent from '@/components/header/sub-header-content';
    import Counter from '@/components/header/items-counter';
    import CommunicationHeader from "../components/communication-header";
    import InputSearch from "@/components/input-search";
    import VoicemailListContent from "./list-content";
    import VoicemailAdvancedSearch from "./advanced-search";

    import GET_VOICEMAIL from '@/graphql/queries/phoenix/ycmdVoicemailGet';
    import GET_VOICEMAIL_AUDIO from '@/graphql/queries/phoenix/ycmdVoicemailAudioGet';
    import MUTATE_VOICEMAIL_STATUS from '@/graphql/mutations/phoenix/ycmdVoicemailUpdateStatus';

    import Confirmation from '@/components/modals/confirmation.vue'

    export default {
        name: 'voicemailList',
        components: {
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'communication-header': CommunicationHeader,
            'input-search': InputSearch,
            'voicemail-list-content': VoicemailListContent,
            'infinite-loader': InfiniteLoader,
            'voicemail-advanced-search': VoicemailAdvancedSearch,
            Loading,
            InfiniteLoading,
            confirmation: Confirmation
        },
        props: {
            changeView: Function,
            params: {
                data: Object
            }
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                searchValue: null,
                headerTabs: null,
                contactId: '',
                fromPhone: '',
                dateCreated: '',
                audioPath: '',
                searchField: null,
                status: 'new',
                newItems: [],
                completeItems: [],
                changedItems: [],
                changedStatus: '',
                nextToken: null,
                limit: 20,
                isLoading: false,
                isLoadingContacts: false,
                infiniteId: 1,
                loadingMessageResult: '',
                currentItems: 0,
                totalItems: 0,
                sortField: 'date_created',
                sortDirection: 'desc',
                advancedSearch: {
                    //start_date: null,
                    //end_date: null,
                    voicemail_date: null,
                    callback_phone: null,
                    mailbox: null
                },
                isLoadingDownload: false,
                allSelected: 'none',
                confirmation: false,
                sending: false
            }
        },
        mounted() {
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            async download (id) {
                let bloburl = await this.getAudioUrl(id);

                axios({
                    url: bloburl, //your url
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${id}.mp3`);
                    document.body.appendChild(link);
                    link.click();
                });
            },
            badgeCount (originalStatus, status) {
                let count = 0

                if (originalStatus === 'new') {
                    if (status !== 'new') {
                        count = -1
                    }
                } else {
                    if (status === 'new' ) {
                        count = 1
                    }
                }
                return count
            },
            multiOperation () {
                this.confirmation = true
            },
            async confirmationClicked (btn) {
                let ids = []
                switch (btn.caption) {
                    case 'OK':
                        this.sending = true
                        let count = 0
                        this.newItems.map(i => {
                            if (i.selected) {
                                count += this.badgeCount(i.status, this.status == 'new' ? 'complete': 'new')

                                ids.push(i.contact_id)
                            }
                        })

                        console.log('IDS', ids)

                        const response = await this.$apollo.mutate({
                            mutation: MUTATE_VOICEMAIL_STATUS,
                            variables: {
                                additional_ids: ids,
                                status: this.status == 'new' ? 'complete': 'new'
                            },
                            client: 'phoenixClient',
                            fetchPolicy: 'no-cache',
                            errorPolicy: 'all'
                        });
                        this.refreshData(ids, this.status == 'new' ? 'complete': 'new');
                        this.$store.dispatch('app/adjustBadge', 
                        {
                            badgeKey: 'voicemail',
                            count: count
                        })
                        break

                    case 'Cancel':

                        break
                }
                this.confirmation = false
                this.sending = false
            },
            toggleSelectAll() {
                let value = this.headerSelectIcon ===  'check_circle_outline' ? false: true
                this.newItems.map(i => {
                    i.selected = value
                })

            },
            clearAdvancedSearch() {
                this.advancedSearch.start_date = null
                this.advancedSearch.end_date = null
                this.advancedSearch.voicemail_date = null

                this.advancedSearch.callback_phone = null
                this.advancedSearch.mailbox = null
                this.submitAdvancedSearch({shouldRefresh:true, closePanel:true})
            },
            submitAdvancedSearch({shouldRefresh, closePanel}) {
                if (shouldRefresh) {
                    // refresh data
                    this.refreshData();
                }
                if (closePanel) {
                    // close panel
                    this.closeRightPanel()
                }
            },
            toggleSort(field) {
                if (this.sortField === field) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
                } else {
                    this.sortField = field

                }

                this.refreshData()
            },
            showDetail(id) {
                this.changeView({view: 'details-view', params: { id }});
            },
            openRightPanel() {

                this.card.panels.right = true;
            },
            closeRightPanel() {

                this.card.panels.right = false;
            },
            async openAudioPlayer(item) {

                //console.log('id: ' + contact_id + ', phone: ' + from_phone);

                const url = await this.getAudioUrl(item.contact_id);

                //console.log("URL: ", url);

                this.fromPhone = formatPhoneNumber(item.from_phone);
                console.log("DATE_CREATED", item.date_created);
                this.dateCreated = item.date_created ? format(parseISO(item.date_created), 'iii MMM d, yyyy h:mm a') : 'NA';
                this.audioPath = url;
                this.$set(item, "isLoading", false);
            },
            async getAudioUrl(contactId) {

                //console.log(contactId);

                try {
                    this.contactId = contactId
                    const response = await this.$apollo.query({
                        query: GET_VOICEMAIL_AUDIO,
                        variables: {
                            contactId: contactId
                        },
                        client: 'phoenixClient',
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    // check errors
                    if(response) {
                        if (response.errors) {
                            throw response.errors;
                        }
                        else {
                            const { ycmdVoicemailAudioGet: url } = response.data;
                            return url;
                        }
                    }
                    else
                        throw "No respones returned when getting audio url";

                }
                catch(e) {

                    console.log(e);
                }
            },
            onSearchClick(e, value) {

                // console.log("SEARCH VALUE");
                // console.log(value);

                if(value === '') value = null;

                this.searchValue = value;

                this.refreshData()
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            setStartingPosition (id) {
                let target = document.getElementById(id)

                this.$vuetify.goTo(target, { container: this.$refs.voicemail_container });
            },
            onChangeTab(e, tab) {
                this.status = (tab === 'tab-queue') ? 'new' : 'complete';

                this.refreshData();
            },
            preProcess(voicemail) {
                let vm = []
                let statusChanged = {}
                console.log('this.changedStatus',this.changedStatus)
                console.log('headerTabs', this.headerTabs)

                if (this.changedItems && this.changedItems.length && this.changedStatus) {
                    this.changedItems.map(i => {
                        statusChanged[i] = true
                    })
                }
                console.log('statusChanged', statusChanged)

                voicemail.map(v => {
                    // it seems there is now some latency between when the elasticsearch index is updated and
                    // when our refresh is done.  Ordinarily I would simply remove the items from the store on the client
                    // but because someone introduced an unnecesary infinite scroller, I have to make sure we update through it
                    // so now we will filter items that have had their status changed
                    
                    let skip = false
                    if (statusChanged[v.contact_id]) {
                        console.log('found statusChanged[v.contact_id]', v.contact_id)
                        if ( (this.headerTabs === 'tab-queue' && this.changedStatus === 'complete') ||
                            this.headerTabs === 'tab-archive' && this.changedStatus !== 'complete') {
                                skip = true
                            }
                    }
                    console.log(`contact ${v.contact_id}`, skip)
                    if (!skip) {

                        if (v.spelled_first_name && v.spelled_first_name.length) {
                            v.spelled_first_name = v.spelled_first_name.charAt(0).toUpperCase() + v.spelled_first_name.slice(1);
                        }
                        if (v.spelled_last_name && v.spelled_last_name.length) {
                            v.spelled_last_name = v.spelled_last_name.charAt(0).toUpperCase() + v.spelled_last_name.slice(1);
                        }
                        v.hasNote = false
                        v.selected = false

                        if (v.history && v.history.length) {
                            let lastStatus
                            let lastStatusIndex=-1
                            let noteIndex = -1
                            for (let i = v.history.length - 1;i >= 0; i--) {
                                let h = v.history[i]
                                if (h.type == 'note') {
                                    noteIndex = i
                                }
                                if (!lastStatus && i <= v.history.length - 1 && h.type == 'status' && h.value !== 'complete'  && h.value !== 'new') {
                                    lastStatus = h.value
                                    lastStatusIndex = i
                                }

                                if (noteIndex >= 0 && lastStatus) {
                                    break
                                    // done
                                }
                            }
                            /*
                            console.log('v.history', v.history)
                            let noteIndex = v.history.findIndex(h => {
                                return h.type === 'note'
                            })
                            */
                            v.lastStatus = lastStatus
                            v.lastStatusIndex = lastStatusIndex
                            //console.log('lastStatus', lastStatus)

                            //console.log('noteIndex', noteIndex)
                            v.hasNote = noteIndex >= 0
                            //console.log('v.hasNote', v.hasNote)
                        }
                        vm.push(v)
                    }
                })
                return vm
            },
            async getVoicemail(inCSV) {
                let statusArray = this.status === 'complete' ? ['complete'] : ['new','left_message','sent_text','waiting', 'unavailable']

                let variables = {
                    limit: inCSV ? 1000: 100,
                    search: this.searchValue,
                    sort_direction: this.sortDirection,
                    sort_field: this.sortField,
                    status: statusArray,
                    nextToken: inCSV ? null: this.nextToken,
                    output_type: inCSV ? 'csv' : null,
                    client_offset: (new Date()).getTimezoneOffset()
                }
                /*
                if (this.advancedSearch.start_date) {
                    variables.start_date = this.advancedSearch.start_date
                }
                if (this.advancedSearch.end_date) {
                    variables.end_date = this.advancedSearch.end_date
                }
                */

                if (this.advancedSearch.voicemail_date) {
                    variables.voicemail_date = this.advancedSearch.voicemail_date
                }

                if (this.advancedSearch.callback_phone) {
                    variables.callback_phone = this.advancedSearch.callback_phone
                }
                if (this.advancedSearch.mailbox) {
                    variables.mailbox = this.advancedSearch.mailbox
                }                    
                this.sending = true

                const response = await this.$apollo.query({
                    query: GET_VOICEMAIL,
                    variables: variables,
                    client: 'phoenixClient',
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                this.sending = false

                if (inCSV) {
                    const {ycmdVoicemailGet} = response.data;

                    if (ycmdVoicemailGet && ycmdVoicemailGet.voicemail_url) {
                        this.$toasted.success('Generated CSV. Please make sure pop-up blocker is not enabled');
                        window.open(ycmdVoicemailGet.voicemail_url, '_blank');
                    } else {
                        // data did not come back
                        this.$toasted.error('There was an issue downloading CSV');
                        console.log('ycmdVoicemailGet data did not come back or is finished: ', response);
                    }                
                }

                return response

            },
            async infiniteHandler($state) {

                this.isLoading = true;

                try {
                    let response = await this.getVoicemail() 
                    this.isLoading = false;

                    const { nextToken, voicemail, total_count } = response.data.ycmdVoicemailGet;
                    if (this.allSelected != 'none') {
                        this.allSelected = 'some'
                    }
                    if (voicemail.length) {
                        this.newItems.push(...this.preProcess(voicemail));

                        // Update counts
                        this.currentItems += voicemail.length;
                        this.totalItems = total_count;

                        if (nextToken) {

                            $state.loaded();

                            if(nextToken === "")
                                $state.complete();

                            this.nextToken = nextToken;
                        }
                        else {

                            $state.loaded();
                            $state.complete();

                            this.nextToken = null;
                            this.loadingMessageResult = '';
                        }
                    }
                    else {
                        // data did not come back
                        $state.complete();

                        this.loadingMessageResult = 'No results were found';
                    }
                }
                catch (e) {

                    $state.complete();
                    console.log(e);
                }
            },
            async changeStatus(item, id, status) {

                console.log('CHANGING STATUS');
                console.log(id);
                console.log(status);
                let count = this.badgeCount(item.status, status)
                try {
                    this.$set(item, "isLoading", true);
                    
                    const response = await this.$apollo.mutate({
                        mutation: MUTATE_VOICEMAIL_STATUS,
                        variables: {
                            voicemail_id: id,
                            status: status
                        },
                        client: 'phoenixClient',
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    console.log('response: ', response);

                    const { ycmdVoicemailUpdateStatus } = response.data

                    this.$set(item, "isLoading", false);

                    if (ycmdVoicemailUpdateStatus) {
                        // remove item
                        for (let index in this.newItems) {
                            if (this.newItems[index].contact_id === id) this.newItems.splice(index, 1);
                        }
                        this.currentItems--;
                        this.totalItems--;

                        if (count) {
                            this.$store.dispatch('app/adjustBadge', 
                            {
                                badgeKey: 'voicemail',
                                count: count
                            })
                        }

                        // notify user
                        this.$toasted.success(`Successfully ${ status === 'complete' ? 'trashed' : 'restored' } voicemail message`);
                    }
                    else {
                        // data did not come back
                        this.$toasted.error(`There was an error deleting the voicemail`);
                        console.log('ycmdVoicemailUpdateStatus did not come back: ', response);
                    }
                    
                }
                catch (e) {
                    this.$toasted.error(`Caught an error deleting voicemail`);
                    console.log('WARNING: Caught error - ', e);
                }
            },
            refreshData(statusChangedIds, status) {
                this.currentItems = 0;
                this.totalItems = 0;
                this.nextToken = null;
                this.newItems = [];

                console.log('statusChangedIds', statusChangedIds)

                if (statusChangedIds && statusChangedIds.length && status) {
                    this.changedItems = statusChangedIds
                    this.changedStatus = status
                } else {
                    this.changedItems = []
                    this.changedStatus = ''
                }
                console.log('this.changedItems', this.changedItems)

                this.infiniteId++;
            },
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open,
                appItemIndex: state => state.app.itemIndex
            }),
            ...mapGetters(
                'profile', ['getUserID']
            ),
            confirmationButtons () {
                return [
                    {caption: 'OK', disabled: this.sending},
                    {caption: 'Cancel', disabled: this.sending}
                ]
            },
            headerSelectIcon () {
                let oneSelected = false
                let oneUnselected = false
                for (let i=0;i<this.newItems.length;i++) {
                    if (this.newItems[i].selected) {
                        oneSelected = true
                    } else {
                        oneUnselected = true
                    }
                    if (oneSelected && oneUnselected) {
                        return 'remove_circle_outline'
                    }
                }
                return oneSelected ? 'check_circle_outline' : 'radio_button_unchecked'
            },
            sortIcon () {
                return this.sortDirection === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down'
            },
            advancedSearchColor () {
                let color = 'white'

                if (this.advancedSearch) {
                    if (this.advancedSearch.voicemail_date ||
                            this.advancedSearch.callback_phone ||
                            this.advancedSearch.mailbox) {
                                color = 'ycmd-green'
                            }                    
                } 

                return color
            }
        },
        watch: {
            'params.data' (newVal, oldVal) {
                if (newVal && newVal.contact_id) {
                    let oldVoicemail = this.newItems.find(v => {
                        return v.contact_id == newVal.contact_id
                    })
                    if (oldVoicemail) {
                        this.$set(oldVoicemail, 'status', newVal.status);
                        this.$set(oldVoicemail, 'history', newVal.history);
                    }
                }
            },
            'params.startingId' (newVal, oldVal) {
                if (newVal) {
                    setTimeout(() => {
                        this.setStartingPosition(newVal)
                    }, 500);
                    this.params.startingId = ''
                }
            }
        }
    }
</script>

<style scoped>

</style>
