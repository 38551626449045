<template>

    <div>

        <v-dialog v-model="value" persistent max-width="300">

            <v-card style="padding-top: 15px;">
                <v-card-title class="fs-18">
                    Two Factor Setup is required
                </v-card-title>
                <v-card-text class="fs-18">
                  <v-checkbox
                      class="d-flex align-center ma-0"
                      v-model="postpone"
                      :label="postponeLabel"
                      :disabled="checkboxDisabled"
                  ></v-checkbox>

                  <v-text-field light solo dense flat outlined
                                class="mt-6"
                                label="Email Address"
                                v-model="email" 
                                :disabled="fieldDisabled"
                                />
                  <v-text-field light solo dense flat outlined
                                class="mt-6"
                                label="Mobile Number"
                                v-model="mobile"
                                :disabled="fieldDisabled"
                                />

                <div v-if="errorMsg" class="mt-3" style="color: red">{{errorMsg}}</div>
                <div v-if="successMsg" class="mt-3" style="color: green">{{successMsg}}</div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="ok" :disabled="okDisabled" v-if="!successMsg">{{caption}}</v-btn>
                    <v-btn text @click="doLogout" v-if="successMsg">Logout</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>

</template>

<script>
  import { mapGetters  } from "vuex";
  import GET_PROFILE from '@/graphql/queries/phoenix/ycmdProfileGet';
  import SETUP_TWOFACTOR from '@/graphql/mutations/phoenix/ycmdAuthenticateTwofactorSetup';
  import {logout} from '@/methods/globalMethods';
  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      }
    },
    data: () => ({
      email: '',
      mobile: '',
      postponeLocal: false,
      sending: false,
      errorMsg: '',
      successMsg: ''
    }),
    mounted() {
        this.getProfile();
    },
    methods: {
      async ok () {
        this.sending = true
        await this.setupTwoFactor()
        this.sending = false
      },
      doLogout () {
        logout()
      },
      async getProfile() {
          try {
              const response = await this.$apollo.query({
                  query: GET_PROFILE,
                  variables: {
                      accountType: 'all'
                  },
                  fetchPolicy: 'no-cache',
                  errorPolicy: 'all'
              });
              console.log('response: ', response);
              // update
              if (response.data && response.data.ycmdProfileGet) {
                  this.email = response.data.ycmdProfileGet.email;
              }
          } catch (e) {
              console.log('Catch error: ', e);
          }
      },
      async setupTwoFactor() {
          try {
            this.errorMsg = ''
            this.successMsg = ''
            const response = await this.$apollo.query({
                query: SETUP_TWOFACTOR,
                variables: {
                    postpone: this.postpone, 
                    email: this.email, 
                    mobile_number: this.mobile, 
                    allow_different_emails: true
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            console.log('2factor response: ', response);
            if (response.errors && response.errors.length) {
              this.errorMsg = response.errors[0].message
            } else {
              if (response && response.data && response.data.ycmdAuthenticateTwofactorSetup && response.data.ycmdAuthenticateTwofactorSetup.twofactor_conversion_date ) {
                this.successMsg = 'Your account has been set up for two factor.  You should have received a text with your temporary password.  You must login with your temporary password to finish setting up two factor'
              } else {
                this.$store.commit('app/setAuthenticationStatusOpen', false)
                this.$store.commit('app/setAuthenticationStatus', response.data.ycmdAuthenticateTwofactorSetup)
              }
            }
              // update

          } catch (e) {
              console.log('Catch error: ', e);
          }
      }
    },
    computed: {
      ...mapGetters(
          'app',['authenticationStatus']
      ),
      caption () {
        return this.postpone ? 'Postpone' : 'Setup'
      },
      postponeLabel () {
        if (this.authenticationStatus && this.authenticationStatus.twofactor_required) {
          return 'Two factor setup can not be postponed'
        }
        let minutesLeft=0
        let hoursLeft = 0
        let daysLeft = 0
        if (this.authenticationStatus.twofactor_grace_period_minutes_left) {

          minutesLeft = this.authenticationStatus.twofactor_grace_period_minutes_left % 60 
          hoursLeft = this.authenticationStatus.twofactor_grace_period_minutes_left / 60

          daysLeft = Math.floor(hoursLeft / 24)

          hoursLeft = Math.floor(hoursLeft - (daysLeft * 24))
        }

        let desc = ''
        if (daysLeft) {
          desc += ` ${daysLeft} days`
        }
        if (hoursLeft) {
          desc += ` ${hoursLeft} hours`
        }
        if (minutesLeft) {
          desc += ` ${minutesLeft} minutes`
        }

        return `Would you like to postpone two factor setup? You have ${desc} left before you must set up two factor`
      },
      postpone: {
        get: function () {
          if (this.authenticationStatus && this.authenticationStatus.twofactor_required) {
            return false
          } else {
            return this.postponeLocal
          }
        },
        set: function (value) {
          if (this.authenticationStatus && this.authenticationStatus.twofactor_required) {
            this.postponeLocal=false
          } else {
            this.postponeLocal=value
          }
        }
      },
      isValid () {
        if (this.successMsg) {
          return false
        }
        if (this.postpone) {
          return true
        } else {
          if (!this.email) {  //todo validate email
            return false
          }
          if (!this.mobile) {  //todo validate phone
            return false
          }

          return true
        }
      },
      fieldDisabled () {
        if (this.successMsg) {
          return true
        }        
        if (this.postpone) {
          return true
        }
        if (this.sending) {
          return true
        }
        return false
      },
      checkboxDisabled () {
        if (this.successMsg) {
          return true
        }
        if (this.sending) {
          return true
        }
        return (this.authenticationStatus && this.authenticationStatus.twofactor_required)
      },
      okDisabled () {
        if (this.sending) {
          return true
        }

        return !this.isValid
      }
    }
  }
</script>

