<template>

    <div class="full-height d-flex align-center white--text fs-13" style="position: absolute; right: 15px; top: 0px;">

        <div class="fade-2">Showing</div>
        <div class="fw-500 fs-14 pl-1 pr-1">{{this.count || "0"}}</div>
        <div class="fade-2">of</div>
        <div class="fw-500 fs-14 pl-1 pr-1">{{this.total || "0"}}</div>

    </div>

</template>

<script>

  export default {
    name: 'itemsCounter',
    props: {
      count: String,
      total: String
    },
    data: () => ({
    }),
    mounted() {},
    methods: {

    },
    computed: {

    }
  }
</script>

<style scoped>

</style>
