<template>

    <div class="d-flex">

        <v-autocomplete
                light dense flat outlined solo
                v-model="nonComputedLocal"
                :items="items"
                :loading="isLoadingSearch"
                :search-input.sync="searchProviders"
                item-text="name"
                item-value="id"
                label="Search Providers"
                background-color="white"
                :rules="rules"
                clearable
                :filter="displayItem"
                return-object/>

    </div>

</template>

<script>

    import GET_PROVIDERS from '@/graphql/queries/phoenix/ycmdUserSearch';

    export default {
        name: 'ycmdProviderSearch',
        components: {
        },
        props: {
            providerSearchSelected: Object,
            rules: Array,
            facilityId: String,
            includeGroupHeaders: {
                type: Boolean,
                default: false
            },
            nameFormat: {
                type: String,
                default: 'first'
            },
            initialValue: null
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data: () => ({
            searchValue: '',
            isLoadingSearch: false,
            items: [],
            searchProviders: null,
            nonComputedLocal: null
        }),
        created() {

        },
        async mounted() {
            let iv

            if (this.initialValue) {
                if (typeof(this.initialValue) === 'string') {
                    iv = this.initialValue
                } else {
                    iv = this.initialValue.providerId
                    if (!iv) {
                        iv = this.initialValue.id
                    }
                }
            }
            await this.getProviders(null, this.initialValue);

            if (this.items && this.items.length && iv) {
                let found = this.items.find(i => {
                    return i.id == iv
                })
                
                this.$nextTick(() => {
                    this.nonComputedLocal = found
                })                                       
            }

        },
        watch: {
            nonComputedLocal(newValue) {
                this.providerSearchSelectedLocal = newValue
            },
            searchProviders(val) {
                if (!val || val.length < 3) return
                this.getProviders(val);
            },
            providerSearchSelected(newValue) {
                if (!newValue) {
                    this.nonComputedLocal = null
                }
            }
        },
        methods: {
            displayItem(item, queryText, itemText) {
                if (item.header) {
                    let groupTotals = this.groupTotals

                    return groupTotals[item.id]
                } else {
                    return this.matchedFilter(item, queryText)
                }
            },
            matchedFilter (item, queryText) {
                let it = item.name.toLowerCase()
                let qt = queryText.toLowerCase()

                return it.indexOf(qt) >= 0 
            },
            getNameProvider(item) {
              return `${item.first_name} ${item.last_name} ${item.group_name ? `- ${item.group_name}` : ''}`
            },
            getValueProvider(item) {
                return `${item.id}`
            },
            async getProviders(val, provider) {
                this.isLoadingSearch = true;
                let userId=null
                let facilityIds = this.facilityId ? [this.facilityId] : null
                if (provider) {
                    userId = provider.providerId
                    if (!userId) {
                        userId = provider.id
                    }
                    if (provider.location) {
                        let pieces = provider.location.split('|')
                        facilityIds = [pieces[0]]
                    }
                }
                try {
                    const response = await this.$apollo.query({
                        query: GET_PROVIDERS,
                        variables: {
                            search: val,
                            roles: ['provider','member'],
                            limit: 30,
                            facility_ids: facilityIds,
                            user_id: userId
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoadingSearch = false;
                    console.log('ycmd providers response: ', response);
                    console.log('this.includeGroupHeaders: ', this.includeGroupHeaders);
                    console.log('this.nameFormat: ', this.nameFormat);

                    const { ycmdUserSearch } = response.data;
                    if (ycmdUserSearch && ycmdUserSearch.length) {
                        this.items = [];
                        ycmdUserSearch.map(group => {
                            if (this.includeGroupHeaders) {
                                this.items.push({
                                    header: group.group_name, 
                                    id: group.group_id
                                })
                            }
                            group.users.map(user => {
                                user.name = this.nameFormat === 'last' ? `${user.last_name}, ${user.first_name}` : `${user.first_name} ${user.last_name}`
                                user.name = `${user.name}${group.group_name && !this.includeGroupHeaders ? ` - ${group.group_name}` : ''}`
                                user.group_id = group.group_id

                                this.items.push(user);
                            })
                        }) 
                        console.log('total items: ', this.items);

                    } else {
                        // data did not come back
                        console.log('ycmdUserSearch data did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoadingSearch = false;
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            providerSearchSelectedLocal: {
                get: function() {
                    // handle items array
                    if (!this.providerSearchSelected) {
                        this.items = [];
                    } else {
                        this.items = [this.providerSearchSelected];
                    }
                    return this.providerSearchSelected;
                },
                set: function(newValue) {
                    this.$emit('update:providerSearchSelected', newValue)
                    this.$emit('selected', newValue)
                }
            },
            groupTotals () {
                let groups = {}
                console.log('this.searchProviders', this.searchProviders)
                this.items.map(i => {
                    if (!i.header) {
                        let match = this.matchedFilter(i, this.searchProviders )
                        if (match) {
                            if (!groups[i.group_id]) {
                                groups[i.group_id] = 1
                            } else {
                                groups[i.group_id] += 1
                            }
                        }
                    }
                })
                return groups
            }
        }
    }
</script>
