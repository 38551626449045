<template>

  <div>
<!--
    <transition :name="transition">
-->      
        <keep-alive >
            <component v-bind:is="currentView" :changeView="changeView" :callbackChanged="callbackChanged" />
        </keep-alive>
<!--        
    </transition>
-->
  
  </div>
</template>

<script>
import dataClient from '@/graphql/clients/axios';
import {mapGetters} from "vuex";
import chatStatusRoomDisplay from './chat-status-room-display.vue';
import chatStatusRoomSelect from './chat-status-room-select.vue';

export default {
    name: 'chatStatusDialog',
    props: {
      initialize: {
        type: Number,
        default: 0
      },
    },
    components: {
        'chat-status-room-display': chatStatusRoomDisplay,
        'chat-status-room-select': chatStatusRoomSelect,
    },
    data() {
        return {
            currentView: 'chat-status-room-display',
            params: {},
            transition: 'fade',
            callbacks: {}
        }
    },
    methods: {
      delayedCallback () {
        setTimeout(() => {
          if (this.callbacks[this.currentView]) {
            this.callbacks[this.currentView]()
          } else {
            this.delayedCallback()
          }
        }, 500);

      },
      changeView(view) {
        if (view) {
          this.currentView = view 
        } else {
          if (this.currentView == 'chat-status-room-display') {
            this.currentView = 'chat-status-room-select'
          } else {
            this.currentView = 'chat-status-room-display'
          }
        }
        if (this.callbacks[this.currentView]) {
          this.callbacks[this.currentView]()
        } else {
          this.delayedCallback()          
        }

      },
      callbackChanged(view, callback) {
        this.callbacks[view] = callback
      },

    },
    computed: {
      ...mapGetters('chat', ['getCurrentRoom']),
    },
  watch : {
    initialize (newValue, oldValue) {
      let initView = 'chat-status-room-display'
      if (!this.getCurrentRoom || !this.getCurrentRoom.id) {
        initView = 'chat-status-room-select'        
      } else {
        let response = dataClient('ycmdChatRoomClearUnread', {room_id: this.getCurrentRoom.id})
        
        //give the ycmdChatRoomClearUnread a 1 second head start
        setTimeout(() => {
          this.$store.dispatch('app/syncBadges', {from: 'chat-status-dialog', type: 'chat_post'}, {root:true})
        }, 1000);
      }

      this.changeView(initView)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>


</style>
