<template>

    <div class="view">

        <div class="d-flex flex-column flex-grow-1 relative">

            <transition :name="transition">

                <keep-alive >
                    <component v-bind:is="currentView.name" @onChangeView="changeView" :params="view.params"/>
                </keep-alive>

            </transition>
        </div>
    </div>
</template>

<script>

    import notificationsList from './notifications-filtered-list.vue';
    import notificationsDetail from '../notifications/notifications-detail.vue';

    export default {
        name: 'notifications',
        components: {
            'list-view': notificationsList,
            'details-view': notificationsDetail
        },
        data() {
            return {
                visit_id: 0,
                view: {
                    name: 'list-view',
                    params: {}
                },
                transition: 'next'
            }
        },
        methods: {
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            changeView({view, params}) {

                this.view.name = view;
                this.view.params = params || {};
            }
        },
        computed: {
            currentView: function() {

                this.transition = (this.view.name === 'list-view') ? 'prev' : 'next';

                return this.view;
            }
        }
    }

</script>

<style scoped>


</style>
