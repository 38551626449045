import gql from 'graphql-tag'

export default gql`query pcpProviders($search: String, $limit: Int) {
	pcpProviders(search: $search, limit: $limit) {
		providers {
      id
      user_id
      first_name
      last_name
    }
  }
}`
