import Vue from 'vue'
import VueRouter from 'vue-router'

import PageNoAccess from './views/page-no-access.vue'
import Chat from './views/apps/chat/index.vue';
import Chat_Rooms from './views/apps/chat/rooms/index.vue'
import Chat_Main from './views/apps/chat/main/index.vue'
import Chat_Details from './views/apps/chat/details/index.vue'
import Chat_Message from './views/apps/chat/message/index.vue'
import Chat_Edit from './views/apps/chat/edit/index.vue'
import Chat_Add_Participant from './views/apps/chat/add-participant/index.vue'
import Chat_Profile from './views/apps/chat/profile/index.vue'
import Chat_GeneralSettings from './views/apps/chat/general-settings/index.vue'
import Chat_Settings from './views/apps/chat/settings/index.vue'
import Chat_Schedule from './views/apps/chat/schedule/index.vue'

import { checkHasTokensPhoenix } from "@/methods/globalMethods";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Chat,
    redirect: '/chat/rooms',
    children: [
      {
        path: 'chat/rooms',
        name: 'CHAT.rooms',
        component: Chat_Rooms
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'main',
        name: 'CHAT.main',
        component: Chat_Main
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'details',
        name: 'CHAT.details',
        component: Chat_Details
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'message',
        name: 'CHAT.message',
        component: Chat_Message
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'edit',
        name: 'CHAT.edit',
        component: Chat_Edit
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'addParticipant',
        name: 'CHAT.addParticipant',
        component: Chat_Add_Participant
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'profile',
        name: 'CHAT.profile',
        component: Chat_Profile
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'general',
        name: 'CHAT.general',
        component: Chat_GeneralSettings
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'settings',
        name: 'CHAT.settings',
        component: Chat_Settings
      }
    ]
  },
  {
    path: '/chat',
    component: Chat,
    children: [
      {
        path: 'schedule',
        name: 'CHAT.schedule',
        component: Chat_Schedule
      }
    ]
  },
  {
    path: "/no-access",
    name: '403',
    component: PageNoAccess
  },
  {
    path: "*",
    name: 'defaultRoute',
    component: PageNoAccess
  }
];

const Router = new VueRouter({
  mode: 'abstract',
  routes
});

Router.beforeEach((to, from, next) => {

  // check if phoenix token is needed
  if (to.meta.phoenixAuthentication && !checkHasTokensPhoenix()) {

    console.log('Phoenix token does not exist');

    this.$store.dispatch('app/logout');

    return;
  }
  next();
});

export default Router