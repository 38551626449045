import gql from 'graphql-tag'

export default gql`query pcpPatientStatus($reference_id: ID, $facility_id: ID, $visit_id: ID) {
	pcpPatientStatus(reference_id: $reference_id, facility_id: $facility_id, visit_id: $visit_id) {
		_facility
    facility_name
    patient_dob
    patient_family_name
    patient_given_name
    patient_id
    patient_middle_name
    patient_sex
    pcp_degree
    pcp_family_name
    pcp_given_name
    pcp_id
    pcp_prefix
    pcp_suffix
    visit_admit_reason
    visit_admitting {
      assigning_authority
      degree
      family_name
      given_name
      id
      middle_name
      prefix
      source_table
      suffix
    }
    visit_attending {
      assigning_authority
      degree
      family_name
      given_name
      id
      prefix
      middle_name
      source_table
      suffix
    }
    visit_id
    first_confirmed_by
    first_confirmed_time
    first_date_created
    first_event_recorded_time
    first_event_type_code
    first_id
    first_message_id
    first_message_trigger_event
    first_message_type
    last_confirmed_by
    last_confirmed_time
    last_date_created
    last_event_recorded_time
    last_event_type_code
    last_id
    last_message_id
    last_message_trigger_event
    last_message_type
    message_sending_facility
  }
}`
